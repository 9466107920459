import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { getDashboard } from "@services/index";
import { BaseDetail } from "@app/admin/base/detail";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { BidSessionDetailService } from "@app/admin/bid-sessions/bid-session-detail.service";
import { Title } from "@angular/platform-browser";
import { StringULID } from "@wearewarp/types";
import { ResponseBidSessionDetail } from "@wearewarp/types-server-admin/carrier-bid/bid-session";
import { DateUtil } from "@services/date-utils";
import { DialogService } from "@dialogs/dialog.service";
import { AddCarrier } from "@app/admin/carrier-sale-detail/component/carriers/add-carrier";

@Component({
  selector: "bid-session-detail",
  templateUrl: "./index.html",
  styleUrls: ["./style.scss"],
})
export class BidSessionDetailComponent extends BaseDetail {

  public isLoading = false;
  public data: ResponseBidSessionDetail;
  public isError: boolean = false;
  get hasData() { return this.data }

  protected subscribeTopbarTitle(fn: Function) {
    return fn("View Bid Session");
  }

  private getDataSubscription: Subscription;

  constructor(
    private titleService: Title,
    private bidSessionDetailService: BidSessionDetailService,
    activatedRoute: ActivatedRoute,
    api: ApiService,
  ) {
    super(activatedRoute);
    this.api = api;
    this.activatedRoute.params.subscribe(params => {
      super.handleNavigationEnd(activatedRoute.toString(), activatedRoute.queryParamMap);
    })
  }

  public get bidSessionId() {
    return this.id
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getDataSubscription = this.bidSessionDetailService.getDataObservable().subscribe(() => {
      this.getData();
    });
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
  }

  ngOnDestroy(): void {
    if (this.getDataSubscription) {
      this.getDataSubscription.unsubscribe();
    }
  }

  getData() {
    if (!this.bidSessionId) return;
    this.isLoading = true;

    let url = `${Const.APIURI_BID_SESSIONS}/${this.bidSessionId}`;
    this.api.GET(url).subscribe(
      resp => {
        this.data = resp.data;
        this.buildDisplayData(this.data);
        const bids = resp.data?.bids ?? [];
        const bidIds = bids.map(it => it.id);
        this.getCarrierResponsed(bidIds);
        this.titleService.setTitle(`${this.data?.name} - BID Session - WARP Admin`);
        this.bidSessionDetailService.setSessionDetail(this.data);
        this.bidSessionDetailService.setBids(bids);
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.showErr(err);
        this.isError = true;
      }
    );
  }

  txtDateTime = '';
  get carrierIds(): string[] { return this.carriers.map(it => it.id) }
  routes: {id: string, code: string, bidId: string}[] = [];
  carriers: {id: string, name: string}[] = [];
  private buildDisplayData(data: ResponseBidSessionDetail) {
    this.txtDateTime = DateUtil.displayTimeWindow({from: data.fromTime, to: data.toTime}, {timezone: data.timeZone, format: 'MMM D, YYYY', formatDateOnly: 'MMM D'});
    this.txtDateTime += ` (${DateUtil.timezoneStandardToUsShort(data.timeZone)})`;
    this.routes = data.bids.map(it => ({...it.route, bidId: it.id}));
    this.carriers = data.candidates.map(it => it.carrier);
  }

  private getCarrierResponsed(bidIds: StringULID[]) {
    const urls = bidIds.map(bidId => Const.APIV2(`${Const.APIURI_CARRIER_BIDS}/${bidId}/get_list_carriers?filter=${encodeURIComponent(JSON.stringify({ type: 'response-received' }))}&limit=-1`));
    this.api.concurrentGET(urls).subscribe(
      res => {
        const candidates = res.map(r => r.data?.list_data || []).flat();
        this.bidSessionDetailService.setCandidatesResponsed(candidates);
      },
      error => {
        this.showErr(error);
      }
    )
  }

  addRoute() {
    return this.router.navigate([Const.routeAdminBidSessions, this.bidSessionId, 'add-routes']);
  }

  addCarrier() {
    DialogService.openFormDialog1(AddCarrier, {
      nzComponentParams: {
        model: {},
        closeOnSuccess: true,
        currentCarrierIds: this.carrierIds,
        submit: async (carrierIds: string[]) => this.addCarrierToSession(carrierIds),
        updateSuccess: (resp) => {
        },
      },
      nzClassName: "send-mail-form modal-xl",
    });
  }

  private addCarrierToSession(listIds: string[]) {
    const url = `${Const.APIURI_BID_SESSIONS}/${this.bidSessionId}/candidates`;
    return this.api.POST(url, {carrierIds: listIds}).toPromise();
  }
}
