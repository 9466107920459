<div class="form-header">
  <div class="flex-space-between">
    <span class="form-title-main">Filters</span>
    <div>
      <i class="ic-right-close clickable" nz-icon nzType="close" nzTheme="outline" (click)="onClose()"></i>
    </div>
  </div>
</div>
<div class="separator h"></div>
<div class="form-body">
  <form class="form-detail" [formGroup]="formInput" nz-form>
    <div nz-row nzGutter="8" class="bottom20">
      <div nz-col nzSm="24" *ngFor="let key of ['clientId']">
        <div class="form-label-v2 bottom5 medium">
          {{getLabel(key)}}
        </div>
        <div class="flex" style="min-width: 100%;" >
          <select-by-searching style="width: 100%;" 
            [formControlName]="key"
            [showSubClient]="true"
            [placeholder]="getPlaceHolder(key)" [version]="2"
            dropdownClassName="filter-customer" nzMode="multiple">
          </select-by-searching>
          <div>
            <nz-select [formControlName]="'clientFilterType'">
              <nz-option nzLabel="Include" nzValue="include"></nz-option>
              <nz-option nzLabel="Exclude" nzValue="exclude"></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>
    <div nz-row nzGutter="8" class="bottom20">
      <div nz-col nzSm="24">
        <div class="form-label-v2 bottom5 medium">
          {{getLabel('pickFrom')}}
        </div>
        <div class="flex">
          <div *ngFor="let key of ['pickFrom']" style="width: 50%">
            <nz-date-picker [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)" style="width: 100%"></nz-date-picker>
          </div>
          <div *ngFor="let key of ['pickTo']" style="width: 50%">
            <nz-date-picker [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)" style="width: 100%"></nz-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div nz-row nzGutter="8" class="bottom20">
      <div nz-col nzSm="24">
        <div class="form-label-v2 bottom5 medium">
          {{getLabel('dropFrom')}}
        </div>
        <div class="flex">
          <div *ngFor="let key of ['dropFrom']" style="width: 50%">
            <nz-date-picker [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)" style="width: 100%"></nz-date-picker>
          </div>
          <div *ngFor="let key of ['dropTo']" style="width: 50%">
            <nz-date-picker [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)" style="width: 100%"></nz-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div nz-row nzGutter="8" class="bottom20">
      <div nz-col nzSm="24" *ngFor="let key of ['reattemptCount']">
        <div class="form-label-v2 bottom5 medium">
          {{getLabel(key)}}
        </div>
        <div style="width: 50%;">
          <input nz-input type="number" [formControlName]="key" >
        </div>
      </div>
    </div>
    <div nz-row nzGutter="8" class="bottom20">
      <div nz-col nzSm="24" *ngFor="let key of ['isPickup']">
        <div class="form-label-v2 bottom5 medium">
          {{getLabel(key)}}
        </div>
        <div style="width: 50%;">
          <label nz-checkbox [formControlName]="key">Pickup</label>
          <label nz-checkbox [formControlName]="'isDropoff'">Dropoff</label>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="footer">
  <div class="separator h"></div>
  <div class="flex-space-between" style="align-items: center; padding: 24px; width: 100%;">
    <div class="form-item">
      <div class="form-label-v2 single-line fix-height"></div>
      <a nz-button nzType="link" (click)="onBtnClearFilter()">Clear Filter</a>
    </div>
    <div class="form-footer">
      <div form-footer [onProgress]="onProgress" style="display: flex; flex-direction: row-reverse;"
        [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress" 
        [labelOK]="'Save'" (onOK)="onBtnSave()" 
        (onCancel)="onClose()"></div>
    </div>
  </div>
</div>
