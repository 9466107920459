<div class="title">Routes({{ routes.length }})</div>

<!-- <div class="settings" *ngIf="isAutoAddRoute">
  Autoadd: Walmart, CHI, Cargo Van, Oct 17th
</div> -->

<div class="g-list">
  <div class="g-item" *ngFor="let route of routes">
    <a class="name" [href]="route.hyperLinkUrl" target="__blank">{{ route.hyperLinkText }}</a>
    <div class="action clickable" (click)="onRemoveRoute(route)">
      <span nz-icon nzType="close" nzTheme="outline"></span>
    </div>
  </div>
</div>