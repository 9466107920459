import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms/forms';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { ApiService } from '@services/api.service';
import { InputHelper } from '@services/input-helper';
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { EditCounter_CarrierBidInfo } from '../../interfaces';
@Component({
  selector: 'app-counter-for-carrier',
  templateUrl: './counter-for-carrier.component.html',
  styleUrls: ['./counter-for-carrier.component.scss']
})
export class CounterForCarrierComponent extends BaseFormDialog1 implements OnInit {

  @Input() carrierBidInfo;
  @Input() carrierBidItem: EditCounter_CarrierBidInfo;

  public isError = false;
  public isLoading = false;

  protected formGroupDeclaration: FormGroupDeclaration = {
    answer: {
      label: "Answer",
      placeHolder: "Please choose anwser",
      required: true,
    },
    price: {
      label: "Price",
      placeHolder: "Enter price",
      getValue: InputHelper.getValueMoney, formatValue: InputHelper.formatMoney
    }
  };
  constructor(api: ApiService) {
    super();
    this.api = api;
  }

  get isCreateNew(): boolean {
    return true;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.createFormInput();
    this.setItemValue("answer", 1 +"")
    this.setItemValue("price", this.getCarrierLatestPrice())
  }

  onAnswerChange(value){
    if(value == 0){
      this.setItemValue("price", this.getCarrierLatestPrice())
      this.getItemByKey("price").disable()
    }
    else if(value == 1){
      this.setItemValue("price", "")
      this.getItemByKey("price").enable()
    }
    else
    {
      this.setItemValue("price", this.getAcceptedPrice())
      this.getItemByKey("price").disable()
    }
  }
  

  onBtnSave() {
    let json: any = this.getFormData_JSON(true);
    if(!json.price && json.answer == 1){
      return this.showInfo('The price field is required!')
    }
    let url = `${Const.APIURI_CARRIER_BIDS}/counter_offer`;
    if(json.answer == 1 || json.answer == 0){
      const param = {
        token: this.carrierBidItem?.token,
        state: Const.CarrierBidState.PlacedBid,
        bidId: this.carrierBidItem?.bidId,
        action: WarpConst.BidCounterAction.counterOffer,
        entity: WarpConst.BidCounterOfferEntities.carrier,
        price: json?.price
      };
      this.startProgress();
      this.api.POST(url, param).subscribe(
        resp => {
          super.onUpdateSuccess(resp);
          this.closeDialog();
          this.stopProgress();
        }, err => {
          this.showErr(err);
          this.stopProgress();
        }
      )
    }else {
      const param = {
        token: this.carrierBidItem?.token,
        state: Const.CarrierBidState.Accepted,
        bidId: this.carrierBidItem?.bidId,
        action: WarpConst.BidCounterAction.acceptCounterOffer,
        entity: WarpConst.BidCounterOfferEntities.carrier,
        price: json?.price,
        bidCounterId: this.carrierBidItem.bidCounterId
      };
      this.startProgress();
      this.api.POST(url, param).subscribe(
        resp => {
          super.onUpdateSuccess(resp);
          this.closeDialog();
          this.stopProgress();
        }, err => {
          this.showErr(err);
          this.stopProgress();
        }
      )
    }
    
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'price': return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true);
    }
  }

  getCarrierLatestPrice(){
    const {bidAnswers = []} = this.carrierBidItem;
    for(let i = bidAnswers.length - 1; i >=0; i--){
      if(bidAnswers[i]?.entity == WarpConst.BidCounterOfferEntities.carrier){
        return bidAnswers[i]?.price;
      }
    }
  }

  getAcceptedPrice(){
    const {bidAnswers = []} = this.carrierBidItem;
    for(let i = bidAnswers.length - 1; i >=0; i--){
      if(bidAnswers[i]?.entity == WarpConst.BidCounterOfferEntities.admin){
        return bidAnswers[i]?.price;
      }
    }
  }

  getKeepPriceLabel(){
    return `Keep ${this.formatMoney(this.getCarrierLatestPrice())}`
  }

  getAcceptPriceLabel(){
    return `Accept ${this.formatMoney(this.getAcceptedPrice())}`
  }

  formatMoney(money: number) {
    return money ? InputHelper.formatMoney2(Number(money).toFixed(2).toString()) : money;
  }

}
