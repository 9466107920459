import { Component, Input, OnInit } from '@angular/core';
import { Const } from '@const/Const';
import { UIHelper } from '@services/UIHelper';
import { ApiService } from '@services/api.service';
import { ResponseAdminFinBatchDetailFinJob, ResponseAdminFinJobDetail } from '@wearewarp/types-server-admin/fin';
import { FinCost } from '../fin-job-cost';
import { CarrierCost, ShipmentCost } from '@wearewarp/types/data-model';
import { ConstFin } from '@wearewarp/js-const-finance';
import { CarrierCostHelper } from '@app/admin/dispatch/components/carrier-cost/helper';
import { StringULID } from '@wearewarp/types';
import { Log } from '@services/log';
import { DialogService } from '@dialogs/dialog.service';
import { OrderCostHelper } from '@app/admin/shipment-entry/components/edit-shipment/revenue-helper/helper';
import { FinService } from '../../fin-service';

@Component({
  selector: '[fin-job-breakdown]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class FinJobBreakdown implements OnInit {
  @Input() parentFinJob: ResponseAdminFinBatchDetailFinJob | undefined;
  @Input() finType: string;
  @Input() finJobId: string;
  isLoading = false;
  data: ResponseAdminFinJobDetail

  get finJobCost(): FinCost {
    return {
      transitCost: this.data.cost.transitCost,
      volumeDiscount: (<ShipmentCost>this.data.cost).volumeDiscount,
      fuelCost: this.data.cost.fuelCost,
      serviceOptions: this.data.cost.serviceOptions
    }
  }

  get isAR(): boolean { return this.finType == ConstFin.FinType.receivable }
  get isAP(): boolean { return this.finType == ConstFin.FinType.payable }
  get isAdditionalCost(): boolean { return !!this.data.relatedBiz.additionalCostId }
  get isClosed(): boolean { return this.data.status == ConstFin.FinJob_FinStatus.closed }

  constructor(private finService: FinService) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getData();
  }

  private getData() {
    this.isLoading = true;
    const url = Const.APIURI_FINANCES_BATCH(`jobs/${this.finJobId}`);
    ApiService.instance.GET(url).subscribe(
      resp => {
        this.data = resp.data;
        this.isLoading = false;
      }, err => {
        UIHelper.showErr(err);
        this.isLoading = false;
      }
    );
  }

  // private refresh() {
  //   this.getData();
  // }

  onBtnEdit() {
    if (this.isClosed) {
      return;
    }
    if (this.isAR) {
      if (this.isAdditionalCost) {
        this.editShipmentCostAdditional();
      } else {
        this.editShipmentCost();
      }
    } else if (this.isAP) {
      if (this.isAdditionalCost) {
        this.editCarrierCostAdditional();
      } else {
        this.editCarrierCost();
      }
    } else {
      Log.e(`Unknown finType ${this.finType}`);
    }
  }

  private editCarrierCostAdditional() {
    DialogService.comingSoon();
  }

  private editCarrierCost() {
    const carrierCost = <CarrierCost>this.data.cost; 
    if (carrierCost?.paid?.when) {
      return UIHelper.showErr("The cost already paid for carrier. Please create additional cost."); 
    }
    CarrierCostHelper.openModalCarrierCost({
      jobId: '',
      title: `Edit Carrier Cost for Route ${this.data.relatedBiz.code}`,
      currentData: carrierCost,
      submit: (jobId, data) => {
        return this.saveCarrierCost(this.data.relatedBiz.id, data, this.data.relatedBiz.additionalCostId);
      },
      onError: err => UIHelper.showErr(err),
      onSuccess: resp => {
        this.finService?.refreshDataAPBatchDetail();
      },
    });
  }

  private saveCarrierCost(jobId: string, data: CarrierCost, additionalCostId?: StringULID) {
    const url = additionalCostId ?
      `${Const.APIV2(Const.APIURI_JOBS)}/${jobId}/additional_carrier_cost/${additionalCostId}` : 
      `${Const.APIV2(Const.APIURI_JOBS)}/${jobId}/carrier_cost`;
    return ApiService.instance.PUT(url, data);
  }

  private editShipmentCostAdditional() {
    DialogService.comingSoon();
    // TODO
  }

  private editShipmentCost() {
    let orderId;
    if (this.parentFinJob && this.parentFinJob.relatedBiz?.type === ConstFin.FinRelatedBiz.order) {
      orderId = this.parentFinJob.relatedBiz.id;
    } else if (!this.parentFinJob && this.data?.relatedBiz?.type === ConstFin.FinRelatedBiz.order) {
      orderId = this.data.relatedBiz.id;
    }
    if (!orderId) {
      UIHelper.showErr('Not found orderId.');
      return;
    }
    OrderCostHelper.openModalOrderCost({
      orderId: orderId,
      onSuccess: () => {
        this.finService?.refreshDataARBatchDetail();
      },
    });
  }

}