import { Component, Input } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { DialogService } from "@dialogs/dialog.service";
import { Subscription } from "rxjs";
import { BidSessionDetailService } from "@app/admin/bid-sessions/bid-session-detail.service";
import { Const } from "@const/Const";
import { SendASAPDlg } from "@app/admin/carrier-sale-detail/component/carriers/send-asap";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
import { ResponseBidSessionDetail } from "@wearewarp/types-server-admin/carrier-bid/bid-session";

@Component({
  selector: "bid-session-outreach",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class BidSessionOutreach extends BaseComponent {
  @Input() bidSessionId: string;
  @Input() currentCarrierIds: string[];
  session: ResponseBidSessionDetail;
  private sessionDetailSubscription: Subscription;

  public tabSelected: number = 0;
  private tabIndexs = {
    'matched-carriers': 0,
    'external-posting': 1,
  }
  
  constructor(private bidSessionDetailService: BidSessionDetailService, private carrierSaleDetailService: CarrierSaleDetailService) {
    super();
  }

  ngOnInit() {
    this.sessionDetailSubscription = this.bidSessionDetailService.sessionDetail$.subscribe(sessionDetail => {
      this.session = sessionDetail;
    });
  }

  ngOnDestroy() {
    if (this.sessionDetailSubscription) {
      this.sessionDetailSubscription.unsubscribe();
    }
  }

  get isMatchedCarriersTab() {
    return this.tabSelected === this.tabIndexs['matched-carriers'];
  }

  get isExternalPostingTab() {
    return this.tabSelected === this.tabIndexs['external-posting'];
  }

  confirmSendAsap() {
    DialogService.openDialog1(SendASAPDlg, {
      nzComponentParams: {
        bidSessionId: this.bidSessionId,
        type: 'sendASAP',
        onSave: () => this.api.POST(`${Const.APIURI_BID_SESSIONS}/${this.bidSessionId}/update-queue`),
        onDone: (data) => {
          this.carrierSaleDetailService.emitGetDataBid();
          this.showSuccess('Requeue successfull');
        }
      },
    });
  }
}