<div class="form-header no-border no-padding-bottom bottom20">
  <div class="flex-space-between">
    <div class="form-title f16 medium">Search company relationship</div>
    <div>
      <i (click)="onBtnClose()" class="ic-close clickable" nz-icon nzTheme="outline" nzType="close"></i>
    </div>
  </div>
</div>
<div *ngIf="accountInfo" class="bottom20">
  <div class="medium bottom5">WARP {{ isCompanyDebtor ? 'Customer' : 'Carrier' }} Information</div>
  <div class="carrier-info-item">
    <div class="carrier-info-label">Name: </div>
    <div *ngIf="isCompanyDebtor">{{ accountInfo?.name || 'N/A' }}</div>
    <div *ngIf="!isCompanyDebtor">{{ accountInfo.basicInfo?.name || 'N/A' }}</div>
  </div>
  <div *ngIf="!isCompanyDebtor" class="carrier-info-item">
    <div class="carrier-info-label">MC: </div>
    <div>{{ accountInfo.basicInfo?.mc || 'N/A' }}</div>
  </div>
  <div class="carrier-info-item">
    <div class="carrier-info-label">Address: </div>
    <div *ngIf="isCompanyDebtor">{{ getAddressText(accountInfo.address) || 'N/A' }}</div>
    <div *ngIf="!isCompanyDebtor">{{ getAddressText(accountInfo.basicInfo?.address) || 'N/A' }}</div>
  </div>
  <div class="carrier-info-item">
    <div class="carrier-info-label">Website: </div>
    <div *ngIf="isCompanyDebtor">{{ accountInfo.website || 'N/A' }}</div>
    <div *ngIf="!isCompanyDebtor">{{ accountInfo.basicInfo?.website || 'N/A' }}</div>
  </div>
</div>
<form nz-form [formGroup]="formInput" class="form-detail" [formGroup]="formInput" nzLayout="vertical">
  <div *ngIf="'companyName' as key">
    <nz-form-item>
      <nz-form-label>{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></nz-form-label>
      <div class="flex">
        <nz-form-control>
          <input nz-input [placeholder]="getPlaceHolder(key)"
            [formControlName]="key"/>
        </nz-form-control>
        <button
          nz-button
          nzType="primary"
          (click)="onBtnSearchCompany()"
          [nzLoading]="isLoading"
          style="width: 100px; margin-left: 20px;"
          >Search</button>
      </div>
    </nz-form-item>
  </div>
  <div class="warning">Note: If you do not found result, please add {{ isCompanyDebtor ? 'customer' : 'contractor' }} relationship on denim system first <a *ngIf="!isCompanyDebtor" class="left5" (click)="onBtnCreateDenimContractor()">at here</a></div>
</form>
<div class="medium top10 bottom5">{{ listOfData.length }} {{ listOfData.length > 1 ? 'results' : 'result' }}</div>
<div>
  <nz-table
    #nzTable nzBordered="true"
    [nzLoading]="isLoading"
    [nzLoadingIndicator]="tplLoading"
    [nzData]="listOfData" nzSize="small"
    [nzHideOnSinglePage]="true"
    [nzPageSize]="limit"
    [nzNoResult]="tplNoData">
    <ng-template #tplLoading>
      <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    </ng-template>
    <ng-template #tplNoData>
      <div class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
    </ng-template>
    <thead>
      <tr>
        <th>Company Info</th>
        <th *ngIf="isCompanyDebtor">Status</th>
        <th *ngIf="isCompanyDebtor">NOA Status</th>
        <th nzWidth="100px">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of nzTable.data; let i = index">
        <td>
          <div>Company Name: <b>{{ item.company?.company_name || 'N/A' }}</b></div>
          <div>EIN: {{ item.company?.ein || 'N/A' }}</div>
          <div>MC Number: {{ item.company?.mc_number || 'N/A' }}</div>
          <div>Address: {{ item.company?.fullAddress || 'N/A' }} </div>
          <div *ngIf="item.company?.freight_forwarder_number">
            Freight Forwarder Number: {{ item.company?.freight_forwarder_number || 'N/A' }}
          </div>
          <div *ngIf="item.company?.website">
            Website: {{ item.company?.website }}
          </div>
        </td>
        <td *ngIf="isCompanyDebtor">
          <div class="relationship-status">
            {{ item.company?.status }}
          </div>
        </td>
        <td *ngIf="isCompanyDebtor">
          <div class="relationship-status">
            {{ item?.client_debtor_relationship?.noa_status }}
          </div>
        </td>
        <td>
          <!-- <button nz-button nzSize="small" (click)="onBtnSelectItem(item)">Select</button> -->
          <button *ngIf="!isSelected(item)" nz-button nzSize="small" (click)="onBtnConfirm(item)" nzType="primary" [nzLoading]="isLoading">Confirm</button>
          <button *ngIf="isSelected(item)" nz-button nzSize="small" nzDanger [nzLoading]="isLoading"
            nz-popconfirm (nzOnConfirm)="onBtnRemove(item)" nzPopconfirmTitle="Are you sure to remove this relationship?">
            Remove
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>

</div>




