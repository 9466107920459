import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ShipmentQueue } from '.';
import { ShipmentQueueV2ListShipmentsModule } from './list-shipments/module';
import { ShipmentQueueV2ListNeedAppointmentsModule } from './list-need-appointments/module';
import { ShipmentQueueRoutingModule } from './shipmentQueue.route';

@NgModule({
  imports: [
    CommonModule,
    ShipmentQueueRoutingModule,
    NzTabsModule,
    NzIconModule,
    ShipmentQueueV2ListShipmentsModule,
    ShipmentQueueV2ListNeedAppointmentsModule
  ],
  declarations: [
    ShipmentQueue,
  ],
  exports: [
    ShipmentQueue,
  ]
})
export class ShipmentQueueModule { }