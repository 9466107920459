import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { InputHelper } from "@services/input-helper";
import { Observable } from "rxjs";

@Component({
  selector: '[send-asap]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class SendASAPDlg extends BaseDialog {
  
  @Input() bidId: string;
  @Input() bidSessionId?: string;
  @Input() type: 'sendASAP' | 'sendRemind';
  @Input() onSave: () => Observable<any>;
  public onDone: (data) => void = (data) => { }
  isLoading: boolean = false;
  onProgress: boolean = false;
  data: {
    numOfCarriers: number,
    numOfSms: number,
    numOfEmail: number,
    costSms: string,
  };

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
  }

  get txtTitle() {
    return this.type === 'sendRemind' ? 'Send Remind' : 'Send ASAP';
  }

  private getUrl() {
    const baseUrl = this.bidSessionId ? `${Const.APIURI_BID_SESSIONS}/${this.bidSessionId}` : `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/${this.bidId}`;
    return this.type === 'sendRemind' ? `${baseUrl}/get-cost-send-remind` : `${baseUrl}/get-cost-send-asap`;
  }

  private getData() {
    this.isLoading = true;
    this.api.GET(this.getUrl()).subscribe(
      resp => {
        if (resp?.data) {
          this.data = {
            numOfCarriers: resp.data.numOfCarriers,
            numOfSms: resp.data.numOfSms,
            numOfEmail: resp.data.numOfEmail,
            costSms: resp.data.costSms ? InputHelper.formatMoney2(`${resp.data.costSms}`) : '',
          }
        }
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.showErr(err);
      }
    );
  }

  onBtnConfirm() {
    this.onProgress = true;
    this.onSave().subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onDone(resp);
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

}
