import { Component, Input } from '@angular/core';
import { ResponseAdminFinJobDetailTx } from '@wearewarp/types-server-admin/fin';
import { InputHelper } from '@services/input-helper';
import { MasterData } from '@services/master.data';
import { Cost, ServiceOptionCost } from '@wearewarp/types/data-model';
import { FinTxRefCode, FinTxRefDetailType } from "@wearewarp/js-const-finance";
import { BaseComponent } from '@abstract/BaseComponent';
import { BizUtil } from '@services/biz';
import { FinDisplayCostItem, FinUtil } from '../../util';


interface DisplayData {
  txDesc: string,
  transitCost: FinDisplayCostItem[],
  fuelCost: FinDisplayCostItem[],
  volumeDiscount: FinDisplayCostItem[],
  serviceOptions: FinDisplayCostItem[][],
}

@Component({
  selector: '[fin-tx-detail]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class FinTxDetail extends BaseComponent {
  @Input() data: ResponseAdminFinJobDetailTx;
  displayData: DisplayData = {
    txDesc: '',
    transitCost: [],
    fuelCost: [],
    volumeDiscount: [],
    serviceOptions: [],
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildDisplayData();
  }

  getMoneyValue(value) {
    return InputHelper.formatMoney2(value ?? 0, '$');
  }

  private isTransitCost(code: string) {
    return code == FinTxRefCode.route.transitCost || code == FinTxRefCode.order.transitCost;
  }

  private isFuelCost(code: string) {
    return code == FinTxRefCode.route.fuelCost || code == FinTxRefCode.order.fuelCost;
  }

  private isServiceOption(code: string) {
    return code == FinTxRefCode.route.serviceOption || code == FinTxRefCode.order.serviceOption;
  }

  private isVolumeDiscount(code: string) {
    return code == FinTxRefCode.order.volumeDiscount;
  }

  private buildDisplayData() {
    this.displayData.txDesc = BizUtil.getTxDesc(this.data);
    const isTransitCost = this.isTransitCost(this.data?.ref?.code);
    const isFuelCost = this.isFuelCost(this.data?.ref?.code);
    const isVolumeDiscount = this.isVolumeDiscount(this.data?.ref?.code);
    const isServiceOption = this.isServiceOption(this.data?.ref?.code);
    const isChange = this.data?.ref?.detail?.type == FinTxRefDetailType.change;
    if (isServiceOption) {
      if (isChange) {
        const oldOptions: ServiceOptionCost[] = this.data.ref.detail.dataOld;
        const newOptions: ServiceOptionCost[] = this.data.ref.detail.dataNew;
        this.displayData.serviceOptions = [FinUtil.buildDisplayServiceOption(oldOptions), FinUtil.buildDisplayServiceOption(newOptions)];
      } else {
        const options: ServiceOptionCost[] = this.data.ref.detail.data;
        this.displayData.serviceOptions = [FinUtil.buildDisplayServiceOption(options)];
      }
    } else if (isTransitCost) {
      if (isChange) {
        const costOld: Cost = this.data.ref.detail.dataOld;
        const costNew: Cost = this.data.ref.detail.dataNew;
        this.displayData.transitCost = [FinUtil.buildDisplayTransitCost(costOld), FinUtil.buildDisplayTransitCost(costNew)];
      } else {
        const cost: Cost = this.data.ref.detail.data;
        this.displayData.transitCost = [FinUtil.buildDisplayTransitCost(cost)];
      }
    } else if (isFuelCost) {
      if (isChange) {
        const costOld: Cost = this.data.ref.detail.dataOld;
        const costNew: Cost = this.data.ref.detail.dataNew;
        this.displayData.fuelCost = [FinUtil.buildDisplayFuelCost(costOld), FinUtil.buildDisplayFuelCost(costNew)];
      } else {
        const cost: Cost = this.data.ref.detail.data;
        this.displayData.fuelCost = [FinUtil.buildDisplayFuelCost(cost)];
      }
    } else if (isVolumeDiscount) {
      if (isChange) {
        const costOld: Cost = this.data.ref.detail.dataOld;
        const costNew: Cost = this.data.ref.detail.dataNew;
        this.displayData.volumeDiscount = [FinUtil.buildDisplayVolumeDiscount(costOld), FinUtil.buildDisplayVolumeDiscount(costNew)];
      } else {
        const cost: Cost = this.data.ref.detail.data;
        this.displayData.volumeDiscount = [FinUtil.buildDisplayVolumeDiscount(cost)];
      }
    } else {
      // This should not occur
    }
  }

  // private buildDisplayTransitCost(cost: Cost): DisplayTransitCost {
  //   return {
  //     rate: InputHelper.formatMoney2(`${cost.rate ?? 0}`, '$'),
  //     qty: `${cost.qty ?? 0}`,
  //     total: InputHelper.formatMoney2(`${cost.total ?? 0}`, '$')
  //   };
  // }

  // private buildDisplayFuelCost(cost: Cost): DisplayFuelCost {
  //   const type = cost.type == 'rpm' ? 'RPM' : (cost.type == 'percentage' ? 'Percentage' : cost.type);
  //   const rate = cost.type == 'rpm' ? InputHelper.formatMoney2(`${cost.rpm ?? 0}`, '$') : `${cost.percentage}% transit cost`;
  //   const qty = `${cost.qty ?? ''}`;
  //   const total = InputHelper.formatMoney2(`${cost.total ?? 0}`, '$');
  //   return {type, rate, qty, total}
  // }

  // private buildDisplayVolumeDiscount(cost: Cost): DisplayVolumeDiscount {
  //   const type = cost.type == 'percentage' ? 'Percentage' : (cost.type == 'flatRate' ? 'Flat Rate' : cost.type);
  //   const rate = cost.type == 'flatRate' ? InputHelper.formatMoney2(`${cost.flatRate ?? 0}`, '$') : `${cost.percentage ?? 0}% transit cost`;
  //   const total = InputHelper.formatMoney2(`${cost.total ?? 0}`, '$');
  //   return {type, rate, total}
  // }

  // private buildDisplayServiceOption(options: ServiceOptionCost[]): DisplayServiceOption[] {
  //   return options.map(it => {
  //     const isNegative = MasterData.isServiceOptionTypeNegative(it.id);
  //     let rate = InputHelper.formatMoney2(`${it.rate ?? 0}`, '$');
  //     let total = InputHelper.formatMoney2(`${it.total ?? 0}`, '$')
  //     if (isNegative) {
  //       total = `-${total}`;
  //     }
  //     return {
  //       name: MasterData.getServiceOptionName(it),
  //       qty: `${it.qty ?? 0}`,
  //       rate, total
  //     };
  //   })
  // }

}