import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { DateUtil } from "@services/date-utils";
import { CarrierRatingIndicatorType, CarrierScore } from "@wearewarp/types/data-model/types/carrier-rating";
import { CarrierRatingMetadata } from "@wearewarp/types/data-model/types/carrier-rating/metadata";

interface IndicatorSetting {
  label: string;
  needDetailAllMonths: boolean;
  metadataKey: string;
  params?: string[];
  zeroWeightDesc?: string;
}

@Component({
  selector: 'detail-carrier-rating',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class DetailCarrierRatingDialog extends BaseDialog {
  @Input() carrierInfo: any;
  @Input() isBiddingScore: boolean = false;

  isLoading: boolean = false;
  dataRating: CarrierScore & {listMonthsDetail: CarrierRatingMetadata[]};
  dataDetailMonths: CarrierRatingMetadata[] = [];   // đã sắp xếp giảm dần theo collectInMonth
  expandSet = new Set<number>();

  indicatorSettings: {[key in CarrierRatingIndicatorType]: IndicatorSetting} = {
    'ontime_rate': { label: 'On Time Rate', needDetailAllMonths: true, metadataKey: 'ontimeRate', params: ['totalOnTime', 'totalCompleted'], zeroWeightDesc: 'Ignore when the number of completed routes is less than 10.' },
    'delivery_rating': { label: 'Delivery Rating', needDetailAllMonths: true, metadataKey: 'deliveryRating', params: ['totalDislike', 'totalCompleted'], zeroWeightDesc: 'Ignore when the number of completed routes is less than 10.' },
    'canceling_after_booked': { label: 'Canceling After Booked', needDetailAllMonths: true, metadataKey: 'canceledRate', params: ['totalCanceled', 'totalAssigned'], zeroWeightDesc: 'Ignore when the number of assigned routes is less than 10.' },
    'no_reply_booking_confirmation': { label: 'No Reply Booking Confirmation', needDetailAllMonths: true, metadataKey: 'noReplyBookingConfirmationRate', params: ['totalNoReply', 'totalAssigned'], zeroWeightDesc: 'Ignore when the number of assigned routes is less than 10.' },
    'document_verified': { label: 'Document Verified', needDetailAllMonths: false, metadataKey: 'documentVerified' },
    'highway_assessment': { label: 'Highway Assessment', needDetailAllMonths: false, metadataKey: 'highwayAssessment' },
    'margin': { label: 'Margin', needDetailAllMonths: false, metadataKey: 'margin' },
    'never_get_job': { label: 'Never Get Job', needDetailAllMonths: false, metadataKey: 'neverGetJob' },
  }

  get scoreTitle() {
    return this.isBiddingScore ? "Bidding Score" : "Carrier Score";
  }

  get requireDocuments() {
    return 'Auto Insurance, W-9 Document, Cargo Insurance';
  }

  get shouldWarningRecordNotFound() {
    return this.isBiddingScore && this.dataRating?.id === 'not_found';
  }
  get warningRecordNotFound() {
    return 'Not found score record for this carrier. Please click "Correct" to update the score.';
  }

  ngOnInit() {
    super.ngOnInit();
    this.fetchData();
  }

  onExpandChange(item) {
    if (!item) return;
    let type = item?.type;
    if (this.expandSet.has(type)) this.expandSet.delete(type);
    else this.expandSet.add(type);
  }

  fetchData() {
    let carrierId = this.carrierInfo?.id;
    if (!carrierId) {
      this.showErr("carrierId is missing");
      return;
    }
    this.isLoading = true;
    let url = `${Const.API_SERVICE_CARRIER_RATING}/carrier-rating/${carrierId}/detail-rating`;
    if (this.isBiddingScore) {
      url = `${url}?isBidding=true&margin=${this.carrierInfo?.margin || 0}`;
    }
    this.api.GET(url).subscribe(
      (resp) => {
        this.isLoading = false;
        this.dataRating = resp.data;
        this.dataDetailMonths = this.dataRating?.listMonthsDetail || [];
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
      }
    )
  }

  getIndicatorLabel(item) {
    return this.indicatorSettings[item?.type]?.label || '';
  }

  getScore() {
    return this.$roundNumer(this.dataRating?.score || 0);
  }

  showWeight(item) {
    return `${this.$roundNumer(item?.weight * 100)}%`;
  }

  showGrade(item) {
    return `${this.$roundNumer(item?.grade)} / 5.0`;
  }

  $roundNumer(num) {
    return num.toFixed(1) || 0;
  }

  needDetailAllMonths(item) {
    return this.indicatorSettings[item?.type]?.needDetailAllMonths || false;
  }

  getMonth(detailMonth) {
    return DateUtil.format(detailMonth?.collectInMonth, 'MMM YYYY');
  }

  isVerifiedDocument(item) {
    return this.dataDetailMonths?.[0]?.data?.documentVerified?.isVerified || false;
  }

  getHighWayStatus(item) {
    return this.dataDetailMonths?.[0]?.data?.highwayAssessment?.status || 'N/A';
  }
  highWayStatusColor(item) {
    let status = this.dataDetailMonths?.[0]?.data?.highwayAssessment?.status;
    switch (status) {
      case 'pass':
      case 'partial_pass':
        return 'success';
      default:
        return 'red';
    }
  }

  summaryIndicatorOnMonth(item, detailMonth) {
    let type = item?.type;
    let indicatorParams = this.indicatorSettings[type]?.params;
    if (!indicatorParams) return 'N/A';
    let metadataKey = this.indicatorSettings[type]?.metadataKey;
    let data = detailMonth?.data?.[metadataKey];
    return indicatorParams.map(param => `${param}: ${data?.[param]}`).join(' / ');
  }

  getFocusRouteOnMonth(item, detailMonth) {
    let type = item?.type;
    let metadataKey = this.indicatorSettings[type]?.metadataKey;
    return detailMonth?.data?.[metadataKey]?.metadata?.routes || [];
  }

  goToDispatch(jobId) {
    return `${Const.routeAdminDispatchList}/${jobId}`
  }

  isZeroWeight(item) {
    return !item?.weight ||  item?.weight === 0;
  }

  getZeroWeightTooltip(item) {
    return this.indicatorSettings[item?.type]?.zeroWeightDesc || '';
  }

  get shouldCorrectCarrierScore() {
    return this.isSysAdmin;
  }

  isLoadingCorrectScore: boolean = false;
  onCorrectCarrierScore() {
    this.isLoadingCorrectScore = true;
    const url = `${Const.API_SERVICE_CARRIER_RATING}/carrier-rating/${this.carrierInfo?.id}/correct-score`;
    this.api.POST(url, {}).subscribe(
      (resp) => {
        setTimeout(() => {
          this.showSuccess('Corrected successfully');
          this.isLoadingCorrectScore = false;
          this.fetchData();
        }, 2000); 
      },
      (err) => {
        this.isLoadingCorrectScore = false;
        this.showErr(err);
      }
    )
  }
}