import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { FormArray } from '@angular/forms';
import { FormInputCostV3 } from "../../forms/input-cost-v3";
import { MasterData } from "@services/master.data";
import { Observable } from "rxjs";
import { Utils } from "@services/utils";
import { InputHelper } from "@services/input-helper";
import { BizUtil } from "@services/biz";
import { FormDataEditCost } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { ModelRevenueDetail } from "@app/interfaces/order";
import { ShipmentEntryMode } from "@wearewarp/types";
import { WarpId } from "@wearewarp/universal-libs";

@Component({
  selector: 'edit-shipment-entry-revenue',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class EditShipmentEntryRevenue extends BaseFormDialog1<FormDataEditCost> {

  @Input() selectLocations;
  @Input() shipments;
  @Input() order;
  @Input() hasInvoice = false;
  @Input() shipmentEntryMode;
  @Input() createShipmentMode: ShipmentEntryMode = Const.ShipmentEntryMode.single;
  @Input() onSave: (data: FormDataEditCost) => Observable<any>;
  @Input() onRefreshDetailOrder: () => void;
  @Input() waitingForData: (() => Observable<ModelRevenueDetail>) | undefined;
  public orderCode;
  public isWaitingForData = false;
  get errorMessage(): string {
    if (this.isWaitingForData === true || this.isWaitingForData === false) {
      return '';
    }
    return Utils.getErrorString(this.isWaitingForData);
  }

  totalRevenueMultiShipments = 0;
  revenueTypeArr = [
    { value: Const.CostLevel.shipment, label: 'Per Shipment Revenue' },
    { value: Const.CostLevel.order, label: 'Batch Revenue' }
  ]

  protected formGroupDeclaration: FormGroupDeclaration = {
    type: { label: '' },
    costOrder: { label: '', type: 'formGroup', childItem: FormInputCostV3.declaration },
    costShipments: {
      label: '', type: 'formArray', initialValue: [{}], childItem: {
        shipmentId: { label: '' },
        shipmentWarpId: { label: '' },
        shipmentCode: { label: '' },
        cost: { label: '', type: 'formGroup', childItem: FormInputCostV3.declaration }
      }
    }
  }


  ngOnInit(): void {
    if (this.waitingForData) {
      this.isWaitingForData = true;
      this.waitingForData().subscribe((data: ModelRevenueDetail) => {
        this.isWaitingForData = false;
        this.model = data as any;
        this.hasInvoice = !!data.hasInvoice;
        this.orderCode = data.orderCode;
        this.shipmentEntryMode = data.shipmentEntryMode;
        this.createShipmentMode = data.createShipmentMode;
        this.selectLocations = data.selectLocations;
        this.shipments = data.shipments;
        this.createFormInput(this.model);
        this.setEnableFormGroup(true); // make sure all readOnly fields will be disabled after created.
      }, err => {
        this.isWaitingForData = err;
      });
    } else {
      super.ngOnInit();
      this.orderCode = this.order ? WarpId.showOrderCode(this.order) : '';
    }
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  serviceOptionsForCostSection = [
    { label: 'Additional services', items: MasterData.ShipmentServiceOptionsAddition },
    { label: 'Pickup services', items: MasterData.ShipmentServiceOptionsPickup },
    { label: 'Delivery services', items: MasterData.ShipmentServiceOptionsDelivery },
  ];

  get isCreateShipmentMode_multiPickDrop() {
    return this.shipmentEntryMode == Const.ShipmentEntryMode.multiPickDrop || this.shipmentEntryMode == 'multiPickDrop_LTL';
  }

  get isPerShipmentRevenueType() {
    let revenueType = this.getItemValue('type');
    return revenueType === Const.CostLevel.shipment;
  }

  onBtnSave() {
    if (!this.needUpdate) return;
    if (this.hasInvoice) {
      this.confirmYesNo(`Order ${this.showOrderCode(this.order)} has already had invoice generated. Do you really want to update the revenue?`, () => {
        this.onSaveData();
      })
    } else {
      this.onSaveData();
    }
  }

  onSaveData() {
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onRefreshDetailOrder();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

  protected getFormData_JSON(isCreateNew: boolean): FormDataEditCost {
    // trường hợp BatchShipment MULTIPLE có  RevenueType = perShipmentRevenue, 
    // vì sử dụng formInputCost dạng SINGLE nên cần update lại deliveryId cho serviceOption
    let data = super.getFormData_JSON(isCreateNew);
    if (this.shipmentEntryMode == Const.ShipmentEntryMode.multiPickDrop && data.type == Const.CostLevel.shipment) {
      for (let costShipment of data?.costShipments || []) {
        const shipmentId = costShipment.shipmentId;
        const shipment = this.shipments?.filter(item => item.id == shipmentId)?.[0];
        if (!shipment) continue;
        const pickInfo = shipment.pickInfo;
        const dropInfo = shipment.dropInfo;
        for (let serviceOption of costShipment?.cost?.serviceOptions || []) {
          let currentService = MasterData.getServiceOptionById(serviceOption._id);
          if (currentService.type == 'pickup') {
            serviceOption.deliveryId = pickInfo.id;
          } else if (currentService.type == 'delivery') {
            serviceOption.deliveryId = dropInfo.id;
          }
        }
      }
    }

    // chuyển tất cả rate về chuẩn USD
    if (data?.costOrder && data?.costOrder?.currency?.type && data?.costOrder?.currency?.type != Const.CurrencyConfig.USD.value) {
      data.costOrder = BizUtil.convertCostToUSD(data.costOrder);
    }
    for (let costShipment of data?.costShipments || []) {
      if (costShipment.cost && costShipment.cost.currency?.type && costShipment.cost.currency?.type != Const.CurrencyConfig.USD.value) {
        costShipment.cost = BizUtil.convertCostToUSD(costShipment.cost);
      }
    }
    return data;
  }

  // dùng để update TotalRenveue trong trường hợp BatchShipmentType.MULTIPLE
  updateTotalRevenueMultiShipments() {
    if (this.shipmentEntryMode != Const.ShipmentEntryMode.multiPickDrop && this.shipmentEntryMode != "multiPickDrop_LTL") return;
    let data = this.getFormData_JSON(true);
    let totalRevenue = 0;
    for (let costShipment of data?.costShipments || []) {
      let grandTotal = costShipment?.cost?.currency?.type == 'USD' ? costShipment?.cost?.grandTotal : costShipment?.cost?.usdConversion;
      if (Utils.isNumber(grandTotal)) totalRevenue += grandTotal;
    }
    setTimeout(() => this.totalRevenueMultiShipments = totalRevenue, 1);
  }

  get totalRevenueAllShipments() {
    return InputHelper.formatMoney2(`${this.totalRevenueMultiShipments}`);
  }

  getShipmentWarpId(key, costIndex) {
    return this.getItemValue(`${key}[${costIndex}]`)?.shipmentCode;
  }

  getFormShipmentCost(key, costIndex) {
    let fg = (<FormArray>this.formInput.get(key))?.at(costIndex);
    return fg?.get('cost');
  }

}
