<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

<div *ngIf="!isLoading" class="bottom20" style="min-height: 250px;">
  <!-- warp info -->
  <div *ngIf="accountInfo" class="bottom20">
    <div class="medium bottom5">WARP Carrier Information</div>
    <div class="carrier-info-item">
      <div class="carrier-info-label">Name: </div>
      <div>{{ accountInfo.basicInfo?.name || 'N/A' }}</div>
    </div>
    <div class="carrier-info-item">
      <div class="carrier-info-label">MC: </div>
      <div>{{ accountInfo.basicInfo?.mc || 'N/A' }}</div>
    </div>
    <div class="carrier-info-item">
      <div class="carrier-info-label">DOT: </div>
      <div>{{ accountInfo.basicInfo?.dot || 'N/A' }}</div>
    </div>
    <div class="carrier-info-item">
      <div class="carrier-info-label">Address: </div>
      <div>{{ getAddressText(accountInfo.basicInfo?.address) || 'N/A' }}</div>
    </div>
    <div class="carrier-info-item">
      <div class="carrier-info-label">Website: </div>
      <div>{{ accountInfo.basicInfo?.website || 'N/A' }}</div>
    </div>
  </div>

  <!-- Form create denim contractor -->
  <form nz-form [formGroup]="formInput" class="form-detail" nzLayout="vertical">
    <div class="f15 medium bottom10">Denim Form</div>
    <div *ngFor="let group of groups">
      <ng-container *ngIf="group.type === 'group'">
          <form nz-form [formGroupName]="group.key" *ngIf="shouldShowGroup(group.key)">
            <div *ngFor="let row of group.rows">
              <div nz-row [nzGutter]="16" class="bottom10">
                <div nz-col *ngFor="let key of row.keys" [nzSpan]="row.span">
                  <div class="form-item-label">{{ getLabel(fullKey(group.key, key)) }}<span *ngIf="isRequired(fullKey(group.key, key))" class="label-mark-required"></span></div>
                  <ng-container [ngSwitch]="key">
                    <!-- Country -->
                    <ng-container *ngSwitchCase="'countryAlpha2Code'">
                      <nz-form-item>
                        <nz-form-control>
                          <nz-select [formControlName]="key" nzShowSearch nzBackdrop="true">
                            <nz-option *ngFor="let item of countriesArr" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
                          </nz-select>
                        </nz-form-control>
                      </nz-form-item>
                    </ng-container>

                    <!-- State -->
                    <ng-container *ngSwitchCase="'state'">
                      <nz-form-item>
                        <nz-form-control>
                          <nz-select [formControlName]="key" nzShowSearch nzBackdrop="true">
                            <nz-option *ngFor="let state of allStates" [nzLabel]="getStateDesc(state)" [nzValue]="state.code"></nz-option>
                          </nz-select>
                        </nz-form-control>
                      </nz-form-item>
                    </ng-container>

                    <!-- factorCompany -->
                    <ng-container *ngSwitchCase="'factorCompanyId'">
                      <nz-form-item>
                        <nz-form-control>
                          <nz-select [formControlName]="key" nzShowSearch nzBackdrop="true">
                            <nz-option *ngFor="let item of factorCompanies" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
                          </nz-select>
                        </nz-form-control>
                      </nz-form-item>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                      <input nz-input 
                        [formControlName]="key"
                        [type]="getInputType(fullKey(group.key, key))"
                        (input)="onInputChanged($event, fullKey(group.key, key))"
                      />
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </form>
      </ng-container>

      <ng-container *ngIf="group.type === 'single'">
        <div *ngIf="group.key === 'hasFactoring'">
          <div class="form-item-label">{{ getLabel(group.key) }}<span *ngIf="isRequired(group.key)" class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
              <nz-radio-group [formControlName]="group.key" (ngModelChange)="onChange('hasFactoring', $event)">
                <label nz-radio [nzValue]="true">Yes</label>
                <label nz-radio [nzValue]="false">No</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>
    </div>

    <!-- xử lý riêng dtp setting -->
    <div *ngIf="'dtpSetting' as groupKey" class="top10">
      <form nz-form [formGroupName]="groupKey">
        <div class="form-item-label bottom10">{{ getLabel(groupKey) }}</div>
        <div *ngFor="let key of ['type']">
          <nz-radio-group [formControlName]="key"
            (ngModelChange)="onChange('dtpSetting.type', $event)"
            style="width: 100%;"
          >
            <div nz-radio [nzValue]="'default'" class="full-width small-text">
              <span class="medium">Default Terms</span>
              <div>Payment date on TMS imports will be retained depending on 'Days to Payment' default setting.</div>
            </div>
            <!-- <div nz-radio [nzValue]="'custom'" class="full-width small-text">
              <span class="medium">Custom Terms</span>
              <div>This selection will override the 'Days to Payment' information on TMS imports.</div>
              <div *ngIf="isCustomTerms" nz-row [nzGutter]="16" class="top10">
                <div *ngFor="let childKey of ['daysToPayOffset']" nz-col [nzSpan]="12">
                  <div class="form-item-label">{{ getLabel(fullKey(groupKey, childKey)) }}<span *ngIf="isRequired(fullKey(groupKey, childKey))" class="label-mark-required"></span></div>
                  <nz-form-item>
                    <nz-form-control>
                      <input nz-input [formControlName]="childKey" [placeholder]="getPlaceHolder(fullKey(groupKey, childKey))"/>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div *ngIf="!isHasFactoring" nz-col [nzSpan]="12">
                  <div *ngFor="let childKey of ['quickPayRate']">
                    <div class="form-item-label">{{ getLabel(fullKey(groupKey, childKey)) }}<span *ngIf="isRequired(fullKey(groupKey, childKey))" class="label-mark-required"></span></div>
                    <nz-form-item>
                      <nz-form-control>
                        <input nz-input [formControlName]="childKey" [placeholder]="getPlaceHolder(fullKey(groupKey, childKey))"/>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </div> -->
          </nz-radio-group>
        </div>
      </form>
    </div>

    <div class="bottom20 top20 flex" style="flex-direction: row-reverse;">
      <button nz-button 
        nzType="primary" 
        [nzLoading]="onProgress"
        [disabled]="!needUpdate || onProgress"
        (click)="onBtnCreateContractor()"
      >Create Contractor</button>
    </div>
  </form>
</div>