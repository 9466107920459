import { Component, Input } from '@angular/core';
import { StopItem, Unit } from '../../models/types';
import { InputHelper } from '@services/input-helper';

@Component({
    selector: 'peak-info',
    templateUrl: './index.html',
    styleUrls: ['./style.scss']
})
export class PeakInfoComponent {
    @Input() peakInfo: StopItem | null = null;
    @Input() peakIndex: number = -1;

    private formatNumber(item: number) {
        return InputHelper._formatMoney(item, 0).replace(/,/g, '.');
    }

    private getTextPallet(units: Unit[], weight: number) {
        const texts = units.map(obj => {
            const qtyUnitText = obj.qty <= 1 
                ? (obj.qtyUnit || 'N/A')
                : (obj.qtyUnit || 'N/A') + 's';
            return `${obj.qty} ${qtyUnitText}`;
        });

        return `${texts.length ? texts.join(', ') : '0 Pallet'}: ${this.formatNumber(weight)} Lbs`;
    }

    public getPeakText() {
        return this.getTextPallet(
            this.peakInfo?.units || [], 
            this.peakInfo?.weight || 0
        );
    }
} 