import { Component, Input, OnInit } from '@angular/core';
import { Const } from '@const/Const';
import { BaseDialog } from '@dialogs/base-dlg';

@Component({
  selector: 'raw-data-orderful',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class RawDataOrderfulComponent extends BaseDialog {
  @Input() orderId: string;

  public isLoading: boolean = false;
  public type: 'json' | 'raw' = 'json';
  public json: any;
  public raw: any;
  constructor() {
    super();
  }

  get noData() {
    return !this.json && !this.raw;
  }

  ngOnInit(): void {
    this.getData();
  }

  private getData() {
    if(!this.orderId) return;
    this.isLoading = true;
    let url = `${Const.APIV2(Const.APIURI_ORDERS)}/${this.orderId}/payload-edi`;
    this.api.GET(url).subscribe({
      next: (res) => {
        console.log(res)
        this.isLoading = false;
        this.json = res?.data?.json;
        this.raw = res?.data?.raw?.[0];
      },
      error: (err) => {
        this.isLoading = false;
        this.showErr(err)
      }
    })
  }

  download() {
    if(this.type === 'json') {
      let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.json, null, 2));
      let downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href",     dataStr);
      downloadAnchorNode.setAttribute("download", Date.now() + ".json");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    } else {
      let dataStr = "data:text;charset=utf-8," + encodeURIComponent(this.raw);
      let downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href",     dataStr);
      downloadAnchorNode.setAttribute("download", Date.now() + ".txt");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    }
  }
}