import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Const } from "@const/Const";
import { Log } from "@services/log";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Utils } from "@services/utils";

// Select client by typing 2 letters for searching
// 2022/03/01 Apply for client and carrier
// https://blog.angular-university.io/angular-custom-form-controls/
@Component({
  selector: 'select-by-searching',
  templateUrl: './select-search.html',
  styleUrls: ['./style.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SelectClientBySearching
    }
  ]
})
export class SelectClientBySearching extends BaseComponent implements ControlValueAccessor {

  // version 1: use _id
  // versino 2: use id
  @Input() version = 1;

  @Input() type: 'client'|'carrier'|'warehouse'|'user' = 'client';
  @Input() placeholder: string = '';
  @Input() isDisabled: boolean = false;
  @Input() dropdownClassName;
  @Input() nzMode: string = 'default';
  @Input() nzSize: string = 'default';
  @Input() nzMaxTagCount: number | undefined = undefined;
  @Input() showSubClient: boolean = false;
  @Input() showAddrWarehouse: boolean = false;
  @Input() emitAllItemInfo: boolean = false; // Dùng khi muốn emit toàn bộ thông tin item
  @Input() apiUrl: string = ''; // Dùng khi muốn custom api for searching
  private _listData: Array<any> = [];
  @Input() get listData(): Array<any> {return this._listData}
  set listData(value: Array<any>) {
    this._listData = value;
    this.listDataChange.emit(value);
  }
  @Output() listDataChange = new EventEmitter<Array<any>>();
  @Output() modelChange = new EventEmitter<any>();
  @Output() onFocus = new EventEmitter<any>();

  showHint = true;
  isLoadData = false;

  // At the beginning, the number of clients is low, so fetch all clients once, no need to type 2 letter for searching
  // Only require type 2 letter for searching when the number of clients is very high (maybe more than 1000)
  @Input() getAllAtOnce = true;

  get isServerSearch() {return !this.getAllAtOnce}

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.getAllAtOnce) {
      this.fetchListData();
    }
  }

  getItemValue(item) {
    if (this.emitAllItemInfo) return item;
    switch (this.version) {
      case 1: return item._id;
      case 2: return item.id;
      default: return item._id;
    }
  }

  private getApiUrl(): string {
    if (this.apiUrl) {
      return this.apiUrl;
    }
    switch (this.type) {
      case 'carrier': return Const.APIURI_CARRIERS;
      case 'client': return Const.APIURI_CLIENTS;
      case 'warehouse': return Const.APIURI_WAREHOUSES;
      case 'user': return Const.APIURI_USERS;
      default: throw Error(`Unknown type ${this.type}`);
    }
  }

  private fetchListData(keyword: string = null) {
    const query = {limit: -1}
    if (keyword) {
      query['search'] = keyword;
      query['search_mt'] = Const.API_SEARCH_START_WITH;
    }
    let url = Utils.appendQueryStringIntoUrl(this.getApiUrl(), query);
    // let url = `${this.getApiUrl()}?limit=-1`;
    // if(this.conditions){
    //   url += `&${this.conditions}`
    // }
    // if (keyword) {
    //   url += `&search=${keyword}&search_mt=${Const.API_SEARCH_START_WITH}`;
    // }
    this.isLoadData = true;
    this.showHint = false;
    this.api.GET(url).subscribe(
      resp => {
        let list: any[];
        if(this.type == 'client' && this.showSubClient){
          list = this.buildClientListWithSubs(resp.data.list_data);
        } else {
          list = resp.data.list_data || [];
        }
        this.listData = list;
        this.isLoadData = false;
      }, err => {
        this.showErr(err);
        this.isLoadData = false;
      }
    );
  }
  private buildClientListWithSubs(list){
    let clientList = [];
    list?.forEach(item => {
      clientList.push(item);
      if(item.metadata?.subClients){
        let subList = item.metadata?.subClients || [];
        subList.forEach(sub=>{
          sub.metadata = { parentClient: { id: item.id, name: item.name } };
        });
        clientList.push(...subList);
      }
    });
    return clientList;
  }

  onSearch(value: string) {
    if (this.isLoadData || this.isDisabled || !this.isServerSearch) {
      return;
    }
    if (value && value.length > 1) {
      this.fetchListData(value);
    } else {
      this.listData = [];
      this.showHint = true;
    }
  }

  getName(item) {
    if (this.type == 'user') return this.getFullName(item);
    if (this.type == 'carrier') return item?.basicInfo?.name;
    if (this.type == 'client' && this.showSubClient){
      if(item.metadata?.parentClient){
        return `${item.name} [${item.metadata?.parentClient?.name}]`;
      }
    }
    if (this.type == 'warehouse' && this.showAddrWarehouse) {
      let arr = [], address = [];
      if (item?.name) arr.push(item.name);
      if (item?.pickAddr?.city) address.push(item.pickAddr.city);
      if (item?.pickAddr?.state) address.push(item.pickAddr.state);
      if (item?.pickAddr?.zipcode) address.push(item.pickAddr.zipcode);
      arr.push(address.join(', '));
      return arr.join(" - ");
    }
    return item.name;
  }

  onModelChange(itemId) {
    this.markAsTouched();
    // let data;
    // for (let item of this.listData) {
    //   if (this.getItemValue(item) == itemId) {
    //     data = item;
    //   }
    // }
    this.onChange(itemId);
    this.modelChange.emit(itemId);
  }
  onModalFocus() {
    this.onFocus.emit();
  }

  value;
  onTouched = () => {};
  onChange = (value) => {};
  touched = false;

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }
}
