import { Const } from "@const/Const";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { ApiService } from "@services/api.service";
import { map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { DialogService } from "@dialogs/dialog.service";
import { EditShipmentEntryRevenue } from "../revenue";
import { ModelOrderDetail, ModelRevenueDetail, ModelShipmentCostDetail } from "@app/interfaces/order";
import { BizUtil } from "@services/biz";
import { buildShipmentCostMissingInfo } from "@wearewarp/js-const-finance";
import { WarpId } from "@wearewarp/universal-libs";
import { FormDataEditCost } from "@wearewarp/types-server-admin/form-data/shipment-entry";


export class OrderCostHelper {
  public static openModalOrderCost(input) {
    new OrderCostHelper().showModalOrderCost(input);
  }

  private showModalOrderCost(input) {
    const orderId = input.orderId;
    DialogService.openFormDialog1(EditShipmentEntryRevenue, {
      nzComponentParams: {
        closeOnSuccess: true,
        waitingForData: () => {
          return this.getDataForOrderCost(orderId);
        },
        onSave: (data) => this.saveCost(orderId, data),
        onRefreshDetailOrder: () => input?.onSuccess?.()
      },
      nzClassName: "modal-xl",
    });
  }

  private saveCost(orderId, data) {
    let costOrderData: FormDataEditCost;
    const revenueType = data.type;
    if (revenueType == Const.CostLevel.order) {
      // lưu vào order
      costOrderData = {
        type: Const.CostLevel.order,
        costOrder: data.costOrder,
        costShipments: [],
      }
    } else if (revenueType == Const.CostLevel.shipment) {
      // lưu vào shipment
      costOrderData = {
        type: Const.CostLevel.shipment,
        costOrder: {},
        costShipments: data.costShipments,
      }
    }
    const url = Const.APIV2(`${Const.APIURI_ORDERS}/${orderId}/cost`);
    return ApiService.instance.PUT(url, costOrderData);
  }

  private getDataForOrderCost(orderId: string): Observable<ModelRevenueDetail> {
    const data: ModelRevenueDetail = {};
    if (!orderId) { return of(data); }
    const url = `${Const.APIV2('orders')}/${orderId}`
    return ApiService.instance.GET(url).pipe(map(resp => {
      if (resp?.data) {
        return this.getModelRevenueDetailFromOrder(resp.data)
      } else { return data }
    }));
  }

  private getModelRevenueDetailFromOrder(modelOrder: ModelOrderDetail): ModelRevenueDetail {
    const currencies = Object.values(Const.CurrencyConfig);
    switch(<string>modelOrder.shipmentEntryMode) {
      case Const.ShipmentEntryMode.single: {
        const shipment = modelOrder.metadata?.shipments?.[0];
        if (shipment) {
          let cost: ModelShipmentCostDetail = { ...modelOrder.cost };
          let isUseCostOrder: boolean = true;
          if (modelOrder.settings?.usingCostLevel) {
            isUseCostOrder = modelOrder.settings.usingCostLevel === WarpConst.CostLevel.order;
          } else if (modelOrder.shipmentType != Const.ShipmentTypes.fullTruckLoad) {
            // dùng khi bị thiếu settings
            isUseCostOrder = false;
          }
          if (!isUseCostOrder) {
            cost = { ...(shipment.cost || {}) };
          }
          if (cost?.currency?.type && cost?.currency.type != 'USD') {
            cost = BizUtil.convertCostFromUSDToOrther({...cost});
          }
          cost = buildShipmentCostMissingInfo(cost);
          cost.symbol = '';
          for (let currency of currencies) {
            if (cost.currency?.type == currency.value) cost.symbol = currency.prefix;
          }
          const deliveryInfos = shipment.deliveryInfos || [];
          const pickInfo = deliveryInfos.filter(item => item.type == Const.TaskType.PICKUP)[0];
          const dropInfo = deliveryInfos.filter(item => item.type == Const.TaskType.DROPOFF)[0];
          const selectLocations = [
            { deliveryId: pickInfo?.id, type: Const.TaskType.PICKUP, label: 'Pickup Location 1'},
            { deliveryId: dropInfo?.id, type: Const.TaskType.DROPOFF, label: 'Delivery Location 1'},
          ]
          return {
            clientId: shipment.clientId,
            shipmentId: shipment.id,
            orderId: modelOrder.id,
            selectLocations,
            shipments : [],
            shipmentEntryMode: modelOrder.shipmentEntryMode,
            createShipmentMode: Const.ShipmentEntryMode.single,
            type: isUseCostOrder ? Const.CostLevel.order : Const.CostLevel.shipment,
            costOrder: isUseCostOrder ? cost : {},
            costShipments: [ {
              shipmentId: shipment.id, 
              shipmentWarpId: shipment.warpId, 
              cost: isUseCostOrder ? {} : cost, 
              shipmentCode: WarpId.showShipmentCode(shipment)} ],
            isUseCostOrder,
            orderCode: WarpId.showOrderCode(modelOrder),
            hasInvoice: !!modelOrder?.invoiceSent?.when
          }
        }
        break;
      }
      case Const.ShipmentEntryMode.multiDrop:
      case Const.ShipmentEntryMode.multiPick: {
        let cost: ModelShipmentCostDetail = { ...modelOrder.cost };
        if (cost?.currency?.type && cost?.currency.type != 'USD') {
          cost = BizUtil.convertCostFromUSDToOrther({...cost});
        }
        cost = buildShipmentCostMissingInfo(cost);
        cost.symbol = '';
        for (let currency of currencies) {
          if (cost.currency?.type == currency.value) cost.symbol = currency.prefix;
        }
        const clientId = modelOrder.clientIds?.[0];
        let selectLocations = [];
        const shipments = modelOrder.metadata?.shipments || [];
        let pickInfos = [];
        let dropInfos = [];
        for (let shipment of shipments) {
          for (let info of shipment.deliveryInfos || []) {
            if (info.type == Const.TaskType.PICKUP) pickInfos.push(info);
            if (info.type == Const.TaskType.DROPOFF) dropInfos.push(info);
          }
        }
        if (modelOrder.shipmentEntryMode == Const.ShipmentEntryMode.multiDrop) {
          // Vì server vẫn đang giữ là dummy_id nên ở đây hardcode là dummy_id
          selectLocations.push({deliveryId: 'dummy_id', type: Const.TaskType.PICKUP, label: `Pickup Location 1`});
          for (let i=0;i <dropInfos.length; i++) {
            selectLocations.push({deliveryId: dropInfos[i].id, type: Const.TaskType.DROPOFF, label: `Delivery Location ${i+1}`});
          }
        } else if (modelOrder.shipmentEntryMode == Const.ShipmentEntryMode.multiPick) {
          for (let i=0;i <pickInfos.length; i++) {
            selectLocations.push({deliveryId: pickInfos[i].id, type: Const.TaskType.PICKUP, label: `Pickup Location ${i+1}`});
          }
          // Vì server vẫn đang giữ là dummy_id nên ở đây hardcode là dummy_id
          selectLocations.push({deliveryId: 'dummy_id', type: Const.TaskType.DROPOFF, label: `Delivery Location 1`});
        }
        return {
          clientId,
          orderId: modelOrder.id,
          selectLocations,
          shipments: [],
          shipmentEntryMode: modelOrder.shipmentEntryMode,
          createShipmentMode: modelOrder.shipmentEntryMode,
          type: Const.CostLevel.order,
          costOrder: cost,
          costShipments: [],
          isUseCostOrder: true,
          orderCode: WarpId.showOrderCode(modelOrder),
          hasInvoice: !!modelOrder?.invoiceSent?.when,
        }
        break;
      }
      case 'multiPickDrop_LTL':
      case Const.ShipmentEntryMode.multiPickDrop: {
        // hiện tại mới chỉ support 1 client nên lấy clientId đầu tiên
        const clientId = modelOrder.clientIds?.[0];
        const costOrder = modelOrder.cost;
        const shipments: any[] = modelOrder.metadata?.shipments || [];
        const selectLocations = [
          { deliveryId: "PICKUP_1", type: Const.TaskType.PICKUP, label: 'Pickup Location 1'},
          { deliveryId: "DROPOFF_1", type: Const.TaskType.DROPOFF, label: 'Delivery Location 1'},
        ]
        let newFormatShipments = shipments.map((sh, index) => {
          const deliveryInfos = sh.deliveryInfos || [];
          const pickInfo = deliveryInfos.filter(item => item.type == Const.TaskType.PICKUP)[0];
          const dropInfo = deliveryInfos.filter(item => item.type == Const.TaskType.DROPOFF)[0];
          return { index, id: sh.id, pickInfo, dropInfo }
        });
        let isUseCostOrder: boolean = true;
        if (modelOrder.settings?.usingCostLevel) {
          isUseCostOrder = modelOrder.settings.usingCostLevel === WarpConst.CostLevel.order;
        } else {
          // dùng khi bị thiếu settings
          if (!costOrder) { isUseCostOrder = false; } 
          else {
            for (let sh of shipments) {
              if (sh.cost) isUseCostOrder = false;
            }
          }
        }
        if (!isUseCostOrder) {
          // đây là trường hợp revenueType == perShipmentRevenue
          const costShipments = shipments.map(sh => { 
            let costFormat = { ...(sh.cost || {}) };
            if (costFormat?.currency?.type && costFormat?.currency.type != 'USD') {
              costFormat = BizUtil.convertCostFromUSDToOrther(costFormat);
            }
            costFormat = buildShipmentCostMissingInfo(costFormat);
            costFormat.symbol = '';
            for (let currency of currencies) {
              if (costFormat.currency?.type == currency.value) costFormat.symbol = currency.prefix;
            }
            return { shipmentId: sh.id, shipmentWarpId: sh.warpId, cost: costFormat, shipmentCode: WarpId.showShipmentCode(sh) }
          });
          return {
            clientId,
            orderId: modelOrder.id,
            selectLocations: selectLocations,
            shipments: newFormatShipments,
            shipmentEntryMode: modelOrder.shipmentEntryMode,
            createShipmentMode: modelOrder.shipmentEntryMode,
            type: Const.CostLevel.shipment,
            costOrder: {},
            costShipments: costShipments,
            isUseCostOrder: false,
            orderCode: WarpId.showOrderCode(modelOrder),
            hasInvoice: !!modelOrder?.invoiceSent?.when,
          }
        } else {
          // đây là trường hợp revenueType == batchRevenue
          let costOrderFormat: ModelShipmentCostDetail = { ...(costOrder || {}) }
          if (costOrderFormat?.currency?.type && costOrderFormat?.currency.type != 'USD') {
            costOrderFormat = BizUtil.convertCostFromUSDToOrther(costOrderFormat);
          }
          costOrderFormat = buildShipmentCostMissingInfo(costOrderFormat);
          costOrderFormat.symbol = '';
          for (let currency of currencies) {
            if (costOrderFormat.currency?.type == currency.value) costOrderFormat.symbol = currency.prefix;
          }
          const costShipments = shipments.map(sh => {
            return { shipmentId: sh.id, shipmentWarpId: sh.warpId, cost: {}, shipmentCode: WarpId.showShipmentCode(sh) }
          });
          return {
            clientId: clientId,
            orderId: modelOrder.id,
            selectLocations: selectLocations,
            shipments: newFormatShipments,
            shipmentEntryMode: modelOrder.shipmentEntryMode,
            createShipmentMode: modelOrder.shipmentEntryMode,
            type: Const.CostLevel.order,
            costOrder: costOrderFormat,
            costShipments: costShipments,
            isUseCostOrder: true,
            orderCode: WarpId.showOrderCode(modelOrder),
            hasInvoice: !!modelOrder?.invoiceSent?.when,
          }
        }
        break;
      }
    }
  }

}