<div *ngIf="isLoading" class="nodata" style="flex-direction: column;">
    <div style="margin-bottom: 20px;">Preparing data, please wait a little bit...</div>
    <i nz-icon nzTheme="outline" nzType="loading"></i>
</div>
<div *ngIf="!isLoading && noData" class="nodata">
    <div style="margin-bottom: 20px;">No Data</div>
</div>
<div *ngIf="!isLoading && !noData" class="content">
    <div class="flex-space-between">
        <nz-radio-group [(ngModel)]="type" nzButtonStyle="solid">
            <label nz-radio-button nzValue="json">JSON</label>
            <label nz-radio-button nzValue="raw">Raw</label>
        </nz-radio-group>
        <button nz-button (click)="download()">
            <i nz-icon nzType="download" nzTheme="outline"></i>
        </button>
    </div>
    
    <div *ngIf="type === 'json'" style="height: 500px; overflow: scroll; background: #f9f9f9; border: 1px solid #00000012; padding: 10px; border-radius: 4px">
        <ng-container *ngIf="!json">
            <div style="margin-bottom: 20px;">No Data</div>
        </ng-container>
        <ng-container *ngIf="json">
            <ngx-json-viewer style="display: block;" [expanded]="true" [json]="json"></ngx-json-viewer>
        </ng-container>
    </div>
    <div *ngIf="type === 'raw'" style="height: 500px; overflow: scroll; background: #f9f9f9; border: 1px solid #00000012; padding: 10px; border-radius: 4px">
       <code style="white-space: pre-wrap;">{{raw}}</code>
    </div>
</div>
