import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { SocketService } from "..";
import { SocketEvent } from "@app/enum";
import { EventSocket_JobFollow } from "@wearewarp/types/event";


@Injectable({providedIn: 'root'})
export class RouteEvent {
  private routeIds_forNotes = new Set<string>();
  private subject_forNotes = new Subject<void>();


  constructor(private socket: SocketService) {
    this.socket.subscribeConnectionStatus(isConnected => {
      if (isConnected) {
        this.onSocketConnected();
      }
    })
  }

  private onSocketConnected() {
    for (let id of this.routeIds_forNotes) {
      this.followRouteNotes(id);
    }
  }

  private followRouteNotes(routeId: string) {
    this.socket.emit<EventSocket_JobFollow>(SocketEvent.jobNotesFollow, {data: {jobId: routeId}});
  }

  subscribeRouteNotes(routeId: string, next: () => void): Subscription {
    const sub = new Subscription();
    sub.add(this.socket.subscribeEvent(SocketEvent.jobNotesUpdate, data => this.subject_forNotes.next()));
    sub.add(this.subject_forNotes.subscribe(next));
    this.routeIds_forNotes.add(routeId);
    this.followRouteNotes(routeId);
    return sub;
  }
}