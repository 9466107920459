
<form nz-form *ngIf="formInput" [formGroup]="formInput" class="form-detail" nzLayout="vertical">
  <!--  Shipment Detail-->
  <nz-row>
    <nz-col [nzMd]="24">
      <div class="header-title">
        Report Carrier
      </div>
      <div class="route-title">
        {{jobHyperLink.hyperLinkText}}
      </div>
      <div class="form-container">
        <div *ngFor="let key of ['reasons']" class="form-item">
          <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
              class="label-mark-required"></span>
            </div>
          <nz-checkbox-group [formControlName]="key"></nz-checkbox-group>
        </div>
      </div>
    </nz-col>
  </nz-row>
</form>