import { Component, Input, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { Log } from "@services/log";
import { FormEditShipmentBatchMultiPick } from "./multi-pick";
import { FormEditShipmentBatchMultiDrop } from "./multi-drop";
import { FormEditShipmentBatchMultiPickDrop } from "./multi-pick-drop";
import { AddRemoveStopsService } from "./add-remove-stops-service";
import { FormDataAddRemoveStops } from "./interface";
import { ResponseAdminOrderDetail } from "@wearewarp/types-server-admin";
import { Utils } from "@services/utils";

@Component({
  selector: 'edit-shipment-entry-add-remove-stops',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class EditShipmentEntryAddRemoveStops extends BaseDialog {

  @ViewChild('formMultiPick') formMultiPick: FormEditShipmentBatchMultiPick;
  @ViewChild('formMultiDrop') formMultiDrop: FormEditShipmentBatchMultiDrop;
  @ViewChild('formMultiPickDrop') formMultiPickDrop: FormEditShipmentBatchMultiPickDrop;

  onOk: () => void;
  @Input() currentShipmentEntryMode;
  @Input() targetShipmentEntryMode;
  @Input() modelOrder: ResponseAdminOrderDetail;
  onProgress = false;
  locations = [];
  originLocations = [];
  dataStops: FormDataAddRemoveStops;
  newSortedTaskIds = [];

  ngOnInit(): void {
    super.ngOnInit();
    this.locations = AddRemoveStopsService.getListLocation_FromModelOrder(this.modelOrder);
    this.originLocations = [ ... this.locations ];
    Log.d('originLocations - ForSort:',this.originLocations);
    this.updateDataLatest();
  }

  async onBtnSave() {
    if (!this.canUpdate) return;
    this.updateDataLatest();
    Log.d('NewDeliveryInfos:',this.dataStops);
    Log.d('newSortedTaskIds:',this.newSortedTaskIds);
    const params = {
      orderData: {
        newSortedTaskIds: this.newSortedTaskIds
      },
      dataStops: this.dataStops
    }
    let url = Const.APIV2(`${Const.APIURI_ORDERS}/${this.modelOrder.id}/add-remove-stops`);
    this.onProgress = true;
    this.api.PUT(url, params).subscribe(
      (response) => {
        this.onProgress = false;
        this.showSuccess(response);
        this.closeDialog();
        this.onOk();
      },
      (err) => {
        this.showErr(err);
        this.onProgress = false;
        this.closeDialog();
      }
    );
  }

  private updateDataLatest() {
    this.dataStops = this.getFormData();
    this.newSortedTaskIds = AddRemoveStopsService.getSortedTaskIds(this.locations);
  }

  public get isMultiPick() {
    return this.targetShipmentEntryMode == Const.ShipmentEntryMode.multiPick;
  }

  public get isMultiDrop() {
    return this.targetShipmentEntryMode == Const.ShipmentEntryMode.multiDrop;
  }

  public get isMultiPickDrop() {
    return this.targetShipmentEntryMode == Const.ShipmentEntryMode.multiPickDrop;
  }

  private getCurrentForm() {
    switch (this.targetShipmentEntryMode) {
      case Const.ShipmentEntryMode.multiPick: return this.formMultiPick;
      case Const.ShipmentEntryMode.multiDrop: return this.formMultiDrop;
      case Const.ShipmentEntryMode.multiPickDrop: return this.formMultiPickDrop;
    }
  }

  public getFormData(): FormDataAddRemoveStops{
    return {
      batchType: this.targetShipmentEntryMode,
      data: this.getCurrentForm()?.getData(),
    };
  }

  onListStopsChange(event) {
    this.updateDataLatest();
    Log.d('GetListLocationChange:',this.dataStops);
    if (this.dataStops.batchType == Const.ShipmentEntryMode.multiDrop || this.dataStops.batchType == Const.ShipmentEntryMode.multiPick) {
      this.locations = AddRemoveStopsService.getListDeliveryInfo_FromStopChange_MultiPick_Or_MultiDrop(this.locations, this.originLocations, this.dataStops.data,);
      Log.d('Locations - Changed :',this.locations);
    } else if (this.dataStops.batchType == Const.ShipmentEntryMode.multiPickDrop) {
      this.locations = AddRemoveStopsService.getListDeliveryInfo_FromStopChange_MultiPickDrop(this.locations, this.originLocations, this.dataStops.data);
      Log.d('Locations - Changed :',this.locations);
    }
  }

  onChangeLocations(data) {
    if (data) {
      this.locations = data;
      Log.d('Sort Changed:',data);
    }
  }

  get canUpdate() {
    if (!this.getCurrentForm()) return false;
    if (this.getCurrentForm()?.canUpdate()) {
      // Nếu Form Locations valid thì cho update luôn
      return true
    } else {
      // Nếu Form Locations invalid (2 khả năng là ko thay đổi gì, hoặc xóa hết dropoff)
      const originSortedTaskIds = AddRemoveStopsService.getSortedTaskIds(this.originLocations);
      const newSortedTaskIds = AddRemoveStopsService.getSortedTaskIds(this.locations);
      if (Utils.isArraysTheSame(originSortedTaskIds, newSortedTaskIds)) {
        return false
      } else {
        const data = this.getCurrentForm()?.getData();
        if (!data?.new?.length && !data?.deleted?.length) {
          return true
        }
        return false
      } 
    }
  }
    
}
