<div class="dashboard-child-container no-padding list">
  <div class="list-header" style="align-items: end;">
    <div user-filter></div>
    <div class="flex1"></div>
    <button *ngIf="requirePermissions([PermissionCode.user.create])" nz-button (click)="onBtnAdd()" style="height: 32px;"><i nz-icon nzType="plus" nzTheme="outline"></i>Create new user</button>
  </div>

  <div class="list-body">
    <nz-table
      #nzTable nzBordered="true"
      [nzData]="listData" nzSize="small"
      [nzTotal]="totalCount" [nzFrontPagination]="false"
      [nzShowPagination]="totalCount > listData.length"
      [nzPageSize]="limit"
      [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="warpId" nzWidth="120px">WARP ID</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="fullName">Full name</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="email">Email</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="phone">Phone</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="status">Status</th>
          <th>{{txtLastLogin}}</th>
          <th colspan="2">{{txtRole}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td>
            <a class="inherit" [routerLink]="getRouterLink(item)">{{item.warpId}}</a>
          </td>
          <td>
            <a [routerLink]="getRouterLink(item)">{{getFullName(item)}}</a>
          </td>
          <td>
            <a class="inherit" [routerLink]="getRouterLink(item)">{{item.email}}</a>
          </td>
          <td>
            <a class="inherit" [routerLink]="getRouterLink(item)">{{formatPhone(item.phone)}}</a>
          </td>
          <td class="text-center">
            {{getStatusUser(item.status)}}
          </td>
          <td class="inherit">
            {{displayLastLoginTime(item)}}
          </td>
          <td class="border-right-none">
            <div>{{getUserRoles(item)}}</div>
            <div *ngIf="shouldShowClientForRole(item)">
              <a style="color: rgba(0, 0, 0, 0.5); font-size: 12px;" [routerLink]="[routeAdminClientList, item.client.id]" target="_blank">{{item.client.name}}</a>
            </div>
            <div *ngIf="shouldShowCarrierForRole(item)">
              <a style="color: rgba(0, 0, 0, 0.5); font-size: 12px;" [routerLink]="[routeAdminCarrierList, item.carrier.id]" target="_blank">{{getCarrierName(item.carrier)}}</a>
            </div>
            <div *ngIf="shouldShowDriverForRole(item)">
              <a style="color: rgba(0, 0, 0, 0.5); font-size: 12px;" [routerLink]="[routeAdminDriverList, item.driver.id]" target="_blank">{{getDriverDesc(item.driver)}}</a>
            </div>
            <div *ngIf="shouldShowWarehouseForRole(item)">
              <div *ngFor="let warehouse of item.warehouses">
                <a style="color: rgba(0, 0, 0, 0.5); font-size: 12px;" [href]="getWarehouseLink(warehouse)" target="_blank">{{warehouse.name}}</a>
              </div>
            </div>
            <div *ngIf="shouldShowOrganizationForRole(item)">
              <a style="color: rgba(0, 0, 0, 0.5); font-size: 12px;">{{item.organization?.name}}</a>
            </div>
          </td>
          <td class="right-btn" style="width: 100px;">
            <div style="display: flex; justify-content: flex-end;">
              <a *ngIf="requirePermissions([PermissionCode.user.unBlock]) && isBlocked(item)" nz-button nzType="text" (click)="onBtnUnBlockUser(item)">
                <i nz-icon nzType="undo" nzTheme="outline" nz-tooltip nzTooltipTitle="Unblock user" nzTooltipPlacement="top"></i>
              </a>
              <a *ngIf="requirePermissions([PermissionCode.user.resetPassword])" nz-button nzType="text" (click)="onBtnResetPw(item)">
                <i nz-icon nzType="lock" nzTheme="outline" nz-tooltip nzTooltipTitle="Reset password" nzTooltipPlacement="right"></i>
              </a>
              <a *ngIf="canDeleteUser(item)" nz-button nzType="text" nzDanger (click)="onBtnDelItem(item)">
                <i nz-icon nzType="delete" nzTheme="outline" nz-tooltip nzTooltipTitle="Delete user" nzTooltipPlacement="right"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>

</div>
