import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { BidSessionCreateComponent } from '.';
import { AddRouteModule } from '../component/add-route/module';
import { RouteListViewModule } from '../component/route-list-view/module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    NzButtonModule,
    RouteListViewModule,
    AddRouteModule
  ],
  declarations: [
    BidSessionCreateComponent
  ],
  exports: [
    BidSessionCreateComponent
  ],
  providers: [
  ]
})
export class BidSessionCreateModule { }