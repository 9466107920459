import {BaseFormTemplate} from '@app/admin/base/form-base-template';
import {FormDataShipmentEntryBasicInfo} from '@wearewarp/types-server-admin/form-data/shipment-entry';
import {FormControl, Validators} from '@angular/forms';
import {Component} from '@angular/core';
import {Const} from '@const/Const';
import {MasterData} from '@services/master.data';
import {ShipmentEntryCreateMode} from '@app/enum';
import {VehicleType} from '@wearewarp/types/data-model';
import {ApiService} from '@services/api.service';
import {Subscription} from 'rxjs';

@Component({
  selector: '[form-shipment-entry-basic-info-single]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class FormShipmentEntryBasicTemplateDefault extends BaseFormTemplate<FormDataShipmentEntryBasicInfo, any> {

  selectedClient = null;
  selectedSubClient = null;
  listClients = [];
  listSubClients = [];
  vehicleType: VehicleType;
  listShipmentModes = MasterData.getAllShipmentModesV2();
  listEquipments = [];
  listShipmentTypes = [...Const.ShipmentTypesArray].filter(it => it !== Const.ShipmentTypes.storage);

  routeAdminClientList = Const.routeAdminClientList;
  subClientSubscription: Subscription;
  firstTimeListUpdate = true;

  constructor(private api: ApiService) {
    super();
  }

  get isFTL(): boolean {
    return this.formComponent.getItemValue('shipmentType') == Const.ShipmentTypes.fullTruckLoad;
  }

  get isLTL(): boolean {
    return this.formComponent.getItemValue('shipmentType') == Const.ShipmentTypes.lessThanTruckload;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.vehicleType = this.getItemValue('vehicleType');

    // let shipmentModeId = this.getItemValue('shipmentModeId');
    // if (shipmentModeId) {
    //   this.onShipmentModeChange();
    // }
    let shipmentType = this.getItemValue('shipmentType');
    if (shipmentType) {
      this.onShipmentTypeChange(shipmentType);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subClientSubscription?.unsubscribe();
  }

  public getShipmentTypeName(code: string): string {
    switch (code) {
      case Const.ShipmentTypes.fullTruckLoad:
        return 'FTL';
      case Const.ShipmentTypes.lessThanTruckload:
        return 'LTL';
      case Const.ShipmentTypes.parcel:
        return 'Parcel';
    }
  }

  getDropdownNoData(key: string): string {
    switch (key) {
      case 'equipmentId':
        let selectedShipmentModeId = this.formInput?.get('shipmentModeId')?.value;
        if (!selectedShipmentModeId) {
          return 'Please select type of truck first';
        }
        break;
      default:
        break;
    }
    return 'No data';
  }

  isDisableShipmentType(type: string) {
    return !(type == 'FTL') && (this.formComponent.type == ShipmentEntryCreateMode.batch);
  }

  onDropdownSelectChange(key: string, event) {
    switch (key) {
      case 'clientId':
        this.onClientChange(event);
        break;
      case 'subClientId':
        this.onSubClientChange(event);
        break;
      case 'vehicleType':
        this.vehicleType = event;
        if (event?.code) {
          this.setItemValue(key, event);
        } else {
          this.setItemValue(key, null);
        }
        break;
      case 'shipmentType':
        this.onShipmentTypeChange(event);
      default:
        break;
    }
  }

  getListForKey(key: string): Array<any> {
    switch (key) {
      case 'shipmentModeId':
        return this.listShipmentModes;
      case 'equipmentId':
        return this.listEquipments;
      default:
        return [];
    }
  }

  onListClientUpdate(clients: Array<any>) {
    let clientId = this.formComponent.getItemValue('clientId');
    let subClientId = this.formComponent.getItemValue('subClientId');
    if (this.firstTimeListUpdate && clients.length > 0) {
      this.firstTimeListUpdate = false;
      setTimeout(() => {
        this.selectedClient = clients.find(item => item.id === clientId);
        if (this.selectedClient) {
          this.fetchSubClient(this.selectedClient.id, subClientId);
        }
      }, 0);
    }
  }

  private onClientChange(clientId) {
    this.selectedClient = this.listClients.find(item => item.id === clientId);
    if (!this.model && this.selectedClient?.isUseCrossDock && this.isLTL) {
      this.formComponent.setItemValue('isCrossDock', true);
    } else {
      this.formComponent.setItemValue('isCrossDock', false);
    }
    //clear old and get new sub-client data
    this.selectedSubClient = null;
    this.formComponent.setItemValue('subClientId', null);
    this.listSubClients = null;
    if (this.selectedClient) {
      this.fetchSubClient(this.selectedClient.id);
    }
  }

  private onShipmentTypeChange(value) {
    if (value === Const.ShipmentTypes.lessThanTruckload) {
      const clientId = this.formComponent.getItemValue('clientId');
      let client = this.listClients.find(item => item.id === clientId);
      if (!this.model && client?.isUseCrossDock) {
        this.formComponent.setItemValue('isCrossDock', true);
      } else {
        this.formComponent.setItemValue('isCrossDock', false);
      }
    } else {
      this.formComponent.setItemValue('isCrossDock', false);
    }
    let isVehicleRequired = value === Const.ShipmentTypes.fullTruckLoad ? true : false;
    this.declaration.vehicleType.required = isVehicleRequired;
    for (let key of ['vehicleType']) {
      let fc = <FormControl> this.formInput.get(key);
      if (fc) {
        if (!isVehicleRequired) {
          fc.removeValidators(Validators.required);
        } else {
          fc.addValidators(Validators.required);
        }
        fc.updateValueAndValidity();
      }
    }
  }

  private onShipmentModeChange(clearEquipment = false) {
    let selectedShipmentModeId = this.formInput.get('shipmentModeId').value;
    let mode = this.listShipmentModes.filter(it => it.id == selectedShipmentModeId)[0];
    if (!mode) {
      return;
    }
    this.listEquipments = mode.equipments;
    if (clearEquipment) {
      this.formComponent.setItemValue('equipmentId', null);
    }
  }

  private onSubClientChange(clientId) {
    // get selected sub-client data
    this.selectedSubClient = this.listSubClients.find(item => item.id === clientId);
  }

  private fetchSubClient(parentId: string,subClientId:string = null) {
    this.subClientSubscription?.unsubscribe();
    let url = Const.APIV2(`${Const.APIURI_CLIENTS}/${parentId}/sub-client`);
    this.subClientSubscription = this.api.GET(url).subscribe(
      resp => {
        this.listSubClients = resp.data.list_data;
        if (subClientId) {
          this.selectedSubClient = this.listSubClients.find(item => item.id === subClientId);
        }
      }
    );
  }
}
