import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { Utils } from "@services/utils";
import { BaseDialog } from "@dialogs/base-dlg";
import { InputHelper } from "@services/input-helper";

@Component({
  selector: '[update-equipment-unassign-carrier]',
  templateUrl: './index.html',
  styleUrls: [
    './style.scss'
  ]
})
export class UpdateEquipmentUnassignCarrier extends BaseDialog {
  @Input() onSaveChangeVehicle: (data: any) => Observable<any>;
  @Input() onSaveUnAssignCarrier: () => Observable<any>;
  @Input() onSubmitSucceeded: () => void;

  @Input() set vehicleType(value) {
    if (!value) return;
    this._originalVehicleType = Utils.cloneObject(value);
    this.newVehicleType = Utils.cloneObject(value);
  }
  @Input() totalPallet: number = 0;
  @Input() totalWeight: number = 0;
  private _originalVehicleType;
  public newVehicleType;
  @Input() carrierName;
  isUnassignCarrier = true;
  reason = 'Caused by WARP';
  onProgress= false

  ngOnInit(): void {
    super.ngOnInit();
  }

  get canUpdate() {
    if (this.onProgress) return false;
    if (!this.newVehicleType) return false;
    return JSON.stringify(this.newVehicleType) !== JSON.stringify(this._originalVehicleType);
  }

  onBtnSave() {
    if (!this.canUpdate) return;
    this.onProgress = true;
    this.onSaveChangeVehicle(this.newVehicleType).subscribe(
      resp => {
        this.showSuccess('Vehicle updated successfully');
        if (this.isUnassignCarrier) {
          this.onSaveUnAssignCarrier().subscribe(
            resp => {
              this.showSuccess('Carrier unassigned successfully');
              this.onDone();
            }, err => {
              this.showErr(err);
              this.onProgress = false;
            }
          )
        } else {
          this.onDone();
        }
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

  private onDone() {
    this.closeDialog();
    this.onProgress = false;
    this.onSubmitSucceeded();
  }

  get shouldShowWarning(): boolean {
    if(!this.newVehicleType?.palletCapacity) return false;
    if(!this.newVehicleType?.capacity?.weight) return false;
    return this.totalPallet > (this.newVehicleType?.palletCapacity || 0) || this.totalWeight > (this.newVehicleType?.capacity?.weight || 0);
  }

  public formatNumber(item: number) {
    return InputHelper._formatMoney(item, 0);
  }
}
