<div class="chart-container">
    <div #chartContainer></div>
</div> 

<nz-table 
    #dataTable 
    nzSize="small"
    [nzData]="tableData" 
    [nzPageSize]="6"
    [nzScroll]="{ x: '800px' }"
    class="mt-4">
    <thead>
        <tr>
            <th>Stop</th>
            <th>Type</th>
            <th class="text-right">Upon Arrival</th>
            <th class="text-right">Action</th>
            <th class="text-right">Upon Departure</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of dataTable.data">
            <td>Stop {{data.stopNumber}}</td>
            <td>{{data.type}}</td>
            <td class="text-right">
                {{data.prevUnits}} {{data.qtyUnitPlural}}, {{formatNumber(data.prevWeight)}} Lbs
            </td>
            <td class="text-right" [style.color]="data.type === 'PU' ? '#52c41a' : '#f5222d'">
                <div>{{data.actionText}}</div>
                {{Math.abs(data.originUnits)}} {{data.qtyUnitPlural}}, {{formatNumber(Math.abs(data.originWeight))}} Lbs
            </td>
            <td class="text-right">
                {{data.currentUnits}} {{data.qtyUnitPlural}}, {{formatNumber(data.weight)}} Lbs
            </td>
        </tr>
    </tbody>
</nz-table> 