import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Input } from "@angular/core";
import { environment } from "@env/environment";
import { Utils } from "@services/utils";
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'dlg-create-order-list-success',
    templateUrl: './view.html',
    styleUrls: ['../../../../../../dialogs/dialogs.scss', '../../../../../../app.scss']
}) 
export class DlgCreateOrderListSuccess extends BaseComponent {

  @Input() orders: any[] = [];
  @Input() ftlOrder: any;
  @Input() isCreated: boolean = true;
  @Input() onSuccess: () => void = () => {};
  @Input() onExport: () => void = () => {};

  constructor(private modal: NzModalRef) {
      super();
    }
  
  private _onProgress = false;
  public countShipment: number = 0;

  get onProgress() {
      return this._onProgress;
  }

  public closeDialog() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  ngOnInit() {
    let orderList = [];
    this.countShipment = this.orders.length;
    this.orders.forEach(order => {
      let index = orderList.findIndex(or => or.warpOrderId === order.warpOrderId);
      if(index == -1) orderList.push(order);
    })
    this.orders = orderList;
  }

  onDone() {
    this.closeDialog();
    this.onSuccess();
  }

  getTrackingLink(order) {
    return `${environment.trackingWebUrl}/${order.code ?? order.trackingCode}`;
  }

  copyTrackingLink(order) {
    let content = this.getTrackingLink(order);

    Utils.copyTextToClipboard(content, () => {
      this.notification.success('Copy Tracking Link', 'Tracking Link has been copied to clipboard')
    })
  }

  displayOrderCode(order, index) {
    let orderCode = order.warpOrderCode;
    if(index < this.orders.length - 1) return `${orderCode}, `;
    return orderCode;
  }

  onBtnExport() {
    this.onExport();
  }
} 