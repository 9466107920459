import { Component, Input, Output, EventEmitter } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Const } from "@const/Const";
import { FormArray, Validators } from "@angular/forms";
import { DrawerService } from "@app/drawers/drawer.service";
import { WarehouseFormV2 } from "@app/admin/warehouses/warehouse-form-v2";
import { DialogService } from "@dialogs/dialog.service";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import { HistoryDialog } from "../history";
import { NotesForm } from "../notes-v2";
import { InputHelper } from "@services/input-helper";
import { EditShipmentEntryContactInfo } from "@app/admin/shipment-entry/components/edit-shipment/contact-info";
import { PhoneInfo } from "@wearewarp/types/data-model/types/PhoneInfo";
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';

@Component({
  selector: '[schedule-appointments]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss', '../../style.scss']
})

export class ScheduleAppointments extends BaseFormDialog1 {

  @Output() onRefresh: EventEmitter<any> = new EventEmitter<any>();

  private _data: any;
  public isShowEmptyView = true;
  public isResending: boolean = false;
  listPhoneInfo: { phoneNumber: string, phoneInfo: PhoneInfo | undefined }[] = []
  public listContact: any[] = [];
  public historiesCount = {};

  @Input() get data() { return this._data }
  @Input() isShowResendBtn: boolean = false;
  set data(value) {
    this._data = value;
    this.isResending = false;
    this.getAppointmentInfo();
  }

  appointmentWindowFormName = 'appointmentWindow'

  protected formGroupDeclaration: FormGroupDeclaration = {
    appointmentWindow: {label: '', type: 'formArray', initialValue: [{}], childItem: {
      date: {label: 'Date', type: 'date', required: true, isChanged: DateUtil.diffYYYYMMDD},
      fromTime: {label: 'From Time', type: 'time', required: true, placeHolder: 'Select time', isChanged: DateUtil.diffHHmm},
      toTime: {label: 'To Time', type: 'time', required: true, placeHolder: 'Select time', isChanged: DateUtil.diffHHmm},
      time: {label: 'Time', type: 'time', required: true, placeHolder: 'Select time', isChanged: DateUtil.diffHHmm},
      instruction: {label: 'Instruction',  placeHolder: 'Call Ahead'},
    }},
    type: { label: 'Select Appointment Type', required: true, placeHolder: 'Select' },
    contactType: { label: 'Select Contact Type', required: true },
    emails: { label: 'Emails' },
    phoneNumbers: { label: 'Phone Numbers' }
  }

  allChecked = false;
  indeterminate = true;

  onBtnScheduleAppointment() {
    if(!this.data?.shipmentId || !this.data?.stopId) return
    let url = Const.APIURI_SHIPMENT_QUEUE();
    let data = this.getFormData_JSON();
    data['appointmentInfo'].status = Const.AppointmentStatus.confirmed
    this.api.PUT(`${url}/${this.data.shipmentId}/book-appointment/${this.data.stopId}`, data).subscribe(
      resp => {
        this.model = resp.data
        this.isModelChanged = false
        this.showSuccess('Appointment Scheduled Successfully!');
        this.onRefresh.emit();
        this.updateSuccess(resp); 
        if (this.isDialog && this.closeOnSuccess) {
          this.closeDialog();
        }
      }, err => {
        this.showErr(err);
      }
    );
  }

  protected getFormData_JSON(): object {
    let customData = {};
    const timezone = this.model?.addr?.metadata?.timeZoneStandard;
    let fa = this.getFormArrayTimeWindows();
    let appointmentWindow = {};
    for (let i = 0; i < fa.length; i++) {
      let fg = fa.at(i);
      let type = <string>this.formInput.get('type')?.value;
      appointmentWindow['type'] = type;
      let date = <Date>fg.get('date').value;
      let fromTime = <Date>fg.get('fromTime').value;
      let toTime = <Date>fg.get('toTime').value;
      let time = <Date>fg.get('time').value;
      let instruction = fg.get('instruction').value;
      switch(type) {
        case Const.AppointmentType.callAhead:
          let fromToObj = DateUtil.toTimeWindow(date, fromTime, toTime, timezone);
          if (fromToObj) {
            appointmentWindow['from'] = fromToObj.from;
            appointmentWindow['to'] = fromToObj.to;
          }
          if(instruction) {
            appointmentWindow['instruction'] = instruction;
          }
          break;
        case Const.AppointmentType.strictAppointment:
          let tmp = DateUtil.getStrictTime(date, time, timezone).toISOString();
          appointmentWindow['from'] = tmp;
          appointmentWindow['to'] = tmp;
          break;
        case Const.AppointmentType.fcfs:
          if(fromTime && toTime) {
            let fromToObj = DateUtil.toTimeWindow(date, fromTime, toTime, timezone);
            if (fromToObj) {
              appointmentWindow['from'] = fromToObj.from;
              appointmentWindow['to'] = fromToObj.to;
            }
          } else {
            let tmp = DateUtil.convertLocalTime(new Date(date), timezone);
            appointmentWindow['from'] = tmp;
            appointmentWindow['to'] = tmp;
          }
          break;
      }
      if (this.formInput.get('contactType')?.value){
        appointmentWindow['contactType'] = this.formInput.get('contactType').value;
      }
    }
    customData['appointmentInfo'] = appointmentWindow;
    return customData;
  }

  getControlTimeWindows() {
    return this.getFormArrayTimeWindows()?.controls ?? [];
  }

  private getFormArrayTimeWindows(): FormArray {
    return <FormArray>this.formInput.get(this.appointmentWindowFormName);
  }

  displayWindows(window, location) {
    const timezone = location?.addr?.metadata?.timeZoneStandard;
    if (window && timezone) {
      return DateUtil.displayTimeWindow(window, {
        timezone: timezone,
        formatDateOnly: 'MM/DD/YY',
        format: "MM/DD/YY h:mm A",
      })
    };
    return 'N/A';
  }
  ngOnInit(): void {
    super.ngOnInit();
  }

  checkboxOptions = []
  initMailCheckbox(){
    const listEmail = this.getListEmail().filter((obj, index, self) =>
    index === self.findIndex((o) => o.value === obj.value)
    );
    this.checkboxOptions = this.processListEmailForCheckbox(listEmail)
  }

  phoneNumberOptions = []
  initPhoneNumberCheckbox(){
    const listPhoneNumber = this.getListPhoneNumber().filter((obj, index, self) =>
    index === self.findIndex((o) => o.value === obj.value));
    this.phoneNumberOptions = this.processListPhoneNumberForCheckbox(listPhoneNumber)
  }

  getListContact() {
    let listContact: any[] = [];
    if (this.model?.contacts?.length) {
      listContact = this.model?.contacts.map((contact) => {
        return { ...contact, from: "Shipment"}
      })
    }
    if (this.model?.warehouseContacts?.length) {
      let listWarehouseContact = this.model?.warehouseContacts.map((contact) => {
        return { ...contact, from: "Location"}
      })
      listContact = listContact.concat(listWarehouseContact)
    }

    if (!listContact.length) return;
    this.listContact = [listContact[0]];
    listContact.forEach(item => {
      let contact = this.listContact.find(c => c.phone == item.phone && c.email == item.email);
      if (!contact) this.listContact.push(item);
    })
  }

  isLoading = false
  getAppointmentInfo(isUpdateContact = false) {
    if(!this._data?.shipmentId || !this._data?.stopId) {
      this.model = undefined
      this.isShowEmptyView = true;
      return
    }
    this.isLoading = true
    const url = Const.APIURI_SHIPMENT_QUEUE();
    this.api.GET(`${url}/${this._data?.shipmentId}/stops/${this._data?.stopId}`).subscribe(
      resp => {
        if (resp.data) {
          this.model = resp.data;
          this.model.warehouseContacts = this.getWarehouseContacts(resp.data?.warehouse)
          this.isShowEmptyView = false;
          this.setAppointmentWindowValue()
          this.initMailCheckbox()
          this.initPhoneNumberCheckbox()
          this.getListContact();
          this.getPhoneNumberInfo()
          this.getHistoriesCount();
          if (isUpdateContact) this.formInput?.get('contactType').setValue(this.draftContactType)
          this.isLoading = false
        } else {
          this.model = undefined
          this.isShowEmptyView = true;
          this.isLoading = false
        }
      }, err => {
      }
    );
  }

  setAppointmentWindowValue(){
    if (this.model) {
      const timezone = this.model.addr?.metadata?.timeZoneStandard;
      const from = this.model.appointmentInfo?.from
      const to = this.model.appointmentInfo?.to

      let date, fromTime, toTime
      if (from && to && timezone){
        const appointmentWindow = DateUtil.fromTimeWindow(this.model.appointmentInfo, timezone)
        date = appointmentWindow?.date
        fromTime = appointmentWindow?.fromTime
        toTime = appointmentWindow?.toTime
      }

      const fa = <FormArray>this.formInput.get(this.appointmentWindowFormName);
      for (let i = 0; i < fa.length; i++) {
        let fg = fa.at(i);
        fg.get('date').setValue(date);
        fg.get('fromTime').setValue(fromTime);
        fg.get('toTime').setValue(toTime);
      }

      const type = this.model.appointmentInfo?.type
      const contactType = this.model.appointmentInfo?.contactType
      this.formInput.get("type").setValue(type)
      this.formInput.get("contactType").setValue(contactType)
    }
    this.isModelChanged = false
  }

  getTypeStops(type) {
    this.getAddressText
    switch (type) {
      case 'DROPOFF': return 'Drop-off';
      case 'PICKUP': return 'Pickup';
      case 'TRANSIT': return 'Transit'
      default: return '';
    }
  }

  openViewNote() {
    DrawerService.openFormDrawer1(NotesForm, {
      nzContentParams: {
        locationId: this.data?.stopId,
      },
      nzWidth: 700,
    });
  }

  onClickLocationDetail() {
    DrawerService.openFormDrawer1(WarehouseFormV2, {
      nzContentParams: {
        model: this.model.warehouse,
        closeOnSuccess: true
      },
      nzWidth: 700,
    });
  }

  copyPhoneNumber(phoneNumber) {
    Utils.copyTextToClipboard(phoneNumber, (e) => {
      if (e) {
        this.showErr("Cannot copy phone number to clipboard");
      } else {
        this.showSuccess(
          "Phone number has already been copied to the clipboard"
        );
      }
    });
  }

  copyEmail(email) {
    Utils.copyTextToClipboard(email, (e) => {
      if (e) {
        this.showErr("Cannot copy email to clipboard");
      } else {
        this.showSuccess(
          "Email has already been copied to the clipboard"
        );
      }
    });
  }

  appointmentType = Const.AppointmentType
  appointmentTypeArray = Const.AppointmentTypeArray
  getAppointmentTypeLabel(type){
    switch (type) {
      case Const.AppointmentType.callAhead:
        return 'Call Ahead'
      case Const.AppointmentType.fcfs:
        return 'FCFS'
      case Const.AppointmentType.strictAppointment:
        return 'Strict Appointment'
      default:
        break;
    }
  }

  appointmentContactType = Const.AppointmentContactType
  orderContactTypeArray: any = [Const.AppointmentContactType.email, Const.AppointmentContactType.sms, Const.AppointmentContactType.phone];
  appointmentContactTypeArray = Object.values(Const.AppointmentContactType).filter(type => type != Const.AppointmentContactType.link)
  .sort((a,b) => {
    let indexa = this.orderContactTypeArray.indexOf(a) == -1 ? Infinity : this.orderContactTypeArray.indexOf(a);
    let indexb = this.orderContactTypeArray.indexOf(b) == -1 ? Infinity : this.orderContactTypeArray.indexOf(b);
    return indexa - indexb
  }) //type link chưa dùng
  getAppointmentContactTypeLabel(type){
    switch (type) {
      case Const.AppointmentContactType.phone:
        return 'Phone'
      case Const.AppointmentContactType.sms:
        return 'Text'
      case Const.AppointmentContactType.email:
        return 'Email'
      default:
        break;
    }
  }

  get isAppointmentScheduled(){
    return this.model?.appointmentInfo?.from
  }

  draftContactType
  requestBtnText = "Confirm request sent"
  onContactTypeChange(value){
    if (!value) return
    this.isResending = false;
    this.draftContactType = value

    this.isModelChanged = true

    switch (value) {
      case this.appointmentContactType.phone:
        this.requestBtnText = "Confirm called"
        break;
      case this.appointmentContactType.sms:
        this.requestBtnText = "Confirm text sent"
        break;
      case this.appointmentContactType.email:
        this.requestBtnText = "Confirm email sent"
      default:
        break;
    }
  }

  get contactType(){
    return this.formInput?.get('contactType')?.value
  }

  get modelContactType() {
    return this.model?.appointmentInfo?.contactType
  }

  get selectedAppointmentType() {
    return this.formInput?.get('type')?.value
  }

  get isShowContactInfo(){
    return (this.isShowRequestSentBtn
      && this.contactType == this.appointmentContactType.phone
      && (this.model?.contacts?.length || this.model?.warehouseContacts?.length))
  }

  get isShowNoContactFound(){
    return !this.model?.contacts?.length && !this.model?.warehouse
  }

  get isShowRequestSentBtn(){
    return !this.isAwaitingResponse && !this.isAppointmentScheduled || (this.contactType && this.contactType != this.model?.appointmentInfo?.contactType)
  }

  get isShowSendEmailBtn(){
    return !this.model?.appointmentInfo?.status
    && !this.isAwaitingResponse
    && !this.isAppointmentScheduled
  }

  get isAwaitingResponse(){
    return this.model?.appointmentInfo?.status == Const.AppointmentStatus.requested
  }

  get isShowScheduleInput(){
    return (this.isAwaitingResponse || this.isAppointmentScheduled) && this.model?.appointmentInfo?.contactType == this.contactType && !this.isResending
  }

  get isWindowsScheduled(){
    return !this.model?.requiresAppointment
    && this.model?.windows?.length
    && !this.isAwaitingResponse
    && !this.isAppointmentScheduled
    && !this.model?.skipAppointment
  }

  editContactType(){
    this.isResending = false;
    this.formInput?.get('contactType').setValue(null)
  }

  confirmRequestSent(){
    if(!this.data?.shipmentId || !this.data?.stopId) return
    const data = {
      appointmentInfo: {
        contactType: this.contactType,
        status: Const.AppointmentStatus.requested
      }
    }
    let url = Const.APIURI_SHIPMENT_QUEUE();
    this.api.PUT(`${url}/${this.data.shipmentId}/request-appointment/${this.data.stopId}`, data).subscribe(
      resp => {
        this.model = resp.data;
        this.setAppointmentWindowValue()
        this.showSuccess(`${this.requestBtnText} Successfully!`);
        this.onRefresh.emit();
      }, err => {
        this.showErr(err);
      }
    );
  }

  getLastRequestTime(){
    const date = this.model?.appointmentInfo?.lastRequestTime
    if (date){
      return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME);
    }
    return
  }

  getLastUpdateTime() {
    let date = this.model?.histories[this.model?.histories?.length-1].insert?.when;
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME);
  }

  getLastUpdateUser() {
    let user = this.model?.histories[this.model?.histories?.length-1].metadata?.user;
    return this.getFullName(user);
  }

  getHistoriesCount() {
    for (let type of Object.keys(Const.AppointmentContactType)) {
      this.historiesCount[type] = 0;
    }
    for (let item of (this.model?.histories || [])) {
      let json = JSON.parse(item?.metadata?.changeInfo || "{}");
      let type = json?.appointmentInfo?.contactType;
      if (type) this.historiesCount[type]++;
    }
  }

  getHistoriesCountForType(type) {
    let count = this.historiesCount[type] || 0;
    if (count > 0) return `(${count})`;
    return '';
  }

  get appointmentHistoryText() {
    let count = this.historiesCount[this.contactType] || 0;
    if (count == 0) return '';
    if(this.contactType === Const.AppointmentContactType.email) return "(Sent)";
    if(this.contactType === Const.AppointmentContactType.phone) return "(Called)";
    if(this.contactType === Const.AppointmentContactType.sms) return "(Sent)";
    return '';
  }

  // onHisUpdateDetail() {
  //   const timezone = this.model.addr?.metadata?.timeZoneStandard;
  //   DialogService.openFormDialog1(HistoryDialog, {
  //     nzComponentParams: {
  //       history: this.model.histories,
  //       timezone: timezone,
  //       closeOnSuccess: true,
  //       updateSuccess: resp => {

  //       }
  //     },
  //     nzClassName: 'modal-no-padding',
  //   });
  // }

  isModelChanged = false

  get isAllowSubmitScheduleAppt(){
    if (this.isAppointmentScheduled){
      if (this.isModelChanged && this.needUpdate) return true
      return false
    }
    return this.needUpdate
  }

  onBtnSendMail(){
    if(!this.data?.shipmentId || !this.data?.stopId) return
    this.confirmYesNo(`Are you sure to send appointment link to ${this.getListEmailChecked()?.toString()}?`, () => {
      const data = {
        emails: this.getListEmailChecked(),
        type: this.model?.type,
        locationName: this.model?.locationName,
        addressText: this.getAddressText(this.model?.addr),
        appointmentInfo: {
          contactType: this.appointmentContactType.email,
          status: Const.AppointmentStatus.requested
        }
      }
      const url = Const.APIURI_SHIPMENT_QUEUE();
      this.api.PUT(`${url}/${this.data.shipmentId}/send-mail-appointment/${this.data.stopId}`, data).subscribe(
        resp => {
          this.model = resp.data;
          this.isResending = false;
          this.setAppointmentWindowValue()
          this.showSuccess(`Send mail successfully!`);
          // this.onRefresh.emit();
          // this.updateSuccess(resp); 
          // if (this.isDialog && this.closeOnSuccess) {
          //   this.closeDialog();
          // }
        }, err => {
          this.showErr(err);
        }
      );
    });
  }

  onBtnResend(event) {
    this.formInput.get('contactType').setValue(event)
    this.isResending = true;
  }

  onBtnSendSMS(){
    if(!this.data?.shipmentId || !this.data?.stopId) return;
    const listPhoneFormatted = this.getListPhoneNumberChecked()?.map(phone => InputHelper.formatPhone(phone))
    this.confirmYesNo(`Are you sure to send sms to ${listPhoneFormatted?.toString()}?`, () => {
      const data = {
        phoneNumbers: this.getListPhoneNumberChecked(),
        type: this.model?.type,
        locationName: this.model?.locationName,
        addressText: this.getAddressText(this.model?.addr),
        appointmentInfo: {
          contactType: this.appointmentContactType.sms,
          status: Const.AppointmentStatus.requested
        }
      }
      const url = Const.APIURI_SHIPMENT_QUEUE();
      this.api.PUT(`${url}/${this.data.shipmentId}/send-sms-appointment/${this.data.stopId}`, data).subscribe(
        resp => {
          this.model = resp.data;
          this.isResending = false;
          this.setAppointmentWindowValue()
          this.showSuccess(`Send sms successfully!`);
          // this.onRefresh.emit();
          // this.updateSuccess(resp); 
          // if (this.isDialog && this.closeOnSuccess) {
          //   this.closeDialog();
          // }
        }, err => {
          this.showErr(err);
        }
      );
    });
  }

  cancelResend() {
    this.isResending = false;
  }

  public getWarehouseContacts(warehouse: any) {
    let contacts: any = [];
    if (warehouse) {
      let { contactName = "", email = "", phone = "", secondaryContact } = warehouse;
      let checkContact = !contactName && !email && !phone;
      if (!checkContact) contacts.push({
        fullName: contactName,
        email,
        phone,
        type: Const.ContactType.primary
      });
      if (secondaryContact) {
        let { contactName = "", email = "", phone = "" } = secondaryContact;
        let checkContact = !contactName && !email && !phone;
        if (!checkContact) contacts.push({
          fullName: contactName,
          email,
          phone,
          type: Const.ContactType.secondary
        });
      }
    }
    return contacts
  }

  getListEmail(){
    let listEmail = []
    const shipmentContacts = this.model?.contacts || []
    for (let contact of shipmentContacts){
      if (contact?.email) listEmail.push({value: contact.email, type: contact?.type, from: 'Shipment'})
    }
    const warehouseContacts = this.model?.warehouseContacts || [];
    for(let contact of warehouseContacts){
    if (contact?.email) listEmail.push({value: contact.email, type: contact?.type, from: 'Location'})
    }
    return listEmail
  }

  getListPhoneNumber(){
    let listPhoneNumber = []
    const shipmentContacts = this.model?.contacts || []
    for (let contact of shipmentContacts){
      if (contact?.phone) listPhoneNumber.push({value: contact.phone, type: contact?.type, from: 'Shipment'})
    }
    const warehouseContacts = this.model?.warehouseContacts || [];
    for(let contact of warehouseContacts){
      if (contact?.phone) listPhoneNumber.push({value: contact.phone, type: contact?.type, from: 'Location'})
    }
    return listPhoneNumber
  }

  processListEmailForCheckbox(listEmail = []){
    let listEmailCheckbox = []
    for (let index = 0; index < listEmail?.length; index++) {
      const email = listEmail[index];
      listEmailCheckbox.push({ label: `${email?.type ? Utils.capitalizeFirstLetter(email.type) + ': ' : ''}${email.value} (${email.from})`, value: email.value, checked: index == 0 })
    }
    return listEmailCheckbox
  }

  processListPhoneNumberForCheckbox(listPhoneNumber = []){
    let listPhoneNumberCheckbox = []
    for (let index = 0; index < listPhoneNumber?.length; index++) {
      const phoneNumber = listPhoneNumber[index];
      listPhoneNumberCheckbox.push({
        label: `${phoneNumber?.type ? Utils.capitalizeFirstLetter(phoneNumber.type) + ': ' : ''}${InputHelper.formatPhone(phoneNumber.value)} (${phoneNumber.from}) ${this.getPhoneType(phoneNumber.value) ? '(' + this.getPhoneType(phoneNumber.value) + ')' : ''}`,
        value: phoneNumber.value,
        checked: index == 0
      })
    }
    return listPhoneNumberCheckbox
  }

  getListEmailChecked(){
    const listEmail = this.formInput.get('emails')?.value;
    return listEmail?.filter(email => email.checked)?.map(email => email?.value)
  }

  getListPhoneNumberChecked(){
    const listPhoneNumber = this.formInput.get('phoneNumbers')?.value;
    return listPhoneNumber?.filter(number => number.checked)?.map(number => number?.value)
  }

  onBtnAddContact(){
    const contactInfo = this.model?.contacts?.[0]
    const contactType = contactInfo?.type
    const locationType = this.model?.type
    const locationTypeTxt = locationType == Const.TaskType.PICKUP ? 'Pickup' : 'Delivery';
    DialogService.openFormDialog1(EditShipmentEntryContactInfo, {
      nzComponentParams: {
        headerText: `Update ${locationTypeTxt} ${contactType ? Utils.capitalizeFirstLetter(contactType): ''} Contact`,
        model: contactInfo,
        contactType: contactType,
        closeOnSuccess: true,
        onSave: data => {
          let contacts = this.model?.contacts
          contacts[0] = { //luôn update cho element đầu tiên để update cho primary contact
            ...data,
            type: Const.ContactType.primary
          }
          const url = Const.APIV2(`shipments/${this._data?.shipmentId}/delivery-info/${this.model.id}`)
          return this.api.PUT(url, {contacts})
        },
        onRefreshDetailOrder: () => this.getAppointmentInfo(true)
      },
      nzClassName: "modal",
    });
  }

  get isEmailContact(){
    return this.contactType == this.appointmentContactType.email
  }

  get isSmsContact(){
    return this.contactType == this.appointmentContactType.sms
  }

  get isAllowSendMail(){
    return this.getListEmailChecked()?.length
  }

  get isAllowSendSms(){
    return this.getListPhoneNumberChecked()?.length
  }

  get isShowSendMail(){
    return (this.isShowRequestSentBtn
    && this.contactType == this.appointmentContactType.email
    && this.getListEmail()?.length) || this.isResending;
  }

  get isShowSendSms(){
    return (this.isShowRequestSentBtn
    && this.contactType == this.appointmentContactType.sms
    && this.getListPhoneNumber()?.length) || this.isResending;
  }

  get isShowCannotSendMail(){
    return this.isShowRequestSentBtn
    && this.contactType == this.appointmentContactType.email
    && !this.getListEmail()?.length
  }

  get isShowCannotSendSms(){
    return this.isShowRequestSentBtn
    && this.contactType == this.appointmentContactType.sms
    && !this.getListPhoneNumber()?.length
  }

  formatPhone(phone: string) {
    return InputHelper.formatPhone(phone);
  }

  getPhoneNumberInfo() {
    const listPhoneNumber = this.getListPhoneNumber().map(item => item.value);
    const url = Const.APIURI_SHIPMENT_QUEUE();
    this.api.POST(`${url}/phone_number_info`, { phoneNumbers: listPhoneNumber }).subscribe(
      resp => {
        if (resp.data) {
          this.listPhoneInfo = resp.data.list_data ?? [];
          this.initPhoneNumberCheckbox();
        }
      }, err => {
        console.log('err', err);
      }
    );
  }

  getPhoneType(phoneNumber: string) {
    let info = this.listPhoneInfo.find(phone => phone.phoneNumber === phoneNumber);
    if (info?.phoneInfo) {
      return Const.getPhoneInfoType(info?.phoneInfo.type);
    }
    return null;
  }

  get lastHistoryScheduleAppointment() {
    let histories = this.model?.histories || [];
    let lastSent;
    for(let i = histories.length - 1; i >= 0; i--) {
      let history = histories[i];
      if(history?.metadata?.changeInfo?.includes('contactType')){
        lastSent = history;
        break;
      }
    }
    if(!lastSent) return;
    let detailJson = JSON.parse(lastSent?.metadata?.changeInfo || '{}');
    let contactType = detailJson?.appointmentInfo?.contactType;
    if(contactType === Const.AppointmentContactType.email) contactType = 'Email';
    if(contactType === Const.AppointmentContactType.sms) contactType = 'SMS';
    if(contactType === Const.AppointmentContactType.phone) contactType = 'Called';
    let lastSentTime = DateUtil.dateToString(lastSent?.insert?.when, Const.FORMAT_GUI_DATETIME);
    return `${contactType} appointment at ${lastSentTime} by ${this.getFullName(lastSent?.metadata?.user)}`;
  }

  hasHistoryScheduleAppointment() {
    return this.model?.histories && this.model?.histories.length
  }

  onChangeAppointmentType(event) {
    this.isModelChanged=true;
    const fa = <FormArray>this.formInput.get(this.appointmentWindowFormName);
    switch(event) {
      case Const.AppointmentType.callAhead:
        for (let i = 0; i < fa.length; i++) {
          let fg = fa.at(i);
          fg.get('fromTime')?.setValidators([Validators.required]);
          fg.get('fromTime')?.updateValueAndValidity();
          fg.get('toTime')?.setValidators([Validators.required]);
          fg.get('toTime')?.updateValueAndValidity();
          fg.get('time')?.clearValidators();
          fg.get('time')?.updateValueAndValidity();
        }
        break;
      case Const.AppointmentType.strictAppointment:
        for (let i = 0; i < fa.length; i++) {
          let fg = fa.at(i);
          fg.get('fromTime')?.clearValidators();
          fg.get('fromTime')?.updateValueAndValidity();
          fg.get('toTime')?.clearValidators();
          fg.get('toTime')?.updateValueAndValidity();
          fg.get('time')?.setValidators([Validators.required]);
          fg.get('time')?.updateValueAndValidity();
        }
        break;
      case Const.AppointmentType.fcfs:
        for (let i = 0; i < fa.length; i++) {
          let fg = fa.at(i);
          fg.get('fromTime')?.clearValidators();
          fg.get('fromTime')?.updateValueAndValidity();
          fg.get('toTime')?.clearValidators();
          fg.get('toTime')?.updateValueAndValidity();
          fg.get('time')?.clearValidators();
          fg.get('time')?.updateValueAndValidity();
        }
        break;
      default:
        break;
    }
  }

  get shouldShowStep2() {
    return this.contactType
  }

  get contactTYpeHighlightStyle() {
    return {
      'background-color': '#d3f261',
      'border-color': '#d3f261',
    }
  }

}
