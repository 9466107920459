import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { DataRepoBid } from "@app/data-repo/bid";
import { ResponseBidCandidateActivities, ResponseBidCounterHistoryItem } from '@wearewarp/types-server-admin/carrier-bid';
import { UIHelper } from '@services/UIHelper';
import { Subscription } from 'rxjs';
import { CarrierBidEvent } from "@app/socket/helper/bid";
import { BidEventData } from "@app/socket/interface";
import { InputHelper } from "@services/input-helper";
import { SocketEvent } from "@app/enum";
import { DialogService } from "@dialogs/dialog.service";
import { CounterForCarrierComponent } from "@app/admin/carrier-bids/components/counter-for-carrier/counter-for-carrier.component";
import { EditCounterComponent } from "@app/admin/carrier-bids/components/edit-counter/edit-counter.component";
import { getApp } from "@services/index";
import { AdminAuthUser } from "@wearewarp/types-server-admin";
import { EditCarrierBidAnswer_CandidateInfo, EditCarrierBidAnswer_CarrierBidInfo, EditCounter_CarrierBidInfo } from "@app/admin/carrier-bids/interfaces";
import { EditCarrierBidAnswerComponent } from "@app/admin/carrier-bids/components/edit-carrier-bid-answer/edit-carrier-bid-answer.component";
import { Const } from "@const/Const";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: '[bid-counter-activities]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class BidCounterActivities implements OnInit, OnDestroy {
  @Input() isLoadExpired: boolean = false;
  @Output() onReload = new EventEmitter();

  isExpanded = false;
  needExpandCollapse = false;       // nếu list item chỉ 3 thằng đổ lại thì không cần
  isLoading = false;
  private data: ResponseBidCandidateActivities;
  private subEvent: Subscription;
  private subApi: Subscription;
  private _candidateId = '';
  private authUser: AdminAuthUser;
  @Input() set candidateId(value: string) {
    if (this._candidateId != value) {
      this._candidateId = value;
      this.subEvent?.unsubscribe();
      this.subEvent = this.carrierBidEvent.subscribeBidCandidate(value, data => this.onCandidateEvent(data));
    }
  }
  get candidateId(): string { return this._candidateId }
  get listData(): ResponseBidCounterHistoryItem[] { return this.data?.activities ?? [] }
  get isFirstLoading(): boolean { return !this.data && this.isLoading }
  get hasData(): boolean { return !!this.data }
  get isBidCounterLocked() { return this.data?.bid?.lockCounter?.byId; }
  get isUserLockedBidCounter() { return this.authUser?.id && this.data?.bid?.lockCounter?.byId === this.authUser?.id; }
  get isAllowPlaceBid() { return this.data?.bid?.isAllowPlaceBid }
  get isCarrierRefusedBid() { return this.data?.candidate?.state == Const.CarrierBidState.Refused }
  get isCarrierAcceptedPrice() { return !this.isCarrierRefusedBid && this.data?.bidCounterStatus == WarpConst.BidCounterStatus.accepted }

  get canCounter() {
    if (this.isLoadExpired || !this.isAllowPlaceBid) return false;      //load expired
    if (this.data?.candidate?.status == 'un_registered') return false;
    if (this.isCarrierAcceptedPrice) return false;
    if (this.isBidCounterLocked && !this.isUserLockedBidCounter) return false;      // bid bị khoá bởi carrier sale
    return true;
  }

  get canEnterCarrierAnswerManually() {
    if (this.isLoadExpired || !this.isAllowPlaceBid) return false;
    if (this.isCarrierAcceptedPrice) return false;
    return true;
  }

  public txtBasePrice = '';
  public txtCarrierAcceptedPrice = '';

  constructor(private repoBid: DataRepoBid, private carrierBidEvent: CarrierBidEvent) {
  }

  ngOnInit(): void {
    this.authUser = getApp()?.getAuthUser();
    this.getData();
  }

  ngOnDestroy(): void {
    this.subEvent?.unsubscribe();
    this.subApi?.unsubscribe();
  }

  private getData() {
    this.isLoading = true;
    this.subApi?.unsubscribe();
    this.subApi = this.repoBid.fetchCandidateActivities(this.candidateId, result => {
      if (result.error) {
        UIHelper.showErr(result.error);
      } else if (result.data) {
        this.getDataDone(result.data);
      }
      this.isLoading = false;
      this.isReloading = false;
    });
  }

  private getDataDone(data: ResponseBidCandidateActivities) {
    this.needExpandCollapse = data.activities.length > 3;
    if (!this.needExpandCollapse) {
      this.isExpanded = true
    }
    const first = data.activities[0];
    this.shouldHilighFirstItem = this.newEvent && first?.entity == 'carrier';
    this.data = data;
    this.candidateId = this.data.candidateId;
    if (this.data.bid.basePrice) {
      this.txtBasePrice = InputHelper.formatMoney1(this.data.bid.basePrice);
    } else {
      this.txtBasePrice = 'N/A';
    }
    if (this.isCarrierAcceptedPrice) {
      this.txtCarrierAcceptedPrice = this.data.candidate.price ? InputHelper.formatMoney1(this.data.candidate.price) : 'N/A';
    }
    this.newEvent = false;
  }

  private newEvent = false;
  private onCandidateEvent(data: BidEventData) {
    switch (data.event) {
      case SocketEvent.carrierBidCandidateReply:
      case SocketEvent.carrierBidCandidateCounter:
        if (data.payload.carrierId != this.data.carrier.id) {
          return;
        }
        this.newEvent = true;
        this.getData();
        break;
      default:
        return;
    }
  }

  private shouldHilighFirstItem = false;
  shouldHilight(index: number): boolean {
    // consider hilight newest item
    // return index == 0 && this.shouldHilighFirstItem;

    return false;
  }

  onOpenCounterDialog() {
    let carrierBid: EditCounter_CarrierBidInfo = {
      bidId: this.data.bid.id,
      token: this.data.candidate.token,
      isNotifyViaSMS: this.data.carrier.isNotifyViaSMS,
    }
    DialogService.openFormDialog1(EditCounterComponent, {
      nzComponentParams: {
        carrierBid,
        closeOnSuccess: true,
        updateSuccess: resp => {
          // do not hard reload
          // this.onReload.emit();
        } 
      }
    })
  }

  enterCarrierAnswer() {
    const hasCounter = this.data.activities.length > 1;
    if (hasCounter) {
      this.onBtnCounterBidForCarrier();
    } else {
      this.onBtnEditCarrierFirstPlace();
    }
  }

  private onBtnCounterBidForCarrier() {
    // data trả về xếp theo thứ tự từ mới đến cũ => reverse để phù hợp với logic trong dialog
    let bidAnswers = [...this.data.activities].reverse();
    const carrierBidItem: EditCounter_CarrierBidInfo = {
      bidId: this.data.bid.id,
      token: this.data.candidate.token,
      bidCounterId: this.data.candidate.bidCounterId,
      bidAnswers,
      isNotifyViaSMS: this.data.carrier.isNotifyViaSMS,
    }
    DialogService.openFormDialog1(CounterForCarrierComponent, {
      nzComponentParams: {
        carrierBidItem,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          // this.onReload.emit();
        }
      },
      nzClassName: "modal",
    })
  }

  private onBtnEditCarrierFirstPlace() {
    if (this.data?.carrier?.id) {
      const carrierBidInfo: EditCarrierBidAnswer_CarrierBidInfo = {
        basePrice: this.data.bid.basePrice,
      }
      const carrierBidItem: EditCarrierBidAnswer_CandidateInfo = {
        token: this.data.candidate.token,
        state: this.data.candidate.state,
        basePrice: this.data.candidate.basePrice
      }
      DialogService.openFormDialog1(EditCarrierBidAnswerComponent, {
        nzComponentParams: {
          carrierBidInfo,
          carrierBidItem,
          closeOnSuccess: true,
          cusSuccessMes: 'Please review in Response Received tab',
          updateSuccess: resp => {
            // this.onReload.emit();
          }
        },
        nzClassName: "modal",
      })
    }
  }

  isReloading = false;
  reload() {
    if (this.isReloading) {
      return;
    }
    this.isReloading = true;
    this.getData();
  }

  togglePanel() {
    this.isExpanded = !this.isExpanded;
  }

  getTooltipCarrierRefused() {
    return this.data?.candidate?.update?.when ? this.$formatDate(this.data?.candidate?.update?.when) : '';
  }

  $formatDate = (date) => {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }
}