import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DialogService } from "@dialogs/dialog.service";
import { Const } from "@const/Const";
import { EditShipmentEntryAddLocation } from "../add-location";
import { Utils } from "@services/utils";
import { BaseChildEditShipmentAddRemoveStops } from "../base-child-comp";
import { DataAddRemoveStops } from "../interface";
import { ResponseAdminOrderDetail } from "@wearewarp/types-server-admin";

interface EditShipmentUICollapseState {
  data?: any,
  isExpand?: boolean,
  isLoading?: boolean,
  isAddNew?: boolean,
}

@Component({
  selector: '[form-edit-shipment-batch-multi-drop]',
  templateUrl: './view.html',
  styleUrls: ['../style.scss'],
})
export class FormEditShipmentBatchMultiDrop extends BaseChildEditShipmentAddRemoveStops {

  @Input() currentShipmentEntryMode;
  @Input() modelOrder: ResponseAdminOrderDetail;
  @Output() onListStopsChange = new EventEmitter();

  readonly uiCollapseState: {pick: EditShipmentUICollapseState, drops: EditShipmentUICollapseState[]} = {
    pick: {},
    drops: []
  }
  get countDropInfos() { return this.uiCollapseState.drops.length }
  originDropIds = [];

  ngOnInit(): void {
    this.extractDataModelOrder();
  }

  private extractDataModelOrder() {
    if (!this.modelOrder) return;
    if (this.currentShipmentEntryMode == Const.ShipmentEntryMode.single) {
      let shipment = this.modelOrder?.metadata?.shipments?.[0];
      if (!shipment) return;
      const deliveryInfos = shipment.deliveryInfos || [];
      const pickInfo = deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
      const dropInfo = deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
      const itemIds : any[] = shipment.itemIds ?? [];
      dropInfo.items = this.modelOrder.items?.filter(it => itemIds.includes(it.id));
      dropInfo.shipmentWarpId = this.showShipmentCode(shipment);
      this.originDropIds.push(dropInfo.id);
      this.uiCollapseState.pick.data = pickInfo;
      this.uiCollapseState.drops.push({data: dropInfo});
    } else {
      let shipments = this.modelOrder?.metadata?.shipments || [];
      for (let shipment of shipments) {
        const deliveryInfos = shipment.deliveryInfos || [];
        const pickInfo = deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
        const dropInfo = deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
        const itemIds : any[] = shipment.itemIds ?? [];
        dropInfo.items = this.modelOrder.items?.filter(it => itemIds.includes(it.id));
        dropInfo.shipmentWarpId = this.showShipmentCode(shipment);
        this.originDropIds.push(dropInfo.id);
        if (!Utils.isObjectNotEmpty(this.uiCollapseState.pick)) {
          this.uiCollapseState.pick.data = pickInfo;
        }
        this.uiCollapseState.drops.push({data: dropInfo});
      }
    }
  }

  public getData(): DataAddRemoveStops {
    let currentDropIds = this.uiCollapseState.drops.map(it => it.data?.id);
    let newDropIds = currentDropIds.filter(it => !this.originDropIds.includes(it));
    let deletedDropIds = this.originDropIds.filter(it => !currentDropIds.includes(it));
    return {
      deleted: deletedDropIds,
      new: this.uiCollapseState.drops.filter(it => newDropIds.includes(it.data?.id)).map(it => it.data)
    }
  }

  public canUpdate() {
    if (this.uiCollapseState.drops.length == 0) return false;
    let currentDropIds = this.uiCollapseState.drops.map(it => it.data?.id);
    let newDropIds = currentDropIds.filter(it => !this.originDropIds.includes(it));
    let deletedDropIds = this.originDropIds.filter(it => !currentDropIds.includes(it));
    if (newDropIds.length == 0 && deletedDropIds.length == 0) return false;
    return true;
  }

  onBtnAddDropInfo() {
    DialogService.openDialog(EditShipmentEntryAddLocation, {
      nzMaskClosable: false,
      nzComponentParams: {
        type: Const.TaskType.DROPOFF,
        onSave: (data) => {
          this.uiCollapseState.drops.push({data: data, isAddNew: true });
          this.onListStopsChange.emit();
        }
      },
      nzClassName: "modal-xxl",
      nzWrapClassName: 'add-new-location-form'
    });
  }

  canRemoveDropLocation(index) { return true }

  onBtnRemoveDropInfo(index, shipmentWarpId) {
    const text = shipmentWarpId ? `WarpID ${shipmentWarpId}` : `Delivery ${index + 1}`;
    DialogService.confirmDeletion({
      message: `Remove <b>${text}</b>?`,
      txtBtnOk: 'Remove',
      fnOk: () => {
        this.uiCollapseState.drops.splice(index, 1);
        this.onListStopsChange.emit();
      }
    })
  }


}