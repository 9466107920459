import { Component } from "@angular/core";
import { BaseDetail } from "@app/admin/base/detail";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";
import { FinJob_IntermediateStatus } from "@wearewarp/types";
import { FinQuickbookStatementPodStatus, ResponseAdminFinQuickbookStatementDetail } from "@wearewarp/types-server-admin/fin";
import { CreateQuickbooksBill_V2 } from "../../components/quickbooks/create-bill-v2";
import { DialogService } from "@dialogs/dialog.service";
import { QuickbooksBillCreateSuccessfully } from "../../components/quickbooks/success-dialog";


@Component({
  selector: 'quickbook-statement-detail',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class FinQuickbookStatementDetail extends BaseDetail<ResponseAdminFinQuickbookStatementDetail> {
  public totalAmt: number = 0;
  public isCreatingBill: boolean = false;
  
  ngOnInit() {
    super.ngOnInit();
  }
  
  get listRoute() {
    return this.model.jobs || [];
  }

  get isStatementCreated() {
    return this.model.status === 'created';
  }

  get isStatementSubmitted() {
    return this.model.status === 'submitted';
  }

  get hasFinJobCreatedBill() {
    return this.model.finJobExistedBillInfos?.jobs?.length > 0;
  }

  get listJobHasBeenCreatedBill() {
    return this.model.finJobExistedBillInfos?.jobs || [];
  }

  getApiUrl(): string {
    return Const.APIURI_FINANCES_QB_STATEMENT();
  }

  showStatementCode() {
    return `ST-${this.model.code}` || 'N/A';
  }

  showDisplayMoney(amt) {
    return InputHelper.formatMoney2(`${amt}`);
  }

  showTotalAmount() {
    return this.showDisplayMoney(`${this.model.amt}`);
  }

  showRouteCompletedWindow() {
    const window = this.model.routeCompletedWindow;
    if (!window) return 'N/A';
    const timezone = window.timezone || 'America/New_York';
    const format = 'MMM DD, YYYY';
    let windowTxt = DateUtil.displayTimeWindow(window, {timezone, format, formatDateOnly: format});
    return `${windowTxt} (Based on Route completed date)`;
  }

  showPayee() {
    return this.model.account.name || 'N/A';
  }

  showTotalRoutes() {
    return this.model.jobIds.length || 'N/A';
  }

  showShipments(job) {
    let shipments = job.shipments || [];
    return shipments.map(it => it.code || `S-it.warpId`).join(', ');
  }

  showStatementCreatedTime() {
    const insert = this.model.insert
    if (!insert) return '';
    const timezone = 'America/New_York';
    const tzShort = DateUtil.timezoneStandardToUsShort(timezone);
    const timeDisplay = DateUtil.displayLocalTime(insert.when, {timezone, format: 'MMM DD, YYYY'});
    return `${timeDisplay} (${tzShort})`;
  }

  showSubmittedTime(time) {
    if (!time) return 'N/A';
    const timezone = 'America/New_York';
    const tzShort = DateUtil.timezoneStandardToUsShort(timezone);
    const timeDisplay = DateUtil.displayLocalTime(time, {timezone, format: 'MMM DD, YYYY'});
    return `${timeDisplay} (${tzShort})`;
  }

  showPODStatus(job) {
    if (!job?.podStatus) return 'N/A';
    const podStatus: FinQuickbookStatementPodStatus = job.podStatus;
    switch (podStatus) {
      case 'needPodUpload': return 'Need POD';
      case 'needPodConfirm': return 'Need Confirm';
      case 'podConfirmed': return 'Confirmed';
      default: return 'N/A';
    }
  }

  shouldShowJobWarning(job) {
    if (!job.status) return true;
    return ![Const.JobStatus.completed, Const.JobStatus.canceled].includes(job.status);
  }

  goToDispatch(jobId) {
    return `${Const.routeAdminDispatchList}/${jobId}`;
  }

  goToConfirmPOD(jobId) {
    return `${Const.routeAdminPODNeedConfirm}/${jobId}`;
  }

  shouldCompletePOD(job) {
    const intermediateStatus: FinJob_IntermediateStatus = job.intermediateStatus;
    return intermediateStatus === 'needPodConfirm' || intermediateStatus === 'needPodUpload';
  }

  goToBatchDetail(job) {
    const batchId = job.finBatchId;
    return `${Const.routeAdminFinAP}/${batchId}`;
  }

  showExistedBill_TotalAmt() {
    return `Total amount: ${this.showDisplayMoney(this.model.finJobExistedBillInfos?.amt)}`;
  }

  onBtnCreateQBBill() {
    this.modalService.create({
      nzContent: CreateQuickbooksBill_V2,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzClassName: "modal-xl",
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        finQbStatement: this.model,
        onSuccess: (quickbooksBill) => {
          this.router.navigate([this.routeAdminFinQBStatement]);
          DialogService.openDialog(QuickbooksBillCreateSuccessfully, {
            nzWidth: 400,
            nzClosable: false,
            nzMaskClosable: false,
            nzComponentParams: {
              model: quickbooksBill
            }
          })
        }
      }
    })
  }

}