import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { BulkExternalPosting } from '.';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    NzTableModule,
    NzTagModule,
    NzButtonModule,
    NzToolTipModule
  ],
  declarations: [
    BulkExternalPosting
  ],
  exports: [
    BulkExternalPosting
  ],
  providers: [
  ]
})
export class BulkExternalPostingModule { }