<!-- select -->
<td (nzCheckedChange)="selectItem()" [nzChecked]="isSelected" (click)="stopPropergate($event)"></td>
<!--          Time To Pickup-->
<td nzLeft="0px" style="min-width: 200px;max-width: 200px">
  <div>{{ getTimeToPickup() }}</div>
  <div class="flex" style="gap: 10px">
    <div *ngIf="item?.carrierSalesRep" class="link-icon driver-info" style="cursor: default;" nz-tooltip nzTooltipTitle="Carrier Sales Rep">
      {{getFullName(item.carrierSalesRep)}}
    </div>
    <div *ngIf="item.lockedCounterById" style="width: 50px;">
      <i nz-icon nzType="lock" nzTheme="outline" nz-tooltip [nzTooltipTitle]="tooltipLockCounter"></i>
      <i nz-icon *ngIf="shouldWaringLockCounter" nzType="warning" nzTheme="outline" nz-tooltip [nzTooltipTitle]="tooltipWaringLockCounter" class="warning left5"></i>
    </div>
  </div>
</td>
<!--          Load ID-->
<td nzLeft="200px" style="max-width: 150px;">
  <div>
    <div *ngIf="item.onHold" class="hold-badge">HOLD</div>
    <div *ngIf="item.onHold" class="top20"></div>
    <div *ngIf="item.isGhostLoad">
      <div class="ghost-load-label">{{ getLabelGhostLoad() }}</div>
      <div class="top20"></div>
    </div>
    <div *ngIf="item.isLinehaul">
      <div class="linehaul-label">Linehaul</div>
      <div class="top20"></div>
    </div>
    <div>
      <a [queryParams]="qParams" [routerLink]="[routeAdminDispatchList, item.id]" nz-tooltip
        nzTooltipTitle="Go to dispatch" style="font-size: 13px;" target="_blank">
        <code>{{item.code}}</code>
      </a>
    </div>
    <div>
      <a *ngIf="item.shipments?.length" [fragment]="getRouterLinkFragmentWarp(item.shipments?.[0])"
        [routerLink]="getRouterLinkWarp(item.shipments?.[0])" class="link"
        target="_blank"
      >
        {{getFirstShipmentId(item.shipments)}}
      </a>
      <ng-container *ngIf="item.shipments?.length > 1">
        <a (click)="seeAllOrder()" class="link" style="margin-left: 5px;">(+{{item.shipments.length - 1}})</a>
      </ng-container>
    </div>
  </div>
</td>
<!--          Customer Name-->
<td>
  <div *ngIf="!item.clients || item.clients?.length==0" style="opacity: 60%">N/A</div>
  <div>
    <span *ngIf="item.clients?.length">
      {{getFirstClientName()}}
    </span>
    <ng-container *ngIf="item.clients?.length > 1">
      <span [nzTooltipTitle]="getAllClientNames()" nz-tooltip
        style="margin-left: 5px;">+{{item.clients.length - 1}}</span>
    </ng-container>
  </div>
</td>
<!--          Equipment-->
<td>
  <div class="shipment-type">{{item.shipmentType}}</div>
  <div *ngIf="shouldShowEquipmentAndTemp()" class="bottom5">
    <div *ngIf="item.equipments" class="shipment-mode">{{item.equipments}}</div>
    <div *ngIf="item.tempRange" class="shipment-mode">{{item.tempRange}}</div>
  </div>
</td>
<!--          Pickup Time & Location-->
<td>
  <div style="white-space: pre-wrap;">{{item.pickWindow}}</div>
  <div *ngIf="item.pickInfo" class="arr-client-address">
    <span *ngIf="item.pickInfo.locationName">{{item.pickInfo.locationName + ', '}}</span>
    {{item.pickInfo.addr}}

    <div style="display: inline-block">
      <span [nzPopoverContent]="pickupInfo" nz-icon nz-popover nzPopoverTitle="Location Info"
        nzTheme="outline" nzType="info-circle" style="cursor: pointer;">
      </span>
      <ng-template #pickupInfo>
        <nz-table class="table-info" nzBordered nzSize="small" nzTemplateMode>
          <tbody>
            <tr>
              <td><b>Note</b></td>
              <td> {{getInfo(item, 'PICKUP')?.note || 'N/A'}}</td>
            </tr>
            <tr>
              <td><b>Instructions</b></td>
              <td> {{getInfo(item, 'PICKUP')?.instructions || 'N/A'}}</td>
            </tr>
          </tbody>
        </nz-table>
      </ng-template>
    </div>
  </div>
</td>
    
<!--          Delivery Time & Location-->
<td>
  <div style="white-space: pre-wrap;">{{item.dropWindow}}</div>
  <div *ngIf="item.dropInfo" class="arr-client-address">
    <span *ngIf="item.dropInfo.locationName">{{item.dropInfo.locationName + ', '}}</span>
    {{item.dropInfo.addr}}


    <div style="display:inline-block;">
      <span [nzPopoverContent]="dropoffInfo" nz-icon nz-popover nzPopoverTitle="Location Info" nzTheme="outline"
        nzType="info-circle" style="cursor: pointer;">
      </span>
      <ng-template #dropoffInfo>
        <nz-table class="table-info" nzBordered nzSize="small" nzTemplateMode>
          <tbody>
            <tr>
              <td><b>Note</b></td>
              <td> {{getInfo(item, 'DROPOFF')?.note || 'N/A'}}</td>
            </tr>
            <tr>
              <td><b>Instructions</b></td>
              <td> {{getInfo(item, 'DROPOFF')?.instructions || 'N/A'}}</td>
            </tr>
          </tbody>
        </nz-table>
      </ng-template>
    </div>
  </div>
</td>
<!--          Revenue-->
<td>
  <div>
    {{item.totalShipmentsCost}}
    <i [nzTooltipTitle]="tplCostItems" class="clickable" nz-icon nz-tooltip nzTheme="outline"
      nzTooltipOverlayClassName="tooltip-info" nzTooltipPlacement="bottom" nzType="info-circle"
      style="margin-left: 10px;"></i>
    <ng-template #tplCostItems>
      <ng-container
        [ngTemplateOutletContext]="{cost: item.totalShipmentsCost, units: item.totalShipmentsItems.units, weight: item.totalShipmentsItems.weight}"
        [ngTemplateOutlet]="tplCostItemsInner">
      </ng-container>
      <ng-template #tplCostItemsInner let-cost="cost" let-units="units" let-weight="weight">
        <div class="tplCostItems">
          <div class="value">{{cost}}</div>
          <div class="description">Add up grand total for all shipments on the route</div>
          <div class="value">{{weight}}</div>
          <div class="description">Add up weight for all shipments on the route</div>
          <div class="value">{{units}}</div>
          <div class="description">Add up units for all shipments on the route</div>
        </div>
      </ng-template>
    </ng-template>
  </div>
  <div *ngIf="!isMarketPlaceLoad()" class="shipment-items">
    {{item.totalShipmentsItems?.weight}}
    <ng-container *ngIf="item.totalShipmentsItems?.units?.length">,
      {{item.totalShipmentsItems.units}}</ng-container>
    <div>
      <span>{{getFirstCommodity(item)}}</span>
      <ng-container *ngIf="item.totalShipmentsItems?.commodity.length > 2">
        <span [nzTooltipTitle]="getAllCommodities(item)" nz-tooltip
          style="margin-left: 5px;">+{{item.totalShipmentsItems.commodity.length - 2}}</span>
      </ng-container>
    </div>
  </div>
  <div *ngIf="isMarketPlaceLoad()" class="shipment-items">
    <ng-container *ngIf="isLoadingGhostShipment">
      <nz-spin nzSimple></nz-spin>
    </ng-container>
    <ng-container *ngIf="!isLoadingGhostShipment">
      {{item.totalShipmentsItems?.weight}}
    <ng-container *ngIf="item.totalShipmentsItems?.units?.length">,
      {{item.totalShipmentsItems.units}}</ng-container>
    <div>
      <span>{{getFirstCommodity(item)}}</span>
      <ng-container *ngIf="item.totalShipmentsItems?.commodity.length > 2">
        <span [nzTooltipTitle]="getAllCommodities(item)" nz-tooltip
          style="margin-left: 5px;">+{{item.totalShipmentsItems.commodity.length - 2}}</span>
      </ng-container>
    </div>
    </ng-container>
  </div>
</td>
<!--          Target Rate-->
<td *ngIf="canPerformAction">
  <ng-container *ngIf="item?.datRate?.total_charge?.mediumTotal">
    <div class="bottom10">
      <span class="source-label">DAT:</span>
      <ng-container *ngIf="!item.isLoading">
        <span>{{getMoneyValue(item?.datRate?.cost_update?.cost ||
          item?.datRate?.total_charge?.mediumTotal)}}</span>
        <span (click)="onBtnOnUpdateTargetRate(item)" nz-icon nzTheme="outline" nzType="edit"
          style="color: #1802d0; cursor: pointer;"></span>
            <span (click)="onReUpdateDatRate(item, $event)" nz-icon nzTheme="outline" nzType="reload"
            style="color: #1802d0; cursor: pointer; margin-left: 7px;"></span>
      </ng-container>
      <ng-container *ngIf="item.isLoading">
        <span nz-icon nzTheme="outline" nzType="loading"
        style="color: #1802d0; cursor: pointer; margin-left: 7px;"></span>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="item?.cost?.finance > 0">
    <div class="bottom10">
      <span class="source-label">WARP:</span>
      <span (click)="onReUpdateDatRate(item, $event)">{{getMoneyValue(item?.cost?.finance) }}</span>
    </div>
  </ng-container>
  <div class="shipment-items">
    <ng-container *ngIf="item?.datRate?.cost_update?.cost > 0">
      <b>DAT:</b> {{getMoneyValue(item?.datRate?.total_charge?.mediumTotal)}}
    </ng-container>
  </div>

</td>
<!--          Historic Rate-->
<td nzAlign="left">
  {{item.avgPastRates || 'N/A'}}
  <ng-container *ngIf="!item.isLoadingAvg">
    <span (click)="onReUpdateAvgPastRate(item)" nz-icon nzTheme="outline" nzType="reload" class="reload"></span>
  </ng-container>
  <ng-container *ngIf="item.isLoadingAvg">
    <span nz-icon nzTheme="outline" nzType="loading" class="reload"></span>
  </ng-container>
</td>
<!--          Accepted Rate-->
<td *ngIf="showAcceptedRate">
  <div *ngIf="getBestOffers(item).length==0" style="opacity: 60%">N/A</div>
  <div *ngFor="let offer of getBestOffers(item); let i=index">
    <ng-container *ngIf="!isCarrierReady(offer)">
      <a nz-tooltip [nzTooltipTitle]="getTooltipCarrierStatus(offer)" [ngClass]="{ 'semibold': offer.flagAssigned }">
        {{i + 1}}. ${{offer.price}} - {{offer.carrier?.basicInfo?.name}}
        <ng-container *ngIf="offer.flagAssigned"> (assigned)</ng-container>
      </a>
    </ng-container>
    <ng-container *ngIf="isCarrierReady(offer)">
      <a (click)="onBtnBestOffer(item, offer)" nz-tooltip [nzTooltipTitle]="getTooltipAssignCarrier()" target="_blank" [ngClass]="{ 'semibold': offer.flagAssigned }">
        {{i + 1}}. ${{offer.price}} - {{offer.carrier?.basicInfo?.name}}
        <ng-container *ngIf="offer.flagAssigned"> (assigned)</ng-container>
      </a>
    </ng-container>
  </div>
</td>
<td *ngIf="showQueued">
  <div>{{getNextQueue(item)}}</div>
</td>
<!--          Bid Sent-->
<td *ngIf="isOnTabBidSent">
  <div>
    {{getSentCount(item)}}
  </div>
</td>
<!--          Bid Status-->
<td *ngIf="isOnTabBidReceived" style="min-width: 100px;">
  <div nz-tooltip nzTooltipTitle="Accepted/Refused/Matches" style="display: flex;">
    <div style="color: var(--dispatch-green);">{{getAcceptanceCount(item)}}</div>
    /
    <div style="color: var(--dispatch-red);">{{getRefuseCount(item)}}</div>
    /
    <div>{{getMatchingCount(item)}}</div>
  </div>
  <div *ngIf="item?.carrierBidInfo?.lastSent" class="shipment-items">
    Last sent: <br />{{formatDate(item?.carrierBidInfo?.lastSent)}}
  </div>
</td>
<!--          Refused -->
<td *ngIf="isOnTabBidSent" style="min-width: 100px;">
  {{ getRefuseCount(item) }}
</td>

<!--          Action-->
<td *ngIf="canPerformAction && !isOnTabNotAcceptLoadTender" nzRight="0px" style="min-width: 50px;">
  <div class="flex">
    <div *ngIf="canSendDocs" class="action-item">
      <a (click)="onBtnSendDocs()" *ngIf="item.carrier" class="action-item">
        <i nz-icon nz-tooltip nz-typography nzTheme="outline" nzTooltipPlacement="top" nzTooltipTitle="Send Docs"
          nzType="file-text"></i>
      </a>
    </div>

    <div class="action-item">
      <div *ngIf="hasBid(item)" class="info">
        <a [routerLink]="getRouteLinkDetail(item)" target="_blank">
          View Bid
        </a>
      </div>
      <div *ngIf="!hasBid(item) && !isAdminReadOnlyRole" class="info">
        <a (click)="onClickCreateCarrierBid(item)">
          Create Bid
        </a>
      </div>
      <div class="flex">
        <div (click)="onBtnCarrierSalesRep(item)" class="clickable f16">
          <i nz-icon [nzType]="!item?.carrierSalesRep ? 'user-add': 'user-switch'" nzTheme="outline" class="icon-update-carrier-sale-rep" nz-tooltip nzTooltipTitle="Update Carrier Sales Rep"></i>
        </div>
        <div *ngIf="hasBid(item)" class="clickable left10 f16" (click)="copyPublicLink(item)" nz-tooltip nzTooltipTitle="Copy public link">
          <i nz-icon nzType="link" nzTheme="outline"></i>
        </div>
      </div>
    </div>
  </div>
</td>