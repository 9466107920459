import { NgModule } from '@angular/core';
import { UiCommonModule } from '@components/common/module';
import { CommonModule } from '@angular/common';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ReactiveFormsModule } from '@angular/forms';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzAlertModule } from "ng-zorro-antd/alert";
import { AddRouteComponent } from '.';
import { SelectBySearchingModule } from '@libs/select-client/module';

@NgModule({
  imports: [
    CommonModule,
    UiCommonModule,
    NzSelectModule,
    NzInputModule,
    NzCheckboxModule,
    NzGridModule,
    ReactiveFormsModule,
    NzTableModule,
    NzAlertModule,
    SelectBySearchingModule,
  ],
  declarations: [
    AddRouteComponent
  ],
  exports: [
    AddRouteComponent
  ],
  providers: [
  ]
})
export class AddRouteModule { }