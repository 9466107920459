<style type="text/css">
  .model-btns {
    display: flex; flex-direction: row; align-items: center; justify-content: center;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
  }
  .dlg-success {
    width: 437px;
  }
  .dlg-items {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
    
<div>
  <div class="top20 dlg-items">
    <img class="bottom20" nz-image width="83px" height="83px" nzSrc="/assets/img/ic_success.png" />
  </div>

  <div class="title dlg-items bottom20">{{ countShipment }} shipments {{isCreated ? 'created' : 'updated'}} successfully</div>

  <div *ngIf="ftlOrder" class="bottom10" style="text-align: center;"> 
    <span>FTL Order: <a [routerLink]="[routeAdminOrderList, ftlOrder.id]">{{showOrderCode(ftlOrder)}}</a></span>
  </div>

  <div class="bottom10" style="text-align: center;"> 
    Order WARP ID: 
  </div>

  <div style="text-align: center;"> 
    <span *ngFor="let order of orders; let i = index">
      <a [routerLink]="[routeAdminOrderList, order.warpOrderId]">{{displayOrderCode(order, i)}}</a>
    </span>
  </div>

  <!-- <div *ngFor="let order of orders; let i = index">
    <span> 
      <a [routerLink]="[routeAdminOrderList, order.warpOrderId]" (click)="closeDialog()">{{displayOrderCode(order, i)}}</a>
    </span>
    <div class="dlg-items"><a [href]="getTrackingLink(order)" target="_blank">{{getTrackingLink(order)}}</a>
      <span nz-icon nzType="copy" nzTheme="outline" (click)="copyTrackingLink(order)" nz-tooltip
      nzTooltipTitle="Copy Tracking URL" style="cursor: pointer; margin-left: 8px;"></span>
    </div>
  </div> -->


  <div class="top20 bottom20 model-btns">
    <button *ngIf="isCreated" nz-button nzType="default" class="right10" (click)="onBtnExport()">Export file <span nz-icon nzType="download" nzTheme="outline"></span></button>
    <button nz-button nzType="primary" style="width: 70px;" (click)="onDone()">Done</button>
  </div>
</div>
    