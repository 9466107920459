import { ChangeDetectorRef, Component, ElementRef, QueryList, ViewChildren } from "@angular/core";
import { BaseForm, ValidateOptions } from "@app/admin/base/form-base";
import { FormDataOrderItems } from "@app/admin/shipment-entry/interface";
import { FormOrderItem } from "./item";
import { FormArray, ValidationErrors } from "@angular/forms/forms";
import { FormDataShipmentItem } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { DialogService } from "@dialogs/dialog.service";

@Component({
  selector: '[form-order-items]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class FormOrderItems extends BaseForm<FormDataOrderItems> {
  get originalItems() { return this.model?.items ?? [] }
  items: Array<FormDataShipmentItem> = [];
  private deleteIds: Array<string> = [];
  @ViewChildren('formItem') formItems: QueryList<FormOrderItem>

  constructor(protected hostElement: ElementRef<HTMLElement>, private cd: ChangeDetectorRef) {
    super(hostElement);
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    items: {label: 'Items', required: true, type: 'formArray', initialValue: [FormOrderItem.defaultData], childItem: FormOrderItem.declaration  }
  }

  get itemControls() {
    return (<FormArray>this.formInput?.get?.('items'))?.controls ?? [];
  }

  get canUpdate(): boolean {
    let isFormChanged = false;
    for (let i = 0; i < this.formItems.length; i++) {
      let form: FormOrderItem = this.formItems.get(i);
      if (form.validate() != null) {
        return false;
      }
      if (form.isFormDataChanged()) {
        isFormChanged = true;
      }
    }
    if (this.formItems.length != this.originalItems.length) {
      // thêm hoặc bớt item
      return true;
    }
    return isFormChanged;
  }

  ngOnInit(): void {
    this.setInitialData();
    super.ngOnInit();
  }

  private setInitialData() {
    if (this.model?.items?.length > 0) {
      this.items = this.model.items.slice();
    } else {
      this.items = [FormOrderItem.defaultData];
    }
  }

  getChildrenModel(index: number) {
    return this.model?.items?.[index];
  }

  onBtnUpload() {
    DialogService.showDialog('Coming soon');
  }

  addItem() {
    this.items.push(FormOrderItem.defaultData);
  }

  deleteItem(index: number) {
    let item = this.items[index];
    if (item.warpId) {
      // Nếu có warpId tức là đã được insert vào database rồi, cần gửi lên server để delete
      this.deleteIds.push(item.id);
    }
    this.items.splice(index, 1);
    this.cd.detectChanges();

  }

  messageDeleteItem(index: number): string {
    if (this.items[index].children?.length > 0) {
      return `Remove this item and all its children?`;
    } else {
      return `Remove this item?`
    }
  }

  canDeleteItem(i): boolean {
    return this.items?.length > 1;
  }

  public validate(options?: ValidateOptions): ValidationErrors | { error: ValidationErrors; message: string; } {
    for (let i = 0; i < this.formItems.length; i++) {
      let err = this.formItems.get(i).validate(options);
      if (err) {
        return err;
      }
    }
    return null;
  }

  public getFormData(): FormDataOrderItems {
    let items = [];
    let deleteIds = [];
    for (let i = 0; i < this.formItems.length; i++) {
      deleteIds = [...deleteIds, ...this.formItems.get(i).getDeleteItems()];
      items.push(this.formItems.get(i).getFormData());
    }
    return {items, deleteIds: [...this.deleteIds, ...deleteIds]};
  }

}