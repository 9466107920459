<div class="flex-space-between center-vertical">
  <div class="form-header no-border no-padding-bottom">
    <div class="form-title f16b">Fraud Detection</div>
  </div>
</div>
<div *ngIf="isLoading" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
<div *ngIf="!isLoading && !fraudDetectionData" class="nodata">
  <nz-empty></nz-empty>
</div>
<div class="top10">
  <div class="flex" style="flex-flow: wrap;">
    <ng-container *ngFor="let item of warpData">
      <nz-tag [nzColor]="item.color"
        nz-popover [nzPopoverContent]="logFraud"
        style="margin-right: 5px; margin-bottom: 5px; font-weight: 500"
        (nzPopoverVisibleChange)="onPopoverVisibleChange($event, item)"
      >
      {{ item.description }}  
      </nz-tag>
    </ng-container>
    <ng-template #logFraud>
      <div style="padding: 15px;">
        <ul>
          <ng-container *ngFor="let log of (popoverData ?? [])">
            <li>Reported by {{log.whenBy?.fullName}} at route <a [routerLink]="[log?.hyperLinkUrl]" target="_blank">{{log?.jobInfo?.code}}</a> </li>
          </ng-container>
        </ul>
      </div>
    </ng-template>
    <ng-container *ngFor="let item of highwayData">
      <nz-tag class="nzTag" [nzColor]="item.color"
          style="margin-right: 5px; margin-bottom: 5px; font-weight: 500">{{ item.description }} <img src="/assets/img/GoHighwayIcon.png">
      </nz-tag>
    </ng-container>
  </div>
  
</div>
