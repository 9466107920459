import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import {
  FormDataShipmentEntryBasicInfo,
  FormDataShipmentEntryDraftBatch
} from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { InfoBatchService } from "./info-batch-service";

@Component({
  selector: "shipment-info-batch",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss"],
})
export class ShipmentInfoBatch implements OnInit {
  isEmpty: boolean = true;
  customerAndEquipment: FormDataShipmentEntryBasicInfo;
  // deliveryInfos: Array<DeliveryInfo> = [];
  locations = [];
  private _modelDraft: FormDataShipmentEntryDraftBatch;

  @Input() get modelDraft(): FormDataShipmentEntryDraftBatch { return this._modelDraft};
  set modelDraft(value) {
    if (this._modelDraft == null && value == null) {
      return;
    }
    this._modelDraft = value;
    if (this.modelDraft?.basicInfo){
      this.setCustomerAndEquipment(this.modelDraft.basicInfo);
    }
    this.locations = InfoBatchService.getListDeliveryInfoBySortedStopIds(this.modelDraft);
    this.checkDiffSortedTaskIds();
  }
  @Output() onSortedTaskIdsChanged = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
  }
  checkDiffSortedTaskIds() {
    const newSortedTaskIds = InfoBatchService.getSortedTaskIds(this.locations);
    const isDiff: boolean = InfoBatchService.checkDiffSortedTaskIds(newSortedTaskIds, this.modelDraft?.sortedTaskIds);
    if (isDiff) {
      this.onSortedTaskIdsChanged.emit(newSortedTaskIds);
    }
  }

  onChangeLocations(data) {
    if (data) {
      this.locations = data;
      this.checkDiffSortedTaskIds();
    }
  }

  setCustomerAndEquipment(data) {
    this.customerAndEquipment = data;
    this.checkEmptyState();
  }

  checkEmptyState() {
    this.isEmpty = this.customerAndEquipment == null && (this.locations == null || this.locations?.length == 0);
  }
}
