<div class="container-box">
    <div class="bottom15">
        <div class="bottom5">Date</div>
        <nz-date-picker class="width-100" nzFormat="yyyy-MM-dd" nzPlaceHolder="From Date" [(ngModel)]="fromDate">
        </nz-date-picker>        
    </div>
    <div class="actions">
        <button nz-button [disabled]="!fromDate" (click)="onBtnExport()" nzType="primary" [nzLoading]="downloading">Download</button>
    </div>    
</div>
