<div *ngIf="isFirstLoading" class="nodata">
  <i nz-icon nzType="loading" nzTheme="outline"></i>
</div>

<ng-container *ngIf="hasData">

  <nz-tag *ngIf="isCarrierAcceptedPrice" nzColor="success" nz-tooltip [nzTooltipTitle]="">
    <span nz-icon nzType="check" nzTheme="outline"></span>
    Accepted {{txtCarrierAcceptedPrice}}
  </nz-tag>
  <nz-tag *ngIf="isCarrierRefusedBid" nzColor="error" nz-tooltip [nzTooltipTitle]="getTooltipCarrierRefused()">
    Refused
  </nz-tag>

  <div class="history-box" [ngClass]="{'short': !isExpanded, 'full': isExpanded}">
    <div bid-counter-activity *ngFor="let item of listData; let i = index" 
      [ngClass]="{'hilight': shouldHilight(i), 'first': i == 0}"
      [data]="item" [isNew]="shouldHilight(i)"></div>
  </div>
  <div class="flex top10">
    <div class="flex1">Base price</div>
    <div>{{txtBasePrice}}</div>
  </div>
  
  <div class="bottom-bar">
    <button class="right10"
      nz-button nzType="default" nzSize="small"
      [disabled]="!canCounter"
      (click)="onOpenCounterDialog()">Counter</button>
    <button class="right10"
      nz-button nzType="default" nzSize="small"
      [disabled]="!canEnterCarrierAnswerManually"
      (click)="enterCarrierAnswer()"
      nz-tooltip nzTooltipTitle="Enter Carrier's answer">
      <img src="/assets/svg/question_answer.svg" alt="" style="margin-right: 3px;">
    </button>
    <button
      nz-button nzType="default" nzSize="small"
      [disabled]="!canCounter" (click)="reload()"
      nz-tooltip nzTooltipTitle="Reload">
      <i *ngIf="!isReloading" nz-icon nzType="reload" nzTheme="outline"></i>
      <i *ngIf="isReloading" nz-icon nzType="loading" nzTheme="outline"></i>
    </button>
    <div class="flex1"></div>
    <button *ngIf="needExpandCollapse" nz-button nzType="link" class="btn-expand" (click)="togglePanel()">{{isExpanded ? 'Collase' : 'Expand'}}</button>
  </div>
</ng-container>
