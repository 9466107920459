import { BaseForm } from "@app/admin/base/form-base";
import { ShipmentEntryContext } from "../../interface";
import { Directive, Input } from "@angular/core";
import { FormDataShipmentEntryDraftBatch } from "@wearewarp/types-server-admin/form-data/shipment-entry";

@Directive()
export class ShipmentEntryForm<T> extends BaseForm<T> {
  @Input() context: ShipmentEntryContext;

  ngOnInit(): void {
    this.context.modelDraftChange.subscribe(this.onModelDataChanged);
    super.ngOnInit();
  }

  protected onModelDraftChange(data: FormDataShipmentEntryDraftBatch) {
  }

}