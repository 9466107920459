<div class="route-info-selection">
  <div class="route-info">
    <div class="route-link">
      <span style="margin-right: 6px;">Route</span>
      <a [routerLink]="[jobHyperLink.hyperLinkUrl]" target="_blank">{{bid?.job?.code}}</a>
      <span class="route-status" [ngClass]="this.bid?.job?.status">{{ getRouteStatus() }}</span>
      <span *ngIf="routeInfo?.isMarketplace" class="ghost-load-label">Marketplace</span>
      <span *ngIf="!routeInfo?.isMarketplace && routeInfo?.isGhostLoad" class="ghost-load-label">Ghost Load</span>
      <span *ngIf="routeInfo?.isLinehaul" class="linehaul-label">Linehaul</span>
      <i *ngIf="bid?.jobId" style="margin-left: 10px; color: #8D8D8D" class="clickable"
        nz-tooltip nzTooltipTitle="Route basic info"
        nz-icon nzType="info-circle" nzTheme="outline"
        (click)="showRouteBasicInfo(bid?.jobId)"></i>
    </div>
    <div class="top10">
      <span class="text-label">Customer</span>
      <span class="left5">{{ routeInfo?.clientName }}</span>
      <span *ngIf="routeInfo?.numOfClient && routeInfo?.numOfClient > 1" class="pointer"
        [nzTooltipTitle]="tplMoreClient" nzTooltipPlacement="right" nz-tooltip nz-typography nzEllipsis>
        (+{{routeInfo.numOfClient - 1}})
      </span>
      <ng-template #tplMoreClient>
        <div *ngFor="let name of routeInfo?.arrClientName">{{name}}</div>
      </ng-template>
    </div>
  </div>
  <div job-stop-timeline [stops]="bid?.job?.stops" [mileage]="routeInfo.mileage"></div>
  <div>
    <div class="flex" style="width: 100%; justify-content: flex-end;">
      <div class="bottom10 flex" style="width: 170px;">
        <div *ngIf="routeInfo?.isShowRemainingTimePickup" class="flex" nz-tooltip nzTooltipTitle="Time To Pickup">
          <img src="/assets/img/schedule.svg" style="margin-right: 4px;" />
          <div class="bold flex" style="white-space: nowrap;" [ngClass]="{ 'red' : remainingTime.isNegative }">
            <div *ngIf="remainingTime.isNegative">-</div>
            <div *ngIf="remainingTime.days">{{ remainingTime.days }}d : </div>
            <div>{{ remainingTime.hours }}h : </div>
            <div>{{ remainingTime.minutes }}m : </div>
            <div>{{ remainingTime.seconds }}s</div>
          </div>
        </div>
        <div *ngIf="isShowCarrierSalesLegacy" class="left5">
          <a [routerLink]="gotoLegacyBid()" target="_blank" nz-tooltip nzTooltipTitle="Go to Legacy">
            <span nz-icon nzType="login" nzTheme="outline"></span>
          </a>
        </div>
      </div>
    </div>
    <div class="group-section">
      <div>
        <a (click)="onBtnUpdatePickupTime()">Update Pickup Time</a>
      </div>
      <div *ngIf="routeInfo?.carrierSalesRep" class="flex" style="justify-content: flex-end;">
        <div style="white-space: nowrap;">
          <img src="/assets/img/account_circle_2.svg" style="margin-right: 4px;" />
          <span nz-tooltip nzTooltipTitle="Carrier Sales Rep">{{ routeInfo?.carrierSalesRep }}</span>
          <span (click)="onBtnEditCarrierSalesRep()" nz-icon nzTheme="outline" nzType="edit"
            style="color: #1802d0; cursor: pointer; margin-left: 4px;" nz-tooltip
            nzTooltipTitle="Update Carrier Sales Rep"></span>
        </div>
      </div>
      <div *ngIf="!routeInfo?.carrierSalesRep" style="text-align: end;">
        <i nz-icon nzType="user-add" nzTheme="outline" nz-tooltip nzTooltipTitle="Add Carrier Sales Rep"
          (click)="onBtnEditCarrierSalesRep()" class="pointer"></i>
      </div>
    </div>
  </div>
</div>