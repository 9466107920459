import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Input, OnInit } from '@angular/core';
import { ListNeedAppointmentService } from '../../list-need-appointment.service';
import { ShipmentQueueShipment } from '../../interface';
import { ShipmentQueueDeliveryInfo } from '@wearewarp/types-server-admin';
import { Utils } from '@services/utils';
import { DateUtil } from '@services/date-utils';
import { Const as WarpConst } from '@wearewarp/universal-libs';
import { MasterData } from '@services/master.data';

@Component({
  selector: 'sqv2-order-info',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ShipmentQueueV2OrderInfo extends BaseComponent {
  public shipment: ShipmentQueueShipment;
  public location: ShipmentQueueDeliveryInfo;
  constructor(private listNeedAppointmentService: ListNeedAppointmentService) {
    super();
    this.subscription.add(
      this.listNeedAppointmentService.$currentLocation.subscribe(location => {
        this.location = location;
        this.shipment = this.listNeedAppointmentService.currentShipment;
      })
    )
  }

  ngOnInit(): void {
  }

  public get shipmentIdLevel0() {
    if (this.shipment?.parentId) {
      return this.shipment.parentId;
    }
    return this.shipment.id;
  }

  public get deliveryInfos() {
    return this.shipment.deliveryInfos || [];
  }

  copyValue(value) {
    Utils.copyTextToClipboard(value, (e) => {
      if (e) { } else {
        this.showSuccess(
          `Copied to the clipboard: ${value}`
        );
      }
    });
  }

  getTimePeriods(info) {
    let windows = info?.windows || [];
    if(info?.requiresAppointment && info?.appointmentInfo?.from) {
      windows = [{from: info.appointmentInfo.from, to: info.appointmentInfo.to}]
    }
    windows = windows
      ?.map((window) =>
        DateUtil.displayTimeWindow(window, {
          timezone: info?.addr?.metadata?.timeZoneStandard,
          formatDateOnly: 'MMM DD',
          format: "MMM DD h:mm A",
        })
      );
    return windows?.filter(it => it)
  }

  isMe(location: ShipmentQueueDeliveryInfo) {
    return location.id === this.location.id;
  }

  viewLocationDetail(location: ShipmentQueueDeliveryInfo) {
    let addr = location.addr;
    if (addr) {
      let saveData = {
        locationName: name, filter: {
          street: addr.street,
          city: addr.city,
          state: addr.state,
          zipcode: addr.zipcode
        }
      }
      localStorage.setItem('openDetail', JSON.stringify(saveData));
      window.open(this.routeAdminLocationList, '_blank');
    }
  }

  get orderStatus() {
    if(this.shipment?.parentStatus) return this.getStatusOrder(this.shipment.parentStatus);
    if(this.shipment.status) return this.getStatusOrder(this.shipment.status);
    return this.getStatusOrder(WarpConst.ShipmentStatus.needCarrier);
  }

  getRefNums(location) {
    return (location?.refNums || [])?.filter(it => it);
  }

  getServiceOptions(location) {
    return (location?.serviceOptions || [])?.map(it => MasterData.getServiceOptionName(it))?.filter(it => it);
  }

}
