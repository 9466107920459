import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from  '@angular/common/http';
import { NZ_I18N, NZ_DATE_LOCALE, NzI18nService, en_US } from 'ng-zorro-antd/i18n';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { enUS } from 'date-fns/locale';
import { ModalHelper } from '@wearewarp/ng-antd';
import { WebLibModule } from '@wearewarp/ng-web';
import { DialogsModule } from '@dialogs/dialogs.module';
import { DrawerModule } from '@drawers/drawer.module';
import { SearchBoxModule } from '../libs/search-box/search-box.module';
import { TranslateService } from './locale/index';
import { PageErrorModule } from './page-error/page-error.module';
import { setInjector } from './services';
import { MasterData } from '@services/master.data';
import { AppComponent } from './app';
import { AppRoutingModule } from './app.routing';
import { ApiService } from '@services/api.service';
import { SocketService } from '@app/socket';
import { DialogService } from '@dialogs/dialog.service';
import { DrawerService } from '@app/drawers/drawer.service';
import { NotificationDispatchService } from '@app/drawers/notification-dispatch.service';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { InternalMessageService } from '@services/internal-message.service';
import webLibConfig from '@const/webLibConfig';
import { GlobalSearchModule } from './components/global-search/module';
import { MapboxStaticModule } from './admin/components/mapbox-static/mapbox-static.module';
import { ModuleSetupServer } from './setup-server/module';
import { HttpConfigInterceptor } from '@services/replay-session.interceptor';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzNotificationModule,
    NzModalModule,
    AppRoutingModule,
    PageErrorModule,
    SearchBoxModule,
    DialogsModule,
    DrawerModule,
    NzDrawerModule,
    NzIconModule,
    GlobalSearchModule,
    WebLibModule.forRoot(webLibConfig),
    MapboxStaticModule,
    ModuleSetupServer,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_DATE_LOCALE, useValue: enUS },
    TranslateService,
    ApiService,
    ModalHelper,
    DialogService,
    MasterData,
    TranslateService,
    SocketService,
    DrawerService,
    NotificationDispatchService,
    InternalMessageService,
    {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true}
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule {
  constructor(injector: Injector, private i18n: NzI18nService) {
    setInjector(injector);
    this.i18n.setDateLocale(enUS);  // necessary for manually entering value in nz-date-picker
  }
}
