import { Component, Input } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { InputHelper } from "@services/input-helper";
import { Log } from '@services/log';
import { ZipcodeService } from '@services/zipcode.service';
import { DateUtil } from '@services/date-utils';
import { UsTimezone } from '@wearewarp/types';
import { Const as WarpConst } from '@wearewarp/universal-libs';

@Component({
  selector: '[auto-message-setting]',
  templateUrl: './index.html',
  styleUrls: [
    './style.scss',
    "../../../../styles/row-col.scss",
    "../../../../styles/form-v2.scss"
  ]
})
export class AutoMessagesSetting extends BaseFormDialog1 {
  private zipcodeService: ZipcodeService;

  protected formGroupDeclaration: FormGroupDeclaration = {
    laneType: { label: 'Report Type', required: true, initialValue: 'zipcode' },
    lanes: { label: '', type: 'formArray', initialValue: [{}], childItem: {
      from: { label: "From City or State", required: false, initialValue: "" },
      to: { label: "To City or State", required: false, initialValue: "" },
    }},
    slackChannelIds: { label: 'Slack', type: 'formArray', initialValue: [], childItem: {
      channelId: { label: 'Channel ID', required: true, placeHolder: 'Channel ID' }
    }},
    recipientTos: { label: 'Email (To)', type: 'formArray', initialValue: [{}], childItem: {
      firstName: {label: 'First Name'},
      lastName: {label: 'Last Name'},
      email: {label: 'Email', required: true, validators: Validators.email},
    }},
    recipientCcs: { label: 'Email (CC)', type: 'formArray', initialValue: [], childItem: {
      firstName: {label: 'First Name'},
      lastName: {label: 'Last Name'},
      email: {label: 'Email', required: true, validators: Validators.email},
    }},
    notificationTypes: { label: 'Select Notification Type', required: true, type: 'array', initialValue: [] },
    timeZone: { label: 'Timezone', required: true, type: 'string', initialValue: "America/New_York" },
    sentAt: { label: 'Sent at', required: true, type: 'time', initialValue: new Date().setHours(9,0,0,0) },
    sentEvery: { label: 'Sent every', required: true, type: 'number', initialValue: 30 },
    sentBeforePickup: { label: 'Send before pickup', required: true, type: 'number', initialValue: 2 },
  };

  private _reportType: string;
  @Input() listLocations: any[];
  @Input() notificationTypes: any[];
  @Input() set reportType(value) {
    this._reportType = value;
    const isRequired = value === WarpConst.AutoReportType.specific_lane;
    (this.formGroupDeclaration.lanes.childItem['from'] as any).required = isRequired;
    (this.formGroupDeclaration.lanes.childItem['to'] as any).required = isRequired;
  };
  get reportType() {
    return this._reportType;
  }
  @Input() onSave: (data) => any;
  @Input() headerText = `Auto report notification`;

  public laneTypes = [
    { value: 'zipcode', label: 'Zipcode' },
    { value: 'location', label: 'Location' }
  ]
  private originalModel: any;
  public allShortTimezones = DateUtil.listUsTimezones;

  constructor() {
    super();
    this.zipcodeService = new ZipcodeService(this.api);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initData();
  }

  public listLocationFroms: any[] = [];
  public listLocationTos: any[] = [];
  initData() {
    if(!this.listLocations.length) this.getListLocations();
    if(!this.notificationTypes.length) this.getNotificationTypes();
  }

  public isLoadingNotificationType: boolean = true;
  private getNotificationTypes() {
    this.isLoadingNotificationType = true;
    this.api.GET(Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/CLIENT_SETTINGS/notification_type_settings`)).subscribe(
      resp => {
        this.isLoadingNotificationType = false;
        const value = resp.value || '[]';
        const settings = JSON.parse(value);
        this.notificationTypes = settings;
      }, err => {
        this.isLoadingNotificationType = false;
        this.showErr(err);
      }
    );
  }

  loadingLocation: boolean = false;
  private getListLocations() {
    this.loadingLocation = true;
    let url = `${Const.APIURI_WAREHOUSES}/list/all_for_filter?exposeShortAddr=true`;
    this.api.GET(url).subscribe(
      resp => {
        this.loadingLocation = false;
        this.listLocations = (resp.data.list_data || []).filter(it => it.name || it.pickAddr?.zipcode);
      }, err => {
        this.showErr(err);
        this.loadingLocation = false;
      }
    );
  }

  showWarehouseName(item) {
    let arr = [], address = [];
    if (item?.name) arr.push(item.name);
    if (item?.pickAddr?.city) address.push(item.pickAddr.city);
    if (item?.pickAddr?.state) address.push(item.pickAddr.state);
    if (item?.pickAddr?.zipcode) address.push(item.pickAddr.zipcode);
    arr.push(address.join(', '));
    return arr.join(" - ")
  }


  onChangeNotification(value: any) {
    this.setItemValue(`notificationTypes`, value);
  }

  getCheckedNotification(key: string): boolean {
    const notificationTypes = this.getItemValue(`notificationTypes`);
    return notificationTypes.includes(key);
  }

  getLaneType(): string {
    return this.getItemValue(`laneType`);
  }

  onChangeLaneType(event) {
    Log.d('onChangeLaneType', event);
    this.setItemValue('lanes', [{ from: '', to: '' }]);
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'phoneNumber': return InputHelper.handleInputChangePhone(event, <FormControl>this.formInput.get(key));
      case 'from':
      case 'to':
        if (!this.zipcodeInfo) {
          this.zipcodeInfo = {};
        }
        if (!this.zipcodeInfo?.[key]) {
          let defaultObj = {
            city: '',
            state: '',
            altCities: [],
            isLoading: false,
            error: false,
            desc: ''
          }
          this.zipcodeInfo[key] = {...defaultObj};
        }
        const validated = InputHelper.handleInputKeyZipcode(event, <FormControl>this.formInput.get(key));
        if (!validated) {
          this.zipcodeInfo[key].city = '';
          this.zipcodeInfo[key].state = '';
          this.zipcodeInfo[key].altCities = [];
          this.zipcodeInfo[key].isLoading = false;
          this.zipcodeInfo[key].error = true;
          this.zipcodeInfo[key].desc = 'Invalid zip code'
          this.formInput?.get(key)?.updateValueAndValidity();
        } else {
          this.loadZipcode(key, event.target.value ?? '')
        }
        break;
      default:
        return super.onInputChanged(event, key);
    }
  }

  onInputKeyPress(event: KeyboardEvent, key): boolean {
    switch (key) {
      case 'phoneNumber': return InputHelper.handleInputKeyPressNumberOnly(event);
      default: return super.onInputKeyPress(event, key);
    }
  }

  getApiListDataForFilter(key: string): string|undefined {
    switch (key) {
      case 'from': 
      case 'to': 
        return Const.APIURI_WAREHOUSES;
      default: return
    }
  }

  zipcodeInfo = {};
  private loadZipcode(key, zipcode) {
    if (zipcode.length == 0) {
      return;
    }
    zipcode = zipcode.replace(' ', '')
    if (zipcode == this.zipcodeInfo[key].zipcode && this.zipcodeInfo[key].city && this.zipcodeInfo[key].state) {
      return;
    }
    this.zipcodeInfo[key] = {};
    this.zipcodeInfo[key].isLoading = true;
    this.zipcodeService.get(zipcode, resp => {
      this.zipcodeInfo[key].zipcode = zipcode;
      this.zipcodeInfo[key].city = resp.city;
      this.zipcodeInfo[key].state = resp.state;
      this.zipcodeInfo[key].desc = `${resp.city}, ${resp.state}`;
      this.zipcodeInfo[key].center = resp.center;
      this.zipcodeInfo[key].altCities = resp.altCities;
      this.zipcodeInfo[key].isLoading = false;
      this.formInput?.get(key)?.updateValueAndValidity();
      Log.d('search zipcode success. ', this.zipcodeInfo);
    }, err => {
      this.zipcodeInfo[key].isLoading = false;
      this.zipcodeInfo[key].error = true;
      this.zipcodeInfo[key].desc = 'Invalid zip code'
      this.formInput?.get(key)?.updateValueAndValidity();
      Log.e('search zipcode failed. ', this.zipcodeInfo);
    })
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  get btnSubmitLabel(): string {
    return this.isCreateNew ? 'Add' : this?.model?.isClone ? 'Save' : 'Update';
  }

  protected beforeBindModel(model: any) {
    this.originalModel = { ...model }
    const lanes = [];
    for(let lane of model?.lanes) {
      if(model.laneType === "location") {
        this.listLocationFroms.push({
          name: lane?.from?.name,
          pickAddr: {
            city: lane?.from?.city,
            state: lane?.from?.state,
            zipcode: lane?.from?.zipcode
          },
          id: lane?.from?.warehouseId
        });
        this.listLocationTos.push({
          name: lane?.to?.name,
          pickAddr: {
            city: lane?.to?.city,
            state: lane?.to?.state,
            zipcode: lane?.to?.zipcode
          },
          id: lane?.to?.warehouseId
        });
      }
      if(model.laneType === "zipcode") {
        if(lane?.from?.zipcode) {
          this.zipcodeInfo['from'] = {
            city: lane?.from?.city,
            state: lane?.from?.state,
            zipcode: lane?.from?.zipcode,
            desc: `${lane?.from?.city}, ${lane?.from?.state}`,
            center: lane?.from?.center,
            altCities: lane?.from?.altCities
          }
        }
        if(lane?.to?.zipcode) {
          this.zipcodeInfo['to'] = {
            city: lane?.to?.city,
            state: lane?.to?.state,
            zipcode: lane?.to?.zipcode,
            desc: `${lane?.to?.city}, ${lane?.to?.state}`,
            center: lane?.to?.center,
            altCities: lane?.to?.altCities
          }
        }
      }
      const from = model.laneType === "location" ? lane?.from?.warehouseId : lane?.from?.zipcode;
      const to = model.laneType === "location" ? lane?.to?.warehouseId : lane?.to?.zipcode;
      lanes.push({ from, to });
    }
    model.lanes = lanes;
    return model;
  }

  get needUpdate(): boolean {
    return this.model?.isClone || super.needUpdate;
  }

  onBtnSave() {
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave({ ...data, lanes: this.convertDataLanes(data) });
    this.onProgress = false;
    this.closeDialog();
  }

  get isSpecificLaneMode(): boolean {
    return this.reportType === WarpConst.AutoReportType.specific_lane;
  }

  get isAllLaneMode(): boolean {
    return this.reportType === WarpConst.AutoReportType.all_lane;
  }

  getTimeZoneStandard(timezone: UsTimezone) {
    return DateUtil.mapTimezoneUS(timezone);
  }

  getTimeZoneShort(timezone: UsTimezone) {
    return DateUtil.usTimezoneShortDesc(timezone);
  }

  private convertDataLanes(data) {
    if(this.reportType !== WarpConst.AutoReportType.specific_lane) return [];
    const lanes = data?.lanes || [];
    const result = [];
    for(let lane of lanes) {
      let dataG = {
        from: {},
        to: {},
        fromText: '',
        toText: ''
      }

      const dataFrom = this.listLocations.find(it => it.id === lane.from);
      const dataTo = this.listLocations.find(it => it.id === lane.to);
      const { metadata: metadataFrom, ...restFrom } = dataFrom?.pickAddr || {};
      const { metadata: metadataTo, ...restTo } = dataTo?.pickAddr || {};
      this.zipcodeInfo['from'] = { ...(this.originalModel?.from || {}), ...this.zipcodeInfo['from'] }
      this.zipcodeInfo['to'] = { ...(this.originalModel?.to || {}), ...this.zipcodeInfo['to'] }
      const dataGFrom = { ...(this.zipcodeInfo['from'] || {}), name: dataFrom?.name, ...(restFrom || {}) }
      const dataGTo = { ...(this.zipcodeInfo['to'] || {}), name: dataTo?.name, ...(restTo || {}) }

      dataG = {
        from: data.laneType === 'location' ? { ...dataGFrom, warehouseId: lane.from } : this.zipcodeInfo['from'],
        to: data.laneType === 'location' ? { ...dataGTo, warehouseId: lane.to } : this.zipcodeInfo['to'],
        fromText: data.laneType === 'location' ? `${dataGFrom.name} - ${dataGFrom?.city}, ${dataGFrom?.state}, ${dataGFrom?.zipcode}` : `${this.zipcodeInfo['from']?.city}, ${this.zipcodeInfo['from']?.state}, ${this.zipcodeInfo['from']?.zipcode}`,
        toText: data.laneType === 'location' ? `${dataGTo.name} - ${dataGTo?.city}, ${dataGTo?.state}, ${dataGTo?.zipcode}` : `${this.zipcodeInfo['to']?.city}, ${this.zipcodeInfo['to']?.state}, ${this.zipcodeInfo['to']?.zipcode}`,
      }

      result.push(dataG);
    }

    return result;
  }
}
