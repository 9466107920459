import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FormInputCostModule } from "@app/admin/base/form-input-cost/module";
import { ModuleRating } from "@app/admin/components/rating/module";
import { DialogsModule } from "@dialogs/dialogs.module";
import { SearchBoxModule } from "@libs/search-box/search-box.module";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { AddCarrierByServiceAreaModule } from "../add-carrier-by-area/module";
import { AddCarrierPoolByAreaModule } from "../add-carrier-pool-by-area/module";
import { FormInputUploadImagesModule } from "@app/admin/base/form-input-upload-images/module";
import { CreateByUserModule } from "@app/admin/components/create-by-user/module";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { SharedModule } from "@app/admin/shared/shared.module";
import { CarrierSaleCarrierResult } from ".";
import { CarrierSaleTabResponseReceived } from "./result-tab-response-received";
import { CarrierSaleExpandMoreCandidateInfos } from "./result-tab-response-received/more-candidate-infos";
import { CarrierSaleTabNoResponse } from "./result-tab-no-response";
import { DetailCarrierRatingModule } from "@app/admin/carriers/detail-carrier-rating/module";
import { ModuleBidCounterActivities } from "@app/admin/components/carrier-bid/counter-activities/module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzTagModule,
    NzTableModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzInputModule,
    NzFormModule,
    NzPaginationModule,
    NzBadgeModule,
    NzToolTipModule,
    SearchBoxModule,
    DialogsModule,
    SelectBySearchingModule,
    FormInputCostModule,
    ModuleRating,
    ModuleBidCounterActivities,
    AddCarrierByServiceAreaModule,
    AddCarrierPoolByAreaModule,
    FormInputUploadImagesModule,
    CreateByUserModule,
    SharedModule,
    DetailCarrierRatingModule
  ],
  declarations: [
    CarrierSaleCarrierResult,
    CarrierSaleTabNoResponse,
    CarrierSaleTabResponseReceived,
    CarrierSaleExpandMoreCandidateInfos
  ],
  exports: [
    CarrierSaleCarrierResult,
    CarrierSaleTabNoResponse,
    CarrierSaleTabResponseReceived,
  ],
  providers: [
  ]
})
export class CarrierSaleResultModule {}