import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { BidCounterActivities } from '.';
import { ModuleBidCounterActivity } from '../counter-activity/module';
import { NzTagModule } from 'ng-zorro-antd/tag';

@NgModule({
  imports: [
    CommonModule,
    NzButtonModule,
    NzCollapseModule,
    NzIconModule,
    NzToolTipModule,
    NzTagModule,
    ModuleBidCounterActivity,
  ],
  declarations: [BidCounterActivities],
  exports: [BidCounterActivities]
})
export class ModuleBidCounterActivities {}