import { Component, Input } from "@angular/core";
import { TaskType } from "@wearewarp/types"
import { FormDataEditLocationServiceOptions } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Log } from "@services/log";
import { ServiceOptionsHelper } from "../../forms/shipment-location/helper/service-options";
import { Const } from "@const/Const";
import { Observable } from "rxjs";

@Component({
  selector: 'edit-shipment-entry-service-option',
  templateUrl: './view.html',
  styleUrls: ['./index.scss']
})
export class EditShipmentEntryServiceOption extends BaseFormDialog1<FormDataEditLocationServiceOptions> {

  @Input() onSave: (data: FormDataEditLocationServiceOptions) => Observable<any>;
  @Input() onRefreshDetailOrder: () => void;
  @Input() type: TaskType;
  @Input() headerText = 'Edit Service Option';

  protected formGroupDeclaration: FormGroupDeclaration = {
    serviceOptions: {label: 'Service Options'},
  }

  public serviceOptionsHelper: ServiceOptionsHelper;

  ngOnInit(): void {
    if (!this.type) {
      throw Error('type is required');
    }
    this.serviceOptionsHelper = new ServiceOptionsHelper(this.type, () => this.getItemValue('serviceOptions'), data => this.setItemValue('serviceOptions', data));
    super.ngOnInit();
    this.serviceOptions.selectedItems = this.model.serviceOptions;
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  getLabel(key: string): string {
    const prefix = this.type == Const.TaskType.PICKUP ? 'Pickup' : 'Delivery';
    switch (key) {
      case 'serviceOptions': return `${prefix} Service Options`;
      default: return super.getLabel(key);
    }
  }

  get serviceOptions() { return this.serviceOptionsHelper.params }

  serviceOptionsSelectionChange() {
    this.serviceOptionsHelper.serviceOptionsSelectionChange();
  }

  onBtnSave() {
    if (!this.needUpdate) return
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onRefreshDetailOrder();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

}
