import { Observable } from "rxjs";
import { ApiService } from "./api.service";

import { environment } from "@env/environment";
import { map } from "rxjs/operators";
import TTLCache from "@isaacs/ttlcache";
const LINEHAULLANE = 'LINEHAULLANE'

export class PlanningService {
    api: ApiService = null
    baseUrl: string = null
    backendUrl: string = null
    static cache = new TTLCache({max: 10000, ttl: 60000})

    constructor(api: ApiService) {
        this.api = api
        this.baseUrl = environment.planningUrl;
        this.backendUrl = environment.backendUrl;
    }

    public listActiveSession() {
        const url = `${this.baseUrl}/sessions?limit=100`
        return this.api.GET(url)
    }
    public listPrevSession() {
        const url = `${this.baseUrl}/sessions/prev?limit=100`
        return this.api.GET(url)
    }

    public getSessionDetail(id) {
        const url = `${this.baseUrl}/sessions/${id}`
        return this.api.GET(url)
    }
    public getSessionExportedRoutes(id) {
        const url = `${this.baseUrl}/sessions/${id}/exported`
        return this.api.GET(url)
    }

    public getSessionShipments(id) {
        const url = `${this.baseUrl}/sessions/${id}/shipments?full=true`
        return this.api.GET(url)
    }

    public removeSessionShipment(id, shipmentId) {
        const url = `${this.baseUrl}/sessions/${id}/shipments/${shipmentId}`
        return this.api.DELETE(url)
    }

    public addVehicle(id, vehicle) {
        const url = `${this.baseUrl}/sessions/${id}/fleet`
        return this.api.POST(url, vehicle)
    }

    public setGroup(id, shipmentIds, groupName) {
        const url = `${this.baseUrl}/sessions/${id}/shipments/set-group`
        return this.api.POST(url, {shipmentIds, groupName})
    }

    public unsetGroup(id, shipmentIds) {
        const url = `${this.baseUrl}/sessions/${id}/shipments/unset-group`
        return this.api.POST(url, {shipmentIds})
    }

    public removeVehicle(id, vehicle) {
        const url = `${this.baseUrl}/sessions/${id}/fleet/${vehicle.id}`
        return this.api.DELETE(url)
    }

    public addShipmentToPlanning(id) {
        const url = `${this.baseUrl}/shipments/${id}/sessions`
        return this.api.POST(url)
    }

    public addShipmentsToPlanning(ids) {
        const url = `${this.baseUrl}/sessions/add-shipment`
        return this.api.POST(url, {ids})
    }

    public triggerRouting(id) {
        const url = `${this.baseUrl}/sessions/${id}/trigger-routing`
        return this.api.POST(url)
    }

    public notifyExport(id, jobId, solutionId, routeId) {
        const url = `${this.baseUrl}/sessions/${id}/export`
        return this.api.POST(url, {
            jobId,
            routeId,
            solutionId
        })
    }

    public notifyExporting(id, solutionId, routeId, shipmentIds) {
        const url = `${this.baseUrl}/sessions/${id}/exporting`
        return this.api.POST(url, {
            solutionId,
            routeId,
            shipmentIds
        })
    }

    public loadShipmentPlanningSessions(shipmentIds) {
        const url = `${this.baseUrl}/sessions/search`
        return this.api.POST(url, {
            shipmentIds,
        })
    }

    public listShipmentsNeedRouting() {
        const url = `${this.baseUrl}/need-planning/routing`
        const now = Date.now()
        const weekFromNow = new Date(now + 2 * 24 * 3600 * 1000)
        return this.api.POST(url, {
            shipmentType: 'LTL',
            // pickupTime: {
            //     to: weekFromNow.toISOString()
            // }
        })
    }

    public getJob(jobId) {
        const url = `${this.baseUrl}/job/${jobId}`
        return this.api.GET(url)
    }

    public loadJobTasks(jobId) {
        const url = `${this.baseUrl}/job/${jobId}/tasks`
        return this.api.GET(url)
    }

    public loadJobsDeliveryStatus(ids) {
        const url = `${this.baseUrl}/job/delivery-status`
        return this.api.POST(url, {ids})
    }

    /**
     * Line haul
     */
    public listLinehaulLanes(limit: number = 100, skip: number = 0) {
        const url = `${this.baseUrl}/linehaul-lane?skip=${skip}&limit=${limit}`
        return this.api.GET(url).pipe(
            map(data => {
                if (data?.length) {
                    for (let l of data) {
                        PlanningService.cache.set(`${LINEHAULLANE}_${l.id}`, l)
                    }
                }
                return data
            })
        )
    }
    public getLinehaulLane(id, refresh=false) {
        const cacheKey = `${LINEHAULLANE}_${id}`
        if (!refresh) {
            if (PlanningService.cache.has(cacheKey)) {
                return new 
                Observable((observer) => observer.next(PlanningService.cache.get(cacheKey)))
            }
        }

        const url = `${this.baseUrl}/linehaul-lane/${id}`
        return this.api.GET(url).pipe(
            map(data => {
                PlanningService.cache.set(cacheKey, data)
                return data
            })
        )
    }

    public scheduleShipment(laneId, jobId, shipmentIds, vehicleType, fromTime, toTime) {
        const url = `${this.baseUrl}/linehaul-lane/${laneId}/schedule`
        return this.api.POST(url, {shipmentIds, jobId, vehicleType, fromTime, toTime})
    }

    public removeJobShipment(jobId, shipmentIds) {
        const url = `${this.baseUrl}/job/${jobId}/remove-shipment`
        return this.api.POST(url, {shipmentIds})
    }

    public listLinehaulLanePendingShipment(id) {
        const url = `${this.baseUrl}/linehaul-lane/${id}/pending`
        return this.api.GET(url)
    }

    public listLinehaulLaneCompletedJobs(id, skip = 0) {
        const url = `${this.baseUrl}/linehaul-lane/${id}/job?status=completed&limit=10&skip=${skip ?? 0}`
        return this.api.GET(url)
    }

    public updateJobType(jobId: string, type: string) {
        const url = `${this.baseUrl}/job/${jobId}/switch-type`
        return this.api.POST(url, type)
    }

    public getBidSessionByPlanningId(planningId: string) {
        const url = `${this.backendUrl}/v2/bid-sessions/get-by-planning-session/${planningId}`
        return this.api.GET(url)
    }
}