<div #filter filter-layout [showClearButton]="false" [isShowMoreFilter]="true" ></div>

<ng-template #tplFilter_shipmentWarpId let-form="form" let-key="key">
  <ng-container [formGroup]="form">
    <nz-input-group>
      <input
        (ngModelChange)="onFilterChange(key, $event)"
        [formControlName]="key"
        [placeholder]="'Enter WARP ID'"
        nz-input
      >
    </nz-input-group>
  </ng-container>
</ng-template>
<ng-template #tplFilter_customer let-form="form" let-key="key">
  <ng-container [formGroup]="form">
    <div style="min-width: 200px;">
      <select-by-searching
        (modelChange)="onFilterChange(key, $event)"
        [formControlName]="key"
        [showSubClient]="true"
        [placeholder]="'Select'" [version]="2"
        dropdownClassName="filter-customer" nzMode="multiple">
      </select-by-searching>
    </div>
  </ng-container>
</ng-template>
<ng-template #tplFilter_type_customer let-form="form" let-key="key">
  <ng-container [formGroup]="form">
    <div style="min-width: 100px;">
      <nz-select [formControlName]="key"  (ngModelChange)="onFilterChange(key, $event)" style="width: 100%">
        <nz-option nzLabel="Include" nzValue="include"></nz-option>
        <nz-option nzLabel="Exclude" nzValue="exclude"></nz-option>
      </nz-select>
    </div>
  </ng-container>
</ng-template>
<ng-template #tplFilter_contact_method let-form="form" let-key="key">
  <ng-container [formGroup]="form">
    <div style="min-width: 100px;">
      <nz-select [formControlName]="key" [nzPlaceHolder]="'Select'" [nzAllowClear]="true"
        (ngModelChange)="onFilterChange(key, $event)" style="width: 100%">
        <nz-option nzLabel="Email" nzValue="email"></nz-option>
        <nz-option nzLabel="SMS" nzValue="sms"></nz-option>
        <nz-option nzLabel="Phone" nzValue="phone"></nz-option>
      </nz-select>
    </div>
  </ng-container>
</ng-template>
<ng-template #tplFilter_appointment_status let-form="form" let-key="key">
  <ng-container [formGroup]="form">
    <div style="min-width: 200px;">
      <nz-select [formControlName]="key" [nzPlaceHolder]="'Select'" [nzAllowClear]="true"
        (ngModelChange)="onFilterChange(key, $event)" style="width: 100%">
        <nz-option [nzDisabled]="!shouldShowStatus(0)" nzLabel="Appointment Needed" [nzValue]="0"></nz-option>
        <nz-option [nzDisabled]="!shouldShowStatus(1)" nzLabel="Awaiting Response" [nzValue]="1"></nz-option>
        <nz-option [nzDisabled]="!shouldShowStatus(2)" nzLabel="Made Appointment" [nzValue]="2"></nz-option>
      </nz-select>
    </div>
  </ng-container>
</ng-template>
<ng-template #tplFilter_appointment_reattempt let-form="form" let-key="key">
  <ng-container [formGroup]="form">
    <div>
      <input nz-input type="number" (ngModelChange)="onFilterChange(key, $event)"
        [formControlName]="key" >
    </div>
  </ng-container>
</ng-template>
<ng-template #tplFilter_appointment_task_type let-form="form" let-key="key">
  <ng-container [formGroup]="form">
    <div>
      <label nz-checkbox [formControlName]="key" (ngModelChange)="onFilterChange(key, $event)">{{getPlaceholder(key)}}</label>
    </div>
  </ng-container>
</ng-template>
<ng-template #tplFilter_delivery_time let-form="form" let-key="key">
  <ng-container [formGroup]="form">
    <div style="min-width: 100px;">
      <nz-date-picker [formControlName]="key" (ngModelChange)="onFilterChange(key, $event)" [nzPlaceHolder]="getPlaceholder(key)" style="width: 100%;"></nz-date-picker>
    </div>
  </ng-container>
</ng-template>

