import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { FinQBStatementCountTab, QBStatementTab, QBStatementWindow } from "../../interface";
import { ActivatedRoute } from "@angular/router";
import { QuickBooksService } from "@services/quickbooks.service";
import { Const } from "@const/Const";
import { ConstFin } from "@wearewarp/js-const-finance";
import { ResponseAdminFinAccountForFilter } from "@wearewarp/types-server-admin/fin";
import { DateUtil } from "@services/date-utils";
import moment from "moment";
import { FinStatementTimeWindow } from "@wearewarp/types/data-model/types/TimeWindow";
import { FinQuickbookStatementStatus } from "@wearewarp/types/data-model";
import { DialogService } from "@dialogs/dialog.service";
import { ConfirmCreateQBStatementDialog } from "../create-statement-dialog";
import { Utils } from "@services/utils";

@Component({
  selector: '[quickbook-statement-filter]',
  templateUrl: './index.html',
  styleUrls: [ './index.scss' ]
})

export class QuickbookStatementFilter extends BaseFormItem {
  @Input() set countSum(value: FinQBStatementCountTab) {
    for (let item of this.filterTabs) {
      item.total = value[item.key] || 0;
    }
  }
  @Output() onFilterTabChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

  filterTabs = [
    {
      key: QBStatementTab.statements,
      title: 'Statements',
      total: 0
    },
    {
      key: QBStatementTab.submitted,
      title: 'Submitted',
      total: 0
    },
    {
      key: QBStatementTab.needRouteCheck,
      title: 'Need Route Check',
      total: 0
    }
  ]
  private filterTabsIndex = {
    statements: 0,
    submitted: 1,
    needRouteCheck: 2,
  }
  public windowOptions: {label: string, value: number}[] = [
    { label: 'Last week', value: QBStatementWindow.lastWeek },
    { label: 'Last 4 weeks', value: QBStatementWindow.last4Weeks },
    { label: 'Last 8 weeks', value: QBStatementWindow.last8Weeks },
  ]

  quickbookService: QuickBooksService;
  listFinAccounts: ResponseAdminFinAccountForFilter[] = [];
  isCreatingStatement: boolean = false;
  isFetchingFinAccounts: boolean = false;
  isExporting: boolean = false;

  private condition: {status?: string, accountIds?: string[], routeCompletedWindow?: FinStatementTimeWindow} = {}
  private sort: any = undefined;
  protected formGroupDeclaration: FormGroupDeclaration = QuickbookStatementFilter.declaration;

  public static get declaration(): FormGroupDeclaration {
    return {
      accountIds: {label: '', notAcceptEmpty: true, placeHolder: 'Search by Payee'},
      windowOption: {label: '', type: 'number'}
    }
  }

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      if (p.filter) {
        try {
          this.condition = JSON.parse(p.filter);
        } catch {
          this.condition = {};
        }
      }
      this.sort = p.sort || undefined;
      let tab = p.tab || this.filterTabs[0].key;
      this._selectedTabIndex = this.filterTabsIndex[tab] || 0;
      this.onFilterTabChanged.emit(this._selectedTabIndex);
    })
    this.quickbookService = new QuickBooksService(this.api);
    this.onRefresh();
  }

  private _selectedTabIndex = 0;

  get selectedTabIndex() {
    return this._selectedTabIndex;
  }

  set selectedTabIndex(value) {
    if (this._selectedTabIndex == value) {
      return;
    }
    this.sort = undefined;
    this._selectedTabIndex = value;
    this.onTabIndexChange(value);
  }

  get shouldShowCreateStatement() {
    return this.selectedTabIndex === this.filterTabsIndex.statements;
  }


  ngOnInit() {
    this.model = Object.assign({}, this.condition);
    super.ngOnInit();
    this.fetchDataForFilter()
  }

  fetchDataForFilter() {
    this.fetchFinAccountList();
  }

  private fetchFinAccountList() {
    let url = Const.APIURI_FINANCES_BATCH(`fin_accounts?finType=${ConstFin.FinType.payable}`);
    this.isFetchingFinAccounts = true;
    this.api.GET(url).subscribe(
      resp => {
        this.listFinAccounts = resp.data.list_data;
        this.isFetchingFinAccounts = false;
      }, err => {
        this.showErr(err);
        this.isFetchingFinAccounts = false;
      }
    );
  }
  
  onTabIndexChange(value) {
    this.condition.status = this.getTabFilterStatus(value);
    this.onFilterTabChanged.emit(value);
    this.onRefresh();
  }

  getTabFilterStatus(tabIndex): FinQuickbookStatementStatus | undefined {
    if (tabIndex === this.filterTabsIndex.statements) {
      return 'created';
    }
    else if (tabIndex === this.filterTabsIndex.submitted) {
      return 'submitted';
    }
    else if (tabIndex === this.filterTabsIndex.needRouteCheck) {
      return 'needRouteCheck';
    }
    return undefined;
  }


  onRefresh() {
    if (!this.condition.status) {
      this.condition.status = this.getTabFilterStatus(this.selectedTabIndex);
    }
    if (this.sort) {
      this.sort = null;
    }
    this.loadData();
  }

  // Nếu loadData do đổi filter thì quay về trang đầu
  loadData() {
    let query = {page: 1, loaded: Date.now()};
    query['tab'] = this.filterTabs[this._selectedTabIndex].key;
    query['filter'] = JSON.stringify({...this.condition});
    if (this.sort) {
      query['sort'] = this.sort;
    }
    this.routeWithQueryUrl(query);
  }

  openDialogCreateStatement() {
    // Cố định tạo statement sẽ là last week (sau cần sẽ update lại)
    let windowOption = QBStatementWindow.lastWeek;
    if (windowOption == null || windowOption == undefined) {
      this.showErr('Please select window option');
      return;
    }
    const timezone = 'America/New_York';
    let routeCompletedWindow = this.getTimeWindowFromOption(windowOption, timezone);

    let modelRef = DialogService.openDialog(ConfirmCreateQBStatementDialog, {
      nzComponentParams: {
        routeCompletedWindow,
        windowOption
      },
      nzTitle: 'Create Statement',
      nzFooter: [
        {
          label: 'Cancel',
          type: 'default',
          onClick: () => modelRef.destroy()
        },
        {
          label: 'Create Statement',
          type: 'primary',
          onClick: (comp) => {
            let window = routeCompletedWindow;
            if (comp.needCustomWindow) {
              window = comp.customWindow;
            }
            if (!window.from || !window.to) {
              this.showErr('windowFrom and windowTo are required');
              return;
            }
            this.onCreateStatement(window);
            modelRef.destroy();
          }
        }
      ]
    })
  }

  // Hiện chỉ tạo statement cho Walmart
  onCreateStatement(routeCompletedWindow: FinStatementTimeWindow) {
    const url = Const.APIURI_FINANCES_QB_STATEMENT('create-statement-bill');
    const params = { routeCompletedWindow };
    this.isCreatingStatement = true;
    this.api.POST(url, params).subscribe(
      resp => {
        let total = resp?.data?.total;
        this.showSuccess(`Created ${total} statements successfully`);
        this.isCreatingStatement = false;
        this.refresh.emit();
      }, 
      err => {
        this.showErr(err);
        this.isCreatingStatement = false;
      }
    )
  }

  getTimeWindowFromOption(option: number, timezone = 'America/New_York'): FinStatementTimeWindow | undefined {
    // default là last week
    let currentPST = DateUtil.convertLocalTime2(new Date().toISOString(), timezone);
    let currentDayPST = currentPST.getDay();

    // Date range theo PST sẽ lấy từ (Friday 00:00:00 -> Thursday 23:59:59)
    let dayAfterLastThus = (currentDayPST - 4 + 7) % 7;
    if (dayAfterLastThus == 0) {
      dayAfterLastThus = 7;     // Nếu đang là thứ 5 thì lấy thứ 5 tuần trước
    }
    let lastThus = new Date(currentPST.getTime() - dayAfterLastThus * 24 * 60 * 60 * 1000);
    let timeToPST = DateUtil.toEndOfDay(lastThus);
    let timeFromPST = null;
    let tmpFriday;

    switch (option) {
      case QBStatementWindow.lastWeek: 
        tmpFriday = moment(lastThus).subtract(6, 'days').toDate();
        break;
      case QBStatementWindow.last4Weeks: 
        tmpFriday = moment(lastThus).subtract(3*7 + 6, 'days').toDate();
        break;
      case QBStatementWindow.last8Weeks: 
        tmpFriday = moment(lastThus).subtract(7*7 + 6 , 'days').toDate();
        break;
      default: return;
    }
    if (tmpFriday) timeFromPST = DateUtil.toBeginOfDay(tmpFriday);
    let window: FinStatementTimeWindow = {
      to: DateUtil.convertLocalTime(timeToPST, timezone)?.toISOString(),
      timezone
    }
    if (timeFromPST) {
      window.from = DateUtil.convertLocalTime(timeFromPST, timezone)?.toISOString();
    }
    return window;
  }

  onFilterChange(key, value) {
    if (key === 'accountIds') {
      this.condition.accountIds = value?.length ? value : undefined;
    }
    if (key === 'windowOption') {
      this.condition.routeCompletedWindow = this.getTimeWindowFromOption(value);
    }
    this.loadData();
  }

  onBtnExport() {
    let qs = new URLSearchParams({filter: JSON.stringify(this.condition)}).toString();
    let url = Const.APIURI_FINANCES_QB_STATEMENT('export');
    url = `${url}?${qs}`;
    this.isExporting = true;
    this.api.download(url).subscribe(
      (resp) => {
        let blob = new Blob([resp], { type: "application/vnd.ms-excel" });
        let url = URL.createObjectURL(blob);
        let fileName = `report-ap-statement-${moment(new Date()).format("MMMDD")}.xlsx`;
        Utils.downloadFile(url, fileName);
        this.isExporting = false;
      },
      (err) => {
        this.showErr(err);
        this.isExporting = false;
      }
    );
  }
}