import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { ActivatedRoute } from "@angular/router";
import { Utils } from "@services/utils";
import { Const } from "@const/Const";
import { ResponseAdminCarrierForFilter } from "@wearewarp/types-server-admin/carrier";


@Component({
  selector: '[driver-filter]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../../../app.scss', '../../../dialogs/dialogs.scss']
})
export class DriverFilter extends BaseFormItem {
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();
  public static get declaration(): FormGroupDeclaration {return {
    carrierId: {label: 'Carrier', notAcceptEmpty: true, placeHolder: 'Select from list'},
    searchKeyword: {label: 'Keyword', placeHolder: '', notAcceptEmpty: true},
  }};
  protected formGroupDeclaration: FormGroupDeclaration = DriverFilter.declaration;

  public condition: any = {}
  public searchKeyword: string = null
  public loaded: number = 0
  public page: number = 1
  listCarriers: ResponseAdminCarrierForFilter[] = [];
  
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      if (p.filter) {
        this.condition = JSON.parse(p.filter)
      }
      this.searchKeyword = p.search
      this.loaded = p.loaded || 0
      this.page = p.page || 1
    })
  }

  doSearch(s) {
    this.searchKeyword = s
    this.page = 1
    this.loadData()
  }

  onRefresh() {
    this.onReload.emit()
    this.loadData()
  }

  loadData() {
    let q = {page: this.page || 1, loaded: Date.now()}
    if (this.searchKeyword) {
      q['search'] = this.searchKeyword
    }
    const f = JSON.stringify(this.condition)
    if (f.length > 2) {
      q['filter'] = f
    }
    this.routeWithQueryUrl(q)
  }

  onChange(e, name) {
    this.page = 1
    if (e)
      this.condition[name] = e
    else
      delete this.condition[name]
    this.loadData()
  }
  ngOnInit(): void {
    this.model = Object.assign({}, this.condition, {search: this.searchKeyword})
    super.ngOnInit();
    this.fetchListClients();
  }
  public isFetchingCarrier = false;
  private fetchListClients() {
    let url = Const.APIURI_CARRIERS_FOR_FILTER;
    this.isFetchingCarrier = true;
    this.api.GET(url).subscribe(
      resp => {
        this.listCarriers = resp.data.list_data;
        this.isFetchingCarrier = false;
      }, err => {
        this.showErr(err);
        this.isFetchingCarrier = false;
      }
    );
  }
}
