import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { LocationFilterHeper } from "../../forms/shipment-location/helper/location";
import { Observable } from "rxjs";
import { FormDataEditLocationAddress } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { TaskType } from "@wearewarp/types";
import { Const } from "@const/Const";

@Component({
  selector: 'edit-shipment-entry-location',
  templateUrl: './view.html',
  styleUrls: ['./index.scss']
})
export class EditShipmentEntryLocation extends BaseFormDialog1<FormDataEditLocationAddress> {

  @Input() type: TaskType;
  @Input() onSave: (data) => Observable<any>;
  @Input() onRefreshDetailOrder: () => void;
  @Input() headerText = 'Edit Location';

  protected formGroupDeclaration: FormGroupDeclaration = {
    locationName: {label: 'Location Name', notAcceptNull: true},
    warehouseId: {label: ''},
    addr: {label: 'Address', required: true},
  }

  public locationHelper: LocationFilterHeper;

  ngOnInit(): void {
    this.locationHelper = new LocationFilterHeper(data => this.setLocation(data));
    super.ngOnInit();
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  get locationsFiltered() { return this.locationHelper.locationsFiltered }

  onFilterTextChange(text: string) {
    this.locationHelper.onFilterTextChange(text);
    if (!text) {
      this.setItemValue(`warehouseId`, '');
    }
  }

  onLocationSelected(event, data) {
    this.locationHelper.onLocationSelect(event, data)
  }

  private setLocation(locationObject: any) {
    this.setItemValue(`addr`, locationObject?.pickAddr);
    if (!locationObject) {
      this.setItemValue(`locationName`, '');
    } else {
      this.setItemValue(`warehouseId`, locationObject?.id);
    }
  }

  onBtnSave() {
    if (!this.needUpdate) return
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onRefreshDetailOrder();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

}
