<div class="g-bid-session">
  <div nz-form [formGroup]="formInput" class="form-add-route">
    <div nz-row nzGutter="24">
      <div nz-col nzSpan="16">
        <div class="g-info">
          <div class="g-add-route">
            <add-route-component [shouldShowCheckbox]="false" [selectedRoutes]="routes" #formFilter  (addRoute)="addRoute($event)" [form]="formInput.get('routeInfo')"></add-route-component>
          </div>

          <div class="g-action">
            <button nz-button nzType="default" (click)="onCancel()">Cancel</button>
            <button nz-button nzType="primary" (click)="onSubmit()" [nzLoading]="isSubmitting">Add Route</button>
          </div>
        </div>
      </div>

      <div nz-col nzSpan="8">
        <div class="view-detail">
          <div class="title">{{ model?.name }}</div>
          <div class="desc">
            {{ model?.descShort }}
          </div>
          <div class="time">
            {{ displayTimeWindow() }}
          </div>
        </div>

        <div class="route-list-view">
          <bid-session-route-list-view [routes]="routes" [settings]="settings" (removeRoute)="removeRoute($event)"></bid-session-route-list-view>
        </div>
      </div>
    </div>
  </div>
</div>