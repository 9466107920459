import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RatingService {
  private ratingSubject = new BehaviorSubject<any>(null); // Initial value
  rating$ = this.ratingSubject.asObservable();

  updateRating(newRating) {
    console.log('updateRating', newRating)
    this.ratingSubject.next(newRating); // Cập nhật rating mới
  }
}