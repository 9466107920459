import { Component } from "@angular/core";
import { BaseList } from "@app/admin/base/list";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { DateUtil } from "@services/date-utils";
import { TimeWindow } from "@wearewarp/types/data-model";
import { VehicleTypeService } from "@services/vehicle.service";
import { BaseComponent } from "@abstract/BaseComponent";
import { Subscription } from "rxjs";
import { DialogService } from "@dialogs/dialog.service";
import { ExternalPostingDetailDialog } from "@app/admin/carrier-sale-detail/component/outreach/external-posting/create-post";
import { ThirdPartyPost } from "@wearewarp/types/data-model/types/third-party";
import { BidSessionDetailService } from "@app/admin/bid-sessions/bid-session-detail.service";

@Component({
  selector: 'bulk-external-posting',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})

export class BulkExternalPosting extends BaseComponent {
  listRoutes: any[] = [];
  isLoading: boolean = false;
  isSubmitting: boolean = false;
  private bids: any[] = [];
  equipmentType = [];
  private bidSessionDetailSubscription: Subscription;
  private vehicleTypeService: VehicleTypeService;

  constructor(activatedRoute: ActivatedRoute, api: ApiService, private bidSessionDetailService: BidSessionDetailService) {
    super();
    this.activatedRoute = activatedRoute;
    this.api = api;
    this.vehicleTypeService = new VehicleTypeService(this.api);
  }

  ngOnInit() {
    super.ngOnInit();
    this.bidSessionDetailSubscription = this.bidSessionDetailService.bids$.subscribe(bids => {
      this.bids = bids;
    });
    this.getPosts();
    this.getEquipment();
  }

  ngOnDestroy(): void {
    if (this.bidSessionDetailSubscription) {
      this.bidSessionDetailSubscription.unsubscribe();
    }
  }

  async getInitPost(bidId: string) {
    const data = await this.api.GET(`${Const.APIURI_THIRD_PARTY_POST}/carrier-bid/${bidId}/init`).toPromise();
    return data;
  }

  getPosts() {
    const urls = this.bids.map(bid => `${Const.APIURI_THIRD_PARTY_POST}/carrier-bid/${bid.id}`);
    this.isLoading = true;
    this.api.concurrentGET(urls).subscribe(
      async (response) => {
        this.isLoading = false;
        const results = response.map(it => it.data?.list_data || []).flat();
        const listRoutes = [];
        for(let bid of this.bids) {
          const posts = results.filter(post => post.bidId === bid.id);
          if(!posts.length) {
            const item = await this.getInitPost(bid.id);
            listRoutes.push({
              id: bid.id,
              jobCode: bid?.route?.code,
              ...(item?.data || {}),
              dat: null,
              truckstop: null,
              truckerpath: null
            });
            continue;
          }
          const datPost = posts.find(post => post.party === 'dat');
          const truckstopPost = posts.find(post => post.party === 'truckstop');
          const truckerpathPost = posts.find(post => post.party === 'truckerpath');

          listRoutes.push({
            id: bid.id,
            jobCode: bid?.route?.code,
            origin: datPost?.origin || truckstopPost?.origin || truckerpathPost?.origin,
            destination: datPost?.destination || truckstopPost?.destination || truckerpathPost?.destination,
            pickDt: datPost?.pickDt || truckstopPost?.pickDt || truckerpathPost?.pickDt,
            dropDt: datPost?.dropDt || truckstopPost?.dropDt || truckerpathPost?.dropDt,
            equipment: datPost?.equipment || truckstopPost?.equipment || truckerpathPost?.equipment,
            loadType: datPost?.loadType || truckstopPost?.loadType || truckerpathPost?.loadType,
            dimension: datPost?.dimension || truckstopPost?.dimension || truckerpathPost?.dimension,
            dat: datPost,
            truckstop: truckstopPost,
            truckerpath: truckerpathPost
          });
        }
        this.listRoutes = listRoutes;
      },
      (error) => {
        this.isLoading = false;
        this.showErr(error);
      }
    );
  }

  getEquipment() {
    this.vehicleTypeService.listAll(
      (data) => {
        const types = data.filter((it) => it.active !== false);
        const allTypes = {};
        for (let t of types) {
          allTypes[t.code] = t;
        }
        this.equipmentType = data
          .filter((it) => it.active !== false)
          .map((t) => {
            return {
              label: t.name,
              groupLabel: allTypes[t.parent]?.name,
              value: t.code,
              disabled: !t.selectable,
            }
          });
      },
      (error) => {
        this.showErr(error);
      }
    );
  }

  getRouteOrigin(post) {
    return post?.origin ? [post?.origin?.city, post?.origin?.state].join(", ") : '';
  }

  getRouteDestination(post) {
    return post?.destination ? [post?.destination?.city, post?.destination?.state].join(", ") : '';
  }

  getPickupTime(post){
    let timeWindow: TimeWindow = {
      from: post?.pickDt?.fromDate,
      to: post?.pickDt?.toDate,
    }
    return this.getDisplayTimeWindow(timeWindow, post?.pickDt?.timezone);
  }

  getDropoffTime(post) {
    let timeWindow: TimeWindow = {
      from: post?.dropDt?.fromDate,
      to: post?.dropDt?.toDate,
    }
    return this.getDisplayTimeWindow(timeWindow, post?.dropDt?.timezone);
  }

  getPostLoadInfo(post: ThirdPartyPost) {
    let equipment = this.equipmentType.filter(it => it.value === post?.equipment?.code)?.[0];
    let loadType = post?.loadType;
    let lengthFeet = `${post?.dimension.length || 0} ft`;
    let weight = `${post?.dimension.weight || 0} lbs`;
    return `${loadType}, ${equipment?.label} - ${equipment?.value}, ${lengthFeet}, ${weight}`;
  }

  getDisplayTimeWindow(timeWindow: TimeWindow | undefined, timezone) {
    let dateWindow = '';
    if (timeWindow) {
      dateWindow = DateUtil.displayTimeWindow(timeWindow, {
        timezone: timezone,
        formatDateOnly: 'MMM D, YYYY',
        format: "MMM D, YYYY HH:mm",
      })
    }
    const tz = DateUtil.timezoneStandardToUsShort(timezone);
    const tzText = tz ? ` (${tz})` : '';
    return `${dateWindow}`.trim() + tzText;
  }

  getPartyLogo(party: string) {
    switch (party) {
      case "dat":
        return "assets/img/logo_dat.png";
      case "truckstop":
        return "assets/img/truckstop_logo.png";
      case "truckerpath":
        return "assets/img/truckerpath_logo.png";
      default:
        return "";
    }
  }

  getPostStatus(item, type: string) {
    const post: ThirdPartyPost = item[type];
    if (this.isClosedPost(post)) {
      return 'Removed';
    }
    if (this.isCreateError(post)) {
      return 'Error';
    }
    return 'Live';
  }

  getPostUpdatedTime(item, type: string) {
    const post: ThirdPartyPost = item[type];
    let updatedTime = post?.update?.when;
    return DateUtil.dateToString(updatedTime, 'MMM D, YYYY HH:mm')
  }

  hasPost(item, type: string) {
    return !!item[type];
  }

  getPostStatusColor(item, type: string) {
    const post: ThirdPartyPost = item[type];
    if (this.isClosedPost(post)) {
      return 'gray';
    }
    if (this.isCreateError(post)) {
      return 'red';
    }
    return 'green';
  }

  isClosedPost(post: ThirdPartyPost) {
    return !!post?.close;
  }

  isCreateError(post: ThirdPartyPost) {
    return post?.error && post?.error?.event === 'createPost'
  }

  getErrorMessage(item, type: string) {
    const post: ThirdPartyPost = item[type];
    if (!this.isCreateError(post)) return '';
    const note = post?.error?.note;
    const messages = [];
    try {
      let _note = JSON.parse(note);
      switch (post.party) {
        case 'dat':
          typeof _note === "string" ? messages.push(_note) : messages.push(note);
          break;
        case 'truckstop':
          if (typeof _note === "string") {
            _note = JSON.parse(_note); // parse again
          }
          messages.push(_note?.statusSet?.[0]?.descriptor || '');
          messages.push(_note?.statusSet?.[0]?.message || '');
          break;
        case 'truckerpath':
          typeof _note === "string" ? messages.push(_note) : messages.push(note);
          break;
        default:
          messages.push(note);
          break;
      }
    }
    catch (e) {
      messages.push(note);
    }
    return messages.join(', ');
  }

  onBtnCreatePost(job) {
    this.openExternalPostingDetailDialog(job.bidId, null);
  }

  private openExternalPostingDetailDialog(bidId, postId) {
    DialogService.openFormDialog1(ExternalPostingDetailDialog, {
      nzComponentParams: {
        carrierBidId: bidId,
        postId: postId,
        closeOnSuccess: true,
        reloadData: () => {
          this.getPosts()
        },
      },
      nzWidth: '1200px',
      nzClassName: "modal",
      nzMaskClosable: false,
    })
  }

  onBtnEditPost(post: ThirdPartyPost) {
    this.openExternalPostingDetailDialog(post.bidId, post.id);
  }

  isRefreshingPostId(id) {
    return this.isPostRefreshing && this.refreshPostId === id;
  }

  isPostRefreshing: boolean = false;
  refreshPostId: string = "";
  onBtnRefreshPost(post: ThirdPartyPost, idx?) {
    this.isPostRefreshing = true;
    this.refreshPostId = post.id;
    let url = `${Const.APIURI_THIRD_PARTY_POST}/${post?.id}/refresh`;
    this.api.POST(url).subscribe(
      (resp) => {
        this.getPosts();
        this.isPostRefreshing = false;
        this.refreshPostId = "";
        this.showSuccess(`Post ${idx+1} refreshed.`)
      },
      (err) => {
        this.showErr(err);
        this.isPostRefreshing = false;
        this.refreshPostId = "";
      }
    )
  }

  onBtnDeletePost(post: ThirdPartyPost, tplTitle) {
    this.modalService.create({
      nzTitle: tplTitle,
      nzContent: 'This action will also remove post from the channel. Are you sure you want to delete this post?',
      nzOkText: 'Delete',
      nzOkDanger: true,
      nzWidth: '400px',
      nzOkLoading: this.isLoading,
      nzOnOk: () => {
        this.isLoading = true;
        let url = `${Const.APIURI_THIRD_PARTY_POST}/${post?.id}`;
        this.api.DELETE(url).subscribe(
          (resp) => {
            this.getPosts();
          },
          (err) => {
            this.showErr(err);
            this.isLoading = false;
          }
        )
      },
    });
  }
}