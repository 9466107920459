<div class="dashboard-child-container no-padding list">
  <div nz-row>
    <div nz-col nzJustify="space-between" nzXs="24" nzMd="14">
      <nz-tabset (nzSelectChange)="onTabChange($event)" [nzSelectedIndex]="tabIndex">
        <ng-container *ngFor="let tab of tabList">
            <nz-tab [nzTitle]="titleTemplate"></nz-tab>
            <ng-template #titleTemplate>
              <nz-badge [nzCount]="tab?.total" [nzOverflowCount]="999" nzShowZero [nzStyle]="{ backgroundColor: '#1b1ac4'}">
                <span>{{tab.name}}</span>
              </nz-badge>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="devTab">
          <nz-tab [nzTitle]="titleDevTemplate"></nz-tab>
            <ng-template #titleDevTemplate>
              <nz-badge [nzCount]="devTab?.total" [nzOverflowCount]="999" nzShowZero [nzStyle]="{ backgroundColor: '#1b1ac4'}">
                <span>{{devTab.name}}</span>
              </nz-badge>
            </ng-template>
        </ng-container>
      </nz-tabset>
    </div>
    <div nz-col nzXs="24" nzMd="10">
      <div nz-row [nzGutter]="16">
        <div nz-col nzXs="24" [nzMd]="10">
          <div class="flex">
            <div class="form-group" style="min-width: 200px; width: 100%">
              <search-box #searchBox (doSearch)="doSearch($event)" [placeHolder]="'Search'" [searchKeyword]="searchKeyword">
              </search-box>
            </div>
            <div class="line"></div>
          </div>
        </div>
        <div nz-col nzXs="24" nzMd="14">
          <filter-info-select (onFilterChange)="onSearch($event)"></filter-info-select>
        </div>
      </div>
    </div>

  </div>
  <div [ngClass]="{'list-header': 1, 'no-padding':!isReportRevenue}">
    <ng-container *ngIf="isReportRevenue">
      <div (getdata)="loadData($event)" *ngIf="isReportRevenue" filter-revenue></div>
    </ng-container>
  </div>

    <div class="list-body">
      <ng-container *ngTemplateOutlet="tplRevenueSum; context: {revenueSum}"></ng-container>
      <div class="flex bottom15" style="gap: 24px;">
        <div class="center-children">
          <span>Total show: {{listData.length || 'N/A'}}</span>
          <span>
            <span style="color: #A1A1AA;" class="left10 right10">|</span>
            <i>Selected: {{ getNumItemSelected() }}</i>
          </span>
        </div>
        <div class="flex">
          <button nz-button nzType="default" (click)="onBulkAssignCarrierSale()" [nzLoading]="isLoading" [disabled]="!canBulkAssignCarrierSale">Assign Carrier Sale</button>
        </div>
      </div>
      <nz-table #nzTable 
        (nzPageIndexChange)="onDataListPageChanged($event)" 
        [ngClass]="{'hidden': !shouldShowTable}"
        [nzData]="listData" [nzFrontPagination]="false" 
        [nzLoadingIndicator]="tplLoading" 
        [nzLoading]="isLoading"
        [nzNoResult]="tplNoData" 
        [nzPageIndex]="pageIndex" 
        [nzPageSize]="limit" [nzScroll]="{x: 'scroll'}"
        [nzShowPagination]="totalCount > listData.length" 
        [nzSize]="'default'" 
        [nzTotal]="totalCount" nzBordered="true"
      >
        <ng-template #tplNoData>
          <div *ngIf="!isLoading" class="nodata">
            <i nz-icon nzTheme="outline" nzType="search"></i>
            No results. Please try moving to the other tabs for more results.
          </div>
        </ng-template>

        <ng-template #tplLoading>
          <div class="nodata">
            <i nz-icon nzTheme="outline" nzType="loading"></i>
          </div>
        </ng-template>

        <thead (nzSortOrderChange)="onSortOrderChange($event)">
          <tr>
            <th nzWidth="45px"
              [nzChecked]="selectAll" [nzIndeterminate]="indeterminate"
              (nzCheckedChange)="onClickSelectedAll($event)"
            ></th>
            <th [nzSortFn]="true" nzColumnKey="pickDt.time" nzLeft nzWidth="200px" style="max-width: 200px;" zSortOrder="null">Time To Pickup</th>
            <th nzColumnKey="label" nzLeft="200px" nzWidth="150px" style="max-width: 150px;">Load ID</th>
            <th nzWidth="150px" style="min-width: 150px;">Customer Name</th>
            <th nzColumnKey="label" nzWidth="120px" style="min-width: 120px;">Equipment</th>
            <th nzColumnKey="pickDt.time" nzWidth="200px" style="min-width: 200px;">Pickup Time & Location</th>
            <th nzColumnKey="dropDt.time" nzWidth="200px" style="min-width: 200px;">Delivery Time & Location</th>
            <th nzWidth="150px" style="min-width: 150px;">Revenue</th>
            <th *ngIf="canPerformAction" nzWidth="150px" style="min-width: 150px;">Target Rate</th>
            <th nzWidth="120px" style="min-width: 120px;">Historic Rate</th>
            <th *ngIf="showAcceptedRate" nzWidth="200px" style="min-width: 200px;">Accepted Rate</th>
            <th *ngIf="showQueued" nzWidth="200px" style="min-width: 200px;">Next Queue</th>
            <th *ngIf="isOnTabBidSent" nzWidth="100px" style="min-width: 100px;">Bids Sent</th>
            <th *ngIf="isOnTabBidReceived" nzWidth="100px" style="min-width: 100px;">Bid Status</th>
            <th [nzSortFn]="true" nzColumnKey="carrierBids.metadata.refusedCount" *ngIf="isOnTabBidSent" nzWidth="100px" style="min-width: 100px;" nz-tooltip nzTooltipTitle="Sort by Rate">Refused</th>
            <!-- <th *ngIf="!isOnTabAssigned" nzWidth="50px" style="min-width: 135px;" nzRight>Carrier & Cost</th> -->
            <th *ngIf="canPerformAction && !isOnTabNotAcceptLoadTender" nzRight nzWidth="150px" style="min-width: 150px;">Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of listData; let i = index">
            <tr 
              item-job-component 
              [item]="item" [i]="i" 
              [qParams]="qParams" 
              [isLoadingGhostShipment]="isLoadingGhostShipment"
              [isOnTabNotAcceptLoadTender]="isOnTabNotAcceptLoadTender"
              [showAcceptedRate]="showAcceptedRate"
              [showQueued]="showQueued"
              [isOnTabBidSent]="isOnTabBidSent"
              [isOnTabBidReceived]="isOnTabBidReceived"
              [isSelected]="!!setOfSelectedId[item.id]"
              (onSelectItem)="onSelectItem($event)" 
              (onBtnRefresh)="onBtnRefresh()"
            ></tr>
          </ng-container>
        </tbody>
      </nz-table>
  </div>
</div>


<ng-template #tplRevenueSum let-revenueSum="revenueSum">
  <div *ngIf="isReportRevenue" style="display: block;">
    <ng-container *ngIf="!isLoading">
      <div class="flex f18b bottom15">
        <div class="w150">Revenue:</div>
        <div class="flex1">{{revenueSum.shipmentCost}}</div>
      </div>
      <div class="flex f18b bottom20">
        <div class="w150">Carrier Cost:</div>
        <div class="flex1">{{revenueSum.routeCost}}</div>
      </div>
      <div class="bottom20">
        <a (click)="toggleTable()">{{shouldShowTable ? 'Hide details' : 'Show details'}} ({{totalCount}} loads)</a>
      </div>
    </ng-container>
    <div *ngIf="isLoading" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
  </div>
</ng-template>

<ng-template #tplCreateManualLoadDone>
  <div style="display: flex; flex-direction: column; align-items: center; text-align: center;">
    <img class="ic_complete bottom20">
    <div class="bottom10" style="font-size: 18px; font-weight: 600; line-height: 27px;">Load has been created successfully.</div>
    <div class="bottom10" style="font-size: 16px; font-weight: 400; line-height: 27px;">Please see more details in the Dispatch screen.</div>
    <a
      style="align-self: flex-start;" target="_blank"
      [routerLink]="[dlgManualLoadDoneHyperLink.hyperLinkUrl]">
      {{dlgManualLoadDoneHyperLink.hyperLinkText}}
    </a>
    <button nz-button nzType="primary" style="width: 100%; margin-top: 16px;" (click)="closeDialogCreateManualLoadDone()">Close</button>
  </div>
</ng-template>
