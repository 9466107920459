<div class="stop-item {{displayInfo.status}}" [ngClass]="{'highlight': getStopNeedUpdateETA()}">
  <div class="stop-content">
    <div class="main">
      <div class="row">
        <div class="stop-num flex" (click)="toggleCollapse()">
          <div>
            <i *ngIf="!expanded" class="btn-icon icon arrow_drop_down right5"></i>
            <i *ngIf="expanded" class="btn-icon icon arrow_drop_up right5"></i>
          </div>
          <div>{{displayInfo.shortType}} - Stop {{displayInfo.index}}</div>
        </div>
        <button nz-button nzType="text" class="stop-status status {{displayInfo.status}} {{disableBtnEditStatus()}}" (click)="onBtnUpdateStatusClick()">{{displayInfo.status}}
        </button>

        <div class="group-warning-task" *ngIf="displayInfo.warningReviewActual || displayInfo.warningAddReasonCode">
          <div *ngIf="displayInfo.warningReviewActual" class="warning-task" (click)="onBtnUpdateClick('change-actual-time', true)">
            <span nz-icon nzType="warning" nzTheme="outline"></span> Review Actuals
          </div>
          <div *ngIf="displayInfo.warningAddReasonCode" class="warning-task" (click)="onBtnUpdateClick('add-reason-code')">
            <span nz-icon nzType="warning" nzTheme="outline"></span> Add Reason Code
          </div>
        </div>

        <div *ngIf="displayInfo.warningStatus"  nz-tooltip style="color: #faad14; cursor: pointer;"
        [nzTooltipTitle]="tplWarningStatus" [nzTooltipColor]="'#334155'">
          <i nz-icon nzType="warning" nzTheme="outline"></i>
          Statuses are different.
          <ng-template #tplWarningStatus>
            <div *ngFor="let item of displayInfo.warningStatus">{{item}}</div>
          </ng-template>
        </div>
        <div class="add-pod">
          <ng-container *ngIf="displayInfo.warningPod && !isStyleDisplaySmall">
            <div class="flex" (click)="expandShipments()"
                nz-tooltip nzTooltipTitle="View at shipments level below" [nzTooltipColor]="'#334155'">
              <button nz-button nzType="default" nzSize="small">View POD</button>
              <div class="flex left5" style="font-size: 13px; color: #faad14; cursor: pointer;align-items: center">
                <i nz-icon nzType="warning" nzTheme="outline"></i>
                <div class="left5">{{displayInfo.warningPod}}</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="displayInfo.warningPod && !displayInfo.warningStatus && isStyleDisplaySmall ">
            <div class="flex" (click)="expandShipments()"
                nz-tooltip nzTooltipTitle="View at shipments level below" [nzTooltipColor]="'#334155'">
              <button nz-button nzType="default" nzSize="small">View POD</button>
              <div class="flex left5" style="font-size: 13px; color: #faad14; cursor: pointer;align-items: center">
                <i nz-icon nzType="warning" nzTheme="outline"></i>
                <div class="left5">{{displayInfo.warningPod}}</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!displayInfo.warningPod">
            <button *ngIf="displayInfo.totalPod == 0 && isEnableUpdate" nz-button nzType="default" nzSize="small"
            (click)="onBtnAddPod()">Add POD</button>
            <ng-container *ngIf="displayInfo.totalPod != 0 && isEnableUpdate">
              <nz-badge [nzCount]="displayInfo.podNotConfirmed" [nzOverflowCount]="9">
                <button nz-button nzType="default" nzSize="small" (click)="onBtnViewPod()">View POD
                  ({{displayInfo.totalPod}})</button>
              </nz-badge>
            </ng-container>
            <ng-container *ngIf="displayInfo.totalPod != 0 && !isEnableUpdate">
              <nz-badge [nzCount]="displayInfo.podNotConfirmed" [nzOverflowCount]="9">
                <div style="padding: 4px 8px; border: 1px #d9d9d9 solid">POD ({{displayInfo.totalPod}})</div>
              </nz-badge>
            </ng-container>
          </ng-container>
        </div>
        <div *ngIf="canGetPod" class="add-pod">
          <button *ngIf="isEnableUpdate" nz-button nzType="dashed" nzSize="small" [nzLoading]="isLoading"
            (click)="onBtnGetPod()" nz-tooltip="Get POD from P1">Get POD</button>
        </div>
        <div *ngIf="shouldInitiateReturn()">
          <button nz-button nzType="default" nzSize="small" (click)="onBtnInitReturn()">Initiate Return</button>
        </div>
        <button nz-button nzType="text" *ngIf="isEnableUpdate" class="stop-setting" (click)="onBtnUpdateSettings()">
          <i class="btn-icon icon settings"></i>
        </button>
      </div>
      <div class="row">
        <ng-container *ngIf="displayInfo.warningPod && isStyleDisplaySmall && displayInfo.warningStatus">
          <div class="flex" (click)="expandShipments()"
              nz-tooltip nzTooltipTitle="View at shipments level below" [nzTooltipColor]="'#334155'">
            <button nz-button nzType="default" nzSize="small">View POD</button>
            <div class="flex left5" style="font-size: 13px; color: #faad14; cursor: pointer;align-items: center">
              <i nz-icon nzType="warning" nzTheme="outline"></i>
              <div class="left5">{{displayInfo.warningPod}}</div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="stop-address">
          <display-address [deliveryInfo]="displayInfo.deliveryInfo" [type]="displayInfo.type"></display-address>
          <button *ngIf="isEnableUpdate || (isGhostLoad && !isSourceMarketplace) || isLinehaulNoShipment" class="btn-update" nz-button nzType="link" nzSize="small"
            (click)="onBtnUpdateClick('change-address')">Update</button>
        </div>
      </div>
      <div class="row">
        <div class="warp-info">
          <span class="warp-label">Shipment IDs: </span>
          <display-shipment-warpid [tasks]="displayInfo?.tasks"></display-shipment-warpid>
        </div>
      </div>
      <div collapse-animation [expanded]="expanded">
        <div class="row">
          <div class="warp-info">
            <span class="warp-label right5">Ref No:</span>
            <span *ngIf="!isShowRef()">N/A</span>
            <span *ngIf="isShowRef()">
              <ng-container *ngFor="let ref of displayInfo.refNums">
                <nz-tag [nzColor]="'#F5F5F5'" style="color: #191A23; font-size: 14px; white-space: break-spaces;">{{ ref }}</nz-tag>
              </ng-container>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="appoiment-info">
            <display-appointment [label]="displayInfo.isScheduled ? 'Appointment' : 'Scheduled'"
              [appointment]="displayInfo.appointment"
              [timezone]="displayInfo.timezone" [scheduled]="displayInfo.isScheduled"></display-appointment>
          </div>
          <div *ngIf="displayInfo.warningAppointment" nz-tooltip style="color: #faad14; cursor: pointer;"
          [nzTooltipTitle]="tplWarningAppointment" nzTooltipOverlayClassName="tooltip-warning-appointment" [nzTooltipColor]="'#334155'">
            <i nz-icon nzType="warning" nzTheme="outline"></i>
            Times are different.
            <ng-template #tplWarningAppointment>
              <div *ngFor="let item of displayInfo.warningAppointment">{{item}}</div>
            </ng-template>
          </div>
          <button *ngIf="isEnableUpdate || isGhostLoad || isLinehaulNoShipment" class="btn-update" nz-button nzType="link" nzSize="small"
            (click)="onBtnUpdateClick('update-appointment')">{{displayInfo.isScheduled ? 'Update' : 'Book Appointment'}}</button>
          <button *ngIf="!displayInfo.isScheduled" class="btn-update" nz-button nzType="link" nzSize="small"
          (click)="onBtnUpdateClick('update-schedule')">Update Schedule</button>
          </div>
        <div class="row">
          <div class="eta-info flex1">
            <div style="display: flex; background: #F8C60D; align-items: center; padding: 0 8px; border-radius: 4px;">
              <span class="eta-label">ETA: </span>
              <display-appointment label="" [appointment]="displayInfo.etaTime"
              [timezone]="displayInfo.timezone"></display-appointment>
            </div>
            <button *ngIf="isEnableUpdate" class="btn-update" [ngClass]="{'btn-update-eta': getStopNeedUpdateETA()}" nz-button nzType="link" nzSize="small"
              (click)="onBtnUpdateClick('update-eta')">Update ETA</button>
          </div>
          <div *ngIf="representativeTask" delivery-eta [overflow]="true" [compact]="true" [id]="'TASK_' + representativeTask.data.id" [tz]="displayInfo.timezone"></div>
        </div>
        <div *ngIf="isShowActualTime" class="row">
          <div class="actual-info">
            <b>Actual:</b>
            Arrived: {{displayInfo.actualArrived || 'N/A'}}
            - Departed: {{displayInfo.actualDeparted || 'N/A'}}
          </div>
          <button *ngIf="isEnableUpdate" class="btn-update" nz-button nzType="link" nzSize="small"
            (click)="onBtnUpdateClick('change-actual-time')">Update</button>
        </div>
        <div *ngIf="displayInfo.delay" class="row">
          <div class="semibold" style="color: #faad14;">Failure Code for delay: </div>
          <div>
            {{displayInfo.delay.failureCode || 'N/A'}}
            <ng-container *ngIf="displayInfo.delay.note">
              <span nz-icon nzType="info-circle" nzTheme="outline" nz-popover
              [nzPopoverContent]="noteDelay" style="cursor: pointer;"></span>
            </ng-container>
            <ng-template #noteDelay>
              <div style="white-space:pre-wrap; max-width: 300px;">{{displayInfo.delay.note}}</div>
            </ng-template>
          </div>
        </div>
        <!-- <div class=" row">
          <div class="stop-instruction">
            <b>Stop Inst.:</b> Customer requires on time pickup
          </div>
          <button class="btn-update" nz-button nzType="link" nzSize="small"
            (click)="onBtnUpdateClick('stop-instruction')">Update</button>
        </div> -->
        <div class="row">
          <div class="location-instruction">
            <b>Location Inst.:</b> {{displayInfo?.deliveryInfo?.instructions}}
            <button *ngIf="isEnableUpdate" class="btn-update" nz-button nzType="link" nzSize="small"
              (click)="onBtnUpdateClick('location-instruction')">Update</button>
          </div>
        </div>
        <div class="row" *ngIf="displayInfo?.appointmentCode">
          <div class="appointment-number">
            <b>Appointment Number:</b> {{displayInfo?.appointmentCode}}
          </div>
        </div>
        <div class="row">
          <ng-container *ngIf="getItemCountConfirm() > 0">
            <div class="bold">Item counts confirmed: {{getItemCountConfirm()}} {{getItemCountConfirm() > 1 ? 'items': 'item'}}</div>
          </ng-container>
        </div>
        <div class="row">
          <div class="shipment-overview">{{displayInfo.shipmentCount}} Shipments</div>
          <button *ngIf="displayInfo.shipmentCount" class="btn-update" nz-button nzType="link" nzSize="small" (click)="onBtnViewShipmentClick()">
            {{this.showShipments? 'Hide shipments': 'View Shipments'}}</button>
        </div>
      </div>
    </div>
    <div class="sidebar">
<!--      <div class="row">-->
<!--        <div *ngIf="isEnableUpdate" class="icon paid" (click)="onBtnAdditionalCarrierCost()"></div>-->
<!--        &lt;!&ndash; <div class="icon contact"></div> &ndash;&gt;-->
<!--      </div>-->
      <div *ngIf="displayInfo.index > 1" class="row top15">
        <img src="/assets/img/dispatch-icons/route.svg" />
        {{ mileage }} mi - {{ hours }} hr
      </div>
    </div>
  </div>
  <div class="shipments" collapse-animation [expanded]="this.expanded && this.showShipments">
    <div class="row">
      <!-- <cdk-virtual-scroll-viewport itemSize="5">
          <dispatch-shipment-item *cdkVirtualFor="let task of displayInfo.tasks" [task]="task">
          </dispatch-shipment-item>
        </cdk-virtual-scroll-viewport> -->
      <div class="shipment-list-container" *ngIf="this.expanded && this.showShipments">
        <dispatch-shipment-item *ngFor="let task of displayInfo.tasks" [task]="task" [stop]="stop" [stopItems]="dataStopItems" [isVisiblePOD]="isVisiblePOD" [isEnableUpdateStatus]="isEnableUpdateStatus">
        </dispatch-shipment-item>
      </div>
    </div>
  </div>
</div>
