<div>
  <div *ngIf="error">
    <nz-alert nzType="error" nzMessage="Error" [nzDescription]="error" nzShowIcon></nz-alert>
  </div>
  <div *ngIf="!error">
    <nz-timeline>
      <nz-timeline-item *ngFor="let item of workLogs">
        <div style="white-space: pre-line;">
          {{ getDescFromLog(item) }}
        </div>
        <div class="desc-text" *ngIf="item?.task?.object?.metadata?.stopId">
          <span>Stop {{ item?.task?.object?.metadata?.stopSequence }}</span>
          <span *ngIf="item?.task?.object?.metadata?.stopType"> - {{ capitalizeCase(item?.task?.object?.metadata?.stopType) }}</span>
        </div>
        <div class="desc-text">
          <span>{{ displayTime(item?.insert?.when, "") }}</span>
          <span>, by {{ getFullName(item?.insertBy) }}</span>
        </div>
      </nz-timeline-item>
    </nz-timeline>
  </div>

</div>
