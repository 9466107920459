import { Component, ViewChild } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { AddRouteComponent } from "../component/add-route";
import { getDashboard } from "@services/index";
import { Const } from "@const/Const";
import { Subscription } from "rxjs";
import { Title } from "@angular/platform-browser";
import { BidSessionDetailService } from "../bid-session-detail.service";
import { ActivatedRoute } from "@angular/router";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: "bid-session-add-route",
  templateUrl: "./index.html",
  styleUrls: ["./style.scss"],
})
export class BidSessionAddRouteComponent extends BaseFormItem {
  @ViewChild('formFilter') formFilter: AddRouteComponent;

  routes: any[] = [];
  isLoading: boolean = false;
  isSubmitting: boolean = false;
  private bidSessionId: string;
  get settings() {
    return {}
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    routeInfo: { label: "", type: "formGroup", childItem: AddRouteComponent.declaration }
  };

  protected subscribeTopbarTitle(fn: Function) {
    return fn("Add Route For Bid Session");
  }

  private getDataSubscription: Subscription;
  constructor(
    private titleService: Title,
    private bidSessionDetailService: BidSessionDetailService,
    activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
    this.activatedRoute.params.subscribe(params => {
      this.bidSessionId = params.id;
      super.handleNavigationEnd(activatedRoute.toString(), activatedRoute.queryParamMap);
    })
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchData();
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
  }

  beforeBindModel(model) {
    model.routeInfo = {
      clientIds: model?.clientIds || [],
      markets: model?.markets || [],
      vehicleTypes: model?.vehicleTypes || [],
    }
    return model;
  }

  ngOnDestroy(): void {
    if (this.getDataSubscription) {
      this.getDataSubscription.unsubscribe();
    }
  }

  fetchData() {
    this.isLoading = true;
    let url = `${Const.APIURI_BID_SESSIONS}/${this.bidSessionId}`;
    this.api.GET(url).subscribe(
      resp => {
        const bidSession = resp.data;
        const bids = resp.data?.bids || [];
        this.model = bidSession;
        this.bindDataModel(bidSession);
        this.setEnableFormGroup(true);
        this.titleService.setTitle(`${bidSession?.name} - BID Session - WARP Admin`);
        this.bidSessionDetailService.setSessionDetail(bidSession);
        this.routes = bids.map(it => it.route);
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.showErr(err);
      }
    );
  }

  public displayTimeWindow() {
    const timezone = this.model?.timeZone || 'America/Los_Angeles';
    const window = { from: this.model?.fromTime, to: this.model?.toTime };
    const text = DateUtil.displayTimeWindow(window, {
      timezone: timezone,
      format: 'MMM DD, H:mm',
      formatDateOnly: 'MMM DD'
    });
    return `${text} (${DateUtil.timezoneStandardToUsShort(timezone)})`;
  }

  get selectedJobIds() {
    return this.routes.map(it => it.id);
  }

  addRoute(job) {
    const routes = [...this.routes];
    const exist = routes.find(it => it.id === job.id);
    if(exist) return;
    routes.push(job);
    this.routes = [...routes];
  }

  removeRoute(job) {
    const routes = this.routes.filter(it => it.id !== job.id);
    this.routes = [...routes];
  }

  onCancel() {
    this.router.navigate([this.routeAdminCarrierSales], { queryParams: { bidStage: 'open' } });
  }

  onSubmit() {
    const params = {
      jobIds: this.selectedJobIds
    }
    this.isSubmitting = true;
    this.api.POST(`${Const.APIURI_BID_SESSIONS}/${this.bidSessionId}/add-routes`, params).subscribe(
      (resp) => {
        this.isSubmitting = false;
        this.showInfo(`Routes has been added successfully`);
        this.router.navigate([Const.routeAdminBidSessions, this.bidSessionId]);
      },
      (err) => {
        this.isSubmitting = false;
        this.showErr(err);
      }
    )
  }
}