import { Const as WarpConst } from "@wearewarp/universal-libs";
export class FraudDetectionHelper {
  static getTagColor(key: string) {
    switch(key) {
      case 'found_email_associated_with_multiple_carriers':
      case 'found_email_of_carrier_with_identity_alert':
      case 'found_email_flagged_for_fraud':
      case 'found_email_domain_associated_with_multiple_carriers':
      case 'found_email_domain_flagged_for_fraud':
      case 'email_spoofing_attempt':
      case 'email_domain_spoofing_attempt':
      case 'email_and_email_domain_not_known':
      case 'found_email_of_dispatcher_service':
      case 'found_email_domain_of_carrier_with_identity_alert':
      case 'found_email_domain_of_dispatcher_service':
      case 'found_email_associated_with_one_carrier':
      case 'found_email_domain_associated_with_one_carrier':
      case 'ignored_because_known_non_carrier_email':
        return 'orange';
      case 'user_is_blocked':
      case 'pending_investigation':
      case 'stolen_assets':
      case 'stolenAsset':
      case 'scammer':
      case 'routingComplianceViolation':
        return 'red';
      default:
        return '';
    }
  }
  static getTagDescription(key: string) {
    switch(key) {
      case 'user_is_blocked':
        return 'User is blocked';
      case 'pending_investigation':
        return 'Pending Investigation';
      case 'scammer':
        return 'Scammers';
      case 'stolen_assets':
      case 'stolenAsset':
        return 'Stolen Assets';
      case 'routingComplianceViolation':
        return 'Routing Compliance Violation';
      default:
        return WarpConst.EmailSearchResultCategoryGohighway[key] ?? key;
    }
  }

  static setIdentityAlerts(identityAlerts) {
    const identityAlertsGroup = {};
    identityAlerts.forEach(alert => {
      if (identityAlertsGroup[alert.is_type]) {
        identityAlertsGroup[alert.is_type]++;
      } else {
        identityAlertsGroup[alert.is_type] = 1;
      }
    });
    return identityAlertsGroup;
  }
}