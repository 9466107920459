import { Component, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ValidateOptions } from "@app/admin/base/form-base";
import { FormDataBatchLocationsMultiPick } from "@app/admin/shipment-entry/interface";
import { FormShipmentLocation } from "../../shipment-location";
import { FormOrderItems } from "../../order-items";
import { DialogService } from "@dialogs/dialog.service";
import { ValidationErrors } from "@angular/forms/forms";
import { ShipmentEntryForm } from "../../shipment-entry-form";
import { BatchShipmentUICollapseState } from "../interface";
import { FormDataBatchLocationsItem } from "@wearewarp/types-server-admin/form-data/shipment-entry";

@Component({
  selector: '[form-shipment-location-batch-multi-pick]',
  templateUrl: './view.html',
  styleUrls: ['../style.scss'],
  host: {
    class: 'location-batch'
  }
})
export class FormShipmentLocationBatchMultiPick extends ShipmentEntryForm<FormDataBatchLocationsMultiPick> {

  @ViewChild('formDropoff') formDropoff: FormShipmentLocation;
  @ViewChildren('formPickup') formPickups: QueryList<FormShipmentLocation>;
  @ViewChildren('formItems') formItems: QueryList<FormOrderItems>;

  readonly uiCollapseState: {picks: BatchShipmentUICollapseState[], drop: BatchShipmentUICollapseState} = {
    picks: [],
    drop: {}
  }
  get countPickInfos() { return this.uiCollapseState.picks.length }

  ngOnInit(): void {
    this.extractDataModelDraft();
    super.ngOnInit();
  }

  private extractDataModelDraft() {
    let _data = <FormDataBatchLocationsMultiPick>this.context?.modelDraft;
    this.uiCollapseState.drop.data = _data?.dropInfo;
    this.uiCollapseState.picks = [];
    for (let item of _data?.pickInfos ?? []) {
      this.uiCollapseState.picks.push({data: item});
    }
    if (this.uiCollapseState.picks.length == 0) {
      this.uiCollapseState.picks.push({});
    }
  }

  protected onModelDataChanged(): void {
    this.extractDataModelDraft();
  }

  private validateFormDropoff(options?: ValidateOptions) {
    let err = this.formDropoff.validate(options);
    if (err) {
      if (options?.scrollToErrorField) {
        this.uiCollapseState.drop.isExpand = true;
      }
      return err;
    }
    return null;
  }

  private validateFormPickup(index: number, options?: ValidateOptions) {
    let formPickup = this.formPickups.get(index);
    let formItems = this.formItems.get(index);
    for (let form of [formPickup, formItems]) {
      let err = form.validate(options);
      if (err) {
        if (options?.scrollToErrorField) {
          this.uiCollapseState.picks[index].isExpand = true;
        }
        return err;
      }
    }
    return null;
  }

  /**
   * Validate tất cả các form pickup trước index (từ 0 đến index - 1)
   * Cần tạo error message báo phù hợp
   */
  private validateFormPickupsPrev(index: number) {
    if (index > 0) {
      for (let i = 0; i < index; i++) {
        let msg = `In order to save <b>Pickup ${index + 1}</b>, you must complete all previous Pickups. So please complete <b>Pickup ${i + 1}</b> first.`;
        let err = this.validateFormPickup(i, {showDialogMessage: msg, scrollToErrorField: true});
        if (err) {
          return err;
        }
      }
    }
    return null;
  }

  public validate(options?: ValidateOptions): ValidationErrors {
    let err = this.validateFormDropoff(options);
    if (err) {
      return err;
    }
    for (let i = 0; i < this.countPickInfos; i++) {
      err = this.validateFormPickup(i);
      if (err) {
        return err;
      }
    }
    return null;
  }

  public getFormData(): FormDataBatchLocationsMultiPick {
    let formData: FormDataBatchLocationsMultiPick = {
      pickInfos: [],
      dropInfo: this.formDropoff.getFormData()
    };
    for (let i = 0; i < this.countPickInfos; i++) {
      let formDataPick = this.formPickups.get(i).getFormData();
      let formDataItems = this.formItems.get(i).getFormData();
      let pickInfo: FormDataBatchLocationsItem = { ...formDataPick, items: formDataItems.items };
      formData.pickInfos.push(pickInfo);
    }
    return formData;
  }

  onBtnAddPickInfo() {
    this.uiCollapseState.picks.push({})
  }

  genIdDrop = () => {
    // Trường hợp multi-pickup chỉ nhập 1 dropoff đại diện thì không thể có id chung được, khi nào tách ra mới tạo id riêng cho từng dropoff
    return 'dummy_id';
  }

  onBtnSavePickInfo(index: number) {
    if (this.validateFormPickupsPrev(index)) {
      return;
    }
    if (this.validateFormPickup(index, {showDialogMessage: true, scrollToErrorField: true})) {
      return;
    }
    let formPickup = this.formPickups.get(index);
    let formItems = this.formItems.get(index);
    const pickInfo = formPickup.getFormData();
    const itemData = formItems.getFormData();
    this.context.saveDeliveryInfos({...pickInfo, items: itemData.items}, index, (stt, data) => {
      switch (stt) {
        case 'inProgress':
          this.uiCollapseState.picks[index].isLoading = true;
          break;
        case 'succeeded':
        case 'failed':
          this.uiCollapseState.picks[index].isLoading = false;
          break;
      }
    });
  }

  onBtnRemovePickInfo(index: number) {
    // TODO: nếu remove item đã có trong draft rồi thì phải gọi API để update
    DialogService.confirmDeletion({
      message: `Remove <b>Pickup ${index + 1}</b>?`,
      txtBtnOk: 'Remove',
      fnOk: () => this.uiCollapseState.picks.splice(index, 1)
    })
  }

  onBtnSaveDropInfo() {
    if (this.validateFormDropoff({showDialogMessage: true, scrollToErrorField: true})) {
      return;
    }
    const dropInfo = this.formDropoff.getFormData();
    this.context.saveDeliveryInfo(dropInfo, (stt, data) => {
      switch (stt) {
        case 'inProgress':
          this.uiCollapseState.drop.isLoading = true;
          break;
        case 'succeeded':
        case 'failed':
          this.uiCollapseState.drop.isLoading = false;
          break
      }
    });
  }

  onBtnUploadListItems(index: number) {
    DialogService.comingSoon();
  }

}