export const PermissionCode = {
  sidebar: {
    users: "admin:sidebar:users",
    clients: "admin:sidebar:clients",
    carriers: "admin:sidebar:carriers",
    drivers: "admin:sidebar:drivers",
    developers: "admin:sidebar:developers",
    warehouses: "admin:sidebar:warehouses",
    orders: "admin:sidebar:orders",
    shipment_queue: "admin:sidebar:shipment_queue",
    appointment: "admin:sidebar:appointment",
    quick_orders: "admin:sidebar:quick_orders",
    marketplace: "admin:sidebar:marketplace",
    dispatch: "admin:sidebar:dispatch",
    carrier_sales: "admin:sidebar:carrier_sales",
    bid_sessions: "admin:sidebar:bid_sessions",
    pools: "admin:sidebar:pools",
    zip_rate: "admin:sidebar:zip_rate",
    freight_quote_ftl: "admin:sidebar:freight_quote_ftl",
    comm_unsubscribes: "admin:sidebar:comm_unsubscribes",
    routing_ltl: "admin:sidebar:routing_ltl",
    need_routing: "admin:sidebar:need_routing",
    planning_session: "admin:sidebar:planning_session",
    quick_routing: "admin:sidebar:quick_routing",
    linehaul_lane: "admin:sidebar:linehaul_lane",
    historical_volume: "admin:sidebar:historical_volume",
    warehouse_shipments: "admin:sidebar:warehouse_shipments",
    warehouse_inventories: "admin:sidebar:warehouse_inventories",
    rate_plans: "admin:sidebar:rate_plans",
    manifests: "admin:sidebar:manifests",
    external_route_connecting: "admin:sidebar:external_route_connecting",
    invoice_shipments: "admin:sidebar:invoice_shipments",
    transactions: "admin:sidebar:transactions",
    quick_upload_pod: "admin:sidebar:quick_upload_pod",
    provide_pod: "admin:sidebar:provide_pod",
    report_finance: "admin:sidebar:report_finance",
    announcements: "admin:sidebar:announcements",
    templates: "admin:sidebar:templates",
    custom_textract_template: "admin:sidebar:custom_textract_template",
    report_revenue: "admin:sidebar:report_revenue",
    report_performance: "admin:sidebar:report_performance",
    load_booked: "admin:sidebar:load_booked",
    revenue_booked: "admin:sidebar:revenue_booked",
    report_pod: "admin:sidebar:report_pod",
    report_viewer: "admin:sidebar:report_viewer",
    two_factor_authentication: "admin:sidebar:two_factor_authentication",
    pod_confirmation: "admin:sidebar:pod_confirmation",
    dispatch_dashboard: "admin:sidebar:dispatch_dashboard",
    quickbook_statement_bill: "admin:sidebar:quickbook_statement_bill",
  },
  login: {
    admin: `login:admin`,
    client: `login:client`,
    carrier: `login:carrier`,
    warehouse_admin: `login:warehouse_admin`
  },
  user: {
    read: `user:read`,
    create: `user:create`,
    update: `user:update`,
    delete: `user:delete`,
    unBlock: `user:unBlock`,
    resetPassword: `user:resetPassword`
  },
  client: {
    read: `client:read`,
    create: `client:create`,
    update: `client:update`,
    addSubAccount: `client:addSubAccount`
  },
  carrier: {
    read: `carrier:read`,
    create: `carrier:create`,
    update: `carrier:update`,
    viewTax: `carrier:tax:view`,
    updateTax: `carrier:tax:update`,
  },
  driver: {
    read: `driver:read`,
    create: `driver:create`,
    update: `driver:update`
  },
  developer: {
    read: `developer:read`,
    create: `developer:create`,
    update: `developer:update`,
    delete: `developer:delete`,
  },
  warehouse: {
    read: `warehouse:read`,
    create: `warehouse:create`,
    update: `warehouse:update`,
    delete: `warehouse:delete`
  },
  warehouse_inventory: {
    read: `warehouse_inventory:read`,
    create: `warehouse_inventory:create`
  },
  announcement: {
    read: `announcement:read`,
    create: `announcement:create`
  },
  template: {
    read: `template:read`,
    create: `template:create`,
    update: `template:update`
  },
  order: {
    read: `order:read`,
    create: `order:create`
  },
  routing_ltl: {
    read: `routing_ltl:read`,
    create: `routing_ltl:create`,
    update: `routing_ltl:update`
  },
  comm_unsubscribes: {
    read: `comm_unsubscribes:read`,
    create: `comm_unsubscribes:create`,
    update: `comm_unsubscribes:update`,
    delete: `comm_unsubscribes:delete`
  },
  zip_rate: {
    read: `zip_rate:read`,
    update: `zip_rate:update`
  },
  pool: {
    read: `pool:read`,
    create: `pool:create`,
    update: `pool:update`,
    delete: `pool:delete`
  },
  job: {
    onHold: `job:onHold`,
    unHold: `job:unHold`,
    editAllNote: `job:editAllNote`
  },
  quick_order: {
    read: `quick_order:read`,
    review: `quick_order:review`  
  },
  commlog: {
    read: `commlog:read`
  },
  location_setting: {
    read: `location_setting:read`,
    create: `location_setting:create`,
    update: `location_setting:update`,
    delete: `location_setting:delete`,
  },
  bid_session: {
    read: `bid_session:read`,
    create: `bid_session:create`,
    addRoute: `bid_session:addRoute`
  }
}