import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeliveryInfoService {
  private appointmentRequiredSubject = new BehaviorSubject<any>(null);
  appointmentRequired$ = this.appointmentRequiredSubject.asObservable();

  updateAppointmentRequired(data: any) {
    this.appointmentRequiredSubject.next(data);
  }
}