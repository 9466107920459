<div class="section-header">
  <div class="title">Responses</div>
  <div class="value">
    {{ bid?.jobCode }}
  </div>
</div>

<div class="section-search bottom10">
  <search-box
    #searchBox (doSearch)="doSearch($event)"
    [placeHolder]="'Search by Carrier name, Carrier contact'"
    [searchKeyword]="searchKeyword"
  ></search-box>
</div>

<div class="section-table">
  <nz-table #nestedTable
    [nzScroll]="{ x: 'scroll' }"
    nzBordered="true"
    [nzData]="candidates" nzSize="small"
    [nzTotal]="totalCount" [nzFrontPagination]="false"
    [nzShowPagination]="totalCount > candidates.length"
    [nzPageSize]="limit"
    [nzPageIndex]="pageIndex"
    [nzLoading]="isLoading"
    [nzLoadingIndicator]="tplLoading"
    [nzNoResult]="tplNoData"
    [nzSize]="'default'"
    (nzPageIndexChange)="onChangePage($event)"
  >
    <ng-template #tplNoData>
      <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
    </ng-template>
    <ng-template #tplLoading>
      <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    </ng-template>
    <thead>
      <tr>
        <th nzLeft nzColumnKey="name" [nzWidth]="'238px'">
          Carrier Name
        </th>
        <th nzLeft nzColumnKey="pool" *ngIf="hasDedicatedPool" [nzWidth]="'155px'">
          Dedicated Pool
        </th>
        <th nzLeft nzColumnKey="contacts" [nzWidth]="'201px'">
          Carrier Contact
        </th>
        <th nzColumnKey="source" [nzWidth]="'96px'">
          Source
        </th>
        <th nzColumnKey="laneCompleted" [nzWidth]="'214px'">Performance</th>
        <th nzColumnKey="document" [nzWidth]="'137px'">Documents</th>
        <th nzColumnKey="gohighway" [nzWidth]="'137px'">Highway</th>
        <th nzColumnKey="baseRate" [nzWidth]="'96px'">Base Rate</th>
        <th nzColumnKey="bidding" [nzWidth]="'250px'">{{ isActiveResponseReceivedTab ? 'Bidding' : 'Bid Invitation' }}</th>
        <th nzColumnKey="answer" [nzWidth]="'150px'">{{ isActiveResponseReceivedTab ? 'Assign Carrier' : 'Carrier answer' }}</th>
        <th nzRight nzColumnKey="note" [nzWidth]="'70px'">Note</th>
      </tr>
    </thead>
    <tbody>

      <ng-container *ngFor="let item of nestedTable.data">
        <tr>
          <!-- Carrier Name -->
          <td nzLeft>
            <div class="flex" style="align-items: center;">
              <ng-container *ngIf="item.carrierId">
                <a class="inherit" target="_blank" [routerLink]="[routeAdminCarrierList, item.carrierId]">{{item.name || 'N/A'}}</a>
              </ng-container>
              <ng-container *ngIf="!item.carrierId">
                <div class="inherit">{{item.name || 'N/A'}}</div>
              </ng-container>

              <span nz-icon nzType="down" nzTheme="outline"
                style="margin-left: 8px; padding-right: 10px;"
                nz-tooltip [nzTooltipTitle]="titleTemplate"
                [nzTooltipTitleContext]="{ $implicit: item }"
                nzTooltipPlacement="bottomRight"
                nzTooltipColor="#ffffff"
                nzTooltipOverlayClassName="group-carrier-info"
              ></span>
              <ng-template #titleTemplate let-info>
                <div class="carrier-name">
                  {{ info.name || 'N/A' }}
                </div>
                <nz-divider class="line"></nz-divider>
                <div class="group-info">
                  <span>DOT: {{ item.dot || 'N/A' }}</span><br/>
                  <span>MC: {{ item.mc || 'N/A' }}</span><br/>
                  <span>Equipment: {{ item.equipment || 'N/A' }}</span>
                </div>
              </ng-template>
            </div>

            <div *ngIf="item.status === 'un_registered'">
              <nz-tag>Unregistered</nz-tag>

              <ng-container *ngIf="this.isTruckSearch(item)">
                <ng-container *ngIf="getLastSendInvite(item)">
                  <span class="f12" style="color: #8D8D8D;">Invitation sent</span> <span class="f12" style="color: #8D8D8D" nz-icon nzType="down" nzTheme="outline"
                    style="margin-left: 8px; padding-right: 10px;"
                    nz-tooltip [nzTooltipTitle]="lastSendTeamplate"
                    [nzTooltipTitleContext]="{ $implicit: item }"
                    nzTooltipPlacement="bottomRight"
                    nzTooltipColor="#ffffff"
                    nzTooltipOverlayClassName="group-carrier-info"
                  ></span>
                  <ng-template #lastSendTeamplate>
                    <p class="small-text" style="margin-top: 5px;" style="color: black" >when: {{getLastSendInvite(item)}}</p>
                  </ng-template>
                  <!-- <span style="color: #8D8D8D">Invitation sent</span>  -->
                </ng-container>
                <ng-container *ngIf="!getLastSendInvite(item)">
                  <a class="f12" [href]="" (click)="sendInviteRegisterToCarrier(item)" style="text-decoration: underline; margin-top: 10px;">
                    {{item?.contacts?.[0]?.contactEmail ? 'Invite via Email' : 'Invite via SMS'}}
                  </a>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="!this.isTruckSearch(item)">
                <ng-container *ngIf="item.lastSent">
                  <span class="f12" style="color: #8D8D8D">Invitation sent</span>
                </ng-container>
                <ng-container *ngIf="!item.lastSent">
                  <a class="f12" [href]="" (click)="sendInviteRegisterToCarrierPublic(item)"
                    style="margin-top: 10px;"
                  >
                    Invite via Email
                  </a>
                </ng-container>
              </ng-container>
            </div>

            <ng-container *ngIf="item.status !== 'un_registered'">
              <ng-container *ngIf="item.status != 1">
                <nz-tag nzColor="warning">{{$asCarrierStatusText(item.status)}}</nz-tag>
              </ng-container>
              <ng-container *ngIf="item.status == 1">
                <nz-tag nzColor="success">Active</nz-tag>
              </ng-container>
            </ng-container>
          </td>
          <!-- Dedicated Pool -->
          <td nzLeft *ngIf="hasDedicatedPool">
            <ng-container *ngIf="item.isDedicatedPool">
              <a [routerLink]="[routeAdminPools, item.poolId]" target="_blank">{{item.poolName}}</a> <br />
              Base Rate: <b>{{ item?.basePrice ? $asMoney(item.basePrice) : '-' }}</b>
            </ng-container>
            <ng-container *ngIf="!item.isDedicatedPool">-</ng-container>
          </td>
          <!-- Carrier Contact -->
          <td nzLeft>
            {{item.contacts?.[0]?.contactName || 'N/A'}}<br />
            <p class="small-text">
              <contact-info
                [isFetch]="false"
                owner="{{item.contacts?.[0]?.contactPhone}}"
                type="phone"
                [scopes]="['carrier_bid']"
                [isUnsub]="isUnsubcribed(item.contacts?.[0]?.contactPhone)"
              ></contact-info>
              <contact-info
                [isFetch]="false"
                owner="{{item.contacts?.[0]?.contactEmail}}"
                type="email"
                [scopes]="['carrier_bid']"
                [isUnsub]="isUnsubcribed(item.contacts?.[0]?.contactEmail)"
              ></contact-info>
            </p>
          </td>
          <!-- Source -->
          <td>
            <div class="title-source">
              {{ item?.source?.category }}
              <i *ngIf="whyAdded(item)"
                nz-icon nzType="info-circle"
                nzTheme="outline" nz-tooltip
                [nzTooltipTitle]="whyAdded(item)"
                class="icon-info"
              ></i>
            </div>
            <div class="desc-source" *ngIf="item?.source?.desc">
              {{ item?.source?.desc }}
            </div>
          </td>
          <!-- Performance -->
          <td>
            <div *ngIf="!item.countOfJob">
              <div nz-row class="group-lane">
                <nz-col class="left-lane" nzSpan="24">
                  N/A
                </nz-col>
              </div>
            </div>
            <ng-container *ngIf="item.countOfJob > 0">
              <div nz-row class="group-lane">
                <nz-col class="left-lane" nzSpan="12">
                  <div>
                    All lane: {{ item.countOfJob }}<br/>
                    <!-- <div class="rate-info" display-rate-info
                      style="margin-bottom: 4px;"
                      [numOfLike]="item.countOfJobRateLike"
                      [numOfDislike]="item.countOfJobRateDislike"
                      [carrierId]="item.carrierId"
                    ></div> -->
                  </div>
                </nz-col>
                <nz-col nzSpan="12" class="right-lane">
                  <div>
                    This lane: {{ item.countLane }}
                  </div>
                </nz-col>
                <nz-col nzSpan="24" *ngIf="item.countJobUnassigned">
                  <div style="color: #faad14;" nz-tooltip nz-tooltip nzTooltipTitle="Carrier won the bids but couldn't commit to pickup">
                    <span nz-icon nzType="warning" nzTheme="outline"></span> Carrier Canceled: {{ item.countJobUnassigned }}
                  </div>
                </nz-col>
              </div>
            </ng-container>
          </td>
          <!-- Documents -->
           <td>
            <div class="flex">
              <nz-tag [nzColor]="$documentColor(item?.documents?.isPassed)">{{ item?.documents?.isPassed ? 'Passed': 'Not Verified' }}</nz-tag>
              <div *ngIf="!item?.documents?.isPassed">
                <span nz-icon nzType="down" nzTheme="outline"
                  style="margin-left: 5px;"
                  nz-tooltip [nzTooltipTitle]="documentTemplate"
                  [nzTooltipTitleContext]="{ $implicit: item }"
                  nzTooltipPlacement="bottomRight"
                  nzTooltipColor="#ffffff"
                  nzTooltipOverlayClassName="group-document-info"
                ></span>
                <ng-template #documentTemplate let-item>
                  <ng-container *ngFor="let el of item?.documents?.messages || []">
                    <div class="flex-space-between">
                      <div class="massage">{{ el.label }}</div>
                      <div><nz-tag [nzColor]="'red'">{{ el.status }}</nz-tag></div>
                    </div>
                    <nz-divider class="line"></nz-divider>
                  </ng-container>
                  <div>
                    <a target="_blank" [routerLink]="[routeAdminCarrierList, item.carrierId]">
                      View details in Carrier profile <i nz-icon nzType="login" nzTheme="outline"></i>
                    </a>
                  </div>
                </ng-template>
              </div>
            </div>
           </td>
          <!-- Highway -->
          <td>
            <ng-container>
              <nz-tag [nzColor]="$goHighWayColor(item?.goHightWayStatus)">{{ $goHighWayStatus(item?.goHightWayStatus) }}</nz-tag>
              <a
                *ngIf="item.goHighWayLink"
                [href]="item.goHighWayLink" target="_blank"
                nz-tooltip nzTooltipTitle="Go to GoHighWay"
              >
                <span nz-icon nzType="login" nzTheme="outline"></span>
              </a>
            </ng-container>
            <!-- <div class="flex">
              <nz-tag [nzColor]="$goHighWayColor(item?.goHightWayStatus)">{{ $goHighWayStatus(item?.goHightWayStatus) }}</nz-tag>
              <div *ngIf="item.goHighWayLink">
                <span nz-icon nzType="down" nzTheme="outline"
                  style="margin-left: 5px;"
                  nz-tooltip [nzTooltipTitle]="goHighWayTemplate"
                  [nzTooltipTitleContext]="{ $implicit: { url: item.goHighWayLink, classifications: item.goHightWayClassifications } }"
                  nzTooltipPlacement="bottomRight"
                  nzTooltipColor="#ffffff"
                  nzTooltipOverlayClassName="group-document-info"
                ></span>
                <ng-template #goHighWayTemplate let-info>
                  <ng-container *ngFor="let el of info?.classifications || []">
                    <div class="flex-space-between">
                      <div class="massage">{{ el.name }}</div>
                      <div><nz-tag [nzColor]="$goHighWayColor(el.result)">{{ $goHighWayStatus(el.result) }}</nz-tag></div>
                    </div>
                    <nz-divider class="line"></nz-divider>
                  </ng-container>
                  <div>
                    <a target="_blank" [href]="info.url">
                      View details in Gohighway <i nz-icon nzType="login" nzTheme="outline"></i>
                    </a>
                  </div>
                </ng-template>
              </div>
            </div> -->
          </td>
          <!-- Base Rate -->
          <td>
            <div *ngIf="(item.basePrice && item.basePrice <= bid.basePrice) || (!bid.basePrice && item.basePrice)" class="group-base-rate">
              <div class="icon" nz-tooltip [nzTooltipTitle]="tooltipBaseRate(item)">
                <img src="/assets/svg/icon-info.svg" alt="">
              </div>
              <div class="base-price">
                {{ $asMoney(item.basePrice) }}
              </div>
            </div>
            <div *ngIf="!item.basePrice || (item.basePrice > bid.basePrice && bid.basePrice)" class="group-base-rate">
              <div class="icon" nz-tooltip nzTooltipTitle="WARP base rate">
                <img src="/assets/svg/warp-logo.svg" alt="">
              </div>
              <div class="base-price">
                {{ bid.basePrice ? $asMoney(bid.basePrice) : '-' }}
              </div>
            </div>
          </td>
          <!-- Bidding -->
          <td nzAlign="left">
            <ng-container *ngIf="isActiveNoResponseTab">
              <ng-container *ngIf="item.sendBidQueueStatus == 'cancelled'">
                <nz-tag nzColor="error" [nz-tooltip]="getCancelMessage(item)">Response rate is too low</nz-tag>
                <div>
                  <a nz-tooltip class="f13"
                    (click)="onBtnChangSendBidQueueStatus(item)">
                    Send anyway
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="item.sendBidQueueStatus != 'cancelled'">
                <ng-container *ngIf="item.lastSent">
                  <div class="group-bidding">
                    <div class="group-status">
                      <div class="item-status" *ngIf="item.lastSentEmail?.status">
                        <span nz-icon nzType="mail" nzTheme="outline"></span>
                        <span class="status" [ngClass]="$asSentStatusColor(item.lastSentEmail.status)">
                          {{ $asSentStatusText(item.lastSentEmail.status) }}
                        </span>
                      </div>
                      <div class="item-status" *ngIf="item.lastSentSms?.status">
                        <span nz-icon nzType="message" nzTheme="outline"></span>
                        <span class="status" [ngClass]="$asSentStatusColor(item.lastSentSms.status)">
                          {{ $asSentStatusText(item.lastSentSms.status) }}
                        </span>
                      </div>
                    </div>
                    <div class="action">
                      <span *ngIf="$shouldShowHistory(item.lastSent)"
                        (click)="onBtnHistoryStatus(item)"
                        nz-icon nzType="history" nzTheme="outline"
                      ></span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!item.lastSent && item.lastQueued">
                  <ng-container *ngIf="item.lastQueued.metadata?.disableAutoQueue">
                    Sending...
                  </ng-container>
                  <ng-container *ngIf="!item.lastQueued.metadata?.disableAutoQueue">
                    <span nz-tooltip nzTooltipTitle="This message is queued and will automatically be sent at: {{$displayEstTime(item.lastQueued?.when)}} EST">
                      Send at: <br />
                      {{$displayEstTime(item.lastQueued?.when)}} (EST)
                    </span>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!item.lastQueued && !item.lastSent">
                  Not sent
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="isActiveResponseReceivedTab">
              <ng-container *ngIf="hasCounterBidHistory(item) && !isCarrierRefusedBid(item)">
                <div *ngFor="let counter of item.bidAnswers" style="text-align: left;">
                  <span nz-tooltip [nzTooltipTitle]="getTooltipForBidCounterAction(counter)" nzTooltipPlacement="rightTop">{{counter?.price ? $asMoney(counter.price): '-'}} by {{getAuthorOfBidCounter(counter)}}</span>
                </div>
                <div *ngIf="!isAdminAssignCarrier() && !isCarrierAcceptedPrice(item) && bid?.isAllowPlaceBid" class="flex gap5 top5">
                  <button nz-button nzType="default" [disabled]="!checkCanCounter(item)" (click)="onOpenCounterDialog(item)" nzSize="small">Counter</button>
                  <ng-container *ngIf="item?.bidCounterOffer">
                    <button nz-button nzType="default" [disabled]="!checkCanHelpCarrierCounter(item)" nz-tooltip nzTooltipTitle="Enter Carrier's answer" nzSize="small" (click)="onBtnCounterBidForCarrier(item)"><img src="/assets/svg/question_answer.svg" alt=""></button>
                  </ng-container>
                  <ng-container *ngIf="!item?.bidCounterOffer">
                    <button nz-button  nzType="default" nzSize="small" (click)="onBtnEditItem(item)" nz-tooltip nzTooltipTitle="Enter Carrier's answer">
                      <img src="/assets/svg/question_answer.svg" alt="" style="margin-right: 3px;">
                    </button>
                  </ng-container>
                </div>
              </ng-container>

              <ng-container *ngIf="!hasCounterBidHistory(item) && !isCarrierAcceptedPrice(item) && !isCarrierRefusedBid(item) && !isAdminAssignCarrier()">
                <div class="flex flex-space-between">
                  <ng-container *ngIf="item.lastSent">
                    <nz-tag nzColor="warning">Waiting for a response</nz-tag>
                  </ng-container>
                  <button nz-button  nzType="default" nzSize="small" (click)="onBtnEditItem(item)" nz-tooltip nzTooltipTitle="Enter Carrier's answer">
                    <img src="/assets/svg/question_answer.svg" alt="" style="margin-right: 3px;">
                  </button>
                </div>
              </ng-container>

              <ng-container *ngIf="isCarrierRefusedBid(item)">
                <nz-tag nzColor="error" nz-tooltip [nzTooltipTitle]="formatDate(item?.update?.when)">Refused</nz-tag>
              </ng-container>

              <ng-container *ngIf="isCarrierAcceptedPrice(item)">
                <nz-tag nzColor="success" nz-tooltip [nzTooltipTitle]="getTooltipForBidCounterAction(getLastAnswer(item))">
                  <span nz-icon nzType="check" nzTheme="outline"></span>
                  Accepted {{$asMoney(getCarrierAcceptedPrice(item))}}
                </nz-tag>
              </ng-container>
            </ng-container>
          </td>
          <!-- Answer -->
          <td class="border-right-none">
            <div class="flex-column assign-item" *ngIf="isActiveResponseReceivedTab">
              <div
                *ngIf="item?.carrierId && item?.carrierId != bid?.job?.assignedCarrier?.carrierId && [2, 3].includes(item.state)">
                <div *ngIf="item.status == 1 && (!isMarketplace || isPopulated)">
                  <img class="svg-icon truck1 right10" alt="">
                  <a (click)="onBtnAcceptBid(item)" style="margin-right: 10px;">{{ isCandidateUnassigned(item) ? 'Re-assign Carrier' :'Accept and Assign Carrier' }}</a>
                </div>
                <div *ngIf="item.status == 1">
                  <button *ngIf="!item.sentLostBid" nz-button nzSize="small" class="f12 top5" (click)="onBtnSendLostBid(item)">Send lost bid</button>
                  <div *ngIf="item.sentLostBid" class="top5">
                    <!-- <warp-when-by [model]="item.sentLostBid" [viewTemplate]="tplSentLostBid"></warp-when-by> -->
                    <ng-template #tplSentLostBid let-hasData="hasData" let-displayInfo="displayInfo">
                      <div class="small-text">Sent lost bid by {{displayInfo.createdBy}} on {{displayInfo.createdWhen}}</div>
                    </ng-template>
                  </div>
                </div>
                <div *ngIf="item.status == 1 && isMarketplace && isPopulated">
                  <img class="svg-icon truck1 right10" alt="" nz-tooltip
                    nzTooltipTitle="This carrier cannot be assigned because relate to Marketplace Order">
                </div>
                <div *ngIf="item.status !== 1">
                  <a nz-tooltip="This carrier cannot be assigned because it is not in Active state.">-</a>
                </div>
              </div>
              <div
                *ngIf="item?.carrierId && item?.carrierId == bid?.job?.assignedCarrier?.carrierId && [2, 3].includes(item.state)">
                <span nz-typography nzType="success">
                  <span nz-icon nzType="check" nzTheme="outline"></span> Accepted
                  <p class="small-text">
                    <span>by: {{ getFullName(bid?.job?.assignedCarrier?.update?.byUser) || "N/A" }}</span>
                    <br />
                    <span>when: {{ $formatDate(bid?.job?.assignedCarrier?.update?.when) }}</span>
                  </p>
                </span>
              </div>
              <div
                *ngIf="isCandidateUnassigned(item)">
                <span nz-typography nzType="warning">
                  <span nz-icon nzType="warning" nzTheme="outline"></span> Unassigned
                  <p class="small-text">
                    <span>by: {{ getFullName(getCandidateLastestHistory(item)?.whenBy) || "N/A" }}</span>
                    <br />
                    <span>when: {{ $displayEstTime(getCandidateLastestHistory(item)?.whenBy?.when) }} (EST)</span>
                  </p>
                </span>
              </div>
            </div>
            <div class="flex" *ngIf="isActiveNoResponseTab && !isCarrierDNU(item)">
              <button nz-button  nzType="default" nzSize="small" (click)="onBtnEditItem(item)" nz-tooltip nzTooltipTitle="Enter Carrier's answer">
                <img src="/assets/svg/question_answer.svg" alt="" style="margin-right: 3px;">
              </button>
            </div>
          </td>
          <!-- Note -->
          <td nzRight>
            <div *ngIf="!isAdminReadOnlyRole && !isCarrierDNU(item)">
              <ng-container *ngIf="item.noteCount">
                <nz-badge nzSize="default" [nzCount]="item.noteCount">
                  <a (click)="onBtnEditNote(item)" nzSize="small">
                    <img src="/assets/svg/edit-button.svg" alt="">
                  </a>
                </nz-badge>
              </ng-container>
              <ng-container *ngIf="!item.noteCount">
                <a (click)="onBtnEditNote(item)" nzSize="small">
                  <img src="/assets/svg/edit-button.svg" alt="">
                </a>
              </ng-container>
            </div>
          </td>
        </tr>
        <tr [nzExpand]="item.expand" class="info-more">
          <div nz-row [nzGutter]="16" >
            <div nz-col [nzSpan]="8">
              <div class="info-more-item">
                <div class="info-more-title">
                  <div class="flex-space-between">
                    <div>Profile</div>
                    <div><a target="_blank" [routerLink]="[routeAdminCarrierList, item.carrierId]">Details</a></div>
                  </div>
                </div>
                <div class="info-more-des">
                  <div>DOT: {{ item.dot || 'N/A' }}/ MC: {{ item.mc || 'N/A' }}</div>
                  <div>Equipment: {{ item.equipment || 'N/A' }}</div>
                  <div>Lane: CA -> NV</div>
                  <!-- <div>Service Coverage Area: California, Nevada, Utah, Arizona</div> -->
                  <div><div class="flex">
                    <span style="margin-right: 5px;">Documents:</span>
                    <nz-tag [nzColor]="$documentColor(item?.documents?.isPassed)">{{ item?.documents?.isPassed ? 'Passed': 'Not Verified' }}</nz-tag>
                    <div *ngIf="!item?.documents?.isPassed">
                      <span nz-icon nzType="down" nzTheme="outline"
                        style="margin-left: 5px;"
                        nz-tooltip [nzTooltipTitle]="documentTemplate"
                        [nzTooltipTitleContext]="{ $implicit: item }"
                        nzTooltipPlacement="bottomRight"
                        nzTooltipColor="#ffffff"
                        nzTooltipOverlayClassName="group-document-info"
                      ></span>
                      <ng-template #documentTemplate let-item>
                        <ng-container *ngFor="let el of item?.documents?.messages || []">
                          <div class="flex-space-between">
                            <div class="massage">{{ el.label }}</div>
                            <div><nz-tag [nzColor]="'red'">{{ el.status }}</nz-tag></div>
                          </div>
                          <nz-divider class="line"></nz-divider>
                        </ng-container>
                        <div>
                          <a target="_blank" [routerLink]="[routeAdminCarrierList, item.carrierId]">
                            View details in Carrier profile <i nz-icon nzType="login" nzTheme="outline"></i>
                          </a>
                        </div>
                      </ng-template>
                    </div>
                  </div></div>
                </div>
              </div>
              <div class="info-more-item">
                <div class="info-more-title">
                  <div class="flex-space-between">
                    <div>Highway</div>
                    <div>
                      <a
                        *ngIf="item.goHighWayLink"
                        [href]="item.goHighWayLink" target="_blank"
                        nz-tooltip nzTooltipTitle="Go to GoHighWay"
                      >
                        Details
                      </a>
                    </div>
                  </div>
                </div>
                <div class="info-more-des">
                  <nz-tag [nzColor]="$goHighWayColor(item?.goHightWayStatus)">{{ $goHighWayStatus(item?.goHightWayStatus) }}</nz-tag>
                </div>
              </div>
            </div>
            <div nz-col [nzSpan]="8">
              <div class="info-more-item">
                <div class="info-more-title">Working History</div>
                <div class="info-more-des">
                  <div>Canceled after booked: 0270-2419, 0279-2439, 0340-1419 (+3)</div>
                  <div>PU/DO Late: 3 routes</div>
                  <div>Not Use Live Tracking: 2 routes</div>
                  <div>Last Completed: 1097-2430, 0094-2431, 1274-2430</div>
                </div>
              </div>
              <div class="info-more-item">
                <div class="info-more-title">Availability</div>
                <div class="info-more-des">
                  <div>Assigned to Same Day: 0464-2431 (Aug 16)</div>
                  <div>Good to go</div>
                </div>
              </div>
            </div>
            <div nz-col [nzSpan]="8">
              <div class="info-more-item">
                <div class="info-more-title">Source</div>
                <div class="info-more-des">
                  <div>{{ item?.source?.category }}</div>
                  <div class="desc-source" *ngIf="item?.source?.desc">
                    {{ item?.source?.desc }}
                  </div>
                  <div class="desc-source" *ngIf="whyAdded(item)">
                    {{ whyAdded(item) }}
                  </div>
                </div>
              </div>
              <div class="info-more-item">
                <div class="info-more-title">Bidding History</div>
                <div class="info-more-des">
                  <div>$700 WARP offer base price</div>
                  <div>Invitation sent - Sep 9, 2024 9:41 </div>
                  <div>$850 Carrier bid - Sep 9, 2024 9:00 - from load board</div>
                  <div>$750 WARP counter - Sep 9, 2024 9:12</div>
                  <div>Lost bid sent - Sep 9, 2024 9:41</div>
                </div>
              </div>
            </div>
          </div>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</div>