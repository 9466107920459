import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { CreateOrders } from './create-orders';
import { DetailModule } from '../base/detail.module';
import { ClientModule } from '../clients/module';
import { OrderFilter } from './orders-filter';
import { OrderStatus } from './order-status';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { FormAddressUSModule } from '../base/form-address-us/module';
import { UploadOrderCsv } from './upload_csv';
import { UploadOrderPdf } from './upload_pdf';
import { UiCommonModule } from '../components/common/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzSelectModule,
    NzButtonModule,
    NzInputModule,
    NzIconModule,
    NzFormModule,
    NzDropDownModule,
    NzCollapseModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzPaginationModule,
    NzCheckboxModule,
    NzDividerModule,
    DetailModule,
    FormAddressUSModule,
    ClientModule,
    SelectBySearchingModule,
    UiCommonModule,
  ],
  declarations: [
    CreateOrders,
    OrderFilter,
    OrderStatus,
    UploadOrderCsv,
    UploadOrderPdf,
  ],
  exports: [
  ],
  providers: [
  ]
})
export class OrderModule {}