import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Const } from '@app/const/Const';
import { Job, ShipmentItem, Task, Shipment, Equipment, VehicleType } from '@wearewarp/types/data-model';
import { TaskStatus } from '@wearewarp/types';
import { StopItem, Unit } from '../models/types';
import { Utils } from '@services/utils';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class NetworkDataService {
    constructor(private apiService: ApiService) { }

    fetchJobData(jobId: string): Observable<{ stops: StopItem[], maxWeight?: number, maxQuantity?: number }> {
        const urls = [
            Const.APIV2(`${Const.APIURI_JOBS}/${jobId}`),
            Const.APIV2(`${Const.APIURI_JOBS}/${jobId}/tasks`),
            Const.APIV2(`${Const.APIURI_JOBS}/${jobId}/shipments`),
            Const.APIV2(`${Const.APIURI_JOBS}/${jobId}/shipment_items`)
        ];

        return forkJoin(urls.map(url => this.apiService.GET(url))).pipe(
            map(([repJob, repTask, repShipment, repItem]: any[]) => {
                const job: Job = repJob?.data || {};
                const allTasks: Task[] = repTask?.data?.list_data || [];
                const shipments: Shipment[] = repShipment?.data?.list_data || [];
                const items: ShipmentItem[] = repItem?.data?.list_data || [];

                return this.processJobData(job, allTasks, shipments, items);
            })
        );
    }

    private processJobData(job: Job, allTasks: Task[], shipments: Shipment[], items: ShipmentItem[]): {
        stops: StopItem[],
        maxWeight?: number,
        maxQuantity?: number
    } {
        const stops: StopItem[] = [];

        for (let index in (job?.stops || [])) {
            const stop = (job?.stops || [])[index];
            const tasks = allTasks.filter(task =>
                stop.taskIds.includes(task.id) && !this.isFailed(task.status)
            );

            const shipmentIds = tasks.map((task: any) => task.shipmentId);
            const itemIds = shipments
                .filter(sh => shipmentIds.includes(sh.id))
                .flatMap(sh => sh.itemIds);
            const itemsInStop = items.filter(item => itemIds.includes(item.id));

            const data = this.getTotalShipmentsItems(itemsInStop);
            let units = data.units || [];
            let weight = data.weight;
            let originUnits = [...units];
            let originWeight = weight;

            const type = stop.type === Const.TaskType.PICKUP ? 'PU' :
                (stop.type === Const.TaskType.DROPOFF ? 'DO' : 'RE');

            const prevStop = stops[Number(index) - 1];

            if (prevStop && [Const.TaskType.DROPOFF, Const.TaskType.RETURN].includes(<any>stop.type)) {
                const unitMapped = (prevStop.units || []).map(item => {
                    let unit = units.find(u => u.qtyUnit === item.qtyUnit);
                    if (unit) {
                        const qty = Number((item.qty).toFixed(2)) - Number((unit.qty).toFixed(2));
                        return { ...unit, qty };
                    }
                    return item;
                });
                units = unitMapped;
                weight = Number(prevStop.weight.toFixed(2)) - weight;
            }

            if (prevStop && [Const.TaskType.PICKUP].includes(<any>stop.type)) {
                const unitMapped = (prevStop.units || []).map(item => {
                    let unit = units.find(u => u.qtyUnit === item.qtyUnit);
                    if (unit) {
                        const qty = Number((item.qty).toFixed(2)) + Number((unit.qty).toFixed(2));
                        return { ...unit, qty };
                    }
                    return item;
                });
                units = unitMapped;
                weight += prevStop.weight;
            }

            stops.push({
                id: stop.id,
                type,
                info: stop.info,
                originUnits,
                originWeight,
                units,
                weight
            });
        }

        const vehicle: VehicleType = job?.requiredVehicle;
        const maxWeight = vehicle?.capacity?.weight?.value;
        const maxQuantity = vehicle?.palletCapacity;
        return {
            stops,
            maxWeight,
            maxQuantity
        };
    }

    private isFailed(status: TaskStatus): boolean {
        return [
            Const.TaskStatus.canceled,
            Const.TaskStatus.failed,
            Const.TaskStatus.pickupFailed
        ].includes(<any>status);
    }

    private getTotalShipmentsItems(listItems: ShipmentItem[]) {
        let data = {
            weight: 0,
            units: [] as Unit[]
        };

        if (!Utils.isArrayNotEmpty(listItems)) {
            return data;
        }

        for (let item of listItems) {
            const existingUnit = data.units.find(u => u.qtyUnit === item.qtyUnit);
            if (existingUnit) {
                existingUnit.qty += item.qty;
            } else {
                data.units.push({ qty: item.qty, qtyUnit: item.qtyUnit });
            }

            if (Const.WeightUnits.includes(item.weightUnit)) {
                const weight = item.weightUnit === 'lbs'
                    ? item.weightPerUnit
                    : Number(Const.kgToLbs(item.weightPerUnit).toFixed(2));
                data.weight += weight * item.qty;
            }
        }

        return data;
    }
} 