import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { DialogService } from "@dialogs/dialog.service";
import { ResponseAdminFinStatementDetail } from "@wearewarp/types-server-admin/fin";
import _ from 'underscore'
import { InputHelper } from "@services/input-helper";
import { FormArray, FormControl, Validators } from '@angular/forms';
import { DateUtil } from "@services/date-utils";
import { SearchDenimCompany } from "../search-denim-company";
import dayjs from "dayjs";
import { Utils } from "@services/utils";
import { AttachedFileUtil } from "@services/attached-file-util";
import { QuickBooksService } from "@services/quickbooks.service";

const MinChargeByPercent = { '1_8': 12, '7_5': 10, '15_3': 8 };
// 15 days: 3% --> If 3% is < $8 --> Charge $8.
// 7 days: 5% --> If 5% is < $10 --> Charge $10.
// Next Day: 8% --> If 8% is < $12 --> Charge $12.
// Applied above rates for all customers except Walmart Routes

const CarrierPaymentTermsDefault = { name: 'Net 7 (5% fee)', numOfDay: 7, feePercentage: 5, value: '7_5' };
@Component({
  selector: '[create-denim-job]',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class CreateDenimJob extends BaseFormDialog1 {

  @Input() statementId;
  @Input() onSuccess: (resp) => void;
  public displayInfo: any = {}
  public finAccountDic: any = {}
  public mapFiles: any = {};
  public quickbooksBillDic: any = {};
  quickbookService: QuickBooksService;
  qbPaymentTermOptions: { id: string, name: string, DueDays: number }[] = [];
  public carrierIds: string[] = [];
  public clientIds: string[] = [];
  public shipmentRefNums: string[] = [];
  public isSkipCarrierInvoice: boolean = false;

  get isCreateNew(): boolean {
    return true;
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    is_factored: { label: 'Is Factored', type: 'boolean', required: true, initialValue: true },
    notes: { label: 'Notes' },
    reference_number: { label: 'Job / PO #', required: true },
    debtor_relationships: { label: 'Invoice Customer', type: 'formArray', required: true, childItem: {
      finAccountId: { label: '', required: true },
      finAccountName: { label: 'WARP Customer: ', required: true, readOnly: true, submitReadOnly: true },
      relationship_id: { label: 'Denim Customer', type: 'number', required: true },
      is_confirmed: { label: 'Verify', type: 'boolean', required: true, initialValue: false },
      relationship_info: { label: '' },
      reference_number: { label: 'PO #', required: false },
      base_amount: { label: 'Receivable Amount', type: 'number', required: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value) },
      obligation_date: { label: 'Invoice Date', required: true },
      due_date: { label: 'Collect Date', required: true },
    }},
    payee_relationships: { label: 'Pay Contractor', type: 'formArray', required: true, childItem: {
      finAccountId: {label: '', required: true },
      finAccountName: {label: 'WARP Carrier: ', required: true, readOnly: true, submitReadOnly: true },
      relationship_id: { label: 'Denim Contractor', type: 'number', required: true },
      is_confirmed: { label: 'Verify', type: 'boolean', required: true, initialValue: false },
      relationship_info: { label: '' },
      reference_number: { label: 'Invoice Number', required: true },
      carrier_cost: { label: '' },
      quickbooksBillId: { label: '' },
      total_amount: { label: 'Payable Amount', type: 'number', required: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value) },
      paymentTerms: { label: 'Payment Terms', required: true, initialValue: CarrierPaymentTermsDefault },
      obligation_date: { label: 'Invoice Date', required: true },
      due_date: { label: 'Payment Date', required: true },
      attachmentFile: {label: 'Carrier Invoice',type: 'array',required: true },
    }},
  };

  constructor() {
    super();
    this.quickbookService = new QuickBooksService(this.api);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
  }

  private getData() {
    if (!this.statementId) return;
    let urls = [
      Const.APIURI_FINANCES_STATEMENT(this.statementId),
      Const.APIURI_FINANCES_STATEMENT(`${this.statementId}/refNums`)
    ];
    this.api.concurrentGET(urls).subscribe(
      (resp) => {
        this.buildDisplayInfo(resp?.[0]?.data ?? {});
        this.shipmentRefNums = resp?.[1]?.data?.list_data ?? [];
        const model = this.bindingDataModel();
        this.fetchFiles();
        this.fetchingDataQuickooksBill();
        this.createFormInput(model);
        this.setEnableFormGroup(true); // make sure all readOnly fields will be disabled after created.
        this.updateDataAfterCreateFormInput();
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  private async fetchFiles(){
    let promises: any = [];
    let invoiceFiles: any = [];
    let mapFiles: any = {};
    for (let item of this.displayInfo.payableGroups) {
      let childrens = item.childrens || [];
      invoiceFiles = invoiceFiles.concat(childrens.map(item => item.invoiceFiles).flat().filter(x=>x));
    }
    for (let item of this.displayInfo.fixedRateInfo?.invoiceFiles ?? []) {
      invoiceFiles.push(item);
    }
    invoiceFiles.forEach(async (file) => {
      let url = AttachedFileUtil.attachedFileUrl(file);
      const p = this.api.download(url).toPromise();
      promises.push(p);
    })
    await Promise.all(promises).then((datas) => {
      for (let index in invoiceFiles){
        let invoice = invoiceFiles[index];
        const arrayBuffer = datas[index];
        const blob = new Blob([arrayBuffer], { type: invoice.type });
        const file = new File([blob], invoice.name, { type: invoice.type });
        mapFiles[invoice.id] = file;
      }
    })
    for(let key of Object.keys(this.fileToUpload)){
      for(let key1 of Object.keys(mapFiles)){
        if(key.includes(key1)){
          this.fileToUpload[key] = mapFiles[key1];
        }
      }
    }
  }

  private async fetchingDataQuickooksBill() {
    let billIds: string[] = [];
    for (let item of this.displayInfo.payableGroups) {
      for (let child of item.childrens || []) {
        for (let finJob of child.finJobs || []) {
          if (finJob.quickbooksBillId) {
            billIds.push(finJob.quickbooksBillId);
          }
        }
      }
    }
    billIds = Utils.uniqElementsArray(billIds).filter(x=>x);
    if (!billIds.length) return;
    const res = await this.quickbookService.getAllPaymentTerms().catch(err => {
      this.showErr(err);
    });
    if (res?.length) {
      this.qbPaymentTermOptions = res.map(item => ({
        id: item.Id,
        name: item.Name,
        DueDays: item.DueDays,
      }));
    }
    for (let id of billIds) {
      const bill = await this.quickbookService.getBillDetail(id).catch(err => {
        this.showErr(err);
      });
      if (bill) {
        this.quickbooksBillDic[id] = bill;
      }
    }
    for (let item of this.displayInfo?.payableGroups || []) {
      for (let child of item.childrens || []) {
        for (let finJob of child.finJobs || []) {
          if (finJob.quickbooksBillId) {
            const bill = this.quickbooksBillDic[finJob.quickbooksBillId];
            if (!bill) continue;
            finJob.quickbooksDetailInfo = {
              paymentTerms: this.qbPaymentTermOptions.find(item => item.id === bill?.SalesTermRef?.value)?.name,
              lines: (bill.Line ?? []).map(line => ({
                name: line?.AccountBasedExpenseLineDetail?.AccountRef?.name ?? '',
                amount: this.formatMoney(line.Amount),
              })),
              balance: this.formatMoney(bill.Balance),
            }
          }
        }
      }
    }
  }

  private buildDisplayInfo(data: ResponseAdminFinStatementDetail) {
    // group theo finaccount
    let receivableGroupNames = _.uniq(data.receivables?.map(item => item.account?.name) ?? []).filter(x => x);
    let receivableGroups = _.sortBy(receivableGroupNames.map(g => {
      return {
        name: g,
        account: data.receivables?.find(it => it.account.name === g)?.account,
        childrens: data.receivables?.filter(it => it.account.name === g),
      }
    }), "name");
    let payableGroupNames = _.uniq(data.payables?.map(item => item.account?.name) ?? []).filter(x => x);
    let payableGroups = _.sortBy(payableGroupNames.map(g => {
      return {
        name: g,
        account: data.payables?.find(it => it.account.name === g)?.account,
        childrens: data.payables?.filter(it => it.account.name === g),
      }
    }), "name");
    this.displayInfo = {
      id: data.id,
      code: `ST-${data.code}`,
      receivableGroups,
      payableGroups,
      paymentServiceInfo: data.paymentServiceInfo,
      isFixedRate: data.isFixedRate,
    }
    if (data.fixedRateInfo?.account?.id) {
      this.displayInfo.fixedRateInfo = {
        ...data.fixedRateInfo,
        paymentFixedRate: `${this.formatMoney(data.fixedRateInfo.rate)} ${this.getPaymentFixedRateTypeName(data.fixedRateInfo.type)}`,
        numOfWorkingDays: data.fixedRateInfo.workingDays?.length || 0,
      }
    }
  }

  private bindingDataModel() {
    const shipmentRefNums = [ ...(this.shipmentRefNums ?? []) ];
    let carrierIds: string[] = [];
    let clientIds: string[] = [];
    let model: any;
    if (this.displayInfo?.isFixedRate && this.displayInfo?.fixedRateInfo?.account) {
      carrierIds.push(this.displayInfo.fixedRateInfo.account?.entityId);
      let invoiceFiles = this.displayInfo?.fixedRateInfo?.invoiceFiles || [];
      model = {
        reference_number : this.displayInfo.receivableGroups?.[0]?.childrens?.[0].relatedBiz?.code ?? '',
        is_factored: true,
        debtor_relationships: this.displayInfo.receivableGroups.map(group => {
          let total_amount = 0;
          for (let child of group.childrens || []) {
            total_amount = child?.cost?.grandTotal ?? 0;
          }
          clientIds.push(group.account?.entityId)
          return {
            reference_number: (shipmentRefNums ?? [])?.join(', '),
            finAccountId: group.account.id,
            finAccountName: group.account.name,
            relationship_id: group.account.denimPaymentServiceInfo?.[0]?.client_debtor_relationship?.id,
            relationship_info: group.account.denimPaymentServiceInfo?.[0],
            base_amount : total_amount,
            obligation_date: dayjs().toDate(),
            due_date: dayjs().add(30, "day").toDate(),
          };
        }),
        payee_relationships: [{
          finAccountId: this.displayInfo.fixedRateInfo.account.id,
          finAccountName: this.displayInfo.fixedRateInfo.account.name,
          relationship_id: this.displayInfo.fixedRateInfo.account.denimPaymentServiceInfo?.[0]?.client_payee_relationship?.id,
          relationship_info: this.displayInfo.fixedRateInfo.account.denimPaymentServiceInfo?.[0],
          carrier_cost: this.displayInfo.fixedRateInfo.cost?.grandTotal,
          obligation_date: dayjs().toDate(),
          attachmentFile: invoiceFiles.map(item => {
            let key = `payee_relationships.0.attachmentFile.${item.id}`;
            this.fileToUpload[key] = new File([], item?.name); // tạm khởi tạo file rỗng để hiển thị tên file
            return `payee_relationships.0.attachmentFile.${item.id}`;
          }),
        }]
      }
    } else {
      model = {
        reference_number : this.displayInfo.receivableGroups?.[0]?.childrens?.[0].relatedBiz?.code ?? '',
        is_factored: this.displayInfo.receivableGroups?.length && this.displayInfo.payableGroups?.length ? true : false,
        debtor_relationships: this.displayInfo.receivableGroups.map(group => {
          let total_amount = 0;
          for (let child of group.childrens || []) {
            total_amount = child?.cost?.grandTotal ?? 0;
          }
          clientIds.push(group.account?.entityId)
          return {
            reference_number: (shipmentRefNums ?? [])?.join(', '),
            finAccountId: group.account.id,
            finAccountName: group.account.name,
            relationship_id: group.account.denimPaymentServiceInfo?.[0]?.client_debtor_relationship?.id,
            relationship_info: group.account.denimPaymentServiceInfo?.[0],
            base_amount : total_amount,
            obligation_date: dayjs().toDate(),
            due_date: dayjs().add(30, "day").toDate(),
          };
        }),
        payee_relationships: this.displayInfo.payableGroups.map((group, index) => {
          let carrier_cost = 0;
          for (let child of group.childrens || []) {
            carrier_cost = child?.cost?.grandTotal ?? 0;
          }
          carrierIds.push(group.account?.entityId)
          let childrens = group.childrens || [];
          let invoiceFiles = childrens.map(item => item.invoiceFiles).flat().filter(x=>x);
          let qbBillIds = childrens.map(item => item.finJobs)?.flat()?.map(item => item.quickbooksBillId)?.filter(x=>x) ?? [];
          return {
            finAccountId: group.account.id,
            finAccountName: group.account.name,
            relationship_id: group.account.denimPaymentServiceInfo?.[0]?.client_payee_relationship?.id,
            relationship_info: group.account.denimPaymentServiceInfo?.[0],
            carrier_cost,
            quickbooksBillId: qbBillIds?.[0],
            obligation_date: dayjs().toDate(),
            attachmentFile: invoiceFiles.map(item => {
              let key = `payee_relationships.${index}.attachmentFile.${item.id}`;
              this.fileToUpload[key] = new File([], item?.name); // tạm khởi tạo file rỗng để hiển thị tên file
              return `payee_relationships.${index}.attachmentFile.${item.id}`;
            }),
          }
        })
      }
    }
    this.carrierIds = Utils.uniqElementsArray(carrierIds).filter(x=>x);
    this.clientIds = Utils.uniqElementsArray(clientIds).filter(x=>x);
    this.fetchDataCarrierAndClient();
    return model;
  }

  private fetchDataCarrierAndClient() {
    const carrierIds = [ ...this.carrierIds ];
    const clientIds = [ ...this.clientIds ];
    let dic: any = {}
    this.finAccountDic = {}
    // let urls = carrierIds.map(id => Const.APIV2(`${Const.APIURI_CARRIERS}/${id}/get-detail-simple`));
    // urls = urls.concat(clientIds.map(id => Const.APIV2(`${Const.APIURI_CLIENTS}/${id}/get-detail-simple`)));

    let urls = carrierIds.map(id => `${Const.APIURI_CARRIERS}/${id}`);
    urls = urls.concat(clientIds.map(id => `${Const.APIURI_CLIENTS}/${id}`));
    this.api.concurrentGET(urls).subscribe(
      (resp) => {
        for (let item of resp ?? []) {
          if (item?.data?.id) {
            dic[item.data.id] = item.data;
          }
        }
        for (let payableGroup of this.displayInfo.payableGroups) {
          if (payableGroup?.account?.entityId && dic[payableGroup.account.entityId]) {
            payableGroup.account.mc = dic[payableGroup.account.entityId].basicInfo?.mc;
            this.finAccountDic[payableGroup.account.id] = dic[payableGroup.account.entityId];
          }
        }
        for (let receivableGroup of this.displayInfo.receivableGroups) {
          if (receivableGroup?.account?.entityId && dic[receivableGroup.account.entityId]) {
            this.finAccountDic[receivableGroup.account.id] = dic[receivableGroup.account.entityId];
          }
        }
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  private updateDataAfterCreateFormInput() {
    let fa = <FormArray>this.formInput.get('payee_relationships');
    for (let i = 0; i < fa.length; i++) {
      this.onPaymentTermsChange('payee_relationships', i, null);
    }
  }

  getBatchRouterLink(batch: { id: string, code: string}, type: 'AP'|'AR') {
    if (type == 'AP') return [Const.routeAdminFinAP, batch?.id];
    else return [Const.routeAdminFinAR, batch?.id];
  }

  showBatchCode(batch: { id: string, code: string}, type: 'AP'|'AR') {
    if (type == 'AR') return `AR-${batch?.code}`;
    else if (type == 'AP') return `AP-${batch?.code}`;
    else return batch?.code;
  }

  routerLinkBizEntity(relatedBiz: { id: string, code: string}, type: 'AP'|'AR') {
    if (type == 'AP') {
      return [Const.routeAdminDispatchList, relatedBiz?.id];
    } else if (type == 'AR') {
      return [Const.routeAdminOrderList, relatedBiz?.id];
    }
  }

  routerLinkAccount(account: { entityId: string }, type: 'AP'|'AR') {
    if (type == 'AP') {
      return [Const.routeAdminCarrierList, account?.entityId];
    } else if (type == 'AR') {
      return [Const.routeAdminClientList, account?.entityId];
    }
  }

  public getQBBillLink(quickbooksBillId) {
    if (!quickbooksBillId) return '';
    const baseQBURL = this.isProduction ? 'https://qbo.intuit.com' : 'https://sandbox.qbo.intuit.com';
    return `${baseQBURL}/app/bill?txnId=${quickbooksBillId}`;
  }

  getFinAccountName(groupKey, index, key) {
    return this.getItemValue(`${groupKey}[${index}].${key}`)
  }

  getFinAccountMcNumber(groupKey, index) {
    let finAccountId = this.getItemValue(`${groupKey}[${index}].finAccountId`);
    if (this.finAccountDic[finAccountId]?.basicInfo?.mc) {
      return `(MC: ${this.finAccountDic[finAccountId].basicInfo.mc})`;
    } else { return '' }
  }

  hasRelationshipId(groupKey, index, key): boolean {
    return !!this.getItemValue(`${groupKey}[${index}].${key}`)
  }

  getCompanyName(groupKey, index) {
    return this.getItemValue(`${groupKey}[${index}].relationship_info`)?.company?.company_name;
  }

  getCompanyInfo(groupKey, index) {
    return this.getItemValue(`${groupKey}[${index}].relationship_info`)?.company ?? {}
  }

  getLabelSearchCompany(groupKey, index, key) {
    return this.hasRelationshipId(groupKey, index, key) ? 'Change' : 'Search';
  }

  isVerifiedRelationship(groupKey, index) {
    return !!this.getItemValue(`${groupKey}[${index}].is_confirmed`)
  }

  onBtnConfirmRelationship(groupKey, index) {
    this.setItemValue(`${groupKey}[${index}].is_confirmed`, true);
  }

  undoVerifiedRelationship(groupKey, index) {
    this.setItemValue(`${groupKey}[${index}].is_confirmed`, false);
  }

  onBtnSearchCompany(type: 'debtor' | 'payee', groupKey, index) {
    let accountInfo;
    let finAccountId = this.getItemValue(`${groupKey}[${index}].finAccountId`);
    if (this.finAccountDic[finAccountId]) {
      accountInfo = this.finAccountDic[finAccountId];
      accountInfo.finAccount = this.getFindAccount(accountInfo, type === 'debtor' ? 'receivable' : 'payable');
    }
    DialogService.openFormDialog1(SearchDenimCompany, {
      nzComponentParams: {
        companyType: type,
        accountInfo,
        selectedCompanies: this.getDenimCompanies(accountInfo, type === 'debtor' ? 'receivable' : 'payable'),
        onClose: (reload: boolean) => {
          if (reload) {
            this.fetchDataCarrierAndClient();
            const url = Const.APIV2(`${Const.APIURI_FINANCES}/account/${finAccountId}/for-search-denim`);
            this.api.GET(url).subscribe(
              (resp) => {
                const denimInfo = resp?.data?.denimPaymentServiceInfo?.[0];
                if (denimInfo) {
                  if (type == 'debtor') {
                    const client_debtor_relationship_id = denimInfo.client_debtor_relationship?.id;
                    if (client_debtor_relationship_id) {
                      this.setItemValue(`${groupKey}[${index}].relationship_id`, client_debtor_relationship_id);
                      this.setItemValue(`${groupKey}[${index}].relationship_info`, denimInfo);
                      return;
                    }
                  } else if (type == 'payee') {
                    const client_payee_relationship_id = denimInfo.client_payee_relationship?.id;
                    if (client_payee_relationship_id) {
                      this.setItemValue(`${groupKey}[${index}].relationship_id`, client_payee_relationship_id);
                      this.setItemValue(`${groupKey}[${index}].relationship_info`, denimInfo);
                      return;
                    }
                  }
                } else {
                  this.setItemValue(`${groupKey}[${index}].relationship_id`, null);
                  this.setItemValue(`${groupKey}[${index}].relationship_info`, null);
                }
              }, (err) => {
                this.showErr(err);
              }
            )
          }
        }
      },
      nzClassName: "modal-lg",
    });
  }

  getDenimCompanies(item, type) {
    const finAccounts = item.finAccounts || [];
    const account = finAccounts.find(acc => acc.type === type);
    if (!account || !account.denimPaymentServiceInfo) return [];

    return Array.isArray(account.denimPaymentServiceInfo) ? account.denimPaymentServiceInfo: [account.denimPaymentServiceInfo];
  }

  getFindAccount(item, type) {
    return item.finAccounts.find(acc => acc.type === type);
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'debtor_relationships.base_amount':
      case 'payee_relationships.total_amount':
        return InputHelper.handleInputKeyPressMoney(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onInputChanged(event, key, index = 0) {
    switch (key) {
      case 'debtor_relationships.base_amount': {
        let fa = <FormArray>this.formInput.get('debtor_relationships');
        let fg = fa.at(index);
        return InputHelper.handleInputChangeMoney(event, <FormControl>fg.get('base_amount'), true);
      }
      case 'payee_relationships.total_amount': {
        let fa = <FormArray>this.formInput.get('payee_relationships');
        let fg = fa.at(index);
        return InputHelper.handleInputChangeMoney(event, <FormControl>fg.get('total_amount'), true);
      }
    }
  }

  onCheckboxCarrierInvoiceChanged() {
    if (this.isSkipCarrierInvoice) {
      let fa = <FormArray>this.formInput.get('payee_relationships');
      while (fa.length) {
        fa.removeAt(0);
      }
    } else {
      const model = this.bindingDataModel();
      const payee_relationships = model.payee_relationships ?? [];
      let fa = <FormArray>this.formInput.get('payee_relationships');
      while (fa.length) {
        fa.removeAt(0);
      }
      for (let item of payee_relationships) {
        this.addItemToFormArray('payee_relationships', item);
      }
      this.updateDataAfterCreateFormInput();
    }
  }

  disabledDate = (current: Date): boolean => {
    const today = new Date().setHours(0, 0, 0, 0);
    const difference: number = today - current.getTime();
    return difference > 0;
  }

  disabled30DayAgoDate = (current: Date): boolean => {
    const today = new Date().setHours(0, 0, 0, 0);
    const difference: number = today - current.getTime();
    return difference > 30 * 24 * 60 * 60 * 1000;
  }

  public defaulTimeZone = DateUtil.Timezone_LA;
  public shortTimezone = DateUtil.timezoneStandardToUsShort(this.defaulTimeZone);

  onBtnCreateJob() {
    if (!this.needUpdate) return;
    const data = this.getFormData_JSON(true);
    if (!data) return;
    let formData = new FormData();
    formData.append("params", JSON.stringify(data));
    for (let item of data.payee_relationships || []) {
      const keys = item.attachmentFile;
      // support multiple files
      for(let key of keys){
        formData.append(key, this.fileToUpload[key], this.fileToUpload[key].name);
      }
    }
    let url = Const.APIURI_FINANCES_STATEMENT(`${this.statementId}/create-denim-job`);
    this.startProgress();
    return this.api.postFormData(url, formData).subscribe(
      (resp) => {
        // this.showSuccess('Job has been created successfully.');
        this.closeDialog();
        this.onSuccess(resp?.data ?? {});
        this.stopProgress();
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }

  protected getFormData_JSON(isCreateNew) {
    let json: any = super.getFormData_JSON(true);
    for (let item of json.debtor_relationships || []) {
      if (!item.is_confirmed) {
        this.showErr('Please confirm the customer information is correct.');
        return null;
      }
      for (let key of ['obligation_date', 'due_date']) {
        if (!item[key]) continue;
        item[key] = DateUtil.convertLocalTime(item[key], DateUtil.Timezone_LA).toISOString();
      }
    }
    for (let item of json.payee_relationships || []) {
      if (!item.is_confirmed) {
        this.showErr('Please confirm the carrier information is correct.');
        return null;
      }
      for (let key of ['obligation_date', 'due_date']) {
        if (!item[key]) continue;
        item[key] = DateUtil.convertLocalTime(item[key], DateUtil.Timezone_LA).toISOString();
      }
    }
    return json;
  }

  listOfAccessorials = [
    "Additional broker fee",  "Additional Insurance", "Additional stop fee", "Additional/out of Route Miles",
    "Airport Fees", "Chassis Per Day", "Demurrage", "Detention", "Driver Unloading Assistance",
    "Drop fee", "Dunnage", "Expedite", "Extra miles", "Fuel Surcharge", "Hazmat", "Inside Delivery",
    "Layover", "Lift gate", "Lumper", "Over-dimensional", "Over-weight", "Pre-Pull", "Re-tarp",
    "Return Product", "Storage", "Team Drivers", "Toll Fees", "Trailer clean-out", "Truck Order Not Used", "White glove",
  ]

  onDocumentFileSelected(groupKey, index, key, files) {
    let attachmentFile = this.getItemValue(`${groupKey}[${index}].${key}`) || [];
    const keyTxt = `${groupKey}.${index}.${key}.${Utils.generateULID()}`;
    attachmentFile.push(keyTxt);
    this.fileToUpload[keyTxt] = files[0];
    this.setItemValue(`${groupKey}[${index}].${key}`, attachmentFile);
    console.log(this.fileToUpload)
  }

  getDocumentAttachedFileValue(groupKey, index, key) {
    return this.getItemValue(`${groupKey}[${index}].${key}`) || [];
  }
//valueKey là index của file trong mảng attachmentFile của 1 payee_relationship
  hasDocumentAttachedFile(key) {
    return !!this.fileToUpload[key];
  }

  public labelSelectFile(key): string {
    return this.hasDocumentAttachedFile(key) ? this.getDocumentFileDesc(key) : null;
  }

  getDocumentFileDesc(key): string {
    if (!this.fileToUpload[key]) return "";
    return `${this.fileToUpload[key].name} (${this.displayFileSize(
      this.fileToUpload[key].size
    )})`;
  }

  delDocumentFile(groupKey, index, key, fileKey, inputElement: HTMLInputElement) {
    if (!this.fileToUpload[fileKey]) return;
    this.confirmDeletion({
      message: `Delete file ${this.fileToUpload[fileKey].name}?`,
      txtBtnOk: "Delete",
      fnOk: () => {
        this.fileToUpload[fileKey] = undefined;
        let attachmentFile = this.getItemValue(`${groupKey}[${index}].${key}`) || [];
        attachmentFile = attachmentFile.filter((item) => item != fileKey);
        this.setItemValue(`${groupKey}[${index}].${key}`, attachmentFile);
        inputElement.value = "";
      },
    });
  }

  formatMoney(value: number | string) {
    return InputHelper.formatMoney2(`${value || 0}`);
  }

  public getPaymentFixedRateTypeName(type) {
    switch (type) {
      case 'perDay':
        return 'per Day';
      case 'perWeek':
        return 'per Week';
      case 'perMonth':
        return 'per Month';
      default:
        return '';
    }
  }

  getCarrierCost(groupKey, index) {
    const carrierCost = this.getItemValue(`${groupKey}[${index}].carrier_cost`);
    return this.formatMoney(carrierCost);
  }

  getLabelPaymentFee(groupKey, index) {
    const paymentTerms = this.getItemValue(`${groupKey}[${index}].paymentTerms`);
    if (!paymentTerms) return '';
    return `Payment fee (${paymentTerms.feePercentage}%)`;
  }

  getValuePaymentFee(groupKey, index) {
    const carrierCost = this.getItemValue(`${groupKey}[${index}].carrier_cost`);
    const paymentTerms = this.getItemValue(`${groupKey}[${index}].paymentTerms`);
    if (!paymentTerms) return '';
    let value = carrierCost * paymentTerms.feePercentage / 100;
    if (['1_8', '7_5', '15_3'].includes(paymentTerms.value) && value < MinChargeByPercent[paymentTerms.value]) {
      value = MinChargeByPercent[paymentTerms.value];
    }
    return `-${this.formatMoney(value)}`;
  }

  onPaymentTermsChange(groupKey, index, event) {
    const carrierCost = this.getItemValue(`${groupKey}[${index}].carrier_cost`);
    const paymentTerms = this.getItemValue(`${groupKey}[${index}].paymentTerms`);
    if (!paymentTerms) {
      this.setItemValue(`${groupKey}[${index}].total_amount`, carrierCost);
    } else {
      let paymentFee = carrierCost * paymentTerms.feePercentage / 100;
      if (['1_8', '7_5', '15_3'].includes(paymentTerms.value) && paymentFee < MinChargeByPercent[paymentTerms.value]) {
        paymentFee = MinChargeByPercent[paymentTerms.value];
      }

      const valueStr = (carrierCost - paymentFee).toFixed(2);
      let value = parseFloat(valueStr);
      this.setItemValue(`${groupKey}[${index}].total_amount`, value);
    }
    this.onInvoiceDateChange(groupKey, index, null);
  }

  getPayableAmount(groupKey, index) {
    const totalAmount = this.getItemValue(`${groupKey}[${index}].total_amount`);
    return this.formatMoney(totalAmount);
  }

  onInvoiceDateChange(groupKey, index, event) {
    const invoiceDate = this.getItemValue(`${groupKey}[${index}].obligation_date`);
    if (!invoiceDate) return;
    const paymentTerms = this.getItemValue(`${groupKey}[${index}].paymentTerms`);
    if (!paymentTerms || !Utils.isNumber(paymentTerms?.numOfDay)) return;
    const dueDate = dayjs(invoiceDate).add(paymentTerms.numOfDay, "day").toISOString();
    this.setItemValue(`${groupKey}[${index}].due_date`, dueDate);
  }

  checkPayableAmoutMatchQB(index): number {
    const qbBillId = this.getItemValue(`payee_relationships[${index}].quickbooksBillId`)
    if (!qbBillId) return 0;
    const qbBill = this.quickbooksBillDic[qbBillId];
    if (!qbBill) return 0;
    const qbAmount = qbBill.Balance;
    const denimAmount = this.getItemValue(`payee_relationships[${index}].total_amount`);
    return qbAmount === denimAmount ? 1 : -1;
  }

  copyQuickbookBillNo(text: string) {
    Utils.copyTextToClipboard(text, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('Bill no. has already been copied to the clipboard');
      }
    })
  }

}
