<td>
  <div class="g-session-item">
    <div class="session-section g-name">
      <div class="icon clickable" (click)="onToggleContent()">
        <span nz-icon [nzType]="isToggle ? 'up' : 'down'" nzTheme="outline"></span>
      </div>

      <div class="g-title">
        <a class="title clickable" [routerLink]="detailRouterLink">
          {{ item.name }}
        </a>
        <div class="content">
          {{ displayTimeWindow() }}
        </div>
      </div>
    </div>

    <div class="session-section g-route">
      <div class="title">
        {{ item.routeIds?.length }} Routes
      </div>
      <div class="content">
        {{ countAssigned }} assigned | {{ countHasBids }} has bids | {{ countNoBid }} no bid
      </div>
    </div>

    <div class="session-section">
      <div class="text">
        Carrier Added: {{ countCandidate }}<br />
        Invitation Sent to {{ countSentCandidate }} carriers
      </div>
    </div>

    <div class="session-section g-action">
      <div class="text clickable" (click)="onAddRoute()">
        Add Route
      </div>
      <!-- <div class="text">
        Setting
      </div> -->
    </div>
  </div>

  <div class="g-session-content" *ngIf="isToggle">
    <ng-container *ngFor="let jobId of item.routeIds">
      <div class="g-content-item">
        <div class="route-info">
          <!-- <a [href]="job.hyperLinkUrl" target="__blank">
            {{ job.hyperLinkText }}
          </a> -->
          <a [routerLink]="[routeAdminDispatchList, jobId]" target="__blank">
            Route: {{ jobId }}
          </a>
        </div>
        <!-- <div class="pickup-date">
          PU {{ job.pickupTime }}
        </div>
        <div class="count-bid">
          {{ job.countHasBids }} bids
        </div>
        <div class="assigned">
          {{ job.assignedCarrier?.name }}
        </div> -->
      </div>
    </ng-container>
  </div>
</td>