import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DialogService } from "@dialogs/dialog.service";
import { Const } from "@const/Const";
import { EditShipmentEntryAddLocation } from "../add-location";
import { Utils } from "@services/utils";
import { BaseChildEditShipmentAddRemoveStops } from "../base-child-comp";
import { DataAddRemoveStops } from "../interface";
import { ResponseAdminOrderDetail } from "@wearewarp/types-server-admin";

interface EditShipmentUICollapseState {
  data?: any,
  isExpand?: boolean,
  isLoading?: boolean,
  isAddNew?: boolean,
}

@Component({
  selector: '[form-edit-shipment-batch-multi-pick]',
  templateUrl: './view.html',
  styleUrls: ['../style.scss'],
})
export class FormEditShipmentBatchMultiPick extends BaseChildEditShipmentAddRemoveStops {

  @Input() currentShipmentEntryMode;
  @Input() modelOrder: ResponseAdminOrderDetail;
  @Output() onListStopsChange = new EventEmitter();

  readonly uiCollapseState: {picks: EditShipmentUICollapseState[], drop: EditShipmentUICollapseState} = {
    picks: [],
    drop: {}
  }
  get countPickInfos() { return this.uiCollapseState.picks.length }
  originPickIds = [];

  ngOnInit(): void {
    this.extractDataModelOrder();
  }

  private extractDataModelOrder() {
    if (!this.modelOrder) return;
    if (this.currentShipmentEntryMode == Const.ShipmentEntryMode.single) {
      let shipment = this.modelOrder?.metadata?.shipments?.[0];
      if (!shipment) return;
      const deliveryInfos = shipment.deliveryInfos || [];
      const pickInfo = deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
      const dropInfo = deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
      const itemIds : any[] = shipment.itemIds ?? [];
      pickInfo.items = this.modelOrder.items?.filter(it => itemIds.includes(it.id));
      pickInfo.shipmentWarpId = this.showShipmentCode(shipment);
      this.originPickIds.push(pickInfo.id);
      this.uiCollapseState.drop.data = dropInfo;
      this.uiCollapseState.picks.push({data: pickInfo});
    } else {
      let shipments = this.modelOrder?.metadata?.shipments || [];
      for (let shipment of shipments) {
        const deliveryInfos = shipment.deliveryInfos || [];
        const pickInfo = deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
        const dropInfo = deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
        const itemIds : any[] = shipment.itemIds ?? [];
        pickInfo.items = this.modelOrder.items?.filter(it => itemIds.includes(it.id));
        pickInfo.shipmentWarpId = this.showShipmentCode(shipment);
        this.originPickIds.push(pickInfo.id);
        if (!Utils.isObjectNotEmpty(this.uiCollapseState.drop)) {
          this.uiCollapseState.drop.data = dropInfo;
        }
        this.uiCollapseState.picks.push({data: pickInfo});
      }
    }
  }

  // return delete stop nao, add stop nao
  public getData(): DataAddRemoveStops {
    let currentPickIds = this.uiCollapseState.picks.map(it => it.data?.id);
    let newPickIds = currentPickIds.filter(it => !this.originPickIds.includes(it));
    let deletedPickIds = this.originPickIds.filter(it => !currentPickIds.includes(it));
    return {
      deleted: deletedPickIds,
      new: this.uiCollapseState.picks.filter(it => newPickIds.includes(it.data?.id)).map(it => it.data)
    }
  }

  public canUpdate() {
    if (this.uiCollapseState.picks.length == 0) return false;
    let currentPickIds = this.uiCollapseState.picks.map(it => it.data?.id);
    let newPickIds = currentPickIds.filter(it => !this.originPickIds.includes(it));
    let deletedPickIds = this.originPickIds.filter(it => !currentPickIds.includes(it));
    if (newPickIds.length == 0 && deletedPickIds.length == 0) return false;
    return true;
  }

  onBtnAddPickInfo() {
    DialogService.openDialog(EditShipmentEntryAddLocation, {
      nzMaskClosable: false,
      nzComponentParams: {
        type: Const.TaskType.PICKUP,
        onSave: (data) => {
          this.uiCollapseState.picks.push({data: data, isAddNew: true });
          this.onListStopsChange.emit();
        }
      },
      nzClassName: "modal-xxl",
      nzWrapClassName: 'add-new-location-form'
    });
  }

  canRemovePickLocation(index) { return true }

  onBtnRemovePickInfo(index, shipmentWarpId) {
    const text = shipmentWarpId ? `WarpID ${shipmentWarpId}` : `Pickup ${index + 1}`;
    DialogService.confirmDeletion({
      message: `Remove <b>${text}</b>?`,
      txtBtnOk: 'Remove',
      fnOk: () => {
        this.uiCollapseState.picks.splice(index, 1);
        this.onListStopsChange.emit();
      }
    })
  }

}