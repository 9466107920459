import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { BatchFinJob } from '.';
import { ModuleFinJobBreakdown } from '../fin-job-breakdown/module';
import { FileToolModule } from '@app/admin/components/file/module';
import { CreateByUserModule } from '@app/admin/components/create-by-user/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzIconModule,
    NzButtonModule,
    NzCollapseModule,
    ModuleFinJobBreakdown,
    CreateByUserModule,
    FileToolModule
  ],
  declarations: [BatchFinJob],
  exports: [BatchFinJob]
})
export class ModuleBatchFinJob {}