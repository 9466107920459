import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { BidCounterActivity } from '.';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzButtonModule,
    NzIconModule,
    NzToolTipModule,
  ],
  declarations: [BidCounterActivity],
  exports: [BidCounterActivity]
})
export class ModuleBidCounterActivity {}