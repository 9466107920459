import { Component, Input, OnInit } from "@angular/core";
import { Const } from "@const/Const";
import { UIHelper } from "@services/UIHelper";
import { ApiService } from "@services/api.service";
import { Utils } from "@services/utils";

interface Data {
  routeId: string,
  distance: string,
  estimatedMileage: string,
  estimatedTravelTimeInHour: string,
  tempRanges:string,
  equipment: string,
  productType: string,
  classification: string,
  stops: {
    type: string,
    address: string,
    time: string
  }[]
}

@Component({
  selector: '[basic-route-info]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class BasicRouteInfo implements OnInit {
  @Input() jobId: string;
  data: Data;
  isLoading = false;

  ngOnInit(): void {
    this.getData();
  }

  private getData() {
    this.isLoading = true;
    ApiService.instance.GET(Const.APIV2(`jobs/${this.jobId}/basic-route-info?dataOnly=1`)).subscribe(
      resp => {
        this.data = resp.data;
        this.buildTextForCopy(this.data);
        this.isLoading = false;
      }, err => {
        UIHelper.showErr(err);
        this.isLoading = false;
      }
    );
  }

  private buildTextForCopy(data: Data) {
    let txt = `Route ID: ${data.routeId}\nEquipment: ${data.equipment}\n`;
    if (data.tempRanges) {
      txt += `Temp: ${data.tempRanges}\n`;
    }
    if (data.productType) {
      txt += `Product Type: ${data.productType}\n`;
    }
    if (data.classification) {
      txt += `Shipment Classification: ${data.classification}\n`;
    }
    if (data.estimatedMileage) {
      txt += `Estimated mileage: ${data.estimatedMileage} miles\n`;
    }
    for (let i = 0; i < data.stops.length; i++) {
      const stop = data.stops[i];
      txt += `    • Stop ${i + 1}: ${stop.type}\n      ${stop.address}\n      ${stop.time}\n`;
    }
    this.plainText = txt;
  }

  private plainText = '';
  copyText() {
    Utils.copyTextToClipboard(this.plainText, e => {
      if (e) {
        UIHelper.showErr('Cannot copy text to clipboard');
      } else {
        UIHelper.showSuccess('Copy successfully.');
      }
    })
  }

}