import { Component } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { environment } from "@env/environment";
import { DateUtil } from "@services/date-utils";
import moment from "moment";

@Component({
  selector: "walmart-invoice-statement",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class WalmartInvoiceStatement extends BaseComponent {
  constructor(
  ) {
    super();
  }

  public filter = {
    clientIds: ['01H22NK3MBXBDCW6AZDWFZ09V0'], //walmart
    fromDate: moment().subtract(1, 'months').startOf('month').toDate(),
    toDate: moment().subtract(1, 'months').endOf('month').toDate()
  }
  public loading = false;

  public onExport() {
    console.log('export', this.filter);
    this.loading = true;
    const fromDate = DateUtil.toBeginOfDay(this.filter.fromDate);
    const toDate = DateUtil.toEndOfDay(this.filter.toDate);

    const params = {
      filter: JSON.stringify({
        clientId: this.filter.clientIds,
        dropoffFromDate: DateUtil.convertLocalTime(fromDate, 'America/New_York')?.toISOString(),
        dropoffToDate: DateUtil.convertLocalTime(toDate, 'America/New_York')?.toISOString()
      })
    }
    //sử dụng backendUrlWithoutCDN nếu có để tránh lỗi 504 (gateway timeout)
    let url = `${environment.backendUrlWithoutCDN || environment.backendUrl}/${Const.APIV2(Const.APIURI_FINANCES)}/reports/walmart-invoice-statement`;
    url = Utils.appendQueryStringIntoUrl(url, params);
    
    this.api.GET(url, { responseType: 'arraybuffer', timeout: 20 * 60 * 1000 }, 20 * 60 * 1000).subscribe(
      (resp) => {
        let blob = new Blob([resp], { type: "application/vnd.ms-excel" });
        let url = URL.createObjectURL(blob);
        let fileName = `walmart-invoice-statement-${moment(this.filter.fromDate).format("YYYY-MMMDD")}-${moment(this.filter.toDate).format("MMMDD")}.xlsx`;
        Utils.downloadFile(url, fileName);
        this.loading = false;
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  getApiListDataClientForFilter() {
    return `${Const.APIURI_CLIENTS_FOR_FILTER}?includeSubAccount=true`;
  }

}
