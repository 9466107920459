<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{headerText}}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>

<div>
  <form nz-form class="form-detail" [formGroup]="formInput" nzLayout="vertical">
    <div class="warp-setting">
      <div class="group-setting">
        <ng-container *ngIf="isSpecificLaneMode">
          <nz-collapse class="collapse-form-location" [nzExpandIconPosition]="'right'">
            <nz-collapse-panel [nzHeader]="tplHeader" [nzShowArrow]="false" [nzActive]="true" [nzDisabled]="true">
              <ng-template #tplHeader>
                <div class="group-warper">
                  <div class="group-title">
                    <div>
                      <div>
                        Select Lane Type
                        <span *ngIf="isRequired('laneType')" class="label-mark-required"></span>
                      </div>
                      <nz-radio-group nzButtonStyle="solid" nzName="laneType" formControlName="laneType" (ngModelChange)="onChangeLaneType($event)">
                        <label nz-radio-button *ngFor="let item of laneTypes" [nzValue]="item.value">
                          {{ item.label }}
                        </label>
                      </nz-radio-group>
                    </div>
                    <div *ngIf="getLaneType() === 'zipcode'" class="group-location">
                      <div *ngFor="let gKey of ['lanes'];" [formArrayName]="gKey" class="group-item">
                        <div class="title-item">{{getLabel(gKey)}}</div>
                        <div nz-form *ngFor="let item of getArrayControls(gKey); let ix = index" [formGroupName]="ix" class="flex">
                          <div class="form-item flex1">
                            <div class="single-line">
                              From Zipcode
                              <span *ngIf="isRequired(fullKey(gKey, 'from'))" class="label-mark-required"></span>
                            </div>
                            <div *ngFor="let key of ['from']">
                              <ng-template #tplInfo>
                                <i *ngIf="zipcodeInfo[key]?.isLoading" nz-icon nzType="loading" nzTheme="outline"></i>
                                <ng-container *ngIf="!zipcodeInfo[key]?.isLoading">
                                  <div *ngIf="!zipcodeInfo[key]?.altCities?.length">{{zipcodeInfo[key]?.desc}}</div>
                                  <div *ngIf="zipcodeInfo[key]?.altCities?.length" class="inline">
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="zipcodeInfo[key].city">
                                      <option *ngFor="let city of zipcodeInfo[key].altCities" [label]="city + ', ' + zipcodeInfo[key].state" [value]="city"></option>
                                    </select>
                                  </div>
                                </ng-container>
                              </ng-template>
                              <nz-input-group [nzPrefix]="tplIconLocation" [nzSuffix]="tplInfo" class="lane-input">
                                <input nz-input type="tel" [formControlName]="key"
                                  placeholder="Origin Zipcode"
                                  (input)="onInputChanged($event, key)"
                                  (keypress)="onInputKeyPress($event, key)"
                                  (focusout)="onInputFocusOut($event, key)" 
                                />
                              </nz-input-group>
                            </div>
                          </div>
    
                          <div nz-col nzSpan="2" style="display: flex; justify-content: center;">
                            <i nz-icon nzTheme="outline" nzType="arrow-right" style="margin-top: 29px;"></i>
                          </div>
    
                          <div class="form-item flex1">
                            <div class="single-line">
                              To Zipcode
                              <span *ngIf="isRequired(fullKey(gKey, 'to'))" class="label-mark-required"></span>
                            </div>
                            <div *ngFor="let key of ['to']">
                              <ng-template #tplInfo>
                                <i *ngIf="zipcodeInfo[key]?.isLoading" nz-icon nzType="loading" nzTheme="outline"></i>
                                <ng-container *ngIf="!zipcodeInfo[key]?.isLoading">
                                  <div *ngIf="!zipcodeInfo[key]?.altCities?.length">{{zipcodeInfo[key]?.desc}}</div>
                                  <div *ngIf="zipcodeInfo[key]?.altCities?.length" class="inline">
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="zipcodeInfo[key].city">
                                      <option *ngFor="let city of zipcodeInfo[key].altCities" [label]="city + ', ' + zipcodeInfo[key].state" [value]="city"></option>
                                    </select>
                                  </div>
                                </ng-container>
                              </ng-template>
                              <nz-input-group [nzPrefix]="tplIconLocation" [nzSuffix]="tplInfo" class="lane-input">
                                <input nz-input type="tel" [formControlName]="key"
                                  placeholder="Destination Zipcode"
                                  (input)="onInputChanged($event, key)"
                                  (keypress)="onInputKeyPress($event, key)"
                                  (focusout)="onInputFocusOut($event, key)" 
                                />
                              </nz-input-group>
                            </div>
                          </div>

                          <div class="action-lane">
                            <div class="single-line">&nbsp;</div>
                            <nz-form-item>
                              <nz-form-control>
                                <nz-space class="group-button">
                                  <span *nzSpaceItem
                                    nz-icon nzType="minus-circle"
                                    nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                                    (click)="removeItemInFormArray(gKey, ix)"
                                  ></span>

                                  <ng-container *ngIf="ix === getArrayControls(gKey).length - 1">
                                    <span *nzSpaceItem
                                      nz-icon nzType="plus-circle"
                                      nzTheme="outline"
                                      (click)="addItemToFormArray(gKey, ix)"
                                    ></span>
                                  </ng-container>
                                </nz-space>
                              </nz-form-control>
                            </nz-form-item>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ng-template #tplIconLocation><i nz-icon nzType="environment"></i></ng-template>

                    <!-- Location -->
                    <div *ngIf="getLaneType() === 'location'" class="group-location">
                      <div *ngFor="let gKey of ['lanes'];" [formArrayName]="gKey" class="group-item">
                        <div class="title-item">{{getLabel(gKey)}}</div>
                        <div nz-form *ngFor="let item of getArrayControls(gKey); let ix = index" [formGroupName]="ix" class="flex">
                          <div class="form-item flex1">
                            <div class="single-line">
                              From Location
                              <span *ngIf="isRequired(fullKey(gKey, 'from'))" class="label-mark-required"></span>
                            </div>
                            <div *ngFor="let key of ['from']">
                              <select-by-searching
                                class="lane-input"
                                [version]="2"
                                [type]="'warehouse'"
                                [showAddrWarehouse]="true"
                                [getAllAtOnce]="false"
                                [apiUrl]="getApiListDataForFilter(key)"
                                [formControlName]="key" 
                                [(listData)]="listLocationFroms"
                                [placeholder]="getPlaceHolder(key)"
                              >
                              </select-by-searching>
                            </div>
                          </div>
    
                          <div nz-col nzSpan="2" style="display: flex; justify-content: center;">
                            <i nz-icon nzTheme="outline" nzType="arrow-right" style="margin-top: 29px;"></i>
                          </div>
    
                          <div class="form-item flex1">
                            <div class="single-line">
                              To Location
                              <span *ngIf="isRequired(fullKey(gKey, 'to'))" class="label-mark-required"></span>
                            </div>
                            <div *ngFor="let key of ['to']">
                              <select-by-searching
                                class="lane-input"
                                [version]="2"
                                [type]="'warehouse'"
                                [showAddrWarehouse]="true"
                                [getAllAtOnce]="false"
                                [apiUrl]="getApiListDataForFilter(key)"
                                [formControlName]="key" 
                                [(listData)]="listLocationTos"
                                [placeholder]="getPlaceHolder(key)"
                              >
                              </select-by-searching>
                            </div>
                          </div>

                          <div class="action-lane">
                            <div class="single-line">&nbsp;</div>
                            <nz-form-item>
                              <nz-form-control>
                                <nz-space class="group-button">
                                  <span *nzSpaceItem
                                    nz-icon nzType="minus-circle"
                                    nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                                    (click)="removeItemInFormArray(gKey, ix)"
                                  ></span>

                                  <ng-container *ngIf="ix === getArrayControls(gKey).length - 1">
                                    <span *nzSpaceItem
                                      nz-icon nzType="plus-circle"
                                      nzTheme="outline"
                                      (click)="addItemToFormArray(gKey, ix)"
                                    ></span>
                                  </ng-container>
                                </nz-space>
                              </nz-form-control>
                            </nz-form-item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>

              <div class="info">
                <div *ngFor="let gKey of ['recipientTos', 'recipientCcs'];" [formArrayName]="gKey" class="group-item">
                  <div class="title-item">{{getLabel(gKey)}}</div>
                  <div nz-form *ngFor="let item of getArrayControls(gKey); let ix = index" [formGroupName]="ix">
                    <div class="tdm-row cell-spacing">
                      <div class="tdm-col tdm-col-5" *ngFor="let key of ['firstName', 'lastName', 'email', 'action']">
                        <div *ngIf="key !== 'action'">
                          <div class="form-label-v2" style="color: #1F2533;">
                            {{getLabel(fullKey(gKey, key))}}
                            <span *ngIf="isRequired(fullKey(gKey, key))" class="label-mark-required"></span>
                          </div>
                          <nz-form-item>
                            <nz-form-control>
                              <input nz-input [formControlName]="key"
                                [type]="getInputType(fullKey(gKey, key))"
                                [placeholder]="getPlaceHolder(fullKey(gKey, key))"
                                (input)="onInputChanged($event, key)"
                                (keypress)="onInputKeyPress($event, key)"
                              />
                            </nz-form-control>
                          </nz-form-item>
                        </div>

                        <div *ngIf="key === 'action'" [ngClass]="key">
                          <div class="form-label-v2">&nbsp;</div>
                          <nz-form-item>
                            <nz-form-control>
                              <nz-space class="group-button">
                                <span *nzSpaceItem
                                  nz-icon nzType="minus-circle"
                                  nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                                  (click)="removeItemInFormArray(gKey, ix)"
                                ></span>
                              </nz-space>
                            </nz-form-control>
                          </nz-form-item>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="action-btn">
                    <button nz-button (click)="addItemToFormArray(gKey)">
                      <span nz-icon nzType="plus" nzTheme="outline"></span> Add Recipient
                    </button>
                  </div>
                </div>

                <div *ngFor="let gKey of ['slackChannelIds'];" [formArrayName]="gKey" class="group-item">
                  <div class="title-item">{{getLabel(gKey)}}</div>
                  <div nz-form *ngFor="let item of getArrayControls(gKey); let ix = index" [formGroupName]="ix">
                    <div class="tdm-row cell-spacing">
                      <div class="tdm-col tdm-col-5" *ngFor="let key of ['channelId', 'action']">
                        <div *ngIf="key !== 'action'">
                          <div class="form-label-v2">
                            {{getLabel(fullKey(gKey, key))}}
                            <span *ngIf="isRequired(fullKey(gKey, key))" class="label-mark-required"></span>
                          </div>
                          <nz-form-item>
                            <nz-form-control>
                              <input nz-input [formControlName]="key"
                                [type]="getInputType(fullKey(gKey, key))"
                                [placeholder]="getPlaceHolder(fullKey(gKey, key))"
                                (input)="onInputChanged($event, key)"
                                (keypress)="onInputKeyPress($event, key)" 
                              />
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div *ngIf="key === 'action'" [ngClass]="key">
                          <div class="form-label-v2">&nbsp;</div>
                          <nz-form-item>
                            <nz-form-control>
                              <nz-space class="group-button">
                                <span *nzSpaceItem
                                  nz-icon nzType="minus-circle"
                                  nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                                  (click)="removeItemInFormArray(gKey, ix)"
                                ></span>
                              </nz-space>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="action-btn">
                    <button nz-button (click)="addItemToFormArray(gKey)">
                      <span nz-icon nzType="plus" nzTheme="outline"></span> Add channel
                    </button>
                  </div>
                </div>

                <div class="group-item">
                  <div class="title-item">
                    {{getLabel('notificationTypes')}}
                    <span *ngIf="isRequired('notificationTypes')" class="label-mark-required"></span>
                  </div>
                  <div class="tdm-row cell-spacing notification-item">
                    <nz-checkbox-wrapper (nzOnChange)="onChangeNotification($event)">
                      <div class="tdm-col" *ngFor="let noti of notificationTypes; let ntIndex = index" style="margin-bottom: 8px;">
                        <label nz-checkbox [nzChecked]="getCheckedNotification(noti.key)" [nzValue]="noti.key">{{noti.label}}</label>
                        <ng-container *ngIf="noti.options?.length && getCheckedNotification(noti.key)">
                          <div class="group-option" *ngFor="let key of noti.options">
                            <ng-container [ngSwitch]="key">
                              <div class="form-item" [ngClass]="key" *ngSwitchCase="'sentAt'">
                                <div class="form-label-v2">{{getLabel(key)}}</div>
                                <nz-form-item>
                                  <nz-form-control>
                                    <nz-input-group>
                                      <nz-time-picker 
                                        [formControlName]="'sentAt'"
                                        [nzMinuteStep]="15"
                                        nzFormat="HH:mm"
                                      >
                                      </nz-time-picker>
                                      <nz-select nzBackdrop="true" style="min-width: 100px;"
                                        [nzPlaceHolder]="getPlaceHolder('timeZone')" 
                                        nzShowSearch
                                        formControlName="timeZone">
                                        <nz-option *ngFor="let item of allShortTimezones" [nzLabel]="getTimeZoneShort(item)" [nzValue]="getTimeZoneStandard(item)"></nz-option>
                                      </nz-select>
                                    </nz-input-group>
                                  </nz-form-control>
                                </nz-form-item>
                              </div>

                              <div class="form-item" [ngClass]="key" *ngSwitchCase="'sentEvery'">
                                <div class="form-label-v2">{{getLabel(key)}}</div>
                                <nz-form-item>
                                  <nz-form-control>
                                    <nz-select [formControlName]="'sentEvery'" style="min-width: 200px;">
                                      <nz-option *ngFor="let item of [30, 60]" [nzLabel]="item" [nzValue]="item"></nz-option>
                                    </nz-select>
                                  </nz-form-control>
                                </nz-form-item>
                                <div class="form-label-v2">mins</div>
                              </div>

                              <div class="form-item" [ngClass]="key" *ngSwitchCase="'sentBeforePickup'">
                                <div class="form-label-v2">{{getLabel(key)}}</div>
                                <nz-form-item>
                                  <nz-form-control>
                                    <nz-select [formControlName]="'sentBeforePickup'" style="min-width: 200px;">
                                      <nz-option *ngFor="let item of [2, 4, 8, 12, 24, 48]" [nzLabel]="item" [nzValue]="item"></nz-option>
                                    </nz-select>
                                  </nz-form-control>
                                </nz-form-item>
                                <div class="form-label-v2">hours</div>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </nz-checkbox-wrapper>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </ng-container>

        <ng-container *ngIf="isAllLaneMode">
          <div *ngFor="let gKey of ['recipientTos', 'recipientCcs'];" [formArrayName]="gKey" class="group-item">
            <div class="title-item">{{getLabel(gKey)}}</div>
            <div nz-form *ngFor="let item of getArrayControls(gKey); let ix = index" [formGroupName]="ix">
              <div class="tdm-row cell-spacing">
                <div class="tdm-col tdm-col-5" *ngFor="let key of ['firstName', 'lastName', 'email', 'action']">
                  <div *ngIf="key !== 'action'">
                    <div class="form-label-v2" style="color: #1F2533;">
                      {{getLabel(fullKey(gKey, key))}}
                      <span *ngIf="isRequired(fullKey(gKey, key))" class="label-mark-required"></span>
                    </div>
                    <nz-form-item>
                      <nz-form-control>
                        <input nz-input [formControlName]="key"
                          [type]="getInputType(fullKey(gKey, key))"
                          [placeholder]="getPlaceHolder(fullKey(gKey, key))"
                          (input)="onInputChanged($event, key)"
                          (keypress)="onInputKeyPress($event, key)"
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </div>

                  <div *ngIf="key === 'action'" [ngClass]="key">
                    <div class="form-label-v2">&nbsp;</div>
                    <nz-form-item>
                      <nz-form-control>
                        <nz-space class="group-button">
                          <span *nzSpaceItem
                            nz-icon nzType="minus-circle"
                            nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                            (click)="removeItemInFormArray(gKey, ix)"
                          ></span>
                        </nz-space>
                      </nz-form-control>
                    </nz-form-item>
                  </div>

                </div>
              </div>
            </div>

            <div class="action-btn">
              <button nz-button (click)="addItemToFormArray(gKey)">
                <span nz-icon nzType="plus" nzTheme="outline"></span> Add Recipient
              </button>
            </div>
          </div>

          <div *ngFor="let gKey of ['slackChannelIds'];" [formArrayName]="gKey" class="group-item">
            <div class="title-item">{{getLabel(gKey)}}</div>
            <div nz-form *ngFor="let item of getArrayControls(gKey); let ix = index" [formGroupName]="ix">
              <div class="tdm-row cell-spacing">
                <div class="tdm-col tdm-col-5" *ngFor="let key of ['channelId', 'action']">
                  <div *ngIf="key !== 'action'">
                    <div class="form-label-v2">
                      {{getLabel(fullKey(gKey, key))}}
                      <span *ngIf="isRequired(fullKey(gKey, key))" class="label-mark-required"></span>
                    </div>
                    <nz-form-item>
                      <nz-form-control>
                        <input nz-input [formControlName]="key"
                          [type]="getInputType(fullKey(gKey, key))"
                          [placeholder]="getPlaceHolder(fullKey(gKey, key))"
                          (input)="onInputChanged($event, key)"
                          (keypress)="onInputKeyPress($event, key)" 
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div *ngIf="key === 'action'" [ngClass]="key">
                    <div class="form-label-v2">&nbsp;</div>
                    <nz-form-item>
                      <nz-form-control>
                        <nz-space class="group-button">
                          <span *nzSpaceItem
                            nz-icon nzType="minus-circle"
                            nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                            (click)="removeItemInFormArray(gKey, ix)"
                          ></span>
                        </nz-space>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </div>
            <div class="action-btn">
              <button nz-button (click)="addItemToFormArray(gKey)">
                <span nz-icon nzType="plus" nzTheme="outline"></span> Add channel
              </button>
            </div>
          </div>

          <div class="group-item">
            <div class="title-item">
              {{getLabel('notificationTypes')}}
              <span *ngIf="isRequired('notificationTypes')" class="label-mark-required"></span>
            </div>
            <div class="tdm-row cell-spacing notification-item">
              <nz-checkbox-wrapper (nzOnChange)="onChangeNotification($event)">
                <div class="tdm-col" *ngFor="let noti of notificationTypes; let ntIndex = index;" style="margin-bottom: 8px;">
                  <label nz-checkbox [nzChecked]="getCheckedNotification(noti.key)" [nzValue]="noti.key">{{noti.label}}</label>
                  <ng-container *ngIf="noti.options?.length && getCheckedNotification(noti.key)">
                    <div class="group-option" *ngFor="let key of noti.options">
                      <ng-container [ngSwitch]="key">
                        <div class="form-item" [ngClass]="key" *ngSwitchCase="'sentAt'">
                          <div class="form-label-v2">{{getLabel(key)}}</div>
                          <nz-form-item>
                            <nz-form-control>
                              <nz-input-group>
                                <nz-time-picker 
                                  [formControlName]="'sentAt'"
                                  [nzMinuteStep]="15"
                                  nzFormat="HH:mm"
                                >
                                </nz-time-picker>
                                <nz-select nzBackdrop="true" style="min-width: 100px;"
                                  [nzPlaceHolder]="getPlaceHolder('timeZone')" 
                                  nzShowSearch
                                  formControlName="timeZone">
                                  <nz-option *ngFor="let item of allShortTimezones" [nzLabel]="getTimeZoneShort(item)" [nzValue]="getTimeZoneStandard(item)"></nz-option>
                                </nz-select>
                              </nz-input-group>
                            </nz-form-control>
                          </nz-form-item>
                        </div>

                        <div class="form-item" [ngClass]="key" *ngSwitchCase="'sentEvery'">
                          <div class="form-label-v2">{{getLabel(key)}}</div>
                          <nz-form-item>
                            <nz-form-control>
                              <nz-select [formControlName]="'sentEvery'" style="min-width: 200px;">
                                <nz-option *ngFor="let item of [30, 60]" [nzLabel]="item" [nzValue]="item"></nz-option>
                              </nz-select>
                            </nz-form-control>
                          </nz-form-item>
                          <div class="form-label-v2">mins</div>
                        </div>

                        <div class="form-item" [ngClass]="key" *ngSwitchCase="'sentBeforePickup'">
                          <div class="form-label-v2">{{getLabel(key)}}</div>
                          <nz-form-item>
                            <nz-form-control>
                              <nz-select [formControlName]="'sentBeforePickup'" style="min-width: 200px;">
                                <nz-option *ngFor="let item of [2, 4, 8, 12, 24, 48]" [nzLabel]="item" [nzValue]="item"></nz-option>
                              </nz-select>
                            </nz-form-control>
                          </nz-form-item>
                          <div class="form-label-v2">hours</div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </nz-checkbox-wrapper>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</div>

<div *nzModalFooter>
  <div form-footer [onProgress]="onProgress" 
    [nzIconCancel]="null" [nzIconOK]="null"
    [canClickOK]="needUpdate && !onProgress"
    [canClickCancel]="!onProgress" [labelOK]="btnSubmitLabel"
    (onOK)="onBtnSave()" (onCancel)="closeDialog()"
  ></div>
</div>
