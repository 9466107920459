import { Component, Input } from "@angular/core";
import { ExtendValidators } from "@app/admin/base/validator";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { DateUtil } from "@services/date-utils";
import { StringULID } from "@wearewarp/types";

@Component({
  selector: '[add-bid-session-form]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class AddBidSessionForm extends BaseFormDialog1 {
  @Input() planningSessionId: StringULID;
  @Input() jobIds: StringULID[] = [];

  protected formGroupDeclaration: FormGroupDeclaration = {
    name: { label: "Name", required: true },
    descLong: { label: "Description", required: true },
    descShort: { label: "Short Description", required: true },
    timeFrame: { label: "Time Frame", type: 'formGroup', required: true, childItem: {
      range: {label: '', required: true, placeHolder: ['Start date time', 'End date time'], validators: ExtendValidators.validateTimeWindow},
      timezone: { label: "Timezone", required: true, initialValue: 'EST' },
    }},
  };

  ngOnInit() {
    super.ngOnInit();
  }

  timezones = DateUtil.listUsTimezones.map(it => DateUtil.usTimezoneShortDesc(it));

  private getTime(ranges: any[], timezone: string) {
    let fromTime = ranges[0];
    let toTime = ranges[ranges.length - 1];
    fromTime = DateUtil.convertLocalTime(DateUtil.toBeginOfDay(fromTime), timezone)?.toISOString();
    toTime = DateUtil.convertLocalTime(DateUtil.toEndOfDay(toTime), timezone)?.toISOString();
    return { fromTime, toTime };
  }

  onBtnSave() {
    const data = this.getFormData_JSON(true);
    const shortTimezone = data?.timeFrame?.timezone;
    const timezone = DateUtil.mapTimezoneUS(shortTimezone);
    const { fromTime, toTime } = this.getTime(data?.timeFrame?.range, timezone);

    const params = {
      name: data.name,
      descShort: data.descShort,
      descLong: data.descLong,
      fromTime,
      toTime,
      timeZone: timezone,
      jobIds: this.jobIds,
      planningSessionId: this.planningSessionId,
    }

    this.startProgress();
    this.api.POST(`${Const.APIURI_BID_SESSIONS}/create`, params).subscribe(
      (resp) => {
        this.stopProgress();
        this.showInfo(`Bid session ${data.name} has been created successfully`);
      },
      (err) => {
        this.stopProgress();
        this.showErr(err);
      }
    )
  }
}