<div class="row">
  <div class="text {{entity}}">{{text}}</div>
  <div *ngIf="isNew" class="new">New</div>
  <div class="flex1"></div>
  <div>{{price}}</div>
</div>
<div class="row">
    <a *ngIf="userLink" class="user" [ngClass]="{'deleted': isUserDeleted}" [routerLink]="userLink" target="_blank">{{userName}}</a>
    <div *ngIf="!userLink" class="user" [ngClass]="{'deleted': isUserDeleted}">{{userName}}</div>
  <div class="flex1"></div>
  <div class="user" nz-tooltip [nzTooltipTitle]="time">{{timeAgo}}</div>
</div>