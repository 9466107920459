<div class="g-bid-session">
  <div nz-form [formGroup]="formInput" class="form-add-session">
    <div nz-row nzGutter="24">
      <div nz-col nzSpan="16">
        <div class="g-info">
          <div class="group-normal">
            <div class="title">Infomation</div>
            <div class="g-form">
              <div class="g-item">
                <div class="form-label-v2">
                  Name<i>(must be unique)</i>
                  <span *ngIf="isRequired('name')" class="label-mark-required"></span>
                </div>
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input 
                      maxlength="50"
                      [formControlName]="'name'"
                      [type]="getInputType('name')"
                      [placeholder]="getPlaceHolder('name')"
                      (input)="onInputChanged($event, 'name')"
                    >
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div class="g-item">
                <div class="form-label-v2">
                  Short Description <i>(showed on list screen)</i>
                  <span *ngIf="isRequired('descShort')" class="label-mark-required"></span>
                </div>
                <nz-form-item>
                  <nz-form-control>
                    <textarea 
                      nz-input 
                      maxlength="100"
                      [placeholder]="'Ex: Same day delivery, barcode scanning required, GPS location tracking required, high commitment - high paid'"
                      [nzAutosize]="{ minRows: 2, maxRows: 3 }"
                      formControlName="descShort"
                    ></textarea>
                  </nz-form-control>
                </nz-form-item>
              </div>
  
              <div class="g-item">
                <div class="form-label-v2">
                  Long Description <i>(showed on detail screen)</i>
                  <span *ngIf="isRequired('descLong')" class="label-mark-required"></span>
                </div>
                <nz-form-item>
                  <nz-form-control>
                    <textarea 
                      nz-input 
                      maxlength="200"
                      [placeholder]="'Ex: Same day delivery, barcode scanning required, GPS location tracking required, high commitment - high paid'"
                      [nzAutosize]="{ minRows: 3, maxRows: 6 }"
                      formControlName="descLong"
                    ></textarea>
                  </nz-form-control>
                </nz-form-item>
              </div>
  
              <div class="g-item">
                <div class="form-label-v2">
                  Time frame
                  <span *ngIf="isRequired('timeFrame')" class="label-mark-required"></span>
                  <div [formGroupName]="'timeFrame'" [ngClass]="'timeFrame'">
                    <ng-container *ngFor="let key of ['range', 'timezone']">
                      <ng-container [ngSwitch]="key">
  
                        <nz-form-item *ngSwitchCase="'range'">
                          <nz-form-control>
                            <nz-range-picker
                              [nzFormat]="'yyyy-MM-dd'"
                              [nzShowToday]="true"
                              [nzPlaceHolder]="['Start Time', 'End Time']"
                              formControlName="range"
                              (ngModelChange)="onInputChanged($event, 'range')"
                            ></nz-range-picker>
                          </nz-form-control>
                        </nz-form-item>
  
                        <nz-form-item *ngSwitchCase="'timezone'">
                          <nz-form-control>
                            <nz-select formControlName="timezone" style="width: 100%; min-width: 200px;">
                              <nz-option 
                                *ngFor="let it of timezones" 
                                [nzLabel]="it.label" 
                                [nzValue]="it.value"
                              >
                              </nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="g-add-route">
            <add-route-component #formFilter (addRoute)="addRoute($event)" [selectedRoutes]="routes" [form]="formInput.get('routeInfo')"></add-route-component>
          </div>

          <div class="g-action">
            <button nz-button nzType="default" (click)="onCancel()">Cancel</button>
            <button nz-button nzType="primary" (click)="onSubmit()" [nzLoading]="isSubmitting" [disabled]="!shouldCreateBidding">Create Bidding Session</button>
          </div>
        </div>
      </div>
      
      <div nz-col nzSpan="8">
        <div class="view-sample">
          <div class="title">Carrier View Sample</div>
          <div class="image">
            <img src="/assets/img/bid-session-sample.png" alt="Carrier View Sample">
          </div>
        </div>

        <div class="route-list-view">
          <bid-session-route-list-view [routes]="routes" [settings]="settings" (removeRoute)="removeRoute($event)"></bid-session-route-list-view>
        </div>
      </div>
    </div>
  </div>
</div>