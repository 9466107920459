import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalHelper } from "@wearewarp/ng-antd";
import { Observable } from "rxjs";
import { BaseComponent } from '@abstract/BaseComponent';
import { ActivatedRoute } from '@angular/router';
import { FromCarrierRatingDialog } from './dialog';

@Component({
  selector: '[carrier-rating]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class CarrierRating extends BaseComponent {

  constructor(
    protected activatedRoute: ActivatedRoute,
    private modalHelper: ModalHelper
  ) {
    super(activatedRoute)
  }

  @Input() onSubmit: (data) => Observable<any>;
  @Input() model;
  @Input() jobHyperLink;
  @Input() onSubmitLikeDisLike;
  @Output() onDone: EventEmitter<any> = new EventEmitter<any>();
  @Input() rating
  
  onClickBtn() {
    this.modalHelper.openForm(FromCarrierRatingDialog, {
      nzClosable: false,
      labelBtnOK: 'Submit',
      labelBtnCancel: 'Cancel',
      nzComponentParams: {
        model: this.model,
        jobHyperLink: this.jobHyperLink,
        rating: this.rating,
        onSubmitLikeDisLike: this.onSubmitLikeDisLike,
        submit: (data) => this.onSubmit(data),
        refreshService: () => this.onDone.emit(),
      },
      onSubmitError: err => this.showErr(err),
      onSubmitSucceeded: () => {
        this.notification.create('success', 'Successfully!', 'Thank you for your feedback!');
        this.onDone.emit();
      },
    });
  }
}