import to from 'await-to-js';
import * as XLSX from "xlsx";
import { Component, TemplateRef, ViewChild } from "@angular/core";
import { BaseList } from "@app/admin/base/list";
import { Const } from "@const/Const";
import { ResponseCustomerWarehouseImport, ResponseCustomerWarehouseListItem } from "@wearewarp/types-server-admin/warehouse/customer-warehouse";
import { ExcelUtil } from "@services/excel-util";
import { ModalHelper } from '@wearewarp/ng-antd';
import { GopuffExcelLocationParsingResult, parseGopuff } from '../parse-excel/gopuff';
import { Utils } from '@services/utils';

@Component({
  selector: '[customer-warehouse-list]',
  templateUrl: './view.html',
  styleUrls: [ './style.scss' ]
})
export class CustomerWarehouseList extends BaseList<ResponseCustomerWarehouseListItem> {
  get limit() { return 20 }

  constructor(private modalHelper: ModalHelper) {
    super();
  }

  protected getApiUrl(): string {
    return Const.APIV2('customer-warehouses');
  }

  showWarpLocation(item: ResponseCustomerWarehouseListItem): string {
    return `[${item.warehouse.warpId} - ${item.warehouse.name}] ${item.warehouse.fullAddr}`;
  }

  countTotal: () => Promise< number> = async () => {
    const params = this.prepareParamGetList();
    const apiUrl =  Utils.appendQueryStringIntoUrl(this.getApiUrl(), {...params, countOnly: true});
    const resp = await this.api.GET(apiUrl).toPromise();
    return resp.data;
  }
 
  @ViewChild('tplSelectExcelSheets') tplSelectExcelSheets: TemplateRef<any>;
  public listExcelSheets: string[] = [];
  public selectedExcelSheet: string;
  private excelWorkBook_GoPuff: XLSX.WorkBook;

  async onFileSelected_GoPuff(files: FileList) {
    const [err, wb] = await to(ExcelUtil.readFile(files));
    if (err) {
      this.showErr(err);
    }
    if (!wb) {
      return this.showDialog(`Unable to read excel/csv file.`);
    }
    this.excelWorkBook_GoPuff = wb;
    this.listExcelSheets = Object.keys(wb.Sheets);
    if (this.listExcelSheets.length <= 0) {
      return this.showDialog('File must have at least 1 sheet.');
    } else if (this.listExcelSheets.length == 1) {
      this.onSheetSelected_GoPuff(this.listExcelSheets[0]);
    } else {
      this.modalHelper.open(this.tplSelectExcelSheets, {
        onOK: () => this.onSheetSelected_GoPuff(this.selectedExcelSheet)
      });
    }
  }

  @ViewChild('tplConfirmUploadGopuff') tplConfirmUploadGopuff: TemplateRef<any>;
  public msgConfirmUploadGopuff: string;

  private onSheetSelected_GoPuff(sheetName: string) {
    if (!sheetName) {
      return this.showErr('There is no sheet selected.');
    }
    const data = parseGopuff(this.excelWorkBook_GoPuff.Sheets[sheetName]);
    const arr: string[] = [];
    if (data.listWithoutId.length > 0) {
      arr.push(`${data.listWithoutId.length} rows without ID => skip`);
    }
    if (data.listDuplicatedId.length > 0) {
      arr.push(`${data.listDuplicatedId.length} rows that have duplicated ID => skip`);
    }
    if (data.listOutsideUs.length > 0) {
      arr.push(`${data.listOutsideUs.length} rows that are not in the US => skip`);
    }
    if (data.listWithId.length > 0) {
      arr.push(`${data.listWithId.length} rows will be handled.`);
    }
    if (arr.length == 0) {
      return this.showErr(`There is no data to handle`);
    }
    this.msgConfirmUploadGopuff = arr.join('\n');
    this.modalHelper.open(this.tplConfirmUploadGopuff, {
      onOK: () => this.handleGopuff(data)
    })
  }

  private handleGopuff(data: GopuffExcelLocationParsingResult) {
    const url = `${this.getApiUrl()}/import_gopuff`;
    this.api.POST(url, {list: data.listWithId}).subscribe(
      resp => {
        const data: ResponseCustomerWarehouseImport = resp.data;
        let msg;
        if (data.countInput != data.countHandled) {
          // VD gửi lên 100 item nhưng có 10 item đã có sẵn trong DB rồi (check theo DB) thì sẽ chỉ có 90 item được import thôi nên cần tạo report cho đúng
          msg = `Requested: ${data.countInput}<br/>Handled: ${data.countHandled}<br/>Skip due to existing: ${data.countSkipDueToExisting}`;
        } else {
          // Gửi lên bao nhiêu import hết bây nhiêu
          msg = `Handled: ${data.countHandled}`;
        }
        msg += `<br/>Succeeded: ${data.countSucceeded}<br/>Failed: ${data.countFailed}`;
        this.showDialog(msg);
      }, err => {
        this.showErr(err);
      }
    );
  }

}