import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalHelper } from "@wearewarp/ng-antd";
import { Observable } from "rxjs";
import { BaseComponent } from '@abstract/BaseComponent';
import { ActivatedRoute } from '@angular/router';
import { FromReportCarrierDialog } from './dialog';

@Component({
  selector: '[report-carrier]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ReportCarrier extends BaseComponent {

  constructor(
    protected activatedRoute: ActivatedRoute,
    private modalHelper: ModalHelper
  ) {
    super(activatedRoute)
  }

  @Input() onSubmit: (data) => Observable<any>;
  @Input() jobHyperLink;
  @Input() report;
  @Output() onDone: EventEmitter<any> = new EventEmitter<any>();
  
  onClickBtn() {
    this.modalHelper.openForm(FromReportCarrierDialog, {
      nzClosable: false,
      labelBtnOK: 'Submit',
      labelBtnCancel: 'Cancel',
      nzComponentParams: {
        reasons: this.report ?? [],
        jobHyperLink: this.jobHyperLink,
        submit: (data) => this.onSubmit(data)
      },
      onSubmitError: err => this.showErr(err),
      onSubmitSucceeded: () => {
        this.notification.create('success', 'Reported successfully!', 'Thank you for your feedback!');
        this.onDone.emit();
      },
    });
  }
}