<div class="history-list">
  <div class="title">History</div>
  <nz-timeline [nzPending]="shouldShowPending()" [nzPendingDot]="show_more">
    <nz-timeline-item *ngFor="let item of listData; let isFirst = first">
      <div [ngClass]="{'highlight': isFirst}">{{item.content}} &bull; {{getWhenBy(item)}}</div>
      <div *ngIf="isFirst">
        Please wait for a response or you can still enter the appointment below if you want to manually update this.
          <a *ngIf="item.type != 'Called'" (click)="onBtnResend(item)">Resend</a>
      </div>
    </nz-timeline-item>
  </nz-timeline>
  <a *ngIf="isShowingFull" class="show-more" (click)="isShowingFull = !isShowingFull">Collapse <i nz-icon nzType="up" nzTheme="outline"></i></a>
</div>
<ng-template #show_more>
  <a *ngIf="!isShowingFull" class="show-more" style="margin-left: 40px;" (click)="isShowingFull = !isShowingFull">Expand <i nz-icon nzType="down" nzTheme="outline"></i></a>
</ng-template>
