import { ShipmentQueueDeliveryInfo } from "@wearewarp/types-server-admin";
import { ShipmentQueueShipment } from "./interface";
import { BehaviorSubject } from "rxjs";

export class ListNeedAppointmentService {
  private _currentShipment: ShipmentQueueShipment;
  public get currentShipment(): ShipmentQueueShipment {
    return this._currentShipment;
  }
  public set currentShipment(value: ShipmentQueueShipment) {
    this._currentShipment = value;
  }

  private _currentLocation: ShipmentQueueDeliveryInfo;
  public get currentLocation(): ShipmentQueueDeliveryInfo {
    return this._currentLocation;
  }
  public set currentLocation(value: ShipmentQueueDeliveryInfo) {
    this._currentLocation = value;
    this.$currentLocation.next(value);
  }
  public $currentLocation: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor() { }
}
