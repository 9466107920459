import { Component } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { environment } from "@env/environment";
import moment from "moment";
import { DateUtil } from "@services/date-utils";
import { HttpResponse } from "@angular/common/http";

@Component({
  selector: "download-daily-route",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class DownloadDailyRouteComponent extends BaseComponent {
    constructor() {
        super();
    }

    public downloading = false;
    public fromDate: Date

    private padding(n: number) {
        if (n > 9) return n.toString()
        else return '0' + n.toString()
    }

    public onBtnExport() {
        this.downloading = true;
        const month = this.fromDate.getMonth() + 1
        
        const date = `${this.fromDate.getFullYear()}-${this.padding(month)}-${this.padding(this.fromDate.getDate())}`
        console.log(this.fromDate, date)
        const params = {
            startDate: date,
        }

        let url = `${environment.supportUrl}/job/download`;
        this.api.postDownload(url, params, {observe: 'response', responseType: 'arraybuffer'}).subscribe(
            (resp: HttpResponse<any>) => {
                let body = resp.body;
                const contentDisposition = resp.headers.get('Content-Disposition');
                const fileName = Utils.getFileNameFromContentDisposition(contentDisposition)  ?? `Routes-${date}-${(Date.now() / 1000).toFixed(0)}.xlsx`;
                const fileType = resp.headers.get('Content-Type')
                let blob = new Blob([body], { type: fileType });
                let url = URL.createObjectURL(blob);
                Utils.downloadFile(url, fileName);
                this.downloading = false
            }, err => {
                this.showErr(err);
                this.downloading = false
            }
      );
  }

}
