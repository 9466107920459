import { Pipe } from "@angular/core";

@Pipe({
  name: "getFullName",
})
export class GetFullNamePipe {
  transform(user: any): string {
    if (!user) return '';
    if (typeof user === 'string') return user;
    if (user.fullName) return user.fullName;
    let text = user.firstName || '';
    if (user.lastName) {
      if (text) {
        text += ' ';
      }
      text += user.lastName;
    }
    return text;
  }
}
