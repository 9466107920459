import { Component } from "@angular/core";
import { FinQBStatementCountTab, QBStatementTab } from "../interface";
import { Const } from "@const/Const";
import { BaseList } from "@app/admin/base/list";
import { FinQuickbookStatementPodStatus, ResponseAdminFinQuickbookStatementDetail, ResponseAdminFinQuickbookStatementListItem } from "@wearewarp/types-server-admin/fin";
import { InputHelper } from "@services/input-helper";
import { DateUtil } from "@services/date-utils";
import { FinStatementTimeWindow } from "@wearewarp/types/data-model/types/TimeWindow";
import { FinQuickbookStatementStatus, ResponseDenimFetchingPayeeRelationship } from "@wearewarp/types/data-model";
import to from "await-to-js";
import { CreateQuickbooksBill_V2 } from "../../components/quickbooks/create-bill-v2";
import { DialogService } from "@dialogs/dialog.service";
import { QuickbooksBillCreateSuccessfully } from "../../components/quickbooks/success-dialog";
import { SearchDenimCompany } from "../../components/fin-statement/denim/search-denim-company";


@Component({
  selector: 'quickbook-statement-list',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class FinQuickbookStatementList extends BaseList<ResponseAdminFinQuickbookStatementListItem> {
  private filterTabsIndex = {
    statements: 0,
    submitted: 1,
    needRouteCheck: 2,
  }
  public countSum: FinQBStatementCountTab = {};
  public totalAmt: number = 0;
  private _selectedTabIndex = 0;
  
  get selectedTabIndex() {
    return this._selectedTabIndex;
  }

  get isTabStatementCreated() {
    return this.selectedTabIndex === this.filterTabsIndex.statements;
  }

  get isTabStatementSubmitted() {
    return this.selectedTabIndex === this.filterTabsIndex.submitted;
  }

  get isTabStatementNeedRouteCheck() {
    return this.selectedTabIndex === this.filterTabsIndex.needRouteCheck;
  }

  get shouldShowPodStatus() {
    return this.isTabStatementCreated || this.isTabStatementNeedRouteCheck;
  }

  get shoudldRemoveStatement() {
    return this.isTabStatementCreated || this.isTabStatementNeedRouteCheck;
  }

  get shouldCreateBill() {
    return this.isTabStatementCreated || this.isTabStatementNeedRouteCheck;
  }

  get shouldEditDenim() {
    return this.isTabStatementCreated || this.isTabStatementNeedRouteCheck;
  }

  ngOnInit() {
    super.ngOnInit();
    this.autoShrinkSideBar();
  }

  protected getApiUrl(): string {
    return Const.APIURI_FINANCES_QB_STATEMENT();
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    super.handleNavigationEnd(url, prevQueryParam);
    this.countAll();
    this.fetchTotalAmount();
  }


  private updateCountSum(tab: QBStatementTab, value: number) {
    this.countSum = {...this.countSum, ...{[tab]: value}};
  }

  private countAll() {
    let qbStatementUrl = Const.APIURI_FINANCES_QB_STATEMENT();
    const statusCreated: FinQuickbookStatementStatus = 'created';
    const statusSubmitted: FinQuickbookStatementStatus = 'submitted';
    const statusNeedRouteCheck: FinQuickbookStatementStatus = 'needRouteCheck';
    let currentfilter = JSON.parse(this.queryParams?.filter || '') || {};    
    const statusCreatedFilter = JSON.stringify(Object.assign(currentfilter, {status: statusCreated}));
    const statusSubmittedFilter = JSON.stringify(Object.assign(currentfilter, {status: statusSubmitted}));
    const statusNeedRouteCheckFilter = JSON.stringify(Object.assign(currentfilter, {status: statusNeedRouteCheck}));
    let urls = [
      `${qbStatementUrl}?filter=${statusCreatedFilter}&countOnly=true`,
      `${qbStatementUrl}?filter=${statusSubmittedFilter}&countOnly=true`,
      `${qbStatementUrl}?filter=${statusNeedRouteCheckFilter}&countOnly=true`
    ]
    this.api.concurrentGET(urls).subscribe(
      (resp) => {
        this.updateCountSum(QBStatementTab.statements, resp[0]?.data?.total);
        this.updateCountSum(QBStatementTab.submitted, resp[1]?.data?.total);
        this.updateCountSum(QBStatementTab.needRouteCheck, resp[2]?.data?.total);
      },
      (err) => {
        this.showErr(err);
      }
    )
  }

  private fetchTotalAmount() {
    this.totalAmt = 0;
    const params = this.queryParams;
    const qs = new URLSearchParams(params).toString();
    let url = `${Const.APIURI_FINANCES_QB_STATEMENT('total-amt-for-get-list')}?${qs}`;
    this.api.GET(url).subscribe(
      (resp) => {
        this.totalAmt = resp.data.total;
      },
      (err) => {
        this.showErr(err);
      }
    )
  }

  onFilterTabChanged(value) {
    this._selectedTabIndex = value;
  }

  get showTotalAmount() {
    return InputHelper.formatMoney2(`${this.totalAmt}`);
  }

  getRouterLinkDetail(item) {
    return `${Const.routeAdminFinQBStatement}/${item.id}`;
  }

  getLinkCarrierDetail(item) {
    return `${Const.routeAdminCarrierList}/${item?.account?.id}`;
  }

  isHasPaymentInfo(item) {
    return item?.account?.hasPaymentInfo;
  }

  showStatementCode(item) {
    return `ST-${item.code}` || 'N/A';
  }

  showRouteCompletedWindow(item) {
    const windowInfo: FinStatementTimeWindow = item.routeCompletedWindow;
    const timezone = windowInfo.timezone || 'America/New_York';
    const format = 'DD MMM';
    let timeFrom = DateUtil.displayLocalTime(windowInfo.from, {timezone, format });
    let timeTo = DateUtil.displayLocalTime(windowInfo.to, {timezone, format });
    return `${timeFrom} -> ${timeTo}`;
  }
   
  showTotalRoute(item) {
    return item?.jobIds?.length || 'N/A';
  }

  showAmountItem(item) {
    return InputHelper.formatMoney2(`${item.amt}`) || 'N/A';
  }

  showPodStatus(item) {
    if (!item?.podStatus) return 'N/A';
    const podStatus: FinQuickbookStatementPodStatus = item.podStatus;
    switch (podStatus) {
      case 'needPodUpload': return 'Need POD';
      case 'needPodConfirm': return 'Need Confirm';
      case 'podConfirmed': return 'Confirmed';
      default: return 'N/A';
    }
  }

  showQuickbooksVendor(item) {
    const qbVendor = item?.account?.quickbooksInfo;
    if (!qbVendor?.Id) return 'N/A';
    return qbVendor?.CompanyName || 'N/A';
  }

  quickbooksBillLink(item) {
    return item?.accountingServiceInfo?.qbBillLink;
  }

  quickbooksVendorLink(item) {
    const vendorId = item?.account?.quickbooksInfo?.Id;
    if (!vendorId) return '';
    return this.isProduction ? `https://qbo.intuit.com/app/vendordetail?nameId=${vendorId}` : `https://sandbox.qbo.intuit.com/app/vendordetail?nameId=${vendorId}`;
  }

  onRefresh() {
    this.onBtnRefresh();
    this.countAll();
    this.fetchTotalAmount();
  }

  onBtnRemoveStatement(item) {
    this.confirmDeletion({
      message: `Are you sure you want to remove statement "${this.showStatementCode(item)}"?`,
      txtBtnOk: "Remove",
      fnOk: () => {
        let url = `${Const.APIURI_FINANCES_QB_STATEMENT()}/${item.id}`;
        this.isLoading = true;
        this.api.DELETE(url).subscribe(
          (resp) => {
            this.isLoading = false;
            this.showSuccess("Remove statement successfully.");
            this.onRefresh();
          },
          (err) => {
            this.showErr(err);
            this.isLoading = false;
          }
        )
      }
    })
  }

  async onBtnCreateBill(item) {
    let statementId = item.id;
    let getDetailUrl = `${Const.APIURI_FINANCES_QB_STATEMENT()}/${statementId}`;
    this.isLoading = true;
    const [err, resp] = await to(this.api.GET(getDetailUrl).toPromise());
    this.isLoading = false;
    if (err) {
      this.showErr(err);
      return;
    }
    const statementDetail: ResponseAdminFinQuickbookStatementDetail = resp?.data;
    this.modalService.create({
      nzContent: CreateQuickbooksBill_V2,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzClassName: "modal-xl",
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        finQbStatement: statementDetail,
        onSuccess: (quickbooksBill) => {
          this.onRefresh();
          DialogService.openDialog(QuickbooksBillCreateSuccessfully, {
            nzWidth: 400,
            nzClosable: false,
            nzMaskClosable: false,
            nzComponentParams: {
              model: quickbooksBill
            }
          })
        }
      }
    })
  }

  getDenimCompanies(item) {
    if (!item?.account?.denimPaymentServiceInfo) return [];
    return Array.isArray(item?.account?.denimPaymentServiceInfo) ? item.account.denimPaymentServiceInfo: [item.account.denimPaymentServiceInfo];
  }

  showDenimInfo(item) {
    const denimCompanies = this.getDenimCompanies(item);
    if (!denimCompanies || denimCompanies.length === 0) return 'N/A';
    const denimInfo = denimCompanies.map(company => {
      let payToFactor = company?.client_payee_relationship?.payment_setting?.to_factor ? true : false;
      return `${company?.company?.company_name} (pay to: ${payToFactor ? 'Factor' : 'Contractor'})`;
    }).join(', ');
    return denimInfo;
  }

  async onBtnEditDenim(item) {
    const carrierId = item?.account?.id;
    let getCarrierUrl = `${Const.APIURI_CARRIERS}/${carrierId}`;
    const resp = await this.api.GET(getCarrierUrl).toPromise().catch(err => {
      this.showErr(err);
    });
    const carrier = resp?.data;
    const finAccount = carrier?.finAccounts?.find(acc => acc.id === item.finAccountId);

    DialogService.openFormDialog1(SearchDenimCompany, {
      nzComponentParams: {
        companyType: 'payee',
        accountInfo: {
          ...carrier,
          finAccount
        },
        selectedCompanies: this.getDenimCompanies(item),
        onClose: (reload: boolean) => {
          if (reload) {
            this.onRefresh();
          }
        }
      },
      nzClassName: "modal-lg",
    });
  }

}