import { Component, Input, OnInit } from "@angular/core";
import { MasterData } from "@services/master.data";
import { SocketService } from "@app/socket";
import { Subscription } from "rxjs";
import { EventSocket_DriverLocation } from '@wearewarp/types/event'
import { DispatchLiveTracking, LiveTrackingJobStatus } from "../../dispatch-live-tracking";
import { JobLiveIconData } from "../../interface";
import { Const } from "@const/Const";
import { SocketEvent } from "@app/enum";


@Component({
  selector: "job-live-icon",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class JobLiveIconComponent implements OnInit {
  constructor(private socketService: SocketService, private liveTracking: DispatchLiveTracking) {
  }

  @Input() public isOnline = false;
  @Input() public data: JobLiveIconData;

  get jobId() { return this.data.jobId }
  get driverId() { return this.data.driverId }

  private updateLiveIcon() {
    this.isOnline = this.isDriverOnline && this.isJobActive;
  }
  private isDriverOnline = false;
  private isJobActive = false;
  private subscription = new Subscription();
  private checkIfJobReallyActive(job: {status: string, isActive: boolean}) {
    return job.isActive && job.status == Const.JobStatus.inProgress;
  }

  private driverLocationSubscription: Subscription
  ngOnInit(): void {
    this.startRealtimeLocation()

    this.isJobActive = this.checkIfJobReallyActive(this.data);
    this.subscription.add(this.liveTracking.trackJobStatusChange(this.jobId, (data: LiveTrackingJobStatus) => {
      this.isJobActive = this.checkIfJobReallyActive(data);
      this.updateLiveIcon();
    }));
    this.subscription.add(this.liveTracking.trackDriverOnline(this.driverId, (data: boolean) => {
      this.isDriverOnline = data;
      this.updateLiveIcon();
    }));
  }
  ngOnDestroy(): void {
    this.stopRealTimeLocation();
    this.subscription?.unsubscribe();
  }

  private timeout;

  /** @deprecated */
  private startRealtimeLocation() {
    const interval = MasterData.const.intervalDriverOnline;
    this.driverLocationSubscription = this.socketService.subscribeEvent<EventSocket_DriverLocation>(SocketEvent.updateDriverLocation, data => {
      if (!(data.jobIds ?? []).includes(this.jobId)) return;
      this.isOnline = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.isOnline = false;
      }, interval);
    })
  }

  /** @deprecated */
  private stopRealTimeLocation() {
    this.driverLocationSubscription?.unsubscribe()
  }
}
