<ng-template [nzModalTitle]>
    <div class="component-header" style="display:flex; justify-content: space-between;">
        <b>{{ titleText }}</b>
        <div class="modal-close" (click)="closeDialog()">
            <i nz-icon nzType="close"></i>
        </div>
    </div>
</ng-template>
<div>
    <div> 
        <ng-container *ngIf="!isCarrierConfirmTask">
            <p [innerHTML]="descriptionText"></p>
        </ng-container>
        <ng-container *ngIf="isCarrierConfirmTask">
            <div>
                <b>Call carrier to confirm the unconfirmed details</b><br />
                <ul class="group-list">
                    <ng-container *ngIf="isRequiredAcceptLoadTender">
                        <li>
                            Carrier {{loadTenderTaskComplete ? 'hasn\'t ' : ''}}accepted Load Tender
                            <ng-container *ngIf="acceptedLoadTenderBy">
                                <span style="color: #9A96A0;">- by {{ acceptedLoadTenderBy?.basicInfo?.name || acceptedLoadTenderBy?.name || getFullName(acceptedLoadTenderBy) }} at {{ $formatDate(acceptedLoadTenderBy.when) }} (EST)</span>
                            </ng-container>
                        </li>
                    </ng-container>
                    <li>
                        Carrier {{!isReady ? 'hasn\'t ' : ''}}confirmed Equipment
                        <ng-container *ngIf="confirmedReadyBy">
                            <span style="color: #9A96A0;">- by {{ confirmedReadyBy?.basicInfo?.name || confirmedReadyBy?.name || getFullName(confirmedReadyBy) }} at {{ $formatDate(confirmedReadyBy.when) }} (EST)</span>
                        </ng-container>
                    </li>
                    <li>
                        Carrier {{!hasDriver ? 'hasn\'t ' : ''}}assigned Driver
                        <ng-container *ngIf="hasOneDriver && assignedDriverBy">
                            <span style="color: #9A96A0;">- Assigned automatically as carrier has only 01 driver at {{ $formatDate(assignedDriverBy.when) }} (EST)</span>
                        </ng-container>

                        <ng-container *ngIf="!hasOneDriver && assignedDriverBy">
                            <span style="color: #9A96A0;">- by {{ assignedDriverBy?.basicInfo?.name || assignedDriverBy?.name || getFullName(assignedDriverBy) }} at {{ $formatDate(assignedDriverBy.when) }} (EST)</span>
                        </ng-container>
                    </li>
                </ul>
                <b>How can carrier confirm?</b><br />
                <ul class="group-list">
                    <li>They can confirm in checklist we have sent to them via email. <br/>
                        To find the email, carrier can search route ID "{{ jobCode }}" in their mailbox.</li>
                    <li>
                        If they can’t find the email, you can resend Booking confirmation email 
                        <div class="flex">
                            <div class="flex1 code-block">
                              <span #pre>{{automationTrackingUrl}}</span>
                              <i nz-icon nzType="copy" nzTheme="outline" class="copy clickable" style="padding: 8px;" (click)="onBtnCopyTrackingLink()"></i>
                            </div>
                            <button nz-button class="btn-send-sms" [nzLoading]="isLoadingEmail" (click)="onBtnSendEmail()">Send Email</button>
                        </div>
                    </li>
                </ul>
            </div>
        </ng-container>
    </div>
    <form [formGroup]="formInput" *ngIf="isCompleteTask">
        <div class="top20 bottom20">
            <div class="form-label">Note: </div>
            <textarea [formControlName]="'note'" [placeholder]="getPlaceHolder('note')" nz-input [nzAutosize]="{ minRows: 3, maxRows: 5 }"></textarea>
        </div>
    </form>
</div>

<ng-container *ngIf="isCompleteTask">
    <div form-footer 
        [nzIconCancel]="null" 
        [nzIconOK]="null" 
        (onOK)="onBtnSave()"
        labelOK="Update" 
        [canClickOK]="true" 
        [canClickCancel]="true" 
        (onCancel)="closeDialog()" 
        style="display: flex; flex-direction: row-reverse; justify-content: flex-end; margin-top: 20px;"
    ></div>
</ng-container>