import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  EventSocket_DriverRequestMobileAppStatus,
  EventSocket_DriverMobileAppStatus,
  EventSocket_DriverRequestStartBackgroundLocation,
} from "@wearewarp/types/event";
import { UnitConverter } from "@wearewarp/universal-libs";
import { SocketEvent } from '@app/enum';
import { SocketService } from '@app/socket';
import { Subscription } from 'rxjs';
import { MobileAppDriverInfo, MobileDeviceInfo } from '@wearewarp/types/data-model';
import { DateUtil } from '@services/date-utils';
import { DispatchLiveTracking } from '..';
import { Log } from '@services/log';
import { Utils } from '@services/utils';
import { BizUtil } from '@services/biz';

interface DisplayInfo {
  platform: 'ios' | 'android' | string,
  deviceInfo: string,
  screenSize: string,
  batteryInfo: string,
  firstInstall: string,
  locationPermission: string,
  backgroundLocation: 'running' | 'stop',
  location: string,
  locationTime: string,
  accuracy: string,
  accuracyWarning: string,
  speed: string,
  appVersion: string,
  appState: string,
  activeRoute: string,
  assignedRoutes: {id: string, code: string, status: string, isActive: boolean}[]
}

@Component({
  selector: 'driver-app-status',
  templateUrl: './view.html',
  styleUrls: ['./style.scss'],
})
export class DriverAppStatus implements OnInit, OnDestroy {
  @Input() driverId: string;
  @Input() jobCode: string;
  private subscription: Subscription = new Subscription();

  /**
   * @deprecated giữ để tương thích version cũ, sau này sẽ bỏ
   */
  private devices: {[id: string]: DisplayInfo} = {}

  public isLoading = false;
  public displayInfos: DisplayInfo[] = [];
  public errMsg = '';

  constructor(private socket: SocketService, private dispatchLiveTracking: DispatchLiveTracking) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.dispatchLiveTracking.trackDriverOnline(this.driverId, () => {});
    this.subscription.add(this.socket.subscribeEvent<EventSocket_DriverMobileAppStatus>(SocketEvent.driverMobileAppStatus, this.onData_old.bind(this))); // giữ để tương thích version cũ, sau này sẽ bỏ
    if (!this.socket.emit<EventSocket_DriverRequestMobileAppStatus>(SocketEvent.driverRequestMobileAppStatus, {data: {driverId: this.driverId}, callback: this.onData.bind(this)})) {
      this.isLoading = false;
      this.errMsg = `Socket disconnected`;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getIcon(device: 'ios' | 'android') {
    if (device == 'ios') {
      return 'assets/img/ic_device_iphone.svg';
    } else {
      return 'assets/img/ic_device_android.svg';
    }
  }

  private onData(result: {error?: any, data?: any}) {
    Log.d(`[DriverAppStatus#onData] result: `, result);
    this.isLoading = false;
    if (result.error) {
      Log.e(`[DriverAppStatus#onData] error `, result.error);
      this.errMsg = Utils.getErrorString(result.error);
      return;
    }
    const list: EventSocket_DriverMobileAppStatus[] = result.data;
    if (!Utils.isArrayNotEmpty(list)) {
      this.errMsg = 'No data';
      return;
    }
    this.displayInfos = [];
    for (let item of list) {
      this.displayInfos.push(this.buildDisplayData(item))
    }
  }

  /**
   * @deprecated giữ để tương thích version cũ, sau này sẽ bỏ
   */
  private onData_old(data: EventSocket_DriverMobileAppStatus) {
    Log.d(`[DriverAppStatus#onData_old] data: `, data);
    const id: string = data.sessionId!;
    this.devices[id] = this.buildDisplayData(data);
    this.displayInfos = Object.values(this.devices);
    this.isLoading = false;
  }

  private getActiveJob(data: EventSocket_DriverMobileAppStatus) {
    if (data?.openningJobs && data.openningJobs.length > 0) {
      return data.openningJobs.filter(it => it.isActive)[0];
    } else {
      return data?.activeJob;
    }
  }

  private buildDisplayData(data: EventSocket_DriverMobileAppStatus): DisplayInfo {
    const info: DisplayInfo = {
      platform: data?.device?.platform ?? 'N/A',
      deviceInfo: 'N/A',
      screenSize: 'N/A',
      batteryInfo: 'N/A',
      firstInstall: 'N/A',
      locationPermission: 'N/A',
      backgroundLocation: data?.backgroundLocationRunning == true ? 'running' : 'stop',
      location: 'N/A',
      locationTime: '',
      accuracy: 'N/A',
      accuracyWarning: '',
      speed: 'N/A',
      appVersion: 'N/A',
      appState: 'N/A',
      activeRoute: 'N/A',
      assignedRoutes: [],
    };
    const activeJob = this.getActiveJob(data);
    if (activeJob?.code) {
      info.activeRoute = activeJob.code;
      if (activeJob.code != this.jobCode) {
        info.activeRoute += ` (running another route)`;
      }
    }
    const device: MobileDeviceInfo = data?.device;
    if (device) {
      info.deviceInfo = `${device.brand}, ${device.model}, ${device.os} ${device.osVersion}`;
      info.screenSize = `${Math.round(device.screenSizePixel.width)} x ${Math.round(device.screenSizePixel.height)}`;
      if (device.batteryLevel >= 0) {
        info.batteryInfo = `${Math.floor(device.batteryLevel * 100)}%`;
        const tags = [];
        if (device.lowPowerMode) {
          tags.push('low power mode');
        }
        if (device.batteryState == 'charging') {
          tags.push(device.batteryState);
        }
        if (tags.length > 0) {
          info.batteryInfo += ` (${tags.join(', ')})`;
        }
      }
    }
    const gpsData = data?.lastKnownLocation;
    if (gpsData?.latitude && gpsData?.longitude) {
      info.location = `${gpsData.latitude.toFixed(5)}, ${gpsData.longitude.toFixed(5)}`;
      if (data.lastKnownLocationTs) {
        info.locationTime = DateUtil.format(data.lastKnownLocationTs, 'h:mm A');
      }
    }
    if (gpsData?.accuracy != null) {
      info.accuracy = gpsData.accuracy.toFixed(2);
      if (gpsData.accuracy > 300) {
        info.accuracyWarning = 'Precise Location might be off';
      }
    }
    if (gpsData?.speed && gpsData?.speed > 0) {
      // meters per second => miles per hour
      const s = UnitConverter.metersToMiles(gpsData.speed) * 3600;
      info.speed = `${s.toFixed(2)} mph`;
    }
    const app: MobileAppDriverInfo = data?.app;
    if (app) {
      info.appVersion = app.versionText;
      info.firstInstall = `${DateUtil.format(app.firstInstallTimeMs, 'MMM D, YYYY, h:mm a')}`;
      info.locationPermission = app.locationPermission;
      info.appState = app.appState;
    }
    info.assignedRoutes = data?.openningJobs ?? [];
    for (let item of info.assignedRoutes) {
      item.status = BizUtil.getStatusJob(item.status);
      let tags = [];
      if (item.code == this.jobCode) {
        tags.push('this route');
      }
      if (item.isActive) {
        tags.push('active');
      }
      if (tags.length > 0) {
        item.status += ` (${tags.join(', ')})`;
      }
    }
    return info;
  }

  public startBackgroundLocation() {
    this.socket.emit<EventSocket_DriverRequestStartBackgroundLocation>(SocketEvent.driverRequestStartBackgroundLocation, {data: {driverId: this.driverId}});
  }

  sendCommand_reloadOpenningRoutes() {
    this.sendSocketCommand('refresh_list_job_openning');
  }

  sendCommand_startBaclgroundLocation() {
    this.sendSocketCommand('start_background_location');
  }

  private sendSocketCommand(code: string) {
    this.socket.emit<any>(SocketEvent.driverAppCommand, {data: {driverId: this.driverId, code}});
  }

}