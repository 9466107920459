import { Component, Input } from "@angular/core";
import { DetailCarrierRatingDialog } from "@app/admin/carriers/detail-carrier-rating";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { ApiService } from "@services/api.service";
import { isSysAdmin } from "@services/auth.check-role";
import { DateUtil } from "@services/date-utils";
import { getApp } from "@services/index";
import { InputHelper } from "@services/input-helper";
import { UIHelper } from "@services/UIHelper";
import { Utils } from "@services/utils";
import dayjs from "dayjs";


@Component({
  selector: '[carrier-sale-expand-more-candidate-infos]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class CarrierSaleExpandMoreCandidateInfos {
  @Input() candidate: any;
  @Input() bid: any;

  constructor(protected api: ApiService){
  }

  public isLoadingStatistic: boolean = false;
  public isLoadingWorkingHistory: boolean = false;
  public isLoadingSameDay: boolean = false;
  public isLoadingSameDayBids: boolean = false;
  public biddingStatistic: any = {
    zipcodes: [],
    vehicles: []
  }
  public listJobSameDayAndEquipment = [];
  public workingHistory: {
    lastCompletedRoute?: any,
    notUserLiveTrackingCount?: any,
    canceledAfterBooked?: any,
    lateJobCount?: any
  } = {}
  private authUser: any;
  public listBidsSameDay = [];

  ngOnInit() {
    this.authUser = getApp()?.getAuthUser();
    this.getMoreCarrierBiddingStatistic();
    this.fetchDataSameRouteAlreadyAssigned();
    this.fetchCarrierWorkingHistory();
    this.fetchListBidSameDay();
  }

  ngOnChanges() {
  }

  get hasBasePrice() {
    return this.bid?.basePrice ? true : false;
  }
  
  get isSentInvitation() {
    return this.candidate?.lastSent ? true : false;
  }

  get isSentLostBid() {
    return this.candidate?.sentLostBid ? true : false;
  }
  
  get noDataFraudDetection() {
    return !this.listJobSameDayAndEquipment.length;
  }

  get noDataWorkingHistory() {
    return !this.showCanceledHistory() &&
          !this.workingHistory?.lateJobCount &&
          !this.workingHistory?.notUserLiveTrackingCount &&
          !this.showLastCompletedRoute() &&
          !this.listBidsSameDay.length;
  }

  $asMoney = (money) => {
    return InputHelper.formatMoney1(money + '');
  };

  $documentColor = (isPassed: boolean) => {
    return isPassed ? 'success' : 'default';
  }

  $goHighWayColor = (status: string) => {
    switch (status) {
      case 'pass':
      case 'partial_pass':
        return 'success';
      case 'fail':
        return 'red';
      default:
        return '';
    }
  }

  $goHighWayStatus = (status: string) => {
    switch (status) {
      case 'partial_pass':
        return 'Partial Pass';
      case 'pass':
        return 'Pass';
      case 'incomplete':
        return 'Incomplete';
      case 'fail':
        return 'Fail';
      default:
        return 'N/A';
    }
  }

  whyAdded(candidate) {
    if (!candidate?.metadata?.source) return;
    let text = '';
    switch (candidate.metadata.source) {
      case 'GEO_FEATURE_ASSIGNED':
        text += `This carrier was assigned to same lane in the past. LOAD: ${candidate.metadata.loadCode}.`;
        break;
      case 'GEO_FEATURE_PLACED':
        text += `This carrier placed bid to same lane in the past. LOAD: ${candidate.metadata.loadCode}.`;
        break;
      case 'EXTERNAL_MATCHING':
        text += `This carrier was matched by external system. Source: ${candidate.metadata?.searching?.source}`;
        break;
      case 'SAME_MARKET':
        text += `This carrier placed bid to same MARKET in the past. Source: ${candidate.metadata?.searching?.market}. LOAD: ${candidate.metadata?.loadCode}`;
        break;
      case 'PREFERRED_LANE':
        text += `This carrier has set this lane in the profile. Lane: ${candidate.metadata?.searching?.from?.city}, ${candidate.metadata?.searching?.from?.state} -> ${candidate.metadata?.searching?.to?.city}, ${candidate.metadata?.searching?.to?.state}`;
        break;
      default:
        text += candidate.metadata.source;
    }
    if (candidate.metadata?.searching?.radius) {
      text += ` Radius: ${candidate.metadata?.searching?.radius} miles.`
    }
    return text;
  }

  public formatDate(date: string) {
    if (!date) return '';
    return DateUtil.formatDate(date, Const.FORMAT_GUI_DATETIME_V4);
  }

  public hasCounterBidHistory() {
    if (this.candidate?.bidAnswers && Utils.isArrayNotEmpty(this.candidate?.bidAnswers)) return true;
    return false;
  }

  getAuthorOfBidCounter(couterItem) {
    if (couterItem?.entity == 'carrier') return 'Carrier';
    if (couterItem?.entity == 'admin') return 'Warp';
    return 'N/A'
  }

  showBidCounterAnswer(counter, index) {
    let action = index == 0 ? 'bid' : 'counter';
    return `${counter.price ? this.$asMoney(counter.price) : '-'} ${this.getAuthorOfBidCounter(counter)} ${action}`;
  }

  showBidCounterAnswerWhenBy(counter) {
    return `${this.formatDate(counter?.update?.when)} ${counter?.entityName ? 'by ' + counter.entityName : ''}`
  }

  showBasePrice() {
    return `${this.$asMoney(this.bid?.basePrice)} WARP offer base price`;
  }

  showInvitationSentTime() {
    if (!this.candidate?.lastSent?.when) return 'N/A';
    return dayjs(this.candidate.lastSent.when).tz('America/New_York').format(Const.FORMAT_GUI_DATETIME_V4);
  }

  showLostBidSentTime() {
    if (!this.candidate?.sentLostBid?.when) return 'N/A';
    return dayjs(this.candidate.sentLostBid.when).tz('America/New_York').format(Const.FORMAT_GUI_DATETIME_V4);
  }

  getMoreCarrierBiddingStatistic() {
    if (!this.candidate?.carrierId) return;
    this.isLoadingStatistic = true;
    const url = `${Const.APIV2(Const.APIURI_CARRIERS)}/${this.candidate.carrierId}/carrier-bidding-data`;
    this.api.GET(url).subscribe(
      (resp) => {
        this.buildDisplayBiddingStatictic(resp?.data);
        this.isLoadingStatistic = false;
      },
      (err) => {
        this.isLoadingStatistic = false;
      }
    )
  }

  buildDisplayBiddingStatictic(data) {
    this.biddingStatistic.zipcodes = (data?.zipcodes || []).map(it => {
      let location = this.getBiddingLocation(it.address);
      return {
        ...it,
        statistic: `${location} - ${it.percentage || 'N/A'}`
      }
    })
    this.biddingStatistic.vehicles = (data?.vehicles || []).map(it => {
      let vehicle = this.getBiddingEquipment(it.vehicle);
      return {
        ...it,
        statistic: `${vehicle} - ${it.percentage || 'N/A'}`
      }
    })
  }

  getBiddingLocation(addr) {
    if (!addr) return 'Other';
    if (addr?.city && addr?.state) {
      return `${addr.city}, ${addr.state}`;
    }
    return addr?.state ? addr.state : addr?.city || 'N/A';
  }

  getBiddingEquipment(vehicle) {
    if (!vehicle) return 'Other';
    return vehicle.name ? vehicle.name : vehicle.code || 'N/A';
  }


  getStatisticItemRank(idx) {
    return `rank_${idx}`;
  }

  private fetchDataSameRouteAlreadyAssigned() {
    const carrierId = this.candidate.carrierId;
    const jobId = this.bid?.jobId;
    if (!carrierId || !jobId) {
      return;
    }
    this.listJobSameDayAndEquipment = [];
    const url = Const.APIV2(`${Const.APIURI_CARRIERS}/${carrierId}/get-same-route-already-assigned`)
    this.isLoadingSameDay = true;
    this.api.POST(url, { jobId }).subscribe(
      (resp) => {
        if (resp?.data?.list_data?.length) {
          this.listJobSameDayAndEquipment = resp.data.list_data;
        }
        this.isLoadingSameDay = false;
      },
      (err) => {
        this.isLoadingSameDay = false;
      }
    );
  }

  showJobsSameDay() {
    let text = this.listJobSameDayAndEquipment?.map(it => it.code).join(', ');
    return text || '';
  }

  private fetchCarrierWorkingHistory() {
    const carrierId = this.candidate.carrierId;
    const url = Const.APIV2(`${Const.APIURI_CARRIERS}/${carrierId}/working-history`)
    this.isLoadingWorkingHistory = true;
    this.api.GET(url).subscribe(
      resp => {
        this.workingHistory = resp.data;
        this.isLoadingWorkingHistory = false;
      },
      err => {
        this.isLoadingWorkingHistory = false;
      }
    )
  }

  showCanceledHistory() {
    return this.workingHistory.canceledAfterBooked?.map(it => it.code)?.join(', ') || '';
  }

  showLateJobCount() {
    let lateJobs = this.workingHistory.lateJobCount || 0;
    return `${lateJobs} ${lateJobs > 1 ? 'routes' : 'route'}`;
  }

  showNotUseLiveTrackingCount() {
    const jobCount = this.workingHistory.notUserLiveTrackingCount || 0;
    return `${jobCount} ${jobCount > 1 ? 'routes' : 'route'}`;
  }

  showLastCompletedRoute() {
    return this.workingHistory.lastCompletedRoute?.map(it => it.code)?.join(', ') || '';
  }

  gotoCarrierBid(bidItem) {
    let bidId = bidItem?.bidId || '';
    return `${Const.routeAdminCarrierSales}/${bidId}`;
  }

  gotoDispatch(jobItem) {
    let jobId = jobItem?.id || jobItem?._id || '';
    return `${Const.routeAdminDispatchList}/${jobId}`;
  }

  showCanceledItemRouteCode(item, idx) {
    return `${item?.code}${idx < this.workingHistory?.canceledAfterBooked?.length - 1 ? ',' : ''}`;
  }

  showLastCompletedRouteCode(item, idx) {
    return `${item?.code}${idx < this.workingHistory?.lastCompletedRoute?.length - 1 ? ',' : ''}`;
  }

  showSameDayItemRouteCode(item, idx) {
    return `${item?.code}${idx < this.listJobSameDayAndEquipment?.length - 1 ? ',' : ''}`;
  }

  showBidPricePlace(item) {
    return this.$asMoney(item.price) || 'N/A';
  }

  getStopAddressText(addr) {
    if (!addr) return '';
    let addrArray = [addr.city, addr.state];
    return addrArray.filter(it => it).join(', ')
  }

  get shouldShowCarrierRating() {
    return isSysAdmin(this.authUser);
  }

  onBtnViewDetailRating() {
    const totalShipmentCost = InputHelper.getValueMoney(this.bid?.job?.totalShipmentsCost) || 0;
    if (!this.candidate?.price) {
      UIHelper.showErr('This carrier has no price');
      return;
    }
    const margin = totalShipmentCost ? 1 - this.candidate?.price / totalShipmentCost : 0;
    let carrierInfo = {
      id: this.candidate?.carrierId,
      basicInfo: {
        name: this.candidate?.name,
        mc: this.candidate?.mc,
        dot: this.candidate?.dot,
      },
      margin
    }
    DialogService.openDialog(DetailCarrierRatingDialog, {
      nzComponentParams: {
        carrierInfo,
        isBiddingScore: true
      },
      nzClosable: true,
      nzClassName: 'modal-no-padding',
      nzCentered: true,
      nzWidth: '560px'
    });
  }

  private fetchListBidSameDay() {
    const carrierId = this.candidate.carrierId;
    const pickDate = this.bid?.pickupDate;
    const timezone = this.bid?.pickupAddress?.metadata?.timeZoneStandard || "America/New_York";
    const url = Const.APIV2(`${Const.APIURI_CARRIERS}/${carrierId}/get-same-day-bids`)
    this.isLoadingSameDayBids = true;
    this.api.POST(url, { pickDate, timezone }).subscribe(
      resp => {
        this.listBidsSameDay = resp?.data?.list_data ?? [];
        this.listBidsSameDay = this.listBidsSameDay.filter(bid => bid.jobCode != this.bid?.jobCode);
        this.isLoadingSameDayBids = false;
      },
      err => {
        this.isLoadingSameDayBids = false;
      }
    )
  }
}