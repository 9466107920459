import { NgModule } from '@angular/core';
import { BidSessionListModule } from './list/module';
import { BidSessionCreateModule } from './create/module';
import { BidSessionDetailModule } from './detail/module';
import { BidSessionAddRouteModule } from './add-routes/module';
import { BidSessionRoutingModule } from './bidSession.route';

@NgModule({
  imports: [
    BidSessionListModule,
    BidSessionCreateModule,
    BidSessionDetailModule,
    BidSessionAddRouteModule,
    BidSessionRoutingModule
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: [
  ]
})
export class BidSessionModule { }
