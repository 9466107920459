import { Component, Input } from "@angular/core";
import { BaseForm, FormGroupDeclaration } from '@wearewarp/ng-form';
import { Const as WarpConst } from "@wearewarp/universal-libs";

export interface FormReportCarrier {
  reasons: {value: string, label: string, checked: boolean }[],
}
@Component({
  selector: 'form-report-carrier-dialog',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss'
  ]
})

export class FromReportCarrierDialog extends BaseForm<FormReportCarrier> {
  protected override formGroupDeclaration: FormGroupDeclaration = {
    reasons: {label: ''},
  }
  @Input() reasons;
  @Input() jobHyperLink: { hyperLinkText: string, hyperLinkUrl: string };
  options = [
    { value: WarpConst.CarrierReportReason.stolenAsset, label: 'Stolen Asset', checked: false },
    { value: WarpConst.CarrierReportReason.scammer, label: 'Scammer', checked: false },
    { value: WarpConst.CarrierReportReason.routingComplianceViolation, label: 'Routing Compliance Violation', checked: false },
  ]
  
  ngOnInit(): void {
    super.ngOnInit();
    if (this.reasons?.length) {
      for (const option of this.options) {
        if (this.reasons.includes(option.value)) {
          option.checked = true;
        }
      }
    }
    this.setItemValue('reasons', this.options)
  }
  canSubmit(): boolean {
    return super.canSubmit();
  }
}