import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResponseAdminFinBatchDetailFinJob } from '@wearewarp/types-server-admin/fin';
import { ConstFin } from '@wearewarp/js-const-finance';
import { InputHelper } from '@services/input-helper';
import { FinType } from '@wearewarp/types';
import { Const } from '@const/Const';
import { Utils } from '@services/utils';
import { FinUtil } from '../../util';
import { BaseComponent } from '@abstract/BaseComponent';
import { FinService } from '../../fin-service';
import { CreateQuickbooksBill } from '../quickbooks/create-bill';
import { DialogService } from '@dialogs/dialog.service';
import { QuickbooksBillCreateSuccessfully } from '../quickbooks/success-dialog';

@Component({
  selector: '[batch-fin-job]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss', '../../status.scss']
})
export class BatchFinJob extends BaseComponent implements OnInit {
  @Input() parentFinJob: ResponseAdminFinBatchDetailFinJob | undefined;
  @Input() finJob: ResponseAdminFinBatchDetailFinJob;
  @Input() finType: FinType;
  @Input() index: number;
  @Input() finAccountId: string;
  isExpand = false;

  get isAR(): boolean { return this.finType == 'receivable' }
  get isAP(): boolean { return this.finType == 'payable' }
  get isReadyToProcess(): boolean { return this.finJob.intermediateStatus === ConstFin.FinJob_IntermediateStatus.readyToProcess }
  get isAlreadyProcessed(): boolean { return this.finJob.intermediateStatus === ConstFin.FinJob_IntermediateStatus.alreadyProcessed }

  constructor(private finService: FinService) {
    super();
  }

  ngOnInit(): void {
  }

  showFinJobCode(finJob) {
    return `FIN-${finJob?.code}`;
  }

  showFinJobStatus(finJob) {
    return FinUtil.showStatusFinIntermediate(finJob.intermediateStatus);
  }

  isAdditional(finJob) {
    return finJob.isAdditional;
  }

  toggleFinJobBreakdown() {
    this.isExpand = !this.isExpand
  }

  getAmtDesc(finJob) {
    if (finJob.isAdditional) {
      return this.isAR ? `Additional receivable amount` : `Additional payable amount`;
    } else {
      return this.isAR ? `Receivable amount` : `Payable amount`;
    }
  }

  isFinJobDone(finJob) {
    return finJob.intermediateStatus == ConstFin.FinJob_IntermediateStatus.alreadyProcessed;
  }

  showFinJobAmt(finJob) {
    return '$' + InputHelper._formatMoney(finJob.amt, 2);
  }

  showRelatedBizType() {
    return Utils.capitalizeFirstLetter(this.finJob.relatedBiz.type);
  }

  showRelatedBizCode() {
    return this.finJob.relatedBiz.code;
  }

  routerLinkRelatedBiz() {
    if (this.isAP) {
      return [Const.routeAdminDispatchList, this.finJob.relatedBiz.id];
    }
    if (this.parentFinJob) {
      return [Const.routeAdminOrderList, this.parentFinJob.relatedBiz.id];
    }
    return [Const.routeAdminOrderList, this.finJob.relatedBiz.id];
  }

  getCarrierInvoiceName(){
    if(this.isAR) return 'N/A';
    return `INV-${this.finJob.relatedBiz.code}-${this.index+1}`
  }

  uploadCarrierInvoice(data: any){
    let {file, filename} = data;
    if(!file) return;
    let apiUrl = `${Const.APIV2(Const.APIURI_JOBS)}/upload-carrier-invoice`;
    let formData = new FormData();
    const jsonData: any = {
      'jobId': this.finJob.relatedBiz.id,
    }
    if(this.finJob.relatedBiz?.additionalCostId){
      jsonData['additionalCostId'] = this.finJob.relatedBiz.additionalCostId;
    }
    formData.append("params", JSON.stringify(jsonData));
    formData.append("uploadInvoice", file, filename);
    this.api.postFormData(apiUrl, formData).subscribe(
      (resp) => {
        this.finService.onRefreshAPBatchDetail.emit();
        this.finService.onRefreshARBatchDetail.emit();
        this.showSuccess("Your invoice has been added successfully.");
      },
      (err) => {
        this.showErr(err);
      }
    )
  }

  onDeleteCarrierInvoice(){
    this.confirmYesNo("Are you sure you want to delete this invoice?",
      () => { this.deleteCarrierInvoice()}
    )
  }

  private deleteCarrierInvoice(){
    const params: any = {
      'jobId': this.finJob.relatedBiz.id,
    }
    if(this.finJob.relatedBiz?.additionalCostId){
      params['additionalCostId'] = this.finJob.relatedBiz.additionalCostId;
    }
    let apiUrl = `${Const.APIV2(Const.APIURI_JOBS)}/delete-carrier-invoice`;
    this.api.POST(apiUrl, params).subscribe(
      (resp) => {
        this.finService.onRefreshAPBatchDetail.emit();
        this.finService.onRefreshARBatchDetail.emit();
        this.showSuccess("Your invoice has been deleted successfully.");
      },
      (err) => {
        this.showErr(err);
      }
    )
  }

  onBtnCreateQBBill() {
    this.modalService.create({
      nzContent: CreateQuickbooksBill,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzClassName: "modal-xl",
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        finJob: this.finJob,
        finAccountId: this.finAccountId,
        onSuccess: (quickbooksBill) => {
          let afterCloseSuccess = new EventEmitter();
          afterCloseSuccess.subscribe(() => {
            this.finService.onRefreshAPBatchDetail.emit();
            this.finService.onRefreshARBatchDetail.emit();
          });
          DialogService.openDialog(QuickbooksBillCreateSuccessfully, {
            nzWidth: 400,
            nzClosable: false,
            nzMaskClosable: false,
            nzComponentParams: {
              model: quickbooksBill
            },
            nzAfterClose: afterCloseSuccess,
          });
        }
      }
    });
  }

  get shouldShowCreateQBBill() {
    return this.isAP 
      && !this.finJob.accountingServiceInfo?.quickbooksBillId
      && [ConstFin.FinJob_IntermediateStatus.needPodUpload, 
        ConstFin.FinJob_IntermediateStatus.needPodConfirm,
        ConstFin.FinJob_IntermediateStatus.canceled,
        ConstFin.FinJob_IntermediateStatus.readyToProcess].includes(this.finJob.intermediateStatus as any);
  }

  get quickbooksBillLink() {
    return this.finJob?.accountingServiceInfo?.qbBillLink;
  }

  get quickbooksBillCreatedWhenBy() {
    return this.finJob?.accountingServiceInfo?.createdWhen;
  }

}