import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { ResponseAdminFinBatchDetail, ResponseAdminFinBatchDetailFinJob } from "@wearewarp/types-server-admin/fin";
import { DetailFinStatement } from "../fin-statement/detail";
import { ConstFin } from '@wearewarp/js-const-finance';
import { ListOfStatementBillClientIds } from '../../const';
import { CreateFinStatement } from "../fin-statement/create";
import { FinService } from '../../fin-service';

@Component({
  selector: '[fin-batch]',
  templateUrl: './view.html',
  styleUrls: [ './style.scss' ]
})
export class FinBatch implements OnInit {
  @Input() data: ResponseAdminFinBatchDetail;
  relatedBatches: ResponseAdminFinBatchDetail[] = [];
  listFinJobs: ResponseAdminFinBatchDetailFinJob[] = [];
  listInvoiceFiles: any[] = [];
  isClientStatementBill: boolean = false;

  private batchIdInUrl = '';
  get isAR(): boolean { return this.data?.finType == 'receivable' }
  get isAP(): boolean { return this.data?.finType == 'payable' }
  get shouldShowBatchHyperLink() { return this.batchIdInUrl != this.data.id }
  get isReadyToProcess(): boolean { return this.data?.intermediateStatus === ConstFin.FinJob_IntermediateStatus.readyToProcess }

  constructor(private activatedRoute: ActivatedRoute, private finService: FinService) {
  }

  ngOnInit(): void {
    this.listFinJobs = this.prepareFinJobs();
    this.listInvoiceFiles = this.getListInvoiceFiles();
    this.batchIdInUrl = this.activatedRoute.snapshot.params['id'];
    this.isClientStatementBill = ListOfStatementBillClientIds.includes(this.data?.account?.id);
  }

  getRouterLink() {
    if (!this.shouldShowBatchHyperLink) return undefined;
    if (this.isAP) return [Const.routeAdminFinAP, this.data.id];
    else return [Const.routeAdminFinAR, this.data.id];
  }

  showBatchCode() {
    if (this.isAR) return `AR-${this.data.code}`;
    else if (this.isAP) return `AP-${this.data.code}`;
    else return this.data.code;
  }

  routerLinkBizEntity() {
    if (this.isAP) {
      return [Const.routeAdminDispatchList, this.data.relatedBiz.id];
    } else if (this.isAR) {
      return [Const.routeAdminOrderList, this.data.relatedBiz.id];
    }
  }

  routerLinkAccount() {
    if (this.isAP) {
      return [Const.routeAdminCarrierList, this.data.account.id];
    } else if (this.isAR) {
      return [Const.routeAdminClientList, this.data.account.id];
    }
  }

  private prepareFinJobs() {
    const arr = [];
    for (let item of this.data.finJobs) {
      if (Array.isArray(item.group)) {
        for (let groupMember of item.group) {
          (<any>groupMember).parent = item;
          arr.push(groupMember);
        }
      } else {
        arr.push(item);
      }
    }
    return arr;
  }

  private getListInvoiceFiles() {
    const arr = [];
    for (let item of this.data.finJobs) {
      if (item.invoiceFile) {
        arr.push(item.invoiceFile);
      }
    }
    return arr;
  }

  onBtnViewStatementDetail() {
    if (!this.data.statement?.id) return;
    DialogService.openDialog1(DetailFinStatement, {
      nzComponentParams: {
        id: this.data.statement.id,
      },
      nzClassName: "modal-xl",
    });
  }

  onBtnCreateStatement() {
    if (!this.data || !this.data.id) return;
    DialogService.openFormDialog1(CreateFinStatement, {
      nzComponentParams: {
        closeOnSuccess: true,
        comeFromScreen: 'receivableFinance',
        model: {
          batch: { id : this.data.id, code: this.data.code },
          finAccount: { id: this.data.account.id, name: this.data.account.name },
          relatedBiz: { id: this.data.relatedBiz.id, code: this.data.relatedBiz.code },
          invoiceFiles: this.listInvoiceFiles ?? [],
          cost: this.data.amt
        },
        onRefresh: () => {
          this.finService.onRefreshAPBatchDetail.emit();
          this.finService.onRefreshARBatchDetail.emit();
        }
      },
      nzClassName: "modal-lg",
    });
  }

}