
import { Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import { FormInputUploadImages } from "@app/admin/base/form-input-upload-images/comp";
import { BaseFormDrawer } from "@app/drawers/base-form";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import { Const as WarpConst } from "@wearewarp/universal-libs";

@Component({
  selector: '[notes-form]',
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class NotesForm extends BaseFormDrawer implements OnInit {

  @ViewChild('uploadImagesForm', { static: false }) uploadImagesForm: FormInputUploadImages;

  private _locationId: string;

  @Input() get locationId() {
    return this._locationId
  };
  set locationId(value) {
    this._locationId = value;
    if (value) {
      this.fetchData();
    }
  }
  @Input() visible = true;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Input() showLabel = true;
  newNoteMsg: string;
  uploadImages = [];
  edittingId: number = -1;
  items: any = {};
  pinnedItems = [];
  isLoading: boolean = true;
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onDataChange() {
    this.fetchData();
  }

  formatData(data) {
    let items = {};
    let pinnedItems = [];
    for (let note of data) {
      if (Utils.isArrayNotEmpty(note?.imageUploadFilesArr)) {
        for (let item of note.imageUploadFilesArr) {
          item.imgUrl = this.attachedFileUrl(item);
        }
      }
      let date = note.insert?.when;

      if (note.pinned?.when && note.pinned?.by) {
        pinnedItems.push(note);
        note['time'] = DateUtil.dateToString(date, "MM/DD/YY hh:mm A");
        continue;
        //nếu note được pined thì đẩy lên đầu và không hiển thị lại ở phía dưới.
      }

      note['time'] = DateUtil.dateToString(date, "hh:mm A")
      //group note by day
      if (DateUtil.isToday(date)) date = "Today";
      else if (DateUtil.isYesterday(date)) date = "Yesterday";
      else date = DateUtil.dateToString(date, Const.FORMAT_GUI_DATE);


      if (!items[date]) items[date] = [];
      items[date].push(note);
    }
    this.pinnedItems = pinnedItems.sort(function (a, b) {
      let aDate = new Date(a.pinned.when);
      let bDate = new Date(b.pinned.when);
      return aDate.getTime() < bDate.getTime() ? 1 : (aDate.getTime() > bDate.getTime() ? -1 : 0)
    })
    return items;
  }

  private getUrlConvensation(subjectId: string) {
    return `${Const.APIURI_CONVERSATIONS}/?subjectId=${subjectId}&subjectType=${WarpConst.ConversationSubjectType.deliveryInfo}&type=${WarpConst.ConversationType.book_appointment}`
  }

  fetchData() {
    this.startProgress();
    this.isLoading = true;
    this.items = {};
    this.api.GET(this.getUrlConvensation(this.locationId)).subscribe(
      (resp) => {
        const listData = resp?.data?.list_data || [];
        this.items = this.formatData(listData);
        this.stopProgress();
        this.isLoading = false;
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.isLoading = false;
      }
    );
  }

  close = () => {
    this.newNoteMsg = "";
    this.visibleChange.emit(false);
  };

  onAdd = () => {
    if (!this.newNoteMsg && this.uploadImages.length == 0) return;
    //call API for add new note
    this.startProgress();
    this.isLoading = true;
    let formData = new FormData();
    const jsonData = {
      'content': this.newNoteMsg,
      'countImages': this.uploadImages.length
    }
    formData.append("params", JSON.stringify(jsonData));
    let count = 0;
    for (let image of this.uploadImages) {
      formData.append(`uploadNoteImages.${count}`, image.file, image.fileName);
      count++;
    }
    this.api
    .postFormData(this.getUrlConvensation(this.locationId), formData)
    .subscribe(
        (resp) => {
          this.newNoteMsg = "";
          this.uploadImages = [];
          this.stopProgress();
          this.isLoading = false;
          this.fetchData();
          this.uploadImagesForm.resetFormInput();
        },
        (err) => {
          this.showErr(err);
          this.newNoteMsg = "";
          this.uploadImages = [];
          this.stopProgress();
          this.isLoading = false;
          this.uploadImagesForm.resetFormInput();
        }
      );
  };

  onEdit = (item) => {
    this.newNoteMsg = item.content;
    this.edittingId = item.id;
  };

  onSubmitEdit = () => {
    this.startProgress();
    this.isLoading = true;
    this.api.PUT(`${Const.APIURI_CONVERSATIONS}/${this.edittingId}`, {content: this.newNoteMsg, subjectId: this.locationId})
      .subscribe(
        (resp) => {
          this.isLoading = false;
          this.showSuccess("Your Note has been edited successfully.");
          this.onCancelEdit();
          this.fetchData();
          this.stopProgress();
        },
        (err) => {
          this.showErr(err);
          this.onCancelEdit();
          this.stopProgress();
          this.isLoading = false;
        }
      );
  };

  onCancelEdit = () => {
    this.edittingId = -1;
    this.newNoteMsg = "";
  };

  onBtnPin(item) {
    this.startProgress();
    this.isLoading = true;
    this.api
      .PUT(
        `${Const.APIURI_CONVERSATIONS}/${item.id}/pin?subjectId=${this.locationId}&subjectType=${WarpConst.ConversationSubjectType.deliveryInfo}&type=${WarpConst.ConversationType.book_appointment}`,
        { pin: true }
      )
      .subscribe(
        (resp) => {
          this.isLoading = false;
          this.showSuccess("Your Note has been pinned successfully.");
          this.fetchData();
          this.stopProgress();
        },
        (err) => {
          this.showErr(err);
          this.stopProgress();
          this.isLoading = false;
        }
      );
  }

  onBtnUnPin(item) {
    this.confirmDeletion({
      message: "Do you want to unpin this note?",
      txtBtnOk: 'Yes',
      txtBtnCancel: 'No',
      fnOk: () => {
        this.startProgress();
        this.isLoading = true;
        this.api
          .PUT(
            `${Const.APIURI_CONVERSATIONS}/${item.id}/pin?subjectId=${this.locationId}&subjectType=${WarpConst.ConversationSubjectType.deliveryInfo}&type=${WarpConst.ConversationType.book_appointment}`,
            { pin: false }
          )
          .subscribe(
            (resp) => {
              this.isLoading = false;
              this.showSuccess("Your Note has been un-pinned successfully.");
              this.fetchData();
              this.stopProgress();
            },
            (err) => {
              this.showErr(err);
              this.stopProgress();
              this.isLoading = false;
            }
          );
      },
    });
  }

  getDateItems() {
    return Object.keys(this.items);
  }

  onFileImageSelectedChange(files) {
    this.uploadImages = files;
  }

  viewImageItem(imgUrl) {
    if (!imgUrl) return;
    DialogService.previewImgs([imgUrl], 0);
  }

  get shouldShowBtnAddNote() {
    if (this.isLoading) return false;
    if (!this.newNoteMsg && this.uploadImages.length == 0) return false;
    return true;
  }

  get shouldShowBtnCancel() {
    if (this.isLoading) return false;
    if (this.edittingId != -1 || this.newNoteMsg || this.uploadImages.length > 0) return true;
    return false;
  }

  getLabelBtnAdd() {
    if (this.edittingId != -1) return 'Update';
    return 'Add';
  }

  public getCarrierName(carrier): string {
    let name = super.getCarrierName(carrier);
    if (name) name += ' (Carrier)';
    return name;
  }

  public isExfreight(note) {
    return note?.insert?.byCarrierId == Const.CarrierId_Exfreight;
  }

  onCloseClick() {
    this.onClose();
  }
}
