import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { InputHelper } from "@services/input-helper";
import { BizUtil } from "@services/biz";
import { FormDataEditCost } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { BaseComponent } from "@abstract/BaseComponent";
import { ModelOrderDetail, ModelRevenueDetail, ModelShipmentCostDetail } from "@app/interfaces/order";
import { DialogService } from "@dialogs/dialog.service";
import { EditShipmentEntryRevenue } from "../../edit-shipment/revenue";
import { FuelCostType, VolumeDiscountType } from "@app/enum";
import { AdditionalInvoiceDlg } from "../../edit-shipment/additional-invoice";
import { buildShipmentCostMissingInfo } from "@wearewarp/js-const-finance";

@Component({
  selector: 'shipment-entry-revenue-info-v2',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class ShipmentEntryRevenueInfoV2 extends BaseComponent {
  private _model: ModelOrderDetail;
  @Input() get model(): ModelOrderDetail {
    return this._model;
  }
  set model(value: ModelOrderDetail) {
    this._model = value;
    this.extractModelOrder(value);
  }
  @Input() isTurnOnEditMode = Const.defaultTurnOnEditMode;
  @Output() refreshDetailOrder: EventEmitter<any> = new EventEmitter<any>();
  currencies = Object.values(Const.CurrencyConfig);
  listInfo = [];
  listClients = [];
  revenueInfos: ModelRevenueDetail[] = [];
  isDoNotInvoice = false;
  isShowPopoverReason: boolean = false;
  reasonDoNotInvoice: string = "";
  settingHistories: any[] = [];
  allocations: any[] = []
  additionalCosts: any[] = [];
  invoice: any;
  invoiceSent: any;

  ngOnInit(): void {
    this.fetchClientListData();
  }

  extractModelOrder(modelOrder: ModelOrderDetail) {
    if (!modelOrder) return;
    this.revenueInfos = [];
    this.isDoNotInvoice = modelOrder.settings?.isDoNotInvoice;
    this.reasonDoNotInvoice = modelOrder.settings?.reasonDoNotInvoice;
    this.settingHistories = modelOrder['settingHistories'] || [];
    this.additionalCosts = modelOrder.additionalCosts || [];
    if (modelOrder.invoice) {
      this.invoice = modelOrder.invoice;
    } else if (modelOrder.invoiceFileId) {
      this.invoice = { _id: modelOrder.invoiceFileId, type: "application/pdf" };
    }
    this.invoiceSent = modelOrder.invoiceSent;
    switch(<string>modelOrder.shipmentEntryMode) {
      case Const.ShipmentEntryMode.single: {
        const shipment = modelOrder.metadata?.shipments?.[0];
        if (shipment) {
          let cost: ModelShipmentCostDetail = { ...modelOrder.cost };
          let isUseCostOrder: boolean = true;
          if (modelOrder.settings?.usingCostLevel) {
            isUseCostOrder = modelOrder.settings.usingCostLevel === WarpConst.CostLevel.order;
          } else if (modelOrder.shipmentType != Const.ShipmentTypes.fullTruckLoad) {
            // dùng khi bị thiếu settings
            isUseCostOrder = false;
          }
          if (!isUseCostOrder) {
            cost = { ...(shipment.cost || {}) };
          }
          if (cost?.currency?.type && cost?.currency.type != 'USD') {
            cost = BizUtil.convertCostFromUSDToOrther({...cost});
          }
          cost = buildShipmentCostMissingInfo(cost);
          cost.symbol = '';
          for (let currency of this.currencies) {
            if (cost.currency?.type == currency.value) cost.symbol = currency.prefix;
          }
          const deliveryInfos = shipment.deliveryInfos || [];
          const pickInfo = deliveryInfos.filter(item => item.type == Const.TaskType.PICKUP)[0];
          const dropInfo = deliveryInfos.filter(item => item.type == Const.TaskType.DROPOFF)[0];
          const selectLocations = [
            { deliveryId: pickInfo?.id, type: Const.TaskType.PICKUP, label: 'Pickup Location 1'},
            { deliveryId: dropInfo?.id, type: Const.TaskType.DROPOFF, label: 'Delivery Location 1'},
          ]
          this.revenueInfos.push({
            clientId: shipment.clientId,
            shipmentId: shipment.id,
            orderId: modelOrder.id,
            selectLocations,
            shipments : [],
            shipmentEntryMode: modelOrder.shipmentEntryMode,
            createShipmentMode: Const.ShipmentEntryMode.single,
            type: isUseCostOrder ? Const.CostLevel.order : Const.CostLevel.shipment,
            costOrder: isUseCostOrder ? cost : {},
            costShipments: [ {
              shipmentId: shipment.id, 
              shipmentWarpId: shipment.warpId, 
              cost: isUseCostOrder ? {} : cost, 
              shipmentCode: this.showShipmentCode(shipment)} ],
            isUseCostOrder,
          })
        }
        break;
      }
      case Const.ShipmentEntryMode.multiDrop:
      case Const.ShipmentEntryMode.multiPick: {
        let cost: ModelShipmentCostDetail = { ...modelOrder.cost };
        if (cost?.currency?.type && cost?.currency.type != 'USD') {
          cost = BizUtil.convertCostFromUSDToOrther({...cost});
        }
        cost = buildShipmentCostMissingInfo(cost);
        cost.symbol = '';
        for (let currency of this.currencies) {
          if (cost.currency?.type == currency.value) cost.symbol = currency.prefix;
        }
        const clientId = modelOrder.clientIds?.[0];
        let selectLocations = [];
        const shipments = modelOrder.metadata?.shipments || [];
        let pickInfos = [];
        let dropInfos = [];
        for (let shipment of shipments) {
          for (let info of shipment.deliveryInfos || []) {
            if (info.type == Const.TaskType.PICKUP) pickInfos.push(info);
            if (info.type == Const.TaskType.DROPOFF) dropInfos.push(info);
          }
        }
        if (modelOrder.shipmentEntryMode == Const.ShipmentEntryMode.multiDrop) {
          // Vì server vẫn đang giữ là dummy_id nên ở đây hardcode là dummy_id
          selectLocations.push({deliveryId: 'dummy_id', type: Const.TaskType.PICKUP, label: `Pickup Location 1`});
          for (let i=0;i <dropInfos.length; i++) {
            selectLocations.push({deliveryId: dropInfos[i].id, type: Const.TaskType.DROPOFF, label: `Delivery Location ${i+1}`});
          }
        } else if (modelOrder.shipmentEntryMode == Const.ShipmentEntryMode.multiPick) {
          for (let i=0;i <pickInfos.length; i++) {
            selectLocations.push({deliveryId: pickInfos[i].id, type: Const.TaskType.PICKUP, label: `Pickup Location ${i+1}`});
          }
          // Vì server vẫn đang giữ là dummy_id nên ở đây hardcode là dummy_id
          selectLocations.push({deliveryId: 'dummy_id', type: Const.TaskType.DROPOFF, label: `Delivery Location 1`});
        }
        this.revenueInfos.push({
          clientId,
          orderId: modelOrder.id,
          selectLocations,
          shipments: [],
          shipmentEntryMode: modelOrder.shipmentEntryMode,
          createShipmentMode: modelOrder.shipmentEntryMode,
          type: Const.CostLevel.order,
          costOrder: cost,
          costShipments: [],
          isUseCostOrder: true,
        })
        break;
      }
      case 'multiPickDrop_LTL':
      case Const.ShipmentEntryMode.multiPickDrop: {
        // hiện tại mới chỉ support 1 client nên lấy clientId đầu tiên
        const clientId = modelOrder.clientIds?.[0];
        const costOrder = modelOrder.cost;
        const shipments: any[] = modelOrder.metadata?.shipments || [];
        const selectLocations = [
          { deliveryId: "PICKUP_1", type: Const.TaskType.PICKUP, label: 'Pickup Location 1'},
          { deliveryId: "DROPOFF_1", type: Const.TaskType.DROPOFF, label: 'Delivery Location 1'},
        ]
        let newFormatShipments = shipments.map((sh, index) => {
          const deliveryInfos = sh.deliveryInfos || [];
          const pickInfo = deliveryInfos.filter(item => item.type == Const.TaskType.PICKUP)[0];
          const dropInfo = deliveryInfos.filter(item => item.type == Const.TaskType.DROPOFF)[0];
          return { index, id: sh.id, pickInfo, dropInfo }
        });
        let isUseCostOrder: boolean = true;
        if (modelOrder.settings?.usingCostLevel) {
          isUseCostOrder = modelOrder.settings.usingCostLevel === WarpConst.CostLevel.order;
        } else {
          // dùng khi bị thiếu settings
          if (!costOrder) { isUseCostOrder = false; } 
          else {
            for (let sh of shipments) {
              if (sh.cost) isUseCostOrder = false;
            }
          }
        }
        
        if (!isUseCostOrder) {
          // đây là trường hợp revenueType == perShipmentRevenue
          const costShipments = shipments.map(sh => { 
            let costFormat = { ...(sh.cost || {}) };
            if (costFormat?.currency?.type && costFormat?.currency.type != 'USD') {
              costFormat = BizUtil.convertCostFromUSDToOrther(costFormat);
            }
            costFormat = buildShipmentCostMissingInfo(costFormat);
            costFormat.symbol = '';
            for (let currency of this.currencies) {
              if (costFormat.currency?.type == currency.value) costFormat.symbol = currency.prefix;
            }
            return { shipmentId: sh.id, shipmentWarpId: sh.warpId, cost: costFormat, shipmentCode: this.showShipmentCode(sh) }
          });
          this.revenueInfos.push({
            clientId,
            orderId: modelOrder.id,
            selectLocations: selectLocations,
            shipments: newFormatShipments,
            shipmentEntryMode: modelOrder.shipmentEntryMode,
            createShipmentMode: modelOrder.shipmentEntryMode,
            type: Const.CostLevel.shipment,
            costOrder: {},
            costShipments: costShipments,
            isUseCostOrder: false,
          })
        } else {
          // đây là trường hợp revenueType == batchRevenue
          let costOrderFormat: ModelShipmentCostDetail = { ...(costOrder || {}) }
          if (costOrderFormat?.currency?.type && costOrderFormat?.currency.type != 'USD') {
            costOrderFormat = BizUtil.convertCostFromUSDToOrther(costOrderFormat);
          }
          costOrderFormat = buildShipmentCostMissingInfo(costOrderFormat);
          costOrderFormat.symbol = '';
          for (let currency of this.currencies) {
            if (costOrderFormat.currency?.type == currency.value) costOrderFormat.symbol = currency.prefix;
          }
          const costShipments = shipments.map(sh => {
            return { shipmentId: sh.id, shipmentWarpId: sh.warpId, cost: {}, shipmentCode: this.showShipmentCode(sh) }
          });
          this.revenueInfos.push({
            clientId: clientId,
            orderId: modelOrder.id,
            selectLocations: selectLocations,
            shipments: newFormatShipments,
            shipmentEntryMode: modelOrder.shipmentEntryMode,
            createShipmentMode: modelOrder.shipmentEntryMode,
            type: Const.CostLevel.order,
            costOrder: costOrderFormat,
            costShipments: costShipments,
            isUseCostOrder: true,
          })
        }
        break
      }
    }
    if (
      modelOrder.shipmentType == Const.ShipmentTypes.fullTruckLoad ||
      (modelOrder.shipmentType == Const.ShipmentTypes.lessThanTruckload && !modelOrder.isCrossDock) ||
      (modelOrder.shipmentType == Const.ShipmentTypes.lessThanTruckload && !(modelOrder.metadata?.shipments || [])[0]?.legIds?.length)
    )
    this.allocations = modelOrder.metadata?.shipments?.map(it => {
      const allocation = {
        id: this.showShipmentCode(it),
        revenue: it.finance?.revenueAllocation,
        cost: it.finance?.costAllocation
      }
      return (allocation.revenue || allocation.cost) ? allocation : null
    }).filter(it => it)
  }

  public get hasInvoice() {
    let data: any = this.model
    return !!data?.invoiceSent?.when;
  }

  isLoading = false;
  onBtnDoNotInvoiceChange(value) {
    const formId = 'settings';
    let url = Const.APIV2(`${Const.APIURI_ORDERS}/${this.model.id}/${formId}`);
    const data = {
      isDoNotInvoice: value,
      reasonDoNotInvoice: this.reasonDoNotInvoice
    }
    this.isLoading = true;
    this.api.PUT(url, data).subscribe(
      resp => {
        this.isLoading = false;
        this.settingHistories = resp?.data?.settingHistories || [];
        this.isDoNotInvoice = !!resp?.data?.settings?.isDoNotInvoice;
        this.reasonDoNotInvoice = resp?.data?.settings?.reasonDoNotInvoice;
        this.showSuccess(`Update settings successfully.`)
      }, err => {
        this.showErr(err);
        this.isDoNotInvoice = !!this.model.settings?.isDoNotInvoice;
        this.reasonDoNotInvoice = this.model.settings?.reasonDoNotInvoice;
        this.isLoading = false;
      }
    );
  }

  isCheckDisibleEditRevenue(data): boolean {
    return !!data?.invoiceSent?.when;
  }

  onBtnEditRevenue(revenueInfo) {
    const checkDisible = this.isCheckDisibleEditRevenue(this.model);
    if (checkDisible) {
      return this.showErr("The invoice for this order has been sent. Please generate additional invoice."); 
    }
    DialogService.openFormDialog1(EditShipmentEntryRevenue, {
      nzComponentParams: {
        closeOnSuccess: true,
        model: revenueInfo,
        order: this.model,
        hasInvoice: this.hasInvoice,
        shipmentEntryMode: revenueInfo.shipmentEntryMode,
        createShipmentMode: revenueInfo.createShipmentMode,
        selectLocations: revenueInfo.selectLocations,
        shipments: revenueInfo.shipments,
        onSave: (data) => this.saveCost(revenueInfo, data),
        onRefreshDetailOrder: () => this.refreshDetailOrder.emit()
      },
      nzClassName: "modal-xl",
    });
  }

  private saveCost(revenueInfo, data) {
    let costOrderData: FormDataEditCost;
    const revenueType = data.type;
    if (revenueType == Const.CostLevel.order) {
      // lưu vào order
      costOrderData = {
        type: Const.CostLevel.order,
        costOrder: data.costOrder,
        costShipments: [],
      }
    } else if (revenueType == Const.CostLevel.shipment) {
      // lưu vào shipment
      costOrderData = {
        type: Const.CostLevel.shipment,
        costOrder: {},
        costShipments: data.costShipments,
      }
    }
    const url = Const.APIV2(`${Const.APIURI_ORDERS}/${this.model.id}/cost`);
    return this.api.PUT(url, costOrderData);
  }

  private fetchClientListData() {
    let url = `${Const.APIURI_CLIENTS}?limit=-1`;
    this.api.GET(url).subscribe((resp) => {
      this.listClients = resp.data.list_data;
    });
  }

  get hasData(): boolean {
    return Utils.isArrayNotEmpty(this.revenueInfos);
  }

  getCustomerName(clientId) {
    const client = this.listClients.filter(item => item.id == clientId)[0];
    return client?.name || '';
  }
  getShipmentName(item) {
    if (item.shipmentId) return `Shipment ${item.shipmentId}`;
    else return null;
  }

  labelTotal = 'Total';

  shouldShowServiceOptionLabel(item) {
    return Utils.isArrayNotEmpty(item.serviceOptions)
  }

  getTransitRate(item) {
    return InputHelper.formatMoney2(`${item?.transitCost?.rate || 0}`, item?.symbol);
  }
  getTransitTotal(item) {
    return InputHelper.formatMoney2(`${item?.transitCost?.total || 0}`, item?.symbol);
  }
  getVolumeDiscountType(item) {
    switch (item.volumeDiscount?.type) {
      case VolumeDiscountType.percentage: return '%';
      case VolumeDiscountType.flatRate: return 'Flat Rate';
      default: return '';
    }
  }
  getFuelCostType(item) {
    switch (item.fuelCost?.type) {
      case FuelCostType.ratePerMile: return 'RPM';
      case FuelCostType.percentage: return '%';
      default: return '';
    }
  }
  getSubTotal(item) {
    return InputHelper.formatMoney2(`${item?.subTotal || 0}`, item?.symbol);
  }
  getDiscount(item) {
    return InputHelper.formatMoney2(`${item?.volumeDiscount?.total || 0}`, item?.symbol);
  }
  getFuelRate(item) {
    return InputHelper.formatMoney2(`${item?.fuelCost?.rpm || 0}`, item?.symbol);
  }
  getFuelRateTotal(item) {
    return InputHelper.formatMoney2(`${item?.fuelCost?.total || 0}`, item?.symbol);
  }
  getServiceRate(cost, serviceOptionItem) {
    return InputHelper.formatMoney2(`${serviceOptionItem.rate}`, cost.symbol);
  }
  getServiceOptionMoneyValue(cost, serviceOptionItem) {
    let text = InputHelper.formatMoney2(`${serviceOptionItem.total}`, cost.symbol);
    if (MasterData.isServiceOptionTypeNegative(serviceOptionItem._id)) {
      return `-${text}`;
    }
    return text;
  }
  getRevenueText(item) {
    return InputHelper.formatMoney2(`${this.getRevenue(item)}`, item?.symbol);
  }
  getRevenue(item) {
    return item?.grandTotal || 0;
  }
  getUsdConversion(item) {
    return item?.usdConversion || 0;
  }
  getUsdConversionText(item) {
    return InputHelper.formatMoney2(`${this.getUsdConversion(item)}`);
  }

  onBtnGenerateAdditionalInvoice(revenueInfo) {
    const checkDisible = this.isCheckDisibleEditRevenue(this.model);
    if (!checkDisible) {
      return;
    }
    DialogService.openFormDialog1(AdditionalInvoiceDlg, {
      nzComponentParams: {
        closeOnSuccess: true,
        createShipmentMode: revenueInfo.createShipmentMode,
        selectLocations: revenueInfo.selectLocations,
        onSave: (data) => this.generateAdditionalInvoice(data),
        onRefreshDetailOrder: () => this.refreshDetailOrder.emit()
      },
      nzClassName: "modal-xl",
    });
  }

  private generateAdditionalInvoice(data) {
    const url = Const.APIV2(`${Const.APIURI_ORDERS}/invoice/${this.model.id}/additional`);
    return this.api.POST(url, data);
  }

  downloadInvoice(additionalCost) {
    return this.downloadAttachedFile(additionalCost.invoice);
  }

  onBtnEditAdditionalInvoice(revenueInfo, additionalCost) {
    DialogService.openFormDialog1(AdditionalInvoiceDlg, {
      nzComponentParams: {
        closeOnSuccess: true,
        model: { cost: additionalCost.cost },
        createShipmentMode: revenueInfo.shipmentEntryMode,
        selectLocations: revenueInfo.selectLocations,
        txtHeader: 'Edit Additional Invoice',
        onSave: (data) => this.editAdditionalInvoice({orderId: this.model.id, additionalCostId: additionalCost.id, data}),
        onRefreshDetailOrder: () => this.refreshDetailOrder.emit()
      },
      nzClassName: "modal-xl",
    });
  }

  private editAdditionalInvoice({orderId, additionalCostId, data}) {
    const url = Const.APIV2(`${Const.APIURI_ORDERS}/invoice/${orderId}/additional`);
    return this.api.PUT(url, {additionalCostId, ...data});
  }

  onOpenPopoverReason(isDoNotInvoice) {
    this.isDoNotInvoice = isDoNotInvoice;
    if (this.isDoNotInvoice) this.isShowPopoverReason = true;
    else {
      this.reasonDoNotInvoice = null;
      this.onSavePopoverReason();
    }
  }

  onSavePopoverReason() {
    this.isShowPopoverReason = false;
    this.onBtnDoNotInvoiceChange(this.isDoNotInvoice);
  }

  onClosePopoverReason() {
    this.isDoNotInvoice = false;
    this.isShowPopoverReason = false;
  }

  displayReason() {
    if (this.reasonDoNotInvoice) return `(${this.reasonDoNotInvoice})`;
    else return "";
  }

  getDoNotInvoiceHistory(){
    const history = this.settingHistories[this.settingHistories.length - 1];
    if(history?.settings?.isDoNotInvoice) return history;
  }

}
