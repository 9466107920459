import { Component } from "@angular/core";
import { ExtendValidators } from "@app/admin/base/validator";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { DialogService } from "@dialogs/dialog.service";
import { FormDataShipmentItemBarcodes } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { Observable } from "rxjs";

const key = 'barcodes';

@Component({
  selector: 'form-item-barcodes',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss', 
  ]
})
export class FormItemBarcodes extends BaseFormDialog1<FormDataShipmentItemBarcodes> {

  public static open(input: {model?: FormDataShipmentItemBarcodes, fnSave: (data: FormDataShipmentItemBarcodes) => Observable<any>}) {
    DialogService.openFormDialog1(FormItemBarcodes, {
      nzComponentParams: {
        model: input.model,
        onSave: input.fnSave
      },
      nzClassName: "modal",
    });
  }

  public onSave: (data: FormDataShipmentItemBarcodes) => Observable<any>;

  headerText = 'Edit Barcodes';

  protected formGroupDeclaration: FormGroupDeclaration = {
    barcodes: {label: 'Barcodes (can be multiple)', type: 'formArray', childItem: {
      label: '', notAcceptEmpty: true, validators: ExtendValidators.validateTextNotEmpty
    }, initialValue: []},
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  get countBarcode() {
    return this.getFormArrayLength(key);
  }

  onBtnAddFormArray() {
    this.addItemToFormArray(key);
  }

  onBtnRemoveFormArray(index: number) {
    let itemValue = this.getItemValue(`${key}[${index}]`);
    let message = itemValue ? `Remove barcode <b>${itemValue}</b>?` : `Remove barcode at position <b>${index + 1}</b>?`;
    this.confirmDeletion({
      message: message,
      txtBtnOk: 'Remove',
      fnOk: () => this.removeItemInFormArray(key, index)
    });
  }

  onBtnSave() {
    if (!this.needUpdate) {
      return;
    }
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

}
