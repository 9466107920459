import { Injectable } from "@angular/core";
import { SocketEvent } from "@app/enum";
import { SocketService } from "@app/socket";
import { EventSocket_CarrierBidCandidateFollow, EventSocket_CarrierBidFollow } from "@wearewarp/types/event";
import { Subject, Subscription } from "rxjs";
import { BidEventData } from "../interface";

@Injectable({providedIn: 'root'})
export class CarrierBidEvent {
  private candidateIds = new Set<string>();
  private subjectForCandidate = new Subject<BidEventData>();

  private bidIds = new Set<string>();
  private subjectForBid = new Subject<BidEventData>();

  constructor(private socket: SocketService) {
    this.socket.subscribeConnectionStatus(isConnected => {
      if (isConnected) {
        this.onSocketConnected();
      }
    })
  }

  private onSocketConnected() {
    for (let id of this.candidateIds) {
      this.followBidCandidate(id);
    }
    for (let id of this.bidIds) {
      this.followBid(id);
    }
  }

  private followBidCandidate(id: string) {
    this.socket.emit<EventSocket_CarrierBidCandidateFollow>(SocketEvent.carrierBidCandidateFollow, {data: {candidateId: id}});
  }

  private followBid(id: string) {
    this.socket.emit<EventSocket_CarrierBidFollow>(SocketEvent.carrierBidFollow, {data: {bidId: id}});
  }

  subscribeBidCandidate(bidCandidateId: string, next: (data: BidEventData) => void): Subscription {
    this.candidateIds.add(bidCandidateId);
    const sub = new Subscription();
    for (let event of [
      SocketEvent.carrierBidCandidateReply,
      SocketEvent.carrierBidCandidateCounter,
      SocketEvent.carrierBidCandidateBidDetailStart,
      SocketEvent.carrierBidCandidateBidDetailStop,
      SocketEvent.carrierBidCandidateTypingPriceStart,
      SocketEvent.carrierBidCandidateTypingPriceStop,
    ]) {
      sub.add(this.socket.subscribeEvent(event, data => this.subjectForCandidate.next({event: event, payload: data})));
    }
    this.followBidCandidate(bidCandidateId);
    sub.add(this.subjectForCandidate.subscribe(next));
    return sub;
  }

  subscribeBid(bidId: string, next: (data: BidEventData) => void): Subscription {
    this.bidIds.add(bidId);
    const sub = new Subscription();
    for (let event of [
      SocketEvent.carrierBidReply
    ]) {
      sub.add(this.socket.subscribeEvent(event, data => this.subjectForBid.next({event: event, payload: data})));
    }
    this.followBid(bidId);
    sub.add(this.subjectForBid.subscribe(next));
    return sub;
  }
}