import { Component, Input, ViewChild, ElementRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DualAxes } from '@antv/g2plot';
import { StopItem, ChartDataItem } from '../../models/types';
import { InputHelper } from '@services/input-helper';

interface TableDataItem {
    stopNumber: number;
    type: string;
    prevUnits: number;
    prevWeight: number;
    currentUnits: number;
    weight: number;
    originUnits: number;
    originWeight: number;
    qtyUnitPlural: string;
    actionText: string;
}

@Component({
    selector: 'network-chart',
    templateUrl: './index.html',
    styleUrls: ['./style.scss']
})
export class NetworkChartComponent implements OnDestroy, OnChanges {
    @ViewChild('chartContainer') chartContainer: ElementRef;
    @Input() stops: StopItem[] = [];
    @Input() maxWeight: number = 0;
    @Input() maxQuantity: number = 0;

    tableData: TableDataItem[] = [];
    Math = Math; // Để sử dụng Math trong template

    private chart: DualAxes;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.stops && !changes.stops.firstChange) {
            this.initChart();
            this.prepareTableData();
        }
    }

    ngAfterViewInit() {
        if (this.stops.length) {
            this.initChart();
            this.prepareTableData();
        }
    }

    ngOnDestroy() {
        if (this.chart) {
            this.chart.destroy();
        }
    }

    formatNumber(item: number) {
        return InputHelper._formatMoney(item, 0).replace(/,/g, '.');
    }

    private calculateNiceMaxValue(value: number): number {
        // Nếu giá trị nhỏ hơn hoặc bằng 10, làm tròn lên 12
        if (value <= 10) return 12;

        // Nếu giá trị lớn hơn 10, làm tròn lên đến số chia hết cho 2 gần nhất và cộng thêm 2
        return Math.ceil(value / 2) * 2 + 2;
    }

    private initChart() {
        if (!this.chartContainer || !this.stops.length) {
            console.log('Không thể khởi tạo chart:', {
                hasContainer: !!this.chartContainer,
                stopsLength: this.stops?.length
            });
            return;
        }

        const baseData = this.prepareChartData();
        console.log('Chart data:', baseData);

        // Xóa chart cũ nếu có
        if (this.chart) {
            this.chart.destroy();
        }

        // Tính toán giá trị max đẹp cho cả weight và quantity
        const maxWeightInData = Math.max(...baseData.map(d => d.weight));
        const maxQuantityInData = Math.max(...baseData.map(d => d.quantity));
        const niceMaxWeight = this.calculateNiceMaxValue(maxWeightInData);
        const niceMaxQuantity = this.calculateNiceMaxValue(maxQuantityInData);

        try {
            this.chart = new DualAxes(this.chartContainer.nativeElement, {
                data: [baseData, baseData],
                xField: 'stop',
                yField: ['quantity', 'weight'],
                geometryOptions: [
                    {
                        geometry: 'line',
                        color: '#52c41a',
                        seriesField: undefined,
                        smooth: true,
                        point: {
                            size: 5,
                            shape: 'circle',
                            style: {
                                fill: '#52c41a',
                                stroke: '#52c41a',
                                lineWidth: 2,
                                opacity: 0.7
                            }
                        }
                    },
                    {
                        geometry: 'line',
                        color: '#1890ff',
                        seriesField: undefined,
                        smooth: true,

                        point: {
                            size: 5,
                            shape: 'circle',
                            style: {
                                fill: '#1890ff',
                                stroke: '#1890ff',
                                lineWidth: 2,
                                opacity: 0.7
                            }
                        }
                    },
                ],
                yAxis: {
                    weight: {
                        title: {
                            text: 'Weight (lbs)'
                        },
                        // type: 'log',
                        max: niceMaxWeight,
                        nice: true,
                        base: 10,
                        // minLimit: 1, // Để tránh giá trị 0 hoặc âm
                        label: {
                            formatter: (val) => {
                                return this.formatNumber(Number(val));
                            }
                        }
                    },
                    quantity: {
                        title: {
                            text: baseData[0].qtyUnit + 's'
                        },
                        max: niceMaxQuantity,
                        nice: true
                    }
                },
                tooltip: this.getTooltipConfig(),
                annotations: this.getAnnotationsConfig(baseData),
                animation: {
                    appear: {
                        animation: 'wave-in',
                        duration: 1000
                    }
                },
                legend: {
                    position: 'top',
                    itemName: {
                        formatter: (text) => {
                            if (text === 'weight') return 'Weight';
                            if (text === 'quantity') return 'Quantity';
                            return text;
                        }
                    }
                }
            });

            console.log('Rendering chart...');
            this.chart.render();
            console.log('Chart rendered successfully');
        } catch (error) {
            console.error('Error initializing/rendering chart:', error);
        }
    }

    private prepareChartData(): ChartDataItem[] {
        // Lấy qtyUnit từ stop đầu tiên (nếu có)
        const firstUnit = this.stops[0]?.units[0];
        const qtyUnit = firstUnit?.qtyUnit || 'Unit';

        return this.stops.map((stop, index) => {
            const prevWeight = index > 0 ? this.stops[index - 1].weight : 0;
            const weightChange = stop.weight - prevWeight;

            // Tổng số units
            const currentUnits = stop.units.reduce((sum, unit) => sum + unit.qty, 0);
            const prevStop = index > 0 ? this.stops[index - 1] : null;
            const prevUnits = prevStop?.units.reduce((sum, unit) => sum + unit.qty, 0) || 0;
            const unitsChange = currentUnits - prevUnits;

            return {
                stop: `Stop ${index + 1} (${stop.type})`,
                weight: stop.weight,
                quantity: currentUnits,
                weightChange,
                unitsChange,
                type: stop.type,
                qtyUnit
            };
        });
    }

    private prepareTableData() {
        this.tableData = this.stops.map((stop, index) => {
            const prevStop = index > 0 ? this.stops[index - 1] : null;
            const currentUnits = stop.units.reduce((sum, unit) => sum + unit.qty, 0);
            const originUnits = stop.originUnits.reduce((sum, unit) => sum + unit.qty, 0);
            const prevUnits = prevStop?.units.reduce((sum, unit) => sum + unit.qty, 0) || 0;
            const qtyUnit = stop.units[0]?.qtyUnit || 'Unit';

            return {
                stopNumber: index + 1,
                type: stop.type,
                prevUnits,
                prevWeight: prevStop?.weight || 0,
                currentUnits,
                weight: stop.weight,
                originUnits,
                originWeight: stop.originWeight,
                qtyUnitPlural: qtyUnit + 's',
                actionText: stop.type === 'PU' ? 'Loading' : 'Unloading'
            };
        });
    }

    private getTooltipConfig() {
        return {
            shared: true,
            showMarkers: true,
            showTitle: true,
            customContent: (title: string, data: any[]) => {
                if (!data?.length) return '';

                const stopNumber = parseInt(title.match(/Stop (\d+)/)?.[1] || '0') - 1;
                if (stopNumber < 0 || stopNumber >= this.stops.length) return '';

                const stop = this.stops[stopNumber];
                const prevStop = stopNumber > 0 ? this.stops[stopNumber - 1] : null;

                let html = `<div style="padding: 12px;">`;
                html += `<div style="font-weight: bold; margin-bottom: 8px;"> Stop ${stopNumber + 1} (${stop.type}):</div>`;

                // Tính tổng units
                const currentUnits = stop.units.reduce((sum, unit) => sum + unit.qty, 0);
                const originUnits = stop.originUnits.reduce((sum, unit) => sum + unit.qty, 0);
                const prevUnits = prevStop?.units.reduce((sum, unit) => sum + unit.qty, 0) || 0;

                // Lấy tên unit từ stop đầu tiên
                const qtyUnit = stop.units[0]?.qtyUnit || 'Unit';
                const qtyUnitPlural = qtyUnit + 's';

                if (prevStop) {
                    html += `<div style="margin-bottom: 4px;"><b>Upon Arrival:</b> ${prevUnits.toFixed(0)} ${qtyUnitPlural}, ${this.formatNumber(prevStop.weight)} Lbs</div>`;
                }

                const actionText = stop.type === 'PU' ? 'Loading' :
                    stop.type === 'DO' ? 'Unloading' : 'Unloading';
                html += `<div style="margin-bottom: 4px; color: ${stop.type === 'PU' ? '#52c41a' : '#f5222d'}"><b>${actionText}:</b> ${Math.abs(originUnits).toFixed(0)} ${qtyUnitPlural}, ${this.formatNumber(Math.abs(stop.originWeight))} Lbs</div>`;

                html += `<div><b>Upon Departure:</b> ${currentUnits.toFixed(0)} ${qtyUnitPlural}, ${this.formatNumber(stop.weight)} Lbs</div>`;

                html += `</div>`;
                return html;
            },
            domStyles: {
                'g2-tooltip': {
                    padding: 0,
                    background: 'rgba(255, 255, 255, 0.95)',
                    boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
                    border: '1px solid #ddd'
                }
            }
        };
    }

    private getAnnotationsConfig(baseData: ChartDataItem[]) {
        const annotationWeight = [];
        const annotationQuantity = [];

        // Tạo annotations cho weight
        baseData
            .filter(d => d.unitsChange !== 0)
            .forEach(d => {
                annotationQuantity.push({
                    type: 'dataMarker',
                    offsetY: d.unitsChange > 0 ? -20 : 30,
                    offsetX: 10,
                    position: [d.stop, d.quantity],
                    text: {
                        content: d.unitsChange > 0 ? '\u2B06' : '\u2B07',
                        style: {
                            textAlign: 'center',
                            fontSize: 18,
                            fontWeight: 'bold',
                            fontFamily: 'Arial, sans-serif',
                            fill: d.unitsChange > 0 ? '#52c41a' : '#f5222d',
                        }
                    },
                    point: {
                        style: {
                            fill: 'transparent',
                            stroke: 'transparent'
                        }
                    },
                    line: {
                        length: 0
                    }
                });
            });

        // Thêm line maxWeight
        if (this.maxWeight > 0) {
            annotationWeight.push({
                type: 'line',
                start: ['min', this.maxWeight],
                end: ['max', this.maxWeight],
                style: {
                    stroke: '#ff4d4f',
                    lineDash: [4, 4],
                    lineWidth: 1,
                },
                text: {
                    content: `Max Weight: ${this.formatNumber(this.maxWeight)} lbs`,
                    position: 'end',
                    style: {
                        fill: '#ff4d4f',
                        fontSize: 12,
                        fontWeight: 'normal',
                    },
                },
            });
        }

        // Thêm line maxQuantity
        if (this.maxQuantity > 0) {
            annotationQuantity.push({
                type: 'line',
                start: ['min', this.maxQuantity],
                end: ['max', this.maxQuantity],
                style: {
                    stroke: '#ff4d4f',
                    lineDash: [4, 4],
                    lineWidth: 1,
                },
                text: {
                    content: `Max Capacity: ${this.maxQuantity} pallets`,
                    position: 'start',
                    style: {
                        fill: '#ff4d4f',
                        fontSize: 12,
                        fontWeight: 'normal',
                    },
                },
            });
        }

        return [annotationQuantity, annotationWeight];
    }
} 