import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { FormInputCostV3 } from "../../forms/input-cost-v3";
import { MasterData } from "@services/master.data";
import { Observable } from "rxjs";
import { BizUtil } from "@services/biz";
import { FormDataShipmentCost } from "@wearewarp/types-server-admin/form-data/shipment-entry";

interface FormDataEditAdditionalInvoice {
  cost: FormDataShipmentCost,
}

@Component({
  selector: 'additional-invoice',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class AdditionalInvoiceDlg extends BaseFormDialog1<FormDataEditAdditionalInvoice> {

  @Input() txtHeader: string = 'Generate Additional Invoice';
  @Input() selectLocations;
  @Input() createShipmentMode = Const.ShipmentEntryMode.single;
  @Input() onSave: (data: FormDataEditAdditionalInvoice) => Observable<any>;
  @Input() onRefreshDetailOrder: () => void;

  protected formGroupDeclaration: FormGroupDeclaration = {
    cost: {label: '', type: 'formGroup', childItem: FormInputCostV3.declaration},
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  serviceOptionsForCostSection = [
    {label: 'Additional services', items: MasterData.ShipmentServiceOptionsAddition},
    {label: 'Pickup services', items: MasterData.ShipmentServiceOptionsPickup},
    {label: 'Delivery services', items: MasterData.ShipmentServiceOptionsDelivery},
  ];

  onBtnSave() {
    if (!this.needUpdate) return;
    this.onSaveData();
  }

  onSaveData() {
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.showSuccess(resp);
        this.closeDialog();
        this.onProgress = false;
        this.onRefreshDetailOrder();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

  protected getFormData_JSON(isCreateNew: boolean): FormDataEditAdditionalInvoice {
    let data = super.getFormData_JSON(isCreateNew);

    // chuyển tất cả rate về chuẩn USD
    if (data?.cost && data?.cost?.currency?.type &&  data?.cost?.currency?.type != Const.CurrencyConfig.USD.value) {
      data.cost = BizUtil.convertCostToUSD(data.cost);
    }

    return data;
  }
    
}
