<div class="add-route" nz-form [formGroup]="formInput">
  <div class="title">
    Add Route
  </div>

  <div class="g-form">
    <div class="search-box">
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input 
          type="text" nz-input 
          [formControlName]="'searchKeyword'"
          (ngModelChange)="onChange($event, 'searchKeyword')"
          [placeholder]="getPlaceHolder('searchKeyword')" 
        />
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search"></span>
      </ng-template>
    </div>

    <div class="g-filter" nz-row nzGutter="16">
      <div class="g-item" nz-col nzSpan="8">
        <div class="form-label-v2">
          Customer
          <span *ngIf="isRequired('clientIds')" class="label-mark-required"></span>
        </div>
        <div>
          <select-by-searching
            [version]="2"
            type="client"
            [nzMode]="'multiple'"
            [getAllAtOnce]="false"
            [formControlName]="'clientIds'"
            [(listData)]="listClients"
            [placeholder]="getPlaceHolder('clientIds')"
            (ngModelChange)="onChange($event, 'clientId')"
          >
          </select-by-searching>
        </div>
      </div>

      <div class="g-item" nz-col nzSpan="8">
        <div class="form-label-v2">
          Market
          <span *ngIf="isRequired('markets')" class="label-mark-required"></span>
        </div>
        <div>
          <nz-select
            nzBackdrop="true"
            nzAllowClear
            nzShowSearch
            [nzMode]="'multiple'"
            [formControlName]="'markets'"
            [nzLoading]="filterLoading"
            [nzPlaceHolder]="'Select'"
            (ngModelChange)="onChange($event, 'markets')"
            style="width: 100%"
          >
            <nz-option *ngFor="let item of listMarkets" [nzValue]="item" [nzLabel]="item">
            </nz-option>
          </nz-select>

        </div>
      </div>

      <div class="g-item" nz-col nzSpan="8">
        <div class="form-label-v2">
          Equipment
          <span *ngIf="isRequired('vehicleTypes')" class="label-mark-required"></span>
        </div>
        <div>
          <div class="vehicle-selector" 
            vehicle-selector
            [mode]="'multiple'"
            [value]="getVehicleType()"
            (valueChange)="onChangeVehicleType($event)"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div class="g-checkbox" *ngIf="shouldShowCheckbox">
    <div class="form-label-v2">
      <div nz-checkbox formControlName="isAutoAddRoute" (ngModelChange)="onChange($event, 'isAutoAddRoute')">
        Auto-add routes that match these filters in the future
      </div>
    </div>

    <div class="warning" *ngIf="isShowWarning">
      <nz-alert nzType="warning" nzMessage="Market and Equipment are required to turn on auto-add" nzShowIcon></nz-alert>
    </div>
  </div>

  <div class="g-table">
    <nz-table #nzTable 
      [nzData]="routes" 
      [nzTotal]="totalCount"
      [nzFrontPagination]="true" 
      [nzShowPagination]="true" 
      [nzPageSize]="limit" 
      [nzLoading]="isLoading" 
      [nzLoadingIndicator]="tplLoading" 
      [nzNoResult]="tplNoData"
      #expandTable
    >
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead>
        <tr>
          <th>RouteID</th>
          <th>Origin</th>
          <th>Destination</th>
          <th>Equipment</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let data of expandTable.data">
          <tr>
            <td>{{ data.code }}</td>
            <td>
              <b>{{data?.metadata?.firstPickInfo?.locationName}}</b><br />
              {{ getAddress(data?.metadata?.firstPickInfo) }}
              <div class="time">{{ getTime(data?.pickDt) }}</div>
            </td>
            <td>
              <b>{{data?.metadata?.lastDropInfo?.locationName}}</b><br />
              {{ getAddress(data?.metadata?.lastDropInfo) }}
              <div class="time">{{ getTime(data?.dropDt) }}</div>
            </td>
            <td>{{ getVehicleTypeNameText(data) }}</td>
            <td>
              <ng-container *ngIf="isSelected(data)">
                Added
              </ng-container>
              <ng-container *ngIf="!isSelected(data)">
                <a nzType="primary" (click)="onBtnAddRoute(data)">
                  Add
                </a>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</div>