import { Component } from "@angular/core";
import { FormDataShipmentCost } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { BaseFormTemplate } from "../../../../../base/form-base-template";
import { FormInputCostV3 } from "..";
import { MasterData } from "@services/master.data";
import { ServiceOptionType } from "@wearewarp/types/rest-api";
import { InputHelper } from "@services/input-helper";
import { Const } from "@const/Const";

@Component({
  selector: '[form-input-cost-v3-template-default]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class FormInputCostV3TemplateDefault extends BaseFormTemplate<FormDataShipmentCost, FormInputCostV3> {

  get symbol() {return this.formComponent.symbol}
  get labelTotal() {return this.formComponent.labelTotal}
  get labelCurrency() {return this.formComponent.labelCurrency}
  get currencies() {return this.formComponent.currencies}
  get lastCurrencyType() {return this.formComponent.lastCurrencyType}
  get volumeDiscountTypes() {return this.formComponent.volumeDiscountTypes}
  get fuelCostTypes() {return this.formComponent.fuelCostTypes}
  get volumeDiscountType() {return this.formComponent.volumeDiscountType}
  get fuelCostType() {return this.formComponent.fuelCostType}
  get serviceOptions() {return this.formComponent.serviceOptions}
  get createShipmentMode() {return this.formComponent.createShipmentMode}
  get pickupNumber() {return this.formComponent.pickupNumber}
  get dropoffNumber() {return this.formComponent.dropoffNumber}
  get shipmentNumber() {return this.formComponent.shipmentNumber}
  get listPickupLocationSelect() {return this.formComponent.listPickupLocationSelect}
  get listDeliveryLocationSelect() {return this.formComponent.listDeliveryLocationSelect}
  get isShowOnlyServiceOptions() {return this.formComponent.isShowOnlyServiceOptions}

  selectedNewServiceOption;

  ngOnInit(): void {
  }

  get grandTotal() {
    let grandTotal = this.formComponent.getItemValue('grandTotal') ?? 0;
    return InputHelper.formatMoney2(`${grandTotal}`, this.symbol ? this.symbol : '$');
  }

  get usdConversion() {
    let usdConversion = this.formComponent.getItemValue('usdConversion') ?? 0;
    return InputHelper.formatMoney2(`${usdConversion}`,  this.symbol ? this.symbol : '$');
  }

  getLabel(key): string {
    switch (key) {
      default: return super.getLabel(key);
    }
  }

  getInputType(key: string) {
    return this.formComponent.getInputType(key);
  }

  onInputChanged(event, key) {
    return this.formComponent.onInputChanged(event, key);
  }

  onInputKeyPress(event, key) {
    return this.formComponent.onInputKeyPress(event, key);
  }

  onInputFocusOut(event, key) {
    return this.formComponent.onInputFocusOut(event, key);
  }

  getArrayControls(key) {
    return this.formComponent.getArrayControls(key);
  }

  onChangeCurrencyType(value) {
    return this.formComponent.onChangeCurrencyType(value);
  }

  onVolumeDiscountTypeChange(value) {
    return this.formComponent.onVolumeDiscountTypeChange(value);
  }

  onFuelCostTypeChange(value) {
    return this.formComponent.onFuelCostTypeChange(value);
  }

  // Service Options
  get shouldShowServiceOptionLabel() {
    if (this.formComponent.getArrayControls('serviceOptions').length) return true;
    return false;
  }

  onBtnAddServiceOption(serviceOption) {
    if (!serviceOption) return
    this.formComponent.onBtnAddServiceOption(serviceOption);
    setTimeout(() => this.selectedNewServiceOption = null, 5);
  }

  isDisableAddServiceOptionItem(item: ServiceOptionType) {
    let arr = this.formComponent.getItemValue('serviceOptions') ?? [];
    let count = arr.filter(el => el._id == item._id)?.length ?? 0;
    switch (item.type) {
      case 'pickup':
        return count < this.pickupNumber ? false : true;
      case 'delivery':
        return count < this.dropoffNumber ? false : true;
      case 'addition':
        return count < 1 ? false : true;
    }
  }

  onServiceOptionItemChange(currentServiceIndex: number, event) {
    this.formComponent.onServiceOptionItemChange(currentServiceIndex, event);
  }

  onServiceOptionItemLocationChange(currentServiceIndex: number, event) {
    this.formComponent.onServiceOptionItemLocationChange(currentServiceIndex, event);
  }

  isDisableServiceOptionItem(item: ServiceOptionType, currentServiceIndex: number): boolean {
    const currentServiceId = this.formComponent.getItemValue(`serviceOptions[${currentServiceIndex}]._id`);
    const currentServiceDeliveryId = this.formComponent.getItemValue(`serviceOptions[${currentServiceIndex}].deliveryId`);
    let currentService = MasterData.getServiceOptionById(currentServiceId);
    if (!currentService) return false;
    if (currentService.type == 'pickup') {
      if (item.type == "pickup") {
        let arr = this.formComponent.getItemValue('serviceOptions');
        let count = 0;
        for (let obj of arr) {
          if (obj._id == item._id) count++;
          if (obj._id == item._id && obj.deliveryId == currentServiceDeliveryId && currentServiceDeliveryId != null) return true;
        }
        if (count >= this.pickupNumber) return true;
        return false;
      } else if (item.type == "delivery") {
        let arr = this.formComponent.getItemValue('serviceOptions');
        let count = 0;
        for (let obj of arr) {
          if (obj._id == item._id) count++;
        }
        if (count >= this.dropoffNumber) return true;
        return false;
      }
      let arr = this.formComponent.getItemValue('serviceOptions');
      for (let obj of arr) {
        if (obj._id == item._id) {
          return true;
        }
      }
      return false;
    } else if (currentService.type == 'delivery') {
      if (item.type == "delivery") {
        let arr = this.formComponent.getItemValue('serviceOptions');
        let count = 0;
        for (let obj of arr) {
          if (obj._id == item._id) count++;
          if (obj._id == item._id && obj.dropOffIndex == currentServiceDeliveryId && currentServiceDeliveryId != null) return true;
        }
        if (count >= this.dropoffNumber) return true;
        return false;
      } else if (item.type == "pickup") {
        let arr = this.formComponent.getItemValue('serviceOptions');
        let count = 0;
        for (let obj of arr) {
          if (obj._id == item._id) count++;
        }
        if (count >= this.pickupNumber) return true;
        return false;
      }
      let arr = this.formComponent.getItemValue('serviceOptions');
      for (let obj of arr) {
        if (obj._id == item._id) {
          return true;
        }
      }
      return false;
    } else {
      if (item.type == "pickup" ) {
        let arr = this.formComponent.getItemValue('serviceOptions');
        let count = 0;
        for (let obj of arr) {
          if (obj._id == item._id) count++;
        }
        if (count >= this.pickupNumber) return true;
        return false;
      } else if (item.type == "delivery" ) {
        let arr = this.formComponent.getItemValue('serviceOptions');
        let count = 0;
        for (let obj of arr) {
          if (obj._id == item._id) count++;
        }
        if (count >= this.dropoffNumber) return true;
        return false;
      } else {
        // disable những Pickup va addition đã được selected
        let arr = this.formComponent.getItemValue('serviceOptions');
        for (let obj of arr) {
          if (obj._id == item._id) {
            return true;
          }
        }
        return false;
      }
    }
    return false;
  }

  isDisableServiceOptionLocationItem(item, currentServiceIndex: number): boolean {
    const currentServiceId = this.formComponent.getItemValue(`serviceOptions[${currentServiceIndex}]._id`);
    let arr = this.formComponent.getItemValue('serviceOptions');
    for (let obj of arr) {
      if (obj._id == currentServiceId && obj.deliveryId && obj.deliveryId == item?.deliveryId) return true;
    }
    return false;
  }

  onBtnRemoveServiceOption(index: number) {
    return this.formComponent.onBtnRemoveServiceOption(index);
  }

  get shouldShowSelectLocation() {
    return this.createShipmentMode != Const.ShipmentEntryMode.single ? true : false;
  }

  get isCreateShipmentModeMultiple() {
    return this.createShipmentMode == Const.ShipmentEntryMode.multiPickDrop;
  }

  getServiceOptionItemType(index: number) {
    let itemId = this.formComponent.getItemValue(`serviceOptions[${index}]._id`);
    let option = MasterData.getServiceOptionById(itemId);
    return option?.type || '';
  }

  isSeviceOptionTypeNegative(currentServiceIndex) {
    const currentServiceId = this.formComponent.getItemValue(`serviceOptions[${currentServiceIndex}]._id`);
    if (MasterData.isServiceOptionTypeNegative(currentServiceId)) return true;
    return false;
  }

  getServiceOptionMoneyValue(currentServiceIndex) {
    const total = this.formComponent.getItemValue(`serviceOptions[${currentServiceIndex}].total`);
    let text = InputHelper.formatMoney2(total, this.symbol);
    if (this.isSeviceOptionTypeNegative(currentServiceIndex)) return `-${text}`;
    return text;
  }
  
}