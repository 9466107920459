import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms/forms';
import { BaseFormItem } from '@app/admin/base/form-item';
import { Const } from '@const/Const';
import { BizUtil } from '@services/biz';
import { DateUtil } from '@services/date-utils';
import { MasterData } from '@services/master.data';
import { TimeWindow } from '@wearewarp/types/data-model';

@Component({
  selector: 'add-route-component',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class AddRouteComponent extends BaseFormItem {
  public static get declaration(): FormGroupDeclaration {
    return {
      searchKeyword: { label: '', placeHolder: 'Search by routeID, orderID, shipmentID or REF#', notAcceptEmpty: true },
      clientIds: { label: 'Customer', notAcceptEmpty: true, placeHolder: 'Select customers' },
      markets: { label: 'Market', notAcceptEmpty: true, placeHolder: 'Select markets' },
      vehicleTypes: { label: 'Equipment', notAcceptEmpty: true, placeHolder: 'Select equipments', initialValue: [] },
      isAutoAddRoute: { label: 'Auto add route', type: 'boolean', initialValue: false },
    }
  };

  protected formGroupDeclaration: FormGroupDeclaration = AddRouteComponent.declaration;

  private selectedJobIds: string[] = [];
  @Input() set form(val: FormGroup) {
    this.formInput = val;
  }

  @Input() set selectedRoutes(values: any[]) {
    this.selectedJobIds = values.map(it => it.id);
  }

  @Input() shouldShowCheckbox: boolean = true;
  filterLoading: boolean = false;
  isLoading: boolean = false;
  listClients: any[] = [];
  listMarkets: any[] = [];
  routes: any[] = [];
  totalCount: number = 0;
  limit: number = 5;
  @Output() addRoute: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    super.ngOnInit();
    this.fetchMarkets();
  }

  fetchMarkets() {
    this.filterLoading = true;
    this.api.GET(Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/SYSTEM/LIST_MARKETS`)).subscribe(
      (resp) => {
        let value = resp?.value || '[]';
        try {
          value = JSON.parse(value);
        }
        catch (e) {
          value = [];
        }
        this.filterLoading = false;
        this.listMarkets = value;
      },
      (err) => {
        this.filterLoading = false;
        this.showErr(err);
      }
    )
  }

  getAddress(address: any) {
    return MasterData.getAddressText(address?.addr)
  }

  getVehicleTypeNameText(job) {
    const requiredVehicle = job?.requiredVehicle;
    if (!requiredVehicle) return 'N/A';
    const allVehicle = MasterData.getAllVehicleTypes();
    const vehicle = allVehicle.find(v => v.code === requiredVehicle.code);
    vehicle.options = requiredVehicle.options ?? [];
    return BizUtil.getVehicleName(vehicle);
  }
  
  getVehicleType() {
    const vehicleTypes = this.getItemValue('vehicleTypes');
    if (vehicleTypes?.length) return vehicleTypes;
    return null;
  }

  onChangeVehicleType(value) {
    this.formInput.get("vehicleTypes").setValue(value);
    this.onChange(value.code, 'vehicleTypes');
  }

  loadData() {
    this.isLoading = true
    const data = this.getFormData_JSON(true);
    this.api.POST(`${Const.APIURI_BID_SESSIONS}/get-list-routes`, data).subscribe(
      (resp) => {
        this.isLoading = false;
        this.routes = resp?.data?.list_data || [];
        this.totalCount = resp?.data?.total || 0;
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
      }
    )
  }

  getTime(info) {
    if(!info) return "";
    const window: TimeWindow = { from: info.time, to: info.timeTo };
    const timezone = info.timezone;
    if (window && timezone) {
      const text = DateUtil.displayTimeWindow(window, {
        timezone: timezone,
        format: 'ddd MMM DD, H:mm',
        formatDateOnly: 'ddd MMM DD'
      });
      return `${text} (${DateUtil.timezoneStandardToUsShort(timezone)})`;
    };
    return "";
  }

  get isShowWarning(): boolean {
    const isAutoAddRoute = this.getItemValue('isAutoAddRoute');
    if(!isAutoAddRoute) return false;
    const vehicleTypes = this.getItemValue('vehicleTypes');
    const markets = this.getItemValue('markets');
    if(vehicleTypes.length && markets.length) return false;
    return true;
  }

  onChange(e, name) {
    if(name === 'isAutoAddRoute') return;
    this.loadData()
  }

  onBtnAddRoute(job) {
    this.addRoute.emit(job);
  }

  isSelected(job) {
    return this.selectedJobIds.includes(job.id);
  }
}