<div class="dashboard-child-container no-padding list" style="margin-top: -24px;">
  <nz-tabset [nzSelectedIndex]="tabIndex" (nzSelectChange)="onTabChange($event)">
    <nz-tab *ngFor="let tab of tabs; let i = index;" [nzTitle]="getTabLabel(tab)">
      <ng-template nz-tab>
        <div *ngIf="isShowListAppointment(tab)" shipment-queue-list-need-appointments [apiPath]="tab.apiPath"
          [isVisible]="i == tabIndex" (sumDataUpdated)="onSumDataUpdated(tab, $event)">
        </div>
        <!-- <div *ngIf="tab.key === 'verificationNeeded'">
          <div class="general-info-container">
            <ng-container *ngTemplateOutlet="filter"></ng-container>
            <sqv2-shipment-need-routing-list
            [tab]="tab.key"
            [shipments]="verificationNeeded" (onRemove)="onRemoveShipment($event)"
            (clickShipment)="onClickShipment($event)"
            (onPlanningAdded)="onAddedShipmentPlanning($event)"
            (hoverShipment)="onMouseOverShipment($event)"
            (reviewStatusUpdate)="onReviewStatusUpdate($event)"
            (deliveryInfoUpdated)="onDeliveryInfoUpdate($event)"
            (shipmentsUpdated)="onShipmentsUpdated($event)"
            ></sqv2-shipment-need-routing-list>
          </div>
        </div> -->
        <!-- <div *ngIf="tab.key === 'pending'">
          <div class="general-info-container">
            <ng-container *ngTemplateOutlet="filter"></ng-container>
            <sqv2-shipment-need-routing-list
            [tab]="tab.key"
            [shipments]="pending" (onRemove)="onRemoveShipment($event)"
            (clickShipment)="onClickShipment($event)"
            (onPlanningAdded)="onAddedShipmentPlanning($event)"
            (hoverShipment)="onMouseOverShipment($event)"
            (reviewStatusUpdate)="onReviewStatusUpdate($event)"
            (deliveryInfoUpdated)="onDeliveryInfoUpdate($event)"
            (shipmentsUpdated)="onShipmentsUpdated($event)"
            ></sqv2-shipment-need-routing-list>
          </div>
        </div> -->
        <!-- <div *ngIf="tab.key === 'planned'">
          <div class="general-info-container">
            <ng-container *ngTemplateOutlet="filter"></ng-container>
            <sqv2-shipment-need-routing-list
            [tab]="tab.key"
            [shipments]="planned" (onRemove)="onRemoveShipment($event)"
            (clickShipment)="onClickShipment($event)"
            (onPlanningAdded)="onAddedShipmentPlanning($event)"
            (hoverShipment)="onMouseOverShipment($event)"
            (reviewStatusUpdate)="onReviewStatusUpdate($event)"
            (deliveryInfoUpdated)="onDeliveryInfoUpdate($event)"
            (shipmentsUpdated)="onShipmentsUpdated($event)"
            ></sqv2-shipment-need-routing-list>
          </div>
        </div> -->
      </ng-template>
    </nz-tab>
  </nz-tabset>
</div>
<!-- <ng-template #filter>
  <div class="group id-search">
    <div><label>Search by Id</label></div>
    <div>
      <nz-input-group [nzSuffix]="inputClearTpl">
        <input nz-input nzAllowClear [(ngModel)]="_filter.keyword" ngPlaceholder="Shipment or Order id"
          style="width: 100%; min-width: 100px;" (ngModelChange)="onFilterChange($event)">
      </nz-input-group>
      <ng-template #inputClearTpl>
        <span nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" *ngIf="_filter.keyword"
          (click)="_filter.keyword = null; onFilterChange($event)"></span>
      </ng-template>
    </div>
  </div>
  <div class="group">
    <div><label>Date</label></div>
    <div class="date-filter">
      <span class="date-filter-range" nz-dropdown [nzDropdownMenu]="dateMenu">{{ _filter.dateRange }}</span>
      <nz-dropdown-menu #dateMenu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item (click)="setDateRange('=')">=</li>
          <li nz-menu-item (click)="setDateRange('<')">&lt;</li>
          <li nz-menu-item (click)="setDateRange('>')">&gt;</li>
        </ul>
      </nz-dropdown-menu>
      <nz-select nzBackdrop="true" nzAllowClear nzShowSearch [(ngModel)]="_filter.date"
        style="width: 100%; min-width: 130px;" (ngModelChange)="onFilterChange($event)">
        <nz-option *ngFor="let item of dates" [nzValue]="item.date" [nzLabel]="item.display">
        </nz-option>
      </nz-select>
    </div>
  </div>
  <div class="group">
    <div><label>Crossdocks</label></div>
    <div>
      <nz-select nzBackdrop="true" nzAllowClear nzShowSearch [(ngModel)]="_filter.xdock"
        style="width: 100%; min-width: 160px;" (ngModelChange)="onFilterChange($event)">
        <nz-option *ngFor="let item of xdocks" [nzValue]="item.id" [nzLabel]="item.name">
        </nz-option>
      </nz-select>
    </div>
  </div>
  <div class="group">
    <div><label>Market</label></div>
    <div>
      <nz-select nzBackdrop="true" nzAllowClear nzShowSearch [(ngModel)]="_filter.markets" nzMode="multiple"
        style="width: 100%; min-width: 160px;" (ngModelChange)="onFilterChange($event)">
        <nz-option *ngFor="let item of markets" [nzValue]="item" [nzLabel]="item">
        </nz-option>
      </nz-select>
    </div>
  </div>
  <div class="group">
    <div><label>Customers</label></div>
    <div>
      <nz-select nzBackdrop="true" nzAllowClear nzShowSearch [(ngModel)]="_filter.clients" nzMode="multiple"
        style="width: 100%; min-width: 160px;" (ngModelChange)="onFilterChange($event)">
        <nz-option *ngFor="let item of clients" [nzValue]="item.id" [nzLabel]="item.name">
        </nz-option>
      </nz-select>
    </div>
  </div>
  <div class="group">
    <div><label>Exclude Customers</label></div>
    <div>
      <nz-select nzBackdrop="true" nzAllowClear nzShowSearch [(ngModel)]="_filter.excludeClients" nzMode="multiple"
        style="width: 100%; min-width: 160px;" (ngModelChange)="onFilterChange($event)">
        <nz-option *ngFor="let item of clients" [nzValue]="item.id" [nzLabel]="item.name">
        </nz-option>
      </nz-select>
    </div>
  </div>
  <div class="group">
    <div class="bottom5"><label>Leg</label></div>
    <div>
      <span class="transit-type-option clickable" [class]="{'selected': _filter.transitTypes.has(opt.value)}"
        *ngFor="let opt of transitTypeOptions" (click)="onTransitTypeChange(opt.value)">{{ opt.label }}</span>
    </div>
  </div>

  <div class="group">
    <button nz-button (click)="loadShipments()" class="right10" [disabled]="loading">
      <i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></i>
      <span nz-icon nzType="reload" nzTheme="outline" *ngIf="!loading"></span>
      Refresh
    </button>
  </div>
</ng-template> -->