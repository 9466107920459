import { BaseComponent } from "@abstract/BaseComponent";
import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { DeliveryMap, Utils } from "@app/admin/components/map";
import { Const } from "@const/Const";
import { PlanningService } from "@services/planning.service";
import { AddVehicleForm } from "./add-vehicle-form";
import { AddBidSessionForm } from "./add-bid-session-form";
import { RoutingService } from "@services/routing.service";
import { BizUtil } from "@services/biz";
import _ from 'underscore'
import { AddShipmentToGroupForm } from "./add-to-group-form";
import { Utils as ServiceUtils } from "@services/utils";
import Sockette from "sockette";
import { environment } from "@env/environment";
import { DialogService } from "@dialogs/dialog.service";
import { BidSession } from "@wearewarp/types/data-model";

@Component({
    selector: '[active-planning-session-detail]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss', '../styles.scss']
})
export class ActivePlanningSessionDetail extends BaseComponent {
    _id: string = null
    get id() {
        return this._id
    }
    @Input() set id(v: string) {
        this._id = v
        if (v) {
            this.loadSession()
        }
    }
    service: PlanningService
    routingService: RoutingService

    showExportedRoutes: boolean = false
    showCompletedRoutes: boolean = false

    _session: any = {}
    bidSession: BidSession = null
    get session() {
        return this._session
    }
    set session(res) {
        for (let vehicle of res.fleet || []) {
            if (vehicle.metadata?.maxTravelDistance) {
                const mileage = Math.round(parseFloat(vehicle.metadata?.maxTravelDistance) / 1609.34)
                vehicle.mileageLimit = mileage
            }            
            if (vehicle.metadata?.maxTravelTime) {
                const seconds = parseInt(vehicle.metadata.maxTravelTime)
                vehicle.maxTravelTime = seconds
            }            
        }
        this._session = res
        this.solutionGroups = {}
        this.displayInfo = {...this.displayInfo,
            date: this._session.date.split('T')[0],
            // shipmentCount: `${this._session.shipmentCount}`,
            vehicleCount: `${this._session.fleet?.length || 0} Vehicles`
        }
        this.loadLastSolution()
    }

    _planningShipments: any[] = []
    _shipments: any[] = []
    _exportedShipments: any[] = []
    unrouted: any[] = []
    get planningShipments() {
        return this._planningShipments
    }

    filterWarehouses() {
        const locationIds: Set<string> = new Set(this._shipments.flatMap(it => it.deliveryInfos.map(i => i.warehouseId)).filter(it => it))
        return this.warehouses.filter(it => locationIds.has(it.id))
    }

    set planningShipments(v) {
        const first = !this._planningShipments?.length
        this._planningShipments = v.map(this.validateShipment)
        this.processShipments(first)
    }

    shipmentFilter: string = null
    onShipmentFilterUpdate() {
        this.processGroups()
    }

    validateWindow(window) {
        if (!window) return null
        if (!window.from && !window.to) return null
        const latest = window.to || window.from
        if (Date.parse(latest) < Date.now()) {
            return 'Time Window is too old!'
        }
        if (window.from === window.to) {
            return 'Time Window is too small. Minimum Time Window is 30 minutes'
        }
        if (window.from && window.to) {
            if (Date.parse(window.to) - Date.parse(window.from) < 1800 * 1000) {
                return 'Time Window is too small. Minimum Time Window is 30 minutes'
            }
        }
    }

    // validate
    validateShipment(planningShipment) {
        const { metadata } = planningShipment || {}
        const { shipment } = metadata || {}
        let issue = null

        const pickup = BizUtil.getPickInfo(shipment)
        const dropoff = BizUtil.getDropInfo(shipment)     
        const pickupWindow = BizUtil.getTimeWindowForDeliveryInfo(pickup)
        const dropoffWindow = BizUtil.getTimeWindowForDeliveryInfo(dropoff)

        let v = this.validateWindow(pickupWindow)
        if (v) {
            planningShipment.metadata.issue = 'Pickup ' + v
            return planningShipment
        }
        v = this.validateWindow(dropoffWindow)
        if (v) {
            planningShipment.metadata.issue = 'Dropoff ' + v
            return planningShipment
        }

        if (dropoffWindow.window?.from && pickupWindow.window?.from) {
            if (Date.parse(dropoffWindow.window?.to) < Date.parse(pickupWindow.window?.from)) {
                planningShipment.metadata.issue = 'Delivery Time is Before Pickup Time.'
                return planningShipment
            }
            if (Date.parse(dropoffWindow.window?.from) - Date.parse(pickupWindow.window?.from) > 18 * 3600 * 1000) {
                planningShipment.metadata.issue = 'Delivery Time and Pickup Time are too much different.'
                return planningShipment
            }
        }
        return planningShipment
    }

    processShipments(fitting: boolean = true) {
        this._shipments = this._planningShipments
            .filter(it => it.metadata?.shipment)
            .filter(it => it.status !== 'EXPORTED').map(it => {
            const { metadata, workload } = it || {}
            const { shipment } =  metadata || {}
            shipment.metadata = {...(shipment.metadata || {}), workload}
            return shipment
        }).filter(it => it)

        this.loadShipmentsToMap(fitting)
        this.map.loadWarehouses(this.filterWarehouses())

        this.map.refresh()
        // zoom in
        this.processGroups()
    }

    onClickRoute(route) {
        const { stops } = route || {}
        if (!stops.length) return
        const locations =stops.map(it => it.location.latlng)
        if (route.startLocation?.latlng) {
            locations.push(route.startLocation?.latlng)
        }
        this.map.fitBounds(locations)
    }

    onClickJob(job) {
        const { deliveryStatus } = job || {}
        const locations = deliveryStatus.map(it => it.location)
        this.map.fitBounds(locations)
    }

    groups: any[] = []
    noGroup: any[] = []

    filterShipment(s, q) {
        const { metadata } = s || {}
        const { shipment } = metadata || {}
        const { warpId } = shipment
        if (warpId?.toString()?.indexOf(q) >= 0) return true

        return false
    }

    processGroups() {
        let all = this._planningShipments.filter(it => it.status !== 'EXPORTED')
        if (this.shipmentFilter) {
            all = all.filter(it => this.filterShipment(it, this.shipmentFilter))
        }
        const groupNames = _.uniq(all.map(it => it.group).filter(it => it))
        const noGroup = all.filter(it => !it.group)
        this.noGroup = noGroup.filter(it => it.metadata?.issue).concat(noGroup.filter(it => !it.metadata?.issue))
        this.groups = _.sortBy(groupNames.map(g => {
            return {
                name: g,
                shipments: all.filter(it => it.group === g)
            }
        }), "name")
        this.displayInfo.shipmentCount = all.length
    }

    loading: boolean = false
    loadingShipment: boolean = false
    displayInfo: any = {}
    solution: any = null
    routes: any[] = null
    solutionGroups: any = {}

    constructor() {
        super()
        this.service = new PlanningService(this.api)
        this.routingService = new RoutingService(this.api)
        this.onMouseOverShipment = this.onMouseOverShipment.bind(this)
        this.loadLastSolution = this.loadLastSolution.bind(this)
        this.validateWindow =  this.validateWindow.bind(this)
        this.validateShipment = this.validateShipment.bind(this)
        this.loadExportedRoutes = this.loadExportedRoutes.bind(this)
        this.loadRoutesToMap = this.loadRoutesToMap.bind(this)
        this.loadShipmentsToMap = this.loadShipmentsToMap.bind(this)
    }

    map: DeliveryMap
    ws: Sockette
    wsDeliveryUpdate: Sockette
    
    @ViewChild('mapboxContainer', { static: true }) mapboxContainer: ElementRef;
    @ViewChild('deckCanvas', { static: true }) deckCanvas: ElementRef;

    timer: any = null
    ngOnInit(): void {
        this.map = new DeliveryMap(this.id, this.mapboxContainer, this.deckCanvas)
        this.map.onPickObject = this.onMapPickObject.bind(this)
        this.loadWarehouses()
        // this.timer = setInterval(() => {
        //     this.loadLastSolution()
        // }, 3000)
        this.initRoutingSocket()
        this.initDeliveryUpdateSocket()
    }

    private initRoutingSocket() {
        this.ws = new Sockette(`${ environment.wsUrl}/routing-solution`, {
            timeout: 5e3,
            maxAttempts: 100,
            onopen: e => {
                console.log('Websocket Connected');
            },
            onmessage: (e) => {
                // console.log('Websocket onmessage ', e);
                let data = e.data;
                const comps = data.split(",")
                const problem = comps.filter(it => it.indexOf("PROBLEM_") >= 0)[0]
                const solution = comps.filter(it => it.indexOf("SOLUTION_") >= 0)[0]?.split("_")[1]
                if (problem) {
                    setTimeout(() => {
                        console.log(`Checking solution for problem ${problem.split('_')[1]}`)
                        this.checkSolution(problem.split('_')[1], solution)
                    }, 500);
                    
                }
            },
            onclose: (e) => {
                console.log('Websocket Closed');
            },
            onreconnect: e => console.log('Reconnecting...', e),
            onerror: e => console.error('Websocket Error:', e)
        });
    }

    private initDeliveryUpdateSocket() {
        this.wsDeliveryUpdate = new Sockette(`${ environment.wsUrl}/delivery-update`, {
            timeout: 5e3,
            maxAttempts: 100,
            onopen: e => {
                console.log('Delivery Update Websocket Connected');
            },
            onmessage: (e) => {
                console.log('Delivery Update Websocket onmessage ', e);
                this.processDeliveryUpdate(e)
            },
            onclose: (e) => {
                console.log('Delivery Update Websocket Closed');
            },
            onreconnect: e => console.log('Delivery Update Reconnecting...', e),
            onerror: e => console.error('Delivery Update Websocket Error:', e)
        });
    }

    onMapPickObject({coordinate, object}) {
        const { type, id, warpId } = object || {}
        if (type === 'SHIPMENT' && warpId) {
            ServiceUtils.copyTextToClipboard(warpId, (e) => {
                if (e) {
                    this.showErr("Cannot copy to clipboard");
                } else {
                    this.showSuccess(
                        `Copied to the clipboard Shipment Id ${warpId}`
                    );
                }
            })    
        }
    }

    onCopyText(txt) {
        ServiceUtils.copyTextToClipboard(txt.toString(), (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `Copied to the clipboard ${txt}`
                );
            }
        })    
    }

    ngOnDestroy(): void {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
        this.ws?.close()
        this.wsDeliveryUpdate?.close()
    }

    onCreateBidSession() {
        DialogService.openFormDialog1(AddBidSessionForm, {
            nzTitle: 'Create Bid Session',
            nzComponentParams: {
                planningSessionId: this.id,
                jobIds: this.exportedRoutes.map(it => it.metadata.job.id),
                closeOnSuccess: true,
                updateSuccess: (receivers) => {
                   this.loadSession() 
                },
            },
            nzClassName: "modal-xl",
        });
    }

    onViewBidSession() {
        this.router.navigate([Const.routeAdminBidSessions, this.bidSession.id])
    }


    loadSession() {
        this.loading = true
        this.service.getSessionDetail(this.id).subscribe((res) => {
            this.loading = false
            this.session = res
        }, (err) => {

        })

        this.service.getBidSessionByPlanningId(this.id).subscribe((res) => {
            if(res?.data) this.bidSession = res?.data
        }, (err) => {

        })
        
        this.loadingShipment = true
        this.service.getSessionShipments(this.id).subscribe((res) => {
            this.loadingShipment = false
            this.planningShipments = res
            this.loadShipmentsToMap(true)
        }, (err) => {

        })

        this.loadExportedRoutes()
    }

    processRoutes() {
        if (!this.solutionGroups) return
        const { groups } = this.session || {}

        let routes = []
        let unrouted = []
        if (this.solutionGroups.main?.routes?.length) {
            for (let r of this.solutionGroups.main.routes) {
                r.solutionId = this.solutionGroups.main.id
            }
            routes = routes.concat(this.solutionGroups.main.routes)
            if (this.solutionGroups.main.unrouted) {
                unrouted = unrouted.concat(this.solutionGroups.main.unrouted)
            }
        }

        // TODO: add sub groups
        if (groups?.length)
        for (let group of groups) {
            if (this.solutionGroups[group.name]?.routes?.length) {
                for (let r of this.solutionGroups[group.name].routes) {
                    r.solutionId = this.solutionGroups[group.name].id
                }
                routes = routes.concat(this.solutionGroups[group.name].routes)
                if (this.solutionGroups[group.name].unrouted) {
                    unrouted = unrouted.concat(this.solutionGroups[group.name].unrouted)
                }
    
            }
        }

        let vehicleMap: any = {}
        let shipmentMap: any = {}
        let exportedShipments: Set<string> = new Set()
        for (let v of (this.session.fleet || [])) {
            vehicleMap[v.id] = v
        }
        for (let s of this._planningShipments) {
            shipmentMap[s.shipmentId] = s
        }
        for (let s of this._planningShipments) {
            if (s.status === 'EXPORTED') {
                exportedShipments.add(s.shipmentId)
            }
        }
        let idx = 1
        for (let r of routes) {
            r['vehicle'] = vehicleMap[r.vehicleId]
            r['idx'] = idx++
            for (let s of r.stops) {
                s['shipment'] = shipmentMap[s.shipmentId]
            }    
        }
        for (let r of routes) {
            r.display = true
        }
        this.routes = _.sortBy(routes.filter(r => {
            // check if exported
            const exported = _.any(r.stops, it => exportedShipments.has(it.shipmentId))
            return !exported
        }), it => (-it.cost?.travelTime || 0))
        this.unrouted = unrouted.map(it => shipmentMap[it]).filter(it => it)

        this.loadRoutesToMap()
        this.map.refresh()
    }

    resetSolutions() {
        this.solutionGroups = {}
        this.processRoutes()
    }

    checkSolution(routingProblemId, solutionId) {
        const { triggeredRouting, groups } = this.session || {}
        let affectedRouting: any = null
        if (triggeredRouting?.problemId == routingProblemId) {
            // check if already loaded
            affectedRouting = triggeredRouting
            affectedRouting.name = 'main'
        } else
        if (groups?.length) {
            for (let group of groups) {
                if (routingProblemId == group.triggeredRouting?.problemId) {
                    affectedRouting = triggeredRouting
                    affectedRouting.name = group.name
                }
            }
        }
        if (!affectedRouting) return

        affectedRouting.solutionId = solutionId

        const check = () => {
            if (this.isLoaded(affectedRouting.name, affectedRouting.solutionId)) return
            console.log(`Checking new solution for ${affectedRouting.name} with ${affectedRouting.solutionId}`)
            this.loadProblemSolution(affectedRouting.name, affectedRouting.problemId, affectedRouting.solutionId)
        }

        setTimeout(check, 300)
        setTimeout(check, 1000)
        setTimeout(check, 2000)
        setTimeout(check, 3000)
    }

    isLoaded(name, solutionId) {
        return solutionId && this.solutionGroups[name]?.id == solutionId
    }

    loadLastSolution() {
        const { triggeredRouting, groups } = this.session || {}
        const { problemId } = triggeredRouting || {}

        this.loadProblemSolution('main', problemId)

        if (groups?.length) {
            for (let group of groups) {
                this.loadProblemSolution(group.name, group.triggeredRouting?.problemId)
            }
        }
    }

    minInterval: number = 1000
    maxInterval: number = 12000

    loadProblemSolution(name, problemId, solutionId = null) {
        if (!problemId) {
            this.solutionGroups[name] = {
                lastLoaded: 0
            }
            return
        }
        const lastSolutionId = this.solutionGroups[name]?.id
        const lastLoaded = this.solutionGroups[name]?.lastLoaded ?? 0
        const lastInterval = this.solutionGroups[name]?.lastInterval ?? this.minInterval
        if (Date.now() < lastLoaded + lastInterval) return

        this.routingService.loadProblemLastSolution(problemId, lastSolutionId).subscribe((res) => {
            if (solutionId != null && res.id != solutionId) {
                // DO NOTHING
                console.log(`Expecting a different sollution ${res.id} vs ${solutionId}`)
            } else {
            if (res.id !== lastSolutionId) {
                res.lastLoaded = Date.now()
                res.interval = this.minInterval
                this.solutionGroups[name] = res
                this.processRoutes()
            } else {
                this.solutionGroups[name].lastLoaded = Date.now()
                this.solutionGroups[name].lastInterval =  Math.min(lastInterval * 2, this.maxInterval)
            }
            }
        }, (err) => {
            this.solutionGroups[name] = {
                lastLoaded: Date.now(),
                lastInterval: Math.min(lastInterval * 2, this.maxInterval)
            }
            this.processRoutes()
        })
    }


    onToggleShowExportedRoute(route) {
        this.loadRoutesToMap()
        this.loadShipmentsToMap()
        this.map.refresh()
    }

    showRoutes: boolean = true
    onToggleShowRoutes() {
        for (let r of this.routes) {
            r.display = this.showRoutes
        }

        this.loadShipmentsToMap()
        this.loadRoutesToMap()
        this.map.refresh()
    }

    onToggleShowCompletedRoutes() {
        if (this.completedRoutes) {
            for (let r of this.completedRoutes) {
                r.display = this.showCompletedRoutes
            }
        }
        this.loadShipmentsToMap()
        this.loadRoutesToMap()
        this.map.refresh()
    }

    onToggleShowGroupRoutes(group) {
        for (let r of group.routes) {
            r.display = group.display
        }
        this.loadShipmentsToMap()
        this.loadRoutesToMap()
        this.map.refresh()
    }

    onToggleShowCompletedRoute(route) {
        this.loadRoutesToMap()
        this.loadShipmentsToMap()
        this.map.refresh()
    }

    loadShipmentsToMap(fitting = false) {
        let exportedShipments = []
        if (this.exportedRoutes) {
            for (let r of this.exportedRoutes) {
                if (r.display && r.metadata?.shipments) {
                    exportedShipments = exportedShipments.concat(r.metadata.shipments)
                }
            }
        }
        if (this.completedRoutes) {
            for (let r of this.completedRoutes) {
                if (r.display && r.metadata?.shipments) {
                    exportedShipments = exportedShipments.concat(r.metadata.shipments)
                }
            }
        }
        let hiddenShipments: Set<string> = new Set()
        for (let r of (this.routes || [])) {
            if (!r.display) {
                for (let s of r.stops) {
                    hiddenShipments.add(s.shipmentId)
                }
            }
        }
        const pendingShipments = (this._shipments || []).filter(it => !hiddenShipments.has(it.id))

        const shipments = [...pendingShipments]
        this.map.loadShipments(shipments)

        if (fitting) {
            const toFit = shipments.length ? shipments : this._exportedShipments
            const locations = _.flatten(toFit.map(shipment => {
                const pickup = Utils.getAdressLatLng(BizUtil.getPickInfo(shipment)?.addr, false)
                const dropoff = Utils.getAdressLatLng(BizUtil.getDropInfo(shipment)?.addr, false)
                return [pickup, dropoff]
            }))
            if (locations.length)
                this.map.fitBounds(locations)
        }
    }

    loadRoutesToMap() {
        const jobs = this.allRoutes.filter(it => it.display).map(it => Object.assign({job: it.metadata?.job, tasks: it.deliveryStatus})).filter(it => it.job)
        const pending = (this.routes || []).filter(it => it.display)
        this.map.loadRoutes([...pending])
        this.map.loadJobs(jobs)
    }

    allRoutes: any[] = []
    exportedRoutes: any[] = []
    completedRoutes: any[] = []
    routeGroups: any[] = []

    async loadExportedRoutes() {
        const all = await this.service.getSessionExportedRoutes(this._id).toPromise()
        const jobs = all.map(it => it.metadata.job)
        const jobIds = jobs.map(it => it.id)
        const deliveryStatuses = jobIds.length ? await this.service.loadJobsDeliveryStatus(jobIds).toPromise() : []
        const jobStatus = {}
        for (let d of deliveryStatuses) {
            jobStatus[d.id] = d.tasks
        }
        for (let r of all) {
            r.deliveryStatus = jobStatus[r.metadata.job.id]
        }
        this.allRoutes = all

        this.processExportedRoutes()
        this.loadShipmentsToMap(true)
    }

    isPickedUp(route) {
        if (!route?.deliveryStatus) return false
        for (let task of route.deliveryStatus) {
            if (task.status === 'succeeded') return true
        }
    }

    processExportedRoutes() {
        const created = this.allRoutes.filter(it => it.metadata?.job?.status != 'completed' && it.metadata?.job?.status != 'canceled')
        const noCarrier = created.filter(it => !it.metadata?.job?.assignedCarrier)
        const withCarrier = created.filter(it => it.metadata?.job?.assignedCarrier)
        const notStarted = withCarrier.filter(it => it.metadata?.job?.status != 'inProgress')
        const inProgress = withCarrier.filter(it => it.metadata?.job?.status == 'inProgress')
        const completed =  this.allRoutes.filter(it => it.metadata?.job?.status == 'completed' || it.metadata?.job?.status == 'canceled')
        this.exportedRoutes = [...noCarrier, ...notStarted, ...inProgress]
        this.completedRoutes = completed
        let exportedShipments = []
        for (let r of this.allRoutes) {
            if (r.metadata?.shipments) {
                exportedShipments = exportedShipments.concat(r.metadata.shipments)
            }
        }
        this._exportedShipments = exportedShipments

        // grouping
        const expandedStatus = {
            'completed': false,
            'unassigned': true,
            'inactive': true,
            'pickingUp': false,
            'pickedUp': false
        }
        const displayStatus = {}
        for (let group of this.routeGroups) {
            expandedStatus[group.key] = group.expanded ?? expandedStatus[group.key]
            displayStatus[group.key] = group.display ?? displayStatus[group.key]
        }

        const pickingUp = inProgress.filter(it => !this.isPickedUp(it))
        const pickedUp = inProgress.filter(it => this.isPickedUp(it))

        const routeGroups: any[] = [
            {
                name: 'Un-assigned',
                key: 'unassigned',
                routes: noCarrier,
            },
            {
                name: 'Not Started',
                key: 'inactive',
                routes: notStarted,
            },
            {
                name: 'Picking Up',
                key: 'pickingUp',
                routes: pickingUp,
            },
            {
                name: 'Picked Up',
                key: 'pickedUp',
                routes: pickedUp,
            },
            {
                name: 'Completed',
                key: 'completed',
                routes: completed,
            }
        ]

        for (let group of routeGroups) {
            group.expanded = expandedStatus[group.key]
            group.display = displayStatus[group.key] ?? false
        }

        this.routeGroups = routeGroups
    }

    warehouses: any[] = []
    loadWarehouses() {
        const url = `${Const.APIURI_WAREHOUSES}?limit=-1&filter=${JSON.stringify({warehouseType: 'crossdock'})}`
        this.api.GET(url).subscribe((res) => {
            this.warehouses = res.data.list_data
            this.map.loadWarehouses(this.filterWarehouses())
            this.map.refresh()
        })
    }

    onMouseOverShipment(event) {
        const { metadata } = event || {}
        const { shipment } = metadata || {}
        const { deliveryInfos } = shipment || {}
        const locations = (deliveryInfos || []).map(info => info.addr.metadata)
        this.map.loadHighlightLocations(locations)
        this.map.refresh()
    }

    onMouseOverStop(event) {
        const { location } = event || {}
        const { latlng } = location || {}
        if (latlng?.lat)
            this.map.loadHighlightLocations([{latitude: latlng.lat, longitude: latlng.lng}])
        else
            this.map.loadHighlightLocations([])
        this.map.refresh()
    }

    onMouseOverRoute(event) {
        if (event)
            this.map.loadHighlightRoutes([event])
        else
            this.map.loadHighlightRoutes([])
        this.map.refresh()
    }

    onMouseOverJob(event) {
        if (event) {
            const route = {
                stops: event.deliveryStatus
            }
            this.map.loadHighlightRoutes([route])
        } else
            this.map.loadHighlightRoutes([])
        this.map.refresh()
    }

    onRemoveShipment(event) {
        this.service.removeSessionShipment(this.session.id, event).subscribe((res) => {
            // remove shipments
            const shipments = this._planningShipments.filter(it => it.shipmentId !== event)
            this.planningShipments = shipments
            // setTimeout(() => {
            //     this.loadLastSolution()
            // }, 30000)
        }, (err) => {
            this.showErr(`Error while removing shipment ${event}`)
        })
    }

    public onBtnAddVehicle() {
        let saving: boolean = false
        let modalRef: any = null
        modalRef = this.modalService.create({
          nzTitle: 'Add Vehicle',
          nzContent: AddVehicleForm,
          nzComponentParams: {
          },
          nzFooter: [
            {
              label: 'Save',
              type: 'primary',
              loading: () => saving,
              disabled: (componentInstance) => !componentInstance.vehicleType || !componentInstance.availability || saving,
              onClick: (componentInstance) => {
                saving = true
                const metadata: any = {}
                if (componentInstance.maxMileage) {
                    metadata.maxTravelDistance = Math.round(componentInstance.maxMileage * 1609.34).toString()
                }
                if (componentInstance.maxHours) {
                    metadata.maxTravelTime = Math.round(componentInstance.maxHours * 3600).toString()
                }
                this.updateVehicle(componentInstance.vehicleType, componentInstance.availability, metadata).subscribe(
                  (res) => {
                    modalRef?.destroy()
                    this.session = res
                  },
                  (err) => {
                    saving = false
                    this.notification.create('error', '', err.message);
                  }        
                )
              }
            },
            {
              label: 'Cancel',
              type: 'default',
              disabled: () => saving,
              onClick: () => modalRef?.destroy()
            },
          ]
        })
    }
    
    updateVehicle(vehicle, availability, metadata={}) {
        const payload = {
            availability: availability,
            vehicle,
            capacity: vehicle.palletCapacity,
            name: vehicle.name,
            metadata
        }
        this.resetSolutions()
        return this.service.addVehicle(this.session.id, payload)
    }

    updating = false
    updateShipmentGroup(shipmentIds, group, callback) {
        this.updating = true
        return this.service.setGroup(this.session.id, shipmentIds, group).subscribe((res) => {
            this.updating = false
            this.session = res
            for (let s of this._planningShipments) {
                if (shipmentIds.indexOf(s.shipmentId) >= 0) {
                    s.group = group
                }
            }
            this.processGroups()
            this.resetSolutions()
            callback && callback()
        }, (err) => {
            this.showErr(`Error while updating shipment Group`)
            this.updating = false
        })
    }

    unsetShipmentGroup(shipmentIds) {
        this.updating = true
        return this.service.unsetGroup(this.session.id, shipmentIds).subscribe((res) => {
            this.updating = false
            this.session = res
            for (let s of this._planningShipments) {
                if (shipmentIds.indexOf(s.shipmentId) >= 0) {
                    delete s.group
                }
            }
            this.processGroups()
        }, (err) => {
            this.showErr(`Error while updating shipment Group`)
            this.updating = false
        })
    }

    onBtnRemoveVehicle(vehicle) {
        return this.service.removeVehicle(this.session.id, vehicle).subscribe((res) => {
            this.session = res
            this.resetSolutions()
        })
    }

    // loading last solution

    goBack() {
        this.router.navigate([ Const.routePlanningSessions ])
    }

    onAddShipmentToGroup(event) {
        let saving: boolean = false
        let modalRef: any = null
        modalRef = this.modalService.create({
          nzTitle: 'Add Shipment',
          nzContent: AddShipmentToGroupForm,
          nzComponentParams: {
            shipmentIds: [event]
          },
          nzFooter: [
            {
              label: 'Save',
              type: 'primary',
              loading: () => this.updating,
              disabled: (componentInstance) => !componentInstance.groupName || saving,
              onClick: (componentInstance) => {
                this.updateShipmentGroup(componentInstance.shipmentIds, componentInstance.groupName, () => modalRef?.destroy() )
              }
            },
            {
              label: 'Cancel',
              type: 'default',
              disabled: () => saving,
              onClick: () => modalRef?.destroy()
            },
          ]
        })
    }

    onUnsetShipmentGroup(event) {
        this.unsetShipmentGroup([event])
    }

    exporting: boolean = false
    exportRoute(route) {
        console.log("Done")
        // update shipment status
        const shipmentIds = route.stops.map(it => it.shipmentId)
        for (let s of this._planningShipments) {
            if (shipmentIds.indexOf(s.shipmentId) >= 0) {
                s.status = 'EXPORTED'
            }
        }
        this.processShipments()
        this.processRoutes()
        this.loadExportedRoutes()
        this.showInfo(`New route has been created.`)
    }

    onTriggerRoutingBtn() {
        this.resetSolutions()
        this.service.triggerRouting(this._session.id).subscribe((r) => {
            this.session = r
        })
    }

    viewRoutedList: boolean = true
    onToggleViewRoutedList() {
        this.viewRoutedList = !this.viewRoutedList
    }

    viewCompletedList: boolean = false
    onToggleViewCompletedList() {
        this.viewCompletedList = !this.viewCompletedList
    }

    onToggleViewGroup(group) {
        group.expanded = !group.expanded
    }

    processDeliveryUpdate(event) {
        const comps = event.data.split(",")
        const job = comps.filter(it => it.indexOf("JOB_") >= 0)[0]
        if (!job) return
        const jobId = job.split("_")[1]
        const exportedRoute: any = this.allRoutes.filter(it =>it.metadata?.job?.id == jobId)[0]

        if (!exportedRoute) return

        const taskLevel = event.data.indexOf("TASK.DELIVERY") >= 0
        const jobLevel = event.data.indexOf("JOB.DELIVERY") >= 0 || event.data.indexOf("JOB.PLANNING") >= 0

        if (taskLevel) {
            this.service.loadJobsDeliveryStatus([jobId]).subscribe((res) => {
                for (let d of res) {
                    if (d.id == jobId) {
                        exportedRoute.deliveryStatus = d.tasks
                        if (exportedRoute.metadata?.job) {
                            exportedRoute.metadata.job.status = d.status || exportedRoute.metadata.job.status
                        }
                        this.processExportedRoutes()
                    }
                }
            })
        }

        if (jobLevel) {
            this.service.getJob(jobId).subscribe((res) => {
                if (res?.id == jobId) {
                    exportedRoute.metadata.job = res
                    this.processExportedRoutes()
                }
            })
        }
    }
}