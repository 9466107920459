import { OnInit, OnDestroy, AfterViewInit, OnChanges, DoCheck, AfterContentInit, AfterContentChecked, AfterViewChecked, Injector, RendererFactory2, Directive, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AddressUS, DeliveryInfo, LatLng, AttachedFile, Shipment } from '@wearewarp/types/data-model';
import { AdminAuthUser } from '@wearewarp/types-server-admin';
import { WarpId } from '@wearewarp/universal-libs';
import { TranslateService, KeyLang } from '@locale/index';
import { Const } from '@const/Const';
import { Title, Meta } from '@angular/platform-browser';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService, OnClickCallback } from 'ng-zorro-antd/modal';
import { getInjector, getApp, getDashboard, App } from '@services/index';
import { Utils } from '@services/utils';
import { ApiService } from '@services/api.service';
import { RoleManager } from '@services/role-manager';
import { Log } from '@services/log';
import { Subscription } from 'rxjs';
import isEmail from 'validator/lib/isEmail';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FormControl } from '@angular/forms';
import { EventAuthUserChange } from '@wearewarp/ng-web';
import { MasterData } from '@services/master.data';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { InputHelper } from '@services/input-helper';
import { AttachedFileUtil } from '@services/attached-file-util';
import { BizUtil } from '@services/biz';
import { environment } from '@env/environment';
import { EnvType } from '@env/type';
import { isAccounting, isAdmin, isAdminReadOnlyRole, isCarrier, isClient, isFinanceAllowed, isSysAdmin, requirePermissions } from '@services/auth.check-role';
import { AuthService } from '@wearewarp/ng-web';
import { DateUtil } from '@services/date-utils';
import { ConfirmDeleteOptions } from '@app/interfaces';
import { PermissionManager } from '@services/permission-manager';
import { PermissionCode } from '@const/PermissionCode';
import { HttpResponse } from '@angular/common/http';
import { ResponseAdminOpsRole } from '@wearewarp/types-server-admin/user';
import { UploadFileData } from '@wearewarp/types/rest-api';
dayjs.extend(relativeTime);

@Directive()
export class BaseComponent implements OnChanges, OnInit, DoCheck, AfterContentInit, AfterContentChecked, AfterViewInit, AfterViewChecked, OnDestroy {

  public readonly routeLogin = Const.routeLogin;
  public readonly routeVerifyLogin = Const.routeVerifyLogin;

  public readonly routeDashboard = Const.routeDashboard;
  public readonly routeAdminUserList = Const.routeAdminUserList;
  public readonly routeAdminDeveloperList = Const.routeAdminDeveloperList;
  public readonly routeAdminCarrierList = Const.routeAdminCarrierList;
  public readonly routeAdminDriverList = Const.routeAdminDriverList;
  public readonly routeAdminClientList = Const.routeAdminClientList;
  public readonly routeAdminDispatchList = Const.routeAdminDispatchList;
  public readonly routeAdminOrderList = Const.routeAdminOrderList;
  public readonly routeAdminPodConfirmationList = Const.routeAdminPODNeedConfirm;
  /**
   * @deprecated use routeAdminOrderList instead
   */
  public readonly routeAdminShipmentList = Const.routeAdminOrderList;
  public readonly routeAdminShipmentParcels = Const.routeAdminShipmentParcels;
  public readonly routeAdminCarrierSales = Const.routeAdminCarrierSales;
  public readonly routeAdminBidSessions = Const.routeAdminBidSessions;
  public readonly routeAdminCarrierBidList = Const.routeAdminCarrierBidList;
  public readonly routeAdminPools = Const.routeAdminPools;
  public readonly routeAdminCrossdockWarehouseMap = Const.routeAdminCrossdockWarehouseMap;
  public readonly routeAdminCrossdockWarehouseJob = Const.routeAdminCrossdockWarehouseJob;
  public readonly routeAdminCrossdockWarehouseManifests = Const.routeAdminCrossdockWarehouseManifests;
  public readonly routeAdminLabelTemplateList = Const.routeAdminLabelTemplateList;
  public readonly routeAdminAuditLogList = Const.routeAdminAuditLogList;
  public readonly routeAdminShipmentQueue = Const.routeAdminShipmentQueue;
  public readonly routeRoutingLTLProblems = Const.routeRoutingLTLProblems;
  public readonly routeAdminLocationList = Const.routeAdminLocationList;
  public readonly routeAdminCustomerLocationList = Const.routeAdminCustomerLocationList;
  public readonly routeAdminCustomerLaneList = Const.routeAdminCustomerLaneList;
  public readonly routeAdminFreightQuote = Const.routeAdminFreightQuote;
  public readonly routeAdminFreightQuoteFtl = Const.routeAdminFreightQuoteFtl;
  public readonly routeSettingsLocations = Const.routeSettingsLocations;
  public readonly routeTemplates = Const.routeTemplates;
  public readonly routeAnnouncements = Const.routeAnnouncements;
  public readonly routeSetting2FA = Const.routeSetting2FA;
  public readonly routeAdminWarehouseRatePlan = Const.routeAdminWarehouseRatePlan;
  public readonly routeAdminFinAP = Const.routeAdminFinAP;
  public readonly routeAdminFinAR = Const.routeAdminFinAR;
  public readonly routeAdminFinQBStatement = Const.routeAdminFinQBStatement;
  public readonly isDevelopment = environment.type === EnvType.dev;
  public readonly isProduction = environment.type === EnvType.prod;

  get apiListDataForFilterClients() { return Const.APIURI_CLIENTS_FOR_FILTER }
  get apiListDataForFilterCarriers() { return Const.APIURI_CARRIERS_FOR_FILTER }

  public txtSTT = '';
  public txtOK = '';
  public txtCancel = '';
  public txtLogin = ''
  public txtLoginBy = ''
  public txtEmail = ''
  public txtName = ''
  public txtCode = ''
  public txtFullName = ''
  public txtPassword = ''
  public txtNotification = '';
  public txtError = '';
  public txtErrorRetryLater = '';
  public txtSuccess = '';
  public txtNoData = '';
  public txtFunctionRequireLogin = '';
  public txtRefresh = '';
  public txtSave = '';
  public txtEdit = '';
  public txtRequiredField = '';
  public txtNone = '';
  public txtInformSuccess = '';
  public txtFilter = '';
  public txtNoResult = '';
  public txtDelete = '';
  public txtRemove = '';
  public txtUndoDelete = '';
  public txtFilterNone = '';
  public txtYesterday = '';
  public txtCreateNew = '';

  // window.innerWidth includes the width of the vertical scroll bar
  get isMobile(): boolean { return /*window.innerWidth*/ window.document.body.clientWidth <= Const.MOBILE_WIDTH }
  // get siteName(): string { return AppConst.siteName }
  get lang() { return TranslateService.getCurrentLanguage() }
  // get isEn(): boolean { return this.lang == Const.LANG_EN }
  // get isVi(): boolean { return this.lang == Const.LANG_VI }
  get componentUrl(): string { return window.location.href.split('?')[0]; }

  protected TAG;
  private langChangeSubscription: Subscription;
  private authChangeSubscription: Subscription;
  private windowResizeSubscription: Subscription;

  protected get authUser(): AdminAuthUser { return this.appComponent.getAuthUser() }

  // Lấy từ query string /dashboard/users?param1=1&param2=2.
  // Các class kế thừa ko được update trực tiếp giá trị _queryParams
  // vì nó cần lưu giá trị previous khi xử lý handleNavigationEnd
  // Nếu cần thì hãy clone ra object khác rồi edit trên bản clone đấy
  private _queryParams;
  protected get queryParams() { return this._queryParams || {} }

  protected injector: Injector;
  protected notification: NzNotificationService;
  protected modalService: NzModalService;
  protected drawerService: NzDrawerService;
  protected title: Title;
  protected meta: Meta;
  protected router: Router;
  protected auth: AuthService;
  protected trans: TranslateService;
  protected api: ApiService;
  private rendererFactory: RendererFactory2;
  protected subscription: Subscription = new Subscription();

  constructor(protected activatedRoute: ActivatedRoute = null) {
    this.TAG = this.constructor.name;
    this.injector = getInjector();
    this.notification = this.injector.get(NzNotificationService);
    this.modalService = this.injector.get(NzModalService);
    this.drawerService = this.injector.get(NzDrawerService);
    this.rendererFactory = this.injector.get(RendererFactory2);
    this.title = this.injector.get(Title);
    this.meta = this.injector.get(Meta);
    this.router = this.injector.get(Router);
    this.auth = this.injector.get(AuthService);
    this.trans = this.injector.get(TranslateService);
    this.api = this.injector.get(ApiService);
    this.langChangeSubscription = this.trans.onLangChanged.subscribe(lang => {
      this._setupLanguage();
    });
    if (this.auth) {
      this.authChangeSubscription = this.auth.authChanged.subscribe(ev => {
        switch (ev.code) {
          case EventAuthUserChange.login:
            return this.didLogin();
          case EventAuthUserChange.logout:
            return this.didLogout();
          default: return;
        }
      });
    }
    if (this.appComponent) {
      this.windowResizeSubscription = this.appComponent.subscribeWindowResize(ev => this.onWindowResize(ev));
    }
  }

  ngOnChanges() {
  }

  ngOnInit() {
    this._setupLanguage();
  }

  ngDoCheck() {
  }

  ngAfterContentInit() {
  }

  ngAfterContentChecked() {
  }

  ngAfterViewInit() {
  }

  ngAfterViewChecked() {
  }

  ngOnDestroy() {
    this.langChangeSubscription?.unsubscribe();
    this.windowResizeSubscription?.unsubscribe();
    this.authChangeSubscription?.unsubscribe();
    this.subscription?.unsubscribe()
  }

  private _setupLanguage() {
    this.txtSTT = this.text(KeyLang.Txt_STT);
    this.txtOK = this.text(KeyLang.Txt_OK);
    this.txtCancel = this.text(KeyLang.Txt_Cancel);
    this.txtName = this.text(KeyLang.Txt_Name);
    this.txtCode = this.text(KeyLang.Txt_Code);
    this.txtFullName = this.text(KeyLang.Txt_FullName);
    this.txtEmail = this.text(KeyLang.Txt_Email);
    this.txtPassword = this.text(KeyLang.Txt_Password);
    this.txtLogin = this.text(KeyLang.Txt_Login);
    this.txtLoginBy = this.text(KeyLang.Txt_LoginBy);
    this.txtError = this.text(KeyLang.Txt_Error);
    this.txtErrorRetryLater = this.text(KeyLang.Txt_ErrorRetryLater);
    this.txtSuccess = this.text(KeyLang.Txt_Success);
    this.txtNotification = this.text(KeyLang.Txt_Notification);
    this.txtNoData = this.text(KeyLang.Txt_NoData);
    this.txtFunctionRequireLogin = this.text(KeyLang.Txt_FunctionRequireLogin);
    this.txtRefresh = this.text(KeyLang.Txt_Refresh);
    this.txtSave = this.text(KeyLang.Txt_Save);
    this.txtEdit = this.text(KeyLang.Txt_Edit);
    this.txtRequiredField = this.text(KeyLang.Txt_RequiredField);
    this.txtNone = this.text(KeyLang.Txt_None);
    this.txtInformSuccess = this.text(KeyLang.Txt_InformSuccess);
    this.txtFilter = this.text(KeyLang.Txt_Filter);
    this.txtNoResult = this.text(KeyLang.Txt_NoResult);
    this.txtDelete = this.text(KeyLang.Txt_Delete);
    this.txtRemove = this.text(KeyLang.Txt_Remove);
    this.txtUndoDelete = this.text(KeyLang.Txt_UndoDelete);
    this.txtFilterNone = this.text(KeyLang.Txt_FilterNone);
    this.txtYesterday = this.text(KeyLang.Txt_Yesterday);
    this.txtCreateNew = this.text(KeyLang.Txt_CreateNew);
    this.setupLanguage();
  }

  protected setupLanguage() {
  }
  protected didLogin() {
  }
  protected didLogout() {
  }
  protected onKeyEnter(target) {
  }
  protected onWindowResize(event: Event) {
  }

  protected get appComponent(): App { return getApp(); }

  public get isAppInitOK(): boolean {
    return this.appComponent && this.appComponent.isFirstLoadingOK();
  }
  public get currentUrl(): string {
    return this.router.url;
  }

  public get isCarrerBidEnabled(): boolean {
    return Const.CARRIER_BID_ENABLED;
  }

  public get isAdmin(): boolean {
    return isAdmin(this.authUser);
  }
  public get isSysAdmin(): boolean {
    return isSysAdmin(this.authUser);
  }
  public get isClient(): boolean {
    return isClient(this.authUser);
  }
  public get isCarrier(): boolean {
    return isCarrier(this.authUser);
  }
  public get isAccounting(): boolean {
    return isAccounting(this.authUser);
  }
  public get isAdminReadOnlyRole(): boolean {
    return isAdminReadOnlyRole(this.authUser);
  }
  public get isFinanceAllowed(): boolean {
    return isFinanceAllowed(this.authUser);
  }
  public requirePermissions(codes: string[]): boolean {
    return requirePermissions(this.authUser, codes);
  }

  public get self(): BaseComponent {
    return this;
  }
  public get isLoggedIn(): boolean {
    return this.auth.alreadyLoggedIn();
  }
  public get userName(): string {
    let name = this.authUser ? this.authUser.fullName || this.authUser.email : '';
    return name;
  }
  public get userAvatar(): string {
    return 'assets/img/avatar1.png';
  }
  public get authUserFullName() {
    return this.getFullName(this.authUser);
  }
  public get authUserEmail() {
    return this.authUser.email;
  }
  public get canLoginAdmin(): boolean {
    return PermissionManager.allowLogin(this.authUser);
  }

  public get PermissionCode(){
    return PermissionCode;
  }

  public isMe(user) {
    return user && user._id && this.authUser && this.authUser.id == user.id;
  }

  protected goLogin() {
    this.router.navigate([this.routeLogin]);
  }

  protected goDashboard() {
    this.router.navigate([this.routeDashboard]);
  }

  protected autoShrinkSideBar() {
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
  }

  // Chỉ dùng cho các component detail có url dạng như này /users/:id
  protected getIdForDetailComponent(route: ActivatedRoute) {
    return this.getRouteParam(route, 'id');
  }

  protected getUrlSegments(): Array<string> {
    let urlTree = this.router.parseUrl(this.router.url);
    return urlTree.root.children['primary'].segments.map(it => it.path);
  }

  protected getCurrentUrlPath() {
    return this.getUrlSegments().join('/');
  }

  protected getRouteParam(route: ActivatedRoute, name: string = undefined) {
    if (name === undefined) {
      return (route.snapshot.paramMap as any).params;
    }
    let value = route.snapshot.params[name];
    if (value != undefined && value != null) {
      return decodeURIComponent(value);
    }
    return null;
  }

  public onNavigationEnd(url: string): void {
    Log.d(`[${this.TAG}] onNavigationEnd: ${url}`);
    let params = Utils.parseQueryStringFromUrl(url);
    let prevQueryParam = this.queryParams || {};
    this._queryParams = Utils.cloneObject(params, true);
    this.handleNavigationEnd(url, prevQueryParam);
  }

  // Derived class should override this method as entry point for fetching data from restapi
  protected handleNavigationEnd(url: string, prevQueryParam: any) {
  }

  // query: chỉ cần chứa những param mới cần thay đổi, sau đó sẽ được merge vào queryParams cũ.
  // Dùng hàm này khi url path ko đổi, chỉ thay đổi query param
  // replace = true: repace all query params instead of merge
  protected routeWithQueryUrl(query, replace = false) {
    let url = this.router.url.split('?')[0];
    let q = replace ? query : Object.assign(Utils.cloneObject(this.queryParams || {}), query);
    this.router.navigate([url], { queryParams: q });
  }

  protected getQueryParam(key) {
    let query = Utils.parseQueryStringFromUrl(this.router.url);
    return query[key];
  }

  onKeyUp(event) {
    if (event.keyCode == 13) {
      this.onKeyEnter(event.target);
    }
  }

  // Dùng cho input, chỉ cho nhập số
  public onlyNumberKey(event) {
    // 8: back
    // 48: 0
    // 57: 9
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  // get localized text
  protected text(key: string, words?: string | string[] | number | number[]): string {
    return this.trans.instant(key, words);
  }

  protected changeLanguage(lang: string) {
    return this.trans.use(lang);
  }

  protected showErr(err, options?): void {
    let msg = Utils.getErrorString(err);
    this.notification.create('error', this.txtError, msg, options);
  }

  protected showErrDialog(err) {
    let message = Utils.getErrorString(err);
    this.modalService.error({
      nzContent: message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'OK',
      nzCancelText: null
    });
  }

  protected showSuccess(resp, options?): void {
    let msg = Utils.getSuccessString(resp);
    this.notification.create('success', this.txtSuccess, msg, options);
  }

  protected showInfo(message: any, options?): void {
    let msg = Utils.getErrorString(message);
    this.notification.create('info', this.txtNotification, msg, options);
  }

  protected showWarning(title: any = null, message: any, options?): void {
    let msg = Utils.getErrorString(message);
    this.notification.create('warning', title ?? this.txtNotification, msg, options);
  }

  protected showDialog(message: string | TemplateRef<any>, onOK = () => { }) {
    this.modalService.create({
      nzContent: message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'OK',
      nzOnOk: onOK,
      nzCancelText: null
    });
  }

  protected confirm(message: string, onOk: OnClickCallback<void>, title: string = undefined) {
    this.modalService.create({
      nzTitle: title,
      nzContent: message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'OK',
      nzOnOk: onOk,
      nzCancelText: 'Cancel'
    });
  }

  protected confirmYesNo(message: string, onOk: OnClickCallback<void>, onCancel?: OnClickCallback<void>) {
    if (!Utils.isFunction(onCancel)) {
      onCancel = () => {}
    }
    this.modalService.confirm({
      nzTitle: message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'Yes',
      nzOnOk: onOk,
      nzCancelText: 'No',
      nzOnCancel: onCancel
    });
  }

  protected confirmDeletion(ops: ConfirmDeleteOptions) {
    let fnCancel = ops.fnCancel;
    if (!Utils.isFunction(fnCancel)) {
      fnCancel = () => {
      }
    }
    this.modalService.confirm({
      nzTitle: ops.message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: ops.center ?? true,
      nzOkText: ops.txtBtnOk || this.txtDelete,
      nzOnOk: ops.fnOk,
      nzOkDanger: true,
      nzCancelText: ops.txtBtnCancel || this.txtCancel,
      nzOnCancel: fnCancel,
      nzAfterClose: ops.afterClose
    });
  }

  public scrollToTop() {
    if (this.appComponent) {
      this.appComponent.scrollToTop();
    }
  }

  public scrollToElement(element: HTMLElement, ops: { behavior?: ScrollBehavior } = {}): void {
    if (!element) {
      return;
    }
    let behavior: ScrollBehavior = ops.behavior || 'smooth';
    element.scrollIntoView({ behavior: behavior, block: "start", inline: "nearest" });
  }

  public getAppComponent() {
    return this.appComponent;
  }

  public onRouterActivated() {
  }

  public getFileUrl(path: string): string {
    return ApiService.getImageUrl(path);
  }

  public attachedFileUrl(model: UploadFileData): string {
    return AttachedFileUtil.attachedFileUrl(model);
  }

  public attachedFileDesc(model: AttachedFile): string {
    return `${model.name} (${this.displayFileSize(model.size)})`;
  }

  public getImageUrl(path: string, query = null) {
    if (AttachedFileUtil.isAttachedFile(path)) return AttachedFileUtil.attachedFileUrl(<any>path);
    if (!path) return '';
    if (Utils.isUrl(path) || Utils.isLocalImage(path) || Utils.isAssets(path)) return path;
    let url = ApiService.getImageUrl(path);
    if (query && query.noresize) {
      return Utils.appendQueryStringIntoUrl(url, { noresize: 1 });
    }
    if (Utils.isObjectNotEmpty(query)) {
      let params = Object.assign(query || {}, { fallback: true });
      url = Utils.appendQueryStringIntoUrl(url, params);
    }
    return url;
  }

  public isPdf(item: {type?: string, name?: string}): boolean {
    return AttachedFileUtil.isPdf(item);
  }

  capitalizeFirstLetter(str) {
    return Utils.capitalizeFirstLetter(str);
  }

  nameToCode(name) {
    if (!Utils.isString(name)) {
      return '';
    }
    return name.trim().toLowerCase().replace(/\s+/g, '-');
  }

  getUserRoles(user) {
    const arr = [];
    const hiddenStatuses = [RoleManager.admin, RoleManager.warehouseInternal, RoleManager.warehouseExternal];
    for (let i = 0; i < user.roles.length; i++) {
      if(!hiddenStatuses.includes(user.roles[i].code)) {
        arr.push(this.getRoleName(user.roles[i]));
      }
    }
    return arr.join(', ');
  }

  public validateEmail(input: FormControl): any {
    if (input.value === null || input.value === undefined) {
      return { required: true };
    }
    const str = input.value.trim();
    if (!isEmail(str)) {
      return { required: true };
    }
    return null;
  }

  // str: ISO 8601 string from database
  public displayDateTimeDB(str: string, format = Const.FORMAT_GUI_DATETIME) {
    if (!str) return '';
    let d = dayjs(str);
    return d.format(format);
  }
  // str: ISO 8601 string from database
  public displayDateDB(str: string, format = Const.FORMAT_GUI_DATE) {
    return this.displayDateTimeDB(str, format);
  }

  // str: from database
  public displayDateTime(str, format = null) {
    if (!str) return '';
    let d = dayjs(str);
    let dToday = dayjs();
    if (!format) {
      if (d.isSame(dToday, 'year')) {
        format = this.trans.currentLang == Const.LANG_VI ? 'H:mm, D/M' : 'H:mm, D MMM';
      } else {
        format = this.trans.currentLang == Const.LANG_VI ? 'H:mm, D/M/YYYY' : 'H:mm, D MMM YYYY';
      }
    }
    d.locale(this.lang);
    return d.format(format);
  }

  public displayPhone(str: string): string {
    return InputHelper.formatPhone(str);
  }

  // options.standard?: "iec" | "jedec";
  public displayFileSize(size, options?) {
    return AttachedFileUtil.displayFileSize(size, options);
  }

  public getFileSize(item, options?) {
    return this.displayFileSize(item.size, options);
  }

  public getFileCreatedTime(item) {
    return this.displayDateTime(item.birthtimeMs, Const.DATETIME_FORMAT_FROM_DB);
  }

  public getFileModifiedTime(item) {
    return this.displayDateTime(item.mtimeMs, Const.DATETIME_FORMAT_FROM_DB);
  }

  public openInNewTab(url) {
    return Utils.openInNewTab(url);
  }

  public downloadAttachedFile(attachedFile: AttachedFile) {
    let id = attachedFile.id || attachedFile._id;
    let url = `${Const.APIURI_DOWNLOAD}/${id}`;
    attachedFile.isDownloading = true;
    let fileName = attachedFile.name;
    let fileType = attachedFile.type;
    this.api.GET(url, {observe: 'response', responseType: 'arraybuffer'}).subscribe(
      (resp: HttpResponse<any>) => {
        let body = resp.body;
        if (!fileName) {
          let contentDisposition = resp.headers.get('Content-Disposition');
          fileName = Utils.getFileNameFromContentDisposition(contentDisposition);
        }
        if (!fileType) {
          fileType = resp.headers.get('Content-Type');
        }
        let blob = new Blob([body], { type: fileType });
        let url = URL.createObjectURL(blob);
        Utils.downloadFile(url, fileName);
        attachedFile.isDownloading = false;
      }, err => {
        this.showErr(err);
        attachedFile.isDownloading = false;
      }
    );
  }

  public getStatusCarrier(status: number): string {
    switch (status) {
      case Const.CarrierStatus.temporary: return 'Temporary';
      case Const.CarrierStatus.active: return 'Active';
      case Const.CarrierStatus.pending: return 'Pending';
      case Const.CarrierStatus.rejected: return 'Rejected';
      case Const.CarrierStatus.notOnboard: return 'Not Onboard';
      case Const.CarrierStatus.DNU: return 'DNU';
      case Const.CarrierStatus.deactivate: return 'Deactivate'
      default: return '';
    }
  }

  public getCarrierCreatedTime(item) {
    return this.displayCreatedTime(item)
  }

  public displayCreatedTime(item: any): string {
    return this.displayDateTime(item?.insert?.when, Const.DATETIME_FORMAT_FROM_DB);
  }

  public getStatusUser(status: string): string {
    switch (status) {
      case Const.UserStatus.actived: return 'Active';
      case Const.UserStatus.blocked: return 'Blocked';
      case Const.UserStatus.temporary: return 'Temporary';

      default: return 'Active';
    }
  }

  public getStatusShipment(status: number | string): string {
    switch (status) {
      case Const.OrderStatus.needCarrier: return 'Pending';
      case Const.OrderStatus.booked: return 'Booked';
      case Const.OrderStatus.dispatched: return 'Dispatched';
      case Const.OrderStatus.arrivedAtPickup: return 'Arrived at Pickup';
      case Const.OrderStatus.pickupSuccessful: return 'Pickup Successful';
      case Const.OrderStatus.pickupFailed: return 'Pickup Failed';
      case Const.OrderStatus.inRouteToDropoff: return 'In Route to Dropoff';
      case Const.OrderStatus.arrivedAtDropoff: return 'Arrived at Dropoff';
      case Const.OrderStatus.inRouteToWareHouse: return 'In Route To Warehouse';
      case Const.OrderStatus.arrivedAtWareHouse: return 'Arrived at Warehouse';
      case Const.OrderStatus.departedFromWarehouse: return 'Departed From Warehouse';
      case Const.OrderStatus.dropoffSuccessful: return 'Dropoff Successful';
      case Const.OrderStatus.dropoffFailed: return 'Dropoff Failed';
      case Const.OrderStatus.complete: return 'Complete';
      case Const.OrderStatus.returnInitiated: return 'Return Initiated';
      case Const.OrderStatus.inRouteToReturn: return 'In Route to Return';
      case Const.OrderStatus.arrivedAtReturn: return 'Arrived at Return';
      case Const.OrderStatus.returned: return 'Returned';
      case Const.OrderStatus.canceled: return 'Canceled';
      case Const.OrderStatus.lost: return 'Lost';
      case Const.OrderStatus.issue: return 'Issue';
      case Const.OrderStatus.removed: return 'Removed';
      default: return `Pending`;
    }
  }

  /**
   * @deprecated use getStatusShipment instead
   */
  public getStatusOrder(status: number | string): string {
    return this.getStatusShipment(status);
  }

  public getOrderCancelReason(reason: number | string): string {
    switch (reason) {
      case Const.OrderCancelReasons.ORDER_NOT_DISPENSED: return 'Order not Dispensed';
      case Const.OrderCancelReasons.SYSTEM_ERROR: return 'System Error';
      case Const.OrderCancelReasons.CARRIER_CANCELLED: return 'Carrier Cancelled';
      case Const.OrderCancelReasons.LOCATION_CLOSED: return 'Location Closed';
      case Const.OrderCancelReasons.CUSTOMER_CANCELLED: return 'Customer Cancelled';
      case Const.OrderCancelReasons.SHIPMENT_NOT_READY: return 'Shipment(s) not ready';
      case Const.OrderCancelReasons.PICKUP_FAILED: return 'Pickup failed';
      case Const.OrderCancelReasons.NO_DRIVER_ASSIGNED: return 'No driver assigned';
      case Const.OrderCancelReasons.OTHER: return 'Other';
      default: return `Other`;
    }
  }

  public getStatusJob(status: string): string {
    return BizUtil.getStatusJob(status);
  }

  // for friendly & meaningful displaying
  public getStatusTask(task: any): string {
    let status = task.status;
    if (task.type == 'PICKUP') {
      switch (status) {
        case Const.TaskStatus.created: return 'Created';
        case Const.TaskStatus.enroute: return 'Enroute to Pickup';
        case Const.TaskStatus.arrived: return 'Arrived at Pickup';
        case Const.TaskStatus.succeeded: return 'Pickup Succeeded';
        case Const.TaskStatus.failed: return 'Pickup Failed';
        case Const.TaskStatus.canceled: return 'Pickup Canceled';
        case Const.TaskStatus.pickupFailed: return 'Pickup Failed';
        default: return 'Created';
      }
    } else if (task.type == 'DROPOFF') {
      switch (status) {
        case Const.TaskStatus.created: return 'Created';
        case Const.TaskStatus.enroute: return 'Enroute to Dropoff';
        case Const.TaskStatus.arrived: return 'Arrived at Dropoff';
        case Const.TaskStatus.succeeded: return 'Dropoff Succeeded';
        case Const.TaskStatus.failed: return 'Dropoff Failed';
        case Const.TaskStatus.canceled: return 'Canceled';
        case Const.TaskStatus.pickupFailed: return 'Pickup Failed';
        default: return 'Created';
      }
    }
    // default
    switch (status) {
      case Const.TaskStatus.created: return 'Created';
      case Const.TaskStatus.enroute: return 'Enroute';
      case Const.TaskStatus.arrived: return 'Arrived';
      case Const.TaskStatus.succeeded: return 'Succeeded';
      case Const.TaskStatus.failed: return 'Failed';
      case Const.TaskStatus.canceled: return 'Canceled';
      case Const.TaskStatus.pickupFailed: return 'Pickup Failed';
      default: return 'Created';
    }
  }

  // For displaying in dispatch detail screen
  public getTaskStatus(task: any): string {
    if (task.status == Const.TaskStatus.canceled && task.tonu?.carrierCost) {
      return 'Canceled - TONU';
    }
    switch (task.status) {
      case Const.TaskStatus.created: return 'Created';
      case Const.TaskStatus.enroute: return 'Enroute';
      case Const.TaskStatus.arrived: return 'Arrived';
      case Const.TaskStatus.succeeded: return 'Succeeded';
      case Const.TaskStatus.failed: return 'Failed';
      case Const.TaskStatus.canceled: return 'Canceled';
      case Const.TaskStatus.pickupFailed: return 'Removed';
      default: return 'Created';
    }
  }

  // for css class
  public getTaskStatusCssClass(task: any): string {
    if (task?.status == Const.TaskStatus.canceled && task?.tonu?.carrierCost) {
      return 'canceled-tonu';
    }
    let status = task?.status;
    switch (status) {
      case Const.TaskStatus.created: return 'created';
      case Const.TaskStatus.enroute: return 'enroute';
      case Const.TaskStatus.arrived: return 'arrived';
      case Const.TaskStatus.succeeded: return 'succeeded';
      case Const.TaskStatus.failed: return 'failed';
      case Const.TaskStatus.canceled: return 'failed';
      case Const.TaskStatus.pickupFailed: return 'removed';
      default: return 'created';
    }
  }
  public textLatLng(location: LatLng): string {
    if (!location) return '';
    return `${location.latitude}, ${location.longitude}`;
  }

  public getCarrierName(carrier: {basicInfo?: {name?: string}}): string {
    return carrier?.basicInfo?.name ?? '';
  }

  public getCarrierMC(carrier): string {
    return carrier?.basicInfo?.mc ?? '';
  }

  public getCarrierNameWithMC(carrier): string {
    if (carrier?.basicInfo?.mc) return `${carrier?.basicInfo?.name} (${carrier?.basicInfo?.mc})`
    return carrier?.basicInfo?.name ?? '';
  }

  public getDriverName(driver): string {
    return this.getFullName(driver);
  }

  public getDriverNameWithPhone(driver): string {
    let name = this.getFullName(driver);
    if (driver.phone) {
      return `${name}  ${InputHelper.formatPhone(driver.phone)}`;
    }
    return name;
  }

  public getFullName(user) {
    if (!user) return '';
    if (user.fullName) return user.fullName;
    let text = user.firstName || '';
    if (user.lastName) {
      if (text) {
        text += ' ';
      }
      text += user.lastName;
    }
    return text;
  }

  public getRoleName(role: ResponseAdminOpsRole): string {
    if (role.name) return role.name;
    if (role.code == RoleManager.client) return 'Customer';
    if (role.code == RoleManager.sale) return 'Sales';
    if (role.code == RoleManager.warehouseOperator) return 'WarehouseOperator';
    return Utils.capitalizeFirstLetter(role.code);
  }

  public getAddressText(addr: AddressUS): string {
    return MasterData.getAddressText(addr);
  }

  public getDeliveryInfoTime(deliveryInfo: DeliveryInfo, options: { format?: string, appointmentFlag?: string } = {}): string {
    return BizUtil.getDeliveryInfoTime(deliveryInfo, options);
  }

  public getStateDesc(state): string {
    let obj = state;
    if (typeof state == 'string') {
      obj = MasterData.getStateUSByCode(state);
    }
    if (!obj) {
      return state;
    }
    let str = obj.code;
    if (obj.name) {
      str += ` (${obj.name})`;
    }
    return str;
  }

  public getVehicleTypeName(type: string): string {
    switch (type) {
      case 'straightTrucks.f16': return 'Straight Truck 16ft';
      case 'straightTrucks.f18': return 'Straight Truck 18ft';
      case 'straightTrucks.f20': return 'Straight Truck 20ft';
      case 'straightTrucks.f24': return 'Straight Truck 24ft';
      case 'straightTrucks.f26': return 'Straight Truck 26ft';
      case 'straightTrucks.f28': return 'Straight Truck 28ft';
      case 'reeferStraightTrucks.f16': return 'Reefer Straight Truck 16ft';
      case 'reeferStraightTrucks.f18': return 'Reefer Straight Truck 18ft';
      case 'reeferStraightTrucks.f20': return 'Reefer Straight Truck 20ft';
      case 'reeferStraightTrucks.f24': return 'Reefer Straight Truck 24ft';
      case 'reeferStraightTrucks.f26': return 'Reefer Straight Truck 26ft';
      case 'reeferStraightTrucks.f28': return 'Reefer Straight Truck 28ft';
      case 'liftGateStraightTrucks.f16': return 'Lift Gate Straight Truck 16ft';
      case 'liftGateStraightTrucks.f18': return 'Lift Gate Straight Truck 18ft';
      case 'liftGateStraightTrucks.f20': return 'Lift Gate Straight Truck 20ft';
      case 'liftGateStraightTrucks.f24': return 'Lift Gate Straight Truck 24ft';
      case 'liftGateStraightTrucks.f26': return 'Lift Gate Straight Truck 26ft';
      case 'liftGateStraightTrucks.f28': return 'Lift Gate Straight Truck 28ft';
      case 'liftGateReeferStraightTrucks.f16': return 'Lift Gate Reefer Straight Truck 16ft';
      case 'liftGateReeferStraightTrucks.f18': return 'Lift Gate Reefer Straight Truck 18ft';
      case 'liftGateReeferStraightTrucks.f20': return 'Lift Gate Reefer Straight Truck 20ft';
      case 'liftGateReeferStraightTrucks.f24': return 'Lift Gate Reefer Straight Truck 24ft';
      case 'liftGateReeferStraightTrucks.f26': return 'Lift Gate Reefer Straight Truck 26ft';
      case 'liftGateReeferStraightTrucks.f28': return 'Lift Gate Reefer Straight Truck 28ft';
      case 'tractors': return 'Tractor';
      case 'sprinters': return 'Sprinter';
      case 'dryTrailers': return 'Dry Trailer';
      case 'cargoVans': return 'Cargo Van';
      case 'reefTrailers': return 'Reef Trailer';
      default: return type;
    }
  }

  public getPaymentTermName(code: string): string {
    switch (code) {
      case '24h': return 'Quick Pay - 1 Day';
      case '7d': return '7 Days';
      case '15d': return '15 Days';
      case '30d': return '30 Days';
      default: return code;
    }
  }

  public getShipmentTypeName(code: string): string {
    return BizUtil.getShipmentTypeName(code);
  }

  public getServiceOptionName(id): string {
    return MasterData.getServiceOptionName(id);
  }

  public getSackID(order) {
    if (order.sackImportID) return order.sackImportID;
    return 'N/A';
  }

  public showOrderWarpId(order: number | { warpId: number }): string {
    return WarpId.showOrder(order);
  }

  public showShipmentWarpId(shipment: number | { warpId: number }): string {
    return WarpId.showShipment(shipment);
  }

  public showItemWarpId(item: number | { warpId: number }): string {
    return WarpId.showItem(item);
  }

  public showFinJobCode(item: {code: string} | string): string {
    const code = typeof item == 'string' ? item : item.code;
    if (!code) return '';
    return `FIN-${code}`;
  }

  public showOrderCode(order) {
    return WarpId.showOrderCode(order)
  }

  public showShipmentCode(shipment) {
    return WarpId.showShipmentCode(shipment)
  }

  public showShipmentOnlyCode(shipment) {
    return WarpId.showShipmentCode(shipment, true)
  }

  public comingSoon() {
    this.showDialog('Coming soon');
  }

  public getCssClassByStatus(item): string {
    switch (item.status) {
      case Const.JobStatus.completed: return 'succeeded';
      case Const.JobStatus.inProgress: return 'inprogress';
      case Const.JobStatus.canceled: return 'canceled';
      default: return 'created';
    }
  }
  // code: Const.packagingTypeList = ['Parcel'|'Pallet'|'FTL']
  public getPackagingTypeName(code: string): string {
    if (code == 'Pallet') {
      return 'LTL';
    }
    return code;
  }

  public getContainerName(item) {
    return BizUtil.getShipmentContainerName(item);
  }

  public getItemSumary(data) {
    return BizUtil.getItemSumary(data);
  }

  public getWarpId(data) {
    return BizUtil.getWarpId(data);
  }

  public getRouterLinkWarp(item) {
    if ([Const.ShipmentTransitType.leg, Const.ShipmentTransitType.layover].includes(item.shipmentTransitType)) {
      let orderId = item.metadata?.parent?.orderId || '';
      return [this.routeAdminOrderList, orderId];
    }
    if (item.orderId) {
      return [this.routeAdminOrderList, item.orderId];
    }
    return [this.routeAdminOrderList];
  }

  public getRouterLinkFragmentWarp(item: Shipment) {
    if (item.shipmentTransitType == Const.ShipmentTransitType.leg) {
      return undefined;
    }
    return item.id || (<any>item).shipmentId;
  }

  public displayTimeZone(location: any) {
    // return location?.addr?.metadata?.timeZone || null;
    const timezone = location?.addr?.metadata?.timeZoneStandard || null;
    return timezone ? DateUtil.timezoneStandardToUsShort(timezone) : ""
  }

  public displayTimeWindow(window: any, location: any) {
    const timezone = location?.addr?.metadata?.timeZoneStandard || null;
    if (window && timezone) {
      return DateUtil.displayTimeWindow(window, {
        timezone: timezone,
        format: 'ddd MMM DD, H:mm',
        formatDateOnly: 'ddd MMM DD'
      });
    };
    return "";
  }

  public getShipmentStatus(status: string) {
    status = status || "";
    return Const.getShipmentStatusText(status) || status;
  }

  public getWarehouseJobStatus(status: string) {
    status = status || "";
    let str = Const.WarehouseJobStatusText[status] || status;
    return str ? str.toUpperCase() : str
  }

  public displaySummaryPallets(count: any): string {
    count = count || 0;
    let strPallets = count > 1 ? "pallets" : "pallet";
    return count ? `${count} ${strPallets}` : "N/A"
  }

  public displaySummaryRecords(count: any): string {
    count = count || 0;
    let strPallets = count > 1 ? "records" : "record";
    return `${count} ${strPallets}`
  }

  public barcodeText(barcodes: string[]): string {
		return (barcodes || []).join(', ');
	}
}
