import { Component } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Subscription } from "rxjs";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
import { InputHelper } from "@services/input-helper";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import _ from 'underscore';

@Component({
  selector: "carrier-sale-carries",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleCarriers extends BaseComponent {
  bid: any;
  displayInfo: any = {};
  private bidDetailSubscription: Subscription;
  private carriersSubscription: Subscription;
  // private bidEventSubscription: Subscription;
  public acceptedPrice: any //giá bid đã chốt
  public earliestBid: any //thời gian phản hồi bid sớm nhất
  public lowestPrice: any //giá bid thấp nhất
  public bestExperience: any //chạy tuyến hiện tại nhiều nhất
  public carriers: any = [];
  public basePrice: any //giá bid mà WARP offer
  acceptedCarrier: any;
  public sortPriceDirection = 'ascend';

  public listCarrierWithGroup: any[];     // mảng sau khi đã group theo dedicated pool

  public listData: any = [];
  public listPool: any = [];
  
  constructor(
    private carrierSaleDetailService: CarrierSaleDetailService,
  ) {
    super();
  }

  ngOnInit() {
    this.bidDetailSubscription = this.carrierSaleDetailService.bidDetail$.subscribe(bidDetail => {
      this.bid = bidDetail;
      this.onProcessRouteInfo(bidDetail);
    });
    this.carriersSubscription = this.carrierSaleDetailService.carriers$.subscribe(carrriers => {
      this.carriers = carrriers;
      this.onProcessCarriers()
    });
  }

  getTagsData(listData) {
    let bidDates: number[] = []
    let bidPrices: number[] = []
    let countLanes: number[] = []

    for (let item of listData) {
      //date
      const when = item?.when && new Date(item?.when)?.getTime()
      if (when) bidDates.push(when)
      //price
      const price = !isNaN(item?.price) && item?.price
      if (price) bidPrices.push(price)
      //count lane completed
      const countLane = !isNaN(item?.countLane) && item?.countLane
      if (countLane) countLanes.push(countLane)
    }
    return {
      earliestBid: Math.min(...bidDates),
      lowestPrice: Math.min(...bidPrices),
      bestExperience: Math.max(...countLanes)
    }
  }

  calcPercentage(x, y) {
    if (!x || !y || isNaN(x) || isNaN(y)) return
    const percent = ((x / y) * 100) - 100
    if (!percent) return
    return `${(percent).toFixed(2)}%`;
  }

  formatMoney(money: number) {
    return InputHelper.formatMoney2((money).toFixed(2).toString());
  }

  compareWithOtherPrice(text: string, price: number) {
    if (!price || isNaN(price) || !isFinite(price)) return
    if (this.acceptedPrice > price) {
      return {
        status: Const.compareBidPrice.more,
        percent: this.calcPercentage(this.acceptedPrice, price),
        text: `more than ${text} ${this.formatMoney(this.acceptedPrice - price)}`
      }
    }
    if (this.acceptedPrice == price) {
      return {
        status: Const.compareBidPrice.equal,
        percent: this.calcPercentage(this.acceptedPrice, price),
        text: `Equal to ${text} ${this.formatMoney(this.acceptedPrice)}`
      }
    }
    if (this.acceptedPrice < price) {
      return {
        status: Const.compareBidPrice.less,
        percent: this.calcPercentage(price, this.acceptedPrice),
        text: `less than ${text} ${this.formatMoney(price - this.acceptedPrice)}`
      }
    }
    return
  }

  private onProcessCarriers() {
    let carriers = this.carriers || [];
    for(let carrier of carriers){
      carrier.bidAnswers = this.getCounterBidHistory(carrier);
    }
    this.listCarrierWithGroup = this.sortCarriers(this.groupByDedicatedPool(this.bid));
    this.listData = this.listCarrierWithGroup;
    const { earliestBid, lowestPrice, bestExperience } = this.getTagsData(this.carriers || [])
    this.earliestBid = earliestBid
    this.bestExperience = bestExperience //chạy tuyến này nhiều nhất
    this.lowestPrice = lowestPrice //giá thấp nhất

    this.listCarrierWithGroup = this.addTagsData(this.listCarrierWithGroup, earliestBid, lowestPrice, bestExperience)
    // xử lý info cho accepted carrier
    this.acceptedPrice = this.bid?.job?.assignedCarrier?.cost?.grandTotal;
    this.acceptedCarrier = this.bid?.job?.carrier
    const findCarrier = this.listCarrierWithGroup?.find(carrier => carrier?.carrierId == this.acceptedCarrier?.id)
    if (findCarrier) {
      this.acceptedCarrier = {
        ...this.acceptedCarrier,
        isLowestPrice: findCarrier?.isLowestPrice,
        isEarliestBid: findCarrier?.isEarliestBid,
        isBestExperience: findCarrier?.isBestExperience
      }
    }
  }

  isGroupByPool(item): boolean {
    return (<string>item.key)?.startsWith('pool-');
  }

  private addTagsData(listData, earliestBid, lowestPrice, bestExperience) {
    let newData = []
    for (let item of listData) {
      //isEarliestBid
      if (item?.when && new Date(item.when).getTime() == earliestBid) item.isEarliestBid = true
      //isLowestPrice
      if (item?.price && item?.price == lowestPrice) item.isLowestPrice = true
      //bestExperience
      if (item?.countLane && item?.countLane == bestExperience) item.isBestExperience = true

      newData.push(item)
    }
    return newData
  }

  // Những thằng nào nằm trong cùng 1 dedicated pool thì gom thành 1 nhóm
  private groupByDedicatedPool(bidDetail) {
    const carriers: any[] = this.carriers ?? [];
    const pools: any[] = bidDetail.pools ?? [];
    const dedicatedPools = {};
    for (let pool of pools) {
      if (pool.isDedicatedPool) {
        dedicatedPools[pool.id] = pool;
      }
    }
    const arr = [];
    const dicGroups = {};
    for (let carrier of carriers) {
      carrier.key = `carrier-${carrier.carrierId}`;     // dùng cho TreeNodeInterface
      let isDedicatedPool = false
      let poolData: any = { isDedicatedPool: false }
      if (carrier.poolId && dedicatedPools[carrier.poolId]) {
        isDedicatedPool = true
        poolData = {
          isDedicatedPool: true,
          poolName: dedicatedPools[carrier.poolId].name
        }
      }
      arr.push({ ...carrier, ...poolData });
    }

    if (!Utils.isObjectNotEmpty(dicGroups)) return arr;

    let groups = this.sortPoolGroups(Object.values(dicGroups));
    for (let group of groups) {
      group.children = this.sortCarriers(group.children);
    }
    return [...groups, ...arr];
  }

  private sortPoolGroups(pools: any[]) {
    return pools
  }

  private sortCarriers(carriers: any[]) {
    const sortPriceDirection = this.sortPriceDirection;
    return _(carriers).chain()
      .sortBy(function (patient) {
        return patient.title;
      })
      .sortBy(function (patient) {
        return (patient.status) * -1; //reverse sort => sort status desc
      })
      .sortBy(function (patient) {
        if (sortPriceDirection == 'descend') return (patient.price || 0) * -1;
        return (patient.price || 0); //reverse sort => sort price desc
      })
      .sortBy(function (patient) {
        return patient.lastSent ? -1 : 0; //sort lastSent. những carrier đã sent email sẽ lên trước.
      })
      .sortBy(function (patient) {
        return (patient.state || 0) * -1; //reverse sort => sort tag desc
      })
      .value();
  }


  private getCounterBidHistory(item){
    let answers = item?.bidCounterOffer?.answers || [];
    if(item?.price && !item?.bidCounterOffer?.answers && !item?.bidCounterOffer?.answers.length){
      answers.unshift({
        price: item.price,
        entity: WarpConst.BidCounterOfferEntities.carrier,
        update: item?.update
      })
    }
    return answers;
  }

  private onProcessRouteInfo(bidDetail) {
    this.basePrice = bidDetail?.basePrice //giá offer
    this.acceptedPrice = bidDetail?.job?.assignedCarrier?.cost?.grandTotal
    this.acceptedCarrier = bidDetail?.job?.carrier
    const findCarrier = this.carriers?.find(carrier => carrier?.id == this.acceptedCarrier?.id)
    if (findCarrier) {
      this.acceptedCarrier = {
        ...this.acceptedCarrier,
        isLowestPrice: findCarrier?.isLowestPrice,
        isEarliestBid: findCarrier?.isEarliestBid,
        isBestExperience: findCarrier?.isBestExperience
      }
    }
  }

  ngOnDestroy() {
    this.bidDetailSubscription?.unsubscribe();
    this.carriersSubscription?.unsubscribe();
  }

  reloadData() {
    this.carrierSaleDetailService.emitGetDataBid();
  }
 
}