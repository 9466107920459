import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';

import { TruckLoadEvolutionComponent } from './index';
import { NetworkChartComponent } from './components/network-chart';
import { PeakInfoComponent } from './components/peak-info';

@NgModule({
    declarations: [
        TruckLoadEvolutionComponent,
        NetworkChartComponent,
        PeakInfoComponent
    ],
    imports: [
        CommonModule,
        NzIconModule,
        NzTableModule
    ],
    exports: [
        TruckLoadEvolutionComponent
    ]
})
export class TruckLoadEvolutionModule { }
