import { Component, Input } from "@angular/core";
import { DispatchService } from "../../dispatchService";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "@abstract/BaseComponent";
import { DateUtil } from "@services/date-utils";
import { Const } from "@const/Const";
import { StopItemBarcodeScanStatus, StopItemForDispatch } from "@wearewarp/types-server-admin/stop";
import { ResponseWhenBy_User } from "@wearewarp/types/rest-api/common";
import { getLinkDashboard } from "@services/routerlink";
import { getNameAndLink } from "@services/when-by";

interface BarcodeData {
  value: string,
  userName: string,
  link: string,
  time: string,
  status: string,
  missingReason?: string,
  labelScannedBy: string,
}
@Component({
  selector: "[item-barcode]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class ItemBarcode extends BaseComponent {

  private mapBarcodeScanStatus = {
    notScanned: 'NOT_SCANNED', // item pending to be scanned (at pickup / at dropoff)
    scanned: 'SCANNED', // barcode scanning & checkin was successful
    manuallyScanned: 'MANUALLY_SCANNED', // Driver was not able to scan the barcode due to barcode issue / device issue, but checkin is successful when driver entered the barcode string manually using device keypad.
    damaged: 'DAMAGED', // Item box / item is damaged and not in a position to pick / drop.
    notFound: 'NOT_FOUND', // Item not found during pickup.
    missing: 'MISSING', // Item is missing during dropoff; but pickup checkin was successful.
    error: 'error' // Device / system error.
  }

  // New logic
  private mapStatus(itemScan: StopItemBarcodeScanStatus): string {
    switch (itemScan.status) {
      case 'scanned': return itemScan.isManual ? 'MANUALLY_SCANNED' : 'SCANNED';
      case 'notScanned': return 'MISSING';
      default: return itemScan.status;
    }
  }

  @Input() itemInfo: any;
  @Input() timezone: any;
  @Input() stopItem: StopItemForDispatch
  
  public listBarcodeData: BarcodeData[] = [];

  constructor(protected activatedRoute: ActivatedRoute, protected dispatchService: DispatchService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges() {
    this.listBarcodeData = [];
    for (let itemBarcode of this.stopItem?.barcodes) {
      const barcode = itemBarcode.value;
      const timezone = this.getDisplayTimezone();
      if (itemBarcode.reported) {
        const mainUser: ResponseWhenBy_User = itemBarcode.reported?.by;
        const linkedDriver: ResponseWhenBy_User = (itemBarcode.reported?.linkedEntities ?? []).filter(it => it.entity == 'drivers')[0];
        const user = linkedDriver ?? mainUser;
        const {name, link} = getNameAndLink(user, {includeEntity: true});
        let missingReason = itemBarcode.status == 'notScanned' ? 'Missing due to stop completed without scanning.' : '';
        if (itemBarcode.status == 'scanned' && itemBarcode.isScanAtDropoff) {
          missingReason = 'Not scanned at pickup location but scanned at dropoff location.';
        }
        this.listBarcodeData.push({
          value: barcode,
          userName: name,
          time: `${this.getDisplayTime(itemBarcode.reported.when)} ${timezone}`,
          link: link,
          status: this.mapStatus(itemBarcode),
          missingReason,
          labelScannedBy: itemBarcode.status == 'notScanned' ? 'Reported by' : 'Scanned by',
        });
      } else {
        const info = this.findBarcodeInStopInfo(barcode);
        const userName = this.getFullName(this.itemInfo?.userInfo);
        const driverName = this.getDriverName(this.itemInfo?.driverInfo);
        const hyperLinkCreatedBy = this.getHyperLinkCreatedBy()
        this.listBarcodeData.push({
          value: barcode,
          userName: driverName || userName,
          link: hyperLinkCreatedBy,
          time: `${this.getDisplayTime()} ${timezone}`,
          status: info?.status,
          labelScannedBy: 'Scanned by',
        });
      }
    }
  }

  private getDisplayTime(timeStr?: string) {
    return DateUtil.displayLocalTime(timeStr ?? this.itemInfo?.insert?.when, {
      timezone: this.timezone,
      format: "MM/DD/YY h:mm A",
    })
  }

  private getDisplayTimezone(){
    return DateUtil.timezoneStandardToUsShort(this.timezone)
  }

  private findBarcodeInStopInfo(barcode: string) {
    const barcodeScaned = this.itemInfo?.itemInfo?.items?.map(it => it.barcodes).flat() || [];
    const check = barcodeScaned.find(it => it.barcode == barcode);
    return check;
  }

  getStatusColor(status) {
    switch(status) {
      case this.mapBarcodeScanStatus.notScanned:
        return 'grey';
      case this.mapBarcodeScanStatus.manuallyScanned:
      case this.mapBarcodeScanStatus.scanned:
      case "succeeded":
        return 'green';
      case this.mapBarcodeScanStatus.damaged:
      case this.mapBarcodeScanStatus.notFound:
      case this.mapBarcodeScanStatus.missing:
      case this.mapBarcodeScanStatus.error:
        return 'red';
      default: 
        return 'grey';
    }
  }

  private getHyperLinkCreatedBy() {
    if(this.itemInfo?.driverInfo) return `${Const.routeAdminDriverList}/${this.itemInfo.driverInfo.id}`;
    if(this.itemInfo?.userInfo) return `${Const.routeAdminUserList}/${this.itemInfo.userInfo._id}`;
    return null;
  }

}
