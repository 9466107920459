import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FilterLayout, FilterFieldDeclaration } from "@app/admin/components/filter-layout";
import { DateUtil } from "@services/date-utils";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { DrawerService } from "@app/drawers/drawer.service";
import { FilterModalNeedAppointment } from "./filter-modal-need-appointment/filter-modal-need-appointment";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { BaseComponent } from "@abstract/BaseComponent";

@Component({
  selector: '[shipment-queue-filter-need-appointment]',
  templateUrl: './need-appointment.html',
  styleUrls: ['./style.scss']
})
export class ShipmentQueueFilterNeedAppointment extends BaseComponent implements OnInit, AfterViewInit {
  warpIdInputChange = new Subject<any>();
  private warpIdInputChangeSub;
  listTaskTypes = [
    { label: 'Pickup', value: 'PICKUP' },
    { label: 'Dropoff', value: 'DROPOFF' }
  ];
  constructor() {
    super();
    this.warpIdInputChangeSub = this.warpIdInputChange
      .pipe(debounceTime(150))
      .subscribe((value) => {
        this.filter.onChangeWithWarpIdString(value);
      });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.filter.ngHostAfterViewInit(this.getFilterFields())
    this.filter.drawerComponentRef = FilterModalNeedAppointment;
    setTimeout(() => {
      const code = this.filter.model?.code;
      if (code?.[0] && !this.filter.model?.warpId) {
        this.filter.setItemValue('warpId', code[0]);
      }
    }, 1000);
  }

  // for filter layout
  @ViewChild('filter') filter: FilterLayout;
  @ViewChild('tplFilter_shipmentWarpId') tplFilter_shipmentWarpId: TemplateRef<any>;
  @ViewChild('tplFilter_customer') tplFilter_customer: TemplateRef<any>;
  @ViewChild('tplFilter_type_customer') tplFilter_type_customer: TemplateRef<any>;
  @ViewChild('tplFilter_delivery_time') tplFilter_delivery_time: TemplateRef<any>;
  @ViewChild('tplFilter_contact_method') tplFilter_contact_method: TemplateRef<any>;
  @ViewChild('tplFilter_appointment_status') tplFilter_appointment_status: TemplateRef<any>;
  @ViewChild('tplFilter_appointment_reattempt') tplFilter_appointment_reattempt: TemplateRef<any>;
  @ViewChild('tplFilter_appointment_task_type') tplFilter_appointment_task_type: TemplateRef<any>;

  onFilterChange(key: string, value: any) {
    if (key === 'warpId')
      this.warpIdInputChange.next({key, value});
    else
      this.filter.onChangeWithWarpIdString({key, value});
  }

  getFilterFields(): FilterFieldDeclaration[] {
    return [
      {
        key: 'warpId',
        declaration: { label: 'Search by WARP ID', placeHolder: 'Enter WARP ID', notAcceptEmpty: true },
        templateInput: this.tplFilter_shipmentWarpId
      },
      {
        key: 'clientId',
        declaration: { label: 'Customer', placeHolder: '', notAcceptEmpty: true },
        templateInput: this.tplFilter_customer,
        shouldHidden: true
      },
      {
        key: 'clientFilterType',
        declaration: { label: '', placeHolder: '', notAcceptEmpty: true, initialValue: 'include' },
        templateInput: this.tplFilter_type_customer,
        shouldHidden: true
      },
      {
        key: 'pickFrom',
        declaration: { label: 'Pickup Date Range', placeHolder: 'From date', notAcceptEmpty: true, getValue: DateUtil.getDateFrom },
        templateInput: this.tplFilter_delivery_time,
        shouldHidden: true
      },
      {
        key: 'pickTo',
        declaration: { label: '', placeHolder: 'To date', notAcceptEmpty: true, getValue: DateUtil.getDateTo },
        templateInput: this.tplFilter_delivery_time,
        shouldHidden: true
      },
      {
        key: 'dropFrom',
        declaration: { label: 'Delivery Date Range', placeHolder: 'From date', notAcceptEmpty: true, getValue: DateUtil.getDateFrom },
        templateInput: this.tplFilter_delivery_time,
        shouldHidden: true
      },
      {
        key: 'dropTo',
        declaration: { label: '', placeHolder: 'To date', notAcceptEmpty: true, getValue: DateUtil.getDateTo },
        templateInput: this.tplFilter_delivery_time,
        shouldHidden: true
      },
      {
        key: 'reattemptCount',
        declaration: { label: 'Re-attempt', placeHolder: '', notAcceptEmpty: true },
        templateInput: this.tplFilter_appointment_reattempt,
        shouldHidden: true
      },
      {
        key: 'isPickup',
        declaration: { label: 'Appointments by', placeHolder: 'Pickup', notAcceptEmpty: true, initialValue: false },
        templateInput: this.tplFilter_appointment_task_type,
        shouldHidden: true
      },
      {
        key: 'isDropoff',
        declaration: { label: '', placeHolder: 'Dropoff', notAcceptEmpty: true, initialValue: false },
        templateInput: this.tplFilter_appointment_task_type,
        shouldHidden: true
      },
      {
        key: 'appointmentStatus',
        declaration: { label: 'Status', placeHolder: '', notAcceptEmpty: true },
        templateInput: this.tplFilter_appointment_status
      },
      {
        key: 'appointmentContact',
        declaration: { label: 'Last contact method', placeHolder: '', notAcceptEmpty: true },
        templateInput: this.tplFilter_contact_method
      },
    ]
  }

  getPlaceholder(key: string) {
    return this.filter.getPlaceHolder(key);
  }

  ngOnDestroy() {
    if (this.warpIdInputChangeSub) {
      this.warpIdInputChangeSub.unsubscribe();
    }
  }

  shouldShowStatus(type) {
    let query = Utils.parseQueryStringFromUrl(this.router.url);
    if (query?.tab == "awaitingAppointment") {
      if (type == Const.AppointmentStatus.requested) return true;
      else return false;
    }
    return true;
  }
}
