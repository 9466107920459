import { Component, Input } from "@angular/core";
import { DispatchService } from "../../dispatchService";
import { ActivatedRoute } from "@angular/router";
import TaskEntity from "../../entity/TaskEntity";
import RouteEntity from "../../entity/RouteEntity";
import { Const } from "@const/Const";
import { DeliveryInfo, Shipment, ShipmentItem } from "@wearewarp/types/data-model";
import { FormDataUpdateTaskStatus, ModalHelper, UpdateTaskStatus } from '@wearewarp/ng-antd'
import { Observable } from "rxjs";
import { MasterData } from "@services/master.data";
import { environment } from "@env/environment";
import { Utils } from "@services/utils";
import { BaseComponent } from "@abstract/BaseComponent";
import { DateUtil } from "@services/date-utils";
import { BizUtil } from "@services/biz";
import { DialogService } from "@dialogs/dialog.service";
import { RouteReturn } from "../init-return/task-return";
import { AddItemQuick } from "../add-item-quick";
import { DrawerService } from "@app/drawers/drawer.service";
import { RoutePOD } from "../pod";
import ShipmentEntity from "../../entity/ShipmentEntity";
import StopEntity from "@app/admin/dispatch/entity/StopEntity";
import { WarpId } from "@wearewarp/universal-libs";
import { ShipmentItemStatus } from "@wearewarp/types";
import { StopItemForDispatch } from "@wearewarp/types-server-admin/stop";
import { getDriver, getNameAndLink } from "@services/when-by";

interface DriverAddedInfo {
  name: string,
  link: string,
  time: string,
  label: string,
}

@Component({
  selector: 'dispatch-shipment-item',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class DispatchShipmentItem extends BaseComponent {
  public isLoading = true;
  public displayInfo: any;
  protected route: RouteEntity;
  @Input() task: TaskEntity;
  @Input() stop: StopEntity;
  @Input() isVisiblePOD: boolean = false;
  @Input() isEnableUpdateStatus: boolean = true;
  @Input() stopItems: StopItemForDispatch[] = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    private dispatchService: DispatchService,
    private modalHelper: ModalHelper
  ) {
    super(activatedRoute)
  }

  ngOnInit(): void {
    this.subscription.add(
      this.dispatchService.loading.subscribe(value => {
        this.isLoading = value;
      })
    )
    this.subscription.add(
      this.dispatchService.routeData$.subscribe(() => {
        this.route = this.dispatchService.getRoute();
        if (this.route?.isExternalRoute()) {
          this.isEnableUpdateStatus = false;
        }
      })
    )
  }

  ngOnChanges() {
    this.buildDisplayInfo();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  private stopTimezone = '';
  private async buildDisplayInfo() {
    if (!this.task || !this.stop) return;
    let shipment = this.task.getShipment();
    let taskType = this.task.getType();
    let deliveryInfo: DeliveryInfo;
    let locationType = '';
    // nếu stop đã chung pickup thì hiển thị pickInfo là ko cần thiết => hiển thị dropInfo
    if (taskType == Const.TaskType.PICKUP) {
      deliveryInfo = shipment.getDropInfo();
      locationType = 'Dropoff';
    } else if (taskType == Const.TaskType.DROPOFF) {
      deliveryInfo = shipment.getPickInfo();
      locationType = 'Pickup';
    } 
    this.displayInfo = {
      shipment: shipment?.toJSON(),
      shipmentId : shipment.getId(),
      shipmentWarpId : shipment.getCodeText(),
      locationType: locationType,
      clientName: shipment.getClient()?.name,
      locationName: deliveryInfo?.locationName,
      addressText: MasterData.getAddressText(deliveryInfo?.addr),
      requiresAppointment: deliveryInfo?.requiresAppointment,
      appointmentInfo: deliveryInfo?.appointmentInfo,
      
      cssClassStatus: this.getTaskStatusCssClass(this.task?.toJSON()),
      status:  this.getTaskStatus(this.task?.toJSON()),
      trackingCode: this.getTrackingCodeText(shipment),
      code: shipment.getCode(),
      trackingLink: this.getTrackingLink(shipment.getCode() ?? shipment.getTrackingCode()),
      totalPod: this.task.getTotalPod(),
      podNotConfirmed: this.task.getPodNotConfirmed(),
      failedReason: this.task.getFailedReason(),
      refNums: this.getRefNumsByTaskType(this.task),
      orderRefNums: this.getOrderRefNums(this.task),
      timezone: this.stop.getTimezone(),
      scanningBarcodeResults: this.stop.getScanningBarcodeResults(),
    }
    this.stopTimezone = deliveryInfo?.addr?.metadata?.timeZoneStandard;
    this.displayInfo.items = shipment.getItems().map((item, index) => this.buildDisplayInfoItem(item, index));

    if (deliveryInfo?.requiresAppointment && deliveryInfo?.appointmentInfo?.from) {
      let time = deliveryInfo.appointmentInfo.from;
      let timeTo = deliveryInfo.appointmentInfo.to;
      this.displayInfo.scheduled = this.displayWindow({time, timeTo, timezone: this.stopTimezone})
    } else {
      let window = deliveryInfo?.windows?.[0];
      let time = window?.from;
      let timeTo = window?.to;
      this.displayInfo.scheduled = this.displayWindow({time, timeTo, timezone: this.stopTimezone})
    }
  }

  private buildDisplayInfoItem(item, index) {
    const stopItem: StopItemForDispatch = this.stopItems.filter(it => it.id == item.id)[0];
    const itemCompleted = stopItem.barcodes.filter(it => it.status != null).length > 0;
    const info: any = {
      id: item.id,
      itemCompleted,
      name: this.getItemName(item, index),
      qtyNumer: item.qty || 0,
      qtyTxt: this.getItemQty(item),
      barcodeTxt: this.barcodeText(item?.barcodes),
      barcodes: item?.barcodes || [],
      stopItem: stopItem,
      totalWeight: this.getItemTotalWeight(item),
      weightPerUnit: this.getItemWeightPerUnit(item),
      size: this.getItemSize(item),
      status: this.getItemStatus(item.status),
      isMissing: item.isMissing,
      isScanned: this.isScannedItem(item),
      attachdedFiles: item?.attachdedFiles?.map(it => { 
        return {
          ...it,
          url: this.attachedFileUrl(it)
        }
      }) ?? [],
    }
    if (stopItem.driverAdded) {
      let label = 'Added by: ';
      if (stopItem.driverAdded.stopType) {
        label = `Added at ${stopItem.driverAdded.stopType} location by: `;
      }
      const time = `${DateUtil.displayLocalTime(stopItem.driverAdded.when, {timezone: this.stopTimezone, format: "MM/DD/YY h:mm A"})} ${DateUtil.timezoneStandardToUsShort(this.stopTimezone)}`;
      const driver = getDriver(stopItem.driverAdded);
      const {name, link} = getNameAndLink(driver, {includeEntity: true});
      info.driverAddedInfo = <DriverAddedInfo>{label, name, link, time};
    }
    return info;
  }

  isScannedItem(item): boolean {
    const barcodeScaned = this.displayInfo.scanningBarcodeResults?.itemInfo?.items?.map(it => it.barcodes).flat() || [];
    const check = barcodeScaned.find(it => (item?.barcodes || []).includes(it.barcode) && ['MANUALLY_SCANNED', 'SCANNED'].includes(it.status));
    return !!check;
  }

  isShowCanceledReason() {
    return this.task.getStatus() == Const.TaskStatus.canceled;
  }

  getCanceledReason() {
    const cancelCode = this.task.getCancelReasonCode();
    if (cancelCode) {
      return this.getOrderCancelReason(cancelCode);
    }
    return 'N/A';
  }

  onBtnUpdateStatus() {
    if(!this.isEnableUpdateStatus) return;
    const tasks: any = [this.task?.toJSON()];
    const shipment = this.task.getShipment()?.toJSON();
    if (this.task.getStatus() == Const.TaskStatus.pickupFailed) return;

    for(let index in tasks) {
      let task = tasks[index];
      if(task?.tonu) continue;
      const carrierCostServiceOptions = this.dispatchService.getRoute()?.getCarrierCost()?.serviceOptions || [];
      let serviceCarrierHaveTonu = carrierCostServiceOptions.find(it => it._id === 'tonu');
      let shipmentId = task.shipmentId;
      if(shipmentId !== shipment.id) continue;
      let shipmentCostServiceOptions = shipment?.cost?.serviceOptions || [];
      let serviceCustomerHaveTonu = shipmentCostServiceOptions.find(it => it._id === 'tonu');
      if(!serviceCustomerHaveTonu && !serviceCarrierHaveTonu) continue;
      let data = {
        ...(tasks[index]['statusChangeLog'] || {}),
        canceled: {
          info: {
            customerTONU: serviceCustomerHaveTonu?.total,
            carrierTONU: serviceCarrierHaveTonu?.total
          }
        }
      }
      tasks[index]['statusChangeLog'] = data
    }

    let taskType = this.task.getType();
    UpdateTaskStatus.openModal(this.modalHelper, {
      nzTitle: 'Update Status',
      nzComponentParams: {
        delayCodeOptions: [Const.TaskType.PICKUP, Const.TaskType.DROPOFF].includes(<any>taskType) ? MasterData.getDelayCodesByType(taskType) : MasterData.getDelayCodes(),
        isAssignedCarrier: this.dispatchService.getRoute()?.isAssignedCarrier(),
        tasks: tasks,
        shipments: [shipment],
        showWarpIdShipment: (s) => WarpId.showShipmentCode(s),
        onSave: (data) => {
          return new Observable(o => {
            this.dispatchService.updateTaskStatus({
              tasks,
              data: data
            }).then(() => {
              this.dispatchService.refresh();
              o.next();
            }).catch(e => {
              o.error(e)
            })
          }).subscribe();
        }
      }
    })
  }

  public getRouterLinkWarp(item) {
    if ([Const.ShipmentTransitType.leg, Const.ShipmentTransitType.layover].includes(item.shipmentTransitType)) {
      let orderId = item.metadata?.parent?.orderId || '';
      return [Const.routeAdminOrderList, orderId];
    }
    if(item.orderId) {
      return [Const.routeAdminOrderList, item.orderId];
    }
    return [Const.routeAdminOrderList];
  }

  public getRouterLinkFragmentWarp(item: Shipment) {
    if (item.shipmentTransitType == Const.ShipmentTransitType.leg) {
      return undefined;
    }
    return item.id || (<any>item).shipmentId;
  }

  public copyTrackingCode() {
    let link = this.displayInfo.trackingLink;
    Utils.copyTextToClipboard(link, (e) => {
      if (e) {
        this.showErr("Cannot copy tracking link to clipboard");
      } else {
        this.showSuccess(
          "Tracking link has already been copied to the clipboard"
        );
      }
    });
  }
  public getTrackingLink(trackingCode) {
    return `${environment.trackingWebUrl}/${trackingCode}`;
  }

   // time, timeTo are ISO string 2022-03-29T18:19:10.000Z
   private displayWindow(obj: { time: string, timeTo: string, timezone: string }) {
    if (!obj) return 'N/A';
    let formatDateTime = Const.FORMAT_GUI_DATETIME_V3;
    let timeFrom = obj.time;
    let timeTo = obj.timeTo;
    if (!timeFrom || !timeTo) return 'N/A';
    timeFrom = DateUtil.displayLocalTime(timeFrom, { timezone: obj.timezone, format: formatDateTime });
    timeTo = DateUtil.displayLocalTime(timeTo, { timezone: obj.timezone, format: formatDateTime });
    let arr1 = timeFrom.split(',');
    let arr2 = timeTo.split(',');
    let isSameDay = arr1[0] == arr2[0];
    if (isSameDay) {
      timeTo = arr2[1]?.trim();
    }
    let str = `${timeFrom || 'N/A'} - ${timeTo || 'N/A'}`;
    let tzShort = DateUtil.timezoneStandardToUsShort(obj.timezone);
    if (tzShort) {
      str += ` ${tzShort}`;
    }
    return str;
  }

  public naText = 'N/A';
  getItemName(item: ShipmentItem, index: number): string {
    return item.name || ('Item ' + (index+1));
  }

  getItemQty(item: ShipmentItem): string {
    const quantity = item?.qty || this.naText
    const unit = item?.qtyUnit || this.naText
    return `${quantity} ${unit}`
  }

  getItemSize(item: ShipmentItem): string {
    return BizUtil.getItemSizeDesc(item);
  }

  getItemTotalWeight(item: ShipmentItem): string {
    const quantity = item?.qty || 1;
    const weight = item?.weightPerUnit ?? 0;
    const unit = item?.weightUnit ?? '';
    return `${Utils.roundNumber(weight * quantity, 0)} ${unit}`
  }

  getItemWeightPerUnit(item: ShipmentItem): string {
    const weight = item?.weightPerUnit ?? 0;
    const unit = item?.weightUnit ?? '';
    return `${weight} ${unit}`
  }

  private getRefNumsPickInfo(shipment: ShipmentEntity): string[] {
    if(!shipment) return [];
    const refNums = shipment?.getPickInfo()?.refNums;
    return Utils.uniqElementsArray(refNums) || [];
  }

  private getRefNumsDropInfo(shipment: ShipmentEntity): string[] {
    if(!shipment) return [];
    let refNums = shipment?.getDropInfo()?.refNums;
    return Utils.uniqElementsArray(refNums) || [];
  }

  private getRefNumsByTaskType(task: TaskEntity): string[]{
    if(!task) return [];
    const taskType = task.getType();
    const shipment = task.getShipment();
    if(taskType == Const.TaskType.PICKUP) {
      return this.getRefNumsPickInfo(shipment);
    }
    return this.getRefNumsDropInfo(shipment);
  }

  private getOrderRefNums(task: TaskEntity): string[]{
    if(!task) return [];
    const shipment = task.getShipment();
    if(!shipment) return [];
    const parentShipment = shipment?.getParentInfo();
    if(!parentShipment || !parentShipment['deliveryInfos']) return [];
    let refNums = [];
    parentShipment['deliveryInfos'].forEach((task) => {
      if(task.type == Const.TaskType.PICKUP || task.type == Const.TaskType.DROPOFF) {
        refNums = refNums.concat(task.refNums || []);
      }
    })
    return Utils.uniqElementsArray(refNums) || [];
  }

  public shouldInitiateReturn() {
    if (this.task.getType() != Const.TaskType.DROPOFF) {
      return false;
    }
    // nếu đã tồn tại returntask thi ko tạo nữa
    if (this.task.isExistsReturn()) {
      return false;
    }
    return this.task.getStatus() == Const.TaskStatus.failed;
  }

  public get isShowFailedReason() {
    return this.task.getStatus() == Const.TaskStatus.failed || this.task.getStatus() == Const.TaskStatus.canceled;
  }

  public onBtnInitReturn() {
    if (!this.shouldInitiateReturn()) return;
    DialogService.openDialog(RouteReturn, {
      nzComponentParams: {
        jobId: this.dispatchService?.getRoute()?.getId(),
        taskIds: [this.task.getId()],
        shipmentWarpIds: [this.task.getShipmentCodeText()],
        onSave: data => this.createReturnTask(data),
        onSubmitSucceeded: resp => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: "modal-no-padding modal-task-return",
      nzCentered: true,
      nzTitle: null,
      nzClosable: false,
    });
  }

  private createReturnTask(data: any) {
    const taskIds = [this.task.getId()];
    let url = Const.APIV2(`${Const.APIURI_TASKS}/batchUpdate`);
    return this.api.POST(url, {
      action: 'initiateReturn',
      data: taskIds.map(taskId => ({
        id: taskId,
        data: data
      }))
    });
  }

  public onBtnAddPod() {
    let taskIds = [this.task.getId()];
    DrawerService.openDrawer1(RoutePOD, {
      nzContentParams: {
        taskIds: taskIds,
        stop: this.stop,
        shipment: this.displayInfo.shipment,
        jobId: this.dispatchService.getRoute()?.getId(),
        openUploadForm: true,
        refreshData: data => {
          this.dispatchService.refresh();
        }
      },
      nzWidth: 400,
      nzWrapClassName: 'wrap-drawer route-pod-drawer',
    });
  }

  public onBtnViewPod() {
    let taskIds = [this.task.getId()];
    DrawerService.openDrawer1(RoutePOD, {
      nzContentParams: {
        taskIds: taskIds,
        stop: this.stop,
        shipment: this.displayInfo.shipment,
        jobId: this.dispatchService.getRoute()?.getId(),
        refreshData: data => {
          this.dispatchService.refresh();
        }
      },
      nzWidth: 400,
      nzWrapClassName: 'wrap-drawer route-pod-drawer',
    });
  }

  isShowRef(): boolean{
    return this.displayInfo.refNums?.length > 0;
  }

  isShowOrderRef(): boolean{
    return this.displayInfo.shipment.shipmentTransitType == Const.ShipmentTransitType.leg || this.displayInfo.shipment.shipmentTransitType == Const.ShipmentTransitType.layover;
  }
  


  private getTrackingCodeText(shipment: ShipmentEntity) {
    if (!!shipment.getCode() && !!shipment.getTrackingCode())
      return `${shipment.getCode()} (${shipment.getTrackingCode()})`;
    return shipment.getCode() ?? shipment.getTrackingCode();
  }

  getItemStatus(status: ShipmentItemStatus) {
    switch (status) {
      case Const.ItemStatus.pickupFailed:
        return 'Pickup Failed';
      case Const.ItemStatus.deliverFailed:
        return 'Deliver Failed';
      default:
        return '';
    }
  }

  isJobCompleted(): boolean {
    const routeStatus = this.dispatchService.getRoute().getStatus();
    return routeStatus == Const.JobStatus.completed || routeStatus == Const.JobStatus.canceled;
  }

  canAddNewItem(): boolean {
    return !this.isJobCompleted();
  }

  onReportMissing(item) {
    this.modalService.create({
      nzTitle: 'Report Missing',
      nzContent: `
      ${item.name}, ${item.qtyTxt}, ${item.totalWeight}, ${item.size} <br/><br/>
      Barcode: ${item.barcodeTxt} <br/><br/>
      Are you sure you want to mark this item is missing?
      `,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'OK',
      nzOnOk: () => this.confirmMarkMissing(item.id),
      nzCancelText: 'Cancel'
    });  
  }

  private confirmMarkMissing(itemId) {
    const url = Const.APIV2(`shipment_items/${itemId}/missing`)
    this.api.PUT(url, { isMissing: true, jobId: this.route.getId(), stopId: this.stop.getId(), stopType: this.stop.getType() }).subscribe((res) => {
      this.displayInfo.items = this.displayInfo.items.map(item => {
        if (item.id === itemId) {
          item.isMissing = true
          item.status = this.getItemStatus(res.data?.status)
        }
        return item;
      });
    }, err => {
      this.showErr(err);
    })
  }

  onAddBarcode() {
    DialogService.openFormDialog1(AddItemQuick, {
      nzComponentParams: {
        onSave: data => this.createNewItem(data),
        onRefreshDetailJob: () => {
          this.dispatchService.refresh()
        }
      },
      nzClassName: "modal-no-padding modal-add-item-quick",
      nzCentered: true,
      nzTitle: null,
      nzClosable: false,
    });
  }

  private createNewItem(data: any) {
    const shipmentId = this.task.getShipmentId();
    if(!shipmentId) this.showErr('Shipment not found');
    let url = Const.APIV2(`${Const.APIURI_SHIPMENTS}/add_items_to_shipment`);
    return this.api.POST(url, {
      shipmentId,
      items: [data]
    });
  }
}
