import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { ApiService } from "@services/api.service";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import { Stop, WhenBy } from "@wearewarp/types/data-model";

type ByCollectionEnum = 'users' | 'drivers' | 'clients' | 'carriers';
interface IByData {
    collection: ByCollectionEnum,
    id?: string,
    warpId?: number,
}

@Component({
    selector: 'manual-complete-task-form',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class ManualCompleteTaskForm extends BaseFormDialog1 {
    private _job: any;
    @Input() stop?: Stop;
    @Input() trackingTaskType: any;

    @Input() set job(value: any) {
        this._job = value;
        this.getData();
    }

    get job() {
        return this._job;
    }

    public onOk: (data) => void = () => { }

    constructor() {
        super();
    }

    private async processData(params: WhenBy) {
        if (params.byCarrierId) {
            let byData: IByData = { collection: 'carriers' };
            if (Utils.isNumber(params.byCarrierId)) byData.warpId = params.byCarrierId;
            if (params.byId) byData.id = params.byId;
            const res = await this.getDataFromAPI(byData);
            return res;
        }
        if (params.byDriverId) {
            let byData: IByData = { collection: 'drivers' };
            if (Utils.isNumber(params.byDriverId)) byData.warpId = params.byDriverId;
            if (params.byId) byData.id = params.byId;
            const res = await this.getDataFromAPI(byData);
            return res;
        }
        if (params.by) {
            let byData: IByData = { collection: 'users' };
            if (Utils.isNumber(params.by)) byData.warpId = params.by;
            if (params.byId) byData.id = params.byId;
            const res = await this.getDataFromAPI(byData);
            return res;
        }
    }

    async getDataFromAPI(params: IByData) {
        let url = `${Const.APIURI_USERS}/get-when-by-info`;
        const response = await ApiService.instance.POST(url, params).toPromise();
        return response?.data;
    }

    $formatDate = (date: Date) => {
        return DateUtil.displayLocalTime(date, { format: 'MM/DD/YYYY hh:mm A', timezone: 'America/New_York' });
    }

    public assignedDriverBy: any;
    public confirmedReadyBy: any;
    public acceptedLoadTenderBy: any;
    public hasOneDriver: boolean = false;
    private async getData() {
        const assignedDriverBy = this.job.assignedDriver ? this.job.assignedDriver?.update : null;
        if (assignedDriverBy) {
            const result = await this.processData(assignedDriverBy);
            this.assignedDriverBy = { ...assignedDriverBy, ...result }
        }
        const confirmedReadyBy = this.job.assignedCarrier?.confirmedReadyBy ? this.job.assignedCarrier?.confirmedReadyBy : null;
        if (confirmedReadyBy) {
            const result = await this.processData(confirmedReadyBy);
            this.confirmedReadyBy = { ...confirmedReadyBy, ...result }
        }
        const acceptedLoadTenderBy = this.job.assignedCarrier?.acceptedLoadTenderBy ? this.job.assignedCarrier?.acceptedLoadTenderBy : null;
        if (acceptedLoadTenderBy) {
            const result = await this.processData(acceptedLoadTenderBy);
            this.acceptedLoadTenderBy = { ...acceptedLoadTenderBy, ...result }
        }

        const carrierId = this.job.assignedCarrier?.carrierId;

        if(carrierId) {
            this.hasOneDriver = this.job?.assignedDriver?.driverId ? true : false;
            const url = `${Const.APIURI_DRIVERS}?status=1&limit=-1&carrierId=${carrierId}`;
            const result = await this.api.GET(url).toPromise();
            console.log("result", result)
            this.hasOneDriver = result?.data?.list_data?.length === 1 ? true : false;
        }
    }

    get titleText(): string {
        return this.isCompleteTask ? Const.JobTrackingTaskConfig[this.trackingTaskType].label || 'Mark this task as completed' : 'Task Instruction'
    }

    get isCarrierConfirmTask(): boolean {
        return this.trackingTaskType === Const.JobTrackingTaskConfig.CARRIER_CONFIRM.value;
    }

    get isRequiredAcceptLoadTender(): boolean {
        const isRequireCarrierAcceptLoadTender = this?.job?.settings?.isRequireCarrierAcceptLoadTender || false;
        return isRequireCarrierAcceptLoadTender
    }

    get isReady(): boolean {
        return this.job?.assignedCarrier?.isReady;
    }

    get hasDriver(): boolean {
        return this.job?.assignedDriver?.driverId;
    }

    get jobCode(): string {
        return this.job?.code;
    }

    get automationTrackingUrl(): string {
        return this.job?.automationTrackingUrl;
    }

    get loadTenderTaskComplete(): boolean {
        return this.job?.assignedCarrier?.loadTenderStatus !== 'accepted';
    }

    onBtnCopyTrackingLink(): void {
        Utils.copyTextToClipboard(this.automationTrackingUrl, () => {
            this.showSuccess('Tracking Link copied to ClipBoard')
        })
    }

    get descriptionText(): string {
        const texts = {
            [`${Const.JobTrackingTaskConfig.EMPTY_LOAD_CONFIRM.value}`]: `
                - Check if the driver/truck is ready for the route.<br />
                - Ask current location of driver/truck.
            `,
            [`${Const.JobTrackingTaskConfig.UPDATE_ETA.value}`]: `
                - Please ask the driver to provide ETA to the ${this.stop?.type === Const.TaskType.PICKUP ? 'Pickup' : 'Dropoff'} location.
            `,
            [`${Const.JobTrackingTaskConfig.ARRIVAL_CONFIRM.value}`]: `
                - Please ask the driver if he already arrived at the ${this.stop?.type === Const.TaskType.PICKUP ? 'Pickup' : 'Dropoff'} location.
            `,
            [`${Const.JobTrackingTaskConfig.DEPARTURE_CONFIRM.value}`]: `
                - Please ask the driver if he already ${this.stop?.type === Const.TaskType.PICKUP ? 'loaded at the Pickup' : 'unloaded at the Dropoff'} location.
            `,
            [`${Const.JobTrackingTaskConfig.CHECK_LIVE_CONNECTION.value}`]: `
                - Please ask the driver to use mobile app, turn on location tracking and update delivery status in the app.
            `
        }

        return texts[this.trackingTaskType] || ''
    }

    get isCompleteTask(): boolean {
        return [Const.JobTrackingTaskConfig.CARRIER_CONFIRM.value, Const.JobTrackingTaskConfig.EMPTY_LOAD_CONFIRM.value].includes(this.trackingTaskType)
    }

    protected formGroupDeclaration: FormGroupDeclaration = {
        note: { label: 'Note', placeHolder: 'Enter note here...' },
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    onBtnSave(): void {
        const formData = this.getFormData_JSON(true);
        this.updateSuccess(formData);
        this.closeDialog();
    }

    public isLoadingEmail: boolean = false;
    onBtnSendEmail(): void {
        this.isLoadingEmail = true;
        const url = Const.APIV2(`${Const.APIURI_JOBS}/${this.job.id}/send-email-checklist-task`);
        this.api.POST(url).subscribe(
            (resp) => {
                this.isLoadingEmail = false;
                this.showSuccess('The link has been sent to carrier successfully.');
            },
            (err) => {
                this.isLoadingEmail = false;
                this.showErr(err);
            }
        );
    }
}