import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import { WhenBy } from "@wearewarp/types/data-model";

interface IWarpWhenBy extends WhenBy {
  byUser?: any,
  byClient?: any,
  byCarrier?: any,
  byDriver?: any,
}

type ByCollectionEnum = 'users' | 'drivers' | 'clients' | 'carriers';
interface IByData {
  collection: ByCollectionEnum,
  id?: string,
  warpId?: number,
}
interface IDisplayInfo {
  createdWhen?: string,
  createdBy?: string,
  hyperLinkCreatedBy?: string,
}

@Component({
  selector: 'warp-when-by',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class WarpWhenBy implements OnInit {
  @Input() viewTemplate: TemplateRef<any>;

  private _model: IWarpWhenBy;
  @Input() get model(): IWarpWhenBy { return this._model }
  set model(value) {
    this._model = value;
    this.processData().then(() => {
      this.buildDisplayInfo();
      this.isLoading = false;
    });
  }

  public isLoading: boolean = true;
  public displayInfo: IDisplayInfo = {};

  constructor() {
  }

  get hasData(): boolean {
    return !!this.model;
  }

  async ngOnInit() {
  }

  private async processData() {
    if (!this.model) return;
    if (this.model.byClientId && (!this.model.byClient?.id || !this.model.byClient?.name)) {
      let byData: IByData = { collection: 'clients'};
      if (Utils.isNumber(this.model.byClientId)) byData.warpId = this.model.byClientId;
      if (this.model.byId) byData.id = this.model.byId;
      const res = await this.getDataFromAPI(byData);
      if (res) this.model.byClient = res;

    } else if (this.model.byCarrierId && (!this.model.byCarrier?.id || !this.model.byCarrier?.basicInfo?.name)) {
      let byData: IByData = { collection: 'carriers' };
      if (Utils.isNumber(this.model.byCarrierId)) byData.warpId = this.model.byCarrierId;
      if (this.model.byId) byData.id = this.model.byId;
      const res = await this.getDataFromAPI(byData);
      if (res) this.model.byCarrier = res;

    } else if (this.model.byDriverId && (!this.model.byDriver?.id || !this.model.byDriver?.firstName || !this.model.byDriver?.lastName || !this.model.byDriver?.fullName)) {
      let byData: IByData = { collection: 'drivers' };
      if (Utils.isNumber(this.model.byDriverId)) byData.warpId = this.model.byDriverId;
      if (this.model.byId) byData.id = this.model.byId;
      const res = await this.getDataFromAPI(byData);
      if (res) this.model.byDriver = res;

    } else if (this.model.by && (!this.model.byUser?.id || !this.model.byUser?.firstName || !this.model.byUser?.lastName || !this.model.byUser?.fullName)) {
      let byData: IByData = { collection: 'users' };
      if (Utils.isNumber(this.model.by)) byData.warpId = this.model.by;
      if (this.model.byId) byData.id = this.model.byId;
      const res = await this.getDataFromAPI(byData);
      if (res) this.model.byUser = res;

    }
  }

  async getDataFromAPI(params: IByData) {
    let url = `${Const.APIURI_USERS}/get-when-by-info`;
    const response = await ApiService.instance.POST(url, params).toPromise();
    return response?.data;
  }

  private buildDisplayInfo() {
    if (!this.model) return;
    this.displayInfo = {
      createdWhen: this.model?.when ? DateUtil.format(this.model?.when, 'MMM D, YYYY, h:mm a') : '',
      createdBy: this.getCreatedBy(this.model),
      hyperLinkCreatedBy: this.getHyperLinkCreateBy(),
    }
  }

  public getCreatedBy(data: IWarpWhenBy): string {
    if (data?.byClient) {
      return `${data.byClient.name} (customer)`
    } else if (data?.byDriver) {
      return `${this.getFullName(data?.byDriver)} (driver)`
    } else if (data?.byCarrier) {
      return `${data.byCarrier.basicInfo?.name} (carrier)`
    }
    if (data?.byUser?.id && Const.listWarpSystemIds.includes(data.byUser.id)) {
      return 'WARP System';
    }
    return this.getFullName(data?.byUser)
  }

  public getHyperLinkCreateBy(): string {
    if (this.model?.byClient) {
      return `${Const.routeAdminClientList}/${this.model.byClient.id}`;
    } else if (this.model?.byDriver) {
      return `${Const.routeAdminDriverList}/${this.model.byDriver.id}`;
    } else if (this.model?.byCarrier) {
      return `${Const.routeAdminCarrierList}/${this.model.byCarrier.id}`;
    }
    return `${Const.routeAdminUserList}/${this.model?.byUser?.id}`;
  }

  private getFullName(user: {fullName?: string, firstName?: string, lastName?: string}) {
    if (!user) return '';
    if (user.fullName) return user.fullName;
    return [user.firstName || '', user.lastName || ''].join(' ').trim();
  }

}