import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { BidSessionWave } from '.';
import { BidSessionWareItem } from './ware-item';

@NgModule({
  imports: [
    CommonModule,
    NzGridModule,
    NzPopoverModule,
    NzToolTipModule
  ],
  declarations: [
    BidSessionWareItem,
    BidSessionWave
  ],
  exports: [
    BidSessionWave
  ],
  providers: [
  ]
})
export class BidSessionWaveModule { }