import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipmentQueueV2 } from './index';
import { ShipmentQueueV2RoutingModule } from './routing.module';
import { ShipmentQueueV2ListNeedAppointmentsModule } from '../shipment-queues/v2/list-need-appointments/module';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ShipmentNeedRoutingList } from './components/shipment-need-routing-list';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { TransitNetworkModule } from '../transit-network/module';
import { NzTableModule } from 'ng-zorro-antd/table';



@NgModule({
  declarations: [
    ShipmentQueueV2,
    ShipmentNeedRoutingList
  ],
  imports: [
    CommonModule,
    ShipmentQueueV2RoutingModule,
    NzTabsModule,
    NzIconModule,
    ShipmentQueueV2ListNeedAppointmentsModule,
    NzButtonModule,
    NzRadioModule,
    NzSelectModule,
    FormsModule,
    NzDropDownModule,
    NzInputModule,
    NzToolTipModule,
    NzPopoverModule,
    TransitNetworkModule,
    NzTableModule
  ]
})
export class ShipmentQueueV2Module { }
