<div class="section-table">
  <nz-table #nzTable
    [nzScroll]="{ x: 'scroll' }"
    nzBordered="true"
    [nzData]="candidates" nzSize="small"
    [nzTotal]="totalCount" [nzFrontPagination]="false"
    [nzShowPagination]="totalCount > candidates.length"
    [nzPageSize]="limit"
    [nzPageIndex]="pageIndex"
    [nzNoResult]="tplNoData"
    [nzSize]="'default'"
    (nzPageIndexChange)="onChangePage($event)"
  >
    <ng-template #tplNoData>
      <div class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
    </ng-template>
    <thead>
      <tr>
        <th nzLeft nzColumnKey="name" [nzWidth]="'238px'">
          Carrier Name
        </th>
        <th nzLeft nzColumnKey="pool" *ngIf="hasDedicatedPool" [nzWidth]="'155px'">
          Dedicated Pool
        </th>
        <th nzLeft nzColumnKey="contacts" [nzWidth]="'201px'">
          Carrier Contact
        </th>
        <th nzColumnKey="source" [nzWidth]="'96px'">
          Source
        </th>
        <th nzColumnKey="laneCompleted" [nzWidth]="'214px'">Performance</th>
        <th nzColumnKey="document" [nzWidth]="'137px'">Documents</th>
        <th nzColumnKey="gohighway" [nzWidth]="'145px'">Highway</th>
        <th nzColumnKey="baseRate" [nzWidth]="'96px'">Base Rate</th>
        <th nzColumnKey="bidding" [nzWidth]="'250px'">Bid Invitation</th>
        <th nzColumnKey="answer" [nzWidth]="'150px'">Carrier answer</th>
        <th nzRight nzColumnKey="note" [nzWidth]="'70px'">Note</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of nzTable.data">
        <tr>
           <!-- Carrier Name -->
           <td nzLeft>
            <div class="flex" style="align-items: center;">
              <ng-container *ngIf="item.carrierId">
                <a class="inherit" target="_blank" [routerLink]="[routeAdminCarrierList, item.carrierId]">{{item.name || 'N/A'}}</a>
              </ng-container>
              <ng-container *ngIf="!item.carrierId">
                <div class="inherit">{{item.name || 'N/A'}}</div>
              </ng-container>

              <span nz-icon nzType="down" nzTheme="outline"
                style="margin-left: 8px; padding-right: 10px;"
                nz-tooltip [nzTooltipTitle]="titleTemplate"
                [nzTooltipTitleContext]="{ $implicit: item }"
                nzTooltipPlacement="bottomRight"
                nzTooltipColor="#ffffff"
                nzTooltipOverlayClassName="group-carrier-info"
              ></span>
              <ng-template #titleTemplate let-info>
                <div class="carrier-name">
                  {{ info.name || 'N/A' }}
                </div>
                <nz-divider class="line"></nz-divider>
                <div class="group-info">
                  <span>DOT: {{ item.dot || 'N/A' }}</span><br/>
                  <span>MC: {{ item.mc || 'N/A' }}</span><br/>
                  <span>Equipment: {{ item.equipment || 'N/A' }}</span>
                </div>
              </ng-template>
            </div>

            <ng-container *ngIf="item.status === 'un_registered'">
              <nz-tag>Unregistered</nz-tag>

              <ng-container *ngIf="this.isTruckSearch(item)">
                <ng-container *ngIf="getLastSendInvite(item)">
                  <span class="f12" style="color: #8D8D8D;">Invitation sent</span> <span class="f12" style="color: #8D8D8D" nz-icon nzType="down" nzTheme="outline"
                    style="margin-left: 8px; padding-right: 10px;"
                    nz-tooltip [nzTooltipTitle]="lastSendTeamplate"
                    [nzTooltipTitleContext]="{ $implicit: item }"
                    nzTooltipPlacement="bottomRight"
                    nzTooltipColor="#ffffff"
                    nzTooltipOverlayClassName="group-carrier-info"
                  ></span>
                  <ng-template #lastSendTeamplate>
                    <p class="small-text" style="margin-top: 5px;" style="color: black" >when: {{getLastSendInvite(item)}}</p>
                  </ng-template>
                  <!-- <span style="color: #8D8D8D">Invitation sent</span>  -->
                </ng-container>
                <ng-container *ngIf="!getLastSendInvite(item)">
                  <a class="f12" [href]="" (click)="sendInviteRegisterToCarrier(item)" style="text-decoration: underline; margin-top: 10px;">
                    {{item?.contacts?.[0]?.contactEmail ? 'Invite via Email' : 'Invite via SMS'}}
                  </a>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="!this.isTruckSearch(item)">
                <ng-container *ngIf="item.lastSent">
                  <span class="f12" style="color: #8D8D8D">Invitation sent</span>
                </ng-container>
                <ng-container *ngIf="!item.lastSent">
                  <a class="f12" [href]="" (click)="sendInviteRegisterToCarrierPublic(item)"
                    style="margin-top: 10px;"
                  >
                    Invite via Email
                  </a>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="item.status !== 'un_registered'">
              <ng-container *ngIf="item.status != 1">
                <nz-tag nzColor="warning">{{$asCarrierStatusText(item.status)}}</nz-tag>
              </ng-container>
              <ng-container *ngIf="item.status == 1">
                <nz-tag nzColor="success">Active</nz-tag>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="isNeverBid(item)">
              <nz-tag nzColor="purple">Rookie</nz-tag>
            </ng-container>
          </td>
          <!-- Dedicated Pool -->
          <td nzLeft *ngIf="hasDedicatedPool">
            <ng-container *ngIf="item.isDedicatedPool">
              <a [routerLink]="[routeAdminPools, item.poolId]" target="_blank">{{item.poolName}}</a> <br />
              Base Rate: <b>{{ item?.basePrice ? $asMoney(item.basePrice) : '-' }}</b>
            </ng-container>
            <ng-container *ngIf="!item.isDedicatedPool">-</ng-container>
          </td>
          <!-- Carrier Contact -->
          <td nzLeft>
            {{item.contacts?.[0]?.contactName || 'N/A'}}<br />
            <p class="small-text">
              <contact-info
                [isFetch]="false"
                owner="{{item.contacts?.[0]?.contactPhone}}"
                type="phone"
                [scopes]="['carrier_bid']"
                [isUnsub]="isUnsubcribed(item.contacts?.[0]?.contactPhone)"
              ></contact-info>
              <contact-info
                [isFetch]="false"
                owner="{{item.contacts?.[0]?.contactEmail}}"
                type="email"
                [scopes]="['carrier_bid']"
                [isUnsub]="isUnsubcribed(item.contacts?.[0]?.contactEmail)"
              ></contact-info>
            </p>
          </td>
          <!-- Source -->
          <td>
            <div class="title-source">
              {{ item?.source?.category }}
              <i *ngIf="whyAdded(item)"
                nz-icon nzType="info-circle"
                nzTheme="outline" nz-tooltip
                [nzTooltipTitle]="whyAdded(item)"
                class="icon-info"
              ></i>
            </div>
            <div class="desc-source" *ngIf="item?.source?.desc">
              {{ item?.source?.desc }}
            </div>
          </td>
          <!-- Performance -->
          <td>
            <div *ngIf="!item.countOfJob">
              <div nz-row class="group-lane">
                <nz-col class="left-lane" nzSpan="24">
                  N/A
                </nz-col>
              </div>
            </div>
            <ng-container *ngIf="item.countOfJob > 0">
              <div nz-row class="group-lane">
                <nz-col class="left-lane" nzSpan="12">
                  <div>
                    All lane: {{ item.countOfJob }}<br/>
                    <div class="rate-info" display-rate-info
                      style="margin-bottom: 4px;"
                      [numOfLike]="item.countOfJobRateLike"
                      [numOfDislike]="item.countOfJobRateDislike"
                      [carrierId]="item.carrierId"
                    ></div>
                  </div>
                </nz-col>
                <nz-col nzSpan="12" class="right-lane">
                  <div>
                    This lane: {{ item.countLane }}
                  </div>
                </nz-col>
                <nz-col nzSpan="24" *ngIf="item.countJobUnassigned">
                  <div style="color: #faad14;" nz-tooltip nz-tooltip nzTooltipTitle="Carrier won the bids but couldn't commit to pickup">
                    <span nz-icon nzType="warning" nzTheme="outline"></span> Carrier Canceled: {{ item.countJobUnassigned }}
                  </div>
                </nz-col>
              </div>
            </ng-container>
          </td>
          <!-- Documents -->
          <td>
            <div class="flex">
              <nz-tag [nzColor]="$documentColor(item?.documents?.isPassed)">{{ item?.documents?.isPassed ? 'Passed': 'Not Verified' }}</nz-tag>
              <div *ngIf="!item?.documents?.isPassed">
                <span nz-icon nzType="down" nzTheme="outline"
                  style="margin-left: 5px;"
                  nz-tooltip [nzTooltipTitle]="documentTemplate"
                  [nzTooltipTitleContext]="{ $implicit: item }"
                  nzTooltipPlacement="bottomRight"
                  nzTooltipColor="#ffffff"
                  nzTooltipOverlayClassName="group-document-info"
                ></span>
                <ng-template #documentTemplate let-item>
                  <ng-container *ngFor="let el of item?.documents?.messages || []">
                    <div class="flex-space-between">
                      <div class="massage">{{ el.label }}</div>
                      <div><nz-tag [nzColor]="'red'">{{ el.status }}</nz-tag></div>
                    </div>
                    <nz-divider class="line"></nz-divider>
                  </ng-container>
                  <div>
                    <a target="_blank" [routerLink]="[routeAdminCarrierList, item.carrierId]">
                      View details in Carrier profile <i nz-icon nzType="login" nzTheme="outline"></i>
                    </a>
                  </div>
                </ng-template>
              </div>
            </div>
          </td>
          <!-- Highway -->
          <td>
            <ng-container>
              <nz-tag [nzColor]="$goHighWayColor(item?.goHightWayStatus)">{{ $goHighWayStatus(item?.goHightWayStatus) }}</nz-tag>
              <a
                *ngIf="item.goHighWayLink"
                [href]="item.goHighWayLink" target="_blank"
                nz-tooltip nzTooltipTitle="Go to GoHighWay"
              >
                <span nz-icon nzType="login" nzTheme="outline"></span>
              </a>
            </ng-container>
          </td>
          <!-- Base Rate -->
          <td>
            <div *ngIf="(item.basePrice && item.basePrice <= bid.basePrice) || (!bid.basePrice && item.basePrice)" class="group-base-rate">
              <div class="icon" nz-tooltip [nzTooltipTitle]="tooltipBaseRate(item)">
                <img src="/assets/svg/icon-info.svg" alt="">
              </div>
              <div class="base-price">
                {{ $asMoney(item.basePrice) }}
              </div>
            </div>
            <div *ngIf="!item.basePrice || (item.basePrice > bid.basePrice && bid.basePrice)" class="group-base-rate">
              <div class="icon" nz-tooltip nzTooltipTitle="WARP base rate">
                <img src="/assets/svg/warp-logo.svg" alt="">
              </div>
              <div class="base-price">
                {{ bid.basePrice ? $asMoney(bid.basePrice) : '-' }}
              </div>
            </div>
          </td>
          <!-- Bid Invitation -->
          <td nzAlign="left">
            <ng-container *ngIf="item.sendBidQueueStatus == 'cancelled'">
              <nz-tag nzColor="error" [nz-tooltip]="getCancelMessage(item)">Response rate is too low</nz-tag>
              <div>
                <a nz-tooltip class="f13"
                  (click)="onBtnChangSendBidQueueStatus(item)">
                  Send anyway
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="item.sendBidQueueStatus != 'cancelled'">
              <ng-container *ngIf="item.lastSent">
                <div class="group-bidding">
                  <div class="group-status">
                    <div class="item-status" *ngIf="item.lastSentEmail?.status">
                      <span nz-icon nzType="mail" nzTheme="outline"></span>
                      <span class="status" [ngClass]="$asSentStatusColor(item.lastSentEmail.status)">
                        {{ $asSentStatusText(item.lastSentEmail.status) }}
                      </span>
                    </div>
                    <div class="item-status" *ngIf="item.lastSentSms?.status">
                      <span nz-icon nzType="message" nzTheme="outline"></span>
                      <span class="status" [ngClass]="$asSentStatusColor(item.lastSentSms.status)">
                        {{ $asSentStatusText(item.lastSentSms.status) }}
                      </span>
                    </div>
                  </div>
                  <div class="action">
                    <span *ngIf="$shouldShowHistory(item.lastSent)"
                      (click)="onBtnHistoryStatus(item)"
                      nz-icon nzType="history" nzTheme="outline"
                    ></span>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!item.lastSent && item.lastQueued">
                <ng-container *ngIf="item.lastQueued.metadata?.disableAutoQueue">
                  Sending...
                </ng-container>
                <ng-container *ngIf="!item.lastQueued.metadata?.disableAutoQueue">
                  <span nz-tooltip nzTooltipTitle="This message is queued and will automatically be sent at: {{$displayEstTime(item.lastQueued?.when)}} EST">
                    Send at: <br />
                    {{$displayEstTime(item.lastQueued?.when)}} (EST)
                  </span>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!item.lastQueued && !item.lastSent">
                Not sent
              </ng-container>
            </ng-container>
          </td>
          <!-- Answer -->
          <td class="border-right-none">
            <div class="flex" *ngIf="!isCarrierDNU(item)">
              <button nz-button  nzType="default" nzSize="small" (click)="onBtnEditItem(item)" nz-tooltip nzTooltipTitle="Enter Carrier's answer">
                <img src="/assets/svg/question_answer.svg" alt="" style="margin-right: 3px;">
              </button>
            </div>
          </td>
          <!-- Note -->
          <td nzRight>
            <div *ngIf="!isAdminReadOnlyRole && !isCarrierDNU(item)">
              <ng-container *ngIf="item.noteCount">
                <div nz-popover nzPopoverTitle="Preview Notes" [nzPopoverContent]="notesPopover">
                  <nz-badge nzSize="default" [nzCount]="item.noteCount">
                    <a (click)="onBtnEditNote(item)" nzSize="small">
                      <img src="/assets/svg/edit-button.svg" alt="">
                    </a>
                  </nz-badge>
                </div>
                <!-- notes preview -->
                <ng-template #notesPopover>
                  <div class="preview-bid-note-box">
                    <div *ngIf="item?.pinnedItems?.length > 0" class="pinned-list">
                      <div class="bottom5 flex">
                        <div class="label">
                          <span nz-icon nzType="pushpin" nzTheme="outline"></span>
                          <span class="f13 left5">{{item?.pinnedItems?.length}} Pinned</span>
                        </div>
                      </div>
                      <div class="group-content">
                        <ng-container 
                          *ngFor="let note of item?.pinnedItems" 
                          [ngTemplateOutlet]="noteItem"
                          [ngTemplateOutletContext]="{note, edittingId: note.id, items: item?.pinnedItems}"
                        ></ng-container>
                      </div>
                    </div>

                    <div class="group-date bottom20" *ngFor="let date of getDateItems(item?.notes)">
                      <div class="date-label">{{date}}</div>
                      <div class="group-content">
                        <ng-container 
                          *ngFor="let note of item.notes[date]" 
                          [ngTemplateOutlet]="noteItem"
                          [ngTemplateOutletContext]="{note, edittingId: note.id, items: item.notes[date]}"
                        ></ng-container>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
              <ng-container *ngIf="!item.noteCount">
                <a (click)="onBtnEditNote(item)" nzSize="small">
                  <img src="/assets/svg/edit-button.svg" alt="">
                </a>
              </ng-container>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</div>

<ng-template #noteItem let-item="note" let-edittingId="edittingId" let-list="items">
  <div class="note-list-item">
    <div class="item-header">
      <div class="poster">
        <ng-container>
          <nz-avatar [nzText]="getFullName(item.user).slice(0,1)"
            [ngStyle]="{ 'background-color': '#7265e6' }"
          ></nz-avatar>
        </ng-container>
        <div class="info">
          <div class="name">
            <span class="semibold">{{getFullName(item.user) || 'No name'}}</span>
            <ng-container *ngIf="item?.metadata?.routeCode">
              <br />on
              <span class="semibold" class="primary">
                <ng-container>This Load</ng-container>
              </span>
            </ng-container>
            <span *ngIf="item.pinned?.when" nz-icon nzType="pushpin" nzTheme="fill" class="left10"
              style="color: #FAAD14;"
            ></span>
          </div>
          <div class="time">{{item.time}}</div>
          <div class="top" style="width: 100%; margin-top: 5px;">
            <div style="line-height: 22px;word-wrap: break-word;"
              *ngIf="item.content"
              [innerText]="item.content"
            ></div>
            <div *ngIf="item?.imageUploadFilesArr" class="list-images-upload">
              <div *ngFor="let image of item?.imageUploadFilesArr;let i = index">
                <img *ngIf="image" 
                  (click)="viewImageItem(image.imgUrl)" 
                  class="attached-image" [src]="image.imgUrl"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>