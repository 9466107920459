import { environment } from '@env/environment';

export class AWSUtil {
  private static info = { region: '', poolId: '', s3Bucket: '' }  // will be fetched from server

  public static init(info) {
    this.info = info;
  }

  public static getUrlS3Object(key: string, bucket: string = null) {
    if (!bucket) {
      bucket = this.info.s3Bucket;
    }
    return `${environment.backendUrl}/v1/files/${bucket}/${this.encodeS3Key(key)}`
  }

  public static getSignedUrlS3Object(key: string, bucket: string = null) {
    if (!bucket) {
      bucket = this.info.s3Bucket;
    }
    return `${environment.backendUrl}/v1/files/presigned/${bucket}/${this.encodeS3Key(key)}`
  }

  private static encodeS3Key(key: string) {
    let keys = key.split('/');
    let tryDecode = keys[keys.length - 1];
    //encode last part of the key
    //catch để tránh lỗi decode
    try {
      tryDecode = decodeURIComponent(keys[keys.length - 1]);
    } catch (e) {
    }
    keys[keys.length - 1] = encodeURIComponent(tryDecode);
    return keys.join('/');

  }

}