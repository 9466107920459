import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { RouteService } from "../../services/route.service";
import RouteEntity from "../../entities/RouteEntity";
import { Subscription } from "rxjs";
import TaskEntity from "../../entities/TaskEntity";
import { Const } from "@wearewarp/universal-libs";
import { MapService } from "../../services/map.service";
import { Utils } from "@services/utils";
import { BaseComponent } from "@abstract/BaseComponent";
import { SortedTaskByShipmentId } from "@wearewarp/types-server-admin";

@Component({
  selector: 'edit-route-sequence-screen',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditRouteSequenceScreen extends BaseComponent {
  private _routeId: string;
  public route: RouteEntity;

  public isLoading: boolean = true;
  public isSubmitting: boolean = false;

  constructor(
    private routeService: RouteService,
    private cdr: ChangeDetectorRef
  ) {
    super()
  }

  @Input() onFinish: Function
  @Input() set routeId(routeId) {
    this._routeId = routeId;
    this.routeService.setRouteId(routeId).refresh()
  }
  get routeId() {
    return this._routeId
  }

  ngOnInit(): void {
    this.subscription.add(
      this.routeService.routeData$.subscribe(() => {
        this.route = this.routeService.getRoute();
        this.cdr.detectChanges()
      })
    )

    this.subscription.add(
      this.routeService.loading.subscribe(value => {
        this.isLoading = value;
        this.cdr.detectChanges()
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }


  onCancel() {
    if (this.onFinish) this.onFinish()
  }

  canSubmit() {
    return this.routeService.getRoute()?.isValid()
  }
  async onSubmit() {
    //submit here
    this.isSubmitting = true;
    const tasks = this.route.getTasks();
    await this.routeService.createRoute({
      shipmentIds: Utils.uniqElementsArray(tasks.map(task => task.getShipmentId())),
      sortedTasks: <SortedTaskByShipmentId[]>tasks.map(task => ({
        shipmentId: task.getShipmentId(),
        type: <any>task.getType()
      }))
    });
    if (this.onFinish) this.onFinish();
    this.isSubmitting = false;
    this.showSuccess('Edit sequence of route successfully!')
  }

}