import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BidSessionListComponent } from './list';
import { BidSessionCreateComponent } from './create';
import { BidSessionDetailComponent } from './detail';
import { BidSessionAddRouteComponent } from './add-routes';

const bidSessionRoutes: Routes = [
  { path: '', component: BidSessionListComponent, data: { title: 'Bid Session - WARP Admin' } },
  { path: 'add', component: BidSessionCreateComponent, data: { title: 'Create Bid Session - WARP Admin' } },
  { path: ':id', component: BidSessionDetailComponent, data: { title: 'Bid Session - WARP Admin' } },
  { path: ':id/add-routes', component: BidSessionAddRouteComponent, data: { title: 'Add Route For Bid Session - WARP Admin' } },
];

@NgModule({
  imports: [RouterModule.forChild(bidSessionRoutes)],
  exports: [RouterModule]
})
export class BidSessionRoutingModule { }