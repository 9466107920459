import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FreightQuoteFTL } from './index';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormAddressUSModule } from '../base/form-address-us/module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { FormInputCostModule } from '../base/form-input-cost/module';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { DetailModule } from '../base/detail.module';
import { SharedModule } from '../shared/shared.module';
import { ConfirmBookingQuote } from './confirm-shipment-detail';

// import { ZipcodeSelector } from './zipcode-selector';
import { VehicleTypeSelector } from './vehicle-selector';
import { FreightQuoteFTLSearchForm } from './search-form';
import { FreightQuoteFTLHistoryRequest } from './history-request';
import { QuoteRate } from './quote-rate';
import { Rate } from './quote-rate/rate';
import { QuoteResult } from './quote-result';
import { DecimalNumber } from './quote-rate/number';
import { PickDate } from './pick-date';
import { ServiceOption } from './service-options';
import { QuoteItem } from './quote-item';
import { FreightQuoteBook } from './book-quote';
import { DeliveryInfoInput } from './delivery-info-input';
import { ShipmentQuoteReview } from './shipment-review';
import { DeliveryInfoQuickView } from './shipment-review/delivery_info_quickview';
import { FreightQuoteHistoryList } from './history-list';
import { FreightQuoteHistoryListItem } from './history-list/item';
import { UiCommonModule } from '../components/common/module';
import { ClientSelector } from './client-selector';
import { ListFilter } from './history-list/list_filter';
import { RateDetail } from './quote-rate/rate-detail';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzDividerModule,
    NzTableModule,
    NzButtonModule,
    NzSelectModule,
    NzPaginationModule,
    NzIconModule,
    NzGridModule,
    NzEmptyModule,
    NzInputModule,
    NzFormModule,
    NzDatePickerModule,
    NzStepsModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    NzTagModule,
    NzCollapseModule,
    SearchBoxModule,
    FormAddressUSModule,
    FormInputCostModule,
    NzTimelineModule,
    NzDropDownModule,
    SelectBySearchingModule,
    RouterModule,
    NzToolTipModule,
    NzIconModule,
    NzCardModule,
    NzSwitchModule,
    DetailModule,
    SharedModule,
    UiCommonModule,
  ],
  declarations: [
    FreightQuoteFTL,
    // ZipcodeSelector,
    VehicleTypeSelector,
    FreightQuoteFTLSearchForm,
    FreightQuoteFTLHistoryRequest,
    QuoteRate,
    Rate,
    RateDetail,
    QuoteResult,
    DecimalNumber,
    PickDate,
    ServiceOption,
    QuoteItem,
    ConfirmBookingQuote,
    FreightQuoteBook,
    DeliveryInfoInput,
    ShipmentQuoteReview,
    DeliveryInfoQuickView,
    FreightQuoteHistoryList,
    FreightQuoteHistoryListItem,
    ClientSelector,
    ListFilter
  ],
  exports: [
    FreightQuoteFTL,
    QuoteResult,
    RateDetail,
  ],
})
export class FreightQuoteFTLModule { }
