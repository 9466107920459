import { Component } from "@angular/core";
import { BaseFormDrawer } from "@app/drawers/base-form";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: '[filter-modal-need-appointment]',
  templateUrl: './filter-modal-need-appointment.html',
  styleUrls: ['./filter-modal-need-appointment.scss']
})
export class FilterModalNeedAppointment extends BaseFormDrawer {

  listTaskTypes = [
    { label: 'Pickup', value: 'PICKUP', checked: false },
    { label: 'Dropoff', value: 'DROPOFF', checked: false }
  ];
  protected formGroupDeclaration: FormGroupDeclaration = {
    clientId: { label: 'Customer', placeHolder: '- Select -', required: false },
    clientFilterType: { label: '', placeHolder: '', required: false, initialValue: 'include'},
    pickFrom: { label: 'Pickup Date Range', placeHolder: 'From date', notAcceptEmpty: true, required: false,getValue: DateUtil.getDateFrom },
    pickTo: { label: '', placeHolder: 'To date', notAcceptEmpty: true, required: false, getValue: DateUtil.getDateTo },
    dropFrom: { label: 'Delivery Date Range', placeHolder: 'From date', notAcceptEmpty: true, required: false, getValue: DateUtil.getDateFrom },
    dropTo: { label: '', placeHolder: 'To date', notAcceptEmpty: true, required: false, getValue: DateUtil.getDateTo },
    reattemptCount: { label: 'Re-attempt', placeHolder: '', notAcceptEmpty: true },
    isPickup: { label: 'Appointments by', placeHolder: '', notAcceptEmpty: true, initialValue: false },
    isDropoff: { label: '', placeHolder: '', notAcceptEmpty: true, initialValue: false },
  };
  
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected beforeBindModel(model: any = {}) {
    return model;
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  public onClose() {
    this.nzDrawer?.close();
  }

  onBtnClearFilter() {
    for (let key of this.formInputKeys) {
      this.setItemValue(key, this.formGroupDeclaration[key].initialValue);
    }
  }

  onBtnSave() {
    let formData = this.getFormData_JSON(true);
    for (let key of this.formInputKeys) {
      formData[key] = this.getItemValue(key);
    }
    this.updateSuccess(formData);
    this.nzDrawer?.close();
  }

}
