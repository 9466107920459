import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { FormDataShipmentLocation } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { BaseFormTemplate } from "../../../../../base/form-base-template";
import { FormShipmentLocation } from "..";
import { FormArray } from "@angular/forms/forms";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { LocationFilterHeper } from "../helper/location";
import { ServiceOptionsHelper } from "../helper/service-options";

@Component({
  selector: '[form-shipment-location-template-default]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class FormShipmentLocationTemplateDefault extends BaseFormTemplate<FormDataShipmentLocation, FormShipmentLocation> {
  @Input() locationHelper: LocationFilterHeper;
  @Input() serviceOptionsHelper: ServiceOptionsHelper;

  get locationsFiltered() { return this.locationHelper.locationsFiltered }
  get serviceOptions() { return this.serviceOptionsHelper.params }

  get type() {return this.formComponent.type}
  public isAppointment: boolean = false;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.isAppointment = this.formComponent.getItemValue('requiresAppointment');
  }

  getLabel(key: string): string {
    const prefix = this.type == Const.TaskType.PICKUP ? 'Pickup' : 'Delivery';
    switch (key) {
      case 'windows': return `${prefix} Windows`;
      case 'appointmentInfo': return `Requested ${prefix} Date`;
      case 'serviceOptions': return `${prefix} Service Options`;
      case 'instructions': return `${prefix} Instructions (External)`;
      case 'note': return `${prefix} Note (Internal)`;
      default: return super.getLabel(key);
    }
  }

  onLocationSelected(event, data) {
    this.locationHelper.onLocationSelect(event, data)
  }

  getFormArrayControls(key: string) {
    return (<FormArray>this.formInput.get(key))?.controls ?? [];
  }

  getFormArrayLength(key: string): number {
    return this.getFormArrayControls(key).length;
  }

  shouldShowButtonAddFormArray(key: string, index: number): boolean {
    return index == this.getFormArrayLength(key) - 1;
  }

  shouldShowButtonRemoveFormArray(key: string, index: number): boolean {
    return this.getFormArrayLength(key) > 1;
  }

  onBtnAddFormArray(key: string) {
    this.formComponent.addItemToFormArray(key);
    setTimeout(() => this.cd.detectChanges(), 50);
  }

  onBtnRemoveFormArray(key: string, index: number) {
    let isEmptyValue = false;
    let itemValue = this.formComponent.getItemValue(`${key}[${index}]`);
    let message = `Remove item at position <b>${index + 1}</b>?`;
    switch (key) {
      case 'windows':
        isEmptyValue = itemValue?.range == null || (itemValue?.range?.[0] == null && itemValue?.range?.[1] == null);
        break;
      case 'appointmentInfo':
        isEmptyValue = itemValue?.range == null || (itemValue?.range?.[0] == null && itemValue?.range?.[1] == null);
        break;
      case 'refNums':
        isEmptyValue = !itemValue;
        message = `Remove reference number <b>${itemValue}</b>?`;
        break;
    }
    if (!isEmptyValue) {
      let message = `Remove item at position <b>${index + 1}</b>?`;
      if (key == 'refNums') {
        message = `Remove reference number <b>${itemValue}</b>?`;
      }
      DialogService.confirmDeletion({
        message: message,
        txtBtnOk: 'Remove',
        fnOk: () => this.formComponent.removeItemInFormArray(key, index)
      })
    } else {
      // empty value thì cho xoá luôn mà không cần confirm
      this.formComponent.removeItemInFormArray(key, index);
    }
  }

  onFilterTextChange(text: string) {
    this.locationHelper.onFilterTextChange(text);
    if (!text) {
      this.setItemValue(`warehouseId`, '');
    }
  }

  requiresAppointmentChange(value: boolean) {

    // WPD-3206
    if (!value && (this.serviceOptions.has('residential-pickup') || this.serviceOptions.has('residential-delivery'))) {
      this.setItemValue('requiresAppointment', true);
      return DialogService.showDialog('Residential Pickup/Delivery requires appointment!');
    }

    this.isAppointment = value;
    this.formComponent.onRequiresAppointmentChanged(value);
  }

  serviceOptionsSelectionChange() {

    // WPD-3206
    if (this.serviceOptions.has('residential-pickup') || this.serviceOptions.has('residential-delivery')) {
      this.setItemValue('requiresAppointment', true);
      this.requiresAppointmentChange(true);
    }
    // else {
    //   this.setItemValue('requiresAppointment', false);
    //   this.requiresAppointmentChange(false);
    // }

    this.serviceOptionsHelper.serviceOptionsSelectionChange();
  }

  removeServiceOption(index) {
    this.serviceOptions.remove(index);
    this.serviceOptionsSelectionChange()
  }

  timeWindowChange(index) {
    if (index !== 0) return;      // Chỉ áp dụng khi thay đổi window đầu tiên
    this.formComponent.onTimeWindowChanged();
  }

  addressChange(value) {
    this.formComponent.onAddressChanged();
  }

  get shouldShowTrafficSuggestion() {
    return this.formComponent.trafficInfo && this.formComponent.dropWindowSuggestion ? true : false;
  }

  get trafficInfo() {
    return this.formComponent.trafficInfo;
  }

  get displayDropWindowSuggestion() {
    return this.formComponent.displayDropWindowSuggestion();
  }
}
