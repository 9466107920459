import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ContactInfoComponent } from '.';

@NgModule({
  imports: [
    CommonModule,
    NzToolTipModule,
    NzIconModule
  ],
  declarations: [
    ContactInfoComponent,
  ],
  exports: [
    ContactInfoComponent
  ],
  providers: [
  ]
})
export class ContactInfoModule { }