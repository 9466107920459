<div *ngIf="shipment" class="shipment-transit-network">
    <div class="header">
        <div><b>Transit Network</b> </div>
        <div *ngIf="orderStatus" class="order-status">{{orderStatus}}</div>
    </div>
    <div class="transit-network" transit-network [id]="shipmentIdLevel0" [viewOnly]="true" [highlight]="[shipment.id]"></div>
    <div nz-row class="delivery-info">
        <div *ngFor="let location of deliveryInfos" nz-col [nzSpan]="12" class="location" [ngClass]="{'active': isMe(location)}">
            <div class="info">
                <img src="/assets/img/shipment-queue/location_on.svg" alt="">
                <div class="locationName" (click)="viewLocationDetail(location)"><b>{{location.type}} ({{location.locationName || 'NO NAME'}})</b></div>
            </div>
            <div class="info">
                <img style="margin-left: 5px; margin-top: 3px;" src="assets/img/location-icon.svg">
                <div *ngIf="location?.addr">{{ location.addr.street }}, {{ location.addr.city }}, {{ location.addr.state }} {{ location.addr.zipcode }}</div>
                <div *ngIf="!location?.addr">Location: N/A</div>
            </div>
            <div class="info">
                <span style="margin-left: 4px; margin-top: 3px;" nz-icon nzType="calendar" nzTheme="outline"></span>
                <div>
                    <span class="time-period" *ngFor="let window of getTimePeriods(location)">{{window}}</span>
                    <span *ngIf="!getTimePeriods(location)?.length" class="time-period grey">No time
                        window</span>
                </div>
            </div>
            <div class="info">
                <img src="assets/img/service-options-icon.svg">
                <div>
                    <span class="label">Service Options: </span>
                    <span *ngIf="getServiceOptions(location)?.length">
                        <code class="refNum" *ngFor="let r of getServiceOptions(location); let isLast=last">{{r}}<span *ngIf="!isLast">, </span></code>
                    </span>
                    <span *ngIf="!getServiceOptions(location)?.length">N/A</span>
                </div>
            </div>
            <div class="info">
                <span style="margin-left: 4px; margin-top: 3px;" nz-icon nzType="profile" nzTheme="outline"></span>
                <div>
                    <span class="label">Reference No: </span>
                    <span *ngIf="getRefNums(location).length">
                        <code (click)="copyValue(r)" class="refNum clickable"
                            *ngFor="let r of getRefNums(location); let isLast=last">{{r}}<span *ngIf="!isLast">, </span></code>
                    </span>
                    <span *ngIf="!getRefNums(location).length">N/A</span>
                </div>
            </div>
            <div class="info">
                <span style="margin-left: 4px; margin-top: 3px;" nz-icon nzType="key" nzTheme="outline"></span>
                <div>
                    <span class="label">Access Code: </span>
                    <span *ngIf="location.accessCode">{{ location.accessCode }}</span>
                    <span *ngIf="!location.accessCode">N/A</span>
                </div>
            </div>
            <div class="info">
                <img src="/assets/img/shipment-queue/turn_right.svg" alt="">
                <div>
                    <span class="label">Instructions: </span>
                    <span *ngIf="location.instructions">{{ location.instructions }}</span>
                    <span *ngIf="!location.instructions">N/A</span>
                </div>
            </div>
            <div class="info">
                <img src="/assets/img/shipment-queue/edit_note.svg" alt="">
                <div>
                    <span class="label">Notes: </span>
                    <span *ngIf="location.note">{{ location.note }}</span>
                    <span *ngIf="!location.note">N/A</span>
                </div>
            </div>
        </div>
    </div>
</div>