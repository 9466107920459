import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Log } from "@services/log";

interface JobItemInfo {
  id: string,
  code?: string,
}

@Component({
  selector: 'bulk-assign-carrier-sale',
  templateUrl: './index.html',
  styleUrls: ['../../../../dialogs/dialogs.scss']
})

export class BulkAssignCarrierSaleDialog extends BaseFormDialog1 {
  @Input() jobs: JobItemInfo[];

  public listCarrierSales = [];
  public carrierSalesRepId: string;

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchListCarrierSales();
  }

  get buildAssignDescription() {
    let jobCodes = this.jobs.map(job => job.code);
    return `Update carrier sale rep for ${jobCodes.length} loads:\n${jobCodes.join(', ')}`;
  }

  public isFetchingCarrierSaleRep = false;
  private fetchListCarrierSales(silent: boolean = false) {
    this.isFetchingCarrierSaleRep = true;
    this.api.getListAdminUsers().subscribe(
      resp => {
        this.listCarrierSales = resp?.data?.list_data ?? [];
        this.isFetchingCarrierSaleRep = false;
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingCarrierSaleRep = false;
      }
    );
  }

  onBtnSave() {
    let jobIds = this.jobs.map(job => job.id);
    if (!jobIds.length) {
      this.showErr('jobIds is required');
      return;
    }
    const params = {
      jobIds,
      carrierSalesRepId: this.carrierSalesRepId
    }
    this.setEnableFormGroup(false);
    this.startProgress();
    this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/bulk-assign-carrier-sale`, params).subscribe(
      resp => {
        this.showSuccess('Bulk assign carrier sale done');
        this.onUpdateSuccess(resp);
        this.stopProgress();
      }, err => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }

  canClickOk() {
    return this.carrierSalesRepId ? true : false;
  }
}