<div class="load-header">
    <div class="page-title flex1">Create TONU Route</div>
    <div class="btn-close center-children" >
        <i class="clickable" nz-icon nzType="close" nzTheme="outline" (click)="onBtnClose()"></i>
    </div>
</div>

<div class="load-content">
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
        <div nz-col class="gutter-row" nzXs="24">
            <div class="text">
                <span>The same route with "Cancelled" status will be created, and the TONU fee entered below will be applied.</span>
            </div>
            <form nz-form [formGroup]="formInput" class="form-detail">
                <div class="group no-padding">
                    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
                        <ng-container *ngFor="let key of ['cancelReason']">
                            <div nz-col class="gutter-row" nzXs="24">
                                <div class="label">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-select [formControlName]="key" style="min-width: 100%;" [nzPlaceHolder]="getPlaceHolder(key)">
                                            <nz-option *ngFor="let option of cancelReasonOptions" [nzValue]="option.value"
                                            [nzLabel]="option.label"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let key of ['carrierTONU', 'customerTONU']">
                            <div nz-col class="gutter-row" nzXs="12">
                                <div class="label">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input [formControlName]="key" type="number" [placeholder]="getPlaceHolder(key)" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </ng-container>
                        
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="load-footer">
    <div form-footer class="flex form-footer" [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
        [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress"
        labelOK="Create TONU route" (onOK)="onBtnCreateLoad()" (onCancel)="onBtnClose()">
    </div>
</div>

<ng-template #tplCreateTONURouteDone>
    <div style="position: relative;">
        <div class="btn-close center-children" style="position: absolute; right: 0; top: 0;">
            <i class="clickable" nz-icon nzType="close" nzTheme="outline" (click)="closeDialogCreateTONURouteDone()"></i>
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; text-align: center;">
            <div class="bottom10" style="font-size: 18px; font-weight: 600; line-height: 27px;"><img class="ic_complete right10">TONU Route created.</div>
            <div>Route Id: <a
                target="_blank"
                [routerLink]="hyperLinkUrl">
                {{tonuRoute?.code}}
            </a></div>
            <button nz-button nzType="primary" style="width: 100%; margin-top: 16px;" (click)="closeDialogCreateTONURouteDone()">Done</button>
        </div>
    </div>
</ng-template>