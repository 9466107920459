import { LocationItem } from "@app/admin/shipment-entry/interface";
import { MasterData } from "@services/master.data";
import { FormDataShipmentEntryBatchShipmentItem, FormDataShipmentEntryDraftBatch } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { Const } from "@wearewarp/universal-libs";

export class InfoBatchService {

  private static getLocationsFromSortedStopIds(sortedStopIds, locations) {
    let locationsClone = [...locations];
    let val = [];
    for (let index = 0; index < sortedStopIds.length; index++) {
      const stopIds = sortedStopIds[index];
      if (stopIds?.length) {
        const location = locations.find(info => info.id == stopIds[0]);
        if (location) {
          if (!val.find(item => item.id == location.id)) {
            val.push(location);
          }
          locationsClone = locationsClone.filter((item) => item.id != location.id)
        }
      }
      
    }
    if (locationsClone?.length) {
      val = [...val, ...locationsClone];
    }
    return val;
  }
  private static multiDropLocationBuilder(data: FormDataShipmentEntryDraftBatch) {
    let val: Array<LocationItem> = [];
    let items: Array<any> = [];
    const sortedTaskIds: Array<Array<string>> = data?.sortedTaskIds || [];
    if (data?.dropInfos?.length) {
      data?.dropInfos?.map((info) => {
        if (info.items?.length) {
          items = [...items, ...info.items];
        }
      });
      val = data?.dropInfos.map(info => {
        const serviceOptions = (info?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt));
        return {
          ...info,
          shipmentId: null,
          serviceOptions,
        }
      });
    }
    let pickInfo = data.pickInfo;
    if (pickInfo) {
      const serviceOptions = (pickInfo?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt))
      const info: LocationItem  = {
        ...pickInfo,
        shipmentId: null,
        serviceOptions,
        items: items
      }
      val.unshift(info);
    }
    // Lấy danh sách 1 mảng locations xong rồi sắp xếp lại theo sortedTaskIds;
    if (sortedTaskIds?.length) {
      if (pickInfo && sortedTaskIds[0][0] != pickInfo.id) {
        sortedTaskIds.unshift([pickInfo.id])
      }
      val = InfoBatchService.getLocationsFromSortedStopIds(sortedTaskIds, val);
    }
    
    return val;
  }

  private static multiPickLocationBuilder(data: FormDataShipmentEntryDraftBatch) {
    let val: Array<LocationItem> = [];
    let items: Array<any> = [];
    const sortedTaskIds: Array<Array<string>> = data?.sortedTaskIds || [];
    if (data?.pickInfos?.length) {
      data?.pickInfos?.map((info) => {
        if (info.items?.length) {
          items = [...items, ...info.items];
        }
      });
      val = data?.pickInfos.map(info => {
        const serviceOptions = (info?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt));
        return {
          ...info,
          shipmentId: null,
          serviceOptions,
        }
      });
    }
    let dropInfo = data.dropInfo;
    if (dropInfo) {
      const serviceOptions = (dropInfo?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt))
      const info: LocationItem  = {
        ...dropInfo,
        shipmentId: null,
        serviceOptions,
        items: items
      }
      val.push(info);
    }
    // Lấy danh sách 1 mảng locations xong rồi sắp xếp lại theo sortedTaskIds;
    if (sortedTaskIds?.length) {
      const index = sortedTaskIds.findIndex((item) => item[0] == dropInfo.id);
      if (index >= 0) {
        sortedTaskIds.splice(index, 1);
      }
      // Location dropoff lúc nào cũng phải ở cuối cùng
      if (dropInfo && sortedTaskIds[sortedTaskIds.length -1][0] != dropInfo.id) {
        sortedTaskIds.push([dropInfo.id])
      }
      val = InfoBatchService.getLocationsFromSortedStopIds(sortedTaskIds, val);
    }
    return val;
  }
  private static multiPickDropLocationBuilder(data: FormDataShipmentEntryDraftBatch) {
    let val: Array<LocationItem> = [];
    const sortedTaskIds: Array<Array<string>> = data?.sortedTaskIds || [];
    const shipments: Array<FormDataShipmentEntryBatchShipmentItem> = data?.shipments;
    for (let index = 0; index < shipments.length; index++) {
      const shipment = shipments[index];
      const pickInfo = shipment.pickInfo;
      const dropInfo = shipment.dropInfo;
      const items = shipment.items || [];
      val.push({ ...pickInfo , items, shipmentId: (index + 1) });
      val.push({ ...dropInfo, items, shipmentId: (index + 1) });
    }
    if (sortedTaskIds?.length) {
      val = InfoBatchService.getLocationsFromSortedStopIds(sortedTaskIds, val);
    }
    return val;
  }
  public static getListDeliveryInfoBySortedStopIds(data: FormDataShipmentEntryDraftBatch) {
    let val: Array<LocationItem> = [];
    switch (data?.shipmentEntryMode) {
      case Const.ShipmentEntryMode.multiDrop:
        val = InfoBatchService.multiDropLocationBuilder(data);
        break;
      case Const.ShipmentEntryMode.multiPick:
        val = InfoBatchService.multiPickLocationBuilder(data);
        break;
      case Const.ShipmentEntryMode.multiPickDrop:
        val = InfoBatchService.multiPickDropLocationBuilder(data);
        break;
      default:
        break;
    }
    return val;
  }
  public static getSortedTaskIds(locations) {
    const val = locations.map((info) => [info.id]);
    return val;
  }

  public static checkDiffSortedTaskIds(newSortedTaskIds, oldsortedTaskIds) {
    if (newSortedTaskIds?.length != oldsortedTaskIds?.length) {
      return true;
    }
    if (JSON.stringify(newSortedTaskIds || []) != JSON.stringify(oldsortedTaskIds || [])) {
      return true;
    }
    return false;
  }
}