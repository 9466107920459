import { Component, ElementRef, ViewChild, OnChanges, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { Const } from '@app/const/Const';
import _ from "underscore";
import { BaseComponent } from '@abstract/BaseComponent';
import { ModelOrderDetail } from '@app/interfaces/order';
import { DataorchService } from '@services/dataorch.service';
import { environment } from '@env/environment';
@Component({
    selector: '[transit-network]',
    templateUrl: './index.html',
    styleUrls: ['./styles.scss']
})
export class TransitNetwork extends BaseComponent {
    private _model: ModelOrderDetail
    dataorch: DataorchService

    _id: any = null
    @Input() set id(v) {
        this._id = v
        this.loadData()
    }
    get id() {
        return this._id
    }

    _order = null
    @Input() set order(v) {
        this._order = v
    }

    @Input() showOrderDetail
    @Input() compact
    @Input() viewOnly
    // Fix dữ liệu không refresh do 'id' nhận từ Input là không đổi
    @Input() set model(value) {
        this._model = value
        this.loadData()
    }
    get model() { return this._model }
    @Input() highlight = [];
    @Output() refreshDetailOrder: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
    }

    shipment = null
    children: any[] = []
    items: any[] = []

    loadData() {
        this.api.concurrentGET([
            `${Const.APIV2(Const.APIURI_SHIPMENTS)}/${this.id}`,
            `${Const.APIV2(Const.APIURI_SHIPMENTS)}/${this.id}/all-childrens`
        ]).subscribe(async (response) => {
            this.shipment = response[0].data
            this.children = response[1].data.list_data
            if (!this._order && this.shipment.orderId) {
                this.api.GET(`${Const.APIV2(Const.APIURI_ORDERS)}/${this.shipment.orderId}`).subscribe((orderRes) => {
                   this._order = orderRes.data
                })
            }
            //get warehouseInfo
            await this.loadWarehouse()
        })
        // this.api.GET(`${Const.APIV2(Const.APIURI_SHIPMENTS)}/${this.id}`).subscribe((response) => {
        //     this.shipment = response.data
        //     if (!this._order && this.shipment.orderId) {
        //         this.api.GET(`${Const.APIV2(Const.APIURI_ORDERS)}/${this.shipment.orderId}`).subscribe((orderRes) => {
        //            this._order = orderRes.data
        //         })
        //     }
        // })
        this.loadItems()
    }

    loadItems() {
        if (!this._id) return
        //sử dụng cho môi trường dev, không có server dataorch
        if (!environment.dataorchUrl) {
            console.warn('environment.dataorchUrl is not defined. Using shipment.items instead of dataorch.listShipmentItems()')
            this.api.GET(`${Const.APIV2(Const.APIURI_SHIPMENTS)}/${this._id}/items`).subscribe((res) => {
                this.items = res.data?.list_data || []
            });
            return;
        }

        this.dataorch.listShipmentItems(this._id).subscribe((res) => {
            this.items = res
        })
    }

    async loadWarehouse() {
        const warehouseIds = {};
            for(let deliveryInfos of this.shipment?.deliveryInfos) {
                if(deliveryInfos?.warehouseId) {
                    warehouseIds[deliveryInfos.warehouseId] = deliveryInfos.warehouseId
                }
            }

            for(let child of this.children) {
                for(let deliveryInfos of child?.deliveryInfos) {
                    if(deliveryInfos?.warehouseId) {
                        warehouseIds[deliveryInfos.warehouseId] = deliveryInfos.warehouseId
                    }
                }
            }

            const warehouseIdList = Object.keys(warehouseIds);

            this.api.concurrentGET(warehouseIdList.map((warehouseId) => {
                return `${Const.APIURI_WAREHOUSES}/${warehouseId}`;
            })).subscribe((response) => {
                const warehouses = response.map((res) => res.data)
                for(let child of this.children) {
                    for(let deliveryInfos of child?.deliveryInfos) {
                        if(deliveryInfos?.warehouseId) {
                            deliveryInfos.warehouse = warehouses.find((warehouse) => warehouse.id === deliveryInfos.warehouseId)
                        }
                    }
                }
                for(let deliveryInfos of this.shipment?.deliveryInfos) {
                    if(deliveryInfos?.warehouseId) {
                        deliveryInfos.warehouse = warehouses.find((warehouse) => warehouse.id === deliveryInfos.warehouseId)
                    }
                }
            })
    }

    onBtnRefreshDetailOrder() {
        this.refreshDetailOrder.emit();
    }
}