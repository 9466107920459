import { jsPDF } from "jspdf";
import * as pdfjsLib from "pdfjs-dist"; //sử dụng để đọc file PDF
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "assets/mozilla/pdfjs-2.13.216/pdf.worker.min.js";

export class FileUtil {
  public static readonly defaultImageSize:any = {
    "height": 1920,
    "width": 1080
  }

  //PDF Utils
  public static async convertPdfToImages(file: File) {
    const pdfDoc = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
    let promises = [];
    for (let i = 1; i <= pdfDoc.numPages; i++) {
      promises.push(this.getBlobFromPdfPage(pdfDoc, i))
    };
    return await Promise.all(promises);
  }

  private static async getBlobFromPdfPage(pdfDoc, pageNumber): Promise<Blob> {
    const page = await pdfDoc.getPage(pageNumber);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // select scale for image to fit
    const unscaledViewport = page.getViewport({scale:1});
    const scale = Math.min((this.defaultImageSize.width / unscaledViewport.width), (this.defaultImageSize.height / unscaledViewport.height));
    const viewport = page.getViewport({ scale: scale });
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({
      canvasContext: context,
      viewport: viewport
    }).promise;

    const base64 = canvas.toDataURL('image/jpeg');
    const blob = this.base64toBlob(base64.split(',')[1], 'image/jpeg');
    return blob;
  }

  private static base64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  /**
   * Chuyển đổi file PDF nhiều trang thành PDF 1 trang (chỉ lấy trang đầu tiên)
   * @param file File PDF đầu vào
   * @returns File PDF mới chỉ chứa trang đầu tiên
   */
  public static async extractFirstPageFromPdf(file: File): Promise<File> {
    // Nếu không phải PDF thì trả về file gốc
    if (file.type !== 'application/pdf') {
      return file;
    }
    // Đọc file PDF bằng pdf.js
    const pdfDoc = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
    
    // Nếu PDF chỉ có 1 trang thì trả về file gốc
    if (pdfDoc.numPages <= 1) {
      return file;
    }
    // Lấy trang đầu tiên
    const page = await pdfDoc.getPage(1);
    const viewport = page.getViewport({ scale: 2 });

    // Tạo canvas để render PDF
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF vào canvas
    await page.render({
      canvasContext: context,
      viewport: viewport
    }).promise;

    // Tạo PDF mới từ canvas image
    const newPdf = new jsPDF({
      orientation: viewport.width > viewport.height ? 'l' : 'p',
      unit: 'pt',
      format: [viewport.width, viewport.height]
    });
    
    newPdf.addImage(
      canvas.toDataURL('image/jpeg', 1.0),
      'JPEG',
      0,
      0,
      viewport.width,
      viewport.height
    );

    // Convert to Blob then to File
    const pdfBlob = new Blob([newPdf.output('blob')], { type: 'application/pdf' });
    return new File([pdfBlob], file.name, { type: 'application/pdf' });
  }
}