import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { EventsContainer } from "@app/admin/components/events";
import { Const } from "@const/Const";
import { BizUtil } from "@services/biz";
import { getFeatureFlags } from "@services/feature-flag.service";
import { Utils } from "@services/utils";

@Component({
    selector: '[shipment-popup-content]',
    templateUrl: './popup_shipment.html',
    styleUrls: ['./styles.scss']
})
export class ShipmentPopupContent extends BaseComponent {
    @Input() shipment: any = null
    @Input() contextMenu: boolean = false
    @Input() viewOnly: boolean = false
    @Output() onSelectMenu: EventEmitter<any> = new EventEmitter<any>();

    public shipmentCode = null
    public pickupDate = null
    public deliverDate = null
    public status = null

    ngOnChanges(): void {
        this.shipmentCode = this.showShipmentCode(this.shipment);
        this.pickupDate = BizUtil.getDeliveryInfoTime(BizUtil.getPickInfo(this.shipment), { format: Const.FORMAT_GUI_DATE })
        this.deliverDate = BizUtil.getDeliveryInfoTime(BizUtil.getDropInfo(this.shipment), { format: Const.FORMAT_GUI_DATE })
        this.status = this.getStatusShipment(this.shipment.status)
    }

    public confirming: string = null

    onClick(action) {
        this.onSelectMenu?.emit(action)
    }

    showEvents: boolean = false
    ngOnInit(): void {
        getFeatureFlags().isFlagSetNonBlocking('SHOW_EVENT').subscribe((res) => {
            if (res) {
                this.showEvents = true;
            }      
        })
    }
    showEventHistory(type, id) {
        this.drawerService.create({
            nzContent: EventsContainer,
            nzClosable: false,
            nzContentParams: {
                type,
                id
            }
        });
    }

    public copyTrackingCode() {
        Utils.copyTextToClipboard(this.shipment.trackingCode, (e) => {
            if (e) {
                this.showErr("Cannot copy tracking number to clipboard");
            } else {
                this.showSuccess(
                    "Tracking number has already been copied to the clipboard"
                );
            }
        });
    }
}