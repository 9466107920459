import { PaginationData as PaginationDataInterface } from "@wearewarp/types/common/interface";
import { Const } from "@const/Const";

export class PaginationData<T = any> implements PaginationDataInterface<T> {
  total: number = 0
  skip: number = 0
  limit: number = 0
  list_data: Array<T> = [];
  filterMetadata: any;
  
  constructor(option: number|Array<T> = Const.PAGINATION_LIMIT) {
    if (typeof option == 'number') {
      this.limit = option;
    } else if (Array.isArray(option)) {
      this.list_data = option;
      this.total = this.list_data.length;
      this.limit = -1;
    }
  }
  
  get count() {
    return this.list_data.length;
  }
  
  hasData() {
    return this.list_data.length > 0;
  }

  reset(){
    this.skip = 0;
    this.total = 0;
    this.limit = 0;
    this.list_data = [];
  }
  
  setData(data: PaginationDataInterface<T>) {
    this.skip = data.skip;
    this.total = data.total;
    this.limit = data.limit;
    this.list_data = data.list_data;
    this.filterMetadata = (<any>data).filterMetadata;
  }
  
  append(data: PaginationDataInterface<T>) {
    this.skip = data.skip;
    this.total = data.total;
    this.limit = data.limit;
    for (let item of data.list_data) {
      this.list_data.push(item);
    }
  }
  
  pushHead(item) {
    this.list_data.unshift(item);
    this.skip += 1;
    this.total += 1;
  }
  
  pushTail(item) {
    this.list_data.push(item);
    this.skip += 1;
    this.total += 1;
  }
  
  canLoadMore() {
    return this.list_data.length < this.total;
  }
}