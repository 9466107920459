import { Component, EventEmitter, Input } from "@angular/core";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import {FormArray, FormControl} from '@angular/forms';
import { BaseQuickbooksComponent } from "../base";
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ResponseAdminFinAccountPayableDetail, ResponseAdminFinQuickbookStatementDetail } from "@wearewarp/types-server-admin/fin";
import { FormDataCreateQuickbooksBill } from "@wearewarp/types-server-admin/form-data/fin";
import { SearchQuickbooksVendor } from "../search-quickbooks-vendor";
import { ResponseQuickbooksBill, ResponseQuickbooksVendor } from "@wearewarp/types/data-model/types/finance";
import { InputHelper } from "@services/input-helper";
import dayjs from "dayjs";
import _ from "underscore";
import { Subscription } from "rxjs";
import { DateUtil } from "@services/date-utils";
import { CreateQuickbooksVendor } from "../create-vendor";
import { environment } from "@env/environment";

const DefaultCategory51000: string = 'freight in - cogs';
const DefaultCategory40100: string = 'factoring revenue';
const carrierPriority1Id = '01H22NK88Y5HTN2GH0Q4XND57S';    // Carrier Priority 1
const ExcludeClientIds = [ '01H22NK3MBXBDCW6AZDWFZ09V0' ];   // walmart
const MinChargeByDueDay = { 0: 12, 1: 12, 7: 10, 15: 8 };
// 15 days: 3% --> If 3% is < $8 --> Charge $8.
// 7 days: 5% --> If 5% is < $10 --> Charge $10.
// Next Day: 8% --> If 8% is < $12 --> Charge $12.
// Applied above rates for all customers except Walmart Routes

@Component({
  selector: 'create-quickbooks-bill',
  templateUrl: './view.html',
  styleUrls: [ './style.scss' ]
})
/**
 * Có thể tạo statement bill (gộp nhiều route lại thành 1 bill)
 * Hiện chỉ tạo statement bill cho walmart
 */
export class CreateQuickbooksBill_V2 extends BaseQuickbooksComponent {

  @Input() finQbStatement: ResponseAdminFinQuickbookStatementDetail;
  @Input() onSuccess: (resp) => void;

  isLoadingFinAccount = true;
  isFetchingTerms = false;
  isFetchingCateroryAccount = false;
  finAccountData: ResponseAdminFinAccountPayableDetail;
  carrierData: any;
  qbVendorInfo: ResponseQuickbooksVendor;
  qbLatestBill: ResponseQuickbooksBill;
  qbPaymentTermOptions: { id: string, name: string, DueDays: number }[] = [];
  qbCategoryAccountOptions: {id: string, name: string, accountType: string, accountNum: string}[] = [];
  jobShipmentCodes: string = '';
  jobCodes: string = '';
  qbStatementCode: string = '';
  firstShipmentCode: string = '';
  carrierCostValue: number = 0;
  accountRefIdFor51000: string = '';
  accountRefIdFor40100: string = '';
  paymentTermVersionData: any;
  // hiện statement bill chỉ tạo cho walmart nên mặc định là false
  isCustomerApplyMinCharge: boolean = false; 
  denimCompanyInfo: any; 
  private apiReadySubscription: Subscription;

  protected formGroupDeclaration: FormGroupDeclaration = {
    paymentTerms: { label: "Payment Terms", required: true },
    billDate: { label: "Bill Date", required: true, initialValue: new Date() },
    dueDate: { label: "Due Date", required: true, initialValue: new Date() },
    billNo: { label: "Bill no.", required: true },   // maximum of 21 chars
    lines: { label: "Category details", required: true, type: 'formArray', childItem: {
      id: { label: "#", required: false },
      accountRefId: { label: "Category", required: true },
      description: { label: "Description", required: true },
      amount: { label: "Amount ($)", required: true, type: 'number', getValue: InputHelper.getValueDecimalNumber },
      action: { label: "Action" },
    }, initialValue: [{}] },
    memo: { label: "Memo", required: true },
    attachmentFiles: { label: "Attachment", type: 'array' },
  };

  public row1: FormRow = {
    columns: [
      {key: 'id', span: 1 },
      {key: 'accountRefId', span: 8 },
      {key: 'description', span: 8 },
      {key: 'amount', span: 5 },
      {key: 'action', span: 2 },
    ]
  };

  constructor(private modal: NzModalRef) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.carrierCostValue = this.finQbStatement?.amt ?? 0;
    this.qbStatementCode = this.finQbStatement?.code ?? '';
    this.jobCodes = this.finQbStatement?.jobs?.map(it => it.code).join(', ') ?? '';
    this.apiReadySubscription = this.apiReady$.subscribe(async (res) => {
      if (res) {
        await Promise.all([
          this.fetchingDataFinAccountAndLatestBill(),
          this.fetchingDataQBTerms(),
          this.fetchingDataQBAccounts(),
          this.processShipmentCodes(),
          this.fetchingDataPaymentConfig(),
          this.fetchDenimCompany()
        ]);
        this.bindingDataBillToForm();
      }
    });
  }

  ngOnDestroy() {
    if (this.apiReadySubscription) {
      this.apiReadySubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  onBtnClose() {
    this.closeDialog();
  }

  private closeDialog() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  private async fetchingDataFinAccountAndLatestBill() {
    const finAccountId = this.finQbStatement?.finAccountId;
    if (!finAccountId) {
      this.showWarning('', 'finAccount ID is not found');
      return;
    }
    this.isLoadingFinAccount = true;
    const url = Const.APIV2(`${Const.APIURI_FINANCES}/account/${finAccountId}/for-search-qb`);
    const resp = await this.api.GET(url).toPromise().catch(err => {
      this.showErr(err)
      this.isLoadingFinAccount = false;
    });
    this.finAccountData = resp?.data?.finAccount;
    this.carrierData = resp?.data?.carrier;
    this.qbVendorInfo = resp?.data?.finAccount?.quickbooksInfo;
    this.isLoadingFinAccount = false;

    if (!this.qbVendorInfo?.Id) return;
    const resp2 = await this.quickbookService.getLatestBillByVendor(this.qbVendorInfo.Id).catch(err => {
      this.handleErrorQueryQuickbooks(err);
    });
    this.qbLatestBill = resp2 ? resp2 : null;
  }

  private async fetchingDataQBTerms() {
    this.isFetchingTerms = true;
    const res = await this.quickbookService.getAllPaymentTerms().catch(err => {
      this.handleErrorQueryQuickbooks(err);
      this.isFetchingTerms = false;
    });
    if (res?.length) {
      this.qbPaymentTermOptions = res.map(item => ({
        id: item.Id,
        name: item.Name,
        DueDays: item.DueDays,
      }));
    }
    this.isFetchingTerms = false;
  }

  private async fetchingDataQBAccounts() {
    this.isFetchingCateroryAccount = true;
    const res = await this.quickbookService.getAllAccounts().catch(err => {
      this.handleErrorQueryQuickbooks(err);
      this.isFetchingCateroryAccount = false;
    });
    if (res?.length) {
      this.qbCategoryAccountOptions = res.map(item => ({
        id: item.Id,
        name: item.Name,
        accountType: item.AccountType,
        accountNum: item.AcctNum,
      }));
      const arr = Utils.cloneObject(this.qbCategoryAccountOptions);
      for (let option of arr) {
        let name = option.name?.replace(/\s{2,}/g, ' ')?.trim()?.toLowerCase() ?? '';
        if (name.includes(DefaultCategory51000)) {
          this.accountRefIdFor51000 = option.id;
        } else if (name.includes(DefaultCategory40100)) {
          this.accountRefIdFor40100 = option.id;
        }
      }
    }
    this.isFetchingCateroryAccount = false;
  }

  private async processShipmentCodes() {
    if (!this.finQbStatement) return;
    const shipments = this.finQbStatement?.jobs?.map(it => [...it.shipments])?.flat() ?? [];
    if (!shipments.length) return;
    let arr = shipments.map(shipment => {
      const hasCode = !!shipment?.code;
      const hasWarpId = !!shipment?.warpId;
      if (hasCode && hasWarpId) {
        if (!this.firstShipmentCode) { this.firstShipmentCode = shipment.code.replace('S-', ''); }
        return shipment.code.replace('S-', '');
      } else if (hasCode && !hasWarpId) {
        if (!this.firstShipmentCode) { this.firstShipmentCode = shipment.code.replace('S-', ''); }
        return shipment.code.replace('S-', '');
      } else if (!hasCode && hasWarpId) {
        if (!this.firstShipmentCode) { this.firstShipmentCode = `${shipment.warpId}`; }
        return shipment.warpId;
      }
      return '';
    });
    this.jobShipmentCodes = arr.filter(x => x).join(', ');
  }

  private async fetchingDataPaymentConfig() {
    const firstJobId = this.finQbStatement?.jobs?.[0]?.id;
    if (!firstJobId) return;
    const url = `${Const.APIV2(Const.APIURI_JOBS)}/${firstJobId}/payment-term-version`;
    const resp = await this.api.GET(url).toPromise().catch(err => {
      this.showErr(err);
    });
    if (resp?.data) {
      this.paymentTermVersionData = resp.data;
    } else {
      this.paymentTermVersionData = null;
    }
  }

  get isCreateNew(): boolean {
    return true;
  }

  get labelSearchVendor() {
    return this.qbVendorInfo?.Id ? 'Change vendor' : 'Search vendor';
  }

  get shouldShowFormCreateBill() {
    return !!this.qbVendorInfo?.Id;
  }

  get carrierCost() {
    return this.formatMoney(this.carrierCostValue);
  }

  get qbVendorDetailLink() {
    const vendorId = this.qbVendorInfo?.Id;
    if (!vendorId) return '';
    return this.isProduction ? `https://qbo.intuit.com/app/vendordetail?nameId=${vendorId}` : `https://sandbox.qbo.intuit.com/app/vendordetail?nameId=${vendorId}`;
  }

  formatMoney(value: number | string) {
    return InputHelper.formatMoney2(`${value || 0}`);
  }

  get getBalanceDue() {
    const fa = <FormArray>this.formInput.get('lines');
    let totalAmount: number = 0;
    for (let i=0; i<fa.length; i++) {
      const accountRefId = fa.at(i).get('accountRefId').value;
      if (!accountRefId) continue;
      let amount = fa.at(i).get('amount').value;
      if (amount && parseFloat(amount)) {
        totalAmount = totalAmount + parseFloat(amount);
      }
    }
    return this.formatMoney(totalAmount);
  }

  getAddOnInputAmount(index) {
    let fg = (<FormArray> this.formInput.get('lines')).at(index);
    const accountRefId = fg.get('accountRefId').value;
    if (!accountRefId) return '';
    const option = this.qbCategoryAccountOptions.find(item => item.id == accountRefId);
    if (option) {
      let name = option.name?.replace(/\s{2,}/g, ' ')?.trim()?.toLowerCase() ?? '';
      if (name.includes(DefaultCategory40100)) {
        const amount = fg.get('amount').value;
        let value = Math.abs(parseFloat(amount));
        const amount51000 = this.getAmountLine51000() || this.carrierCostValue;
        let percent = value / amount51000 * 100;
        return `${parseFloat(percent.toFixed(2))}%`;
      } else {
        return null;
      }
    }
    return null
  }

  getRouteCompletedWindow() {
    const window = this.finQbStatement?.routeCompletedWindow;
    if (!window) return 'N/A';
    const timezone = window.timezone || 'America/New_York';
    const format = 'MM/DD';
    return DateUtil.displayTimeWindow(window, {timezone, format, formatDateOnly: format});
  }

  getBillNoDefault() {
    const window = this.finQbStatement?.routeCompletedWindow;
    if (!window) return '';
    const timezone = window.timezone || 'America/New_York';
    const date = dayjs(window.to).add(1, 'day').toISOString();
    const isoDate = DateUtil.displayLocalTime(date, {timezone, format: 'MM/DD/YYYY'});
    return `IC Drivers ${isoDate}`;
  }

  private bindingDataBillToForm() {
    if (!this.formInput) return;
    if (this.jobShipmentCodes) {
      this.setItemValue('memo', `Driver Payment ${this.getRouteCompletedWindow()}\n${this.jobShipmentCodes} : ${this.jobCodes}`);
    }
    const billNoDefault = this.getBillNoDefault();
    if (billNoDefault) {
      this.setItemValue('billNo', billNoDefault);
    }
    if (!this.qbLatestBill) {
      // add new line 51000
      if (this.accountRefIdFor51000) {
        const data = {
          accountRefId: this.accountRefIdFor51000,
          description: this.jobShipmentCodes ? `Driver Payment ${this.getRouteCompletedWindow()}\n${this.jobShipmentCodes}` : '',
          amount: this.carrierCostValue,
        }
        this.setItemValue(`lines[0]`, data);
      }
      // lấy paymentTerms từ settings vendor
      if (this.qbVendorInfo?.TermRef?.value) {
        this.setItemValue('paymentTerms', this.qbVendorInfo.TermRef.value);
        let billDate = this.getItemValue('billDate') ?? new Date();
        const dueDays = this.qbPaymentTermOptions.find(item => item.id == this.qbVendorInfo.TermRef.value)?.DueDays;
        if (Utils.isNumber(dueDays)) {
          const dueDate = dayjs(billDate).add(dueDays, "day").toISOString();
          this.setItemValue(`dueDate`, dueDate);
          if (dueDays < 30) {
            if (dueDays === 20 && this.carrierData?.id === carrierPriority1Id) {
              this.removeCategory40100();
            } else {
              this.addNewCategory40100();
            }
          }
        }
      }
      return;
    }
    if (this.qbLatestBill.SalesTermRef?.value) {
      this.setItemValue('paymentTerms', this.qbLatestBill.SalesTermRef.value);
      let billDate = this.getItemValue('billDate') ?? new Date();
      const dueDays = this.qbPaymentTermOptions.find(item => item.id == this.qbLatestBill.SalesTermRef.value)?.DueDays;
      if (Utils.isNumber(dueDays)) {
        const dueDate = dayjs(billDate).add(dueDays, "day").toISOString();
        this.setItemValue(`dueDate`, dueDate);
      }
    }
    if (this.qbLatestBill.Line?.length) {
      const numOfLineCurrent = (<FormArray>this.formInput.get('lines')).length;
      const numOfLineLatestBill = this.qbLatestBill.Line.length;
      if (numOfLineCurrent < numOfLineLatestBill) {
        for (let i = 0; i < numOfLineLatestBill - numOfLineCurrent; i++) {
          this.addItemToFormArray('lines');
        }
      }
      const lines = _.sortBy(this.qbLatestBill.Line, 'Id');
      for (let i=0; i<lines.length; i++) {
        let accountRefId = lines[i].AccountBasedExpenseLineDetail?.AccountRef?.value;
        if (accountRefId) {
          this.setItemValue(`lines[${i}].accountRefId`, accountRefId);
          const option = this.qbCategoryAccountOptions.find(item => item.id == accountRefId);
          if (option) {
            let name = option.name?.replace(/\s{2,}/g, ' ')?.trim()?.toLowerCase() ?? '';
            if (name.includes(DefaultCategory51000)) {
              this.setItemValue(`lines[${i}].amount`, this.carrierCostValue);
            } else if (name.includes(DefaultCategory40100)) {
              const paymentTerm = this.getItemValue('paymentTerms');
              const dueDays = this.qbPaymentTermOptions.find(item => item.id == paymentTerm)?.DueDays;
              if (Utils.isNumber(dueDays)) {
                const percent = this.getPercentageFeeByDay(dueDays);
                if (Utils.isNumber(percent)) {
                  let value: any = this.carrierCostValue * percent / 100;
                  value = parseFloat(value.toFixed(2));
                  if (this.paymentTermVersionData?.isUseNewConfig && this.isCustomerApplyMinCharge) {
                    const minCharge = MinChargeByDueDay[dueDays];
                    if (value < minCharge) { value = minCharge}
                  }
                  this.setItemValue(`lines[${i}].amount`, 0 - value);
                }
              }
            }
          }
        }
        if (this.jobShipmentCodes) {
          this.setItemValue(`lines[${i}].description`, `Driver Payment ${this.getRouteCompletedWindow()}\n${this.jobShipmentCodes}`);
        }
      }
    }
  }

  private getAmountLine51000() {
    const fa = <FormArray>this.formInput.get('lines');
    for (let i=0; i<fa.length; i++) {
      const accountRefId = fa.at(i).get('accountRefId').value;
      if (!accountRefId) continue;
      if (accountRefId == this.accountRefIdFor51000) {
        return fa.at(i).get('amount').value;
      }
    }
    return 0;
  }

  private addNewCategory40100() {
    if (!this.accountRefIdFor40100) return;
    if (this.finQbStatement?.createBillOptions?.ignoreTermFee) {
      return;
    }
    const fa = <FormArray>this.formInput.get('lines');
    let isExistLine40100 = false;
    for (let i=0; i<fa.length; i++) {
      const accountRefId = fa.at(i).get('accountRefId').value;
      if (!accountRefId) continue;
      if (accountRefId == this.accountRefIdFor40100) {
        isExistLine40100 = true;
        break;
      }
    }
    if (isExistLine40100) { // xóa line cũ để add line mới có amount chuẩn
      this.removeCategory40100();
    }
    let paymentTerms = this.getItemValue('paymentTerms');
    const dueDays = this.qbPaymentTermOptions.find(item => item.id == paymentTerms)?.DueDays;
    const data = {
      accountRefId: this.accountRefIdFor40100,
      description: this.jobShipmentCodes ? `Driver Payment ${this.getRouteCompletedWindow()}\n${this.jobShipmentCodes}` : '',
      amount: 0,
    }
    const percent = this.getPercentageFeeByDay(dueDays);
    if (Utils.isNumber(percent)) {
      const amount51000 = this.getAmountLine51000() || this.carrierCostValue;
      let value: any = amount51000 * percent / 100;
      value = parseFloat(value.toFixed(2));
      if (this.paymentTermVersionData?.isUseNewConfig && this.isCustomerApplyMinCharge) {
        const minCharge = MinChargeByDueDay[dueDays];
        if (value < minCharge) { value = minCharge}
      }
      data.amount = 0 - value;
    }
    this.addItemToFormArray('lines', data);
  }

  private removeCategory40100() {
    const fa = <FormArray>this.formInput.get('lines');
    let indexLine40100: number = -1;
    for (let i=0; i<fa.length; i++) {
      const accountRefId = fa.at(i).get('accountRefId').value;
      if (!accountRefId) continue;
      if (accountRefId == this.accountRefIdFor40100) {
        indexLine40100 = i;
        break;
      }
    }
    if (indexLine40100 == -1) return;
    this.removeItemInFormArray('lines', indexLine40100);
  }

  onPaymentTermChange(event) {
    let billDate = this.getItemValue('billDate') ?? new Date();
    const dueDays = this.qbPaymentTermOptions.find(item => item.id == event)?.DueDays;
    if (Utils.isNumber(dueDays)) {
      const dueDate = dayjs(billDate).add(dueDays, "day").toISOString();
      this.setItemValue(`dueDate`, dueDate);
      if (dueDays < 30) {
        if (dueDays === 20 && this.carrierData?.id === carrierPriority1Id) {
          this.removeCategory40100();
        } else {
          this.addNewCategory40100();
        }
      } else {
        this.removeCategory40100();
      }
    }
  }

  onBillDateChange(event) {
    let billDate = this.getItemValue('billDate') ?? new Date();
    let paymentTerms = this.getItemValue('paymentTerms');
    const dueDays = this.qbPaymentTermOptions.find(item => item.id == paymentTerms)?.DueDays;
    if (Utils.isNumber(dueDays)) {
      const dueDate = dayjs(billDate).add(dueDays, "day").toISOString();
      this.setItemValue(`dueDate`, dueDate);
    }
  }

  getCategoryLineLabel(option: {id: string, name: string, accountType: string, accountNum: string}) {
    if (!option) return '';
    return option.accountNum ? `${option.accountNum} ${option.name}` : option.name;
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'lines.amount':
        return InputHelper.handleInputKeyPressNegativeDecimalNumber(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onInputChanged(event, key, index = 0) {
    switch (key) {
      case 'lines.amount': {
        let fg = (<FormArray> this.formInput.get('lines')).at(index);
        InputHelper.handleInputChangNegativeDecimalNumber(event, <FormControl>fg.get('amount'));
        const accountRefId = fg.get('accountRefId').value;
        if (accountRefId === this.accountRefIdFor51000) {
          this.addNewCategory40100();
        }
        return;
      }
      default:
        return super.onInputChanged(event, key);
    }
  }

  onBtnAddCategoryLine() {
    const data = this.jobShipmentCodes ? { description: `Driver Payment ${this.getRouteCompletedWindow()}\n${this.jobShipmentCodes}` } : {};
    this.addItemToFormArray('lines', data);
  }

  onBtnDeleteLine(index) {
    let fg = (<FormArray> this.formInput.get('lines')).at(index);
    const accountRefId = fg.get('accountRefId').value;
    let text = `${index+1}`;
    if (accountRefId) {
      const option = this.qbCategoryAccountOptions.find(item => item.id == accountRefId);
      text = option ? this.getCategoryLineLabel(option) : text;
    }
    this.confirmDeletion({
      message: `Remove line <b>${text}</b>?`,
      fnOk: () => {
        this.removeItemInFormArray('lines', index);
      }
    });
  }

  onChangeCategory(index) {
    let fg = (<FormArray> this.formInput.get('lines')).at(index);
    const description = fg.get('description').value;
    if (!description && this.jobShipmentCodes) {
      fg.get('description').setValue(`Driver Payment ${this.getRouteCompletedWindow()}\n${this.jobShipmentCodes}`);
    }
  }

  onBillNoChange(event: KeyboardEvent) {
    let inputText = (<HTMLInputElement>event.target).value;
    // get 21 characters first of inputText
    let text = inputText.slice(0, 21);
    const fc = <FormControl>this.formInput.get('billNo');
    if (text != (<HTMLInputElement>event.target).value) {
      (<HTMLInputElement>event.target).value = text;
      if (fc) {
        fc.setValue(text, { emitEvent: false });
      }
    }
  }

  copyMemoValue() {
    const memo = this.getItemValue('memo');
    if (!memo) {
      this.showWarning('', 'Memo is empty');
      return;
    }
    Utils.copyTextToClipboard(memo, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('Memo has already been copied to the clipboard');
      }
    })
  }

  // Attachment
  onAttachmentFileSelected(key, files) {
    let attachmentFiles = this.getItemValue(key) || [];
    const keyTxt = `${key}.${Utils.generateULID()}`;
    attachmentFiles.push(keyTxt);
    this.fileToUpload[keyTxt] = files[0];
    this.setItemValue(key, attachmentFiles);
  }

  getAttachedFileValue(key) {
    return this.getItemValue(key) || [];
  }

  hasAttachedFile(key) {
    return !!this.fileToUpload[key];
  }

  public labelSelectFile(key): string {
    return this.hasAttachedFile(key) ? this.getDocumentFileDesc(key) : null;
  }

  getDocumentFileDesc(key): string {
    if (!this.fileToUpload[key]) return "";
    return `${this.fileToUpload[key].name} (${this.displayFileSize(this.fileToUpload[key].size)})`;
  }

  delDocumentFile(key, fileKey, inputElement: HTMLInputElement) {
    if (!this.fileToUpload[fileKey]) return;
    this.confirmDeletion({
      message: `Delete file ${this.fileToUpload[fileKey].name}?`,
      txtBtnOk: "Delete",
      fnOk: () => {
        this.fileToUpload[fileKey] = undefined;
        let attachmentFiles = this.getItemValue(key) || [];
        attachmentFiles = attachmentFiles.filter((item) => item != fileKey);
        this.setItemValue(key, attachmentFiles);
        inputElement.value = "";
      },
    });
  }
  // End Attachment

  onBtnSearchQBVendor() {
    let afterClose = new EventEmitter();
    afterClose.subscribe(async () => {
      await this.fetchingDataFinAccountAndLatestBill();
      if (!this.qbLatestBill) {
        // reset form
        this.model = null;
        this.formInput = null;
        this.createFormInput();
      }
      this.bindingDataBillToForm();
    })
    this.modalService.create({
      nzTitle: 'Search Quickbooks Vendor',
      nzContent: SearchQuickbooksVendor,
      nzFooter: null,
      nzClosable: true,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzClassName: "modal-lg",
      nzWrapClassName: 'vertical-center-modal',
      nzAfterClose: afterClose,
      nzComponentParams: {
        accountInfo: {
          ...(this.carrierData ?? {}),
          finAccount: this.finAccountData,
        },
        selectedVendor: this.finAccountData?.quickbooksInfo,
        onCreateVendor: () => {
          this.onCreateQuickbooksVendor({ ...(this.carrierData ?? {}), finAccount: this.finAccountData });
        }
      }
    });
  }

  private onCreateQuickbooksVendor(accountInfo) {
    let afterClose = new EventEmitter();
    afterClose.subscribe(async () => {
      await this.fetchingDataFinAccountAndLatestBill();
      if (!this.qbLatestBill) {
        // reset form
        this.model = null;
        this.formInput = null;
        this.createFormInput();
      }
      this.bindingDataBillToForm();
    })
    this.modalService.create({
      nzTitle: 'Create Quickbooks Vendor',
      nzContent: CreateQuickbooksVendor,
      nzFooter: null,
      nzClosable: true,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzClassName: "modal-lg",
      nzWrapClassName: 'vertical-center-modal',
      nzAfterClose: afterClose,
      nzComponentParams: {
        accountInfo: accountInfo,
      }
    });
  }

  onBtnCreateBill() {
    if (!this.needUpdate || this.onProgress) return;
    if (!this.qbVendorInfo?.Id) {
      this.showWarning('', 'Please select vendor');
      return;
    }
    const data = this.getFormData_JSON(true);
    if (!data) return;
    const params: FormDataCreateQuickbooksBill = {
      isStatementBill: true,
      statementId: this.finQbStatement?.id,
      finJobIds: this.finQbStatement?.finJobIds,
      vendorId: this.qbVendorInfo.Id,
      paymentTerms: data.paymentTerms,
      billDate: DateUtil.dateToString(data.billDate, "YYYY-MM-DD"),
      dueDate: DateUtil.dateToString(data.dueDate, "YYYY-MM-DD"),
      billNo: data.billNo,
      memo: data.memo,
      attachmentFiles: data.attachmentFiles || undefined,
      lines: data.lines,
      noRequireInvoice: true, // Nếu sau dùng cho client khác walmart thì cần sửa lại
    }
    let formData = new FormData();
    formData.append("params", JSON.stringify(params));
    const keys = data.attachmentFiles ?? [];
    for (let key of keys) {
      if (!this.fileToUpload[key]) continue;
      formData.append(key, this.fileToUpload[key], this.fileToUpload[key].name);
    }
    //sử dụng backendUrlWithoutCDN nếu có để tránh lỗi 504 (gateway timeout)
    let url = `${environment.backendUrlWithoutCDN || environment.backendUrl}/${Const.APIV2(Const.APIURI_FINANCES)}/quickbooks/create-bill`;
    this.startProgress();
    return this.api.postFormData(url, formData, { timeout: 15 * 60 * 1000 }).subscribe(
      (resp) => {
        this.closeDialog();
        this.onSuccess(resp?.data ?? {});
        this.stopProgress();
      },
      (err) => {
        this.handleErrorQueryQuickbooks(err);
        this.stopProgress();
      }
    );
  }

  private getPercentageFeeByDay(dueDays: number): number | undefined {
    if (!this.paymentTermVersionData?.isUseNewConfig) {
      return Const.getPercentageFeeByDayLegacy(dueDays)
    } else {
      return Const.getPercentageFeeByDay(dueDays)
    }
  }

  public formatTimestampToDisplay(timestamp) {
    const date = new Date(timestamp).toISOString();
    return DateUtil.displayLocalTime(date, { timezone: 'America/Los_Angeles', format: Const.FORMAT_GUI_DATETIME_V4});
  }

  showStatementCode(code) {
    return code ? `ST-${code}` : 'N/A';
  }

  showPayToInfo() {
    if (!this.denimCompanyInfo) return 'N/A';
    const payToFactor = this.denimCompanyInfo.client_payee_relationship?.payment_setting?.to_factor ? true: false;
    if (payToFactor) return 'Factor';
    return 'Contractor';
  }

  private async fetchDenimCompany() {
    // Nếu đã map mới denim rồi thì không cần search nữa
    let existDenimInfo = this.finQbStatement?.account?.denimPaymentServiceInfo;
    if (existDenimInfo) {
      this.denimCompanyInfo = Array.isArray(existDenimInfo) ? existDenimInfo[0] : existDenimInfo;
      return;
    }
    const companyName = this.finQbStatement?.account?.name;
    if (!companyName) {
      this.showWarning('', 'companyName is missing');
      return;
    }
    const params = {
      query: companyName,
      companyType: 'payee',
    }
    let url = Const.APIURI_FINANCES_STATEMENT('seach-denim-company-relationship');
    url = Utils.appendQueryStringIntoUrl(url, params);
    const resp = await this.api.GET(url).toPromise().catch(err => {
      this.showErr(err);
    })
    const data = resp?.data?.list_data ?? [];
    this.denimCompanyInfo = data.find(it => it.company?.company_name == companyName);
  }
}
