import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";


@Component({
  selector: "resend-confirm-booking-dialog",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class ResendConfirmBookingDialog extends BaseDialog {
  @Input() jobId: string;
  @Input() contactName: string;
  @Input() emailContact: string;
  @Input() emailSubject: string;
  @Input() confirmLink: string;

  @Input() onSuccess: () => void;

  public isDisabledSenderEmail = true;
  public customSenderEmail = '';

  ngOnInit() {
    super.ngOnInit();
    this.customSenderEmail = this.emailContact;
  }

  get emailContent() {
    return `
      Hello ${this.contactName}!<br>
      You have a WARP route starting soon that requires your attention.<br>
      To confirm, please click the link below:<br>
      ${this.confirmLink}<br>
      Please Note:<br>
      - Failure to confirm may result in loss of the load.<br>
      - Tracking is required for this load via the WARP Driver App<br>
      If you have any questions or concerns please contact our WARP support team at dispatch@wearewarp.com, call (213) 267-1373 or reply this email.<br>
      Sincerely,<br>
      WARP Dispatcher team    
    `
  }

  public isSendMailLoading = false;
  onBtnSendEmail() {
    if (!this.jobId) {
      this.showErr("jobId is missing");
      return;
    }
    this.isSendMailLoading = true;
    let params = {};
    if (this.customSenderEmail !== this.emailContact) {
      params = { contactEmail: this.customSenderEmail };
    }
    let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.jobId}/send-email-checklist-task`);
    this.api.POST(url, params).subscribe({
      next: (resp) => {
        this.isSendMailLoading = false;
        this.showSuccess('Email has been sent successfully!');
        this.onSuccess();
        this.closeDialog();
      },
      error: (e) => {
        this.isSendMailLoading = false;
        this.showErr(e);
      }
    });
  }

  onEditSenderEmail() {
    this.isDisabledSenderEmail = !this.isDisabledSenderEmail;
  }
}