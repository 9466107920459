import { Component, ViewChild } from '@angular/core';
import {
  FormDataBatchShipmentRevenueArray,
  FormDataShipmentEntryDraftBatch
} from '@wearewarp/types-server-admin/form-data/shipment-entry';
import { ComponentForm } from '../../../interface';
import { BaseForm } from '@app/admin/base/form-base';
import { FormBatchShipmentRevenue } from '../../forms/batch-shipment-revenue';
import { ShipmentEntryTabContent } from '../../tab-content-component';
import { Const } from '@const/Const';
import { Utils } from '@services/utils';
import { Log } from '@services/log';

@Component({
  selector: '[shipment-entry-batch-revenue]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ShipmentEntryBatchRevenue extends ShipmentEntryTabContent implements ComponentForm<FormDataBatchShipmentRevenueArray>{
  @ViewChild('formComp') formComp: FormBatchShipmentRevenue;

  getForm(): BaseForm<FormDataBatchShipmentRevenueArray> {
    return this.formComp;
  }

  get modelDraft(): FormDataShipmentEntryDraftBatch {
    return super.modelDraft;
  }

  shipmentEntryMode;

  onTabActive(active: boolean): void {
    if (active) {
      Log.d('onTabActive BatchRevenue');
      this.shipmentEntryMode = this.modelDraft?.shipmentEntryMode;
      if (this.modelDraft?.revenueInfos) {
        this.formModel = {
          settings: {
            isDoNotInvoice: this.modelDraft?.settings?.isDoNotInvoice,
            reasonDoNotInvoice: this.modelDraft?.settings?.reasonDoNotInvoice
          },
          revenueInfos: this.modelDraft.revenueInfos
        }
      } else this.initBatchRevenueModel();
    }
  }
  
  private _formModel: FormDataBatchShipmentRevenueArray;
  get formModel(): FormDataBatchShipmentRevenueArray {
    return this._formModel;
  }
  set formModel(value) {
    this._formModel = value;
  }

  initBatchRevenueModel() {
    let dataModel: FormDataBatchShipmentRevenueArray = {
      revenueInfos : []
    };
    switch (this.shipmentEntryMode) {
      case Const.ShipmentEntryMode.multiPick: {
        let clientId = this.modelDraft?.basicInfo?.clientId || '';
        let type = Const.CostLevel.order;
        let deliveryInfos = [];
        let pickInfos = this.modelDraft?.pickInfos;
        let dropInfo = this.modelDraft?.dropInfo;
        if (Utils.isArrayNotEmpty(pickInfos)) deliveryInfos = [ ...pickInfos ];
        if (dropInfo) deliveryInfos.push(dropInfo);
        dataModel.revenueInfos.push({ clientId, type, deliveryInfos, revenues: [{}] });
        break;
      }
      case Const.ShipmentEntryMode.multiDrop: {
        let clientId = this.modelDraft?.basicInfo?.clientId || '';
        let type = Const.CostLevel.order;
        let deliveryInfos = [];
        let pickInfo = this.modelDraft?.pickInfo;
        let dropInfos = this.modelDraft?.dropInfos;
        if (Utils.isArrayNotEmpty(dropInfos)) deliveryInfos = [ ...dropInfos ];
        if (pickInfo) deliveryInfos.unshift(pickInfo);
        dataModel.revenueInfos.push({ clientId, type, deliveryInfos, revenues: [{}] });
        break;
      }
      case Const.ShipmentEntryMode.multiPickDrop:
        let clientDic = {};
        let count = -1;
        for (let shipment of this.modelDraft?.shipments || []) {
          // vì chưa có clientId trong shipment, tạm thời lấy clientId ở basicInfo, đợi update interface
          shipment['clientId'] = this.modelDraft?.basicInfo?.clientId;
          count += 1;
          shipment['index'] = count;
          if (!shipment['clientId']) continue;
          let clientId = shipment['clientId'];
          if (!Object.keys(clientDic).includes(clientId)) {
            clientDic[clientId] = {
              clientId: clientId,
              type: Const.CostLevel.order,
              shipments: [shipment],
              revenues: [{}]
            }
          } else {
            clientDic[clientId].shipments.push(shipment);
          }
        }
        for (let key of Object.keys(clientDic)) {
          dataModel.revenueInfos.push(clientDic[key]);
        }
        break;
    }
    this.formModel = dataModel;
  }
  
}