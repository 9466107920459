import { Component, Input } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Const } from "@const/Const";
import { InputHelper } from "@services/input-helper";
import { MasterData } from "@services/master.data";
import { NzModalRef } from "ng-zorro-antd/modal";


@Component({
  selector: 'create-denim-contractor',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class CreateDenimContractor extends BaseFormItem {
  @Input() accountInfo;
  @Input() createSuccess: (resp) => void = () => {};

  protected formGroupDeclaration: FormGroupDeclaration = {
    companyInfo: {label: '', type: 'formGroup', childItem: {
      dotNumber: { label: "DOT Number" },
      mcNumber: { label: "MC Number" },
      companyName: { label: "Company name", required: true },
      city: {label: 'City', required: true },
      state: {label: 'State', required: true },
      countryAlpha2Code: {label: 'Country', required: true, initialValue: Const.CountryCodeAlpha2_USA},
      ein: {label: 'EIN (IRS Employer ID)' },
    }},
    hasFactoring: {label: 'Does this contractor have a factor?', required: true, initialValue: false },
    clientInfo: {label: '', type: 'formGroup', childItem: {
      firstName: { label: "First Name", required: true },
      lastName: { label: "Last Name", required: true },
      email: { label: "Invoicing / Remittance Email", required: true, validators: Validators.email },
      phone: { label: "Phone", required: true, inputType: 'tel', formatValue: InputHelper.formatPhone },
    }},
    payee: {label: '', type: 'formGroup', childItem: {
      factorCompanyId: {label: 'Factoring Company', required: false, type: 'number' },
    }},
    dtpSetting: {label: 'Days to Payment (DTP)', required: true, type: 'formGroup', childItem: {
      type: {label: '', required: true, initialValue: 'default'},     // value: default / custom
      daysToPayOffset: {label: 'Days to Payment (calendar days)', type: 'number', required: false, placeHolder: 'Enter days' },
      quickPayRate: {label: 'Quick Pay Fee', type: 'number', required: false, placeHolder: 'Enter %' },
    }}
  }

  // Xử lý riêng cho dtpSetting
  groups = [
    { key: 'companyInfo', type: 'group', rows: [
      { keys: ['dotNumber', 'mcNumber'], span: 12 },
      { keys: ['companyName'], span: 24 },
      { keys: ['countryAlpha2Code'], span: 24 },
      { keys: ['city', 'state'], span: 12 },
      { keys: ['ein'], span: 24 },
    ]},
    { key: 'hasFactoring', type: 'single'},
    { key: 'clientInfo', type: 'group', rows: [
      { keys: ['firstName', 'lastName'], span: 12 },
      { keys: ['phone', 'email'], span: 12 },
    ]},
    { key: 'payee', type: 'group', rows: [
      { keys: ['factorCompanyId'], span: 24 },
    ]}
  ]

  countriesArr = [
    { label: 'United States of America', value: 'USA' },
  ]

  isLoading: boolean = false;
  allStates = MasterData.getStatesUS();
  loadingFactorCompany: boolean = false;
  factorCompanies = [];

  constructor(private modal: NzModalRef) {
    super();
  }

  get isCreateNew(): boolean {
    return true;
  }

  get isCustomTerms() {
    return this.getItemValue('dtpSetting.type') === 'custom';
  }

  get isHasFactoring() {
    return this.getItemValue('hasFactoring') === true;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
  }

  beforeBindModel(model) {
    const basicInfo = model?.basicInfo || {};
    const contacts = basicInfo?.contacts || [];
    const defaultContact = {
      firstName: contacts[0]?.firstName || '',
      lastName: contacts[0]?.lastName || '',
      phone: contacts[0]?.phone || '',
      email: contacts[0]?.email || '',
    }
    const payableContact = model?.payment?.payableContact || defaultContact;
    
    model = {
      companyInfo: {
        mcNumber: basicInfo.mc,
        dotNumber: basicInfo.dot,
        companyName: basicInfo.name,
        city: basicInfo.address?.city,
        state: basicInfo.address?.state,
        countryAlpha2Code: 'USA'
      },
      clientInfo: {
        firstName: payableContact.firstName,
        lastName: payableContact.lastName,
        email: payableContact.email,
        phone: payableContact.phone,
      }
    }
    return model;
  }

  loadData() {
    this.loadCarrierModel();
    this.getFactorCompanies();
  }

  private loadCarrierModel() {
    this.isLoading = true;
    const carrierId = this.accountInfo?.id;
    const url = `${Const.APIURI_CARRIERS}/${carrierId}`;
    this.api.GET(url).subscribe(
      resp => {
        this.isLoading = false;
        this.model = resp?.data;
        this.createFormInput(this.model);
      }, err => {
        this.isLoading = false;
      }
    );
  }

  private getFactorCompanies() {
    const url = `${Const.APIV2(Const.APIURI_CARRIERS)}/factor-companies`;
    this.loadingFactorCompany = true;
    this.api.GET(url).subscribe(
      resp => {
        this.loadingFactorCompany = false;
        this.factorCompanies = (resp?.data?.list_data || []).map(it => {
          const company = it.company || {};
          return {
            label: company.company_name,
            value: company.id
          }
        });
      }, err => {
        this.loadingFactorCompany = false;
        this.showErr(err);
      }
    );
  }

  shouldShowGroup(groupKey) {
    let isHasFactoring = this.getItemValue('hasFactoring');
    switch (groupKey) {
      case 'payee':
        return isHasFactoring === true;
      case 'clientInfo':
        return isHasFactoring === false;
      default:
        return true;
    }
  }

  onChange(key, value) {
    if (key === 'hasFactoring') {
      // update clientInfo
      this.formGroupDeclaration.clientInfo.childItem['firstName'].required = !value;
      this.formGroupDeclaration.clientInfo.childItem['lastName'].required = !value;
      this.formGroupDeclaration.clientInfo.childItem['email'].required = !value;
      this.formGroupDeclaration.clientInfo.childItem['phone'].required = !value;
      // update payee
      this.formGroupDeclaration.payee.childItem['factorCompanyId'].required = value;
      this.resetValidateField('clientInfo');
      this.resetValidateField('payee');
    }
    else if (key === 'dtpSetting.type') {
      if (value === 'custom') {
        this.formGroupDeclaration.dtpSetting.childItem['daysToPayOffset'].required = true;
      }
      else {
        this.formGroupDeclaration.dtpSetting.childItem['daysToPayOffset'].required = false;
      }
      this.resetValidateField('dtpSetting');
    }
  }

  private resetValidateField(key: string) {
    let fc = this.formInput.get(key);
    if(!fc) return;
    const childKeys = Object.keys(this.formGroupDeclaration[key].childItem || {});
    if(childKeys.length) {
      for (let childKey of childKeys) {
        let fcc = fc.get(childKey);
        if(!fcc) continue;
        if (!this.formGroupDeclaration[key].childItem[childKey].required) {
          fcc.removeValidators(Validators.required);
        } 
        else {
          fcc.setValidators(Validators.required);
        }
        fcc.updateValueAndValidity();
      }
      return;
    }

    if (!this.formGroupDeclaration[key].required) fc.removeValidators(Validators.required);
    else fc.addValidators(Validators.required);
    fc.updateValueAndValidity();
  }

  private closeDialog() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  onInputChanged(event, key) {
    if (key === "clientInfo.phone") {
      InputHelper.handleInputChangePhone(
        event,
        <FormControl>this.formInput.get(key)
      );
    }
  }

  private getDataForCreate() {
    let data = this.getFormData_JSON(true);
    if (!data) return null;
    if (data.hasFactoring) {
      delete data.clientInfo;
      delete data.dtpSetting.quickPayRate; // factoring thì không cần quickPayRate
    }
    else {
      delete data.payee;
    }
  
    let dtpSetting = data.dtpSetting;
    if (dtpSetting?.type === 'default') {
      delete dtpSetting.daysToPayOffset;
      delete dtpSetting.quickPayRate;
    }
    if (dtpSetting.quickPayRate) {
      dtpSetting.quickPayRate = dtpSetting.quickPayRate * 100;
    }
    data.dtpSetting = dtpSetting;
    return data;
  }

  onBtnCreateContractor() {
    let data = this.getDataForCreate();
    if (!data) return;
    const url = Const.APIV2(`${Const.APIURI_FINANCES}/denim/create-contractor`);
    this.startProgress();
    this.api.POST(url, data).subscribe(
      (resp) => {
        this.stopProgress();
        this.showSuccess(resp);
        this.closeDialog();  
        this.createSuccess(resp);
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }
}