import { AdminAuthUser } from '@wearewarp/types-server-admin';
import { DashboardComponent } from '@app/admin/dashboard.component';
import { NavigationEndData, NavigationUrlData } from '@app/interfaces';
import { Subscription } from 'rxjs';
export * from './injector';

export interface App {
  loginSucceeded(): Promise<void>;
  subscribeWindowResize(observerOrNext?: any, error?: any, complete?: any): Subscription;
  subscribeNavigationEnd(observerOrNext?: any, error?: any, complete?: any): Subscription;
  getCurrentNavigationUrlData(): NavigationUrlData;
  createNavigationEndData(): NavigationEndData;
  getAuthUser(): AdminAuthUser;
  myProfileChanged(): Promise<void>;
  scrollToTop(): void;
  isFirstLoadingOK(): boolean;
}

let appComponent: App;
let dashboard: DashboardComponent;

export function setApp(app: App) {
  appComponent = app;
}

export function getApp(): App {
  return appComponent;
}

export function setDashboard(comp: DashboardComponent) {
  dashboard = comp;
}

export function getDashboard(): DashboardComponent {
  return dashboard;
}