import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { DialogsModule } from '@dialogs/dialogs.module';
import { DetailModule } from '../base/detail.module';
import { FormAddressUSModule } from '../base/form-address-us/module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { SharedModule } from "../shared/shared.module";
import { FormInputCostModule } from '../base/form-input-cost/module';
import { CommLogModule } from '../components/comm-log/index.module';
import { UiCommonModule } from '../components/common/module';
import { ModuleRating } from '../components/rating/module';
import { LoadingModule } from '../components/loading-bar/module';
import { CarrierSalesResponseModule } from '../report/carrier-sales-response/module';
import { CarrierSaleBidInfo } from './component/bid-info';
import { CarrierSaleDetailComponent } from './detail';
import { CarrierSaleRouteInfo } from './component/bid-info/route-info';
import { ModuleJobStopTimeline } from '../components/carrier-bid/job-stop-timeline/module';
import { CarrierSaleEquipment } from './component/bid-info/equipment';
import { CarrierSaleBidSetting } from './component/bid-info/bid-setting';
import { CarrierSaleWarpOffer } from './component/bid-info/warp-offer';
import { CarrierSaleMarketRate } from './component/bid-info/market-rate';
import { CarrierSaleRate } from './component/bid-info/rate';
import { CarrierSaleSameLaneLoad } from './component/bid-info/same-lane-load';
import { CalendarSameLaneModule } from '../components/carrier-bid/calendar-same-lane/module';
import { ViewPreviousRouteModule } from '../components/carrier-bid/view-previous-route/module';
import { TruckLoadEvolutionModule } from '../components/truck-load-evolution/module';
import { CarrierSaleAcceptedPrice } from './component/carriers/accepted-price';
import { CarrierSaleCarriers } from './component/carriers';
// import { CarrierSaleCarrierResult } from './component/carriers/carrier-result';
import { CarrierSaleOutreach } from './component/outreach';
import { CarrierSaleWave } from './component/outreach/matched-carrier/ware';
import { DetailBidOutreachAnalytics } from './component/outreach/matched-carrier/ware/analytics';
import { ListExternalPosting } from './component/outreach/external-posting/list';
import { ExternalPostingDetailDialog } from './component/outreach/external-posting/create-post';
import { ThirdPartyPostModule } from '../third-party-post/module';
import { DetailBidWareItem } from './component/outreach/matched-carrier/ware/ware-item';
import { AddCarrier } from './component/carriers/add-carrier';
import { AddCarrierPool } from './component/carriers/add-carrier-pool';
import { CarrierSalePoolFilter } from './component/carriers/select-list-carrier-pools/filter';
import { CarrierSalePoolList } from './component/carriers/select-list-carrier-pools';
import { AddCarrierByServiceAreaModule } from './component/carriers/add-carrier-by-area/module';
import { AddCarrierPoolByAreaModule } from './component/carriers/add-carrier-pool-by-area/module';
import { NoteList } from './component/carriers/notes';
import { FormInputUploadImagesModule } from '../base/form-input-upload-images/module';
import { SendASAPDlg } from './component/carriers/send-asap';
import { CreateByUserModule } from '../components/create-by-user/module';
// import { CarrierSaleTabResponseReceived } from './component/carriers/carrier-result/result-tab-response-received';
import { CarrierSaleResultModule } from './component/carriers/carrier-result/module';
import { ModuleBasicRouteInfo } from '../dispatch/components/basic-route-info/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzTagModule,
    NzSwitchModule,
    NzDropDownModule,
    NzTableModule,
    NzPopoverModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    CommLogModule,
    NzInputModule,
    NzFormModule,
    NzPaginationModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzBadgeModule,
    NzAutocompleteModule,
    NzToolTipModule,
    NzTypographyModule,
    SearchBoxModule,
    DialogsModule,
    DetailModule,
    FormAddressUSModule,
    SelectBySearchingModule,
    SharedModule,
    FormInputCostModule,
    UiCommonModule,
    ModuleRating,
    ModuleBasicRouteInfo,
    LoadingModule,
    CarrierSalesResponseModule,
    ModuleJobStopTimeline,
    CalendarSameLaneModule,
    ViewPreviousRouteModule,
    ThirdPartyPostModule,
    CarrierSalesResponseModule,
    AddCarrierByServiceAreaModule,
    AddCarrierPoolByAreaModule,
    FormInputUploadImagesModule,
    CreateByUserModule,
    CarrierSaleResultModule,
    TruckLoadEvolutionModule
  ],
  declarations: [
    CarrierSaleDetailComponent,
    CarrierSaleBidInfo,
    CarrierSaleRouteInfo,
    CarrierSaleEquipment,
    CarrierSaleBidSetting,
    CarrierSaleWarpOffer,
    CarrierSaleMarketRate,
    CarrierSaleRate,
    CarrierSaleSameLaneLoad,
    CarrierSaleCarriers,
    CarrierSaleAcceptedPrice,
    CarrierSaleOutreach,
    CarrierSaleWave,
    DetailBidOutreachAnalytics,
    ListExternalPosting,
    ExternalPostingDetailDialog,
    DetailBidWareItem,
    AddCarrier,
    AddCarrierPool,
    CarrierSalePoolFilter,
    CarrierSalePoolList,
    NoteList,
    SendASAPDlg,
  ],
  exports: [

  ],
  providers: [
  ]
})
export class CarrierSaleDetailModuleV2 {}
