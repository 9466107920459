import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { DialogService } from "@dialogs/dialog.service";
import { ResponseAdminFinStatementDetail } from "@wearewarp/types-server-admin/fin";
import _ from 'underscore'
import { Utils } from "@services/utils";
import { CreateDenimJob } from "../denim/create-denim-job";
import { SearchDenimJobInput } from "../denim/search-job";
import { DenimJobCreateSuccessfully } from "../denim/success-dialog";
import { InputHelper } from "@services/input-helper";

@Component({
  selector: 'detail-fin-statement',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class DetailFinStatement extends BaseDialog {

  @Input() id;
  @Input() onRefresh: () => void;
  public displayInfo: any = {}

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
  }

  private getData() {
    if (!this.id) return;
    const url = Const.APIURI_FINANCES_STATEMENT(this.id);
    this.api.GET(url).subscribe(
      (resp) => {
        this.buildDisplayInfo(resp?.data ?? {});
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  private buildDisplayInfo(data: ResponseAdminFinStatementDetail) {
    // group theo finaccount
    let receivableGroupNames = _.uniq(data.receivables?.map(item => item.account?.name) ?? []).filter(x => x);
    let receivableGroups = _.sortBy(receivableGroupNames.map(g => {
      return {
        name: g,
        account: data.receivables?.find(it => it.account.name === g)?.account,
        childrens: data.receivables?.filter(it => it.account.name === g),
      }
    }), "name");
    let payableGroupNames = _.uniq(data.payables?.map(item => item.account?.name) ?? []).filter(x => x);
    let payableGroups = _.sortBy(payableGroupNames.map(g => {
      return {
        name: g,
        account: data.payables?.find(it => it.account.name === g)?.account,
        childrens: data.payables?.filter(it => it.account.name === g),
      }
    }), "name");
    this.displayInfo = {
      id: data.id,
      code: `ST-${data.code}`,
      receivableGroups,
      payableGroups,
      paymentServiceInfo: data.paymentServiceInfo,
      proofOfPaymentFiles: (data.proofOfPaymentFiles ?? []).map(item => ({
        ...item,
        imgUrl: this.attachedFileUrl(item),
      })),
      isFixedRate: data.isFixedRate,
    }
    if (data.fixedRateInfo?.account?.id) {
      this.displayInfo.fixedRateInfo = {
        ...data.fixedRateInfo,
        paymentFixedRate: `${this.formatMoney(data.fixedRateInfo.rate)} ${this.getPaymentFixedRateTypeName(data.fixedRateInfo.type)}`,
        numOfWorkingDays: data.fixedRateInfo.workingDays?.length || 0,
      }
    }
  }

  public get isPaymentServiceDenim() {
    return this.displayInfo?.paymentServiceInfo?.name == 'Denim';
  }

  public getQBBillLink(quickbooksBillId) {
    if (!quickbooksBillId) return '';
    const baseQBURL = this.isProduction ? 'https://qbo.intuit.com' : 'https://sandbox.qbo.intuit.com';
    return `${baseQBURL}/app/bill?txnId=${quickbooksBillId}`;
  }

  getBatchRouterLink(batch: { id: string, code: string}, type: 'AP'|'AR') {
    if (type == 'AP') return [Const.routeAdminFinAP, batch?.id];
    else return [Const.routeAdminFinAR, batch?.id];
  }

  showBatchCode(batch: { id: string, code: string}, type: 'AP'|'AR') {
    if (type == 'AR') return `AR-${batch?.code}`;
    else if (type == 'AP') return `AP-${batch?.code}`;
    else return batch?.code;
  }

  routerLinkBizEntity(relatedBiz: { id: string, code: string}, type: 'AP'|'AR') {
    if (type == 'AP') {
      return [Const.routeAdminDispatchList, relatedBiz?.id];
    } else if (type == 'AR') {
      return [Const.routeAdminOrderList, relatedBiz?.id];
    }
  }

  routerLinkAccount(account: { entityId: string }, type: 'AP'|'AR') {
    if (type == 'AP') {
      return [Const.routeAdminCarrierList, account?.entityId];
    } else if (type == 'AR') {
      return [Const.routeAdminClientList, account?.entityId];
    }
  }

  viewImageItem(imgUrl) {
    if (!imgUrl) return;
    DialogService.previewImgs([imgUrl], 0);
  }

  get canCreateDenimJob() {
    return this.isPaymentServiceDenim && !this.displayInfo.paymentServiceInfo?.denimJobId;
  }

  onBtnCreateDenimJob() {
    if (!this.canCreateDenimJob) return;
    this.closeDialog();
    DialogService.openFormDialog1(CreateDenimJob, {
      nzComponentParams: {
        statementId: this.id,
        onSuccess: (denimJob) => {
          DialogService.openDialog(DenimJobCreateSuccessfully, {
            nzWidth: 400,
            nzClosable: false,
            nzMaskClosable: false,
            nzComponentParams: {
              model: denimJob
            },
          });
          this.onRefresh();
        }
      },
      nzClassName: "modal-xxl",
    });
  }

  onBtnCheckDenimJobWithRef() {
    let refNum = this.displayInfo.paymentServiceInfo?.refs?.[0];
    let orderId = this.displayInfo.receivableGroups?.[0]?.childrens?.[0].relatedBiz?.id;
    if (!refNum && this.displayInfo.receivableGroups?.[0]?.childrens?.[0].relatedBiz?.code) {
      refNum = this.displayInfo.receivableGroups?.[0]?.childrens?.[0].relatedBiz?.code;
    }
    DialogService.openFormDialog1(SearchDenimJobInput, {
      nzComponentParams: {
        model: { refNum, orderId },
        onSearch: (refNum) => {
          const params = { query: refNum }
          let url = Const.APIURI_FINANCES_STATEMENT('check-denim-job-exist');
          url = Utils.appendQueryStringIntoUrl(url, params);
          return this.api.GET(url);
        },
        onAssign: (data) => {
          let url = Const.APIURI_FINANCES_STATEMENT(`${this.id}/assign-denim-job`);
          return this.api.POST(url, data);
        },
        onRefresh: (resp) => {
          this.getData();
        }
      },
      nzClassName: "modal-lg",
    });
  }

  formatMoney(value: number | string) {
    return InputHelper.formatMoney2(`${value || 0}`);
  }

  public getPaymentFixedRateTypeName(type) {
    switch (type) {
      case 'perDay':
        return 'per Day';
      case 'perWeek':
        return 'per Week';
      case 'perMonth':
        return 'per Month';
      default:
        return '';
    }
  }

}
