<form class="form-detail" [formGroup]="formInput" nz-form nzLayout="vertical">
  <div nz-row>
    <div nz-col [nzSpan]="24">
      <div class="form-label-v2">
        Name<i>(must be unique)</i>
        <span *ngIf="isRequired('name')" class="label-mark-required"></span>
      </div>

      <nz-form-item>
        <nz-form-control>
          <input nz-input 
            maxlength="50"
            formControlName="name"
            [type]="getInputType('name')"
            [placeholder]="getPlaceHolder('name')"
            (input)="onInputChanged($event, 'name')"
          />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24">
      <div class="form-label-v2">
        Short Description <i>(showed on list screen)</i>
        <span *ngIf="isRequired('descShort')" class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control>
          <textarea 
            nz-input 
            maxlength="100"
            [placeholder]="'Ex: Same day delivery, barcode scanning required, GPS location tracking required, high commitment - high paid'"
            [nzAutosize]="{ minRows: 2, maxRows: 3 }"
            formControlName="descShort"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24">
      <div class="form-label-v2">
        Long Description <i>(showed on detail screen)</i>
        <span *ngIf="isRequired('descLong')" class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control>
          <textarea 
            nz-input 
            maxlength="200"
            [placeholder]="'Ex: Same day delivery, barcode scanning required, GPS location tracking required, high commitment - high paid'"
            [nzAutosize]="{ minRows: 3, maxRows: 6 }"
            formControlName="descLong"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24">
      <div class="form-label-v2">
        Time frame
        <span *ngIf="isRequired('timeFrame')" class="label-mark-required"></span>
        <div formGroupName="timeFrame" [ngClass]="'timeFrame'">
          <ng-container *ngFor="let key of ['range', 'timezone']">
            <ng-container [ngSwitch]="key">

              <nz-form-item *ngSwitchCase="'range'">
                <nz-form-control>
                  <nz-range-picker
                    [nzFormat]="'yyyy-MM-dd'"
                    [nzShowToday]="true"
                    [nzPlaceHolder]="['Start Time', 'End Time']"
                    formControlName="range"
                    (ngModelChange)="onInputChanged($event, 'range')"
                  ></nz-range-picker>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item *ngSwitchCase="'timezone'">
                <nz-form-control>
                  <nz-select formControlName="timezone" style="width: 100%; min-width: 200px;">
                    <nz-option 
                      *ngFor="let it of timezones" 
                      [nzLabel]="it" 
                      [nzValue]="it"
                    >
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</form>

<div form-footer [onProgress]="onProgress"
  [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress"
  [canClickCancel]="!onProgress" [labelOK]="'Save'"
  (onOK)="onBtnSave()" (onCancel)="closeDialog()"
></div>