import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { BidSessionOutreach } from '.';
import { BidSessionWaveModule } from './matched-carrier/ware/module';
import { BulkExternalPostingModule } from './bulk-external-posting/module';

@NgModule({
  imports: [
    CommonModule,
    NzTabsModule,
    NzIconModule,
    NzButtonModule,
    BidSessionWaveModule,
    BulkExternalPostingModule
  ],
  declarations: [
    BidSessionOutreach
  ],
  exports: [
    BidSessionOutreach
  ],
  providers: [
  ]
})
export class BidSessionOutreachModule { }