<div class="group">
    <span class="moment-type-option clickable" [class]="{'selected': momentType === opt.value}"
        *ngFor="let opt of momentTypeOptions" (click)="onMomentTypeChange(opt.value)">{{ opt.label }}</span>
</div>
<div *ngIf="setOfCheckedId.size" class="top10">
    <button class="right10" nzType="primary" nz-button (click)="onRollShipments()">Roll Selected Shipments</button>
    <button nzType="primary" nz-button (click)="onAddShipmentsToPlanning()">Add to Planning</button>
</div>
<ng-container *ngIf="tab == 'verificationNeeded'">
    <nz-table #basicTable1 [nzData]="list" nzFrontPagination="true" nzPaginationPosition = "top" nzSize="small" [nzShowTotal]>
        <thead>
            <tr>
                <!-- <th [nzChecked]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th> -->
                <th style="text-align: center;">
                    <span *ngIf="setOfCheckedId.size" class="clickable" (click)="setOfCheckedId.clear()">
                        <span style="color: #888;" nz-icon nzType="close" nzTheme="outline"></span>
                    </span>
                </th>
                <th nzWidth="20px"></th>
                <th nzWidth="150px">WARP ID</th>
                <th nzWidth="90px">Order</th>
                <th>Customer</th>
                <th>Validation</th>
                <th>Status</th>
                <th>Pickup</th>
                <th>Delivery</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of basicTable1.data"
                [class]="{'INVALID': data.issue, 'EXCLUDED': data.excluded, 'PLANNED': data.session}"
                (mouseenter)="hoverShipment.emit(data.id)" (mouseleave)="hoverShipment.emit(null)"
                (click)="clickShipment.emit(data.id)">
                <!-- <td>
                    <span nz-icon nzType="delete" nzTheme="outline" class="clickable" (click)="removeShipment($event, data.id)"></span>
                </td> -->
                <td [nzChecked]="setOfCheckedId.has(data.id)" [nzDisabled]="data.excluded"
                    (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
                <td>
                    <span nz-dropdown [nzDropdownMenu]="menu">
                        <span nz-icon nzType="ellipsis" nzTheme="outline"></span>
                    </span>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu nzSelectable>
                            <li nz-menu-item (click)="removeShipment($event, data.id)">
                                <span nz-icon nzType="delete" nzTheme="outline" class="right5"></span> Remove
                            </li>
                            <li nz-menu-item (click)="updateShipmentReviewStatus($event, data)">
                                <span nz-icon nzType="file-done" nzTheme="outline" class="right5"></span> Update Review
                                Status
                            </li>
                            <li nz-menu-item [nzDisabled]="data.issue || data.onHold"
                                (click)="addToPlanning($event, data.id)">
                                <span nz-icon nzType="plus" nzTheme="outline" class="right5"></span>
                                Add to Planning
                            </li>
                            <li nz-menu-item (click)="removeRoutingRequirement($event, data)">
                                <span nz-icon nzType="file-done" nzTheme="outline" class="right5"></span> Routing no
                                longer needed
                            </li>
                            <li nz-menu-item (click)="onBtnRollToDate(data)">
                                <span nz-icon nzType="hourglass" nzTheme="outline" class="right5"></span> Move To
                                another Date
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                </td>
                <td>
                    <i nz-icon nzType="loading" nzTheme="outline" *ngIf="data.addingStatus=='ADDING'"></i>
                    <i nz-icon nzType="check" style="color: green;" nzTheme="outline"
                        *ngIf="data.addingStatus=='ADDED'"></i>
                    <i nz-icon nzType="warning" style="color: red;" nzTheme="outline"
                        *ngIf="data.addingStatus=='ERROR'"></i>
                    <span class="clickable right5" (click)="onCopyId($event, data.code)">{{ data.code }}</span>
                    <a target="_blank" *ngIf="!data.orderId" [routerLink]="[routeAdminOrderList]"
                        [queryParams]="{search: data.code}">
                        <span nz-icon nzType="link" nzTheme="outline"></span>
                    </a>
                    <a target="_blank" *ngIf="data.orderId" [routerLink]="[routeAdminOrderList, data.orderId]">
                        <span nz-icon nzType="link" nzTheme="outline"></span>
                    </a>
                </td>
                <td>
                    <span class="clickable right5" (click)="onCopyId($event, data.orderWarpId)">{{ data.orderWarpId ||
                        '' }}</span>
                    <ng-container *ngIf="data.parentId">
                        <span nz-popover nzPopoverTitle="Transit Network" nzPopoverTrigger="click"
                            [nzPopoverContent]="contentTemplate" nzPopoverPlacement="rightBottom">
                            <span style="color: #888;" nz-icon nzType="partition" nzTheme="outline"></span>
                        </span>
                        <ng-template #contentTemplate>
                            <div transit-network [id]="data.parentId" [viewOnly]="true"></div>
                        </ng-template>
                    </ng-container>
                </td>
                
                <!-- <td>
                    {{ data.transitType }}
                </td> -->
                <td>{{data.clientName}}</td>
                <td>
                    <!-- <span *ngIf="data.issue" style="color: red;" nz-icon nzType="warning" nzTheme="outline" nz-popover
                        nzPopoverTitle="Validation Errror" [nzPopoverContent]="data.issue">
                    </span> -->
                    <span *ngIf="data.issue">{{data.issue}}</span>
                    <span *ngIf="data.onHold" class="on-hold">HOLD</span>
                </td>
                <td>
                    <span *ngIf="data.readiness?.message">{{data.readiness?.message}}</span>
                    <!-- <span *ngIf="data.readiness" [style.color]="readinessColors[data.readiness.level]" nz-icon
                        nzType="like" nzTheme="outline" nz-tooltip [nzTooltipTitle]="data.readiness?.message"></span>
                    <span *ngIf="data.readiness && data.readiness.level === 0" style="color: red;" nz-icon
                        nzType="dislike" nzTheme="outline" nz-tooltip [nzTooltipTitle]="data.readiness?.message"></span> -->
                </td>
                <td>
                    <!-- <span *ngIf="data.pickup.market">[{{data.pickup.market}}]</span> -->
                    <div *ngIf="data.pickup?.locationName">{{ data.pickup?.locationName }}</div>
                    <div class="address">
                        <img src="/assets/img/location-icon.svg" alt="" class="icon">
                        <div>{{data.pickup.addr.street}}, {{data.pickup.addr.city}}, {{data.pickup.addr.state}} {{data.pickup.addr.zipcode}}</div>
                    </div>
                    <div>
                        <span class="clickable edit-button" (click)="onBtnEditWindowsTime(data, data.pickup)">
                            <span nz-icon nzType="edit" nzTheme="outline"></span>
                        </span>
                        {{data.pickupWindow}}
                    </div>
                </td>
                
                <!-- <td>
                    <span class="clickable edit-button" (click)="onBtnEditWindowsTime(data, data.pickup)">
                        <span nz-icon nzType="edit" nzTheme="outline"></span>
                    </span>
                    {{data.pickupWindow}}
                </td> -->
                <td>
                    <!-- <span *ngIf="data.dropoff.market">[{{data.dropoff.market}}]</span> -->
                    <div *ngIf="data.dropoff?.locationName">{{ data.dropoff?.locationName }}</div>
                    <div class="address">
                        <img src="/assets/img/location-icon.svg" alt="" class="icon">
                        <div>{{data.dropoff.addr.street}}, {{data.dropoff.addr.city}}, {{data.dropoff.addr.state}} {{data.dropoff.addr.zipcode}}</div>
                    </div>
                    <div>
                        <span class="clickable edit-button" (click)="onBtnEditWindowsTime(data, data.dropoff)">
                            <span nz-icon nzType="edit" nzTheme="outline"></span>
                        </span>
                        {{data.dropoffWindow}}
                    </div>
                </td>
                <td>
                    <!-- <span class="clickable edit-button" (click)="onBtnEditWindowsTime(data, data.dropoff)">
                        <span nz-icon nzType="edit" nzTheme="outline"></span>
                    </span>
                    {{data.dropoffWindow}} -->
                </td>
                <td>
                    <ng-container *ngIf="data.session">
                        <a target="_blank" [routerLink]="[routePlanningSessions, data.session.id]">
                            <span nz-icon nzType="thunderbolt" nzTheme="outline"></span>
                        </a>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </nz-table>
</ng-container>
<ng-container *ngIf="tab == 'pending'">
    <nz-table #basicTable2 [nzData]="list" nzFrontPagination="true" nzPaginationPosition = "top" nzSize="small">
        <thead>
            <tr>
                <th></th>
                <th nzWidth="150px">WARP ID</th>
                <th nzWidth="90px">Order</th>
                <th>Customer</th>
                <!-- <th>Validation</th> -->
                <th>Status</th>
                <th>Pickup</th>
                <th>Delivery</th>
                <th></th>
                <!-- <th></th>
                <th></th>
                <th></th>
                <th>Pickup</th>
                <th></th>
                <th>Pickup Window</th>
                <th>Delivery</th>
                <th>Delivery Window</th>
                <th></th> -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of basicTable2.data" [class]="{'INVALID': item.issue, 'EXCLUDED': item.excluded, 'PLANNED': item.session}" (mouseenter)="hoverShipment.emit(item.id)" (mouseleave)="hoverShipment.emit(null)" (click)="clickShipment.emit(item.id)">
                <td>
                    <span nz-dropdown [nzDropdownMenu]="menu">
                        <span nz-icon nzType="ellipsis" nzTheme="outline"></span>
                    </span>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu nzSelectable>
                            <li nz-menu-item (click)="removeRoutingRequirement($event, item)">
                                <span nz-icon nzType="file-done" nzTheme="outline" class="right5"></span> Routing no longer needed
                            </li>
                        </ul>
                    </nz-dropdown-menu>                        
                </td>
                <td>
                    <i nz-icon nzType="loading" nzTheme="outline" *ngIf="item.addingStatus=='ADDING'"></i>
                    <i nz-icon nzType="check" style="color: green;" nzTheme="outline" *ngIf="item.addingStatus=='ADDED'"></i>
                    <i nz-icon nzType="warning" style="color: red;" nzTheme="outline" *ngIf="item.addingStatus=='ERROR'"></i>
                    <span class="clickable right5" (click)="onCopyId($event, item.code)">{{ item.code }}</span>
                    <a target="_blank" *ngIf="!item.orderId" [routerLink]="[routeAdminOrderList]" [queryParams]="{search: item.code}">
                        <span nz-icon nzType="link" nzTheme="outline"></span>
                    </a>
                    <a target="_blank" *ngIf="item.orderId" [routerLink]="[routeAdminOrderList, item.orderId]">
                        <span nz-icon nzType="link" nzTheme="outline"></span>
                    </a>
                </td>
                <td>
                    <span class="clickable right5" (click)="onCopyId($event, item.orderWarpId)">{{ item.orderWarpId || '' }}</span>
                    <ng-container *ngIf="item.parentId">
                        <span nz-popover nzPopoverTitle="Transit Network" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate" nzPopoverPlacement="bottomLeft">
                            <span style="color: #888;" nz-icon nzType="partition" nzTheme="outline"></span>
                        </span>
                        <ng-template #contentTemplate>
                            <div transit-network [id]="item.parentId" [viewOnly]="true"></div>
                        </ng-template>
                    </ng-container>
                </td>
                <td>{{item.clientName}}</td>
                <!-- <td>
                    <span *ngIf="item.issue">{{item.issue}}</span>
                </td> -->
                <td>
                    <!-- <span *ngIf="item.readiness" [style.color]="readinessColors[item.readiness.level]" nz-icon nzType="like" nzTheme="outline" nz-tooltip [nzTooltipTitle]="item.readiness?.message"></span>
                    <span *ngIf="item.readiness && item.readiness.level === 0" style="color: red;" nz-icon nzType="dislike" nzTheme="outline" nz-tooltip [nzTooltipTitle]="item.readiness?.message"></span> -->
                    <span *ngIf="item.readiness?.message">{{item.readiness?.message}}</span>
                </td>
                <!-- <td>
                    {{ item.transitType }}
                </td> -->
                <td>
                    <div *ngIf="item.pickup?.locationName">{{ item.pickup?.locationName }}</div>
                    <div class="address">
                        <img src="/assets/img/location-icon.svg" alt="" class="icon">
                        <div>{{item.pickup.addr.street}}, {{item.pickup.addr.city}}, {{item.pickup.addr.state}} {{item.pickup.addr.zipcode}}</div>
                    </div>
                    <div>
                        <span class="clickable edit-button" (click)="onBtnEditWindowsTime(item, item.pickup)">
                            <span nz-icon nzType="edit" nzTheme="outline"></span>
                        </span>
                        {{item.pickupWindow}}
                        <span *ngIf="item.pickup.requiresAppointment && !item.pickup.appointmentInfo?.from" style="color: red;">Appt required</span>
                    </div>
                </td>
                <!-- <td>
                    <span class="clickable edit-button" (click)="onBtnEditWindowsTime(item, item.pickup)">
                        <span nz-icon nzType="edit" nzTheme="outline"></span>
                    </span>
                    {{item.pickupWindow}}
                    <span *ngIf="item.pickup.requiresAppointment && !item.pickup.appointmentInfo?.from" style="color: red;">Appt required</span>
                </td> -->
                <td>
                    <div *ngIf="item.dropoff?.locationName">{{ item.dropoff?.locationName }}</div>
                    <div class="address">
                        <img src="/assets/img/location-icon.svg" alt="" class="icon">
                        <div>{{item.dropoff.addr.street}}, {{item.dropoff.addr.city}}, {{item.dropoff.addr.state}} {{item.dropoff.addr.zipcode}}</div>
                    </div>
                    <div>
                        <span class="clickable edit-button" (click)="onBtnEditWindowsTime(item, item.dropoff)">
                            <span nz-icon nzType="edit" nzTheme="outline"></span>
                        </span>
                        {{item.dropoffWindow}}
                        <span *ngIf="item.dropoff.requiresAppointment && !item.dropoff.appointmentInfo?.from" style="color: red;">Appt required</span>
                    </div>
                </td>
                <!-- <td>
                    <span class="clickable edit-button" (click)="onBtnEditWindowsTime(item, item.dropoff)">
                        <span nz-icon nzType="edit" nzTheme="outline"></span>
                    </span>
                    {{item.dropoffWindow}}
                    <span *ngIf="item.dropoff.requiresAppointment && !item.dropoff.appointmentInfo?.from" style="color: red;">Appt required</span>
                </td> -->
                <td>
                    <ng-container *ngIf="item.session">
                        <a target="_blank" [routerLink]="[routePlanningSessions, item.session.id]">
                            <span nz-icon nzType="thunderbolt" nzTheme="outline"></span>
                        </a>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </nz-table>
</ng-container>
<ng-container *ngIf="tab == 'planned'">
    <nz-table #basicTable3 [nzData]="list" nzFrontPagination="true" nzPaginationPosition = "top" nzSize="small">
        <thead>
            <tr>
                <th nzWidth="150px">WARP ID</th>
                <th nzWidth="90px">Order</th>
                <th>Customer</th>
                <th>Status</th>

                <!-- <th></th>
                <th></th>
                <th></th> -->
                <th>Pickup</th>
                <!-- <th></th> -->
                <!-- <th>Pickup Window</th> -->
                <th>Delivery</th>
                <!-- <th>Delivery Window</th> -->
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of basicTable3.data" [class]="{'INVALID': data.issue, 'EXCLUDED': data.excluded, 'PLANNED': data.session}" (mouseenter)="hoverShipment.emit(data.id)" (mouseleave)="hoverShipment.emit(null)" (click)="clickShipment.emit(data.id)">
                <td>
                    <i nz-icon nzType="loading" nzTheme="outline" *ngIf="data.addingStatus=='ADDING'"></i>
                    <i nz-icon nzType="check" style="color: green;" nzTheme="outline" *ngIf="data.addingStatus=='ADDED'"></i>
                    <i nz-icon nzType="warning" style="color: red;" nzTheme="outline" *ngIf="data.addingStatus=='ERROR'"></i>
                    <span class="clickable right5" (click)="onCopyId($event, data.code)">{{ data.code }}</span>
                    <a target="_blank" *ngIf="!data.orderId" [routerLink]="[routeAdminOrderList]" [queryParams]="{search: data.code}">
                        <span nz-icon nzType="link" nzTheme="outline"></span>
                    </a>
                    <a target="_blank" *ngIf="data.orderId" [routerLink]="[routeAdminOrderList, data.orderId]">
                        <span nz-icon nzType="link" nzTheme="outline"></span>
                    </a>
                </td>
                <td>
                    <span class="clickable right5" (click)="onCopyId($event, data.orderWarpId)">{{ data.orderWarpId || '' }}</span>
                    <ng-container *ngIf="data.parentId">
                        <span nz-popover nzPopoverTitle="Transit Network" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate" nzPopoverPlacement="bottomLeft">
                            <span style="color: #888;" nz-icon nzType="partition" nzTheme="outline"></span>
                        </span>
                        <ng-template #contentTemplate>
                            <div transit-network [id]="data.parentId" [viewOnly]="true"></div>
                        </ng-template>
                    </ng-container>
                </td>
                <!-- <td>
                    <span *ngIf="data.issue" style="color: red;" nz-icon nzType="warning" nzTheme="outline"
                        nz-popover nzPopoverTitle="Validation Errror" [nzPopoverContent]="data.issue"
                    >
                    </span>
                </td> -->
                <!-- <td>
                    {{ data.transitType }}
                </td> -->
                <td>{{data.clientName}}</td>
                <td>
                    <!-- <span *ngIf="data.readiness" [style.color]="readinessColors[data.readiness.level]" nz-icon nzType="like" nzTheme="outline" nz-tooltip [nzTooltipTitle]="data.readiness?.message"></span>
                    <span *ngIf="data.readiness && data.readiness.level === 0" style="color: red;" nz-icon nzType="dislike" nzTheme="outline" nz-tooltip [nzTooltipTitle]="data.readiness?.message"></span> -->
                    <span *ngIf="data.readiness?.message">{{data.readiness?.message}}</span>
                </td>
                <td>
                    <!-- <span *ngIf="data.pickup.market">[{{data.pickup.market}}]</span> -->
                    <div *ngIf="data.pickup?.locationName">{{ data.pickup?.locationName }}</div>
                    <div class="address">
                        <img src="/assets/img/location-icon.svg" alt="" class="icon">
                        <div>{{data.pickup.addr.street}}, {{data.pickup.addr.city}}, {{data.pickup.addr.state}} {{data.pickup.addr.zipcode}}</div>
                    </div>
                    <div>
                        <span class="clickable edit-button" (click)="onBtnEditWindowsTime(data, data.pickup)">
                            <span nz-icon nzType="edit" nzTheme="outline"></span>
                        </span>
                        {{data.pickupWindow}}
                    </div>
                </td>
                
                <!-- <td>
                    <span class="clickable edit-button" (click)="onBtnEditWindowsTime(data, data.pickup)">
                        <span nz-icon nzType="edit" nzTheme="outline"></span>
                    </span>
                    {{data.pickupWindow}}
                </td> -->
                <td>
                    <!-- <span *ngIf="data.dropoff.market">[{{data.dropoff.market}}]</span> -->
                    <div *ngIf="data.dropoff?.locationName">{{ data.dropoff?.locationName }}</div>
                    <div class="address">
                        <img src="/assets/img/location-icon.svg" alt="" class="icon">
                        <div>{{data.dropoff.addr.street}}, {{data.dropoff.addr.city}}, {{data.dropoff.addr.state}} {{data.dropoff.addr.zipcode}}</div>
                    </div>
                    <div>
                        <span class="clickable edit-button" (click)="onBtnEditWindowsTime(data, data.dropoff)">
                            <span nz-icon nzType="edit" nzTheme="outline"></span>
                        </span>
                        {{data.dropoffWindow}}
                        <span *ngIf="data.dropoff.requiresAppointment && !data.dropoff.appointmentInfo?.from" style="color: red;">Appt required</span>
                    </div>
                </td>
                <!-- <td>
                    <span class="clickable edit-button" (click)="onBtnEditWindowsTime(data, data.dropoff)">
                        <span nz-icon nzType="edit" nzTheme="outline"></span>
                    </span>
                    {{data.dropoffWindow}}
                    <span *ngIf="data.dropoff.requiresAppointment && !data.dropoff.appointmentInfo?.from" style="color: red;">Appt required</span>
                </td> -->
                <td>
                    <ng-container *ngIf="data.session">
                        <a target="_blank" [routerLink]="[routePlanningSessions, data.session.id]">
                            <span nz-icon nzType="thunderbolt" nzTheme="outline"></span>
                        </a>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </nz-table>
</ng-container>