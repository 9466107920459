import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { DialogsModule } from '@dialogs/dialogs.module';
import { DetailModule } from '../base/detail.module';
import { FormAddressUSModule } from '../base/form-address-us/module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { SharedModule } from "../shared/shared.module";
import { UiCommonModule } from '../components/common/module';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { QuickUploadPod } from './quick-upload-pod';
import { FinanceRoutingModule } from './finance.route';
import { QuickUploadPodService } from './quickUploadPod.service';
import { PodImagePreview } from './components/pod-image-preview';
import { PodFormInfo } from './components/pod-form-info';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { PodSearchForm } from './components/search-form';
import { PodSearchAIForm } from './components/search-ai-form';
import { PodOrderDetailForm } from './components/order-detail-form';
import { TextDetectionModule } from '../components/text-detection/module';
import { PodShipmentDetailForm } from './components/shipment-detail-form';
import { ShipmentStructureModule } from '../shipments/shipment-structure/module';
import { SafeUrlPipe } from './pipes/safeUrl.pipe';
import { Reports } from './reports';
import { IcDriverPayment } from './components/reports/ic-driver-payment';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { AssignCarriersProvidePod } from './carriers-provide-pod';
import { CheckListEmailDialog } from './carriers-provide-pod/check-list-email-dialog';
import { IcLinehaulPayment } from './components/reports/ic-linehaul-payment';
import { IcCustomerPayment } from './components/reports/ic-customer-payment';
import { ExportCustomerPaymentConfirm } from './components/reports/ic-customer-payment/confirm-dlg';
import { ExportLinehaulPaymentValidate } from './components/reports/ic-linehaul-payment/validate-dlg';
import { HistoryRequestPod } from './carriers-provide-pod/history-request-pod';
import { AritiziaBillingStatementComponent } from './components/reports/aritzia-billing-statement';
import { WalmartInvoiceStatement } from './components/reports/walmart-invoice';
import { XdockShipmentReportComponent } from './components/reports/xdock-shipment';
import { CustomerBillingStatement } from './components/reports/customer-billing-statement';
import { CustomerBillingStatementConfirm } from './components/reports/customer-billing-statement/confirm-dlg';
import { DownloadDailyRouteComponent } from './components/reports/download-daily-route';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ScrollingModule,
    FinanceRoutingModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzTagModule,
    NzSwitchModule,
    NzDropDownModule,
    NzTableModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzInputModule,
    NzFormModule,
    NzPaginationModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    NzToolTipModule,
    NzTypographyModule,
    DialogsModule,
    DetailModule,
    FormAddressUSModule,
    SharedModule,
    UiCommonModule,
    NzSpinModule,
    NgxPanZoomModule,
    TextDetectionModule,
    ShipmentStructureModule,
    SelectBySearchingModule,
  ],
  declarations: [
    QuickUploadPod,
    PodImagePreview,
    PodFormInfo,
    PodSearchForm,
    PodSearchAIForm,
    PodOrderDetailForm,
    PodShipmentDetailForm,
    SafeUrlPipe,
    AssignCarriersProvidePod,
    CheckListEmailDialog,
    Reports,
    IcDriverPayment,
    IcLinehaulPayment,
    IcCustomerPayment,
    AritiziaBillingStatementComponent,
    DownloadDailyRouteComponent,
    XdockShipmentReportComponent,
    ExportCustomerPaymentConfirm,
    ExportLinehaulPaymentValidate,
    WalmartInvoiceStatement,
    HistoryRequestPod,
    CustomerBillingStatement,
    CustomerBillingStatementConfirm,
  ],
  exports: [
    PodShipmentDetailForm
  ],
  providers: [
    QuickUploadPodService
  ]
})
export class FinanceModule {}
