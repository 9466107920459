<div>
  <div class="group-item">
    <div class="fw-500">Send to</div>
    <nz-input-group [nzAddOnAfter]="tplEditSenderEmail">
      <input nz-input class="input-box" [(ngModel)]="customSenderEmail" [disabled]="isDisabledSenderEmail"/>
      <ng-template #tplEditSenderEmail>
        <a (click)="onEditSenderEmail()">Edit</a>
      </ng-template>
    </nz-input-group>
  </div>
  <div class="group-item">
    <div class="fw-500">Subject</div>
    <input nz-input class="input-box" [(ngModel)]="emailSubject" [disabled]="true"/>
  </div>
  <div class="group-item">
    <div class="fw-500">Content</div>
    <div class="email-content" [innerHTML]="emailContent"></div>
  </div>
</div>
<div class="footer">
  <button nz-button nzType="primary" [nzLoading]="isSendMailLoading" [disabled]="isSendMailLoading" (click)="onBtnSendEmail()">Send</button>
</div>