import { Component, HostListener, Input } from "@angular/core";
import { NavigationEndData } from "@app/interfaces";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { User } from "@wearewarp/types/data-model";
import { WarpId } from "@wearewarp/universal-libs";
import { InputHelper } from "@services/input-helper";
import { ModalHelper } from "@wearewarp/ng-antd";
import moment from "moment";
import { startOfDay, endOfDay } from 'date-fns'
import { BaseList } from "@app/admin/base/list";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";

@Component({
  selector: 'denim-list',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class NonFactored extends BaseList {
  @Input() isVisible: boolean = true;
  public presentSearch = undefined;
  public presentClientIds;
  public exceptClientIds; 
  private defaultExceptClient = [
    '01H22NK3MBXBDCW6AZDWFZ09V0', //walmart
    '01H22NK3KJN4XFD46A9AAYM1Q1', //Davinci
    '01H22NK3M9GX448T6XRYP54DRA', //Livetrends
    '01HD7EFGW7MJEVPEZ0P5FES91K', //WarpZeroBill
    '01H22NK3KK67DGSNNPVM03NFTH', //Jennifer Fisher
    '01HV1M82QGPJHZ8FJS78XQ4F33', //ERIK EUGENIO MARTINEZ PARACHINI
    '01H6W80AV7C4XE0YQQZS0VQBCZ', //Brand Hardware
    '01HQ3PSWE711P7285T0XE7ZCKF', //Simply EV
    '01H22NK3MAKRBVKRPQDDK3XF01', //Warp Cross Dock - Vernon, CA
    '01H22NK3M66EEW0924TD9KZG5Y', //GoBolt
    '01H22NK3M19YVTGZZPJ6QK7K8J', //Ollie Pets
    '01H22NK3KT971T9K4R8B5XSJ6M', //The FruitGuys
    '01H22NK3M6DSGV6VM1YGCM5BF9', //sharing excess
    '01HAYMB6J10J7A8D2AMB23K511', //Hello Fresh
    '01H22NK3KK222CH8CKCDTTJ82B', //Shef
    '01HGDK0FSGG0F08NEFBSNX19A6', //Ghost Town Oats
    '01H22NK3M37C6CTNT968BCGPFQ', //Leisure Project
    '01H22NK3KK5KJHR8BX7AB9E4W1', //Sak's Off 5th
    '01H22NK3M3HQHFATG3AW36J61H', //Thistle
    '01H22NK3KWGFJBJGCSAK16YK3Y', //Plainville
    '01H22NK3M2H7XA97V5H0YRGDE8', //Strand Books
    '01H22NK3MEXXE935KB9PPMTAGG', //Hoplark
    '01H57Z4FA5DV78GZYBNCY8HB18', //Hailify
    '01HHBDKXWQ15K51T4PSXJKECCC', //Doordash
    '01J8T818YZJ06DX5F5ZTZ3HNSY', //Dropoff, Inc
    '01HRZEJV34B82ZVT2B1TE06VWX', //gopuff
    '01J2CW1K337KRCZCV8NPS19VSC', //gopuff bevmo
    '01JARNHT4T8R1QPZ1DEV0WBBYH', //Bevmo (Store Delivery)
    '01H22NK3KGFJDNRAM52HCVZ0SW', //Yun Express
    '01J5ECNEZ7R8F3JK37X0DYR0T3', //Back to the Roots, Inc
    '01J1QM9Z7T4Q5JS4V5KXP67DKH', //Aritzia (United States of Aritzia)
  ]; 
  public filterFromDate;
  public filterToDate;
  public filterCustomerRate;
  public filterInvoiceStatus;
  public filterPodStatus;
  public tableHeight: number = 400;
  public denimStatusArray = Object.keys(Const.DenimTrackingStatuses).map(key => {
    return {
      value: key,
      label: Const.getDenimTrackingStatusText(key)
    }
  });


  constructor(public api: ApiService, private modalHelper: ModalHelper) {
    super();
    this.subscribeNavigationEnd();
  }

  // Gọi trong hàm constructor
  protected subscribeNavigationEnd() {
    this.subscription.add(this.appComponent.subscribeNavigationEnd((e: NavigationEndData) => {
      if (this.isVisible) {
        this.onNavigationEnd(e.current.url);
      }
    }));
    if (this.appComponent.getCurrentNavigationUrlData()) {
      // Đợi kết thúc hàm constructor rồi mới thực hiện
      setTimeout(() => this.onNavigationEnd(this.appComponent.createNavigationEndData().current.url), 0);
    }
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    let params = Utils.parseQueryStringFromUrl(url);
    if (!params?.filter) {
      let jsonStr = JSON.stringify({
        toDate: DateUtil.get30DaysAgo(),
        exceptClientIds: this.defaultExceptClient
      });
      return this.routeWithQueryUrl({filter: jsonStr});
    }
    super.handleNavigationEnd(url, prevQueryParam);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.autoShrinkSideBar();
    this.toggleDetailContainerHeight();
    this.filterToDate = DateUtil.get30DaysAgo();
    this.exceptClientIds = Utils.cloneObject(this.defaultExceptClient);
    let params = Utils.parseQueryStringFromUrl(this.router.url);
    if (params.filter) {
      let filter = JSON.parse(params.filter);
      if (Utils.isArrayNotEmpty(filter?.clientIds)) {
        this.presentClientIds = filter.clientIds
      } else this.presentClientIds = [];
      if (Utils.isArrayNotEmpty(filter?.exceptClientIds)) {
        this.exceptClientIds = filter.exceptClientIds
      } else this.exceptClientIds = [];
      if (filter?.fromDate) this.filterFromDate = filter.fromDate;
      if (filter?.toDate) this.filterToDate = filter.toDate;
      if (filter?.customerRate) this.filterCustomerRate = filter.customerRate;
      if (filter?.invoiceStatus) this.filterInvoiceStatus = filter.invoiceStatus;
      if (filter?.podStatus) this.filterPodStatus = filter.podStatus;
    }
    if (params.search) this.presentSearch = params.search;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.toggleDetailContainerHeight();
  }

  toggleDetailContainerHeight() {
    this.tableHeight = window.innerHeight - 330;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected getApiUrl(): string {
    return `${Const.APIV2(Const.APIURI_FINANCES)}/denim/non-factored`;
  }

  protected getDataDone(resp: any): void {
    this.processListData(resp);
    super.getDataDone(resp);
  }

  public get currentPageSize(){
    return 20
  }

  private processListData(resp) {
    if (!resp.data.list_data) {
      return;
    }
    let users = resp.data?.users || [];
    let orderInfos = resp.data?.orderInfos || [];
    //convert users to map
    const userMap = {};
    users.forEach((user: User) => {
      userMap[user.id] = user;
    });
    //convert orderInfos to map
    const orderInfoMap = {};
    orderInfos.forEach((orderInfo) => {
      orderInfoMap[orderInfo.orderId] = orderInfo;
    });

    let listData = [];
    resp.data.list_data.forEach((item, index) => {
      const denimInfo = orderInfoMap[item.id];
      let deliveryInfos = (item.shipments || []).map(sh => sh.deliveryInfos || []).flat();
      let refNums = deliveryInfos.map(info => info.refNums || []).flat();
      refNums = Utils.uniqElementsArray(refNums.filter(x=>x));
      listData.push({
        id: item.id,
        warpId: WarpId.showOrder(item.warpId),
        code: item.code || 'N/A',
        refNums,
        lastJobId: (item.shipments || [])?.[0]?.lastJobId,
        customerName: (item.metadata.shipments as any)?.[0]?.client.name,
        amount: InputHelper.formatMoney2(item.amount?.toString()),
        status: item.status,
        denimStatus: denimInfo?.status || Const.DenimTrackingStatuses.pending,
        isLoadingValidation: false,
        validation: "",
        denimNote: denimInfo?.note,
        changeLogs: this.buildChangeLogs(denimInfo?.changeLogs || {}, userMap),
      });
    });
    let orderIds = resp.data.list_data.map(item => item.id);
    this.validateGenerateInvoice(orderIds, listData);
    resp.data.list_data = listData;
  }

  public onBtnReset() {
    let q = { page: 1, loaded: Date.now() }
    if (this.presentSearch) this.presentSearch = '';
    if (this.presentClientIds) this.presentClientIds = null;
    if (this.filterFromDate) this.filterFromDate = null;
    if (this.filterCustomerRate) this.filterCustomerRate = null;
    if (this.filterInvoiceStatus) this.filterInvoiceStatus = null;
    if (this.filterPodStatus) this.filterPodStatus = null;
    this.filterToDate = DateUtil.get30DaysAgo(); 
    this.exceptClientIds = Utils.cloneObject(this.defaultExceptClient);
    let condition: any = {
      toDate: this.startOfDay(this.filterToDate).toISOString(),
      exceptClientIds: this.defaultExceptClient
    }
    const f = JSON.stringify(condition)
    if (f.length > 2) {
      q['filter'] = f
    }
    this.routeWithQueryUrl(q, true);
  }

  private buildQuery(): any {
    let q = { page: 1, loaded: Date.now() }
    if (this.presentSearch) {
      q['search'] = this.presentSearch
    }
    let condition: any = {}
    if (Utils.isArrayNotEmpty(this.presentClientIds)) {
      condition.clientIds = this.presentClientIds
    }
    if (Utils.isArrayNotEmpty(this.exceptClientIds)) {
      condition.exceptClientIds = this.exceptClientIds
    }
    if (this.filterFromDate) {
      condition.fromDate = this.startOfDay(new Date(this.filterFromDate)).toISOString()
    }
    if (this.filterToDate) condition.toDate = this.endOfDay(new Date(this.filterToDate)).toISOString();
    if (this.filterCustomerRate) condition.customerRate = this.filterCustomerRate;
    if (this.filterInvoiceStatus) condition.invoiceStatus = this.filterInvoiceStatus;
    if (this.filterPodStatus) condition.podStatus = this.filterPodStatus;
    const f = JSON.stringify(condition)
    if (f.length > 2) {
      q['filter'] = f
    }
    return q;
  }

  public onSearchChange() {
    this.routeWithQueryUrl(this.buildQuery(), true);
  }

  private validateGenerateInvoice(orderIds, listData) {
    if (!orderIds?.length) return;
    listData.map(item => item.isLoadingValidation = true);
    const params = { orderIds };
    this.api.POST(Const.APIV2(`${Const.APIURI_FINANCES}/denim/validate-generate-invoice`), params).subscribe(
      resp => {
        let orders = resp.data?.list_data || [];
        if (!orders.length) return;
        const orderDic: any = {};
        for (let item of orders) {
          if (!item.orderId) continue;
          orderDic[item.orderId] = item;
        }
        for (let item of listData) {
          if (!orderDic[item.id]) continue;
          let obj: any = {
            isValid: orderDic[item.id].isValid,
            hasInvoice: !!orderDic[item.id].hasInvoice,
            invoice: orderDic[item.id].hasInvoice ? { _id: orderDic[item.id].invoiceFileId, type: "application/pdf" } : null,
            msg: orderDic[item.id].msg,
          }
          if (orderDic[item.id].invoice?._id) {
            obj.invoice = orderDic[item.id].invoice;
          } else if (orderDic[item.id].invoiceFileId) {
            obj.invoice = { _id: orderDic[item.id].invoiceFileId, type: "application/pdf" }
          }
          item.validation = obj;
        }
        listData.map(item => item.isLoadingValidation = false);
      }, err => {
        this.showErr(err);
        listData.map(item => item.isLoadingValidation = false);
      }
    );
  }

  private buildChangeLogs(changeLogs, userMap) {
    const newChangeLogs = {};
    Object.keys(changeLogs).forEach((key) => {
      const user = userMap[changeLogs[key].byId];
      newChangeLogs[key] = {
        ...changeLogs[key],
        when: moment(changeLogs[key].when).format('YYYY-MM-DD HH:mm:ss'),
        user: user
      }
    });
    return newChangeLogs;
  }

  public onNoteChanged(orderId, note) {
    console.log(orderId, note);
    this.updateInfo(orderId, { note })
    //call API here
  }

  public onStatusChanged(orderId, status) {
    console.log(orderId, status);
    this.updateInfo(orderId, { status })
    //call API here
  }
  private async updateInfo(orderId, data) {
    const res = await this.api.POST(`${Const.APIV2(Const.APIURI_FINANCES)}/denim/${orderId}/update-info`, data).toPromise();
    const resData = res.data || {};

    //xử lý sau khi update
    const info = this.listData.find(item => item.id === orderId);
    if (info) {
      info.denimStatus = resData.status;
      info.denimNote = resData.note;
      info.changeLogs = this.buildChangeLogs(resData.changeLogs || {}, {});
    }
  }

  isExporting = false;
  onBtnExport() {
    this.isExporting = true;
    let query = this.prepareParamGetList();
    let qs = new URLSearchParams(JSON.parse(JSON.stringify(query))).toString();
    this.api.postExport(`${Const.APIV2(Const.APIURI_FINANCES)}/denim/non-factored-export/?${qs}`,{}).subscribe(
      resp => {
        ApiService.handleDownloadResponse(resp);
        this.isExporting = false;
      }, err => {
        this.showErr(err);
        this.isExporting = false;
      }
    );
  }

  downloadInvoice({invoice}) {
    if (invoice?.isDownloading) return;
    return this.downloadAttachedFile(invoice);
  }

  onBtnGenerateInvoice(item) {
    this.modalHelper.confirmYesNo(
      `Do you really want to generate invoice for order ${WarpId.showOrder(item.warpId)}?`, 
      () => this.onDoGenerateInvoice(item)
    );
  }

  onDoGenerateInvoice(item) {
    if (item.isGeneratingInvoice) return;
    item.isGeneratingInvoice = true;
    const url = `v2/${Const.APIURI_ORDERS}/invoice/${item.id}`;
    this.api.POST(url, {
      forceReGenerate: true
    }).subscribe(
      resp => {
        this.showSuccess(`File ${resp.data?.name} has been created!`)
        item.isGeneratingInvoice = false;
        if (resp?.data?._id) {
          item.validation = {
            isValid: true,
            hasInvoice: true,
            invoice: resp.data,
          }
          this.downloadInvoice({invoice: resp.data});
        }
      }, err => {
        item.isGeneratingInvoice = false;
        this.showErr(err);
      }
    );
  }

  getTimezoneShort() {
    const localTimeZone = DateUtil.getLocalTimeZone();
    const localTimeZoneShort = DateUtil.timezoneStandardToUsShort(localTimeZone);
    return localTimeZoneShort || '';
  }

  public startOfDay(result: Date): Date {
    return result ? startOfDay(result) : null;
  }
  public endOfDay(result: Date): Date {
    return result ? endOfDay(result) : null;
  }

  getOrderLink(order) {
    return `${Const.routeAdminOrderList}?page=1&search=${order.warpId}`
  }

  onBtnCopyNewWarpId(item) {
    let text = item.code;
    Utils.copyTextToClipboard(text, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('Copy successful.');
      }
    });
  }

  onBtnCopyRef(refNums) {
    const text = (refNums || []).join(', ');
    Utils.copyTextToClipboard(text, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('Copy successful.');
      }
    });
  }

  canGoToConfirmPOD(item) {
    return item?.validation?.msg?.includes('POD not confirmed') && item?.lastJobId;
  }

  goToConfirmPOD(item) {
    return `${Const.routeAdminPODNeedConfirm}/${item?.lastJobId}`;
  }

}
