import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseDetail } from '@app/admin/base/detail';
import { Const } from '@const/Const';
import {FormControl, Validators} from '@angular/forms';
import { InputHelper } from '@services/input-helper';
import { Utils } from '@services/utils';
import { ZipcodeSelectorValue } from '@app/admin/components/common/zipcode-selector';
import { RequestCreateCustomerLane } from '@wearewarp/types-server-admin/client/client-lane';
import { VehicleType } from '@wearewarp/types/data-model';
import { ResponseCustomerLaneDetailUI } from './interface';

@Component({
  selector: 'customer-lane-detail',
  templateUrl: './view.html',
  styleUrls: ['../../../detail.scss', './style.scss']
})
export class CustomerLaneDetail extends BaseDetail<ResponseCustomerLaneDetailUI> {
  protected formGroupDeclaration: FormGroupDeclaration = {
    clientId: {label: 'Customer', required: true},
    shipmentType: {label: 'Shipment Type', required: true},
    vehicleType: {label: 'Equipment', required: false},
    fromZipcode: {label: 'From zip code', required: true},
    toZipcode: {label: 'To zip code', required: true},
    rate: {label: 'Rate', type: 'number', required: true, placeHolder: `$0.00`, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value)},
    clientLaneCode: {label: 'Dedicated Lane ID', required: true, readOnly: true, submitReadOnly: true}, 
  }

  listClients = [];
  public listShipmentTypes = Const.ShipmentTypesArray;
  public vehicleType: VehicleType;

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  protected getApiUrl(): string {
    return Const.APIV2('customer-lanes');
  }

  getApiListDataForFilter(key: string) {
    switch (key) {
      case 'clientId': return Const.APIURI_CLIENTS_FOR_FILTER;
    }
  }

  private genCode() {
    const originZipcode = this.zipcodeSelectorOrigin?.zipcode;
    const destZipcode = this.zipcodeSelectorDestination?.zipcode;
    if (!originZipcode || !destZipcode) {
      return;
    }
    let code = `${originZipcode}_${destZipcode}`;
    const shipmentType = this.getItemValue('shipmentType');
    if (shipmentType == Const.ShipmentTypes.fullTruckLoad) {
      const vehicleType: VehicleType = this.getItemValue('vehicleType');
      if (vehicleType) {
        code += `_${Utils.snakeToCamel(vehicleType.name.replace(/\s+/g, '_'))}`;
        if (Utils.isArrayNotEmpty(vehicleType?.options)) {
          code += '_' + vehicleType.options.map(it => Utils.snakeToCamel(it.toLowerCase())).join('_');
        }
      }
    }
    this.setItemValue('clientLaneCode', code);
  }

  protected getFormData_JSON(isCreateNew: boolean) {
    const json = super.getFormData_JSON(isCreateNew);
    const vehicleType: VehicleType | undefined = json.shipmentType === Const.ShipmentTypes.fullTruckLoad && json.vehicleType ? json.vehicleType : undefined;
    const data: RequestCreateCustomerLane = {
      clientId: json.clientId,
      shipmentType: json.shipmentType,
      origin: this.zipcodeSelectorOrigin,
      destination: this.zipcodeSelectorDestination,
      rate: json.rate,
      clientLaneCode: json.clientLaneCode,
    }
    if (vehicleType) {
      data.vehicleType = vehicleType;
    }
    return data
  }

  get shouldDisableVehicleType() {
    if (!this.isCreateNew) return true;
    return this.getItemValue('shipmentType') !== Const.ShipmentTypes.fullTruckLoad;
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'rate':
        return InputHelper.handleInputKeyPressMoney(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  private zipcodeSelectorOrigin: ZipcodeSelectorValue;
  private zipcodeSelectorDestination: ZipcodeSelectorValue;

  onInputChanged(event, key) {
    switch (key) {
      case 'shipmentType':
        this.onChangeShipmentType(event)
        break;
      case 'rate':
        InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true);
        break;
      case 'vehicleType':
        this.setItemValue('vehicleType', event);
        break;
      case 'fromZipcode':
        this.zipcodeSelectorOrigin = event;
        this.setItemValue('fromZipcode', event);
        break;
      case 'toZipcode':
        this.zipcodeSelectorDestination = event;
        this.setItemValue('toZipcode', event);
        break;
      default:
        super.onInputChanged(event, key);
        break;
    }
    switch (key) {
      case 'fromZipcode':
      case 'toZipcode':
      case 'shipmentType':
      case 'vehicleType':
        this.genCode();
        break;
    }
  }

  onChangeShipmentType(value) {
    if (value === Const.ShipmentTypes.fullTruckLoad) {
      this.formGroupDeclaration.vehicleType.required = true;
      this.updateRequireField("vehicleType", true);
    } else {
      this.formGroupDeclaration.vehicleType.required = false;
      this.updateRequireField("vehicleType", false);
    }
    this.vehicleType = null;
    this.setItemValue("vehicleType", null);
  }

  public updateRequireField(path, value){
    let fc = <FormControl>this.formInput.get(path);
    if (fc) {
      if (!value) {
        fc.removeValidators(Validators.required);
      } else {
        fc.addValidators(Validators.required);
      }
      fc.updateValueAndValidity();
    }
  }

  getValueInitZipcode(key) {
    const value = this.getItemValue(key);
    if (Utils.isObjectNotEmpty(value) && value.zipcode) {
      return value.zipcode;
    }
    return value
  }

  protected onGetDetailSuccess(data: ResponseCustomerLaneDetailUI) {
    data.fromZipcode = data.origin?.zipcode;
    data.toZipcode = data.destination?.zipcode;
    this.zipcodeSelectorOrigin = data.origin;
    this.zipcodeSelectorDestination = data.destination;
    this.vehicleType = data.vehicleType;
    return data;
  }

}