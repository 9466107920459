<ng-container *ngIf="!displayInfo.isGhostLoad">
  <div class="text-header">WARP Offer</div>
  <div nz-row class="line-item">
    <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">Base price</div>
    <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">
      <ng-container *ngIf="!bid?.isShowBasePrice && !basePrice">-</ng-container>
      <ng-container *ngIf="!bid?.isShowBasePrice && basePrice">
        {{basePrice}} (Hidden for carriers)
      </ng-container>
      <ng-container *ngIf="bid?.isShowBasePrice">
        {{basePrice ?? 0}} (Show for carriers)
      </ng-container>
      <span style="color: #1802d0; cursor: pointer; margin-left: 7px;" (click)="onBtnEditSettings()" nz-icon nzTheme="outline" nzType="edit" class="reload" ></span>
    </div>
  </div>
 
</ng-container>