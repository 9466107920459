import { Component, Input } from "@angular/core";
import { ExtendValidators } from "@app/admin/base/validator";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { TaskType } from "@wearewarp/types";
import { FormDataEditLocationRefnums } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { Observable } from "rxjs";

const refNumsKey = 'refNums';

@Component({
  selector: 'edit-shipment-entry-reference-number',
  templateUrl: './view.html',
  styleUrls: ['./index.scss']
})
export class EditShipmentEntryReferenceNumber extends BaseFormDialog1<FormDataEditLocationRefnums> {

  @Input() type: TaskType;
  @Input() onSave: (data: FormDataEditLocationRefnums) => Observable<any>;
  @Input() onRefreshDetailOrder: () => void;
  @Input() headerText = 'Edit Reference Number';

  protected formGroupDeclaration: FormGroupDeclaration = {
    refNums: {label: 'Reference number (can be multiple)', type: 'formArray', childItem: {
      label: 'Ref', notAcceptEmpty: true, validators: ExtendValidators.validateTextNotEmpty
    }, initialValue: []},
  }

  ngOnInit(): void {
    if (!this.type) {
      throw Error('type is required');
    }
    super.ngOnInit();
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  get countRefNums() {
    return this.getFormArrayLength(refNumsKey);
  }

  onBtnAddFormArray() {
    this.addItemToFormArray(refNumsKey);
  }

  onBtnRemoveFormArray(index: number) {
    let itemValue = this.getItemValue(`${refNumsKey}[${index}]`);
    let message = itemValue ? `Remove reference number <b>${itemValue}</b>?` : `Remove reference number at position <b>${index + 1}</b>?`;
    this.confirmDeletion({
      message: message,
      txtBtnOk: 'Remove',
      fnOk: () => this.removeItemInFormArray(refNumsKey, index)
    });
  }

  onBtnSave() {
    if (!this.needUpdate) {
      return;
    }
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onRefreshDetailOrder();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

}
