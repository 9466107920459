import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ResponseBidCounterHistoryItem } from "@wearewarp/types-server-admin/carrier-bid";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";
import { Const } from "@const/Const";

@Component({
  selector: '[bid-counter-activity]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class BidCounterActivity implements OnInit, OnDestroy {
  @Input() data: ResponseBidCounterHistoryItem;
  @Input() isNew: boolean = false;

  get entity() { return this.data.entity }
  private get historyAction(): string {
    switch (this.data.action) {
      case 'accept': return 'accepted';
      case 'decline': return 'declined';
      default: return 'placed';
    }
  }
  private get prefix() {
    switch (this.data.entity) {
      case WarpConst.BidCounterOfferEntities.admin: return 'Warp offered';
      case WarpConst.BidCounterOfferEntities.carrier: return `Carrier ${this.historyAction}`;
      default: return '';
    }
  }
  get text() { return this.prefix }
  get price() { return InputHelper.formatMoney1(this.data.price) }
  get userName() { return this.data?.create?.by?.name ?? '' }
  get isUserDeleted() { return this.data?.create?.by?.isDeleted }
  get time() { return DateUtil.format(this.data.create.when, 'MMM D, YYYY, h:mm:ss a') };
  get timeAgo() {
    if (!this.data.create.when) return '';
    return DateUtil.timeAgo(this.data.create.when)
  };
  get userLink() {
    if (this.data.create.by.entity == 'users') return [Const.routeAdminUserList, this.data.create.by.id];
    return undefined;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}