import { FormControl } from "@angular/forms";
import { routingNumber, accountNumber } from 'us-bank-account-validator';

export class InputHelper {
  public static isNumber(event: KeyboardEvent): boolean {
    switch (event.key) {
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        return true;
      default:
        return false;
    }
  }

  public static isAlphaNumeric(event: KeyboardEvent): boolean {
    return /[a-zA-Z0-9]/.test(event.key);
  }

  public static isDot(event: KeyboardEvent): boolean {
    return event.key == '.';
  }

  public static isEnter(event: KeyboardEvent): boolean {
    return event.key == 'Enter';
  }

  public static isEsc(event: KeyboardEvent): boolean {
    return event.key == 'Escape';
  }

  // tổ hợp phím ctrl và 1 phím kí tự
  public static isCtrl(character: string, event: KeyboardEvent): boolean {
    return event.ctrlKey && event.key.toLowerCase() == character.toLowerCase();
  }

  public static setCursorPosition(elem, position) {
    if (!elem) {
      return;
    }
    if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.move('character', position);
      range.select();
    } else {
      if (elem.selectionStart) {
        elem.focus();
        elem.setSelectionRange(position, position);
      } else {
        elem.focus();
      }
    }
  }

  // VD lấy 2 chữ số thập phân
  // phân tách nghìn bằng dấu .
  // phân tách phần nguyên, thập phân bằng dấu ,
  // formatMoney(123456789.12345, 2, ',', '.');
  public static _formatMoney(amt, c = 0, d = undefined, t = undefined): string {
    c = isNaN(c = Math.abs(c)) ? 2 : c;
    d = d == undefined ? "." : d;
    t = t == undefined ? "," : t;
    let s = amt < 0 ? "-" : "";
    let i = String(parseInt(amt = Math.abs(Number(amt) || 0).toFixed(c)));
    var j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substring(0, j) + t : "") + i.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(amt - Number(i)).toFixed(c).slice(2) : "");
  }

  // Thêm tiền tố và luôn để 2 chữ số sau dấu phẩy. VD $1,000.00
  // Lưu ý: chỉ áp dụng cho các trường tính toán, ko phải user nhập input (ở màn shipment)
  public static formatMoney2(str: string|number, symbol: string = '$') {
    return symbol + InputHelper._formatMoney(str, 2);
  }

  // Thêm tiền tố. VD $1,000
  public static formatMoney1(str: string|number, symbol: string = '$') {
    return InputHelper.formatMoney(str, { prefixDollar: true, symbol })
  }

  public static formatMoney(str: string|number, options: { prefixDollar?: boolean, numAfterPoint?: number, symbol?: string } = { symbol: '$' }): string {
    if (!str) return '';
    let defaultOptions = { prefixDollar: false, numAfterPoint: 0 };
    let ops = Object.assign(defaultOptions, options || {});
    let _str: string = typeof str == 'string' ? str : `${str}`;
    let arr = _str.split('.');
    let text = InputHelper._formatMoney(arr[0], ops.numAfterPoint);
    if (arr.length > 1) {
      text += '.' + arr[1].substring(0, 2);
    }
    if (ops.prefixDollar) {
      text = ops.symbol + text;
    }
    return text;
  }

  public static formatPercentage(str: string) {
    if (!str) return str;
    let text = str.replace(/[^0-9.]/g, '');
    if (text && text.length > 0) {
      text += ' %';
    }
    return text;
  }

  // for U.S phone number format: +1 (323) 974-5003
  public static formatPhone(phoneStr: string): string {
    if (!phoneStr) return "";
    let text = phoneStr.replace(/[^0-9]/g, '');
    if (text.length < 10) return text;

    if (text.length > 10) {
      let part1 = text.substring(0, text.length - 10);
      let part2 = InputHelper.formatPhoneUS(text.substr(-10));
      return `+${part1} ${part2}`
    }
    return InputHelper.formatPhoneUS(text)
  }

  private static formatPhoneUS(phoneStr: string): string {
    let part1 = phoneStr.substring(0, 3);
    let part2 = phoneStr.substring(3, 6);
    let part3 = phoneStr.substring(6, 10);
    let text = '(' + part1;
    if (part1.length == 3 && part2.length > 0) {
      text += ')';
    }
    if (part2.length > 0) {
      text += ' ' + part2;
      if (part3.length > 0) {
        text += '-' + part3;
      }
    }
    return text;
  }

  public static isValidPhone(phoneStr: string) {
    var regex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return regex.test(phoneStr);
  }

  /**
     * US : +1
     * Vietnam: +84
     * Bolivia: +591
     * Jamaica : +1876
  */
  public static getValuePhone(str: string): string {
    if (str === null || str === undefined) return str;
    return str.replace(/[^0-9]/g, '').substring(0, 14); // mã quốc gia 1-4 kí tự số
  }

  public static handleInputChangePhone(event, formcontrol: FormControl) {
    let text = InputHelper.formatPhone(event.target.value);
    if (text != event.target.value) {
      event.target.value = text;
      if (formcontrol) {
        formcontrol.setValue(text, { emitEvent: false });
      }
    }
  }

  // isInteger = true: dữ liệu input là kiểu số nguyên (phải cắt hết 0 ở đầu)
  // mặc định: dữ liệu input là kiểu string (bao nhiêu 0 ở đầu cũng được)
  public static handleInputChangeNumberOnly(event, formcontrol: FormControl, options: { isInteger?: boolean } = {}) {
    let defaultOptions = { isInteger: false }
    let ops = Object.assign(defaultOptions, options || {});
    let text = event.target.value;
    text = text.replace(/[^0-9]/g, '');
    if (text.length == 0) {
      text = null;
    }
    if (ops.isInteger && text && text.length > 1) {
      text = text.replace(/^0+/, '');
      if (text.length == 0) {
        text = '0';
      }
    }
    if (text != event.target.value) {
      event.target.value = text;
      if (formcontrol instanceof FormControl) {
        formcontrol.setValue(text, { emitEvent: false });
      }
    }
  }

  public static handleInputKeyPressNumberOnly(event: KeyboardEvent): boolean {
    return InputHelper.isNumber(event);
  }

  public static handleInputKeyPressDecimalNumber(event): boolean {
    let text = event.target.value;
    if (text.includes('.')) {
      return InputHelper.isNumber(event);
    } else {
      return InputHelper.isNumber(event) || InputHelper.isDot(event);
    }
  }

  public static handleInputChangDecimalNumber(event: KeyboardEvent, formcontrol: FormControl) {
    let inputText = (<HTMLInputElement>event.target).value;
    let text = inputText.replace(/[^0-9.]/g, '');
    if (text == '.') {
      text = '0.';
    }
    if (text.charAt(0) == '.') {
      text = text.substring(1);
    }
    if (typeof event === 'string') {
      return text;
    }
    if (text != (<HTMLInputElement>event.target).value) {
      (<HTMLInputElement>event.target).value = text;
      if (formcontrol) {
        formcontrol.setValue(text, { emitEvent: false });
      }
    }
  }

  public static handleInputChangeTemperature(event, formcontrol: FormControl, options: { isInteger?: boolean } = {}) {
    let defaultOptions = { isInteger: false }
    let ops = Object.assign(defaultOptions, options || {});
    let text = event.target.value;
    text = text.replace(/[^0-9-]/g, '');
    if (text.length == 0) {
      text = null;
    }
    if (text && text.length > 1) {
      while (text.lastIndexOf('-') != -1 && text.lastIndexOf('-') != 0) text = text.slice(0, text.lastIndexOf('-')) + text.slice(text.lastIndexOf('-') + 1, text.length);
    }
    if (ops.isInteger && text && text.length > 1) {
      if (text.lastIndexOf('-') != -1) {
        text = text.replace(/^0+/, '');
      } else {
        text = text.replace(/^-0+/, '-');
      }
      if (text.length == 0) {
        text = '0';
      }
    }
    if (text != event.target.value) {
      event.target.value = text;
      if (formcontrol) {
        formcontrol.setValue(text, { emitEvent: false });
      }
    }
  }

  public static isTemperatureNumber(event: KeyboardEvent): boolean {
    return /[0-9-]/.test(event.key);
  }

  public static handleInputKeyPressTemperature(event: KeyboardEvent): boolean {
    return InputHelper.isTemperatureNumber(event);
  }

  public static handleInputKeyPressAlphaNumericOnly(event): boolean {
    return InputHelper.isAlphaNumeric(event);
  }

  public static handleInputChangeAlphaNumericOnly(event, formcontrol: FormControl) {
    let text = event.target.value;
    text = text.replace(/[^a-zA-Z0-9]/g, '');
    if (text.length == 0) {
      text = null;
    }
    if (text != event.target.value) {
      event.target.value = text;
      if (formcontrol) {
        formcontrol.setValue(text, { emitEvent: false });
      }
    }
  }

  public static handleInputKeyPressMoney(event): boolean {
    let text = event.target.value;
    if (text.includes('.')) {
      if (text.split('.')[1].length > 2) {
        return false;
      }
      return InputHelper.isNumber(event);
    } else {
      return InputHelper.isNumber(event) || InputHelper.isDot(event);
    }
  }

  public static getValueMoney(str: string): number {
    if (str == null || str == '') return null;
    if (typeof str === 'string') {
      return Number(str.replace(/[^0-9.]/g, ''));
    } else if (typeof str === 'number') {
      return str;
    } else {
      return null;
    }
  }

  public static handleInputChangeMoney(event: KeyboardEvent, formcontrol: FormControl, prefixDollar = false, symbol: string = '$') {
    let inputText = (<HTMLInputElement>event.target).value;
    let text = inputText.replace(/[^0-9.]/g, '');
    if (text == '.') {
      text = '0.';
    }
    if (text.charAt(0) == '.') {
      text = text.substring(1);
    }
    if (text.length > 0) {
      text = InputHelper.formatMoney(text, { prefixDollar: prefixDollar, symbol });
    }
    if (typeof event === 'string') {
      return text;
    }
    if (text != (<HTMLInputElement>event.target).value) {
      (<HTMLInputElement>event.target).value = text;
      if (formcontrol) {
        formcontrol.setValue(text, { emitEvent: false });
      }
    }
  }

  public static handleInputKeyPressNegativeDecimalNumber(event): boolean {
    let text = event.target.value;
    if (text.includes('.')) {
      return InputHelper.isNumber(event) || event.key == '-';
    } else {
      return InputHelper.isNumber(event) || InputHelper.isDot(event) || event.key == '-';
    }
  }

  public static handleInputChangNegativeDecimalNumber(event: KeyboardEvent, formcontrol: FormControl) {
    let inputText = (<HTMLInputElement>event.target).value;
    let text = inputText.replace(/[^0-9.-]/g, '');
    if (text == '.') {
      text = '0.';
    }
    if (text.charAt(0) == '.') {
      text = text.substring(1);
    }
    if (text && text.length > 1) {
      while (text.lastIndexOf('-') != -1 && text.lastIndexOf('-') != 0) {
        text = text.slice(0, text.lastIndexOf('-')) + text.slice(text.lastIndexOf('-') + 1, text.length);
      }
    }
    if (typeof event === 'string') {
      return text;
    }
    if (text != (<HTMLInputElement>event.target).value) {
      (<HTMLInputElement>event.target).value = text;
      if (formcontrol) {
        formcontrol.setValue(text, { emitEvent: false });
      }
    }
  }

  public static getValueDecimalNumber(str: string): number {
    if (str == null || str == '') return null;
    if (typeof str === 'string') {
      return Number(str.replace(/[^0-9.-]/g, ''));
    } else if (typeof str === 'number') {
      return str;
    } else {
      return null;
    }
  }

  public static handleInputKeyPressPercentage(event): boolean {
    let text = event.target.value.replace(/[^0-9.]/g, '');  // current value before key press
    if (event.key == '0' && text.length > 0 && Number(text) === 0) {
      return false;
    }
    let valid = false;
    if (text.includes('.')) {
      if (text.split('.')[1].length >= 2) {
        return false;
      }
      valid = InputHelper.isNumber(event);
    } else {
      valid = InputHelper.isNumber(event) || InputHelper.isDot(event);
    }
    if (valid) {
      let n = this.getValuePercentage(text + event.key);
      if (n > 100) {
        valid = false;
      }
    }
    return valid;
  }

  public static getValuePercentage(str: string): number {
    if (str === null || str === undefined) return null;
    if (typeof str === 'string') {
      return Number(str.replace(/[^0-9.]/g, ''));
    } else if (typeof str === 'number') {
      return str;
    } else {
      return null;
    }
  }

  public static handleInputChangePercentage(event, formcontrol: FormControl) {
    let text = event.target.value.replace(/[%\s]/g, '').replace(/^0+(?!$)/, '');
    if (text.startsWith('.')) {
      text = '0' + text;
    }
    let n = Number(text);
    if (isNaN(n) || n > 100) {
      text = '';
    } else {
      text = InputHelper.formatPercentage(text);
    }
    if (text != event.target.value) {
      event.target.value = text;
      if (formcontrol) {
        formcontrol.setValue(text, { emitEvent: false });
      }
    }
    let position = text.length - 2;
    if (position < 0) {
      position = 0;
    }
    this.setCursorPosition(event.target, position);
  }

  public static isBankRoutingNumberValid(value) {
    const check = routingNumber(value);
    return check.isValid
  }

  public static isBankAccountNumberValid(value) {
    const check = accountNumber(value);
    return check.isValid
  }

  public static handleInputKeyZipcode(event, formcontrol?: FormControl): boolean {
    let text = event.target.value;
    text = text.trim()

    if (text != event.target.value) {
      event.target.value = text;
      if (formcontrol) {
        formcontrol.setValue(text, { emitEvent: false });
      }
    }

    return InputHelper.validateZipcode(text);
  }

  public static validateZipcode(z) {
    return this.validateUsZipCode(z) || this.validateCaZipCode(z)
  }

  public static validateUsZipCode(z) {
    const pattern = /^\d{5}$/
    return pattern.test(z)
  }

  public static validateCaZipCode(z) {
    const pattern = /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY] ?\d[ABCEGHJKLMNPRSTVXY]\d$/
    return pattern.test(z)
  }

  public static handleInputChangeEINNumber(event, formcontrol: FormControl) {
    let text = InputHelper.formatEIN(event.target.value);
    if (text != event.target.value) {
      event.target.value = text;
      if (formcontrol) {
        formcontrol.setValue(text, { emitEvent: false });
      }
    }
  }
  // XX-XXXXXXX
  public static formatEIN(str: string): string {
    if (!str) return str;
    let text = str.replace(/[^0-9]/g, '');
    if (text.length > 9) {
      text = text.substring(0, 9);
    }
    if (text.length > 2) {
      text = text.substring(0, 2) + '-' + text.substring(2, 9);
    }
    
    return text;
  }

  public static handleInputChangeSSNNumber(event, formcontrol: FormControl) {
    let text = InputHelper.formatSSNOrITIN(event.target.value);
    if (text != event.target.value) {
      event.target.value = text;
      if (formcontrol) {
        formcontrol.setValue(text, { emitEvent: false });
      }
    }
  }

  // XXX-XX-XXXX
  public static formatSSNOrITIN(str: string): string {
    if (!str) return str;
    let text = str.replace(/[^0-9]/g, '');
    
    if (text.length > 9) {
      text = text.substring(0, 9);
    }
    if (text.length > 3) {
      text = text.substring(0, 3) + '-' + text.substring(3, 5) + (text.length > 5 ? '-' + text.substring(5, 9) : '');
    }
    
    return text;
  } 

}
