import { Component, ElementRef, ViewChild } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { ActivatedRoute } from "@angular/router";
import { Utils } from "@services/utils";
import { startOfMonth } from 'date-fns'

@Component({
  selector: '[report-pod-header-filter]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ReportPODHeaderFilter extends BaseFormItem {

  protected formGroupDeclaration: FormGroupDeclaration = {
    accountingId: {label: 'Accounting User', required: true, placeHolder: 'Select', notAcceptEmpty: true},
    type: {label: 'Type', required: true, placeHolder: 'Select', notAcceptEmpty: true},
    fromDate: {label: 'From date', type: 'date', required: true },
    toDate: {label: 'To date', type: 'date', required: true },
    year: {label: 'Year', type: 'date', required: true }
  };

  public filterTypeOptions = [
    {name: 'Day', value: 'day'},
    {name: 'Month', value: 'month'},
    {name: 'Quarter', value: 'quarter'}
  ];

  public loaded: number = 0

  @ViewChild('form') form: ElementRef<HTMLFormElement>;
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      this.loaded = p.loaded || 0
    })
  }

  ngOnInit(): void {
    let params = Utils.parseQueryStringFromUrl(this.router.url);
    if (params.filter) {
      this.model = JSON.parse(params.filter);
    } else {
      this.model = this.buildDefaultValue();
    }
    super.ngOnInit();
    this.loadData();
    this.fetchListAccountingUsers();
  }

  private buildDefaultValue() {
    return {
      type: 'day',
      fromDate: startOfMonth(new Date()),
      toDate: new Date(),
      year:  new Date(),
    };
  }

  private buildQuery(): any {
    let q = {}
    let condition: any = this.getFormData_JSON(true);
    const f = JSON.stringify(condition)
    if (f.length > 2) {
      q['filter'] = f
    }
    return q;
  }

  loadData() {
    this.routeWithQueryUrl(this.buildQuery());
  }

  onChange(value, key) {
    this.model[key] = value
    setTimeout(() => {
      this.loadData();
    }, 1); // phải kết thúc hàm onChange thì trong form mới có dữ liệu
  }

  public listAccountingUsers = [];
  public isFetchingAccounting = false;
  private fetchListAccountingUsers() {
    this.isFetchingAccounting = true;
    this.api.getListAdminUsers().subscribe(
      resp => {
        this.listAccountingUsers = resp?.data?.list_data ?? [];
        this.isFetchingAccounting = false;
      }, err => {
        this.showErr(err);
        this.isFetchingAccounting = false;
      }
    );
  }

}
