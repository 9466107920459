import { Component, ElementRef, ViewChild } from '@angular/core';
import { BaseDetail } from '../../base/detail';
import { Const } from '@const/Const';
import { Const as WarpConst } from '@wearewarp/universal-libs';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { Utils } from '@services/utils';
import { ActivatedRoute } from '@angular/router';
import { InputHelper } from '@services/input-helper';
import { DialogService } from '@dialogs/dialog.service';
import { WarehouseForm } from '../../warehouses/warehouse-form';
import { environment } from '@env/environment';
import { ModalHelper } from '@wearewarp/ng-antd';
import { AddQuickOrderSetting, FormDataAddQuickOrderSetting } from '.././quick-order-settings';
import { Observable } from 'rxjs';
import { UIHelper } from '@services/UIHelper';
import { LocationSetting } from '../location-setting';
import { Log } from '@services/log';
import { MasterData } from '@services/master.data';
import { AutoReportType, UsTimezone } from '@wearewarp/types';
import { ZipcodeService } from '@services/zipcode.service';
import { DateUtil } from '@services/date-utils';
import moment from 'moment';

@Component({
  selector: '[client-add]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../../detail.scss', '../../../../styles/row-col.scss', '../../../../styles/form-v2.scss']
})
export class ClientAdd extends BaseDetail {
  protected formGroupDeclaration: FormGroupDeclaration = {
    name: {label: 'Company name', required: true},
    parentClientId: {label: '',placeHolder: 'Select parent company', required: false},
    website: {label: 'Company website', inputType: 'url', },
    address: {label: 'Address', required: true},
    primaryContact: {label: '', type: 'formGroup', childItem: {
      firstName: {label: 'First Name', required: true},
      lastName: {label: 'Last Name', required: true},
      phone: {label: 'Phone', required: true, inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
      phoneExtension: {label: '', placeHolder: 'Ext'},
      email: {label: 'Email', required: true, validators: Validators.email},
      slackId: {label: 'Slack Channel ID'},
      isPayableContact: {label: 'Send invoice to above contact.', type: 'boolean', initialValue: true}
    }},
    payableContact: {label: '', type: 'formGroup', hidden: true, childItem: {
      firstName: {label: 'First Name', required: true},
      lastName: {label: 'Last Name', required: true},
      phone: {label: 'Phone', required: true, inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
      phoneExtension: {label: '', placeHolder: 'Ext'},
      email: {label: 'Email', required: true, validators: Validators.email},
      slackId: {label: 'Slack Channel ID'},
    }},
    saleUserId: {label: 'Assigned Seller'}, // label cũ Assigned Sales Rep => Assigned National Account Director
    customerServiceUserId: {label: 'Account Owner'}, // label cũ Assigned Client Success Rep => Assigned Client Success Manager
    customerServiceRepUserId: {label: 'Assigned Customer Service Rep'},
    creditLimit: {label: 'Credit Limit', getValue: InputHelper.getValueMoney, formatValue: InputHelper.formatMoney},
    creditRemaining: {label: 'Credit Remaining', readOnly: true, formatValue: InputHelper.formatMoney},
    payableAvgDays: {label: 'Payables AVG Days Outstanding', readOnly: true},
    isShowLoadFunctions: {label: 'Load Functionality', type: 'boolean', initialValue: false},
    freightQuoteEnabled: {label: 'Freight Quote Functionality', type: 'boolean', initialValue: false},
    hideWarpBrandInfo: {label: 'Hide WARP brand info', type: 'boolean', initialValue: false},
    isShowCarrierNameInQuote: {label: 'Display Carrier Name in Freight Quote', type: 'boolean', initialValue: false},
    isShowQuoteSourceName:  { label: "Display Source Name in Freight Quote", type: "boolean", initialValue: false },
    isUseCrossDock: {label: 'Use Crossdock for all LTL shipments', type: 'boolean', initialValue: false},
    isInvoiceWithoutPOD: {label: 'Invoice Without POD', type: 'boolean', initialValue: false},
    isShowCarrierNameInMarketPlaceQuote: { label: 'Display Carrier Name in Marketplace Quote', type: 'boolean', initialValue: false },
    isOnlyShowWARPLTLQuoteInFreightQuote: { label: "Only show WARP's quote in LTL Freight Quote", type: 'boolean', initialValue: false },
    isShowCustomerNameInAppointment: { label: "Show customer name at appointment page/email", type: "boolean", initialValue: false },
    marketplaceLTLEnabled: { label: "Marketplace LTL Functionality", type: "boolean", initialValue: false },
    isCreditLimitExceeded: {label: 'Credit Exceeded', type: 'boolean', initialValue: false},
    isShowBusinessNumber: {label: 'Business Number (EDI)', type: 'boolean', initialValue: false},
    enableCarrierInReport: {label: 'Enable Carrier Column in Report', type: 'boolean', initialValue: false},
    isNotRequireLT: {label: 'Do not require Load Tender Acceptance', type: 'boolean', initialValue: false},
    needBuildTaskLayover: {label: 'Need to build task for Layover', type: 'boolean', initialValue: false},
    canUseFtlBooking: { label: 'FTL Freight Quote', type: 'boolean', initialValue: false },
    canUploadLogo: { label: "Upload Custom Logo", type: "boolean", initialValue: false },
    classificationSettings: {label: '', type: 'formGroup', notAcceptEmpty: true, childItem: {
      productType: {label: 'Product Type', notAcceptEmpty: true },
      classificationShipment: {label: 'Shipment Classification', notAcceptEmpty: true },
    }},
    contacts: {label: '', type: 'formArray', initialValue: [{}], childItem: {
      firstName: {label: 'First Name'},
      lastName: {label: 'Last Name'},
      phone: {label: 'Phone', inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
      email: {label: 'Email', validators: Validators.email},
    }},
    settings: {label: '', type: 'formArray', initialValue: [{}], childItem: {
      warehouseId: { label: 'Warehouse' },
      locationType: { label: 'Location Type', initialValue: 'ALL' },
      scanBarcodeRequired: { label: 'Scan Barcode', initialValue: false },
      photoRequired: { label: 'POD', initialValue: false },
      podAtLocationLevel: { label: 'POD', initialValue: true },
      bolRequired: { label: 'BOL', initialValue: false },
      signatureRequired: { label: 'Signature.', initialValue: false },
      metadata: { label: 'Metadata' }
    }},
    paymentTerm: {label: 'Select Payment Terms'},
    payments: {label: '', type: 'formArray', initialValue: [], childItem: {
      id: { label: ''},
      bankName: { label: 'Bank Name', placeHolder: 'Enter Bank Name', required: true },
      bankRoutingNumber: { label: 'Routing Number', placeHolder: 'Enter Routing Number', required: true, },
      bankAccountNumber: { label: 'Bank Account Number', placeHolder: 'Enter Bank Account Number', required: true, validators: [this.validateAccountNumber]},
      ein: { label: 'EIN Number', placeHolder: 'Enter EIN Number', required: true },
      ssn: { label: 'SSN Number', placeHolder: 'Enter SSN Number', notAcceptEmpty: false }
    }},
    reportType: { label: '', initialValue: WarpConst.AutoReportType.all_lane },
    autoMessages: {label: '', type: "formArray", initialValue: [{}], childItem: {
      laneType: { label: 'Report Type', required: true, initialValue: 'zipcode' },
      lanes: { label: '', type: 'formArray', initialValue: [{}], childItem: {
        from: { label: "From City or State", required: false },
        to: { label: "To City or State", required: false },
      }},
      slackChannelIds: { label: 'Slack', type: 'formArray', childItem: {
        channelId: { label: 'Channel ID', required: true, placeHolder: 'Channel ID' }
      }},
      recipientTos: { label: 'Email (To)', type: 'formArray', childItem: {
        firstName: {label: 'First Name', required: true},
        lastName: {label: 'Last Name', required: true},
        email: {label: 'Email', required: true, validators: Validators.email},
      }},
      recipientCcs: { label: 'Email (CC)', type: 'formArray', childItem: {
        firstName: {label: 'First Name', required: true},
        lastName: {label: 'Last Name', required: true},
        email: {label: 'Email', required: true, validators: Validators.email},
      }},
      notificationTypes: { label: 'Select Notification Type', type: 'array', initialValue: [] },
      timeZone: { label: 'Timezone', required: true, type: 'string', initialValue: "America/New_York" },
      sentAt: { label: 'Sent at', required: true, type: 'time', initialValue: new Date().setHours(9,0,0,0) },
      sentEvery: { label: 'Sent every', required: true, type: 'number', initialValue: 30 },
      sentBeforePickup: { label: 'Send before pickup', required: true, type: 'number', initialValue: 2 },
    }},
    palletTemplateId: { label: 'Pallet Label'},
    sortingTemplateId: { label: 'Sorting Label'},
    inbound: {label: 'Inbound', type: 'formArray', initialValue: [{}], childItem: {
      taskType: {label: 'Task Type', inputType: 'hidden'},
      levels: {label: 'Level', type: 'formGroup', childItem: {
        shipment: { label: 'Shipment', type: 'boolean', required: true, initialValue: false, readOnly: true, submitReadOnly: true },
        item: { label: 'Item', type: 'boolean', initialValue: false },
      }},
    }},
    // sorting: {label: 'Sorting', type: 'formArray', initialValue: [{}], childItem: {
    //   taskType: {label: 'Task Type', inputType: 'hidden'},
    //   levels: {label: 'Level', type: 'formGroup', childItem: {
    //     shipment: { label: 'Shipment', type: 'boolean', required: true, initialValue: false, readOnly: true, submitReadOnly: true },
    //     item: { label: 'Item', type: 'boolean', initialValue: false },
    //   }},
    // }},
    outbound: {label: 'Outbound', type: 'formArray', initialValue: [{}], childItem: {
      taskType: {label: 'Task Type', inputType: 'hidden'},
      levels: {label: 'Level', type: 'formGroup', childItem: {
        shipment: { label: 'Shipment', type: 'boolean', required: true, initialValue: false },
        item: { label: 'Item', type: 'boolean', initialValue: false },
      }},
    }},
    logos: {label: '', type: 'formGroup', childItem: {
      logoLightMode: {label: 'Logo Light Mode', required: false},
      logoDarkMode: {label: 'Logo Dark Mode', required: false}
    }}
  };

  get formInputKeys_basic() {
    return this.formInputKeys.filter(it => it != 'pickupAddresses');
  }

  public allPaymentTerms = ['prepay'];
  public listAddressAutoComplete: any = {};
  public allShortTimezones = DateUtil.listUsTimezones;
  
  @ViewChild('laneInputFrom', { static: false }) laneInputFrom: ElementRef<HTMLInputElement>;
  @ViewChild('laneInputTo', { static: false }) laneInputTo: ElementRef<HTMLInputElement>;

  public reportTypes = [
    { value: WarpConst.AutoReportType.all_lane, label: 'All Lanes' },
    { value: WarpConst.AutoReportType.specific_lane, label: 'Specific Lanes' }
  ]

  get isSpecificLaneMode(): boolean {
    return this.reportType === WarpConst.AutoReportType.specific_lane;
  }

  get isAllLaneMode(): boolean {
    return this.reportType === WarpConst.AutoReportType.all_lane;
  }

  get routerUrlBack() {return this.routeAdminClientList}
  get shouldShowDevelopmentSection(): boolean {
    return this.isClient && this.model;
  }
  public listWarehouses = [];
  public developer;
  public allSaleUsers = [];
  public taskSettings = [];
  public palletTemplates = [];
  public sortingTemplates = [];
  public indexOpenDialog;
  public allStates = [];
  public allStatesCode = [];
  public productTypeArr = Object.keys(Const.ProductTypes);
  public classificationShipmentArr = Object.keys(Const.ClassificationShipments);
  public fileLogos: { [key: string]: File } = {};
  public laneTypes = [
    { value: 'zipcode', label: 'Zipcode' },
    { value: 'location', label: 'Location' }
  ]
  zipcodeInfo = [];
  listLocations = []

  private zipcodeService: ZipcodeService;

  constructor(protected activatedRoute: ActivatedRoute, private modalHelper: ModalHelper) {
    super(activatedRoute);
    this.allStates = MasterData.getStatesUS();
    for (let item of this.allStates) {
      this.allStatesCode[item.code.toUpperCase()] = item;
    }
    this.zipcodeService = new ZipcodeService(this.api)
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getLabelTemplates();
    this.getNotificationTypes();
    this.getListLocations();
    if (this.isAdmin) {
      this.getAllSales();
    }
  }

  protected getIdForDetailComponent(route: ActivatedRoute) {
    return 'add';
  }

  public isLoadingTemplates = false;
  private getLabelTemplates() {
    this.isLoadingTemplates = true;
    this.api.GET(`${Const.APIURI_LABEL_TEMPLATES}?limit=-1`).subscribe(
      resp => {
        this.isLoadingTemplates = false;
        let data = resp.data.list_data || [];
        this.palletTemplates = data.filter(lt => lt.type === Const.LabelTemplateTypes.PALLET);
        this.sortingTemplates = data.filter(lt => lt.type === Const.LabelTemplateTypes.SORT);
      }, err => {
        this.isLoadingTemplates = false;
        this.showErr(err);
      }
    );
  }

  public isLoadingNotificationType: boolean = false;
  public notificationTypes = [];
  private getNotificationTypes() {
    this.isLoadingNotificationType = true;
    this.api.GET(Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/CLIENT_SETTINGS/notification_type_settings`)).subscribe(
      resp => {
        this.isLoadingNotificationType = false;
        const value = resp.value || '[]';
        const settings = JSON.parse(value);
        this.notificationTypes = settings;
      }, err => {
        this.isLoadingNotificationType = false;
        this.showErr(err);
      }
    );
  }

  public isLoadingSales = false;
  private getAllSales() {
    this.isLoadingSales = true;
    this.api.GET(`${Const.APIURI_USERS}?saleOnly=1`).subscribe(
      resp => {
        this.isLoadingSales = false;
        this.allSaleUsers = resp.data.list_data;
      }, err => {
        this.isLoadingSales = false;
        this.showErr(err);
      }
    );
  }

  private async getLocationSettings() {
    const url = Const.APIV2(`${Const.APIURI_LOCATION_SETTING}/ALL`);
    const response = await this.api.GET(url).toPromise();
    const data = response?.data?.list_data || [];
    let result = [];
    for(let item of data) {
      let value = JSON.parse(item.value || '{}');
      result.push({
        id: item.id,
        warehouseId: item?.warehouseId,
        locationType: item.scope,
        ...value
      })
    }
    return result;
  }

  isDisable(mode, index, key) {
    const value = this.getItemValue(`${mode.type}[${index}].${key}`);
    if(mode.type === WarpConst.WarehouseTaskStage.inbound && value === WarpConst.WarehouseTaskType.scanPallet) return true;
    return false;
  }

  private async getWarehouseTasks() {
    const url = Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/WAREHOUSE/warehouse-tasks`)
    const response = await this.api.GET(url).toPromise();
    const value = response.value || '[]';
    const settings = JSON.parse(value);
    let inboundTasks = [];
    // let sortingTasks = [];
    let outboundTasks = [];
    for(let setting of settings) {
      const levels = setting.levels;
      const levelObj = {}
      for(let level of levels) {
        let types: any[] = [WarpConst.WarehouseTaskType.uploadBOL];
        const _id = this.getIdForDetailComponent(this.activatedRoute);
        //set default task selection here
        //FIXME: không set default kiểu như này
        if(_id === "add") types = [
          ...types,
          WarpConst.WarehouseTaskType.addWeight,
          WarpConst.WarehouseTaskType.uploadProductPhoto,
          WarpConst.WarehouseTaskType.scanPallet
        ];
        if(types.includes(setting.type)) levelObj[level] = true;
        else levelObj[level] = false;
      }
      if(setting.stages.includes(WarpConst.WarehouseTaskStage.inbound)) {
        inboundTasks.push({
          taskType: setting.type,
          levels: levelObj
        })
      }
      // if(setting.stages.includes(WarpConst.WarehouseTaskStage.sorting)) {
      //   sortingTasks.push({
      //     taskType: setting.type,
      //     levels: levelObj
      //   })
      // }
      if(setting.stages.includes(WarpConst.WarehouseTaskStage.outbound)) {
        outboundTasks.push({
          taskType: setting.type,
          levels: levelObj
        })
      }
    }
    const taskSettings = [
      { type: WarpConst.WarehouseTaskStage.inbound, tasks: inboundTasks },
      // { type: WarpConst.WarehouseTaskStage.sorting, tasks: sortingTasks },
      { type: WarpConst.WarehouseTaskStage.outbound, tasks: outboundTasks }
    ];
    this.taskSettings = taskSettings;

    return taskSettings;
  }

  public getLevels(mode, index, key) {
    const value = this.getItemValue(`${mode.type}[${index}].${key}`);
    const tasks = mode.tasks;
    const task = tasks.find(it => it.taskType === value);
    const levels =  task?.levels || {}
    return Object.keys(levels);
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected canDelete(model: any): boolean {
    return this.isAdmin;
  }

  protected get crudEntity(): string {
    return 'clients';
  }

  protected async handleNavigationEnd(url: string, prevQueryParam: any) {
    const taskSettings = await this.getWarehouseTasks();

    if(taskSettings.length) {
      let inboundTasks = {};
      // let sortingTasks = {};
      let outboundTasks = {};

      for(let key of [WarpConst.WarehouseTaskStage.sorting, WarpConst.WarehouseTaskStage.inbound, WarpConst.WarehouseTaskStage.outbound]) {
        const { tasks } = this.taskSettings.find(it => it.type === key) || {};
        if(key === WarpConst.WarehouseTaskStage.inbound) {
          for(let task of tasks) {
            inboundTasks = this.getTasks(task, inboundTasks, false, true)
          }
        }
        // if(key === WarpConst.WarehouseTaskStage.sorting) {
        //   for(let task of tasks) {
        //     sortingTasks = this.getTasks(task, sortingTasks, false, true)
        //   }
        // }
        if(key === WarpConst.WarehouseTaskStage.outbound) {
          for(let task of tasks) {
            outboundTasks = this.getTasks(task, outboundTasks, false, task.taskType === WarpConst.WarehouseTaskType.scanPallet ? true : false)
          }
        }
      }
      this.formGroupDeclaration.inbound.initialValue = Object.values(inboundTasks);
      // this.formGroupDeclaration.sorting.initialValue = Object.values(sortingTasks);
      this.formGroupDeclaration.outbound.initialValue = Object.values(outboundTasks);

    }

    const settings = await this.getLocationSettings();
    if(settings.length) {
      this.formGroupDeclaration.settings.initialValue = Object.values(settings);
    }

    super.handleNavigationEnd(url, prevQueryParam);

    // Đoạn này phải đặt phía sau super.handleNavigationEnd vì lúc này mới có formInput để setItemValue
    let parentClient = this.queryParams['parentClient'];
    if(parentClient){
      this.setItemValue('parentClientId',parentClient);
      this.formInput?.get('parentClientId')?.disable();
    }
  }

  private getTasks(task, obj, value = true, isUse = false) {
    if(!obj[task.taskType]) {
      let levels = isUse ? task.levels : {}
      if(task.level) {
        levels[task.level] = value;
      }
      obj[task.taskType] = {
        taskType: task.taskType,
        levels: levels
      }
    }
    else {
      const levels = obj[task.taskType]['levels'];
      if(task.level && !levels[task.level]) {
        let levels = obj[task.taskType]['levels'];
        levels[task.level] = value;
        obj[task.taskType]['levels'] = levels;
      }
    }

    return obj;
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let data: any = super.getFormData_JSON(isCreateNew);
    const settings = {
      inbound: data.inbound ? data.inbound : this.model.inbound,
      // sorting: data.sorting ? data.sorting : this.model.sorting,
      outbound: data.outbound ? data.outbound : this.model.outbound
    }
    let warehouseTasks = [];
    for(let key of [WarpConst.WarehouseTaskStage.sorting, WarpConst.WarehouseTaskStage.inbound, WarpConst.WarehouseTaskStage.outbound]) {
      const tasks = settings[key] || [];
      if(!tasks.length) continue;
      const taskMapped = [];
      for(let task of tasks) {
        const levels = task.levels || {};
        if(Object.values(levels).length) {
          for(let k of ['shipment', 'item']) {
            if(levels[k]) {
              taskMapped.push({
                taskType: task.taskType,
                level: k,
                stage: key
              });
            }
          }
        }
      }
      warehouseTasks = [...warehouseTasks, ...taskMapped];
    }
    data.warehouseTasks = warehouseTasks;
    const autoMessages = [];
    for(let i in data.autoMessages) {
      const { lanes = [], recipientTos = [], recipientCcs = [], laneType, sentEvery = null, sentBeforePickup = null, timeZone = null, sentAt, ...otherProps } = data.autoMessages[i];
      let params: any = {}
      if(lanes.length && data.reportType === WarpConst.AutoReportType.specific_lane && laneType === 'zipcode') {
        params.lanes = lanes.map(lane => {
          console.log("lane", lane)
          const item = {
            from: {
              city: this.zipcodeInfo[i]?.from?.city,
              state: this.zipcodeInfo[i]?.from?.state,
              zipcode: this.zipcodeInfo[i]?.from?.zipcode
            },
            to: {
              city: this.zipcodeInfo[i]?.to?.city,
              state: this.zipcodeInfo[i]?.to?.state,
              zipcode: this.zipcodeInfo[i]?.to?.zipcode
            }
          }
          return item;
        });
      }
      if (lanes.length && data.reportType === WarpConst.AutoReportType.specific_lane && laneType === 'location') {
        params.lanes = lanes.map(lane => {
          const fromLocation = this.listLocations.find(it => it.id === lane?.from);
          const toLocation = this.listLocations.find(it => it.id === lane?.to);
          const item = {
            from: {
              warehouseId: fromLocation?.id,
              name: fromLocation?.name,
              city: fromLocation?.pickAddr?.city,
              state: fromLocation?.pickAddr?.state,
              zipcode: fromLocation?.pickAddr?.zipcode
            },
            to: {
              warehouseId: toLocation.id,
              name: toLocation?.name,
              city: toLocation?.pickAddr?.city,
              state: toLocation?.pickAddr?.state,
              zipcode: toLocation?.pickAddr?.zipcode
            }
          }
          return item;
        });
      }
      let recipients: any = [];
      if(recipientTos.length) {
        recipients = [...recipients, ...recipientTos.map(it => { return { ...it, type: 'to' }})];
      }
      if(recipientCcs.length) {
        recipients = [...recipients, ...recipientCcs.map(it => { return { ...it, type: 'cc' }})];
      }
      autoMessages.push({
        ...otherProps,
        laneType,
        sentAt: sentAt ? moment(sentAt).format('HH:mm') : null,
        sentEvery,
        sentBeforePickup,
        timeZone,
        recipients,
        ...params
      })
    }
    data.autoMessages = autoMessages;
    return data;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
  }

  protected async onCreateSuccess(resp) {
    await this.addLocationSetting();
    let parentClient = this.queryParams['parentClient'];
    if(parentClient){
      if (resp && resp.ui_message) {
        this.showInfo(resp.ui_message);
      } else {
        this.showInfo("New item has been created successfully.");
      }
      this.reset();
      this.formInput = null;
      this.router.navigate([this.routeAdminClientList, parentClient],{queryParams:{tab:'sub-account'}})
    }else{
      super.onCreateSuccess(resp);
    }
  }

  private addLocationSetting() {
    const settings = this.getItemValue('settings');
    if(!settings.length) return;
    const url = Const.APIV2(`${Const.APIURI_LOCATION_SETTING}/add-settings`);
    let data = {
      clientId: this.model.id,
      settings
    }

    return this.api.POST(url, data).toPromise();
  }

  getDataChildModelToArray(key) {
    return this.formInput.get(key) ? this.formInput.get(key).value : [];
  }

  onInputChanged(event, key) {
    const [_key, _index] = key.split('__');

    switch (_key) {
      case 'creditLimit': return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(_key));
      case 'phoneNumber': return InputHelper.handleInputChangePhone(event, <FormControl>this.formInput.get(_key));
      case 'from':
      case 'to':
        if (!this.zipcodeInfo[_index]) {
          this.zipcodeInfo[_index] = {};
        }
        if (!this.zipcodeInfo[_index]?.[_key]) {
          let defaultObj = {
            city: '',
            state: '',
            altCities: [],
            isLoading: false,
            error: false,
            desc: ''
          }
          this.zipcodeInfo[_index][_key] = {...defaultObj};
        }
        const validated = InputHelper.handleInputKeyZipcode(event, <FormControl>this.formInput.get(_key));
        if (!validated) {
          this.zipcodeInfo[_index][_key].city = '';
          this.zipcodeInfo[_index][_key].state = '';
          this.zipcodeInfo[_index][_key].altCities = [];
          this.zipcodeInfo[_index][_key].isLoading = false;
          this.zipcodeInfo[_index][_key].error = true;
          this.zipcodeInfo[_index][_key].desc = 'Invalid zip code'
          this.formInput?.get(_key)?.updateValueAndValidity();
        } else {
          this.loadZipcode(_index, _key, event.target.value ?? '')
        }
        break;
      default:
        return super.onInputChanged(event, _key);
    }
  }

  onInputKeyPress(event: KeyboardEvent, key): boolean {
    switch (key) {
      case 'creditLimit': return InputHelper.handleInputKeyPressMoney(event);
      case 'phoneNumber': return InputHelper.handleInputKeyPressNumberOnly(event);
      default: return super.onInputKeyPress(event, key);
    }
  }

  onCheckBoxPayableContactChange(value) {
    if (this.isEditOrCreate) {
      let changedData = this.getFormData_JSON(this.isCreateNew);
      let currentData;
      if (this.isCreateNew) {
        currentData = changedData;
      } else {
        currentData = Object.assign(Utils.cloneObject(this.model), changedData);
      }
      if (!currentData.primaryContact) {
        currentData.primaryContact = {};
      }
      this.formGroupDeclaration.payableContact.hidden = value;
      currentData.primaryContact.isPayableContact = value;
      this.createFormInput(currentData);
      this.setEnableFormGroup(true);
    }
  }

  onBtnAddLocation() {
    DialogService.openFormDialog1(WarehouseForm, {
      nzComponentParams: {
        model: location,
        client: this.model,
        closeOnSuccess: true,
      },
      nzClassName: 'modal-no-padding warehouse-form',
    });
  }

  getTaskName(type, index, key) {
    const labels = {
      uploadBOL: "Upload BOL",
      uploadProductPhoto: "Upload Product Photo",
      scanPallet: "Assign Label",
      addWeight: "Add Weight"
    }
    const value = this.getItemValue(`${type}[${index}].${key}`)
    return labels[value] || '';
  }

  getQuickOrderUrl(info) {
    if (!info?.token) return '';
    return `${environment.customerWebUrl}/public/quick-order/${info.token}`;
  }

  onBtnAddQuickOrderURL() {
    this.modalHelper.openForm(AddQuickOrderSetting, {
      nzTitle: `Add Quick Order Settings`,
      labelBtnOK: 'Generate URL',
      onSubmitError: (err) => {
        UIHelper.showErr(err);
      },
      onSubmitSucceeded: (resp) => {
        UIHelper.showSuccess(resp);
        this.onBtnRefresh();
      },
      nzComponentParams: {
        submit: (data: FormDataAddQuickOrderSetting) => this.createQuickOrderSetting(data),
      }
    });
  }

  private createQuickOrderSetting(data): Observable<any> {
    const url = `${Const.APIURI_CLIENTS}/${this.model.id}/generate-url-quick-order`;
    return this.api.POST(url, data)
  }

  isGenerating = false;
  onBtnReGenerateQuickOrderURL(quickOrderAccess) {
    let url = `${Const.APIURI_CLIENTS}/${this.model.id}/re-generate-url-quick-order`;
    let data = {
      email: quickOrderAccess.email,
      token: quickOrderAccess.token
    }
    this.isGenerating = true;
    this.api.POST(url, data).subscribe(
      resp => {
        this.isGenerating = false;
        this.onBtnRefresh();
      }, err => {
        this.showErr(err);
        this.isGenerating = false;
      }
    );
  }

  getBtnLabelSendEmailQuickOrder(quickOrderAccess) {
    return quickOrderAccess?.isTokenExpired ? 'Re-Send Email' : 'Send Email';
  }

  onBtnSendEmailQuickOrder(quickOrderAccess) {
    this.modalHelper.confirmYesNo(
      `Do you really want to send Quick Order email to ${quickOrderAccess?.email} ?`,
      () => this.sendEmailQuickOrder(quickOrderAccess)
    );
  }

  isSendingEmail = false;
  private sendEmailQuickOrder(quickOrderAccess) {
    let data = {
      email: quickOrderAccess.email,
      token: quickOrderAccess.token
    }
    this.isSendingEmail = true;
    this.api.POST(`${Const.APIURI_CLIENTS}/${this.model.id}/send_quick_order_email`, data).subscribe(
      (resp) => {
        this.isSendingEmail = false;
        this.showSuccess("Quick Order email has been sent successfully.");
        this.onBtnRefresh();
      },
      (err) => {
        this.showErr(err);
        this.isSendingEmail = false;
      }
    );
  }

  isRemoving = false;
  onBtnRemoveQuickOrderSetting(quickOrderAccess) {
    this.modalHelper.confirmYesNo(
      `Do you really want to remove settting for email ${quickOrderAccess?.email} ?`,
      () => {
        let url = `${Const.APIURI_CLIENTS}/${this.model.id}/remove-quick-order-access`;
        let data = {
          email: quickOrderAccess.email,
          token: quickOrderAccess.token
        }
        this.isRemoving = true;
        this.api.POST(url, data).subscribe(
          resp => {
            this.isRemoving = false;
            this.onBtnRefresh();
          }, err => {
            this.showErr(err);
            this.isRemoving = false;
          }
        );
      }
    );
  }

  get isExistPaymentInfo() {
    return this.getItemValue('payments')?.length ? true : false;
  }

  validateAccountNumber(input: FormControl) {
    const value = input?.value?.trim();
    if(InputHelper.isBankAccountNumberValid(value)) return null
    return {
      "invalid": {
        'en': 'Invalid account number'
      }
    };
  }

  isCreateSubAccount() {
    return this.queryParams['parentClient'];
  }

  public reportType: AutoReportType = WarpConst.AutoReportType.all_lane;

  onChangeReportType(value: AutoReportType) {
    this.reportType = value;
    const isRequired = value === WarpConst.AutoReportType.specific_lane;
    let childKeys = ['from', 'to'];
    for (let childKey of childKeys) {
      this.formGroupDeclaration.autoMessages.childItem['lanes'].childItem[childKey].required = isRequired;
    }
    let fa = this.getFormArrayAutoMessages();
    for (let i = 0; i < fa.length; i++) {
      let fg = fa.at(i);
      for (let childKey of childKeys) {
        let fc = <FormControl>fg.get(childKey);
        if (fc) {
          if (!isRequired) {
            fc.removeValidators(Validators.required);
          } else {
            fc.addValidators(Validators.required);
          }
          fc.updateValueAndValidity();
        }
      }
    }
  }

  private getFormArrayAutoMessages(): FormArray {
    return <FormArray>this.formInput.get('autoMessages');
  }

  onChangeNotification(value, index: number) {
    this.setItemValue(`autoMessages[${index}].notificationTypes`, value);
  }

  getCheckedNotification(key: string, index: number): boolean {
    const notificationTypes = this.getItemValue(`autoMessages[${index}].notificationTypes`);
    return notificationTypes.includes(key);
  }

  getValue(index: number, key: string) {
    const item = this.getItemValue(`settings[${index}]`);
    const value = this.getItemValue(`settings[${index}].${key}`);
    switch (key) {
      case 'warehouseId':
        return value ? item?.metadata?.warehouseName || 'Warehouse' : 'ALL'
      case 'locationType':
        return value;
      case 'scanBarcodeRequired':
        return value ? 'Required' : '';
      case 'podAtLocationLevel':
        if(!item.photoRequired) return '';
        return value ? 'Required (stop level)' : 'Required (shipment level)';
      case 'bolRequired':
        return value ? 'Required' : '';
      case 'signatureRequired':
        return value ? 'Required' : '';
      default:
        return '';
    }
  }

  editItemSetting(index) {
    const item = this.getItemValue(`settings[${index}]`);

    DialogService.openFormDialog1(LocationSetting, {
      nzComponentParams: {
        headerText: `Edit Settings`,
        model: {
          settings: item,
          locationType: item.locationType,
          warehouseId: item.warehouseId,
          metadata: item.metadata
        },
        onSave: data => {
          const fc = (<FormArray>this.formInput.get('settings'))?.at(index);
          if (fc) fc.setValue({
            ...item, ...data.settings,
            locationType: data.locationType,
            warehouseId: data.warehouseId,
            metadata: data.metadata
          });
        }
      },
      nzClassName: "modal",
    });
  }

  addSetting() {
    DialogService.openFormDialog1(LocationSetting, {
      nzComponentParams: {
        headerText: `Add Location Setting`,
        model: null,
        onSave: async data => {
          let item = {
            ...data.settings,
            locationType: data.locationType,
            warehouseId: data.warehouseId,
            metadata: {
              ...(data.metadata || {})
            }
          }
          this.addItemToFormArray('settings', item);
        }
      },
      nzClassName: "modal",
    });
  }

  onBtnSave() {
    this.fileToUpload = { ...this.fileToUpload, ...this.fileLogos};
    super.onBtnSave();
  }

  onLogoChanged(data) {
    const key = 'logos';
    const { logos, files } = data;
    this.setItemValue(key, logos);
    this.fileLogos = files;
  }

  getLaneType(index: number): string {
    return this.getItemValue(`autoMessages[${index}].laneType`);
  }

  onAltCityChange(key) {
    // Do nothing
  }

  private loadZipcode(index, key, zipcode) {
    if (zipcode.length == 0) {
      return;
    }
    zipcode = zipcode.replace(' ', '')
    if (zipcode == this.zipcodeInfo[index][key].zipcode && this.zipcodeInfo[index][key].city && this.zipcodeInfo[index][key].state) {
      return;
    }
    this.zipcodeInfo[index][key] = {};
    this.zipcodeInfo[index][key].isLoading = true;
    this.zipcodeService.get(zipcode, resp => {
      this.zipcodeInfo[index][key].zipcode = zipcode;
      this.zipcodeInfo[index][key].city = resp.city;
      this.zipcodeInfo[index][key].state = resp.state;
      this.zipcodeInfo[index][key].desc = `${resp.city}, ${resp.state}`;
      this.zipcodeInfo[index][key].center = resp.center;
      this.zipcodeInfo[index][key].altCities = resp.altCities;
      this.zipcodeInfo[index][key].isLoading = false;
      this.formInput?.get(key)?.updateValueAndValidity();
      // this.onChangeLocation.emit({ zipcodeInfo: this.zipcodeInfo[index][key], key: key });
      Log.d('search zipcode success. ', this.zipcodeInfo);
    }, err => {
      this.zipcodeInfo[index][key].isLoading = false;
      this.zipcodeInfo[index][key].error = true;
      this.zipcodeInfo[index][key].desc = 'Invalid zip code'
      this.formInput?.get(key)?.updateValueAndValidity();
      Log.e('search zipcode failed. ', this.zipcodeInfo);
    })
  }

  showWarehouseName(item) {
    let arr = [], address = [];
    if (item?.name) arr.push(item.name);
    if (item?.pickAddr?.city) address.push(item.pickAddr.city);
    if (item?.pickAddr?.state) address.push(item.pickAddr.state);
    if (item?.pickAddr?.zipcode) address.push(item.pickAddr.zipcode);
    arr.push(address.join(', '));
    return arr.join(" - ")
  }

  private getListLocations() {
    let url = `${Const.APIURI_WAREHOUSES}/list/all_for_filter?exposeAddr=true`;
    this.api.GET(url).subscribe(
      resp => {
        this.listLocations = (resp.data.list_data || []).filter(it => it.name || it.pickAddr?.zipcode);
      }, err => {
        this.showErr(err);
      }
    );
  }

  getTimeZoneStandard(timezone: UsTimezone) {
    return DateUtil.mapTimezoneUS(timezone);
  }

  getTimeZoneShort(timezone: UsTimezone) {
    return DateUtil.usTimezoneShortDesc(timezone);
  }
  
}
