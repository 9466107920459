import { Component, ViewChild } from '@angular/core';
import { FormDataShipmentEntryDraftSingle, FormDataShipmentItem } from '@wearewarp/types-server-admin/form-data/shipment-entry';
import { ShipmentEntryTabContent } from '../../tab-content-component';
import { ComponentForm, FormDataOrderItems } from '@app/admin/shipment-entry/interface';
import { BaseForm } from '@app/admin/base/form-base';
import { FormOrderItems } from '../../forms/order-items';
import { FormOrderItem } from '../../forms/order-items/item';
import { DialogService } from '@dialogs/dialog.service';

@Component({
  selector: '[shipment-entry-items]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ShipmentEntryItems extends ShipmentEntryTabContent implements ComponentForm<FormDataOrderItems> {
  items: FormDataShipmentItem[] = []
  @ViewChild('formComp') formComp: FormOrderItems;
  
  getForm(): BaseForm<FormDataOrderItems> {
    return this.formComp;
  }

  get formModel() {
    return {items: ((<FormDataShipmentEntryDraftSingle>this.modelDraft)?.items ?? [FormOrderItem.defaultData])};
  }

  onBtnUploadListItems() {
    DialogService.comingSoon();
  }
}