import to from 'await-to-js';
import { ChangeDetectorRef, Component, Input, TemplateRef } from "@angular/core";
import { DispatchService } from "../../dispatchService";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription, merge } from "rxjs";
import RouteEntity from "../../entity/RouteEntity";
import StopEntity from "../../entity/StopEntity";
import IssueEntity from "../../entity/IssueEntity";
import { TaskType } from "@wearewarp/types";
import { DialogService } from "@dialogs/dialog.service";
import { BookAppointment, FormDataUpdateEta, FormDataUpdateInstructions, FormDataUpdateTaskStatus, FormDataUpdateTimeWindows, ModalHelper, UpdateEta, UpdateInstructions, UpdateTaskStatus, UpdateTimeWindows } from '@wearewarp/ng-antd'
import { MasterData } from "@services/master.data";
import { DrawerService } from "@app/drawers/drawer.service";
import { RoutePOD } from "../pod";
import { DateUtil } from "@services/date-utils";
import { Const } from "@const/Const";
import { EditShipmentEntryLocation } from "@app/admin/shipment-entry/components/edit-shipment/location-address";
import { FormDataEditLocationAddress } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { Log } from "@services/log";
import { ApiService } from "@services/api.service";
import { FormDataActualTime, FormDataAddReasonCode, JobUpdateFormId } from "@wearewarp/types-server-admin/form-data/dispatch";
import { ResponseStopItemsForDispatch, StopItemForDispatch } from "@wearewarp/types-server-admin/stop";
import { UpdateArrivedTime } from "../update-actual-time";
import { AddReasonCode } from "../add-reason-code";
import { StopSettings } from "../stop-settings";
import { NzModalService } from "ng-zorro-antd/modal";
import { RouteReturn } from "../init-return/task-return";
import { UIHelper } from "@services/UIHelper";
import { CarrierCostHelper } from "../carrier-cost/helper";
import {Const as WarpConst, WarpId} from "@wearewarp/universal-libs";
import { Task } from "@wearewarp/types/data-model";
import { getInjector } from '@services/injector';
import { CreateTONURouteDialog } from '../create-tonu-route-dialog';

@Component({
  selector: 'dispatch-route-stop',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class DispatchRouteStop {
  public isLoading = true;
  public displayInfo: any = {};
  protected subscription: Subscription = new Subscription();
  protected route: RouteEntity;
  public expanded?: boolean;
  public showShipments = false;
  public isGhostLoad = false;
  public isLinehaulNoShipment = false;
  public canGetPod = false;

  @Input() stop: StopEntity;
  @Input() issues: IssueEntity[] = [];
  @Input() isEnableUpdate = true;
  @Input() isShowActualTime = true;
  @Input() isExpandWithStatus = false;
  @Input() isEnableUpdateStatus = true;
  @Input() dispatchService2;
  @Input() stopNeedUpdateETA;

  constructor(
    public activatedRoute: ActivatedRoute,
    private dispatchService: DispatchService,
    private api: ApiService,
    private modalService: NzModalService,
    private modalHelper: ModalHelper,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    if (!this.dispatchService2) {
      this.subscription.add(
        this.dispatchService.routeData$.subscribe(() => {
          this.route = this.dispatchService.getRoute();
          if (this.route?.isGhostLoad()) {
            this.isEnableUpdate = false;
            this.isGhostLoad = true;
          }
          if (this.route?.isLinehaul()) {
            const shipmentIds = this.route.getShipments().map(it => it.getId()) || [];
            this.isLinehaulNoShipment = shipmentIds.length == 0;
            this.isEnableUpdate = shipmentIds.length > 0;
          }
          if (this.route?.isAssignedCarrier) {
            /** Check stop type */
            const isDropff = this.stop.getType() === Const.TaskType.DROPOFF;

            /** Check third-party */
            const isPrioriry1 = this.route.getCarrierId() === Const.CarrierId.priority1;

            this.canGetPod = isDropff && isPrioriry1;
          }
          // if (this.route?.isExternalRoute()) {
          //   this.isEnableUpdate = false;
          // }
        })
      )
      this.subscription.add(
        this.dispatchService.loading.subscribe(value => {
          this.isLoading = value;
        })
      )
      this.subscription.add(
        this.dispatchService.message.subscribe(value => {
          if (value?.type == 'error' && value?.message == 'This Route has a Carrier Assigned. Please notify the Carrier Sales Rep for this Route.') {
            this.showDialog(value.message);
          }
        })
      )
    } else {
      this.dispatchService = this.dispatchService2;
    }
    this.expandWithStatus();
    this.getDataStopItems();
  }
  ngOnChanges() {
    this.buildDisplayInfo();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  private dataStop: ResponseStopItemsForDispatch;
  public dataStopItems: StopItemForDispatch[] = [];
  private getDataStopItems() {
    const jobId = this.dispatchService.getRouteId();
    const stopId = this.stop.getId();
    const stopType = this.stop.getType();
    const url = Const.APIV2(`stops/items_for_dispatch?jobId=${jobId}&stopId=${stopId}&type=${stopType}`);
    this.api.GET(url).subscribe(
      resp => {
        this.dataStop = resp?.data;
        this.dataStopItems = this.dataStop?.items ?? [];
      }, err => {
        // UIHelper.showErr(err);
        console.error(`getDataStopItems failed. `, err);
      }
    );
  }

  public getItemCountConfirm(): number | number {
    return this.dataStop?.countConfirmedByDriver ?? this.displayInfo?.actualQuantity;
  }

  public toggleCollapse() {
    this.expanded = !this.expanded
  }

  get isSourceMarketplace() {
    return this.route.isSourceMaketplace();
  }

  get hadCarrier(){
    return this.dispatchService.getRoute()?.isAssignedCarrier();
  }

  ghostLoadAllowUpdateField(type) {
    return this.isGhostLoad && ['change-address', 'update-appointment'].includes(type);
  }

  linehaulAllowUpdateField(type) {
    return this.isLinehaulNoShipment && ['change-address', 'update-appointment'].includes(type);
  }

  public onBtnUpdateClick(type, isConfirm = false) {
    if (!(this.isEnableUpdate || this.ghostLoadAllowUpdateField(type) || this.linehaulAllowUpdateField(type))) return;
    switch (type) {
      case 'change-address':
        this.onChangeAddress();
        break;
      case 'update-appointment':
        this.onUpdateAppointment();
        break;
      case 'update-eta':
        this.onUpdateETA();
        break
      case 'change-actual-time':
        this.onChangeActualTime(isConfirm);
        break;
      case 'location-instruction':
        this.onUpdateLocationInstruction();
        break;
      case 'add-reason-code':
        this.onAddReasonCode();
        break;
      case 'update-schedule':
        this.onUpdateSchedule();
        break;
    }
  }

  public onBtnViewShipmentClick() {
    this.showShipments = !this.showShipments
  }

  public onBtnUpdateStatusClick() {
    if(this.isEnableUpdate) {
      let tasks: any = this.stop.getTasks().filter(task => ![Const.TaskStatus.failed, Const.TaskStatus.canceled, Const.TaskStatus.pickupFailed].includes(<any>task.getStatus()))
      if (tasks.length == 0) {
        tasks = this.stop.getTasks();
      }
      tasks = tasks.map(task => task?.toJSON());
      const shipments = this.stop.getShipments().map(sh => sh?.toJSON());
      if (this.stop.getStatus() == Const.TaskStatus.pickupFailed) return;
      for(let index in tasks) {
        let task = tasks[index];
        if(task?.tonu) continue;
        const carrierCostServiceOptions = this.route?.getCarrierCost()?.serviceOptions || [];
        let serviceCarrierHaveTonu = carrierCostServiceOptions.find(it => it._id === 'tonu');
        let shipmentId = task.shipmentId;
        let shipment = shipments.find(it => it.id === shipmentId);
        let shipmentCostServiceOptions = shipment?.cost?.serviceOptions || [];
        let serviceCustomerHaveTonu = shipmentCostServiceOptions.find(it => it._id === 'tonu');
        if(!serviceCustomerHaveTonu && !serviceCarrierHaveTonu) continue;
        let data = {
          ...(tasks[index]['statusChangeLog'] || {}),
          canceled: {
            info: {
              customerTONU: serviceCustomerHaveTonu?.total,
              carrierTONU: serviceCarrierHaveTonu?.total
            }
          }
        }
        tasks[index]['statusChangeLog'] = data
      }

      UpdateTaskStatus.openModal(this.modalHelper, {
        nzTitle: 'Update route status',
        nzComponentParams: {
          delayCodeOptions: [Const.TaskType.PICKUP, Const.TaskType.DROPOFF].includes(<any>this.stop.getType()) ? MasterData.getDelayCodesByType(this.stop.getType()) : MasterData.getDelayCodes(),
          isAssignedCarrier: this.dispatchService.getRoute()?.isAssignedCarrier(),
          isFirstStop: this.displayInfo.index == 1,
          tasks: tasks,
          shipments: shipments,
          showWarpIdShipment: s => WarpId.showShipmentCode(s),
          onSave: (data) => {
            return this.doUpdateStatus(tasks, data);
          },
          onCreateTonuRoute: (data) => {
            return this.createTonuRoute(data);
          },
        }
      })
    }
  }

  private async checkIfDriverUsingApp(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const jobId = this.route.getId();
      const driverId = this.route.getDriverId();
      if (!driverId) {
        // TODO: chỗ này cũng cần hiển thị cảnh báo nếu dispatcher update status mà route chưa có carrier/driver
        resolve(false);
        return;
      }
      const url = Const.APIV2(`jobs/${jobId}/check_if_driver_using_mobile_app`);
      const [err, resp] = await to(this.api.POST(url, {driverId}).toPromise());
      if (resp?.data?.isUsingApp == true) {
        let message = `It seems that the driver is using mobile app`;
        if (resp.data?.appVersion) {
          message += ` <b>version ${resp.data?.appVersion}</b>`;
        }
        if (resp.data?.deviceDesc) {
          message += ` on his device <b>${resp.data?.deviceDesc}</b>`;
        }
        message += `.<br><br>Please do not update status by yourself because it might cause unexpected behaviors on the driver app, instead, ask the driver to update status on the app.`;
        message += `<br/></br>Please make sure you know exactly what you are doing.`
        const modalService = getInjector().get(NzModalService);
        modalService.confirm({
          nzTitle: message,
          nzClosable: false,
          nzMaskClosable: false,
          nzCentered: true,
          nzClassName: 'warning-not-update-task-status-manually',
          nzOkText: 'Got it. I will ask the driver to update',
          nzOnOk: () => {
            resolve(true);
          },
          nzCancelText: 'No I really need to update manually',
          nzOnCancel: () => {
            resolve(false);
          }
        });
      } else {
        resolve(false);
      }
    });
  }

  private doUpdateStatus(tasks: Task[], data: FormDataUpdateTaskStatus) {
    return new Observable(o => {
      (async () => {
        const shouldAskDriverDoIt = await this.checkIfDriverUsingApp();
        if (shouldAskDriverDoIt) {
          o.next();
        } else {
          this.dispatchService.updateTaskStatus({tasks, data}).then(() => {
            this.dispatchService.refresh();
            o.next();
          }).catch(e => {
            o.error(e)
          })
        }
      })();
    }).subscribe()
  }

  private async buildDisplayInfo() {
    if (!this.stop) return;
    let issuesStop = this.issues.filter(it => it.getStopId() === this.stop.getId());
    let reviewActual = issuesStop.find(it => it.getType() === WarpConst.IssueType.reviewActuals);
    let addReasonCode = issuesStop.find(it => it.getType() === WarpConst.IssueType.addReasonCode);

    const newData = {
      shortType: this.getDisplayType(this.stop.getType()),
      type: this.stop.getType(),
      index: this.stop.getIndex() + 1,
      status: this.stop.getStatus(),
      warningStatus: this.stop.getWarningStatus(),
      totalPod: this.stop.getTotalPod(),
      warningPod: this.stop.getWarningPod(),
      podNotConfirmed: this.stop.getPodNotConfirmed(),
      locationName: this.stop.getLocationName(),
      deliveryInfo: this.stop.getDeliveryInfo(),
      appointment: this.stop.getAppointment(),
      appointmentCode: this.stop.getAppointmentCode(),
      warningAppointment: this.stop.getWarningAppointment(),
      isScheduled: this.stop.isAppointmentSchduled(),
      timezone: this.stop.getTimezone(),
      etaTime: this.stop.getETA(),
      shipmentCount: this.stop.getTasks().filter(task => task.getShipmentId()).length,
      tasks: this.stop.getTasks(),
      actualArrived: this.getDisplayTime(this.stop.getArrivedTime()),
      actualDeparted: this.getDisplayTime(this.stop.getDepartedTime()),
      refNums: this.stop.getRefNums(),
      isShowRefNumsFull: this.isShowRefNumsFull(),
      refNums_Short: this.getRefNums_Short(),
      refNums_More: this.getMoreRefNums(),
      delay: this.getDisplayDelayInfo(this.stop.getFirstTask()?.getDelay()),
      numberClient: this.stop.getShipmentClientIds()?.length,
      warningReviewActual: reviewActual && reviewActual?.getStatus() === WarpConst.IssueStatus.alert,
      warningAddReasonCode: addReasonCode && addReasonCode?.getStatus() === WarpConst.IssueStatus.alert,
      actualQuantity: this.stop.getScanningBarcodeResults()?.itemInfo?.actualQuantity ?? null,
    }

    if (JSON.stringify(newData) == JSON.stringify(this.displayInfo)) return;
    this.displayInfo = newData

    //chỉ chạy lần đầu để tránh giật
    if (this.expanded === undefined) {
      this.expanded = true;
    }
    this.representativeTask = this.displayInfo.tasks.filter(it => !it.data?.status || it.data?.status === 'enroute' || it.data?.status === 'created')[0];
    // this.cdr.detectChanges()
  }

  get isVisiblePOD() {
    return true;
    return !!this.displayInfo.warningPod || (this.displayInfo.numberClient && this.displayInfo.numberClient > 1)
  }

  representativeTask: any = null

  private getDisplayType(stopType: TaskType) {
    switch (stopType) {
      case Const.TaskType.PICKUP: return "PU"
      case Const.TaskType.RETURN: return "RE"
      default: return "DO"
    }
  }

  private getRefNums_Short() {
    let refNums = this.stop.getRefNums() || [];
    if (refNums.length <= 2) {
      return this.stop.getRefNums().join(', ');
    } else {
      return `${refNums[0]}, ${refNums[1]}`;
    }
  }

  private isShowRefNumsFull() {
    let refNums = this.stop.getRefNums() || [];
    return refNums.length <= 2;
  }

  private getMoreRefNums() {
    let refNums = this.stop.getRefNums() || [];
    if (refNums.length > 2) {
      return refNums.length-2;
    }
  }

  private getDisplayTime(time) {
    return DateUtil.displayLocalTime(time,
      {
        timezone: this.stop.getTimezone(),
        format: 'MM/DD/YY h:mm A'
      }
    )
  }

  private getDisplayDelayInfo(delayInfo) {
    if (!delayInfo) return null;
    let data: any = {};
    const listDelayCodes = MasterData.getDelayCodes();
    if (delayInfo?.delayCodeId) {
      data.failureCode = listDelayCodes.find((option) => option._id == delayInfo.delayCodeId)?.name;
      data.note = delayInfo.note;
    }
    return data;
  }

  public shouldInitiateReturn() {
    if (this.stop.getType() != Const.TaskType.DROPOFF) {
      return false;
    }
    // nếu đã tồn tại returntask thi ko tạo nữa
    if (this.stop.isExistsReturnTask()) {
      return false;
    }
    return this.stop.getStatus() == Const.TaskStatus.failed;
  }

  public onBtnInitReturn() {
    if (!this.shouldInitiateReturn()) return;
    DialogService.openDialog(RouteReturn, {
      nzComponentParams: {
        jobId: this.dispatchService?.getRoute()?.getId(),
        taskIds: this.stop.getTasks().map(item => item.getId()),
        shipmentWarpIds: this.stop.getShipments().map(item =>  item.getCodeText()),
        onSave: data => this.createReturnTask(data),
        onSubmitSucceeded: resp => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: "modal-no-padding modal-task-return",
      nzCentered: true,
      nzTitle: null,
      nzClosable: false,
    });
  }

  private createReturnTask(data: any) {
    const taskIds = this.stop.getTasks().map(item => item.getId());
    let url = Const.APIV2(`${Const.APIURI_TASKS}/batchUpdate`);
    return this.api.POST(url, {
      action: 'initiateReturn',
      data: taskIds.map(taskId => ({
        id: taskId,
        data: data
      }))
    });
  }

  public onBtnAdditionalCarrierCost() {
    CarrierCostHelper.openModalServiceOptions({
      jobId: this.dispatchService?.getRoute()?.getId(),
      stopId: this.stop.getId(),
      type: this.stop.getType(),
      title: 'Additional Carrier cost',
      onSuccess: () => {
        let msg = 'Carrier cost has been updated successfully.';
        this.showDialog(msg);
        this.dispatchService.refresh();
      }
    });
  }

  public onBtnAddPod() {
    let taskIds = (this.stop.getTasks() || []).map(item => item.getId());
    DrawerService.openDrawer1(RoutePOD, {
      nzContentParams: {
        taskIds: taskIds,
        stop: this.stop,
        jobId: this.dispatchService?.getRoute()?.getId(),
        openUploadForm: true,
        refreshData: data => {
          this.dispatchService.refresh();
        }
      },
      nzWidth: 400,
      nzWrapClassName: 'wrap-drawer route-pod-drawer',
    });
  }

  /** Get POD from third party (Default: Priority 1) */
  public onBtnGetPod() {
    this.isLoading = true;
    this.api.GET(`${Const.APIURI_THIRD_PARTY}/priority1/sync?jobId=${this.route.getId()}`).subscribe(
      resp => {
        this.dispatchService.refresh();
        this.isLoading = false;
      },
      err => {
        UIHelper.showErr(err);
        this.isLoading = false;
      }
    )
  }

  public onBtnViewPod() {
    let taskIds = (this.stop.getTasks() || []).map(item => item.getId());
    DrawerService.openDrawer1(RoutePOD, {
      nzContentParams: {
        taskIds: taskIds,
        stop: this.stop,
        jobId: this.dispatchService?.getRoute()?.getId(),
        refreshData: data => {
          this.dispatchService.refresh();
        }
      },
      nzWidth: 400,
      nzWrapClassName: 'wrap-drawer route-pod-drawer',
    });
  }

  private onChangeAddress() {
    const deliveryInfo = this.stop.getDeliveryInfo();
    const formDataModel: FormDataEditLocationAddress = {
      locationName: deliveryInfo.locationName,
      addr: deliveryInfo.addr,
      warehouseId: String(deliveryInfo.warehouseId),
    }
    DialogService.openFormDialog1(EditShipmentEntryLocation, {
      nzAutofocus: null,
      nzComponentParams: {
        headerText: `${this.locationType(this.stop.getType())} Location`,
        type: this.stop.getType(),
        model: formDataModel,
        closeOnSuccess: true,
        onSave: data => this.updateForm('location-address', data),
        onRefreshDetailOrder: () => {
          this.dispatchService.refresh()
        }
      },
      nzClassName: "modal",
    });
  }

  private locationType(type: TaskType) {
    return type == Const.TaskType.PICKUP ? 'Pickup' : 'Delivery';
  }

  private updateForm(formId: JobUpdateFormId, data: any) {
    Log.d(`updateForm ${formId} for ${this.stop.getType()}, data: `, data);
    const url = Const.APIV2(`${Const.APIURI_JOBS}/${this.dispatchService.getRoute().getId()}/${formId}`);
    const params = this.convertData(formId, data)
    return this.api.PUT(url, params);
  }

  private convertData(formId: JobUpdateFormId, data: any) {
    const tasks = this.stop.getTasks().map(it => it?.toJSON()) || [];
    const taskIds = tasks.map(it => it.id);
    const shipmentIds = this.stop.getShipments().map(sh => sh.getId()) || [];
    const deliveryIds = this.stop.getShipmentDeliveryInfo().map(item => item.id) || [];
    const locationType = this.stop.getType();

    let params = { ...data, taskIds, shipmentIds, deliveryIds, locationType }
    if (formId === 'actual-time') {
      let taskData = [];
      for (let task of tasks) {
        const { statusChangeLog, status } = task;
        const timezone = task.info.addr?.metadata?.timeZoneStandard;

        if (!statusChangeLog[status]) continue;
        if (params.delayCodeId) {
          if (!statusChangeLog[status].info) statusChangeLog[status].info = {};
          statusChangeLog[status].info.delayCodeId = params.delayCodeId;
          statusChangeLog[status].info.delayNote = params.note;
        } else if (statusChangeLog[status]?.info?.delayCodeId) {
          statusChangeLog[status].info.delayCodeId = null;
          statusChangeLog[status].info.delayNote = null;
        }

        if (statusChangeLog[status]) {
          for (let type of ['arrived', 'departed']) {
            if ([undefined, null, 'N/A'].includes(data[type])) continue;
            let changeWhen = DateUtil.convertLocalTime(data[type], timezone).toISOString();
            if (type === 'arrived') {
              statusChangeLog['arrived'] = {
                ...statusChangeLog[status],
                changeWhen
              }
            }
            if (type === 'departed') {
              statusChangeLog[status] = {
                ...statusChangeLog[status],
                changeWhen
              }
            }
          }
        }

        let item: any = {
          taskId: task.id,
          statusChangeLog
        }

        if(data.confirmed) item = { ...item, confirmed: true }

        taskData.push(item);
      }

      params = { taskData, taskIds, shipmentIds, deliveryIds, locationType, stopId: this.stop.getId() }
    }
    return params;
  }

  private onUpdateAppointment() {
    // luôn luôn book appointment
    const appointmentInfo = this.stop.getAppointment() || {};
    const deliveryInfo = this.stop.getDeliveryInfo();
    BookAppointment.openModal(this.modalHelper, {
      onSubmitError: (err) => {
        UIHelper.showErr(err);
      },
      onSubmitSucceeded: () => {
        this.dispatchService.refresh()
      },
      nzTitle: `${this.locationType(this.stop.getType())} Appointment`,
      nzComponentParams: {
        timezone: this.stop.getTimezone(),
        model: {
          appointmentInfo,
          reasonCodeId: deliveryInfo['reasonCodeId'],
        },
        reasonCodes: MasterData.getChangeDateTimeReasons(),
        submit: data => this.updateForm('location-appointment', data),
      }
    });
  }

  private onUpdateETA() {
    const timezone = this.stop.getTimezone();
    let data: FormDataUpdateEta;
    if (this.stop.getETA()?.from && this.stop.getETA()?.to) {
      data = {
        from: new Date(this.stop.getETA().from),
        to: new Date(this.stop.getETA().to),
        city: undefined,
        state: undefined,
      };
    } else {
      data = { from: null, to: null, city: undefined, state: undefined };
    }
    this.modalHelper.openForm<FormDataUpdateEta, UpdateEta>(UpdateEta, {
      onSubmitError: (err) => {
        UIHelper.showErr(err);
      },
      onSubmitSucceeded: (resp) => {
        this.dispatchService.refresh();
      },
      nzTitle: "Update ETA",
      nzComponentParams: {
        timezone: timezone,
        model: data,
        states: [
          { label: "US States", items: MasterData.getStatesUS() },
          { label: "Canada Provinces", items: MasterData.getCanadaProvinces() },
        ],
        currentDriverLocationFun: async () => {
          try {
            let currentDriverLocationApi = `${Const.APIV2(Const.APIURI_JOBS)}/${this.dispatchService
              ?.getRoute()
              ?.getId()}`;
            let location = (await this.api.GET(currentDriverLocationApi).toPromise())?.data?.assignedDriver
              ?.currentLocation;
            if (location?.city && location?.state) {
              return `${location.city}, ${location.state}`;
            } else return "";
          } catch (error) {
            console.error("Error get driver location data:", error);
            return "";
          }
        },
        searchCitiesFun: async (key) => {
          try {
            return (await this.api.searchUsCities(key).toPromise())?.data.list_data?.map(
              (it) => `${it.city}, ${it.stateCode}`
            );
          } catch (error) {
            console.error("Error search city data:", error);
            return [];
          }
        },
        getStateDesc: this.getStateDesc,
        submit: (data) => {
         const updateDriverLocation =
             this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/${this.dispatchService?.getRoute()?.getId()}/driver_location`, data)
         const updateEtaTime = this.updateForm("eta-time", { etaTime: data });
         const mergeActions = merge(updateDriverLocation, updateEtaTime);
             return mergeActions;
        },
      },
    });
  }

  public getStateDesc(state): string {
    let obj = state;
    if (typeof state == 'string') {
      obj = MasterData.getStateUSByCode(state);
    }
    if (!obj) {
      return state;
    }
    let str = obj.code;
    if (obj.name) {
      str += ` (${obj.name})`;
    }
    return str;
  }

  private onChangeActualTime(isConfirm) {
    if (!this.displayInfo.actualArrived && !this.displayInfo.actualDeparted) return;
    const delayInfo = this.stop.getFirstTask().getDelay();
    let data: FormDataActualTime = {
      arrived: this.displayInfo.actualArrived || 'N/A',
      departed: this.displayInfo.actualDeparted || 'N/A',
      delayCodeId: delayInfo?.delayCodeId,
      note: delayInfo?.note
    }

    DialogService.openFormDialog1(UpdateArrivedTime, {
      nzComponentParams: {
        type: this.stop.getType(),
        timezone: this.stop.getTimezone(),
        appointment: this.stop.getAppointment(),
        model: data,
        isConfirm: isConfirm,
        onSave: data => this.updateForm('actual-time', data),
        onRefreshDetailJob: () => {
          this.dispatchService.refresh()
        }
      },
      nzClassName: 'modal-no-padding modal-update-time',
    });
  }

  private onAddReasonCode() {
    const actualArrived = this.getDisplayTime(this.stop.getArrivedTime());
    if (!actualArrived) return;
    const delayInfo = this.stop.getFirstTask().getDelay();
    let data: FormDataAddReasonCode = {
      delayCodeId: delayInfo?.delayCodeId,
      delayNote: delayInfo?.note
    }

    DialogService.openFormDialog1(AddReasonCode, {
      nzComponentParams: {
        type: this.stop.getType(),
        model: data,
        stopIndex: this.stop.getIndex() + 1,
        onSave: data => this.submitAddReasonCode(data),
        onRefreshDetailJob: () => {
          this.dispatchService.refresh()
        }
      },
      nzClassName: 'modal-no-padding modal-add-reason-code',
    });
  }

  private submitAddReasonCode(data: any) {
    const url = Const.APIV2(`${Const.APIURI_TASKS}/batchUpdate`);
    const tasks = this.stop.getTasks().map(it => it?.toJSON()) || [];
    const params = {
      action: 'updateReasonCode',
      data: tasks.map(task => ({
        id: task.id,
        data: data
      }))
    }

    return this.api.POST(url, params);
  }

  private onUpdateLocationInstruction() {
    let data: FormDataUpdateInstructions = {
      instructions: this.stop.getDeliveryInfo().instructions
    }
    this.modalHelper.openForm<FormDataUpdateInstructions, UpdateInstructions>(UpdateInstructions, {
      onSubmitError: (err) => {
        UIHelper.showErr(err);
      },
      onSubmitSucceeded: (resp) => {
        this.dispatchService.refresh()
      },
      nzTitle: 'Location Instructions',
      nzComponentParams: {
        model: data,
        submit: (data) => {
          return this.updateForm('location-instructions', data);
        }
      }
    })
  }

  public onBtnUpdateSettings() {
    let data = {
      settings: this.stop.getDeliveryInfo().settings,
      type: this.stop.getType()
    }
    DialogService.openFormDialog1(StopSettings, {
      nzComponentParams: {
        headerText: `Location Settings`,
        model: data,
        onSave: data => this.updateForm('location-settings', data),
        updateSuccess: resp => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: "modal",
    });
  }

  protected showDialog(message: string | TemplateRef<any>, onOK = () => { }) {
    this.modalService.create({
      nzContent: message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'OK',
      nzOnOk: onOK,
      nzCancelText: null
    });
  }

  public disableBtnEditStatus() {
    if(this.isEnableUpdate == false) {
      return 'disable-edit-status-btn'
    } else {
      return;
    }
  }

  expandWithStatus() {
    if(this.isExpandWithStatus == true) {
      if(this.displayInfo.status == Const.TaskStatus.enroute) {
        this.expanded = true;
      } else {
        this.expanded = false;
      }
    }
  }

  getStopNeedUpdateETA() {
    let data : any = this.dispatchService?.getRoute()?.getData();
    this.stopNeedUpdateETA = data?.issues?.find(issue => issue.status == 'snoozed'  || issue.status == 'alert');
    if(this.stopNeedUpdateETA?.stopId == this.stop?.getId()) {
      return true;
    }
    return false;
  }

  expandShipments() {
    if(!this.expanded) this.expanded = true;
    if(!this.showShipments) this.showShipments = true;
  }

  public get mileage(): string {
    if (!this.stop) return '';
    return this.stop.getEstimateTraffic()?.mileage;
  }

  public get hours(): string {
    if (!this.stop) return '';
    return this.stop.getEstimateTraffic()?.hours;
  }

  get isStyleDisplaySmall() {
    return window.innerWidth < 1700;
  }

  isShowRef(): boolean{
    return this.displayInfo.refNums?.length > 0;
  }

  onUpdateSchedule() {
    UpdateTimeWindows.openModal(this.modalHelper, {
      onSubmitError: err => UIHelper.showErr(err),
      onSubmitSucceeded: resp => {},
      nzTitle: `${this.locationType(this.stop.getType())} Time Windows`,
      nzComponentParams: {
        timezone: this.stop.getTimezone(),
        reasonCodes: MasterData.getChangeDateTimeReasons(),
        model: {
          windows: this.stop.getTimeWindows() || [],
          reasonCodeId: this.stop.getReasonCodeId()
        },
        submit: (data: FormDataUpdateTimeWindows) => {
          this.updateForm('location-windows', data).subscribe(
            resp => {
              this.dispatchService.refresh()
            }
          )
        }
      }
    });
  }

  createTonuRoute(data: any = {}) {
    console.log(data);
    let modelData = {
      carrierTONU: data.carrierTONU,
      customerTONU: data.customerTONU,
      cancelReason: data.cancelReason,
    }
    DialogService.openFormDialog1(CreateTONURouteDialog, {
      nzClosable: false,
      nzMaskClosable: false,
      nzClassName: 'modal',
      nzWidth: '500px',
      nzComponentParams: {
        jobId: this.route.getId(),
        model: modelData,
        onSubmitSucceeded: (resp) => {
          this.dispatchService.refresh();
        }
      }
    })
  }

}
