import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { SearchBoxModule } from '@libs/search-box/search-box.module';

import { DialogsModule } from '@dialogs/dialogs.module';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { SharedModule } from '@app/admin/shared/shared.module';
import { UiCommonModule } from '@app/admin/components/common/module';
import { FraudDetection } from '.';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzPopoverModule,
    NzStepsModule,
    NzTableModule,
    NzTagModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzInputModule,
    NzFormModule,
    NzPaginationModule,
    NzDatePickerModule,
    NzAutocompleteModule,
    NzTabsModule,
    SearchBoxModule,
    SharedModule,
    DialogsModule,
    UiCommonModule,
    NzEmptyModule,
  ],
  declarations: [
    FraudDetection,
  ],
  exports: [
    FraudDetection,
  ],
  providers: [
  ]
})
export class FraudDetectionModule {}
