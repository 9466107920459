import { Const } from "@const/Const";
import { MasterData } from "@services/master.data";
import { TaskType } from "@wearewarp/types";
import { ServiceOptionType } from "@wearewarp/types/rest-api";

interface ServiceOptionsHelperParams {
  selectedItems: Array<string>,
  allItems: Array<ServiceOptionType>,
  sum: () => string,
  remove: (index: number) => void,
  name: (id: string) => string,
  has: (key: string) => boolean
}

// Dùng với nz-select và nz-tag
export class ServiceOptionsHelper {
  private type: TaskType;
  private getFormValue: () => Array<string>;
  private setFormValue: (data: Array<string>) => void;

  public params: ServiceOptionsHelperParams = {
    selectedItems: [],
    allItems: [],
    sum: () => this.sumServiceOptions(),
    remove: (index) => this.removeServiceOptions(index),
    name: (id) => MasterData.getServiceOptionName(id),
    has: (key) => this.params.selectedItems.length && this.params.selectedItems.some((it) => it == key)
  }

  constructor(type: TaskType, fnGet: () => Array<string>, fnSet: (data: Array<string>) => void) {
    this.type = type;
    this.getFormValue = fnGet;
    this.setFormValue = fnSet;
    this.params.allItems = MasterData.getServiceOptionsByType(this.type);
  }

  public bindSelectedItems(itemIds: Array<string>) {
    this.params.selectedItems = [...itemIds];
  }

  private sumServiceOptions(): string {
    let count = this.params.selectedItems.length;
    return `${count} ${count > 1 ? 'options' : 'option'}`
  }

  private removeServiceOptions(index: number) {
    let arr: Array<string> = [];
    for (let i = 0; i < this.params.selectedItems.length; i++) {
      if (i != index) {
        arr.push(this.params.selectedItems[i]);
      }
    }
    this.params.selectedItems = arr;
    this.serviceOptionsSelectionChange();
  }

  public serviceOptionsSelectionChange() {
    let currentOptions = this.getFormValue() ?? [];
    let arr: Array<string> = [];
    for (let item of currentOptions) {
      let option = MasterData.getServiceOptionById(item);
      if (option.type == 'pickup' && this.type == Const.TaskType.PICKUP) {
        if (!this.params.selectedItems.includes(option._id)) {
          continue;
        }
      } else if (option.type == 'delivery' && this.type == Const.TaskType.DROPOFF) {
        if (!this.params.selectedItems.includes(option._id)) {
          continue;
        }
      }
      arr.push(item);
    }
    for (let itemId of this.params.selectedItems) {
      if (arr.filter(it => it == itemId).length == 0) {
        arr.push(itemId);
      }
    }
    this.setFormValue(arr);
  }
}
