import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { DialogService } from "@dialogs/dialog.service";
import { Log } from "@services/log";
import { EditShipmentEntryAddRemoveStops } from ".";
import { ResponseAdminOrderDetail } from "@wearewarp/types-server-admin";

@Component({
  selector: 'shipment-entry-select-edit-mode',
  templateUrl: './select-edit-mode.html',
  styleUrls: []
})
// Component sử dụng khi muốn edit từ single-shipment ==> batch-shipment
export class ShipmentEntrySelectEditMode extends BaseDialog {

  @Input() modelOrder: ResponseAdminOrderDetail;
  onOk: () => void

  ngOnInit(): void {
    super.ngOnInit();
  }

  public batchTypes = [
    {id: Const.ShipmentEntryMode.multiDrop, name: 'Single Pickup/Multi Dropoff'},
    {id: Const.ShipmentEntryMode.multiPick, name: 'Multi Pickup/Single Dropoff'},
    {id: Const.ShipmentEntryMode.multiPickDrop, name: 'Multi Pickup/Multi Dropoff'},
  ];

  onBtnSelectEditMode(id) {
    this.closeDialog();
    DialogService.openDialog(EditShipmentEntryAddRemoveStops, {
      nzMaskClosable: false,
      nzComponentParams: {
        currentShipmentEntryMode: Const.ShipmentEntryMode.single,
        targetShipmentEntryMode: id,
        modelOrder: this.modelOrder,
        onOk: () => { this.onOk()}
      },
      nzClassName: "modal-xxl",
    });
  }
    
}
