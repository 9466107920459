import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ItemBidSessionComponent } from '../component/item-bid-session';
import { BidSessionListComponent } from '.';

@NgModule({
  imports: [
    CommonModule,
    NzTableModule,
    SearchBoxModule,
    NzTabsModule,
    NzBadgeModule,
    NzGridModule,
    RouterModule,
    NzIconModule
  ],
  declarations: [
    BidSessionListComponent,
    ItemBidSessionComponent
  ],
  exports: [
    BidSessionListComponent
  ],
  providers: [
  ]
})
export class BidSessionListModule { }