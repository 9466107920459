import { BaseComponent } from '@abstract/BaseComponent';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RollShipmentForm } from '@app/admin/planning/roll-shipment-form';
import { Const } from '@const/Const';
import { BizUtil } from '@services/biz';
import { DataorchService } from '@services/dataorch.service';
import { MasterData } from '@services/master.data';
import { PlanningService } from '@services/planning.service';
import { UIHelper } from '@services/UIHelper';
import { ModalHelper, UpdateTimeWindows } from '@wearewarp/ng-antd';
import dayjs from 'dayjs';
import _ from 'underscore';
import { Utils as ServiceUtils } from "@services/utils";
import { UpdateShipmentReviewStatusForm } from '@app/admin/planning/update-review-status';


@Component({
  selector: 'sqv2-shipment-need-routing-list',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ShipmentNeedRoutingList extends BaseComponent {
  momentTypeOptions = [
    { value: 'past', label: 'Past' },
    { value: 'today', label: 'Today' },
    { value: 'tomorrow', label: 'Tomorrow' },
    { value: 'future', label: 'Future' },
  ]
  momentType: any = '';
  _shipments: any[] = null
  service: PlanningService
  dataorch: DataorchService
  filterError: string = null
  filteredCount: any = null
  checked: boolean = false
  indeterminate: boolean = false
  setOfCheckedId = new Set<string>();
  routePlanningSessions = Const.routePlanningSessions;
  list: any
  get shipments() {
    return this._shipments
  }
  @Input() set shipments(v) {
    this.calculateDays()
    // this._shipments = v.map(this.processShipment)
    // this.planned = this._shipments.filter(it => it.session?.id)
    // const apptRequired = (it) => (it.dropoff.requiresAppointment && !it.dropoff.appointmentInfo?.from) || (it.pickup.requiresAppointment && !it.pickup.appointmentInfo?.from)
    // this.pendingAppt = this._shipments.filter(it => !it.session?.id).filter(apptRequired)
    // this.unplanned = this._shipments.filter(it => !it.session?.id).filter(it => !apptRequired(it))
    this._shipments = v
    this.list = v
    // this.filterShipments()
    // update checked id
    const allIds = new Set<string>();
    for (let s of v) {
      allIds.add(s.id)
    }
    this.setOfCheckedId = new Set([...this.setOfCheckedId].filter(it => allIds.has(it)))
  }
  @Input() tab: 'verificationNeeded' | 'pending' | 'planned' = 'verificationNeeded'

  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPlanningAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() hoverShipment: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickShipment: EventEmitter<any> = new EventEmitter<any>();
  @Output() reviewStatusUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() deliveryInfoUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() shipmentsUpdated: EventEmitter<any> = new EventEmitter<any>();
  constructor(private modalHelper: ModalHelper) {
    super()
    this.service = new PlanningService(this.api)
    this.dataorch = new DataorchService(this.api)
    // this.processShipment = this.processShipment.bind(this)
    this.rollShipmentsToDate = this.rollShipmentsToDate.bind(this)
    this.addShipmentsToPlanning = this.addShipmentsToPlanning.bind(this)
  }

  ngOnInit(): void {
  }

  onMomentTypeChange(value) {
    if(this.momentType === value){
      this.momentType = '';
      this.list = this.shipments;
      return;
    }
      this.momentType = value;
    const isToday = (shipment) => {
      const { pickupDate, dropoffDate } = shipment?.metadata || {}
      if (pickupDate && pickupDate == this.today) return true
      if (dropoffDate && dropoffDate == this.today) return true
      return false
    }
    const isTomorrow = (shipment) => {
      const { pickupDate, dropoffDate } = shipment?.metadata || {}
      if (pickupDate && pickupDate == this.tomorrow) return true
      if (dropoffDate && dropoffDate == this.tomorrow) return true
      return false
    }
    const isFuture = (shipment) => {
      const { pickupDate, dropoffDate } = shipment?.metadata || {}
      if (pickupDate == 'N/A' && dropoffDate == 'N/A') return false
      if (pickupDate && pickupDate != 'N/A' && pickupDate <= this.nextWorkingDay) return false
      if (dropoffDate && dropoffDate != 'N/A' && dropoffDate <= this.nextWorkingDay) return false
      return true
    }
    const isPast = (shipment) => {
      const { pickupDate, dropoffDate } = shipment?.metadata || {}
      if (pickupDate == 'N/A' && dropoffDate == 'N/A') return false
      if (pickupDate && pickupDate != 'N/A' && pickupDate >= this.today) return false
      if (dropoffDate && dropoffDate != 'N/A' && dropoffDate >= this.today) return false
      return true
    }
    
    switch (this.momentType) {
      case 'past':
        this.list = this.shipments.filter(isPast)
        break
      case 'today':
        this.list = this.shipments.filter(isToday)
        break
      case 'tomorrow':
        this.list = this.shipments.filter(isTomorrow)
        break
      case 'future':
        this.list = this.shipments.filter(isFuture)
        break
      default: this.list = this.shipments
    }
  }

  readinessColors = ['red', 'black', 'gray', 'orange', 'blue', 'green']

  today: string = ''
  tomorrow: string = ''
  nextWorkingDay: string = ''
  coming: string[] = []


  calculateDays() {
    const now = dayjs()
    const today = now.startOf('day')
    const tomorrow = today.add(1, 'day')
    const dow = tomorrow.day()
    const nextWorkingDay = dow == 0 ? tomorrow.add(1, 'day') : dow == 6 ? tomorrow.add(2, 'day') : tomorrow
    const coming: any[] = []
    let day = today
    coming.push(day)
    while (day.isBefore(nextWorkingDay)) {
      day = day.add(1, 'day')
      coming.push(day)
    }
    this.today = today.format('YYYY-MM-DD')
    this.tomorrow = tomorrow.format('YYYY-MM-DD')
    this.nextWorkingDay = nextWorkingDay.format('YYYY-MM-DD')
    this.coming = coming.map(it => it.format('YYYY-MM-DD'))
  }

  onBtnRollToDate(shipment) {
    this.rollShipmentsToDate([shipment])
  }

  rollShipmentsToDate(shipments) {
    const modalRef = this.modalHelper.open(RollShipmentForm, {
      nzTitle: "Move Shipment to different Date",
      nzComponentParams: {
        shipments,
        onComplete: (results) => {
          this.shipmentsUpdated.emit(results)
          this.setOfCheckedId.clear()
          modalRef.close()
        },
        onCancel: () => {
          modalRef.close()
        }
      },
      nzFooter: null
    })
  }

  addShipmentsToPlanning(ids) {
    this.service.addShipmentsToPlanning(ids).subscribe((res) => {
      const byShipment = {}
      for (let s of res) {
        byShipment[s.shipmentId] = s
      }
      // slow
      for (let shipment of this._shipments) {
        const session = byShipment[shipment.id]
        if (session) {
          shipment.addingStatus = 'ADDED'
          shipment.session = {
            id: session.sessionId
          }
          this.onPlanningAdded.emit(shipment)
        }
      }
    })
  }

  onBtnEditWindowsTime(shipment, deliveryInfo) {
    UpdateTimeWindows.openModal(this.modalHelper, {
      onSubmitError: err => UIHelper.showErr(err),
      onSubmitSucceeded: res => {
        const s = this._shipments.filter(it => it.id === shipment.id)[0]
        if (!s) return
        if (deliveryInfo.type === 'PICKUP') {
          s.pickup = res.data
          s.pickupWindow = BizUtil.getDeliveryInfoTime(res.data, { showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D' }) || '-'
        } else {
          s.dropoff = res.data
          s.dropoffWindow = BizUtil.getDeliveryInfoTime(res.data, { showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D' }) || '-'
        }
        this.deliveryInfoUpdated.emit({ id: shipment.id, deliveryInfo: res.data })
      },
      nzTitle: `Shipment ${shipment.warpId} ${deliveryInfo.type} Time Windows`,
      nzComponentParams: {
        timezone: deliveryInfo.addr?.metadata?.timeZoneStandard,
        model: {
          windows: deliveryInfo.windows,
          reasonCodeId: deliveryInfo.reasonCodeId,
        },
        reasonCodes: MasterData.getChangeDateTimeReasons(),
        submit: data => this.updateDeliveryInfo(shipment.id, deliveryInfo.id, data),
      }
    });
  }
  private updateDeliveryInfo(shipmentId, deliveryInfoId, data) {
    const url = Const.APIV2(`shipments/${shipmentId}/delivery-info/${deliveryInfoId}`)
    return this.api.PUT(url, data)
  }
  onCopyId(event, id) {
    event.stopPropagation()
    if (!id) return
    ServiceUtils.copyTextToClipboard(id.toString(), (e) => {
      if (e) {
        this.showErr("Cannot copy to clipboard");
      } else {
        this.showSuccess(
          `Copied ${id} to the clipboard`
        );
      }
    })
  }
  onCopyIds() {
    const ids = this._shipments.filter(it => this.setOfCheckedId.has(it.id)).filter(it => it.warpId).filter(it => !it.excluded).map(it => it.warpId.toString())
    ServiceUtils.copyTextToClipboard(ids.join(", "), (e) => {
      if (e) {
        this.showErr("Cannot copy to clipboard");
      } else {
        this.showSuccess(
          `Copied ${ids.length} ids to the clipboard`
        );
      }
    })
  }

  removeRoutingRequirement(event, item) {
    event.stopPropagation()
    this.modalHelper.confirmDelete({
      message: `Are you sure you want to mark shipment ${item.code ?? ''} [${item.warpId}] as routing no longer needed?`,
      fnOk: () => {
        this.dataorch.updateShipmentMetadata(item.id, { needRouting: false }).subscribe((res) => {
          item.excluded = true
          item.removed = true
        })
      }
    })
  }

  onRollShipments() {
    const selected = this._shipments.filter(it => this.setOfCheckedId.has(it.id))
    if (!selected.length) return
    this.rollShipmentsToDate(selected)
  }

  onAddShipmentsToPlanning() {
    const selected = this._shipments.filter(it => this.setOfCheckedId.has(it.id))
    if (!selected.length) return
    const codes = selected.map(it => `${it.code ?? ""} [${it.warpId}]`).join(", ")
    const ids = selected.map(it => it.id)
    this.modalService.confirm({
      nzContent: `Please confirm you want to add the following shipments to planning: ${codes}`,
      nzOnOk: () => {
        this.addShipmentsToPlanning(ids)
      }
    })
  }

  addToPlanning(event, id) {
    event.stopPropagation()
    const shipment = this._shipments.filter(it => it.id === id)[0]
    if (!shipment) return
    shipment.addingStatus = 'ADDING'
    this.service.addShipmentToPlanning(id).subscribe((res) => {
      shipment.addingStatus = 'ADDED'
      shipment.session = {
        id: res.sessionId,
      }
      this.onPlanningAdded.emit(shipment)
    }, (err) => {
      shipment.addingStatus = 'ERROR'
    })
  }

  updateShipmentReviewStatus(event, shipment) {
    event.stopPropagation()
    this.modalService.create({
      nzTitle: `Update Review Status for shipment ${shipment.warpId}`,
      nzContent: UpdateShipmentReviewStatusForm,
      nzWidth: "800px",
      nzComponentParams: {
        id: shipment.id,
        warpId: shipment.warpId,
        status: shipment.reviewStatus || {}
      },
      nzOnOk: (comp) => {
        comp.onSave().subscribe((res) => {
          const { review } = res.data
          const { status } = review
          shipment.reviewStatus = status
          this.reviewStatusUpdate.emit(res.data)
        }, (err) => {
          this.showErr(err)
        })
      },
      nzOnCancel: (comp) => {
        comp.onCancel()
      }
    })
  }

  canAdd: boolean = false
  checkCanAdd() {
    this.canAdd = this.shipments?.length && this.shipments.filter(it => it.issue).length < 1
  }

  removeShipment(event, id) {
    event.stopPropagation()
    let removed = false
    this._shipments.filter(it => it.id === id).map((r) => {
      r.excluded = !r.excluded
      removed = r.excluded
    })
    this.checkCanAdd()

    this.onRemove.emit({ id, removed })
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
  }

}
