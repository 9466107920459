import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { environment } from "@env/environment";

@Component({
    selector: '[wwex-info]',
    templateUrl: './wwex.html',
    styleUrls: ['./index.scss']
})  
export class WwexInfo extends BaseComponent {
    @Input() info: any = null

    bolLink: any = null
    palletLabelLink: any = null
    bols: string = null
    scheduledDelivery = null
    identifiers = null
    status = null
    carrier = null
    rate: number | null = null
    canCancel: boolean = false
    cancelling: boolean = false
    confirmingCancel: boolean = false
    externalTrackingLink: string | null = null
    rateDetail: any[]

    @Output() onCancel: EventEmitter<any> = new EventEmitter()

    ngOnInit(): void {
        const { pickupOrderResponse, shipmentOrderResponse, metadata } = this.info || {}
        if (this.info.rate) {
            this.rate = Math.round(this.info.rate * 100) / 100
        }
        const pickupIdentifiers = pickupOrderResponse?.order?.orderedItemList?.flatMap(it => it.secondaryTxnIdList ?? [])?.map(it => Object.assign({type: 'BOL'}, it))
        const orderIdentifiers = shipmentOrderResponse?.order?.orderedItemList?.flatMap(it => it.secondaryTxnIdList ?? [])?.map(it => Object.assign({type: 'BOL'}, it))
        console.log(pickupIdentifiers)
        this.identifiers = pickupIdentifiers?.length ? pickupIdentifiers : orderIdentifiers
        const documentList: any[] = []
        for (let item of shipmentOrderResponse?.order?.orderedItemList ?? []) {
            for (let doc of item.documentList ?? []) {
                documentList.push(doc)
            }
        }
        this.bolLink = documentList.filter(it => it.docType == "BILL_OF_LADING")[0]
        this.palletLabelLink = documentList.filter(it => it.docType == "PALLET_LABEL")[0]

        this.status = this.info?.status
        this.canCancel = this.status == null || !this.status || this.status == 'PENDING_PICKUP'  || this.status == 'CREATED'

        const { carrierCode, carrierName, rate } = metadata?.option || {}
        if (rate?.items?.length) {
            this.rateDetail = rate.items
        } else {
            this.rateDetail = []
        }
        if (carrierCode) {
            this.carrier = `[${carrierCode}] ${carrierName}`
        }

        if (carrierCode == 'ABFS') {
            const pro = this.identifiers?.filter(it => it.type == 'PRN')[0]
            if (pro?.value) {
                this.externalTrackingLink = `https://view.arcb.com/nlo/tools/tracking/${pro.value}`
            }
        } else if (carrierCode == 'CTII') {
            this.externalTrackingLink = 'https://www.centraltransport.com/tools/track-shipment'
        }
    }

    onConfirmCancelBtn() {
        const shipmentId = this.info.subject.split('_')[1]
        const url = environment.backendUrl + `/v2/pricing/quote/shipments/${shipmentId}/cancel`
        this.cancelling = true
        this.api.POST(url).subscribe((res) => {
            this.cancelling = false
            this.onCancel.emit()
        })
    }
    onCancelBtn() {
        this.confirmingCancel = true
    }
    onRejectCancelBtn() {
        this.confirmingCancel = false
    }

    onDownloadFile(doc: any) {
        if (doc.downloading) return
        if (doc.url) {
            return window.open(doc.url, '_blank')
        }
        const url = environment.backendUrl + `/v2/pricing/quote/booked/WWEX_${this.info.id}/documents/${doc.s3fileName}`
        doc.downloading = true
        this.api.GET(url).subscribe((res) => {
            doc.url = res[0].url
            doc.downloading = false
            window.open(doc.url, '_blank')
        })

    }
}