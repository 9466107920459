import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { Const } from '@const/Const';
import { Log } from '../services/log';
import { io, Socket } from 'socket.io-client';
import { AuthService } from '@wearewarp/ng-web';
import { SocketEvent } from '@app/enum';

interface SocketEmitParam<T = any> {
  data?: T,
  callback?: Function,
}

@Injectable({providedIn: 'root'})
export class SocketService {
    private readonly serverUrl = environment.socketUrl;
    private socket: Socket | undefined;
    private connectionStatus = new BehaviorSubject<boolean>(false);
    private registeredEvents: { [key: string]: Subject<any> } = {};

    constructor(private auth: AuthService) {
    }

    subscribeConnectionStatus(next: (value: boolean) => void) {
      return this.connectionStatus.subscribe(next);
    }

    private connectionStatusChange(connectionEvent: string, data?: any) {
      Log.d(`[socket-receive-event] ${connectionEvent} `, data);
      if (this.connectionStatus.value != this.isConnected) {
        this.connectionStatus.next(this.isConnected);
      }
    }

    private init() {
      if (!this.serverUrl) {
        return;
      }
      if (this.socket) {
        return; // đã khởi tạo trước đó rồi thì thôi
      }
      let ops = {
        transports: ['websocket'],
        auth: (cb) => {
          cb({ token: this.auth.getAccessToken() })
        },
        query: { app: Const.APP_HTTP_HEADER }
      };
      const socket = io(this.serverUrl, ops);
      // Manager events
      socket.io.on('reconnect_attempt', () => this.connectionStatusChange('reconnect_attempt'));
      socket.io.on('reconnect', () => this.connectionStatusChange('reconnect'));
      socket.io.on('reconnect_error', error => this.connectionStatusChange('reconnect_error', error));  // Will repeat reconnect_attemp
      socket.io.on('reconnect_failed', () => this.connectionStatusChange('reconnect_failed'));          // Fired when couldn't reconnect within reconnectionAttempts

      // Socket events
      socket.on('connect', () => Log.d('[socket] connect'));   // Do nothing, wait for welcome event
      socket.on('connect_error', error => this.connectionStatusChange('connect_error', error));   // Will lead to reconnect_attemp
      socket.on('disconnect', reason => this.connectionStatusChange('disconnect', reason));

      // Acknowledgements are not caught (https://socket.io/docs/v4/emitting-events/#acknowledgements)
      socket.onAny(this.handleEvents.bind(this));
      this.socket = socket;
    }

    public connect() {
      if (!this.serverUrl) {
        Log.e('socketServerUrl is not available');
        return;
      }
      if (this.isConnected) {
        return;
      }
      if (!this.socket) {
        this.init();
      }
      this.socket?.connect();
    }

    private handleEvents(...args: any[]) {
      const event: string = args[0];
      let callback: Function | undefined;
      if (args.length > 1 && typeof args[args.length - 1] == 'function') {
        callback = args[args.length - 1];
      }
      let data;
      if (args.length > 1 && typeof args[1] != 'function') {
        data = args[1];
      }
      if (data) {
        Log.d(`[socket-receive-event] ${event}, data: `, data);
      } else {
        Log.d(`[socket-receive-event] ${event}`);
      }
      if (event == SocketEvent.welcome) {
        this.connectionStatusChange(event);
        Log.d('[socket] connect successfully');
      }
      if (this.registeredEvents[event]) {
        this.registeredEvents[event].next(data)
      }
    }

    public subscribeEvent<T = any>(eventName: string, next: (data: T) => void): Subscription {
      if (!this.registeredEvents[eventName]) {
        this.registeredEvents[eventName] = new Subject();
      }
      const sub = this.registeredEvents[eventName].subscribe(next);
      if (!this.isConnected) {
        this.connect();
      }
      return sub;
    }

    public disconnect() {
      this.socket?.disconnect();
    }

    public get isConnected(): boolean {
      return this.socket && this.socket.connected;
    }

    public emit<T = any>(event: string, params?: SocketEmitParam<T>): boolean {
      if (this.isConnected) {
        Log.d(`[socket-send-event] ${event}, data: `, params.data);
        this.socket.emit(event, params.data, params.callback);
        return true;
      } else {
        console.error(`[socket-send-event] ${event} while not connected`);
        return false;
      }
    }

}
