import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from "ng-zorro-antd/input";
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { DetailModule } from '@app/admin/base/detail.module';
import { CreateFinStatement } from './create';
import { DetailFinStatement } from './detail';
import { FormInputUploadImagesModule } from '@app/admin/base/form-input-upload-images/module';
import { SearchDenimCompany } from './denim/search-denim-company';
import { CreateDenimJob } from './denim/create-denim-job';
import { SearchDenimJobInput } from './denim/search-job';
import { DenimJobCreateSuccessfully } from './denim/success-dialog';
import { CreateFinStatementForFixedRate } from './create-for-carrier-fixed-rate';
import { StatementCreateSuccessfully } from './create-success-dialog';
import { StatementAdditionalServiceInput } from './create-for-carrier-fixed-rate/additional-service';
import { ModuleCarrierPaymentTerm } from '@app/admin/components/forms/payment-term/module';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { CreateDenimContractor } from './denim/create-denim-contractor';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzModalModule,
    NzTableModule,
    NzCheckboxModule,
    NzDescriptionsModule,
    NzPopoverModule,
    NzRadioModule,
    NzDatePickerModule,
    NzCalendarModule,
    NzSpinModule,
    NzToolTipModule,
    NzDividerModule,
    NzDropDownModule,
    NzIconModule,
    NzSelectModule,
    NzButtonModule,
    NzInputModule,
    DetailModule,
    FormInputUploadImagesModule,
    ModuleCarrierPaymentTerm,
    NzPopconfirmModule
  ],
  declarations: [
    CreateFinStatement,
    CreateFinStatementForFixedRate,
    StatementCreateSuccessfully,
    DetailFinStatement,
    CreateDenimJob,
    SearchDenimCompany,
    SearchDenimJobInput,
    DenimJobCreateSuccessfully,
    StatementAdditionalServiceInput,
    CreateDenimContractor,
  ],
  exports: [
    CreateFinStatement,
    DetailFinStatement,
    SearchDenimCompany,
  ]
})
export class ModuleFinStatement {}
