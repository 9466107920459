import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { DialogService } from "@dialogs/dialog.service";
import { DownloadingComponent } from "@app/admin/components/downloading/downloading.component";
import { InputHelper } from "@services/input-helper";
import { Utils } from "@services/utils";
import { DateUtil } from "@services/date-utils";
import { environment } from "@env/environment";

const localStorageExportKey = 'export.customer.payment.requestId';
@Component({
  selector: '[export-customer-payment-confirm]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss', '../../../../../../dialogs/dialogs.scss']
})
export class ExportCustomerPaymentConfirm extends BaseDialog {

  selectedTabIndex = 0;
  keys = {
    active: 'active',
    podProblem: 'podProblem',
    exclude: 'exclude',
  }
  tabs = [
    {
      name: 'Active',
      key: this.keys.active,
      total: 0,
    },
    {
      name: 'POD Problem',
      key: this.keys.podProblem,
      total: 0,
    },
    {
      name: 'Exclude',
      key: this.keys.exclude,
      total: 0,
    },
  ]
  listOfDataActiveOriginal: any[] = [];
  listOfDataActive: any[] = [];
  listOfDataPODProblem: any[] = [];
  listOfDataExclude: any[] = [];
  excludeOrderIds: string[] = [];
  numOfOrderActiceHasInvoice = 0;
  numOfOrderActiveHasRevenueZero = 0;

  public limit = 10;
  public pageIndex: number = 1;
  public isLoading: boolean = false;
  public isDownloadingInvoice: boolean = false;
  private customerName = '';
  public pickupCities: string[] = [];
  public filterPickCity;

  @Input() clientId: string;
  @Input() fromDate: string;
  @Input() toDate: string;

  ngOnInit(): void {
    const requestId = localStorage.getItem(localStorageExportKey);
    if (requestId) {
      this.confirmYesNo('An invoice download request is in progress. Would you like to continue?', () => {
        this.onBtnExport(requestId);
      });
    }
    this.getData();
    super.ngOnInit();
  }

  get payload() {
    return {
      clientId: this.clientId,
      fromDate: this.fromDate,
      toDate: this.toDate,
    }
  }

  private getData() {
    this.isLoading = true;
    let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_FINANCES)}/reports/ic-customer-payment`;
    this.api.POST(url, this.payload).subscribe(
      (resp) => {
        this.processListItems(resp?.data?.list_data || []);
        this.isLoading = false;
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  private processListItems(orders) {
    for (let order of orders || []) {
      let customerName = order?.clients?.[0]?.name;
      if (!this.customerName && customerName) {
        this.customerName = String(customerName).replace(' ', '_');
      }
      order.customerName = customerName || 'N/A';
      order.warpId = this.showOrderWarpId(order.warpId);
      order.isAmountZero = !order?.amount || order.amount == 0;
      order.amount = InputHelper.formatMoney2(order.amount?.toString());
      if (order?.invoice?.insert?.when) {
        order.invoiceCreateWhen = DateUtil.format(order.invoice.insert.when, 'MMM D, YYYY, h:mm a');
      }
      let pickRefNums: string[] = [];
      let dropRefNums: string[] = [];
      let pickCityOrder: string[] = [];
      for (let shipment of order?.shipments || []) {
        if (shipment.lastJobId) order.lastJobId = shipment.lastJobId;
        for (let info of shipment.deliveryInfos || []) {
          const window = info?.windows?.[0];
          if (!window?.from) continue;
          if (info.type == Const.TaskType.PICKUP) {
            pickRefNums = pickRefNums.concat(info.refNums || []);
            if (!order.pickWindow || new Date(window.from).getTime() < new Date(order.pickWindow.from).getTime()) {
              order.pickWindow = window;
              order.displayPickWindow = DateUtil.displayTimeWindow(window, {
                timezone: info.addr?.metadata?.timeZoneStandard,
                format: "MMM D, YYYY HH:mm",
                formatDateOnly: "MMM D, YYYY"
              })
            }
            if (info.addr?.city) {
              pickCityOrder.push(info.addr.city);
            }
          } else if (info.type == Const.TaskType.DROPOFF) {
            dropRefNums = dropRefNums.concat(info.refNums || []);
            if (!order.dropWindow || new Date(window.to).getTime() > new Date(order.dropWindow.to).getTime()) {
              order.dropWindow = window;
              order.displayDropWindow = DateUtil.displayTimeWindow(window, {
                timezone: info.addr?.metadata?.timeZoneStandard,
                format: "MMM D, YYYY HH:mm",
                formatDateOnly: "MMM D, YYYY"
              })
            }
          }
        }
      }
      order.pickCities = Utils.uniqElementsArray(pickCityOrder.filter(x=>x));
      order.pickRefNums = Utils.uniqElementsArray(pickRefNums.filter(x=>x));
      order.dropRefNums = Utils.uniqElementsArray(dropRefNums.filter(x=>x));
    }
    
    let pickupCities: string[] = [];
    let listOfDataActive: any[] = [];
    let listOfDataPODProblem: any[] = [];
    let listOfDataExclude: any[] = [];

    for (let order of orders || []) {
      if (!order || !order?.id) continue;
      if (this.excludeOrderIds.includes(order.id)) {
        listOfDataExclude.push(order);
        continue;
      }
      if (order?.isValidGenInvoice?.isValid) {
        listOfDataActive.push(order);
        pickupCities = pickupCities.concat(order.pickCities || []);
      } else {
        listOfDataPODProblem.push(order);
      }
    }
    this.pickupCities = Utils.uniqElementsArray(pickupCities.filter(x=>x));
    this.listOfDataActive = listOfDataActive;
    this.listOfDataActiveOriginal = Utils.cloneObject(listOfDataActive);
    this.listOfDataPODProblem = listOfDataPODProblem;
    this.listOfDataExclude = listOfDataExclude;
    this.updateNoticeWarning();
    this.tabs.find(it => it.key == this.keys.active).total = listOfDataActive.length;
    this.tabs.find(it => it.key == this.keys.podProblem).total = listOfDataPODProblem.length;
    this.tabs.find(it => it.key == this.keys.exclude).total = listOfDataExclude.length;
  }

  private updateNoticeWarning() {
    let numOfOrderActiceHasInvoice = 0;
    let numOfOrderActiveHasRevenueZero = 0;
    for (let order of this.listOfDataActive) {
      if (order.invoiceFileId) numOfOrderActiceHasInvoice += 1;
      if (order.isAmountZero) numOfOrderActiveHasRevenueZero += 1;
    }
    this.numOfOrderActiceHasInvoice = numOfOrderActiceHasInvoice;
    this.numOfOrderActiveHasRevenueZero = numOfOrderActiveHasRevenueZero;
  }

  countPOD(item) {
    const shipments = item?.shipments || [];
    let count = 0;
    for (let shipment of shipments) {
      count += shipment?.pod?.total ?? 0;
    }
    return count;
  }

  countConfirmedPOD(item) {
    const shipments = item?.shipments || [];
    let countNotConfirmed = 0;
    for (let shipment of shipments) {
      countNotConfirmed += shipment?.pod?.notConfirmed ?? 0;
    }
    return this.countPOD(item) - countNotConfirmed;
  }

  isConfirmedPOD(item) {
    const shipments = item?.shipments || [];
    let confirmed = true;
    for (let shipment of shipments) {
      const pod = shipment.pod || {}
      if (pod?.notConfirmed) {
        confirmed = false;
        break;
      }
    }
    return confirmed;
  }

  getOrderLink(order) {
    return `${Const.routeAdminOrderList}?page=1&search=${order.warpId}`
  }

  getRequestPODLink(order) {
    return `${Const.routeAdminPODNeedConfirm}/${order.lastJobId}?runPODMatching=true`;
  }

  get canGenerateInvoice(): boolean {
    return !!this.listOfDataActive?.length;
  }

  loadingRef;
  onBtnExport(requestId?: string) {
    let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_FINANCES)}/reports/ic-customer-payment-export`;
    const orderIds = this.listOfDataActive.map(it => it.id);
    this.isDownloadingInvoice = true;
    this.loadingRef = DialogService.openFormDialog1(DownloadingComponent, {});
    const newRequestId = requestId || Utils.generateULID();
    localStorage.setItem(localStorageExportKey, newRequestId);
    this.api.POST(url, { ...this.payload, orderIds }, { customHeaders: { requestId: newRequestId }, responseType: 'arraybuffer', timeout: 20 * 60 * 1000 }).subscribe(
      resp => {
        let blob = new Blob([resp], { type: "application/zip" });
        let url = URL.createObjectURL(blob);
        let toDate = this.payload?.toDate ?? Date.now();
        let fileName = `${this.customerName}_Invoice_${DateUtil.format(toDate, 'YYMMDD-hhmmss')}.zip`;
        Utils.downloadFile(url, fileName);
        this.isDownloadingInvoice = false;
        this.completeDownload(newRequestId);
        localStorage.removeItem(localStorageExportKey);
        this.loadingRef?.close();
        this.onBtnReCheck();
        this.filterPickCity = null;
      }, err => {
        this.showErr(err);
        this.isDownloadingInvoice = false;
        localStorage.removeItem(localStorageExportKey);
        this.loadingRef?.close();
        this.filterPickCity = null;
      }
    );
  }

  onBtnReCheck() {
    this.filterPickCity = null;
    this.getData();
  }

  onBtnExcludeOrder(item) {
    this.excludeOrderIds = [ ...this.excludeOrderIds, item.id ];
    this.listOfDataExclude = [ ...this.listOfDataExclude, item ];
    this.tabs.find(it => it.key == this.keys.exclude).total = this.listOfDataExclude.length;
    const listOfDataActive = this.listOfDataActive.filter(it => it.id != item.id);
    this.listOfDataActive = [ ...listOfDataActive ];
    this.tabs.find(it => it.key == this.keys.active).total = listOfDataActive.length;
    const listOfDataActiveOriginal = this.listOfDataActiveOriginal.filter(it => it.id != item.id);
    this.listOfDataActiveOriginal = [ ...listOfDataActiveOriginal ];
    this.updateNoticeWarning();
  }

  onBtnUndoExcludeOrder(item) {
    this.listOfDataActive = [ ...this.listOfDataActive, item ];
    this.listOfDataActiveOriginal = [ ...this.listOfDataActiveOriginal, item ];
    this.tabs.find(it => it.key == this.keys.active).total = this.listOfDataActive.length;
    this.excludeOrderIds = this.excludeOrderIds.filter(it => it != item.id);
    const listOfDataExclude = this.listOfDataExclude.filter(it => it.id != item.id);
    this.listOfDataExclude = [ ...listOfDataExclude ];
    this.tabs.find(it => it.key == this.keys.exclude).total = listOfDataExclude.length;
    this.updateNoticeWarning();
  }

  completeDownload(requestId) {
    let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_FINANCES)}/reports/completed-export/${requestId}`;
    this.api.GET(url).subscribe(resp => {}, err => {});
  }

  onFilterPickCityChange(event) {
    if (!event) {
      this.listOfDataActive = [ ...this.listOfDataActiveOriginal ];
    } else {
      let listActive = this.listOfDataActiveOriginal.filter(it => (it.pickCities || []).includes(event));
      this.listOfDataActive = [ ...listActive ];
    }
    this.tabs.find(it => it.key == this.keys.active).total = this.listOfDataActive.length;
  }
    
}
