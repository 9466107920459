<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<ng-container *ngIf="hasData">
  <nz-collapse nzGhost class="section">
    <nz-collapse-panel #pBasic [nzHeader]="tplHeaderBasic" [nzShowArrow]="false" [nzActive]="true">
      <ng-template #tplHeaderBasic>
        <div class="section-title">
          Basic info
          <i style="margin-left: 10px;" nz-icon nzType="right" nzTheme="outline" class="ant-collapse-arrow" [nzRotate]="pBasic.nzActive ? 90 : 0"></i>
        </div>
      </ng-template>
      <div class="row">
        <div class="label">Name</div>
        <div class="value">{{data.name}}</div>
      </div>
      <div class="row">
        <div class="label">Short description</div>
        <div class="value">{{data.descShort}}</div>
      </div>
      <div class="row">
        <div class="label">Long description</div>
        <div class="value">{{data.descLong}}</div>
      </div>
      <div class="row">
        <div class="label">Time frame</div>
        <div class="value">{{txtDateTime}}</div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>

  <nz-collapse nzGhost class="section">
    <nz-collapse-panel #pRoute [nzHeader]="tplHeaderRoutes" [nzShowArrow]="false">
      <ng-template #tplHeaderRoutes>
        <div class="section-title">
          Added routes ({{routes.length}})
          <i style="margin-left: 10px;" nz-icon nzType="right" nzTheme="outline" class="ant-collapse-arrow" [nzRotate]="pRoute.nzActive ? 90 : 0"></i>
        </div>
        <button nz-button nzType="primary" (click)="addRoute()">
          <span nz-icon nzType="plus" nzTheme="outline"></span>
          Add Route
        </button>
      </ng-template>
      <div *ngFor="let route of routes" class="row">
        <a [routerLink]="[routeAdminDispatchList, route.id]" target="_blank" style="width: 120px; margin-right: 50px;">{{route.code}}</a>
        <a [routerLink]="[routeAdminCarrierSales, route.bidId]" target="_blank">View bid</a>
      </div>
    </nz-collapse-panel>
  </nz-collapse>

  <nz-collapse nzGhost class="section">
    <nz-collapse-panel #pCarrier [nzHeader]="tplHeaderCarriers" [nzShowArrow]="false">
      <ng-template #tplHeaderCarriers>
        <div class="section-title">
          Added carriers ({{carriers.length}})
          <i style="margin-left: 10px;" nz-icon nzType="right" nzTheme="outline" class="ant-collapse-arrow" [nzRotate]="pCarrier.nzActive ? 90 : 0"></i>
        </div>

        <button nz-button nzType="primary" (click)="addCarrier()">
          <span nz-icon nzType="plus" nzTheme="outline"></span>
          Add Carriers
        </button>
      </ng-template>
      <div *ngFor="let carrier of carriers" class="row">
        <a [routerLink]="[routeAdminCarrierList, carrier.id]" target="_blank">{{carrier.name}}</a>
      </div>
    </nz-collapse-panel>
  </nz-collapse>

  <bid-session-outreach [bidSessionId]="bidSessionId"></bid-session-outreach>
  <div class="responsed-title">
    Responsed Carriers  
  </div>
  <div class="carrier-selection">
    <bid-session-carriers [bidSessionId]="bidSessionId"></bid-session-carriers>
  </div>
</ng-container>