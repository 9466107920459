<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Update Equipments</div>
    <div *ngIf="!onProgress">
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div class="bottom20">
  <div vehicle-selector [(value)]="newVehicleType" [withOptions]="true"></div>
  <div class="warning-message" *ngIf="shouldShowWarning">
    The <b>{{ newVehicleType?.name }}</b> vehicle you selected has a maximum capacity of <b>{{ formatNumber(newVehicleType?.capacity?.weight?.value || 0) }} lbs and {{ formatNumber(newVehicleType?.palletCapacity || 0) }} pallets</b>. 
    However, this load contains <b>{{ formatNumber(totalWeight) }} lbs and {{ formatNumber(totalPallet) }} pallets</b>. 
    Please confirm your action carefully.
  </div>
  <ng-container *ngIf="canUpdate">
    <div class="top15 medium red">Carrier bid may be no longer valid !</div>
    <div class="top5">
      <label nz-checkbox [(ngModel)]="isUnassignCarrier">Unassign carrier and reopen bid</label>
    </div>
    <div *ngIf="isUnassignCarrier" class="top10 red">
      <span class="medium">{{ carrierName }}</span> will be unassigned <br>
      Reason: {{ reason }}
    </div>
  </ng-container>
</div>
<div form-footer class="no-border"
  [canClickOK]="canUpdate"
  [canClickCancel]="!onProgress"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>


