import { Component, Input, EventEmitter, Output } from "@angular/core";
import { FormDataShipmentEntryDraftData } from "@wearewarp/types-server-admin/form-data/shipment-entry";
@Component({
  selector: "[shipment-entry-info-summary]",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss"],
})
export class ShipmentEntryInfoSummary {
  @Input() modelDraft: FormDataShipmentEntryDraftData;
  @Input("isSingle") isSingle: boolean;
  @Output() onSortedTaskIdsChanged = new EventEmitter();

  updateSortedTaskIds(data) {
    this.onSortedTaskIdsChanged.emit(data);
  }
}
