import { Component, Input, OnInit } from "@angular/core";
import { DateUtil } from "@services/date-utils";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { DeliveryInfo } from "@wearewarp/types/data-model";
import { FormDataShipmentLocation } from "@wearewarp/types-server-admin/form-data/shipment-entry";

@Component({
  selector: "delivery-info",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss"],
})
export class DeliveryInfoComponent implements OnInit {
  @Input("model") infos: Array<FormDataShipmentLocation> = [];
  constructor() {}

  ngOnInit(): void {}
  get hasInfo() {
    return this.infos != null && this.infos?.length != 0;
  }
  getLocationName(info: FormDataShipmentLocation) {
    return info?.locationName;
  }

  public getLocationAddress(info: FormDataShipmentLocation): string {
    return MasterData.getAddressText(info?.addr);
  }
  getTitle(info: FormDataShipmentLocation) {
    if (info.type == "PICKUP") return "Pick Up";
    else if (info.type == "DROPOFF") return "Delivery";
    else if (info.type == "TRANSIT") return "Transit";
    else if (info.type == "RETURN") return "Return";
    else if (info.type == "RETURN_DEPOT") return "Return Depot";
  }

  getRefNum(info: FormDataShipmentLocation) {
    return info?.refNums?.join("; ");
  }

  getAccessCode(info: FormDataShipmentLocation) {
    return info?.accessCode;
  }

  hasTimePeriods(info: FormDataShipmentLocation): boolean {
    return info?.windows != null && info?.windows?.length != 0;
  }
  getTimePeriods(info: FormDataShipmentLocation) {
    let windows = info?.windows
      ?.map((window) =>
        DateUtil.displayTimeWindow(window, {
          timezone: info?.addr?.metadata?.timeZoneStandard,
          format: "MM/DD/YYYY h:mm A",
        })
      );
    if (Utils.isArrayNotEmpty(windows)) return windows.join("\n");
    else if (info.requiresAppointment) return 'Requires Appointment.';
    else return '';
  }
  hasServiceOptions(info: FormDataShipmentLocation): boolean {
    return !!(info?.serviceOptions?.length);
  }
  getServiceOptions(info: FormDataShipmentLocation) {
    const serviceOptions = (info?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt))
    return serviceOptions?.join(", ");
  }
}
