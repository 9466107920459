import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { FraudDetectionHelper } from "./helper";


@Component({
    selector: '[fraud-detection]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss', '../../../app.scss', '../../../dialogs/dialogs.scss']
})

export class FraudDetection extends BaseComponent{
  constructor() {
      super();
  }
  @Input() carrierId: any;
  @Input() refresh: boolean = false;
  isLoading: boolean = false;
  fraudDetectionData = null;
  warpFraud: any = {
    stolenAsset: {
      count: 0,
      logs: []
    }, 
    scammer: {
      count: 0,
      logs: []
    },
    routingComplianceViolation: {
      count: 0,
      logs: []
    }
  };
  identityAlertsGroup: { [key: string]: number } = {};
  objectEntries = Object.entries;
  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
  }

  async getData() {
    if (!this.carrierId || this.carrierId == 'add') {
      return;
    }
    this.isLoading = true;
    let url = `v2/${Const.APIURI_CARRIERS}/${this?.carrierId}/highway_fraud_alert`;
    if(this.refresh){
      url = url + `?refresh=true`
    }
    const resp = await this.api.GET(url).toPromise();
    if (resp) {
      this.fraudDetectionData = resp?.data?.highway;
      this.warpFraud = resp?.data?.warp;
      const identityAlerts = this.fraudDetectionData?.identity_alerts ?? [];
      this.identityAlertsGroup = FraudDetectionHelper.setIdentityAlerts(identityAlerts);
      this.onProcessHighwayData();
      this.onProcessWarpData();
    }
    this.isLoading = false;
  }
  highwayData = [];
  onProcessHighwayData() {
    this.highwayData = [];
    if (this.fraudDetectionData?.user_is_blocked) {
      this.highwayData.push({
        color: this.getTagColor('user_is_blocked'),
        description: this.getTagDescription('user_is_blocked')
      });
    }
    if (this.objectEntries(this.identityAlertsGroup ?? {}).length > 0) {
      for (const entry of this.objectEntries(this.identityAlertsGroup)) {
        this.highwayData.push({
          color: this.getTagColor(entry[0]),
          description: `${this.getTagDescription(entry[0])}(${entry[1]})`,
        })
      }
    }
    if (this.fraudDetectionData?.email_search_result_category) {
      this.highwayData.push({
        color: this.getTagColor(this.fraudDetectionData?.email_search_result_category),
        description: this.getTagDescription(this.fraudDetectionData?.email_search_result_category)
      });
    }
  }
  warpData = [];
  onProcessWarpData() {
    this.warpData = [];
    if (this.objectEntries(this.warpFraud ?? {}).length > 0) {
      for (const entry of this.objectEntries(this.warpFraud)) {
        if ((entry[1] as { count: number })?.count > 0) {
          this.warpData.push({
            color: this.getTagColor(entry[0]),
            description: `${this.getTagDescription(entry[0])}(${(entry[1] as { count: number }).count})`,
            logs: (entry[1] as { logs: any })?.logs ?? []
          })
        }
      }
    }
  }
  
  getTagColor(key: string) {
    return FraudDetectionHelper.getTagColor(key);
  }
  getTagDescription(key: string) {
    return FraudDetectionHelper.getTagDescription(key);
  }

  popoverData: { data: string[] } = { data: [] };
  onPopoverVisibleChange(visible: boolean, item: any): void {
    if (visible) {
      // Cập nhật dữ liệu cần truyền vào ng-template
      this.popoverData = (item.logs ?? []).map(it => ({
        ...it,
        hyperLinkUrl: `${Const.routeAdminDispatchList}/${it.jobInfo.id}`,
      }));
      
    }
  }
}
