<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<div *ngIf="!isLoading && !isError">
  <div class="top15 bottom15 bid-summany-selection">
    <div class="route-info-selection">
      <div class="route-info">
        <div class="route-link">
          <span style="margin-right: 6px;">Route</span>
          <a [routerLink]="[jobHyperLink.hyperLinkUrl]" target="_blank">{{model?.job?.code}}</a>
          <span *ngIf="displayInfo.isMarketplace" class="ghost-load-label">Marketplace</span>
          <span *ngIf="!displayInfo.isMarketplace && displayInfo.isGhostLoad" class="ghost-load-label">Ghost Load</span>
        </div>
        <div class="top10">
          <span class="text-label">Customer</span>
          <span class="left5">{{ displayInfo.clientName }}</span>
          <span *ngIf="displayInfo.numOfClient && displayInfo.numOfClient > 1" class="pointer"
            [nzTooltipTitle]="tplMoreClient" nzTooltipPlacement="right" nz-tooltip nz-typography nzEllipsis>
            (+{{displayInfo.numOfClient - 1}})
          </span>
          <ng-template #tplMoreClient>
            <div *ngFor="let name of displayInfo.arrClientName">{{name}}</div>
          </ng-template>
        </div>
      </div>
      <div job-stop-timeline [stops]="model?.job?.stops" [mileage]="displayInfo.mileage"></div>
      <div>
        <div *ngIf="displayInfo.isShowRemainingTimePickup" class="bottom10 flex" nz-tooltip
          nzTooltipTitle="Time To Pickup" style="width: 160px; justify-content: flex-end;">
          <img src="/assets/img/schedule.svg" style="margin-right: 4px;" />
          <div class="bold flex" style="white-space: nowrap;">
            <div *ngIf="remainingTime.isNegative">-</div>
            <div *ngIf="remainingTime.days">{{ remainingTime.days }}d : </div>
            <div>{{ remainingTime.hours }}h : </div>
            <div>{{ remainingTime.minutes }}m : </div>
            <div>{{ remainingTime.seconds }}s</div>
          </div>
        </div>
        <div class="group-section">
          <div>
            <span style="color: red; font-weight: bold; padding-right: 5px;">Legacy Screen</span>
          </div>
          <div *ngIf="displayInfo.carrierSalesRep" class="flex" style="justify-content: flex-end;">
            <div style="white-space: nowrap;">
              <img src="/assets/img/account_circle_2.svg" style="margin-right: 4px;" />
              <span nz-tooltip nzTooltipTitle="Carrier Sales Rep">{{ displayInfo.carrierSalesRep }}</span>
              <span (click)="onBtnEditCarrierSalesRep()" nz-icon nzTheme="outline" nzType="edit"
                style="color: #1802d0; cursor: pointer; margin-left: 4px;" nz-tooltip
                nzTooltipTitle="Update Carrier Sales Rep"></span>
            </div>
          </div>
          <div *ngIf="!displayInfo.carrierSalesRep" style="text-align: end;">
            <i nz-icon nzType="user-add" nzTheme="outline" nz-tooltip nzTooltipTitle="Add Carrier Sales Rep"
              (click)="onBtnEditCarrierSalesRep()" class="pointer"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="more-info-selection">
      <div class="more-info-item width-smaller" style="position: relative;">
        <div class="text-header">Equipment</div>
        <div class="line-item equipment-info-item">
          <div class="label">Load</div>
          <div class="value">
            <ng-container *ngIf="displayInfo.vehicles?.length">
              <div *ngFor="let vehicle of displayInfo.vehicles">{{ vehicle }}</div>
            </ng-container>
            <ng-container *ngIf="!displayInfo.vehicles?.length">
              <div>N/A</div>
            </ng-container>
          </div>
        </div>
        <div class="line-item equipment-info-item">
          <div class="label">Items</div>
          <div class="value">
            {{model.job?.totalShipmentsItems?.weight}}
            <ng-container *ngIf="model?.job?.totalShipmentsItems?.units?.length">,
              {{model.job.totalShipmentsItems.units}}</ng-container>
            <!-- <div *ngIf="displayInfo.firstCommodity">
              <span>{{ displayInfo.firstCommodity }}</span>
              <ng-container *ngIf="model?.job?.totalShipmentsItems?.commodity?.length > 1">
                <span [nzTooltipTitle]="displayInfo.allCommodities" nz-tooltip
                  style="margin-left: 5px;">(+{{model.job.totalShipmentsItems.commodity.length - 1}})</span>
              </ng-container>
            </div> -->
          </div>
        </div>
        <div *ngIf="displayInfo.tempRange" class="line-item equipment-info-item">
          <div class="label">Temperature</div>
          <div class="value">{{ displayInfo.tempRange }}</div>
        </div>
        <div *ngIf="displayInfo.numOfServiceOptions" class="line-item equipment-info-item">
          <div class="label">Service Options</div>
          <div class="value">
            <span>{{ displayInfo.firstServiceOptions }}</span>
            <ng-container *ngIf="displayInfo.numOfServiceOptions > 1">
              <span [nzTooltipTitle]="displayInfo.allServiceOptions" nz-tooltip
                style="margin-left: 5px;">(+{{displayInfo.numOfServiceOptions - 1}})</span>
            </ng-container>
          </div>
        </div>
        <div class="line-item equipment-info-item">
          <div class="label">
            Optional <i nz-icon nzType="info-circle" nzTheme="outline" nzTooltipPlacement="bottom"
              nzTooltipOverlayClassName="tooltip-info" style="margin-left: 10px;" class="clickable" nz-tooltip
              [nzTooltipTitle]="'The other carriers who have this equipment can also bid'"></i>
          </div>
          <div class="value">
            <span>{{ displayInfo.firstOptionalEquipments || 'N/A'}}</span>
            <ng-container *ngIf="displayInfo.numOfOptionalEquipments > 1">
              <span [nzTooltipTitle]="displayInfo.allOptionalEquipments" nz-tooltip
                style="margin-left: 5px;">(+{{displayInfo.numOfOptionalEquipments - 1}})</span>
            </ng-container>
            <a style="margin-left: 5px;" (click)="onBtnEditOptionalEquipments()">Update</a>
          </div>
        </div>
        <div *ngIf="onHold?.length" class="warning-on-hold-shipment">
          <div style="margin-right: 3px;">On-hold shipments:</div>
          <div *ngFor="let s of onHold" class="shipment-on-hold">{{ showShipmentWarpId(s) }}</div>
        </div>
      </div>
      <div class="more-info-item width-smaller">
        <ng-container *ngIf="!displayInfo.isGhostLoad">
          <div class="text-header">Rate</div>
          <div nz-row class="line-item">
            <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">Revenue</div>
            <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">
              <span nz-tooltip nzTooltipTitle="Add up grand total for all shipments on the route"
                nzTooltipPlacement="right">
                {{ displayInfo.totalShipmentCost || 'N/A'}}
              </span>
            </div>
          </div>
          <div nz-row class="line-item">
            <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">Rate / mile</div>
            <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">{{ displayInfo.totalShipmentCostPerMile || 'N/A' }}
            </div>
          </div>
        </ng-container>
        <div class="text-header top10">Market Rate</div>
        <!-- <div nz-row class="line-item">
          <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">Load to truck ratio</div>
          <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">xxx</div>
        </div> -->
        <div nz-row class="line-item">
          <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">DAT Rate</div>
          <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">
            <div *ngIf="!model?.job?.datRate?.total_charge?.mediumTotal && !model?.job?.datRate?.cost_update?.cost">-
            </div>
            <ng-container *ngIf="model?.job?.datRate?.total_charge?.mediumTotal">
              <ng-container *ngIf="!isLoadingDatRate">
                <span>{{getMoneyValue(model?.job?.datRate?.cost_update?.cost ||
                  model?.job?.datRate?.total_charge?.mediumTotal)}}</span>
                <span (click)="onBtnOnUpdateTargetRate()" nz-icon nzTheme="outline" nzType="edit"
                  style="color: #1802d0; cursor: pointer; margin-left: 7px;"></span>
                <span (click)="onReUpdateDatRate($event)" nz-icon nzTheme="outline" nzType="reload"
                  style="color: #1802d0; cursor: pointer; margin-left: 7px;"></span>
              </ng-container>
              <ng-container *ngIf="isLoadingDatRate">
                <span nz-icon nzTheme="outline" nzType="loading"
                  style="color: #1802d0; cursor: pointer; margin-left: 7px;"></span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="model?.job?.datRate?.cost_update?.cost > 0">
              <div style="color: #adadad; margin-top: 4px;" nzTooltipTitle="DAT Rate Original" nz-tooltip
                nzTooltipPlacement="left">
                {{getMoneyValue(model?.job?.datRate?.total_charge?.mediumTotal)}}
              </div>
            </ng-container>
          </div>
        </div>
        <div nz-row class="line-item">
          <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">Historic Rate</div>
          <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">
            <ng-container *ngIf="!isLoadingAvg">
              <ng-container *ngIf="!avgCostOfRoutes">N/A</ng-container>
              <ng-container *ngIf="avgCostOfRoutes">
                {{ getMoneyValue(avgCostOfRoutes) }}
              </ng-container>
              <span (click)="onReUpdateAvgPastRate($event)" nz-icon nzTheme="outline" nzType="reload" class="reload"></span>
            </ng-container>

            <ng-container *ngIf="isLoadingAvg">
              <span nz-icon nzTheme="outline" nzType="loading" class="reload"></span>
            </ng-container>

            <a (click)="onBtnPreviousRoute()" class="btn-detail">Details</a>
          </div>
        </div>
      </div>
      <div class="more-info-item">
        <div class="text-header">Same Lane Loads <span class="left15" nz-tooltip nzTooltipTitle="Same lane loads are loads that share the same Pickup - Dropoff zip code and equipment."><i nz-icon nzType="exclamation-circle" nzTheme="outline"></i></span></div>
        <calendar-same-lane [bidId]="carrierBidId"></calendar-same-lane>
      </div>
      <div class="more-info-item">
        <div class="flex-space-between">
          <div class="text-header">Settings</div>
          <div>
            <a (click)="onBtnEditSettings()">
              <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 4px;"></i>Edit
            </a>
          </div>
        </div>
        <div class="line-item">
          <div class="label">Bid Mode</div>
          <div >{{ displayInfo.bidModeType }}</div>
        </div>
        <div class="line-item">
          <div class="label">WARP Offer</div>
          <div><b>{{ displayInfo.warpOffer || '-' }}</b></div>
        </div>
        <div class="line-item">
          <div class="label">Settings</div>
          <div>
            <div class="setting-item">
              <i class="icon-checkbox"
                [ngClass]="{'checkbox-setting-active': model?.isAllowPlaceBid , 'checkbox-setting-deactive': !model?.isAllowPlaceBid}"></i>
              <span>Allow the carrier place bid</span>
            </div>
            <div class="setting-item">
              <i class="icon-checkbox"
                [ngClass]="{'checkbox-setting-active': model?.isShowBasePrice , 'checkbox-setting-deactive': !model?.isShowBasePrice}"></i>
              <span>Show base price for carrier</span>
            </div>
            <div class="setting-item">
              <i class="icon-checkbox"
                [ngClass]="{'checkbox-setting-active': model?.isShowOnBidBoard , 'checkbox-setting-deactive': !model?.isShowOnBidBoard}"></i>
              <span>Show on bid board</span>
            </div>
            <div class="setting-item">
              <i class="icon-checkbox"
                [ngClass]="{'checkbox-setting-active': model?.isRequireCarrierAcceptLoadTender , 'checkbox-setting-deactive': !model?.isRequireCarrierAcceptLoadTender}"></i>
              <span style="line-height: 20px;">Carrier needs to accept load tender after route assignment</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="carrier-selection" *ngIf="!isLoading && !isError">

  <!-- Mode & Settings -->
  <div *ngIf="acceptedPrice" class="box-with-title" style="padding: 20px;">
    <div nz-row [nzGutter]="12">
      <div nz-col nzSpan="8">
        <div>Accepted Price:</div>
        <h4 nz-typography>{{formatMoney(acceptedPrice)}}</h4>
        <span><nz-tag *ngIf="acceptedCarrier?.isEarliestBid" [nzColor]="'blue'">Earliest Bid</nz-tag></span>
        <span><nz-tag *ngIf="acceptedCarrier?.isLowestPrice" [nzColor]="'blue'">Lowest Price</nz-tag></span>
        <span><nz-tag *ngIf="acceptedCarrier?.isBestExperience" [nzColor]="'blue'">Best Experience</nz-tag></span>
        <div style="margin-top: 10px;">{{acceptedCarrier?.basicInfo?.name}}</div>
      </div>
      <div nz-col nzSpan="8">
        <ng-container *ngIf="compareWithLowestPrice" [ngTemplateOutlet]="statisticTemplate"
          [ngTemplateOutletContext]="{data: compareWithLowestPrice}">
        </ng-container>

        <!-- bug: wrong market price => wrong compare (FIX ME) -->
        <!-- <ng-container
          *ngIf="compareWithMarketPrice"
          [ngTemplateOutlet]="statisticTemplate"
          [ngTemplateOutletContext]="{data: compareWithMarketPrice}">
        </ng-container> -->

        <ng-container *ngIf="compareWithOfferPrice" [ngTemplateOutlet]="statisticTemplate"
          [ngTemplateOutletContext]="{data: compareWithOfferPrice}">
        </ng-container>

        <ng-template #statisticTemplate let-data='data'>
          <div style="margin-bottom: 10px;">
            <nz-tag [nzColor]="getCompareBidPriceColor(data?.status)">
              <span nz-icon [nzType]="getCompareBidPriceIcon(data?.status)" nzTheme="outline"></span>
              <span style="margin-right: 5px; margin-left: 2px;" *ngIf="data?.percent">{{data?.percent}}</span>
              <span>{{data?.text}}</span>
            </nz-tag>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <!-- Carriers & DAT -->
  <div class="box-with-title" style="margin-top: 30px;">
    <div class="box-title flex">
      <div>Carriers <span *ngIf="isRequired('carrierIds')" class="label-mark-required"></span></div>
      <div class="carrier-tab" style="margin-left: 50px;">
        <nz-tabset [(nzSelectedIndex)]="tabSelected" [nzType]="'line'" [nzSize]="'small'"
          (nzSelectedIndexChange)="onTabSelectedIndexChange()">
          <nz-tab nzTitle="Carrier Matches"></nz-tab>
          <nz-tab nzTitle="Truck Search"></nz-tab>
          <nz-tab nzTitle="Outreach Performance"></nz-tab>
        </nz-tabset>
      </div>
    </div>
    <div *ngIf="isCarrierMatchesTab" class="box-content top20">
      <div class="group-box-action">
        <div class="box-action" *ngIf="!isAdminReadOnlyRole">
          <button nz-button (click)="openAddCarrierModal()" nzType="default">
            <span nz-icon nzType="plus" nzTheme="outline"></span>
            Add carrier
          </button>
          <button nz-button (click)="openAddCarrierPoolModal()" nzType="default">
            <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
            Add Pools
          </button>
          <button nz-button (click)="onAddCarriersByCoverage()" nzType="default">
            <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
            Add Carriers
          </button>
          <button nz-button (click)="onClickPoolCoverage()" nzType="default">
            <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
            Add Pools By Coverage Area
          </button>

          <button nz-button (click)="confirmRemoveCarrier()" nzType="default" nzDanger
            [disabled]="isNoChecked() || shouldDisableRemoveButton()">
            <span nz-icon nzType="delete" nzTheme="outline"></span>
            Remove selected
          </button>
        </div>
        <div class="box-action" *ngIf="!isAdminReadOnlyRole">
          <!-- <dat-post-button-v2 *ngIf="carrierBidId" [carrierBidId]="carrierBidId" [jobId]="data.jobId"></dat-post-button-v2> -->
          <button *ngIf="shouldShowBtnPost" nz-button (click)="onBtnThirdPartyPost()" style="margin-left: 10px;"
            nzType="primary">
            <i nz-icon nzType="send" nzTheme="outline"></i>
            {{thirdPartyPostExist ? "View third-party post" : "Post to third-party (DAT, Truckstop ...)"}}
          </button>
          <button nz-button (click)="onBtnSendMessage()" style="margin-left: 10px;" nzType="primary"
            [disabled]="isNoChecked()">
            <i nz-icon nzType="send" nzTheme="outline"></i>
            Send email & sms to carriers
          </button>
        </div>
      </div>
      <nz-table #nzTable nzBordered="true" [nzData]="listData" [nzLoading]="isLoading" [nzHideOnSinglePage]="true"
        [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData" [nzSize]="'default'">
        <ng-template #tplNoData>
          <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
        </ng-template>
        <ng-template #tplLoading>
          <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        </ng-template>
        <thead>
          <tr>
            <th *ngIf="!isAdminReadOnlyRole" nzWidth="50px" [nzSelections]="listOfSelection"
              [nzChecked]="isCheckedAll()" [nzIndeterminate]="isIndeterminate()"
              (nzCheckedChange)="onCheckedChange($event, 'not_sent')"
              nz-tooltip="By default, only carriers who have not been sent an invitation will be selected">
            </th>
            <th nzCustomFilter nzLeft nzColumnKey="name">
              <div class="flex">
                Carrier Name
              </div>
              <input (input)="onSearch($event.target.value, 'name')" type="text" class="input-search" nz-input
                placeholder="Search by Carrier Name" nzSize="default" />
            </th>
            <th nzCustomFilter nzLeft nzColumnKey="poolName">
              Dedicated Pool
              <input (input)="onSearch($event.target.value, 'poolName')" type="text" class="input-search" nz-input
                placeholder="Search by Carrier Pool" nzSize="default" />
            </th>
            <th nzLeft nzColumnKey="contacts">
              Carrier Contact
              <input (input)="onSearch($event.target.value, 'contacts')" type="text" class="input-search" nz-input
                placeholder="Search by Carrier Contact" nzSize="default" />
            </th>
            <th nzColumnKey="completedRoute">Completed Route</th>
            <th nzColumnKey="status">Type</th>
            <th nzColumnKey="answer">Mail & SMS Invitation</th>
            <!-- <th nzColumnKey="answer">Answer</th> -->
            <th nzColumnKey="price" [nzSortOrder]="sortPriceDirection" [nzSortDirections]="['ascend', 'descend', null]"
              [nzSortFn]='sortPriceCarrier' (nzSortOrderChange)="changeSort($event)" [nzWidth]="'200px'">Bidding</th>
            <th nzColumnKey="action" *ngIf="data.jobId">Action</th>
            <th nzRight nzColumnKey="note">Note</th>
          </tr>
        </thead>
        <tbody>

          <ng-container *ngFor="let carrier of nzTable.data">
            <ng-container *ngFor="let item of mapOfExpandedData[carrier.key]">
              <tr *ngIf="(item.parent && item.parent.expand) || !item.parent">

                <td nzLeft *ngIf="!isAdminReadOnlyRole">
                  <label *ngIf="!isGroupByPool(item)" nz-checkbox [(ngModel)]="item['checked']"
                    (nzCheckedChange)="onItemCheckedChange($event,item)">
                  </label>
                </td>
                <!-- Carrier Name -->
                <td nzLeft [nzIndentSize]="item.level*0" [nzShowExpand]="false" [(nzExpand)]="item.expand"
                  (nzExpandChange)="onExpandChange(mapOfExpandedData[carrier.key], item, $event)"
                  [style]="item.level && item.level > 0 ? 'background-color:rgb(218, 218, 215);': ''">
                  <div *ngIf="isGroupByPool(item)" (click)="toggleChildren(carrier, item)" style="cursor: pointer;">
                    <div style="font-weight: 500;">
                      [Dedicated Pool]
                      <span>{{item.poolName}} ({{item?.children?.length || 0}})</span>
                      <i nz-icon [nzType]="item.expand ? 'up' : 'down'" nzTheme="outline"
                        *ngIf="(item.children || []).length>0" style="margin-left: 10px"></i>
                    </div>
                  </div>
                  <div style="margin-bottom: 10px;">
                    <nz-tag *ngIf="item.isEarliestBid" nzColor="blue">Earliest Bid</nz-tag>
                    <nz-tag *ngIf="item.isLowestPrice" nzColor="blue">Lowest Price</nz-tag>
                    <nz-tag *ngIf="item?.isBestExperience" [nzColor]="'blue'">Best Experience</nz-tag>
                  </div>
                  <a *ngIf="!isGroupByPool(item)" class="inherit" target="_blank"
                    [routerLink]="[routeAdminCarrierList, item.carrierId]">{{item.name}}</a>

                  <i *ngIf="whyAdded(item)" nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip
                    [nzTooltipTitle]="whyAdded(item)" style="margin-left: 5px; color: #333; cursor: pointer;"></i>

                  <a *ngIf="item.goHighWayLink" style="margin-left: 15px;" [href]="item.goHighWayLink" target="_blank"
                    nz-tooltip nzTooltipTitle="Go to GoHighWay"><span nz-icon nzType="login"
                      nzTheme="outline"></span></a>



                </td>
                <!-- Dedicated Pool -->
                <td nzLeft>
                  <ng-container *ngIf="item.isDedicatedPool">
                    <nz-ribbon nzText="Dedicated">
                      <a [routerLink]="[routeAdminPools, item.poolId]" target="_blank">{{item.poolName}}</a> <br />
                      Base Rate: <b>{{ item?.basePrice ? $asMoney(item.basePrice) : '-' }}</b>
                    </nz-ribbon>
                  </ng-container>
                  <ng-container *ngIf="!item.isDedicatedPool">-</ng-container>
                </td>
                <!-- Carrier Contact -->
                <td nzLeft>
                  <ng-container *ngIf="!isGroupByPool(item)">
                    {{item.contacts?.[0]?.contactName}}<br />
                    <p class="small-text">
                      <contact-info owner="{{item.contacts?.[0]?.contactPhone}}" type="phone"
                        [scopes]="['carrier_bid']"></contact-info>
                      <contact-info owner="{{item.contacts?.[0]?.contactEmail}}" type="email"
                        [scopes]="['carrier_bid']"></contact-info>
                    </p>
                  </ng-container>
                </td>
                <!-- Completed Route -->
                <td>
                  <ng-container *ngIf="!isGroupByPool(item)">
                    <div *ngIf="item.countOfJob == 0">
                      -
                    </div>
                    <div *ngIf="item.countOfJob > 0">
                      <div style="white-space: nowrap;">
                        Completed:
                        <span nz-tooltip nzTooltipTitle="Count of Routes completed">{{ item.countOfJob }}</span> /
                        <span nz-tooltip nzTooltipTitle="Count of Routes completed for the specific lane">{{
                          item.countLane
                          }}</span>
                      </div>
                      <div display-rate-info style="margin-bottom: 4px;" [numOfLike]="item.countOfJobRateLike"
                        [numOfDislike]="item.countOfJobRateDislike">
                      </div>
                      <p class="small-text">
                        <span>Last date: {{ item.lastDate ? $formatDate(item.lastDate) : '-' }}</span>
                        <ng-container *ngIf="item.countLane">
                          <br />
                          <span>Last cost: {{ item.lastCost ? $asMoney(item.lastCost) : '-' }}</span>
                        </ng-container>
                      </p>
                    </div>
                  </ng-container>
                </td>
                <!-- Type -->
                <td>
                  <ng-container *ngIf="!isGroupByPool(item)">
                    <ng-container *ngIf="item.status != 1">
                      <nz-tag nzColor="warning">{{$asCarrierStatusText(item.status)}}</nz-tag>
                    </ng-container>
                    <ng-container *ngIf="item.status == 1">
                      <nz-tag nzColor="success">Active</nz-tag>
                    </ng-container>
                  </ng-container>
                </td>
                <!-- Last Sent -->
                <td>
                  <ng-container *ngIf="!isGroupByPool(item)">
                    <ng-container *ngIf="item.lastSent">
                      {{$formatDate(item.lastSent?.when)}} <br />
                      By {{item.sender?.name}}<br />
                      <span class="status" [ngClass]="$asSentStatusColor(item.lastSent.status)">{{
                        $asSentStatusText(item.lastSent.status) }}</span>
                      <span *ngIf="$shouldShowHistory(item.lastSent)" (click)="onBtnHistoryStatus(item)" nz-icon
                        nzType="history" nzTheme="outline" style="cursor: pointer;"></span>
                    </ng-container>
                    <ng-container *ngIf="!item.lastSent && item.lastQueued">
                      <span nz-tooltip nzTooltipTitle="This message is queued and will automatically be sent at: {{$displayEstTime(item.lastQueued?.when)}} EST">
                        Queued: <br />
                        {{$formatDate(item.lastQueued?.when)}}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="!item.lastQueued && !item.lastSent">
                      Never
                    </ng-container>
                  </ng-container>
                </td>
                <!-- Answer -->
                <!-- <td>
                  <ng-template>
                    <nz-tag nzColor=" #FFE6E1">Fail to send</nz-tag>
                    <nz-tag nzColor="#FFF4C6">Waiting for a response</nz-tag>
                    <nz-tag nzColor="#E2F7E1">Responsed</nz-tag>
                    <nz-tag nzColor="default">Pending...</nz-tag>
                  </ng-template>
                  <ng-container *ngIf="!isGroupByPool(item)">
                    <div class="flex flex-space-between">
                      <div>
                        <ng-container *ngIf="item.state == 0 || item.state == undefined">
                          <ng-container *ngIf="item.lastSent">
                            <nz-tag nzColor="#FFF4C6">Waiting for a response</nz-tag>
                          </ng-container>
                          <ng-container *ngIf="!item.lastSent">
                            <nz-tag nzColor="default">Not Sent</nz-tag>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="item.state == 1">
                          <nz-tag nzColor="error">Refused</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="item.state == 2">
                          <nz-tag nzColor="success">Accepted</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="item.state == 3">
                          <nz-tag nzColor="success">Placed Bid</nz-tag>
                        </ng-container>
                        <p class="small-text" style="margin-top: 5px;" *ngIf="item.when">when:
                          {{$formatDate(item.when)}}</p>
                      </div>
                      <div *ngIf="!isAdminReadOnlyRole">
                        <button nz-button (click)="onBtnEditItem(item)" nzSize="small">
                          <i nz-icon nzType="edit" nzTheme="outline"></i>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </td> -->
                <!-- Bidding -->
                <td nzAlign="left">
                  <!-- <ng-container *ngIf="!isGroupByPool(item)">
                    {{ item.price? $asMoney(item.price): '-'}}
                  </ng-container> -->
                  <ng-container *ngIf="hasCounterBidHistory(item)  && !isCarrierRefusedBid(item)">
                    <div *ngFor="let counter of item.bidAnswers" style="text-align: left;">
                      <span nz-tooltip [nzTooltipTitle]="getTooltipForBidCounterAction(counter)" nzTooltipPlacement="rightTop">{{counter?.price ? $asMoney(counter.price): '-'}} by {{getAuthorOfBidCounter(counter)}}</span>
                    </div>
                    <div *ngIf="!isAdminAssignCarrier() && !isCarrierAcceptedPrice(item) && data?.isAllowPlaceBid" class="flex gap5 top5">
                      <button nz-button nzType="default" [disabled]="!checkCanCounter(item)" (click)="onOpenCounterDialog(item)" nzSize="small">Counter</button>
                      <button nz-button nzType="default" [disabled]="!checkCanHelpCarrierCounter(item)" nz-tooltip nzTooltipTitle="Enter Carrier's answer" nzSize="small" (click)="onBtnCounterBidForCarrier(item)"><img src="/assets/svg/question_answer.svg" alt=""></button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!(hasCounterBidHistory(item)) && !isCarrierAcceptedPrice(item) && !isCarrierRefusedBid(item) && !isAdminAssignCarrier()">
                    <div class="flex flex-space-between">
                      <ng-container *ngIf="item.lastSent">
                        <nz-tag nzColor="warning">Waiting for a response</nz-tag>
                      </ng-container>
                      <button nz-button  nzType="default" nzSize="small" (click)="onBtnEditItem(item)" nz-tooltip nzTooltipTitle="Enter Carrier's answer">
                        <img src="/assets/svg/question_answer.svg" alt="" style="margin-right: 3px;">
                        <span *ngIf="!item.lastSent">Enter Carrier's answer</span>
                      </button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isCarrierRefusedBid(item)">
                    <nz-tag nzColor="default" nz-tooltip [nzTooltipTitle]="formatDate(item?.update?.when)">Refused</nz-tag>
                  </ng-container>
                  <ng-container *ngIf="isCarrierAcceptedPrice(item)">
                    <nz-tag nzColor="success" nz-tooltip [nzTooltipTitle]="getTooltipForBidCounterAction(getLastAnswer(item))">
                      <span nz-icon nzType="check" nzTheme="outline"></span>
                      Accepted {{$asMoney(getCarrierAcceptedPrice(item))}}
                    </nz-tag>
                  </ng-container>
                </td>
                <!-- Action -->
                <td class="border-right-none" *ngIf="data.jobId">
                  <div class="flex assign-item" *ngIf="!isAdminReadOnlyRole">
                    <div
                      *ngIf="item?.carrierId && item?.carrierId != data?.job?.assignedCarrier?.carrierId && [2, 3].includes(item.state)">
                      <div *ngIf="item.status == 1 && (!displayInfo.isMarketplace || data.populated)">
                        <img class="svg-icon truck1 right10" alt="">
                        <a (click)="onBtnAcceptBid(item)" style="margin-right: 10px;">Accept and Assign Carrier</a>
                      </div>
                      <div *ngIf="item.status == 1 && displayInfo.isMarketplace && !data.populated">
                        <img class="svg-icon truck1 right10" alt="" nz-tooltip
                          nzTooltipTitle="This carrier cannot be assigned because relate to Marketplace Order">
                      </div>
                      <div *ngIf="item.status !== 1">
                        <a nz-tooltip="This carrier cannot be assigned because it is not in Active state.">-</a>
                      </div>
                    </div>
                    <div
                      *ngIf="item?.carrierId && item?.carrierId == data?.job?.assignedCarrier?.carrierId && [2, 3].includes(item.state)">
                      <span nz-typography nzType="success">
                        <span nz-icon nzType="check" nzTheme="outline"></span> Accepted
                        <p class="small-text">
                          <span>by: {{ getFullName(data?.job?.assignedCarrier?.update?.byUser) || "N/A" }}</span>
                          <br />
                          <span>when: {{ $formatDate(data?.job?.assignedCarrier?.update?.when) }}</span>
                        </p>
                      </span>
                    </div>
                  </div>
                </td>
                <!-- Note -->
                <td nzRight>
                  <div *ngIf="!isGroupByPool(item)" class="flex flex-space-between">
                    <div>
                      <p *ngIf="item.note" nz-typography nzEllipsis nzExpandable [nzEllipsisRows]="2" class="note-text">
                        {{item.note}}
                      </p>
                    </div>
                    <div *ngIf="!isAdminReadOnlyRole">
                      <button nz-button (click)="onBtnEditNote(item)" nzSize="small">
                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </nz-table>

    </div>
    <div *ngIf="isTruckSearchTab" dat-truck-search [carrierBidInfo]="data"></div>
    <div *ngIf="isOutreachPerformance" class="box-content top20">
      <div detail-bid-outreach-performance [carrierBidId]="carrierBidId"></div>
    </div>
  </div>
  <div *ngIf="arrBidFromPublic.length && isCarrierMatchesTab" class="box-with-title" style="margin-top: 50px">
    <div class="box-title">Bid From Public</div>
    <div class="box-content">
      <nz-table #nzTablePublic [nzData]="arrBidFromPublic" nzBordered="true" [nzHideOnSinglePage]="true"
        [nzSize]="'default'">
        <thead>
          <tr>
            <th>MC/DOT</th>
            <th>Contact Information</th>
            <th>Answer</th>
            <th>Price</th>
            <th>WARP Carrier Name</th>
            <th>Type</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of nzTablePublic.data">
            <tr>
              <td>
                <div *ngIf="item.dot">DOT: {{item.dot}}</div>
                <div *ngIf="item.mc">MC: {{item.mc}}</div>
                <div *ngIf="!item.mc && !item.dot">N/A</div>
              </td>
              <td>
                <div>Email: {{item.email || 'N/A'}}</div>
                <div>Phone Number: {{displayPhone(item.phone)}}</div>
              </td>
              <td>
                <ng-container *ngIf="item.state == 1">
                  <nz-tag nzColor="error">Refused</nz-tag>
                </ng-container>
                <ng-container *ngIf="item.state == 2">
                  <nz-tag nzColor="success">Accepted</nz-tag>
                </ng-container>
                <ng-container *ngIf="item.state == 3">
                  <nz-tag nzColor="success">Placed Bid</nz-tag>
                </ng-container>
                <p class="small-text" style="margin-top: 5px;" *ngIf="item.insert?.when">when:
                  {{$formatBidPublicDate(item.insert.when)}}</p>
              </td>
              <td>
                {{ item.price? $asMoney(item.price): '-'}}
              </td>
              <td>
                {{ item.carrierInfo?.name || '-' }}
              </td>
              <td>
                <ng-container *ngIf="!item.carrierInfo">-</ng-container>
                <ng-container *ngIf="item.carrierInfo && item.carrierInfo.status != 1">
                  <nz-tag nzColor="warning">{{$asCarrierStatusText(item.carrierInfo.status)}}</nz-tag>
                </ng-container>
                <ng-container *ngIf="item.carrierInfo && item.carrierInfo.status == 1">
                  <nz-tag nzColor="success">Active</nz-tag>
                </ng-container>
              </td>
              <td nzAlign="center">
                <div *ngIf="item.carrierId">
                  <nz-tag [nzColor]="'green'">Moved to Carrier Matches</nz-tag>
                </div>
                <ng-container *ngIf="!item.carrierId">
                  <ng-container *ngIf="!item.signupInfo">
                    <div *ngIf="!item.isMatchCarrierOrUser">
                      <div><nz-tag nzMode="default" nzColor="error">Unregistered</nz-tag></div>
                      <div class="top10">
                        <a [href]="" (click)="sendInviteRegisterToCarrierPublic(item)"
                          style="margin-top: 10px;">
                          Invite via Email
                        </a>
                      </div>
                      <p class="small-text" style="margin-top: 5px;" *ngIf="item.lastSent">Last Sent:
                        {{$formatDate(item.lastSent.when)}}</p>
                    </div>
                    <div *ngIf="item.isMatchCarrierOrUser">
                      <nz-tag>User already existed but not login yet</nz-tag>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="item.signupInfo?.carrierId">
                    <div>
                      Carrier has been completed signup.<br> Need review at
                      <a href="/dashboard/carriers/{{item.signupInfo.carrierId}}" target="_blank">here</a>
                    </div>
                  </ng-container>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>

<div class="footer-btn" *ngIf="!isAdminReadOnlyRole && !isLoading && !isError && isCarrierMatchesTab">
  <button *ngIf="shouldShowHistory" nz-button (click)="goToHistory(model, routeAdminCarrierBidList)">
    <i nz-icon nzType="history" nzTheme="outline"></i>
    View History
  </button>
</div>
