import { Injectable } from "@angular/core";
import { SocketEvent } from "@app/enum";
import { Log } from "@services/log";
import { SocketService } from "@app/socket";
import { Utils } from "@services/utils";
import { EventSocket_DriverTrackLocation, EventSocket_DriverUnTrackLocation, EventSocket_UpdateDriverLocation } from "@wearewarp/types/event";
import { Subject } from "rxjs";

@Injectable({providedIn: 'root'})
export class DriverLocationService {
  private jobId = '';
  private driverLocationSubject: Subject<EventSocket_UpdateDriverLocation> = new Subject();

  constructor(private socket: SocketService) {
    this.socket.subscribeConnectionStatus(isConnected => {
      if (isConnected) {
        this.onSocketConnected();
      }
    })
  }

  setRouteId(id: string) {
    this.trackDriverLocation(id);
    this.jobId = id;
  }

  subscribeDriverLocation(next: (data: EventSocket_UpdateDriverLocation) => void) {
    return this.driverLocationSubject.subscribe(next);
  }

  // reconnect
  private onSocketConnected() {
    if (this.jobId) {
      // Chẳng may bị đứt kết nối mà có kết nối lại thì cần gọi hàm để join lại vào room
      this.trackDriverLocation(this.jobId);
    }
  }

  private trackDriverLocation(jobId: string) {
    Log.i(`trackDriverLocation for job ${jobId}`);
    if (this.jobId && this.jobId != jobId) {
      this.untrackDriverLocation(this.jobId);
    }
    this.socket.subscribeEvent<EventSocket_UpdateDriverLocation>(SocketEvent.driverLocation, data => {
      this.driverLocationSubject.next(Utils.cloneObject(data));
    });
    this.socket.emit<EventSocket_DriverTrackLocation>(SocketEvent.driverTrackLocation, {data: {jobId}});
  }

  private untrackDriverLocation(jobId: string) {
    Log.i(`untrackDriverLocation for job ${jobId}`);
    this.socket.emit<EventSocket_DriverUnTrackLocation>(SocketEvent.driverUnTrackLocation, {data: {jobId}});
  }
}