import { ApiService } from "./api.service";

import { environment } from "@env/environment";
import { map } from 'rxjs/operators';

export class VehicleTypeService {
    api: ApiService = null
    baseUrl: string = null

    
    constructor(api: ApiService) {
        this.api = api
        this.baseUrl = environment.backendUrl + '/v2/vehicle-types'
    }

    public listAll = (success, error) => {
        // TODO Caching
        return this.api.GET(this.baseUrl)
        .pipe(
            map(response => {
                const storageVehicle = {
                    order: 999, 
                    code: 'STORAGE',
                    name: 'Storage',
                    selectable: true,
                    quotable: false,
                    active: true,
                    parent: 'AUTO',
                    palletCapacity: 0,
                };
                return [...response, storageVehicle];
            })
        )
        .subscribe(
            resp => {
                if (resp)
                    success && success(resp)
                else
                    error && error({'message': 'Not found'})
            }, err => {
                error && error(err)
            }
        )
    }
}