import { Component, Input } from "@angular/core";
import { BaseForm, FormGroupDeclaration } from '@wearewarp/ng-form';
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { RatingService } from "../../route-header/rating.service";

export interface FormReportCarrier {
  reasons: {value: string, label: string, checked: boolean }[],
}
@Component({
  selector: 'form-carrier-rating-dialog',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss'
  ]
})

export class FromCarrierRatingDialog extends BaseForm<FormReportCarrier> {
  constructor(private ratingService: RatingService) {
    super();
  }
  protected override formGroupDeclaration: FormGroupDeclaration = {
    responsiveness: {label: 'How responsively did the carrier communicate?', required: true},
    collaboration: {label: 'How did the carrier collaborate with the shipper, warehouse operator, and dispatcher?', required: true},
    techSavvy: {label: 'How is the carrier tech-savvy?', required: true},
    recommendationLikelihood: {label: 'How likely are you to recommend the team to use this carrier?', required: true},
    customerSatisfaction: {label: 'Overall Customer Satisfaction?', required: true},
  }
  @Input() reasons;
  @Input() jobHyperLink: { hyperLinkText: string, hyperLinkUrl: string };
  @Input() rating;
  @Input() onSubmitLikeDisLike
  
  ngOnInit(): void {
    super.ngOnInit();
    this.ratingService.rating$.subscribe(newRating => {
      if (newRating) this.rating = newRating;
    });
  }
  getValue(key) {
    return this.getItemValue(key);
  }

  canSubmit(): boolean {
    return super.canSubmit();
  }
  public thumupCarrierRateOptions = [
    'Carrier responded actively during this route',
    'Carrier used WARP driver app or open tracking url actively',
    'Carrier picked up on-time',
    'Carrier delivered on-time',
  ]
  
  public thumdownCarrierRateOptions = [
    'Carrier did not response actively during this route',
    'Carrier did not use WARP driver app or open tracking url',
    'Carrier picked up lately',
    'Carrier delivered lately',
  ]
  refreshService() {
  }

  public updateRating(newRating: number) {
    this.rating = newRating;
  }
}