export enum EnvType {
  portable = 'portable',
  dev1 = 'dev1',
  dev2 = 'dev2',
  dev3 = 'dev3',
  dev4 = 'dev4',
  dev5 = 'dev5',
  dev = 'development',
  stg = 'staging',
  prod = 'production'
}

export interface IEnv {
  type: EnvType,
  socketUrl: string,
  backendUrl: string,
  backendUrlWithoutCDN?: string,
  authUrl: string,
  customerWebUrl: string,
  carrierWebUrl: string,
  warehouseWebUrl: string,
  trackingWebUrl: string,
  routingUrl: string,
  ratingUrl: string,
  routingAppUrl: string,
  zipcodeUrl: string,
  eventUrl: string,
  routing2Url: string,
  planningUrl: string,
  dataorchUrl: string,
  supportUrl?: string,

  wsUrl: string,
  restWarehouseBackendUrl: string,
  twofaRequired: false,
  commlogServiceUrl: string,
  mapboxgl: {
    useProxy: true,
    apiUrl: string,
    eventUrl: string,
  },
  workQueueServiceUrl: string,
  replaySession?: {
    projectKey: string,
    ingestPoint: string,
    assetUrl: string,
  },
  carrierRatingServiceUrl: string
}