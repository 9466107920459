<div class="dashboard-child-container no-padding list">
  <div nz-row>
    <div nz-col nzJustify="space-between" nzXs="24" nzMd="14">
      <nz-tabset (nzSelectChange)="onTabChange($event)" [nzSelectedIndex]="tabIndex">
        <ng-container *ngFor="let tab of tabList">
            <nz-tab [nzTitle]="titleTemplate"></nz-tab>
            <ng-template #titleTemplate>
              <nz-badge [nzCount]="tab?.total" [nzOverflowCount]="999" nzShowZero [nzStyle]="{ backgroundColor: '#1b1ac4'}">
                <span>{{tab.name}}</span>
              </nz-badge>
            </ng-template>
        </ng-container>
      </nz-tabset>
    </div>
    <div nz-col nzXs="24" nzMd="10">
      <div nz-row [nzGutter]="16">
        <div nz-col nzXs="24" [nzMd]="24">
          <div class="flex">
            <div class="form-group" style="min-width: 200px; width: 100%">
              <search-box #searchBox (doSearch)="doSearch($event)" [placeHolder]="'Search'" [searchKeyword]="searchKeyword">
              </search-box>
            </div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="list-body">
    <nz-table #nzTable 
      (nzPageIndexChange)="onDataListPageChanged($event)" 
      [nzData]="listData" [nzFrontPagination]="false" 
      [nzLoadingIndicator]="tplLoading" 
      [nzLoading]="isLoading"
      [nzNoResult]="tplNoData"
      [nzPageIndex]="pageIndex" 
      [nzPageSize]="limit"
      [nzShowPagination]="totalCount > listData.length" 
      [nzSize]="'default'" 
      [nzTotal]="totalCount" nzBordered="true"
      nzSize="small" style="font-size: 12px;"
    >
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata">
          <i nz-icon nzTheme="outline" nzType="search"></i>
          No results. Please try moving to the other tabs for more results.
        </div>
      </ng-template>

      <ng-template #tplLoading>
        <div class="nodata" style="min-height: 50px;">
          <i nz-icon nzTheme="outline" nzType="loading"></i>
        </div>
      </ng-template>

      <tbody>
        <ng-container *ngFor="let item of listData; let i = index">
          <tr 
            item-bid-session-component 
            [item]="item"
          ></tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</div>
