import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShipmentQueueV2 } from './index';


const shipmentQueueRoutes: Routes = [
  {
    path: '', component: ShipmentQueueV2, data: { title: 'Shipment Queue V2 - WARP Admin' }, children: [
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(shipmentQueueRoutes)],
  exports: [RouterModule]
})
export class ShipmentQueueV2RoutingModule { }
