<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

<div class="main-container" *ngIf="!isLoading">
  <div class="header-box flex">
    <div class="title">Detail Carrier Rating</div>
    <div class="carrier-basic-info flex">
      <div>
        <div>{{getCarrierName(carrierInfo)}}</div>
        <div>(MC: {{carrierInfo?.basicInfo?.mc || "N/A"}} / DOT: {{carrierInfo?.basicInfo?.dot || "N/A"}})</div>
      </div>
    </div>
  </div>
  
  <div class="content-box">
    <!-- carrier score -->
    <div class="flex flex-space-between bottom10">
      <div nz-row [nzGutter]="16">
        <div nz-col class="text-label">{{ scoreTitle }}:</div>
        <div nz-col>
          {{ getScore() }}
          <i nz-icon nzType="star" nzTheme="fill" class="left5"></i>
          <i *ngIf="shouldWarningRecordNotFound" nz-icon nzType="warning" style="color: #faad14;" class="left10" nz-tooltip [nzTooltipTitle]="warningRecordNotFound"></i>
        </div>
      </div>
      <button *ngIf="shouldCorrectCarrierScore" nz-button nzType="primary" (click)="onCorrectCarrierScore()" [nzLoading]="isLoadingCorrectScore" [disabled]="isLoadingCorrectScore">
        Correct score
      </button>
    </div>
    <!-- detail score -->
     <div>
      <div class="text-label bottom10">Detail</div>
      <nz-table #nzTable [nzData]="dataRating?.scoreDetail"
        nzBordered="true" nzSize="small"
        [nzFrontPagination]="false"
      >
        <thead>
          <tr>
            <th>Indicator</th>
            <th>Grade</th>
            <th>Weight</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of nzTable.data">
            <tr>
              <td (click)="onExpandChange(item)">
                {{ getIndicatorLabel(item) }}
                <span *ngIf="isZeroWeight(item)" nz-icon nzType="warning" style="color: #faad14; margin-left: 5px;" nz-tooltip [nzTooltipTitle]="getZeroWeightTooltip(item)"></span>
              </td>
              <td>{{ showGrade(item) }}</td>
              <td>{{ showWeight(item) }}</td>
            </tr>
            <!-- expand detail indicator -->
            <tr *ngIf="expandSet.has(item.type)" style="background-color: #F7F7F8;">
              <td colspan="3" style="padding-left: 24px;">
                <ng-container [ngSwitch]="item.type">
                  <ng-container *ngSwitchCase="'ontime_rate'" [ngTemplateOutlet]="tplDetailAllMonths" [ngTemplateOutletContext]="{item: item}"></ng-container>
                  <ng-container *ngSwitchCase="'delivery_rating'" [ngTemplateOutlet]="tplDetailAllMonths" [ngTemplateOutletContext]="{item: item}"></ng-container>
                  <ng-container *ngSwitchCase="'canceling_after_booked'" [ngTemplateOutlet]="tplDetailAllMonths" [ngTemplateOutletContext]="{item: item}"></ng-container>
                  <ng-container *ngSwitchCase="'no_reply_booking_confirmation'" [ngTemplateOutlet]="tplDetailAllMonths" [ngTemplateOutletContext]="{item: item}"></ng-container>
                  <ng-container *ngSwitchCase="'document_verified'">
                    <div class="small-text">
                      <div>Status: <nz-tag class="left15" [nzColor]="isVerifiedDocument(item) ? 'success' : 'red'">{{ isVerifiedDocument(item) ? 'Passed': 'Not Verified' }}</nz-tag></div>
                      <div>Require documents: <span class="text-secondary text-italic">{{ requireDocuments }}</span></div>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'highway_assessment'">
                    <div class="small-text">Status: <nz-tag class="left15" [nzColor]="highWayStatusColor(item)">{{ getHighWayStatus(item) }}</nz-tag></div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'margin'"></ng-container>
                  <ng-container *ngSwitchCase="'never_get_job'"></ng-container>

                  <ng-template #tplDetailAllMonths let-item="item">
                    <div *ngFor="let detailMonth of dataDetailMonths" class="small-text bottom10">
                      <div class="flex">
                        <div>{{ getMonth(detailMonth) }}</div>
                        <div class="left15 summary-item">{{ summaryIndicatorOnMonth(item, detailMonth) }}</div>
                      </div>
                      <div class="flex">
                        <div><i nz-icon nzType="arrow-right" class="right10"></i></div>
                        <div class="flex" style="flex-wrap: wrap;">
                          <a *ngFor="let route of getFocusRouteOnMonth(item, detailMonth)" [href]="goToDispatch(route.jobId)" target="_blank" class="right10" [ngClass]="route?.type ? 'issued-route' : ''">{{route.code}}</a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>                
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
     </div>
  </div>
</div>