import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { PlanningService } from "@services/planning.service";
import { AddShipmentForm } from "./add-shipment-form";
import _ from "underscore";
import dayjs from "dayjs";
import { NzRadioModule } from 'ng-zorro-antd/radio';

@Component({
    selector: '[active-planning-session-list]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class ActivePlanningSessionList extends BaseComponent {
    service: PlanningService

    raw: any[] = []
    sessions: any[] = []
    prevSessions: any[] = []
    days: any[] = []
    loading: boolean = false
    showPrevSession: boolean = false
    clientFilter: string = 'all'

    constructor() {
        super()
        this.service = new PlanningService(this.api)

        this.loadSessions()
        this.loadPrevSessions()
    }

    loadSessions() {
        this.loading = true
        this.service.listActiveSession().subscribe((res) => {
            this.loading = false
            this.raw = res.filter(it => it.shipmentCount)
            this.filter()
        }, (err) => {

        })
    }

    filter() {
        const walmartId = '01H22NK3MBXBDCW6AZDWFZ09V0' // TODO: config
        if (this.clientFilter == 'all')
            this.sessions = this.raw
        else if (this.clientFilter == 'walmart') {
            this.sessions = this.raw.filter(it => it.clientIds?.includes(walmartId))
        }
        else if (this.clientFilter == 'non-walmart') {
            this.sessions = this.raw.filter(it => !it.clientIds?.includes(walmartId))
        }
        this.processData(this.sessions)
    }

    loadPrevSessions() {
        this.loading = true
        this.service.listPrevSession().subscribe((res) => {
            this.loading = false
            const sessions = res.filter(it => it.shipmentCount)
            for (let session of sessions) {
                session['day'] = dayjs(session['date']).format('dddd, MMM DD')
            }
            this.prevSessions = _.sortBy(sessions, 'date').reverse()
        }, (err) => {

        })
    }

    processData(data) {
        for (let session of data) {
            session['day'] = dayjs(session['date']).format('dddd, MMM DD')
            session['unrouted'] = (session.shipmentCount || 0) - (session.routedShipmentCount || 0)
        }
        const sorted = _.sortBy(data, 'date')
        const days = _.uniq(sorted.map(it => it.day))
        const byday = _.groupBy(sorted, 'day')
        this.days = days.map(day => Object.assign({day}, {sessions: _.sortBy(byday[day], it => -it['unrouted'])}))
    }

    toggleShowPrevSessions() {
        this.showPrevSession = !this.showPrevSession
    }

    onRefreshBtn() {
        this.loadSessions()
        this.loadPrevSessions()
    }

    selectSession(session) {
        this.router.navigate([ Const.routePlanningSessions, session.id])
    }

    onAddShipmentBtn() {
        let saving: boolean = false
        let modalRef: any = null
        modalRef = this.modalService.create({
            nzTitle: 'Add Shipments',
            nzContent: AddShipmentForm,
            nzWidth: '1000px',
            nzComponentParams: {},
            nzFooter: [
                {
                    label: 'CLOSE',
                    type: 'default',
                    disabled: () => saving,
                    onClick: () => modalRef?.destroy()
                },
            ]
        })
    }

    addShipments(ids) {

    }
}