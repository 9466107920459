import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { DetailModule } from '../base/detail.module';
import { ClientModule } from '../clients/module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { FormInputCostModule } from '../base/form-input-cost/module';
import { CarrierSales } from './list';
import { SendDocsComponent } from './send-docs/send-docs.component';
import { ReportRevenue } from './report-revenue';
import { ReportRevenueFilter } from './filter-revenue';
import { SharedModule } from '../shared/shared.module';
import { AddTargetRate } from './target-rate';
import { DispatchModule } from '../dispatch/module';
import { CarrierSalesRoutingModule } from './carrierSales.route';
import { CarrierSalesListHeaderFilterV3 } from './list-header-filter-v3';
import { ModuleFilterStates } from '@wearewarp/ng-antd'
import { UiCommonModule } from '../components/common/module';
import { FilterModal } from './list-header-filter-v4/filter-modal';
import { AssignCarrier } from "@app/admin/carrier-sales-v2/components/assign-carrier";
import { InternalMessageService } from '@services/internal-message.service';
import { FilterInfoSelectComponent } from './list-header-filter-v5';
import { FilterInfoModal } from './list-header-filter-v5/filter-modal';
import { SelectFilterInfoModule } from '../components/filter-info/select-filter-info.module';
import { ItemJobComponent } from './components/item-job';
import { BulkAssignCarrierSaleDialog } from './components/bulk-assign-carrier-sale';

@NgModule({
  imports: [
    CommonModule,
    CarrierSalesRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzPaginationModule,
    NzSelectModule,
    NzButtonModule,
    NzInputModule,
    NzIconModule,
    NzFormModule,
    NzCollapseModule,
    NzCheckboxModule,
    NzRadioModule,
    NzDividerModule,
    NzDatePickerModule,
    SearchBoxModule,
    DetailModule,
    ClientModule,
    SelectBySearchingModule,
    FormInputCostModule,
    NzToolTipModule,
    NzTypographyModule,
    NzPopoverModule,
    SharedModule,
    DispatchModule,
    ModuleFilterStates,
    UiCommonModule,
    NzBadgeModule,
    SelectFilterInfoModule
  ],
  declarations: [
    CarrierSales,
    ReportRevenue,
    ReportRevenueFilter,
    SendDocsComponent,
    AddTargetRate,
    CarrierSalesListHeaderFilterV3,
    FilterModal,
    AssignCarrier,
    FilterInfoSelectComponent,
    FilterInfoModal,
    ItemJobComponent,
    BulkAssignCarrierSaleDialog,
  ],
  exports: [
    CarrierSales,
    ReportRevenue,
  ],
  providers: [
    InternalMessageService,
  ]
})
export class CarrierSalesModuleV2 { }
