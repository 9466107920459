import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { InputHelper } from "@app/services/input-helper";
import _ from 'underscore';
import { DialogService } from "@dialogs/dialog.service";
import { EditCarrierBidAnswerComponent } from "../edit-carrier-bid-answer/edit-carrier-bid-answer.component";
import { DateUtil } from "@services/date-utils";
import { getDashboard } from "@services/index";
import { BaseDetail } from "@app/admin/base/detail";
import { ActivatedRoute } from "@angular/router";
import { SendMessageComponent } from "../send-message/send-message.component";
import { EditNoteComponent } from "../edit-note/edit-note.component";
import { ViewPreviousRouteComponent } from "../../../components/carrier-bid/view-previous-route/view-previous-route.component";
import { BizUtil } from "@services/biz";
import { AddCarrier } from "./add-carrier";
import { AddCarrierPool } from "./add-carrier-pool";
import { CommLogList } from "@components/comm-log";
import { AddCarrierPoolByCoverage } from "./add-carrier-pool-by-coverage";
import { SelectCarrierByServiceArea } from "./add-carrier-by-coverage";
import { ListShipmentExpandUtil } from "@services/list-shipment-expand-util";
import { Utils } from "@services/utils";
import { AssignCarrier } from "@app/admin/carrier-bids/components/assign-carrier";
import { EMPTY, Subject } from "rxjs";
import { Log } from '@services/log';
import { debounceTime, switchMap } from "rxjs/operators";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { AddTargetRate } from "@app/admin/carrier-sales-v2/target-rate";
import { AddCarrierSaleRep } from "@app/admin/dispatch/components/sales/add-carrier-sale-rep";
import { EditOptionalEquipment } from "../../../components/carrier-bid/edit-optional-equipment";
import { EditCarrierBidSettings } from "../../../components/carrier-bid/edit-settings";
import moment from "moment";
import dayjs from "dayjs";
import { EditCounterComponent } from "../edit-counter/edit-counter.component";
import { CounterForCarrierComponent } from "../counter-for-carrier/counter-for-carrier.component";
const API_URLS = {
  GET_CARRIER_BIDS: Const.APIURI_CARRIER_BIDS,
};
const CHECKSTATUS = {
  NO_ITEM_CHECKED: 0,
  AT_LEAST_ONE_ITEM_CHECKED: 1,
  ALL_ITEMS_CHECKED: 2
}
@Component({
  selector: "app-detail-carrier-bid",
  templateUrl: "./detail-carrier-bid.component.html",
  styleUrls: ["./detail-carrier-bid.component.scss"],
})
export class DetailCarrierBidComponent extends BaseDetail {

  public isError = false;
  public isLoading = false;
  public data: any = {};
  public isShowAddCarrierModal = false
  public arrBidFromPublic: any[] = [];
  protected get crudEntity(): string { return 'carrier_bids' }
  public keywordSearchName: string;
  public keywordSearchPoolName: string;
  public keywordSearchContacts: string;
  public listData: any = [];
  public listPool: any = [];
  public shipments: any[] = [];
  public sortPriceDirection = 'ascend';

  protected subscribeTopbarTitle(fn: Function) {
    return fn("View Carrier Bid");
  }

  private listCarrierOriginal: any[];     // lưu mảng carrier ban đầu trước khi convert/group
  public listCarrierWithGroup: any[];     // mảng sau khi đã group theo dedicated pool

  public avgCostOfRoutes: any //giá bid trung bình của route này
  public earliestBid: any //thời gian phản hồi bid sớm nhất
  public lowestPrice: any //giá bid thấp nhất
  public bestExperience: any //chạy tuyến hiện tại nhiều nhất
  public basePrice: any //giá bid mà WARP offer
  public acceptedPrice: any //giá bid đã chốt
  public acceptedCarrier: any
  public displayInfo: any = {};
  public get canPerformAction(): boolean { return true }

  public get checkedStatus() {
    const carriers = this.data.carriers || [];
    let countChecked = carriers.filter(carrier => carrier.checked).length
    if (countChecked == 0) return CHECKSTATUS.NO_ITEM_CHECKED;                   // No item is checked
    if (countChecked == carriers.length) return CHECKSTATUS.ALL_ITEMS_CHECKED;     // All items are checked
    return CHECKSTATUS.AT_LEAST_ONE_ITEM_CHECKED;                                         // A few items (not all) are checked
  }

  thirdPartyPostExist = false;
  shouldShowBtnPost = true;

  public tabSelected: number = 0;
  private tabIndexs = {
    'carrier-matches': 0,
    'truck-search': 1,
    'outreach-performance': 2,
  }

  constructor(activatedRoute: ActivatedRoute, api: ApiService) {
    super(activatedRoute);
    this.api = api;
    this.activatedRoute.params.subscribe(params => {
      super.handleNavigationEnd(activatedRoute.toString(), activatedRoute.queryParamMap);
    })
  }

  public jobHyperLink;

  public get carrierBidId() {
    return this.id
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.searchSubject.pipe(
      debounceTime(150),
      switchMap((query: string) => {
        this.onFilterData();
        return EMPTY;
      })
    ).subscribe(result => { });
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
    this.getThirdPartyPost();
  }

  ngOnDestroy(): void {
    this.timer && clearInterval(this.timer)
  }

  public mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};

  toggleChildren(originalItem: TreeNodeInterface, mappedItem: TreeNodeInterface) {
    ListShipmentExpandUtil.toggleChildren(originalItem, mappedItem, this.mapOfExpandedData);
  }

  onExpandChange(array: TreeNodeInterface[], data: TreeNodeInterface, isExpanded: boolean) {
    ListShipmentExpandUtil.collapse(array, data, isExpanded);
  }

  getTreeNodeId(mappedItem: TreeNodeInterface) {
    let item: any = mappedItem.parent ?? mappedItem;
    return item.id;
  }

  getTagsData(listData) {
    let bidDates: number[] = []
    let bidPrices: number[] = []
    let countLanes: number[] = []

    for (let item of listData) {
      //date
      const when = item?.when && new Date(item?.when)?.getTime()
      if (when) bidDates.push(when)
      //price
      const price = !isNaN(item?.price) && item?.price
      if (price) bidPrices.push(price)
      //count lane completed
      const countLane = !isNaN(item?.countLane) && item?.countLane
      if (countLane) countLanes.push(countLane)
    }
    return {
      earliestBid: Math.min(...bidDates),
      lowestPrice: Math.min(...bidPrices),
      bestExperience: Math.max(...countLanes)
    }
  }

  private addTagsData(listData, earliestBid, lowestPrice, bestExperience) {
    let newData = []
    for (let item of listData) {
      //isEarliestBid
      if (item?.when && new Date(item.when).getTime() == earliestBid) item.isEarliestBid = true
      //isLowestPrice
      if (item?.price && item?.price == lowestPrice) item.isLowestPrice = true
      //bestExperience
      if (item?.countLane && item?.countLane == bestExperience) item.isBestExperience = true

      newData.push(item)
    }
    return newData
  }

  private processListCarrier(listData) {
    this.mapOfExpandedData = ListShipmentExpandUtil.processListData(listData);
    const listCarrierNoGroup = [];
    for (let arr of Object.values(this.mapOfExpandedData)) {
      for (let item of arr) {
        if (this.isGroupByPool(item)) {
          continue;
        }
        listCarrierNoGroup.push(item);
      }
    }
    this.data.carriers = listCarrierNoGroup;
  }

  isGroupByPool(item): boolean {
    return (<string>item.key)?.startsWith('pool-');
  }

  // Những thằng nào nằm trong cùng 1 dedicated pool thì gom thành 1 nhóm
  private groupByDedicatedPool(bidDetail) {
    const carriers: any[] = bidDetail.carriers ?? [];
    const pools: any[] = bidDetail.pools ?? [];
    const dedicatedPools = {};
    for (let pool of pools) {
      if (pool.isDedicatedPool) {
        dedicatedPools[pool.id] = pool;
      }
    }
    const arr = [];
    const dicGroups = {};
    for (let carrier of carriers) {
      carrier.key = `carrier-${carrier.carrierId}`;     // dùng cho TreeNodeInterface
      let isDedicatedPool = false
      let poolData: any = { isDedicatedPool: false }
      if (carrier.poolId && dedicatedPools[carrier.poolId]) {
        isDedicatedPool = true
        poolData = {
          isDedicatedPool: true,
          poolName: dedicatedPools[carrier.poolId].name
        }
      }
      // if (carrier.poolId && dedicatedPools[carrier.poolId]) {
      //   let group = dicGroups[carrier.poolId];
      //   if (!group) {
      //     group = {
      //       key: `pool-${carrier.poolId}`,
      //       poolName: dedicatedPools[carrier.poolId].name,
      //       children: []
      //     };
      //     dicGroups[carrier.poolId] = group;
      //   }
      //   group.children.push(carrier);
      // } else {
      //   arr.push(carrier);
      // }
      arr.push({ ...carrier, ...poolData });
    }

    if (!Utils.isObjectNotEmpty(dicGroups)) return arr;

    let groups = this.sortPoolGroups(Object.values(dicGroups));
    for (let group of groups) {
      group.children = this.sortCarriers(group.children);
    }
    return [...groups, ...arr];
  }

  private sortPoolGroups(pools: any[]) {
    return pools
    // return pools.sort((a: any, b: any) => {
    //   const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    //   const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    //   if (nameA < nameB) {
    //     return -1;
    //   }
    //   if (nameA > nameB) {
    //     return 1;
    //   }
    //   // names must be equal
    //   return 0;
    // })
  }

  private sortCarriers(carriers: any[]) {
    const sortPriceDirection = this.sortPriceDirection;
    return _(carriers).chain()
      .sortBy(function (patient) {
        return patient.title;
      })
      .sortBy(function (patient) {
        return (patient.status) * -1; //reverse sort => sort status desc
      })
      .sortBy(function (patient) {
        if (sortPriceDirection == 'descend') return (patient.price || 0) * -1;
        return (patient.price || 0); //reverse sort => sort price desc
      })
      .sortBy(function (patient) {
        return patient.lastSent ? -1 : 0; //sort lastSent. những carrier đã sent email sẽ lên trước.
      })
      .sortBy(function (patient) {
        return (patient.state || 0) * -1; //reverse sort => sort tag desc
      })
      .value();
  }

  async getData() {
    if (!this.carrierBidId) return;
    this.isLoading = true;

    // check permission access carrier sales legacy
    if (!Const.SHOW_CARRIER_SALES_LEGACY) {
      const url = `${Const.APIV2(`metadata`)}/USER_${this.authUser.id}/USER_PREFERENCES/CARRIER_SALES_LEGACY`;
      const res = await this.api.GET(url).toPromise().catch(err => {
        this.router.navigate([Const.routeAdminCarrierSales, this.carrierBidId]);
      });
      if (res?.value !== 'true' && res?.value !== true) {
        this.router.navigate([Const.routeAdminCarrierSales, this.carrierBidId]);
      }
    }

    let urls = [
      `${API_URLS.GET_CARRIER_BIDS}/${this.carrierBidId}`,
      `${API_URLS.GET_CARRIER_BIDS}/${this.carrierBidId}/get_list_carriers?limit=-1`
    ];
    this.api.concurrentGET(urls).subscribe(
      resp => {
        const bid = resp[0].data;
        const carriers = resp[1].data.list_data;
        this.processDataBid({ ...bid, carriers })
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.showErr(err);
        this.isError = true;
      }
    );
    this.getDataBidPublic();
  }

  private getDataBidPublic() {
    if (!this.carrierBidId) return;
    this.api.GET(`${API_URLS.GET_CARRIER_BIDS}/${this.carrierBidId}/bid-public`).subscribe(
      (response) => {
        let list_data = response?.data?.list_data || [];
        this.arrBidFromPublic = list_data;
      },
      (err) => { }
    );
  }

  calcPercentage(x, y) {
    if (!x || !y || isNaN(x) || isNaN(y)) return
    const percent = ((x / y) * 100) - 100
    if (!percent) return
    return `${(percent).toFixed(2)}%`;
  }

  formatMoney(money: number) {
    return InputHelper.formatMoney2((money).toFixed(2).toString());
  }

  compareWithOtherPrice(text: string, price: number) {
    if (!price || isNaN(price) || !isFinite(price)) return
    if (this.acceptedPrice > price) {
      return {
        status: Const.compareBidPrice.more,
        percent: this.calcPercentage(this.acceptedPrice, price),
        text: `more than ${text} ${this.formatMoney(this.acceptedPrice - price)}`
      }
    }
    if (this.acceptedPrice == price) {
      return {
        status: Const.compareBidPrice.equal,
        percent: this.calcPercentage(this.acceptedPrice, price),
        text: `Equal to ${text} ${this.formatMoney(this.acceptedPrice)}`
      }
    }
    if (this.acceptedPrice < price) {
      return {
        status: Const.compareBidPrice.less,
        percent: this.calcPercentage(price, this.acceptedPrice),
        text: `less than ${text} ${this.formatMoney(price - this.acceptedPrice)}`
      }
    }
    return
  }

  getCompareBidPriceColor(status) {
    switch (status) {
      case Const.compareBidPrice.more:
        return 'red'
      case Const.compareBidPrice.equal:
      case Const.compareBidPrice.less:
        return 'green'
    }
  }

  getCompareBidPriceIcon(status) {
    switch (status) {
      case Const.compareBidPrice.more:
        return 'arrow-up'
      case Const.compareBidPrice.equal:
        return 'check'
      case Const.compareBidPrice.less:
        return 'arrow-down'
    }
  }


  get compareWithLowestPrice() {
    return this.compareWithOtherPrice('lowest price', this.lowestPrice)
  }

  get compareWithMarketPrice() {
    return this.compareWithOtherPrice('market price', this.avgCostOfRoutes)
  }

  get compareWithOfferPrice() {
    return this.compareWithOtherPrice('offer price', this.basePrice)
  }

  private processDataBid(data) {
    if (data.job) this.jobHyperLink = BizUtil.createHyperLinkForJob(data.job);

    //convert data
    let carriers = data.carriers || [];
    for(let carrier of carriers){
      carrier.bidAnswers = this.getCounterBidHistory(carrier);
    }
    data.carriers = carriers;
    this.data = data;
    this.listCarrierOriginal = data.carriers;
    this.listCarrierWithGroup = this.sortCarriers(this.groupByDedicatedPool(data));
    this.listData = this.listCarrierWithGroup;

    //start: xử lý earliest bid, lowest price, route completed
    const { earliestBid, lowestPrice, bestExperience } = this.getTagsData(this.listData)
    this.earliestBid = earliestBid
    this.bestExperience = bestExperience //chạy tuyến này nhiều nhất
    this.lowestPrice = lowestPrice //giá thấp nhất
    this.basePrice = this.data?.basePrice //giá offer
    this.avgCostOfRoutes = this.data?.job?.avgPastRates;
    this.listCarrierWithGroup = this.addTagsData(this.listCarrierWithGroup, earliestBid, lowestPrice, bestExperience)
    // xử lý info cho accepted carrier
    this.acceptedPrice = this.data?.job?.assignedCarrier?.cost?.grandTotal
    this.acceptedCarrier = this.data?.job?.carrier
    const findCarrier = this.listCarrierWithGroup?.find(carrier => carrier?.carrierId == this.acceptedCarrier?.id)
    if (findCarrier) {
      this.acceptedCarrier = {
        ...this.acceptedCarrier,
        isLowestPrice: findCarrier?.isLowestPrice,
        isEarliestBid: findCarrier?.isEarliestBid,
        isBestExperience: findCarrier?.isBestExperience
      }
    }
    //end: xử lý earliest bid, lowest price, route completed

    this.processListCarrier(this.listCarrierWithGroup);

    const pickupTimeZone = data["pickupAddress"]?.metadata?.timeZoneStandard;
    const dropoffTimeZone = data["dropoffAddress"]?.metadata?.timeZoneStandard;
    data['pickupDateOriginal'] = Utils.cloneObject(data['pickupDate']);
    data['pickupDate'] = data['pickupDate'] ? DateUtil.convertLocalTime2(data['pickupDate'], pickupTimeZone) : null;
    data['dropoffDate'] = data['dropoffDate'] ? DateUtil.convertLocalTime2(data['dropoffDate'], dropoffTimeZone) : null;

    data.equipments = data.equipments.map((item) => item.id);
    data.pickupAddressObj = data.pickupAddress
    data.dropoffAddressObj = data.dropoffAddress
    data.pickupAddress = this.getAddressTxtCarrierBid(data.pickupAddress);
    data.dropoffAddress = this.getAddressTxtCarrierBid(data.dropoffAddress);
    this.setFormValues(data);

    this.model = data;
    this.getListPool();
    this.processShipmentsOnHold(this.model?.jobId);

    if (data.job?.shipments?.length) {
      this.data.populated = true
    }
    this.buildDisplayInfo();
    if (this.canPerformAction) {
      const params = { canPerformAction: true };
      this.updateDatRate(params);
    }
  }

  private buildDisplayInfo() {
    this.displayInfo = {
      isGhostLoad: this.model?.job?.type === WarpConst.JobType.ghost,
      isMarketplace: this.model?.job?.type === WarpConst.JobType.ghost && this.model?.job?.source == WarpConst.JobSources.marketplace,
      clientName: this.model?.job?.clients?.[0]?.name || 'N/A',
      numOfClient: this.model?.job?.clients?.length ?? 0,
      arrClientName: this.model?.job?.clients?.map(it => it.name) || [],
      mileage: this.getMileage(),
      carrierSalesRep: this.getFullName(this.model?.job?.carrierSalesRep),
      isShowRemainingTimePickup: this.isShowRemainingTimePickup(),
      vehicles: this.getVehicles(),
      firstCommodity: this.getFirstCommodity(),
      allCommodities: this.getAllCommodities(),
      tempRange: this.model?.job?.tempRange,
      numOfServiceOptions: this.model?.serviceOptions?.length ?? 0,
      firstServiceOptions: this.model?.serviceOptions?.[0]?.name ?? '',
      allServiceOptions: this.model?.serviceOptions?.map(it => it.name)?.join(', '),
      numOfOptionalEquipments: this.model?.optionalEquipments?.length ?? 0,
      firstOptionalEquipments: this.model?.optionalEquipments?.[0]?.label ?? '',
      allOptionalEquipments: this.model?.optionalEquipments?.map(it => it.label)?.join(', '),
      totalShipmentCost: this.model?.job?.totalShipmentsCost,
      totalShipmentCostPerMile: this.getTotalShipmentCostPerMile(),
      bidModeType: this.getLabelType(this.model?.type),
      warpOffer: Utils.isNumber(this.model?.basePrice) ? InputHelper.formatMoney2(String(this.model.basePrice)) : null,
    }
    if (this.displayInfo.isShowRemainingTimePickup) {
      if (this.timer) clearInterval(this.timer);
      this.calculateRemainingTime();
      this.timer = setInterval(() => {
        this.calculateRemainingTime();
      }, 1000);
    }
  }

  private isShowRemainingTimePickup() {
    if (this.model?.job?.assignedCarrier?.carrierId) {
      return false;
    }
    if (!this.model?.pickupDate) return false;
    return true;
  }

  private getMileage(): string {
    const totalDistance = this.model?.job?.totalDistance;
    if (!totalDistance) return '';
    return (totalDistance / 1609.34).toFixed(2).toLocaleString();
  }

  private getVehicles(): any[] {
    let vehicles = this.model?.vehicles;
    if (!vehicles?.length) return [];
    vehicles = vehicles.filter(x => x);
    return vehicles.map(vehicle => {
      if (vehicle.options?.length) {
        return `${vehicle.name} /w ${vehicle.options.map(Utils.capitalize).join(", ")}`
      }
      return vehicle.name
    });
  }

  getMoneyValue(value) {
    return '$' + InputHelper._formatMoney(value, 0);
  }

  isLoadingDatRate = false;
  private updateDatRate(params) {
    if (!this.model?.jobId) return;
    this.isLoadingDatRate = true;
    this.api.PUT(`${Const.APIURI_JOBS}/${this.model.jobId}/update_dat_rate_for_job`, params).subscribe(
      resp => {
        if (resp?.data?.total_charge && this.model?.job) {
          this.model.job.datRate = resp?.data;
        }
        this.isLoadingDatRate = false;
      }, err => {
        this.isLoadingDatRate = false;
        Log.e(err);
      }
    );
  }

  isLoadingAvg = false;
  private updateAvgPastRate(params) {
    if (!this.model?.jobId) return;
    this.isLoadingAvg = true;
    this.api.POST(Const.APIV2(`${Const.APIURI_JOBS}/${this.model.jobId}/update_avg_past_rate`), params).subscribe(
      resp => {
        if (resp?.data && this.model?.job) {
          this.avgCostOfRoutes = resp?.data?.avgPastRates;
        }
        this.isLoadingAvg = false;
      }, err => {
        this.isLoadingAvg = false;
        Log.e(err);
      }
    );
  }

  public onBtnOnUpdateTargetRate() {
    DialogService.openFormDialog1(AddTargetRate, {
      nzComponentParams: {
        data: this.model?.job?.datRate?.cost_update?.cost,
        closeOnSuccess: true,
        onSave: data => this.updateDatRate(data)
      },
    });
  }

  onReUpdateDatRate(event) {
    this.updateDatRate({});
  }

  onReUpdateAvgPastRate(event) {
    this.updateAvgPastRate({});
  }

  private processShipmentsOnHold(jobId: string) {
    if (!jobId) return;
    const url = Const.APIV2(`${Const.APIURI_JOBS}/${jobId}/${Const.APIURI_SHIPMENTS}`);
    this.api.GET(url).subscribe(
      resp => {
        this.shipments = resp?.data?.list_data;
        this.processOnHold();
      }, err => {
        this.showErr(err);
      }
    );
  }

  private getTotalShipmentCostPerMile() {
    let mileage: any = this.getMileage();
    if (!mileage) return null;
    mileage = parseFloat(mileage);
    const totalShipmentCost = InputHelper.getValueMoney(this.model?.job?.totalShipmentsCost)
    if (mileage && Utils.isNumber(totalShipmentCost)) {
      return `$${(totalShipmentCost / mileage).toFixed(2)}/mi`;
    }
    return null;
  }

  onHold: string[] = []
  processOnHold() {
    this.onHold = (this.shipments || []).filter(it => it.tags && it.tags.indexOf('HOLD') >= 0).map(it => it.warpId);
  }

  getAddressTxtCarrierBid(addr) {
    if (!addr) return '';
    let txt = `${addr.city}, ${addr.state}, ${addr.zipcode}`;
    if (addr.street) {
      txt = `${addr.street}, ${txt}`;
    }
    return txt;
  }

  onBtnAcceptBid(carrier: any) {
    let cost = this.model.baseRate?.grandTotal === carrier.price ? this.model.baseRate : this.makeCost(carrier)
    DialogService.openFormDialog1(AssignCarrier, {
      nzComponentParams: {
        jobId: this.data.jobId,
        isRequireCarrierAcceptLoadTender: this.model?.isRequireCarrierAcceptLoadTender ?? false,
        matchedCarrier: carrier,
        cost: cost,
        job: this.data?.job,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.onBtnRefresh()
          this.showDialog(`Carrier has been assigned successfully.<br /><br />
            <a href="${this.gotoDispatch()}" target="_blank">Go to dispatch</a>
          `);
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
    // this.confirmYesNo('This action will accept bid for this carrier. Please confirm you are sure you want to perform this action.', async () => this.acceptBid(carrier))
  }

  onBtnHistoryStatus(item) {
    const { contacts } = item;
    let allHistories = [];
    for (let contact of contacts) {
      let histories = contact?.histories || [];
      if (histories.length) histories = histories.map(it => {
        return { ...it, email: contact.contactEmail, phone: contact.contactPhone }
      })
      allHistories = [...allHistories, ...histories];
    }

    allHistories = allHistories.sort(function (a, b) {
      let bDate = new Date(b.when)
      let aDate = new Date(a.when)
      return Number(bDate) - Number(aDate)
    });

    if (!allHistories.length) return;
    DialogService.openFormDialog1(CommLogList, {
      nzComponentParams: {
        model: { histories: _.uniq(allHistories, it => it.logId) },
        closeOnSuccess: true
      },
      nzClassName: "comm-log-form modal-no-padding modal-xl",
    });
  }

  $asSentStatusText = (status = "") => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'Pending';
      case 'sent':
      case 'unknown':
        return 'Sent';
      case 'failed':
        return 'Failed';
      case 'success':
        return 'Success';
      case 'delivered':
        return 'Delivered';
      case 'opened':
        return 'Opened';
      case 'clicked':
        return 'Clicked';
      case 'unsubscribed':
        return 'Unsubscribed';
      case 'undelivered':
        return 'Undelivered';
      case 'invalid':
        return 'Invalid';
      case 'unreachable':
        return 'Unreachable';
      default:
        return status;
    }
  };

  $asSentStatusColor = (status = "") => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'gray';
      case 'sent':
      case 'success':
      case 'unknown':
        return 'green';
      case 'failed':
      case 'invalid':
      case 'unsubscribed':
        return 'red';
      case 'delivered':
      case 'opened':
      case 'clicked':
        return 'blue';
      case 'undelivered':
      case 'unreachable':
        return 'orange';
      default:
        return '';
    }
  }

  $shouldShowHistory = (item) => {
    return item?.logId
  }

  private makeCost(data) {
    return {
      currency: {
        type: "USD",
        fxRate: null
      },
      transitCost: {
        rate: data.price,
        qty: 1,
        total: data.price
      },
      volumeDiscount: {
        type: "percentage",
        percentage: null,
        flatRate: null,
        qty: null,
        total: 0
      },
      subTotal: data.price,
      fuelCost: {
        type: "rpm",
        percentage: null,
        rpm: null,
        qty: null,
        total: 0
      },
      serviceOptions: [],
      negativeMarginReason: null,
      manager: null,
      grandTotal: data.price,
      usdConversion: 0
    }
  }

  async acceptBid(carrier) {
    const params = {
      jobId: this.data.jobId,
      carrierId: carrier.carrierId,
      cost: this.makeCost(carrier)
    };
    const resp = await this.api.POST(`${Const.APIURI_CARRIER_BIDS}/accept-bid`, params).toPromise().catch(err => {
      this.showErr(err);
    });

    if (resp) {
      this.onBtnRefresh();
      this.showDialog(`Carrier has been assigned successfully.<br /><br />
        <a href="${this.gotoDispatch()}" target="_blank">Go to dispatch</a>
      `);
    }
  }

  gotoDispatch() {
    return `${Const.routeAdminDispatchList}/${this.data.jobId}`
  }

  onBtnSendMessage() {
    DialogService.openFormDialog1(SendMessageComponent, {
      nzComponentParams: {
        model: { ...this.data },
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "send-message-form modal-xl",
    });
  }

  onBtnEditItem(item) {
    DialogService.openFormDialog1(EditCarrierBidAnswerComponent, {
      nzComponentParams: {
        carrierBidItem: item,
        carrierBidInfo: this.data,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "modal",
    });
  }

  onBtnEditNote(item) {
    DialogService.openFormDialog1(EditNoteComponent, {
      nzComponentParams: {
        carrierBidItem: item,
        carrierBidInfo: this.data,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          //this.listData[index].carrier = resp.data.assignedCarrier.carrierObj;
          this.getData()
        },
      },
      nzClassName: "modal modal-lg",
    });
  }

  onCheckedChange(event, key = 'not_sent') {
    if (key === 'all' && event) {
      this.data.carriers.map(carrier => carrier['checked'] = event);
    }
    if (key === 'not_sent' && event) {
      this.data.carriers.map(carrier => {
        if (!carrier?.lastSent?.when) carrier['checked'] = true;
        else carrier['checked'] = false;
        return carrier;
      });
    }
    if (!event) this.data.carriers.map(carrier => carrier['checked'] = false);
    this.reRenderListCarrier(this.data.carriers);
  }

  listOfSelection = [
    {
      text: 'Select all carriers',
      onSelect: () => {
        this.onCheckedChange(true, 'all');
      }
    }
  ];

  onBtnBack() {
    this.location.back()
  }
  onBtnEdit() {
    this.router.navigate([this.routeAdminCarrierSales, 'edit', this.data.id])
  }
  onBtnGoToJob() {
    this.router.navigate([this.routeAdminDispatchList, this.data.jobId])
  }

  onBtnThirdPartyPost() {
    if (!this.thirdPartyPostExist) {
      this.router.navigate([this.routeAdminCarrierSales, this.data.id, 'third-party-post', 'add'])
    } else {
      this.router.navigate([this.routeAdminCarrierSales, this.data.id, 'third-party-post'])
    }
  }

  onBtnPreviousRoute() {
    DialogService.openFormDialog1(ViewPreviousRouteComponent, {
      nzComponentParams: {
        model: this.data,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "view-previous-route-form modal-xl",
    });
  }

  $asCarrierStatusText = (status) => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const statusKey = Object.keys(Const.CarrierStatus).filter((key) => Const.CarrierStatus[key] == status)[0] || "";
    return capitalizeFirstLetter(statusKey);
  };

  $asMoney = (money) => {
    return InputHelper.formatMoney1(money + '');
  };
  $formatDate = (date) => {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }
  $displayEstTime = (date) => {
    return dayjs(date).tz('America/New_York').format('MMM DD, hh:mm A');
  }
  $formatBidPublicDate = (isoStr) => {
    return DateUtil.displayLocalTime(isoStr, { format: Const.FORMAT_GUI_DATETIME_SHORT });
  }

  openAddCarrierModal() {
    DialogService.openFormDialog1(AddCarrier, {
      nzComponentParams: {
        model: this.model,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "send-mail-form modal-xl",
    });
  }

  onClickPoolCoverage() {
    DialogService.openDialog(AddCarrierPoolByCoverage, {
      nzComponentParams: {
        model: this.data,
        onSave: this.addCarrierByCoverage.bind(this)
        // closeOnSuccess: true,
        // updateSuccess: (resp) => {
        //   this.getData()
        // },
      },
      nzClassName: "add-carrier-pool-by-coverage modal-xxl",
    });
  }

  onAddCarriersByCoverage() {
    DialogService.openDialog(SelectCarrierByServiceArea, {
      nzComponentParams: {
        model: this.data,
        onSave: this.addCarrierByCoverage.bind(this)
        // closeOnSuccess: true,
        // updateSuccess: (resp) => {
        //   this.getData()
        // },
      },
      replaceWrapClassName: true,
      nzClosable: false,
      nzClassName: "add-carrier-pool-by-coverage modal-xxl",
    });
  }

  addCarrierByCoverage(carrierIds: string[] = []) {
    let carriers = carrierIds.map(id => ({ carrierId: id }))
    this.api.POST(`${Const.APIURI_CARRIER_BIDS}/${this.model?.id}/update-carrier`, {
      carriers: carriers
    }).subscribe(
      (response) => {
        this.getData()
        this.showInfo('Add Carriers successfully');
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  openAddCarrierPoolModal() {
    DialogService.openFormDialog1(AddCarrierPool, {
      nzComponentParams: {
        model: this.model,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "add-carrier-pool-form modal-xl",
    });
  }

  confirmRemoveCarrier() {
    this.confirmDeletion({
      message: `Are you sure you want to remove selected carrier ?`,
      fnOk: () => {
        const carriersRemovedIds = this.data?.carriers?.filter(carrier => carrier.checked).map(it => it.carrierId)
        this.api.POST(`${Const.APIURI_CARRIER_BIDS}/${this.model?.id}/update-carrier`, { deletedCarrierIds: carriersRemovedIds }).subscribe(
          (response) => {
            this.keywordSearchName = '';
            this.keywordSearchPoolName = '';
            this.keywordSearchContacts = ''
            this.getData()
            this.showInfo('Remove successfully');
          },
          (err) => {
            this.showErr(err);
          }
        );
      },
      txtBtnOk: 'Remove'
    });
  }

  get isShowWarpOffer() {
    return this?.formInput?.get('isShowBasePrice')?.value;
  }

  carrierBIDType = Object.values(Const.CarrierBidTypes);

  getLabelType(key) {
    switch (key) {
      case Const.CarrierBidTypes.direct: return 'Auto assign when carrier accept load (Direct)';
      case Const.CarrierBidTypes.manual: return 'Manual review (Indirect)';
      default: return 'Manual';
    }
  }

  onItemCheckedChange(event, item) {
    (this.data.carriers?.find(it => it.carrierId == item.carrierId)).checked = event;
  }

  private searchSubject = new Subject<string>();
  onSearch(value: string, type: string) {
    this.data.carriers.map(carrier => {
      if (carrier['checked']) carrier['checked'] = false;
    });
    if (type == 'name') this.keywordSearchName = value;
    if (type == 'contacts') this.keywordSearchContacts = value;
    if (type == 'poolName') this.keywordSearchPoolName = value;
    this.searchSubject.next(value);
  }

  private onFilterData() {
    if (!this.keywordSearchName && !this.keywordSearchContacts && !this.isActiveFilterPool && !this.keywordSearchPoolName) {
      this.reRenderListCarrier(this.listCarrierOriginal);
      return;
    }
    this.reRenderListCarrier(this.listCarrierOriginal.filter(carrier => this.matchedCarrier(carrier)));
  }

  private reRenderListCarrier(carriers) {
    this.data.carriers = carriers.filter(carrier => this.matchedCarrier(carrier));
    this.listCarrierWithGroup = this.sortCarriers(this.groupByDedicatedPool(this.data));
    this.mapOfExpandedData = ListShipmentExpandUtil.processListData(this.listCarrierWithGroup);
    this.listData = this.listCarrierWithGroup;
  }
  private matchedCarrier(carrier) {
    let matched = this.filterName(this.keywordSearchName, carrier)
      && this.filterPoolName(this.keywordSearchPoolName, carrier)
      && this.filterContact(this.keywordSearchContacts, carrier)
      && this.filterPool(this.listPool, carrier);
    return matched
  }

  filterName(inputValue: string, item: any): boolean {
    if (!inputValue) return true;
    inputValue = inputValue.trim()
    let invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;:+_-]+/g;
    inputValue = inputValue.replace(invalid, "");
    const regexName = new RegExp(inputValue, "i");
    return regexName.test(item.name.replace(invalid, ""));
  }

  filterPoolName(inputValue: string, item: any): boolean {
    if (!inputValue) return true;
    inputValue = inputValue.trim()
    let invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;:+_-]+/g;
    inputValue = inputValue.replace(invalid, "");
    const regexName = new RegExp(inputValue, "i");
    return regexName.test((item?.poolName || "").replace(invalid, ""));
  }

  filterContact(inputValue: string, item: any): boolean {
    if (!inputValue) return true;
    inputValue = inputValue.trim();
    let invalid = /[°"§%()\[\]{}=\\?´ `'#<>|,;:+_-]+/g;
    let newValue = inputValue.replace(invalid, "");
    const regex = new RegExp(newValue, "i");
    const regexContact = new RegExp(inputValue, "i");
    return regexContact.test(item.contacts[0].contactName) || regex.test(item.contacts[0].contactEmail) || regex.test(item.contacts[0].contactPhone);
  }

  filterPool(listPool: any[], item: any): boolean {
    if (this.isActiveFilterPool == false) return true;
    const listCheckedPool = listPool?.filter(it => it.checked == true);
    if (item.poolId) return listCheckedPool?.find(it => it.value == item.poolId) != null;
    if (!Utils.isArrayNotEmpty(item.children)) return false;
    const firstChildrenPoolId = item?.children[0]?.poolId;
    return listCheckedPool?.find(it => it.value == firstChildrenPoolId) != null;
  }

  sortPriceCarrier = (a, b) => {
    if (a.price && b.price) return a.price - b.price;
    if (a.children && b.children) return a.children[0].price - b.children[0].price;
    if (a.price || b.children) {
      if (this.sortPriceDirection === 'ascend') return (a.price || 9999999) - (b.children?.[0]?.price || 9999999);
      return (a.price || 0) - (b.children?.[0]?.price || 0);
    }
    if (a.children || b.price) {
      if (this.sortPriceDirection === 'ascend') return (a.children?.[0]?.price || 9999999) - (b.price || 9999999);
      return (a.children?.[0]?.price || 0) - (b.price || 0);
    }
  }

  getListPool() {
    this.listPool = this.model?.pools.map(pool => {
      return {
        label: pool.name,
        value: pool.id ?? pool._id
      }
    });
  }

  isVisibleFilterPool = false;
  isActiveFilterPool = false;
  onChangeFilterPool(event) {
    this.isActiveFilterPool = event?.find(it => it?.checked) ? true : false;
  }

  onBtnResetFilterPool() {
    this.listPool?.forEach(it => {
      if (it.checked) it.checked = false;
    });
    this.isVisibleFilterPool = false;
    this.isActiveFilterPool = false;
    this.onFilterData()
  }

  onBtnFilterPool() {
    this.isVisibleFilterPool = false;
    this.onFilterData();
  }

  shouldShowFilterPool() {
    return this.model?.pools?.length > 0;
  }

  changeSort(event) {
    this.sortPriceDirection = event;
    this.reRenderListCarrier(this.listCarrierOriginal);
  }

  async getThirdPartyPost() {
    const resp = await this.api.GET(`${Const.APIURI_THIRD_PARTY_POST}/carrier-bid/${this.carrierBidId}`)
    .toPromise().catch(err => {
      this.showErr(err);
      this.shouldShowBtnPost = false;
    });
    this.thirdPartyPostExist = resp?.data?.count > 0 || resp.data?.list_data?.length > 0 || false;
    return;
  }

  public shouldDisableRemoveButton() {
    const carriersRemoved = this.data?.carriers?.filter(carrier => carrier.checked);
    for (let carrier of carriersRemoved) {
      if (carrier?.state == Const.CarrierBidState.Accepted || carrier?.state == Const.CarrierBidState.PlacedBid) return true;
      if (carrier?.lastSent?.when) return true;
      if (carrier.price) return true;
    }
    return false;
  }

  public isIndeterminate() {
    return this.checkedStatus === CHECKSTATUS.AT_LEAST_ONE_ITEM_CHECKED
  }

  public isCheckedAll() {
    return this.checkedStatus === CHECKSTATUS.ALL_ITEMS_CHECKED
  }

  public isNoChecked() {
    return this.checkedStatus === CHECKSTATUS.NO_ITEM_CHECKED
  }

  public getFirstCommodity() {
    if (this.model?.job?.totalShipmentsItems?.commodity?.length) {
      return this.model.job.totalShipmentsItems.commodity[0];
    }
    else return "";
  }

  public getAllCommodities() {
    return this.model?.job?.totalShipmentsItems?.commodity?.join(', ');
  }

  onTabSelectedIndexChange() {
    // refresh data when switching tab
    // if (this.isCarrierMatchesTab) {
    //   this.onBtnRefresh();
    // }
  }

  get isCarrierMatchesTab() {
    return this.tabSelected === this.tabIndexs['carrier-matches'];
  }

  get isTruckSearchTab() {
    return this.tabSelected === this.tabIndexs['truck-search'];
  }

  get isOutreachPerformance() {
    return this.tabSelected === this.tabIndexs['outreach-performance'];
  }

  isEmailSending: boolean;
  sendInviteRegisterToCarrierPublic(item) {
    if (this.isEmailSending) return;
    this.isEmailSending = true;
    let url = `${API_URLS.GET_CARRIER_BIDS}/send-register-invite-bid-public`;
    let data = { logId: item.id }
    this.api.POST(url, data).subscribe(
      resp => {
        this.showSuccess('Sent successfully');
        this.isEmailSending = false;
        this.getDataBidPublic();
      }, err => {
        this.showErr(err);
        this.isEmailSending = false;
      }
    )
  }

  onBtnEditCarrierSalesRep() {
    if (!this.model?.jobId) return;
    const carrierSalesRepId = this.model?.job?.carrierSalesRep?.id;
    DialogService.openFormDialog1(AddCarrierSaleRep, {
      nzComponentParams: {
        jobId: this.model.jobId,
        carrierSalesRepId: carrierSalesRepId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.getData();
        }
      },
      nzClassName: 'modal-no-padding',
    })
  }

  onBtnEditOptionalEquipments() {
    DialogService.openFormDialog1(EditOptionalEquipment, {
      nzComponentParams: {
        model: {
          optionalEquipments: this.model?.optionalEquipments || []
        },
        onSave: data => {
          let url = `${Const.APIURI_CARRIER_BIDS}/${this.id}/update-optional-equipment`;
          return this.api.PUT(url, data)
        },
        updateSuccess: resp => {
          this.getData();
        }
      },
      nzClassName: 'modal-no-padding',
    })
  }

  onBtnEditSettings() {
    DialogService.openFormDialog1(EditCarrierBidSettings, {
      nzComponentParams: {
        model: {
          type: this.model?.type,
          isAllowPlaceBid: this.model?.isAllowPlaceBid,
          isShowBasePrice: this.model?.isShowBasePrice,
          basePrice: this.model?.basePrice,
          isShowOnBidBoard: this.model?.isShowOnBidBoard,
          isRequireCarrierAcceptLoadTender: this.model?.isRequireCarrierAcceptLoadTender,
        },
        isSourceMarketPlace: this.displayInfo.isMarketplace,
        onSave: data => {
          let url = `${Const.APIURI_CARRIER_BIDS}/${this.id}/settings`;
          return this.api.PUT(url, data)
        },
        updateSuccess: resp => {
          this.getData();
        }
      },
      nzClassName: 'modal-no-padding',
    })
  }

  remainingTime: any = {};
  timer = null;
  calculateRemainingTime() {
    if (!this.model?.pickupDateOriginal) return;
    const currentTime = new Date();
    const difference = new Date(this.model.pickupDateOriginal).getTime() - currentTime.getTime();
    this.remainingTime.isNegative = difference < 0;
    const absoluteDifference = Math.abs(difference);
    this.remainingTime.days = Math.floor(absoluteDifference / (1000 * 60 * 60 * 24));
    this.remainingTime.hours = Math.floor((absoluteDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.remainingTime.minutes = Math.floor((absoluteDifference % (1000 * 60 * 60)) / (1000 * 60));
    this.remainingTime.seconds = Math.floor((absoluteDifference % (1000 * 60)) / 1000);
  }

  whyAdded(item) {
    if (!item?.metadata?.source) return;
    let text = '';
    switch (item.metadata.source) {
      case 'GEO_FEATURE_ASSIGNED':
        text += `This carrier was assigned to same lane in the past. LOAD: ${item.metadata.loadCode}.`;
        break;
      case 'GEO_FEATURE_PLACED':
        text += `This carrier placed bid to same lane in the past. LOAD: ${item.metadata.loadCode}.`;
        break;
      case 'EXTERNAL_MATCHING':
        text += `This carrier was matched by external system. Source: ${item.metadata?.searching?.source}`;
        break;
      case 'SAME_MARKET':
        text += `This carrier placed bid to same MARKET in the past. Source: ${item.metadata?.searching?.market}. LOAD: ${item.metadata?.loadCode}`;
        break;
      case 'PREFERRED_LANE':
        text += `This carrier has set this lane in the profile. Lane: ${item.metadata?.searching?.from?.city}, ${item.metadata?.searching?.from?.state} -> ${item.metadata?.searching?.to?.city}, ${item.metadata?.searching?.to?.state}`;
        break;
      default:
        text += item.metadata.source;
    }
    if (item.metadata?.searching?.radius) {
      text += ` Radius: ${item.metadata?.searching?.radius} miles.`
    }
    return text;
  }

  messageAutoSend() {
    if(!this.data.basePrice) return;
    if(this.data?.metadata?.isSentBid) return;
    //nếu chưa đến 3.5 ngày trước pickup thì hiển thị mess
    if (!this.pickupDateInTime())
      return `This bid is ready to send to carriers. It will be sent automatically at ${this.getSendTime()?.format("YYYY-MM-DD HH:mm")} EST.`;
    console.log(`isWorkingTime: ${this.isWorkingTime()}`)
    console.log(`pickupBeforeNextWorkingTime: ${this.pickupBeforeNextWorkingTime()}`)
    //nếu trong 3.5 ngày thì không hiện
    if (this.isWorkingTime()) return;
    //nếu không phải working time và pickup date sau next working time thì hiện
    if (this.pickupBeforeNextWorkingTime()) return;
    return `This bid is ready to send to carriers. It will be sent automatically at ${this.getNextWorkingTime()?.format("YYYY-MM-DD HH:mm")} EST.`;
  }

  isWorkingTime() {
    //get now in EST
    const now = moment().tz('America/New_York').format('HH:mm:ss');
    return now >= Const.CarrierBidWorkingTimes.start && now <= Const.CarrierBidWorkingTimes.end;
  }
  getNextWorkingTime() {
    //get now in EST
    let startWorkingTime = Const.CarrierBidWorkingTimes.start?.split(":")
    let tomorrow = moment().tz('America/New_York').set({
      hour: Number(startWorkingTime[0]) || 9,
      minute: Number(startWorkingTime[1]) || 0,
      second: Number(startWorkingTime[2]) || 0
    }).add(1, "day");
    return tomorrow
  }

  getSendTime(){
    let pickupDate = moment(this.data.pickupDateOriginal).tz('America/New_York');
    //const nextWorkingTime = this.getNextWorkingTime();

    let sendTime = pickupDate.clone().subtract(3.5, 'days');
    let startWorkingTimeSplit = Const.CarrierBidWorkingTimes.start?.split(":")
    let endWorkingTimeSplit = Const.CarrierBidWorkingTimes.end?.split(":")
    let startWorkingTime = sendTime.clone().set({
      hour: Number(startWorkingTimeSplit[0]) || 9,
      minute: Number(startWorkingTimeSplit[1]) || 0,
      second: Number(startWorkingTimeSplit[2]) || 0
    });
    console.log("startWorkingTime1", startWorkingTime.format("YYYY-MM-DD HH:mm Z"))
    let endWorkingTime = sendTime.clone().set({
      hour: Number(endWorkingTimeSplit[0]) || 17,
      minute: Number(endWorkingTimeSplit[1]) || 0,
      second: Number(endWorkingTimeSplit[2]) || 0
    });
    console.log("endWorkingTime1", endWorkingTime.format("YYYY-MM-DD HH:mm Z"))
    console.log("pickupDate1", pickupDate.format("YYYY-MM-DD HH:mm Z"))
    if(pickupDate.isBefore(endWorkingTime)) return startWorkingTime
    //next day
    let tomorrow = startWorkingTime.clone().add(1, "day");
    return tomorrow
  }

  pickupBeforeNextWorkingTime() {
    const nextWorkingTime = this.getNextWorkingTime();
    let pickupDate = moment(this.data.pickupDateOriginal);
    console.log(`pickupDate: ${pickupDate.format("YYYY-MM-DD HH:mm")}`)
    console.log(`nextWorkingTime: ${nextWorkingTime.format("YYYY-MM-DD HH:mm")}`)
    console.log(pickupDate.toISOString(), nextWorkingTime.toISOString())
    return pickupDate.isBefore(nextWorkingTime);
  }

  pickupDateInTime() {
    //check if pickup date within 3.5 days
    let pickupDate = moment(this.data.pickupDateOriginal);
    let time = moment().add(84, 'hours');
    return pickupDate.isBefore(time);
  }

  public onOpenCounterDialog(item){
    DialogService.openFormDialog1(EditCounterComponent, {
      nzComponentParams: {
        carrierBid: item,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.getData();
        }
      },
    })
  }

  public onBtnCounterBidForCarrier(item) {
    DialogService.openFormDialog1(CounterForCarrierComponent, {
      nzComponentParams: {
        carrierBidItem: item,
        carrierBidInfo: this.data,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.getData()
        },
      },
      nzClassName: "modal",
    });
  }

  public hasCounterBidHistory(item){
    if(item?.bidAnswers && Utils.isArrayNotEmpty(item?.bidAnswers)) return true;
    return false;
  }

  public getAuthorOfBidCounter(couterItem){
    if(couterItem?.entity == 'carrier') return 'Carrier';
    if(couterItem?.entity == 'admin') return 'Warp';
    return 'N/A'
  }

  public getCounterBidHistory(item){
    let answers = item?.bidCounterOffer?.answers || [];
    if(item?.price && !item?.bidCounterOffer?.answers && !item?.bidCounterOffer?.answers.length){
      answers.unshift({
        price: item.price,
        entity: WarpConst.BidCounterOfferEntities.carrier,
        update: item?.update
      })
    }
    return answers;
  }

  public checkCanCounter(item){
    if(this.data?.job?.assignedCarrier?.carrierId) return false;
    if(item?.bidCounterOffer?.status == WarpConst.BidCounterStatus.waitAdminReply ) return true;
    if(item?.price && !item?.bidCounterOffer) return true;
    return false;
  }

  public checkCanHelpCarrierCounter(item){
    if(this.data?.job?.assignedCarrier?.carrierId) return false;
    if(item?.bidCounterOffer?.status && item?.bidCounterOffer?.status == WarpConst.BidCounterStatus.waitCarrierReply) return true;
    return false;
  }

  public isCarrierAcceptedPrice(item){
    return (item?.bidCounterOffer?.status == WarpConst.BidCounterStatus.accepted)
  }

  public isCarrierRefusedBid(item){
    return item?.state == 1;
  }

  public getCarrierAcceptedPrice(item){
    return item?.price
  }

  public  isAdminAssignCarrier(){
    return this.data?.job?.assignedCarrier?.carrierId;
  }

  public formatDate(date: string){
    if(!date) return '';
    return DateUtil.formatDate(date, Const.FORMAT_GUI_DATETIME_V3) + ' ';
  }

  public getTooltipForBidCounterAction(counter){
    return `${this.formatDate(counter?.update?.when)}${ counter?.entityName ? 'by '+counter.entityName : ''}`
  }

  public getLastAnswer(item){
    let answers = item?.bidAnswers || [];
    if(answers.length) return answers[answers.length - 1];
    return {};
  }
}
