import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { BidSessionAddRouteComponent } from '.';
import { RouteListViewModule } from '../component/route-list-view/module';
import { AddRouteModule } from '../component/add-route/module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzGridModule,
    ReactiveFormsModule,
    NzButtonModule,
    RouteListViewModule,
    AddRouteModule
  ],
  declarations: [
    BidSessionAddRouteComponent,
  ],
  exports: [
    BidSessionAddRouteComponent
  ],
  providers: [
  ]
})
export class BidSessionAddRouteModule { }