import { Component, Input, OnInit } from '@angular/core';
import { Const } from '@const/Const';
import { ResponseAdminFinJobDetailTx } from '@wearewarp/types-server-admin/fin';
import { ModalHelper } from '@wearewarp/ng-antd';
import { ConstFin } from '@wearewarp/js-const-finance';
import { InputHelper } from '@services/input-helper';
import { BizUtil } from '@services/biz';
import { DateUtil } from '@services/date-utils';
import { FinTxDetail } from '../fin-tx-detail';

@Component({
  selector: '[fin-job-tx-history]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class FinJobTxHistory implements OnInit {
  @Input() txArr: ResponseAdminFinJobDetailTx[];

  constructor(private modalHelper: ModalHelper) {}

  ngOnInit(): void {
  }

  isTxClosed(tx: ResponseAdminFinJobDetailTx): boolean {
    return tx.status == ConstFin.FinTransactionStatus.closed;
  }

  displayTxStatus(tx: ResponseAdminFinJobDetailTx): string {
    switch (tx.status) {
      case ConstFin.FinTransactionStatus.created: return 'Open';
      case ConstFin.FinTransactionStatus.closed: return 'Closed';
      case ConstFin.FinTransactionStatus.canceled: return 'Canceled';
      default: return tx.status;
    }
  }

  displayDateTime(tx: ResponseAdminFinJobDetailTx) {
    return DateUtil.format(tx.created.when, Const.FORMAT_GUI_DATETIME);
  }

  displayUser(tx: ResponseAdminFinJobDetailTx) {
    return BizUtil.getFullName(tx.created.byUser);
  }

  routerLinkUser(user) {
    return [Const.routeAdminUserList, user.id];
  }

  getMoneyValue(value) {
    return InputHelper.formatMoney2(value ?? 0, '$');
  }

  getTxDesc(tx: ResponseAdminFinJobDetailTx) {
    return BizUtil.getTxDesc(tx);
  }

  openTxDetail(tx: ResponseAdminFinJobDetailTx) {
    const modalRef = this.modalHelper.open(FinTxDetail, {
      nzClosable: false,
      nzCentered: true,
      nzComponentParams: { data: tx },
      nzFooter: [{
        label: 'Close',
        onClick: () => modalRef?.destroy()
      }]
    });
  }
}