import { Component, ViewChild } from '@angular/core';
import { ShipmentEntryTabContent } from '../../tab-content-component';
import {
  ComponentForm,
  FormDataBatchLocations,
} from '@app/admin/shipment-entry/interface';
import { BaseForm } from '@app/admin/base/form-base';
import { FormShipmentLocationBatch } from '../../forms/batch-locations';
import { Subscription } from 'rxjs';
import { FormDataShipmentEntryDraftBatch } from '@wearewarp/types-server-admin/form-data/shipment-entry';

@Component({
  selector: '[shipment-entry-batch-location-stops]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ShipmentEntryBatchLocationStops extends ShipmentEntryTabContent implements ComponentForm<FormDataBatchLocations> {

  @ViewChild('formComp') formComp: FormShipmentLocationBatch;

  getForm(): BaseForm<FormDataBatchLocations> {
    return this.formComp;
  }

  get formModel(): FormDataBatchLocations {
    return this._formModel;
  }
  
  private sub: Subscription;
  private _formModel: FormDataBatchLocations;

  ngOnInit(): void {
    this.sub = this.context.modelDraftChange.subscribe(this.onModelDraftChange);
    if (this.context.modelDraft) {
      this.onModelDraftChange(this.context.modelDraft);
    }
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.sub?.unsubscribe();
  }

  private onModelDraftChange(data: FormDataShipmentEntryDraftBatch) {
    this._formModel = {
      batchType: this.modelDraft?.shipmentEntryMode,
      data: <FormDataShipmentEntryDraftBatch>this.modelDraft,
    }
  }

}