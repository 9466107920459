import { Pipe } from "@angular/core";

@Pipe({
  name: "capitalize",
})
export class CapitalizePipe {
  transform(text: string = ""): string {
    if (!text) return "";
    return text
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }
}
